import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import Header from '../../components/Header'
import { ToastContainer } from "react-toastify";
import NavbarCoordenador from "../../components/Navbars/NavbarCoordenador/navbarCoordenador";
import Footer from "../../components/Footer";

const MenuCoordinator = () => {
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [name, setName] = useState("");
    const params = useParams();
    const navigate = useNavigate()

    //Recebe um coordenador pelo seu id
    function getCoordenador() {
        fetch(`${process.env.REACT_APP_API_URL}/coordenadores/${params.id_candidatura}`, {
            headers: {
                'authorization': localStorage.getItem("coord")
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    //Contar quantos cursos estão finalizados
                    let contador = 0
                    result.percurso.forEach(cont => {
                        if (cont.fechoAdmin === false) {
                            contador++
                        }
                    });
                    setData2(contador);
                    nomeApelido(result.fullName)
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    };

    function nomeApelido(nomeCompleto) {
        if (nomeCompleto) {
            let names = nomeCompleto.split(" ");
            let firstName = names[0];
            let lastName = ""
            if (names.length > 1) {
                lastName = names.pop();
            }
            setName(firstName + " " + lastName)
        }
    }

    useEffect(() => {
        getCoordenador()
    }, [loading]);

    return (
        <>
            <ToastContainer />
            <main className="min-h-screen grid-cols-7 mx-auto md:grid xl:grid-cols-10">
                <div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    <NavbarCoordenador currentPage={"home"} />
                </div>

                <div className="h-full col-span-5 mb-8 xl:col-span-8">

                    <div className='mx-10 mt-8'>
                        <Header sideMenu={"true"} />
                        <div className="items-center sm:flex lg:ml-24 ">
                            <div>
                                {data && <img alt="profile" className="mx-auto max-h-40 rounded-3xl sm:m-0" src={data.photo === undefined || data.photo === "" ? 'https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg' : data.photo} />}
                            </div>
                            <div className='mx-auto sm:ml-16'>
                                {<h2 className="text-2xl font-bold text-text-main-color" >Olá, {name}!</h2>}
                                <div className="justify-around mt-2 font-medium sm:flex text-text-second-color">
                                    <div>
                                        <p>Percursos em atividade</p>
                                        {data2 && data2}
                                    </div>
                                    <div className="px-1">
                                        <p>Percursos finalizados</p>
                                        {data && data.percurso.length - data2}
                                    </div>
                                    <div className="px-1">
                                        <p>Percursos total</p>
                                        {data && data.percurso.length}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className='my-6' />
                        <div className="mb-5 lg:ml-24">
                            <div className='ml-2'>
                                <h2 className='text-lg font-bold text-center sm:text-left text-text-main-color'>Acesso rápido</h2>
                                <h3 className="mb-2 text-center sm:text-left text-text-second-color">Percursos em atividade</h3>
                            </div>
                            <div className="grid grid-cols-1 gap-10 mt-2 mb-5 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3">
                                {data && (
                                    <>
                                        {data.percurso
                                            .filter(item => !item.fechoAdmin)
                                            .map((item, index) => (
                                                <div key={index}>
                                                    <div
                                                        key={item._id}
                                                        onClick={() =>
                                                            navigate(
                                                                `/menucoordenador/${params.id_candidatura}/cursos/${item._id}`
                                                            )
                                                        }
                                                        className="flex flex-col w-full p-4 py-5 transition shadow-lg bg-secundary-color rounded-3xl duration-250 hover:cursor-pointer hover:scale-105 min-h-[13rem]"
                                                    >
                                                        <div>
                                                            <div className="min-h-[5.4rem] flex items-center justify-center uppercase">
                                                                <h4 className="font-semibold text-text-main-color">{item.name}</h4>
                                                            </div>
                                                            <div className="grid grid-cols-1 mt-2 text-center sm:text-left sm:grid-cols-2">
                                                                <div>
                                                                    <div>
                                                                        <p className="text-sm text-text-third-color">
                                                                            <b>Modalidade: </b>
                                                                            {item.modalidade}
                                                                        </p>
                                                                    </div>
                                                                    <div>
                                                                        <p className="text-sm text-text-third-color">
                                                                            <b>Tipo: </b>
                                                                            {item.type}
                                                                        </p>
                                                                    </div>
                                                                    <div>
                                                                        <p className="text-sm text-text-third-color">
                                                                            <b>Edição: </b>
                                                                            {item.edicoes}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div>
                                                                        <p className="text-sm text-text-third-color">
                                                                            <b>Início: </b>
                                                                            {item.dateBegin}
                                                                        </p>
                                                                    </div>
                                                                    <div>
                                                                        <p className="text-sm text-text-third-color">
                                                                            <b>Fim: </b>
                                                                            {item.dateEnd}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
}

export default MenuCoordinator