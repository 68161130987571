import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom'
import Header from "../Header";
import Footer from "../Footer";
import toastSuccess from "../Toast/toastSuccess";
import toastError from "../Toast/toastError";
import { addDays, format } from 'date-fns';
import NavbarFormador from "../Navbars/NavbarFormador/navbarFormador";
import NavbarCoordenador from "../Navbars/NavbarCoordenador/navbarCoordenador";
import NavbarAdmin1 from "../Navbars/NavbarAdmin1/NavbarAdmin1";
import NavbarAdmin2 from "../Navbars/NavbarAdmin2/NavbarAdmin2";

function GerarModulos(current, token) {
    let Next = process.env.PUBLIC_URL + "/Next.svg"
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [ativSub, setAtivSub] = useState(false);
    const [nModulos, setNModulos] = useState("")
    const [evento0Day, setEvento0Day] = useState("")
    const [evento0Hour, setEvento0Hour] = useState("")
    const [evento0Duration, setEvento0Duration] = useState("")
    const [nSubModulos, setNSubModulos] = useState([])
    const [modulo, setModulo] = useState([])
    const [subModulo, setSubModulo] = useState([])
    const [cont, setCont] = useState(0)
    const [section, setSection] = useState(1)
    const [section2, setSection2] = useState(1)
    const [events, setEvents] = useState([]);
    const params = useParams();
    const navigate = useNavigate();
    const [typeEvent, setTypeEvent] = useState("");
    const [horasDisponiveis, setHorasDisponiveis] = useState("");
    const [valores, setValores] = useState([{
        name: "", cargaHoraria: 0, dataInicio: "", dataFinal: "", eventoDay: "", horas: "", duration: "", diaTeste: "", teste: "", horaTeste: "", guardarSub2: [{
            name: "", cargaTotal: 0, carga1: 0, carga2: 0, carga3: 0
        }]
    }])

    //Obter Ufcds
    function getUfcds() {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/${params.id_ufcd}`,
            {
                headers: {
                    'authorization': localStorage.getItem(token)
                }
            })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    for (let i = 0; i < result.percurso[0].ufcds.length; i++) {
                        fetch(`${process.env.REACT_APP_API_URL}/calendar/ufcds/${result.percurso[0].ufcds[i]}`, {
                            headers: {
                                'authorization': localStorage.getItem(token)
                            }
                        })
                            .then((res) => res.json())
                            .then(
                                (result) => {
                                    setEvents(prevState => [...prevState, ...result])
                                },
                                (error) => {
                                    console.error("Error fetching data: ", error);
                                    setError(error);
                                }
                            );
                    };
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    }

    function verificarHoras(date, value, duration) {
        for (let event of events) {
            // Converte as horas em milisegundos
            let startInput = date + "T" + value
            let start = new Date(startInput);
            let end = new Date(start.getTime() + (parseFloat(duration) * (1000 * 60 * 60))); // add 1 hour to the 'start' time
            const startTime = start.getTime();
            const endTime = end.getTime();
            const eventStartTime = new Date(event.start).getTime();
            const eventEndTime = new Date(event.end).getTime();
            if ((startTime >= eventStartTime && startTime < eventEndTime) ||
                (endTime > eventStartTime && endTime <= eventEndTime) ||
                (startTime <= eventStartTime && endTime >= eventEndTime)) {
                return false;
            }
            else {
            }
        }
    }
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };
    const formatDateT = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };
    useEffect(() => {
        if (section === 1) { getUfcds() }
        if (valores && valores.length > 0) {
            let soma = 0
            for (let i = 0; i < valores.length; i++) {
                if (valores[i].cargaHoraria) {
                    soma = soma + parseInt(valores[i].cargaHoraria)
                }
            }
            setHorasDisponiveis(soma)
        }
    }, [cont, section, section2]);

    function formatDateToMinFormat(date) {
        const year = date.getFullYear().toString().padStart(4, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    // Mapa das UFCDs Modelo selecionadas
    function RenderList() {
        let array = [];
        let guardarMod = [];
        let guardarSub = [];

        for (let i = 0; i < nModulos; i++) {
            if (i === 0) {
                array.push(i + 1)
                guardarMod.push({ name: "", cargaHoraria: 0, dataInicio: data.dateBegin, dataFinal: "", eventoDay: "", horas: "", duration: "", diaTeste: "", teste: "", horaTeste: "" })

            } else if (i === (nModulos - 1)) {
                array.push(i + 1)
                guardarMod.push({ name: "", cargaHoraria: 0, dataInicio: array[i - 1].dateEnd, eventoDay: "", dataFinal: data.dataEnd, horas: "", duration: "", diaTeste: "", teste: "", horaTeste: "" })
            } else {
                array.push(i + 1)
                guardarMod.push({ name: "", cargaHoraria: 0, dataInicio: array[i - 1].dateEnd, eventoDay: "", dataFinal: "", horas: "", duration: "", diaTeste: "", teste: "", horaTeste: "" })
            }
        }

        function section1() {
            if (section2 === array.length && section2 === 1) {
                let array = ({
                    name: valores[section2 - 1].name,
                    cargaHoraria: valores[section2 - 1].cargaHoraria,
                    dataInicio: data.dateBegin,
                    dataFinal: data.dateEnd,
                    horas: valores[section2 - 1].horas,
                    duration: valores[section2 - 1].duration,
                    subModulos: valores[section2 - 1].guardarSub2,
                    eventoDay: valores[section2 - 1].eventoDay,
                    teste: valores[section2 - 1].teste,
                    diaTeste: valores[section2 - 1].diaTeste,
                    horaTeste: valores[section2 - 1].horaTeste

                })
                addModulo(array)
            }
            else if (section2 === array.length && section2 !== 1) {
                let array = ({
                    name: valores[section2 - 1].name,
                    cargaHoraria: valores[section2 - 1].cargaHoraria,
                    dataInicio: format(addDays(new Date(valores[section2 - 2].dataFinal), 1), 'yyyy-MM-dd'),
                    dataFinal: data.dateEnd,
                    horas: valores[section2 - 1].horas,
                    duration: valores[section2 - 1].duration,
                    subModulos: valores[section2 - 1].guardarSub2,
                    eventoDay: valores[section2 - 1].eventoDay,
                    teste: valores[section2 - 1].teste,
                    diaTeste: valores[section2 - 1].diaTeste,
                    horaTeste: valores[section2 - 1].horaTeste
                })
                addModulo(array)
            }
            else if (section2 !== array.length && section2 === 1) {
                valores[section2 - 1].dataInicio = data.dateBegin
                let array = ({
                    name: valores[section2 - 1].name,
                    cargaHoraria: valores[section2 - 1].cargaHoraria,
                    dataInicio: data.dateBegin,
                    dataFinal: valores[section2 - 1].dataFinal,
                    duration: valores[section2 - 1].duration,
                    horas: valores[section2 - 1].horas,
                    subModulos: valores[section2 - 1].guardarSub2,
                    eventoDay: valores[section2 - 1].eventoDay,
                    teste: valores[section2 - 1].teste,
                    diaTeste: valores[section2 - 1].diaTeste,
                    horaTeste: valores[section2 - 1].horaTeste
                })
                addModulo(array)
            }
            else {
                let array = ({
                    name: valores[section2 - 1].name,
                    cargaHoraria: valores[section2 - 1].cargaHoraria,
                    dataInicio: format(addDays(new Date(valores[section2 - 2].dataFinal), 1), 'yyyy-MM-dd'),
                    dataFinal: valores[section2 - 1].dataFinal,
                    duration: valores[section2 - 1].duration,
                    horas: valores[section2 - 1].horas,
                    subModulos: valores[section2 - 1].guardarSub2,
                    eventoDay: valores[section2 - 1].eventoDay,
                    teste: valores[section2 - 1].teste,
                    diaTeste: valores[section2 - 1].diaTeste,
                    horaTeste: valores[section2 - 1].horaTeste
                })
                addModulo(array)
            }
        }

        //Validações para o ultimo modulo
        function finalizar() {
            let nome = valores[section2 - 1].name;
            let cargaHoraria = valores[section2 - 1].cargaHoraria;
            let horas = valores[section2 - 1].horas;
            let duration = valores[section2 - 1].duration
            let dateToCheck = new Date(valores[section2 - 1].dataInicio);
            let nSubModulo = nSubModulos[section2 - 1];
            let eventoDay = valores[section2 - 1].eventoDay
            let teste = valores[section2 - 1].teste;
            let diaTeste = new Date(valores[section2 - 1].diaTeste);
            let horaTeste = valores[section2 - 1].horaTeste;
            if (nome !== "" && cargaHoraria !== 0 && horas !== "" && dateToCheck && nSubModulo && duration && eventoDay && ((teste === "true" && diaTeste && horaTeste) || teste === "false")) {
                if (cargaHoraria > 0) {
                    if (nSubModulo > 0) {
                        let numSubValidos = 0;
                        let exitLoop = false;
                        let somaCargas = 0;
                        valores[section2 - 1].guardarSub2.forEach(subMod => {
                            if (exitLoop) {
                                return;
                            }
                            if (subMod.name) {
                                if (subMod.cargaTotal && subMod.cargaTotal > 0) {
                                    somaCargas += parseInt(subMod.cargaTotal);
                                    numSubValidos++;
                                } else {
                                    toastError("Insira uma carga nos objetos de aprendizagem válida.");
                                    exitLoop = true;
                                }
                            } else {
                                toastError("Insira nomes de objetos de aprendizagem válidos.");
                                exitLoop = true;
                            }
                        });

                        if (numSubValidos === guardarSub.length) {

                            if (somaCargas === parseInt(cargaHoraria)) {
                                handleNext()
                                setAtivSub(false)
                            } else toastError("A carga horária têm que ser igual à soma da carga dos objetos de aprendizagem.");
                        }
                    } else toastError("Preencha um número de objetos de aprendizagem válido.")
                } else toastError("Insira uma carga horária válida.")
            } else toastError("Existem campos por preencher.")
        }

        let arraySubModulos = []
        for (let i = 0; i < nSubModulos[section2 - 1]; i++) {
            arraySubModulos.push(i + 1)
            guardarSub.push({ name: "", cargaTotal: 0, carga1: 0, carga2: 0, carga3: 0 })
            if (valores[section2 - 1].guardarSub2.length < guardarSub.length) {
                valores[section2 - 1].guardarSub2.push({ name: "", cargaTotal: 0, carga1: 0, carga2: 0, carga3: 0 })
            }
        }

        if (guardarSub.length !== 0 && valores[section2 - 1].guardarSub2.length > guardarSub.length) {
            valores[section2 - 1].guardarSub2.splice(guardarSub.length, valores[section2 - 1].guardarSub2.length)

        }
        return (
            <>
                <section>
                    {section2 <= array.length ? <div>
                        {ativSub === false ?
                            <div>
                                <h1 className="subtitle">Informação geral</h1>
                                <div className="grid mt-4 sm:grid-cols-2">
                                    <div className="mx-auto">
                                        <div className="mt-4 text-center sm:text-left" >
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Nome do Módulo:</div>
                                            <input className="mt-2 text-lg input-field w-52" type="text" id={"nameModulos" + section2} name="nameModulos" defaultValue={valores[section2 - 1].name} onChange={(e) => { valores[section2 - 1].name = e.target.value }} />
                                        </div>

                                        <div className="mt-3 text-center sm:text-left">
                                            {section2 === 1 ? <>
                                                <div className="text-xs uppercase text-text-second-color opacity-90">Data Início (Módulo):</div>
                                                <input type="text" disabled id={"dataInicio" + section2} name="dataInicio" className="mt-2 text-lg input-field w-52" value={formatDate(evento0Day)} ></input></> :
                                                <>
                                                    <div className="text-xs uppercase text-text-second-color opacity-90">Data Início (Módulo):</div>
                                                    <input disabled type="date" id={"dataInicio" + section2} name="dataInicio" className="mt-2 text-lg input-field w-52" value={format(addDays(new Date(valores[section2 - 2].dataFinal), 1), "yyyy-MM-dd")} onChange={(e) => { valores[section2 - 1].dataInicio = e.target.value }} />
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="mx-auto">
                                        <div className="mt-3 text-center sm:text-left sm:mt-0">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Carga horária (horas):</div>
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Horas realizadas: {horasDisponiveis}/{data.cargaHoraria}</div>
                                            <input className="mt-2 text-lg text-text-main-color input-field w-52" type="number" min={1} name="cargaHoraria" id={"cargaHoraria" + section2} defaultValue={valores[section2 - 1].cargaHoraria} onChange={(e) => { valores[section2 - 1].cargaHoraria = e.target.value }} onBlur={() => (setCont(cont + 1))} />
                                        </div>

                                        <div className="mt-3 text-center sm:text-left">
                                            {section2 === parseInt(nModulos) ? <>
                                                <div className="text-xs uppercase text-text-second-color opacity-90">Data Final (Módulo):</div>
                                                <input type="text" disabled id={"dataFinal" + section2} name="dataFinal" className="mt-2 text-lg input-field w-52" value={valores[section2 - 1].eventoDay ? formatDate(addDays(new Date(valores[section2 - 1].eventoDay), 2)) : formatDate(data.dateEnd)} />
                                            </> :
                                                <>
                                                    {section2 != 1 ? <>
                                                        <div className="text-xs uppercase text-text-second-color opacity-90">Data Final:</div>
                                                        <input type="date" id={"dataFinal" + section2} min={formatDateToMinFormat(addDays(new Date(valores[section2 - 2].dataFinal), 1))} max={formatDateToMinFormat(new Date(data.dateEnd))} name="dataFinal" className="mt-2 input-field w-52" defaultValue={valores[section2 - 1].dataFinal} onChange={(e) => { valores[section2 - 1].dataFinal = e.target.value }} />
                                                    </>
                                                        : <>
                                                            <div className="text-xs uppercase text-text-second-color opacity-90">Data Final (Módulo):</div>
                                                            <input type="date" id={"dataFinal" + section2} min={formatDateToMinFormat(new Date(data.dateBegin))} max={formatDateToMinFormat(new Date(data.dateEnd))} name="dataFinal" className="mt-2 text-lg input-field w-52" defaultValue={valores[section2 - 1].dataFinal} onChange={(e) => { valores[section2 - 1].dataFinal = e.target.value }} />
                                                        </>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-4 mt-8 border-t-1 border-main-color" />
                                <h1 className="subtitle">Teste</h1>
                                <div className="grid mt-4 sm:grid-cols-2">
                                    <div className="mx-auto">
                                        <div className="text-left">
                                            <div className="mt-3 text-xs text-center uppercase sm:text-left text-text-second-color opacity-90">Teste: </div>
                                            <select onWheel={(e) => e.target.blur()} className="text-lg selector w-52" name="sel" id={"teste" + section2} defaultValue={valores[section2 - 1].teste} onChange={(e) => { valores[section2 - 1].teste = e.target.value }} onBlur={() => (setCont(cont + 1))}>
                                                <option disabled value="">Selecione uma opção</option>
                                                <option value={"true"}>Sim</option>
                                                <option value={"false"}>Não</option>
                                            </select>
                                        </div>
                                        <div className="mt-3 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Data Início (Teste):</div>
                                            <input disabled={valores[section2 - 1].teste === "true" ? false : true} type="date" id={"dataInicioTeste" + section2} min={valores[section2 - 2] ? formatDateToMinFormat(addDays(new Date(valores[section2 - 2].dataFinal), 1)) : formatDateToMinFormat(new Date(evento0Day))} name="dataInicioeEvento" className={`mt-2 text-lg w-52 ${valores[section2 - 1].teste === "true" ? "input-field" : "input-field-disabled"}`} defaultValue={valores[section2 - 1].diaTeste} onChange={(e) => { valores[section2 - 1].diaTeste = e.target.value }} onBlur={() => (setCont(cont + 1))} ></input>
                                        </div>
                                    </div>
                                    <div className="mx-auto">
                                        <div className="text-left">
                                            <div className="mt-3 text-xs text-center uppercase sm:text-left text-text-second-color opacity-90">Hora de Inicio do Teste: </div>
                                            {valores[section2 - 1].teste === "true" && document.getElementById("dataInicioTeste" + section2) && valores[section2 - 1].diaTeste ?
                                                <select onWheel={(e) => e.target.blur()} className={`mt-2 text-lg w-52 ${valores[section2 - 1].teste === "true" && document.getElementById("dataInicioTeste" + section2) && valores[section2 - 1].diaTeste ? "selector" : "selector-disabled"}`} name="sel" id={"horasTeste" + section2} defaultValue={valores[section2 - 1].horaTeste} onChange={(e) => { valores[section2 - 1].horaTeste = e.target.value }} >
                                                    <option disabled value="">Selecione uma opção</option>
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "08:00") !== false && <option value="08:00">08:00h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "08:30") !== false && <option value="08:30">08:30h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "09:00") !== false && <option value="09:00">09:00h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "09:30") !== false && <option value="09:30">09:30h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "10:00") !== false && <option value="10:00">10:00h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "10:30") !== false && <option value="10:30">10:30h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "11:00") !== false && <option value="11:00">11:00h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "11:30") !== false && <option value="11:30">11:30h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "12:00") !== false && <option value="12:00">12:00h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "14:00") !== false && <option value="14:00">14:00h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "14:30") !== false && <option value="14:30">14:30h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "15:00") !== false && <option value="15:00">15:00h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "15:30") !== false && <option value="15:30">15:30h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "16:00") !== false && <option value="16:00">16:00h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "16:30") !== false && <option value="16:30">16:30h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "17:00") !== false && <option value="17:00">17:00h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "17:30") !== false && <option value="17:30">17:30h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "18:00") !== false && <option value="18:00">18:00h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "18:30") !== false && <option value="18:30">18:30h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "19:00") !== false && <option value="19:00">19:00h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "19:30") !== false && <option value="19:30">19:30h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "20:00") !== false && <option value="20:00">20:00h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "20:30") !== false && <option value="20:30">20:30h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "21:00") !== false && <option value="21:00">21:00h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "21:30") !== false && <option value="21:30">21:30h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "22:00") !== false && <option value="22:00">22:00h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "22:30") !== false && <option value="22:30">22:30h</option>}
                                                    {verificarHoras(document.getElementById("dataInicioTeste" + section2).value, "23:00") !== false && <option value="23:00">23:00h</option>}

                                                </select>
                                                : <select onWheel={(e) => e.target.blur()} disabled={true} className={`mt-2 text-lg w-52 selector-disabled`} name="sel" id={"horasTeste" + section2} defaultValue={valores[section2 - 1].horaTeste} onChange={(e) => { valores[section2 - 1].horaTeste = e.target.value }} >
                                                    <option disabled value="">Selecione uma opção</option>
                                                </select>}
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-4 mt-8 border-t-1 border-main-color" />
                                <h1 className="subtitle">Evento</h1>
                                <div className="grid mt-4 sm:grid-cols-2">
                                    <div className="mx-auto">
                                        <div className="text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Data (Evento):</div>
                                            <input type="date" id={"dataInicioEvento" + section2} min={valores[section2 - 2] ? formatDateToMinFormat(addDays(new Date(valores[section2 - 2].dataFinal), 1)) : formatDateToMinFormat(new Date(evento0Day))} name="dataInicioeEvento" className={`mt-2 text-lg input-field w-52`} defaultValue={valores[section2 - 1].eventoDay} onChange={(e) => { valores[section2 - 1].eventoDay = e.target.value }} onBlur={() => (setCont(cont + 1))} ></input>
                                        </div>
                                        <div className="mt-3 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Tipo (Evento):</div>
                                            <select onWheel={(e) => e.target.blur()} disabled={data.formato === "Misto" || !data.formato ? false : true}
                                                id={"tipo" + section2}
                                                name="tipo"
                                                className={`text-lg mt-2 ${data.formato === "Misto" || !data.formato ? "selector" : "selector-disabled"} w-52`}
                                                value={typeEvent ? typeEvent : data.formato === "Online" ? "Online" : data.formato === "Presencial" ? "Presencial" : ""}
                                                onChange={(e) => { setTypeEvent(e.target.value) }}
                                            >
                                                <option disabled value="">Selecione uma opção</option>
                                                <option value="Presencial">Presencial</option>
                                                <option value="Online">Online</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mx-auto">
                                        {section2 === parseInt(nModulos) ? <>
                                            <div className="text-xs text-center uppercase sm:text-left text-text-second-color opacity-90">Duração (Evento):</div>
                                            <select onWheel={(e) => e.target.blur()} disabled={valores[section2 - 1].eventoDay ? false : true} className={`mt-2 text-lg w-52 ${valores[section2 - 1].eventoDay ? "selector" : "selector-disabled"}`} name="sel" id={"duracao" + section2} defaultValue={valores[section2 - 1].duration} onChange={(e) => { valores[section2 - 1].duration = e.target.value }} onBlur={() => (setCont(cont + 1))}>
                                                <option disabled value="">Selecione uma opção</option>
                                                <option value="1">1h</option>
                                                <option value="1.5">1:30h</option>
                                                <option value="2">2h</option>
                                                <option value="2.5">2:30h</option>
                                                <option value="3">3h</option>
                                                <option value="3.5">3:30h</option>
                                                <option value="4">4h</option>
                                                <option value="4.5">4h:30</option>
                                                <option value="5">5h</option>
                                            </select>
                                        </> :
                                            <>
                                                <div className="text-xs text-center uppercase sm:text-left text-text-second-color opacity-90">Duração (Evento):</div>
                                                <select onWheel={(e) => e.target.blur()} disabled={valores[section2 - 1].eventoDay ? false : true} className={`mt-2 text-lg w-52 ${valores[section2 - 1].eventoDay ? "selector" : "selector-disabled"}`} name="sel" id={"duracao" + section2} defaultValue={valores[section2 - 1].duration} onChange={(e) => { valores[section2 - 1].duration = e.target.value }} onBlur={() => (setCont(cont + 1))}>
                                                    <option disabled value="">Selecione uma opção</option>
                                                    <option value="1">1h</option>
                                                    <option value="1.5">1:30h</option>
                                                    <option value="2">2h</option>
                                                    <option value="2.5">2:30h</option>
                                                    <option value="3">3h</option>
                                                    <option value="3.5">3:30h</option>
                                                    <option value="4">4h</option>
                                                    <option value="4.5">4h:30</option>
                                                    <option value="5">5h</option>
                                                </select>
                                            </>
                                        }
                                        <div className="text-left">
                                            <div className="mt-3 text-xs text-center uppercase sm:text-left text-text-second-color opacity-90">Hora de Evento: </div>

                                            {document.getElementById("dataInicioEvento" + section2) && valores[section2 - 1].duration ?
                                                <select onWheel={(e) => e.target.blur()} className="mt-2 text-lg selector" name="sel" id={"horas" + section2} defaultValue={valores[section2 - 1].horas} onChange={(e) => { valores[section2 - 1].horas = e.target.value }}>
                                                    <option disabled value="">Selecione uma opção</option>
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "08:00", document.getElementById("duracao" + section2).value) !== false && <option value="08:00">08:00h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "08:30", document.getElementById("duracao" + section2).value) !== false && <option value="08:30">08:30h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "09:00", document.getElementById("duracao" + section2).value) !== false && <option value="09:00">09:00h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "09:30", document.getElementById("duracao" + section2).value) !== false && <option value="09:30">09:30h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "10:00", document.getElementById("duracao" + section2).value) !== false && <option value="10:00">10:00h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "10:30", document.getElementById("duracao" + section2).value) !== false && <option value="10:30">10:30h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "11:00", document.getElementById("duracao" + section2).value) !== false && <option value="11:00">11:00h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "11:30", document.getElementById("duracao" + section2).value) !== false && <option value="11:30">11:30h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "12:00", document.getElementById("duracao" + section2).value) !== false && <option value="12:00">12:00h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "14:00", document.getElementById("duracao" + section2).value) !== false && <option value="14:00">14:00h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "14:30", document.getElementById("duracao" + section2).value) !== false && <option value="14:30">14:30h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "15:00", document.getElementById("duracao" + section2).value) !== false && <option value="15:00">15:00h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "15:30", document.getElementById("duracao" + section2).value) !== false && <option value="15:30">15:30h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "16:00", document.getElementById("duracao" + section2).value) !== false && <option value="16:00">16:00h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "16:30", document.getElementById("duracao" + section2).value) !== false && <option value="16:30">16:30h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "17:00", document.getElementById("duracao" + section2).value) !== false && <option value="17:00">17:00h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "17:30", document.getElementById("duracao" + section2).value) !== false && <option value="17:30">17:30h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "18:00", document.getElementById("duracao" + section2).value) !== false && <option value="18:00">18:00h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "18:30", document.getElementById("duracao" + section2).value) !== false && <option value="18:30">18:30h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "19:00", document.getElementById("duracao" + section2).value) !== false && <option value="19:00">19:00h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "19:30", document.getElementById("duracao" + section2).value) !== false && <option value="19:30">19:30h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "20:00", document.getElementById("duracao" + section2).value) !== false && <option value="20:00">20:00h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "20:30", document.getElementById("duracao" + section2).value) !== false && <option value="20:30">20:30h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "21:00", document.getElementById("duracao" + section2).value) !== false && <option value="21:00">21:00h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "21:30", document.getElementById("duracao" + section2).value) !== false && <option value="21:30">21:30h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "22:00", document.getElementById("duracao" + section2).value) !== false && <option value="22:00">22:00h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "22:30", document.getElementById("duracao" + section2).value) !== false && <option value="22:30">22:30h</option>}
                                                    {verificarHoras(valores[section2 - 1].eventoDay, "23:00", document.getElementById("duracao" + section2).value) !== false && <option value="23:00">23:00h</option>}

                                                </select>
                                                :
                                                <select onWheel={(e) => e.target.blur()} disabled={true} className="mt-2 text-lg selector-disabled" name="sel" id={"horas" + section2} defaultValue={valores[section2 - 1].horas} onChange={(e) => { valores[section2 - 1].horas = e.target.value }}>
                                                    <option disabled value="">Selecione uma opção</option>
                                                </select>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-4 mt-8 border-t-1 border-main-color" />
                                <div className="subtitle">Objetos de aprendizagem:</div>
                                <div className="flex flex-col items-center mt-4 text-center sm:text-left">
                                    <div className="text-xs text-center uppercase text-text-second-color opacity-90">Número de objetos de aprendizagem:</div>
                                    <input type="number" onWheel={(e) => e.target.blur()} min={1} className="mt-2 text-lg text-text-main-color input-field w-52" id={"nSubModulos" + section2} name="nSubModulos" defaultValue={nSubModulos[section2 - 1]} onChange={(e) => { nSubModulos[section2 - 1] = e.target.value }} onBlur={() => (setCont(cont + 1))} />
                                </div>

                            </div> : ""}
                        {arraySubModulos.map((index) => (
                            <div className="py-2 my-4 overflow-auto bg-white border border-gray-400 rounded-lg max-h-72" key={index}>
                                <div className="grid mx-4 mt-4 sm:grid-cols-2" >

                                    <div className="mx-auto">
                                        <div className="text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">{index}. Nome</div>
                                            <input className="mt-2 text-lg input-field" type="text" id={"nameSubModulos" + index} name="nameSubModulos" defaultValue={valores[section2 - 1].guardarSub2[index - 1].name} onChange={(e) => { valores[section2 - 1].guardarSub2[index - 1].name = (e.target.value); }} onBlur={() => (setCont(cont + 1))} />
                                        </div>
                                    </div>

                                    <div className="mx-auto">
                                        <div className="text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Carga:</div>
                                            <input className="mt-2 text-lg input-field" type="text" id={"cargaSubModulos" + index} name="cargaSubModulos" defaultValue={valores[section2 - 1].guardarSub2[index - 1].cargaTotal} onChange={(e) => {
                                                valores[section2 - 1].guardarSub2[index - 1].cargaTotal = ((e.target.value));
                                                valores[section2 - 1].guardarSub2[index - 1].carga1 = (((e.target.value) * 60) * 0.10);
                                                valores[section2 - 1].guardarSub2[index - 1].carga2 = (((e.target.value) * 60) * 0.40);
                                                valores[section2 - 1].guardarSub2[index - 1].carga3 = (((e.target.value) * 60) * 0.50);
                                            }} onBlur={() => (setCont(cont + 1))} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))}

                        {section2 === 1 && (<button onClick={(e) => {

                            let nome = valores[section2 - 1].name;
                            let cargaHoraria = valores[section2 - 1].cargaHoraria;
                            let horas = valores[section2 - 1].horas;
                            let duration = valores[section2 - 1].duration;
                            let nSubModulo = nSubModulos[section2 - 1];
                            let eventoDay = valores[section2 - 1].eventoDay
                            let diaTeste = new Date(valores[section2 - 1].diaTeste);
                            let teste = valores[section2 - 1].teste;
                            let horaTeste = valores[section2 - 1].horaTeste;
                            if (nome !== "" && cargaHoraria !== 0 && horas !== "" && nSubModulo && duration && eventoDay && ((teste === "true" && diaTeste && horaTeste) || teste === "false")) {
                                if (cargaHoraria > 0) {
                                    if (nSubModulo > 0) {
                                        let numSubValidos = 0;
                                        let exitLoop = false;
                                        let somaCargas = 0;
                                        valores[section2 - 1].guardarSub2.forEach(subMod => {
                                            if (exitLoop) {
                                                return;
                                            }
                                            if (subMod.name) {
                                                if (subMod.cargaTotal && subMod.cargaTotal > 0) {
                                                    somaCargas += parseInt(subMod.cargaTotal);
                                                    numSubValidos++;
                                                } else {
                                                    toastError("Insira carga nos objetos de aprendizagem válida.");
                                                    exitLoop = true;
                                                }
                                            } else {
                                                toastError("Insira nomes de objetos de aprendizagem válidos.");
                                                exitLoop = true;
                                            }
                                        });
                                        if (numSubValidos === guardarSub.length) {
                                            if (somaCargas === parseInt(cargaHoraria)) {
                                                if (valores[section2 - 1] && section2 < array.length && !valores[section2]) {
                                                    valores.push({
                                                        name: "", cargaHoraria: 0, dataInicio: "", dataFinal: "", eventoDay: "", horas: "", duration: "", diaTeste: "", teste: "", horaTeste: "", guardarSub2: [{
                                                            name: "", cargaTotal: 0, carga1: 0, carga2: 0, carga3: 0
                                                        }]
                                                    })
                                                }
                                                subModulo.push(guardarSub);
                                                setAtivSub(false)

                                                if (modulo.length < section2) {
                                                    section1()
                                                } else {
                                                    modulo[section2 - 1].title = valores[section2 - 1].name;
                                                    modulo[section2 - 1].cargaHoraria = valores[section2 - 1].cargaHoraria;
                                                    modulo[section2 - 1].horas = valores[section2 - 1].horas;
                                                    modulo[section2 - 1].duration = valores[section2 - 1].duration;
                                                    modulo[section2 - 1].start = valores[section2 - 1].dataInicio;
                                                    modulo[section2 - 1].end = valores[section2 - 1].dataFinal;
                                                    modulo[section2 - 1].subModulos = valores[section2 - 1].guardarSub2;
                                                    modulo[section2 - 1].eventoDay = valores[section2 - 1].eventoDay;
                                                    modulo[section2 - 1].diaTeste = valores[section2 - 1].diaTeste;
                                                    modulo[section2 - 1].teste = valores[section2 - 1].teste;
                                                    modulo[section2 - 1].horaTeste = valores[section2 - 1].horaTeste;
                                                }
                                                handleNextModulo()
                                            } else toastError("A carga horária têm que ser igual à soma da carga dos objetos de aprendizagem.");
                                        }
                                    } else toastError("Preencha um número de objetos de aprendizagem válido.")
                                } else toastError("Insira uma carga horária válida.")
                            } else toastError("Existem campos por preencher.")
                        }} type="button" className="button-next-form"><img className="invert" alt="Next" src={Next} /></button>)}
                        {section2 > 1 && section2 <= (array.length) ? (<button onClick={(e) => {
                            let today = new Date()
                            let nome = valores[section2 - 1].name;
                            let cargaHoraria = valores[section2 - 1].cargaHoraria;
                            let horas = valores[section2 - 1].horas;
                            let duration = valores[section2 - 1].duration;
                            let dateToCheck = new Date(valores[section2 - 1].dataInicio);
                            let finalDate = addDays(new Date(valores[section2 - 1].dataFinal), 1)
                            let nSubModulo = nSubModulos[section2 - 1];
                            let eventoDay = valores[section2 - 1].eventoDay
                            let diaTeste = new Date(valores[section2 - 1].diaTeste);
                            let teste = valores[section2 - 1].teste
                            let horaTeste = valores[section2 - 1].horaTeste;
                            if (nome !== "" && cargaHoraria !== 0 && horas !== "" && dateToCheck && nSubModulo && duration && eventoDay && ((teste === "true" && diaTeste && horaTeste) || teste === "false")) {
                                if (new Date(data.dateEnd) > new Date(finalDate)) {
                                    if (cargaHoraria > 0) {
                                        if (nSubModulo > 0) {
                                            let numSubValidos = 0;
                                            let exitLoop = false;
                                            let somaCargas = 0;
                                            valores[section2 - 1].guardarSub2.forEach(subMod => {
                                                if (exitLoop) {
                                                    return;
                                                }
                                                if (subMod.name) {
                                                    if (subMod.cargaTotal && subMod.cargaTotal > 0) {
                                                        somaCargas += parseInt(subMod.cargaTotal);
                                                        numSubValidos++;
                                                    } else {
                                                        toastError("Insira carga nos objetos de aprendizagem válida.");
                                                        exitLoop = true;
                                                    }
                                                } else {
                                                    toastError("Insira nomes de objetos de aprendizagem válidos.");
                                                    exitLoop = true;
                                                }
                                            });

                                            if (numSubValidos === guardarSub.length) {
                                                if (somaCargas === parseInt(cargaHoraria)) {
                                                    if (valores[section2 - 1] && section2 < array.length && !valores[section2]) {
                                                        valores.push({
                                                            name: "", cargaHoraria: 0, dataInicio: "", dataFinal: "", eventoDay: "", horas: "", duration: "", diaTeste: "", teste: "", horaTeste: "", guardarSub2: [{
                                                                name: "", cargaTotal: 0, carga1: 0, carga2: 0, carga3: 0
                                                            }]
                                                        })
                                                    }
                                                    subModulo.push(guardarSub);
                                                    setAtivSub(false)
                                                    if (modulo.length < section2) {
                                                        if (section2 === 1) {
                                                            section1()
                                                        }
                                                        else if (section2 === nModulos) {
                                                            valores[section2 - 1].dataInicio = format(addDays(new Date(valores[section2 - 2].dataFinal), 1), 'yyyy-MM-dd')
                                                            valores[section2 - 1].dataFinal = data.dateEnd
                                                            let array = ({
                                                                name: valores[section2 - 1].name,
                                                                cargaHoraria: valores[section2 - 1].cargaHoraria,
                                                                dataInicio: format(addDays(new Date(valores[section2 - 2].dataFinal), 1), 'yyyy-MM-dd'),
                                                                dataFinal: data.dateEnd,
                                                                duration: valores[section2 - 1].duration,
                                                                horas: valores[section2 - 1].horas,
                                                                subModulos: valores[section2 - 1].guardarSub2,
                                                                eventoDay: valores[section2 - 1].eventoDay,
                                                                teste: valores[section2 - 1].teste,
                                                                diaTeste: new Date(valores[section2 - 1].diaTeste),
                                                                horaTeste: valores[section2 - 1].horaTeste
                                                            })
                                                            addModulo(array)
                                                        }
                                                        else {
                                                            valores[section2 - 1].dataInicio = format(addDays(new Date(valores[section2 - 2].dataFinal), 1), 'yyyy-MM-dd')
                                                            let array = ({
                                                                name: valores[section2 - 1].name,
                                                                cargaHoraria: valores[section2 - 1].cargaHoraria,
                                                                dataInicio: format(addDays(new Date(valores[section2 - 2].dataFinal), 1), 'yyyy-MM-dd'),
                                                                dataFinal: valores[section2 - 1].dataFinal,
                                                                duration: valores[section2 - 1].duration,
                                                                horas: valores[section2 - 1].horas,
                                                                subModulos: valores[section2 - 1].guardarSub2,
                                                                eventoDay: valores[section2 - 1].eventoDay,
                                                                teste: valores[section2 - 1].teste,
                                                                diaTeste: new Date(valores[section2 - 1].diaTeste),
                                                                horaTeste: valores[section2 - 1].horaTeste
                                                            })
                                                            addModulo(array)
                                                        }
                                                    } else {
                                                        modulo[section2 - 1].title = valores[section2 - 1].name;
                                                        modulo[section2 - 1].cargaHoraria = valores[section2 - 1].cargaHoraria;
                                                        modulo[section2 - 1].horas = valores[section2 - 1].horas;
                                                        modulo[section2 - 1].duration = valores[section2 - 1].duration;
                                                        modulo[section2 - 1].start = valores[section2 - 1].dataInicio;
                                                        modulo[section2 - 1].end = valores[section2 - 1].dataFinal;
                                                        modulo[section2 - 1].subModulos = valores[section2 - 1].guardarSub2;
                                                        modulo[section2 - 1].eventoDay = valores[section2 - 1].eventoDay;
                                                        modulo[section2 - 1].teste = valores[section2 - 1].teste;
                                                        modulo[section2 - 1].diaTeste = valores[section2 - 1].diaTeste;
                                                        modulo[section2 - 1].horaTeste = valores[section2 - 1].horaTeste;
                                                    }
                                                    handleNextModulo()

                                                } else toastError("A carga horária têm que ser igual à soma da carga dos objetos de aprendizagem.");
                                            }
                                        } else toastError("Preencha um número de objetos de aprendizagem válido.")
                                    } else toastError("Insira uma carga horária válida.")
                                } else toastError("A data do Módulo ultrapassa a data da Disciplina.");
                            } else toastError("Existem campos por preencher.")
                        }} type="button" className="button-next-form"><img className="invert" alt="Next" src={Next} /></button>) : ""}
                        {section2 === (array.length) && (<button onClick={(e) => { section1(); finalizar() }} type="button" className="button-next-form"><img alt="Next" className="invert" src={Next} /></button>)}
                        {section2 === 1 && (<button onClick={handlePrevious} type="button" className="button-back-form"><img className="invert" alt="Back" src={Next} /></button>)}
                        {section2 !== 1 && (<button onClick={handlePreviousModulo} type="button" className="button-back-form"><img className="invert" alt="Back" src={Next} /></button>)}
                    </div> : ""}
                </section >
            </>
        )
    }

    //rejeitar ufcds selecionadas
    async function addModulo(userData) {
        await modulo.push({
            title: userData.name,
            cargaHoraria: userData.cargaHoraria,
            horas: userData.horas,
            start: userData.dataInicio,
            eventoDay: userData.eventoDay,
            end: userData.dataFinal,
            duration: userData.duration,
            subModulos: userData.subModulos,
            diaTeste: userData.diaTeste,
            horaTeste: userData.horaTeste,
            teste: userData.teste
        })
        let vazio = []

        await setSubModulo(vazio)
    }
    async function submeter(e) {
        e.preventDefault()
        let endTeste = new Date(new Date(valores[parseInt(nModulos) - 1].eventoDay).getTime() + (2 * 24 * 60 * 60 * 1000));

        await fetch(`${process.env.REACT_APP_API_URL}/ufcds/date/${params.id_ufcd}`,
            {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json', "authorization": localStorage.getItem(token) },
                body: JSON.stringify({
                    dateBegin: evento0Day,
                    dateEnd: formatDateT(endTeste)
                })
            })
            .then(res => res.json())
            .then(
                (res) => {
                },
                (error) => {
                    console.error(error)
                    return
                }
            )
        async function createModuleAndEvents(modulos, ind) {
            const evento = []
            let startInput = modulos.eventoDay + "T" + modulos.horas
            let titulo = ""

            startInput = startInput + "Z"; // Interpret input as UTC

            let start = new Date(startInput);
            let end = new Date(start.getTime() + (parseFloat(modulos.duration) * (1000 * 60 * 60))); // add 1 hour to the 'start' time

            // Converte as horas em milisegundos 
            const startTime = start.getTime();
            const endTime = end.getTime();
            let startInputTeste = modulos.diaTeste + "T" + modulos.horaTeste
            startInputTeste = startInputTeste + "Z";
            let startTeste = new Date(startInput);
            let endTeste = new Date(startTeste.getTime() + (2 * 24 * 60 * 60 * 1000));
            if (modulos.teste === "true") {
                fetch(`${process.env.REACT_APP_API_URL}/testes`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': localStorage.getItem(token)
                    },
                    body: JSON.stringify({
                        idUfcd: params.id_ufcd,
                        dateBegin: startTeste.toISOString(),
                        dateEnd: endTeste.toISOString(),
                    }),
                })
                    .then((res) => res.json())
                    .then(
                        (result) => {
                        },
                        (error) => {
                            console.error("Error fetching data: ", error);
                            setError(error);
                        }
                    );
            }
            // Verifica as horas para não acontecer overlap.
            for (let event of events) {
                // Converte as horas em milisegundos
                const eventStartTime = new Date(event.start).getTime();
                const eventEndTime = new Date(event.end).getTime();

                if ((startTime > eventStartTime && startTime < eventEndTime) ||
                    (endTime > eventStartTime && endTime < eventEndTime) ||
                    (startTime < eventStartTime && endTime > eventEndTime)) {
                    toastError('O novo evento sobrepõe-se a um existente. Por favor, corrija.');
                    return;
                }
            }
            evento.push({
                start: start.toISOString(),
                end: end.toISOString(),
            })
            if (typeEvent != "Misto") {
                setTypeEvent(data.formato)
            }

            if (typeEvent === "Online") {
                titulo = "VC" + ind + " - " + modulos.title;
            } else {
                titulo = "P" + ind + " - " + modulos.title;
            }
            fetch(`${process.env.REACT_APP_API_URL}/calendar/${params.id_ufcd}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                },
                body: JSON.stringify({
                    title: titulo,
                    tipo: typeEvent,
                    evento: evento,
                    id_ufcds: params.id_ufcd,
                    color: data.color,
                    id_formador: data.formadores[0]._id
                }),
            })
                .then((res) => res.json())
                .then(
                    (result) => {
                    },
                    (error) => {
                        console.error("Error fetching data: ", error);
                        setError(error);
                    }
                );

            fetch(`${process.env.REACT_APP_API_URL}/modulos`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', "authorization": localStorage.getItem(token) },
                    body: JSON.stringify({
                        id_ufcd: params.id_ufcd,
                        title: modulos.title,
                        cargaHoraria: modulos.cargaHoraria,
                        start: modulos.start,
                        endHour: modulos.horas,
                        end: modulos.end,
                        subModulos: modulos.subModulos,
                        idFormador: data.formadores[0]._id
                    })
                })
                .then(res => res.json())
                .then(
                    (res) => {

                    },
                    (error) => {
                        console.error(error)
                        return
                    }
                )

        }
        async function processModules() {
            for (let ind = 1; ind <= modulo.length; ind++) {
                const modulos = modulo[ind - 1];
                await createModuleAndEvents(modulos, ind);
            }
            eventoStart();
        }
        async function gerarRoteiro() {
            fetch(`${process.env.REACT_APP_API_URL}/modulos/roteiro/formador/${params.id_ufcd}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                },
            })
                .then((res) => res.json())
                .then(
                    (result) => {
                    },
                    (error) => {
                        console.error("Error fetching data: ", error);
                        setError(error);
                    }
                );

        }
        await processModules();
        await gerarRoteiro()
    }

    function eventoStart() {
        let titulo = ""
        let startInput = evento0Day + "T" + evento0Hour
        startInput = startInput + "Z";
        let start = new Date(startInput);
        let end = new Date(start.getTime() + (parseFloat(evento0Duration) * (1000 * 60 * 60))); // add 1 hour to the 'start' time
        let evento = []
        evento.push({
            start: start.toISOString(),
            end: end.toISOString(),
        })
        if (typeEvent != "Misto") {
            setTypeEvent(data.formato)
        }

        if (typeEvent === "Online") {
            titulo = "VC0 - Apresentação";
        } else {
            titulo = "P0 - Apresentação";
        }
        fetch(`${process.env.REACT_APP_API_URL}/calendar/${params.id_ufcd}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
            body: JSON.stringify({
                title: titulo,
                tipo: typeEvent,
                evento: evento,
                id_ufcds: params.id_ufcd,
                color: data.color,
                id_formador: data.formadores[0]._id
            }),
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    toastSuccess("Roteiro Pedagógico criado com sucesso");
                    navigate(-1)
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    }
    // ------------------------------Animações------------------------------------

    function sectionNext() {
        setSection(section + 1);
    }
    function section2Next() {
        setSection2(section2 + 1);
    }
    //Secção anterior
    function sectionBack() {
        setSection(section - 1);
    }
    function section2Back() {
        setSection2(section2 - 1);
    }
    function fade() {
        setTimeout(() => {
            document.querySelector('#main-form').classList.remove('fadeInLeft')
            document.querySelector('#main-form').classList.remove('fadeInRight')
            document.querySelector('#main-form').classList.add('fadeOutLeft')
        }, 0);
        setTimeout(() => {
            document.querySelector('#main-form').classList.remove('fadeOutLeft')
            sectionNext()
            document.querySelector('#main-form').classList.add('fadeInRight')
        }, 500);
    }

    function fadeSection2() {
        setTimeout(() => {
            document.querySelector('#main-form').classList.remove('fadeInLeft')
            document.querySelector('#main-form').classList.remove('fadeInRight')
            document.querySelector('#main-form').classList.add('fadeOutLeft')
        }, 0);
        setTimeout(() => {
            document.querySelector('#main-form').classList.remove('fadeOutLeft')
            section2Next()
            document.querySelector('#main-form').classList.add('fadeInRight')
        }, 500);
    }


    function fadeInRight() {
        setTimeout(() => {
            document.querySelector('#main-form').classList.remove('fadeInRight')
            document.querySelector('#main-form').classList.remove('fadeInLeft')
            document.querySelector('#main-form').classList.add('fadeOutRight')
        }, 0);
        setTimeout(() => {
            document.querySelector('#main-form').classList.remove('fadeOutRight')
            sectionBack()
            document.querySelector('#main-form').classList.add('fadeInLeft')
        }, 500);

    }
    function fadeInRight2() {
        setTimeout(() => {
            document.querySelector('#main-form').classList.remove('fadeInRight')
            document.querySelector('#main-form').classList.remove('fadeInLeft')
            document.querySelector('#main-form').classList.add('fadeOutRight')
        }, 0);
        setTimeout(() => {
            document.querySelector('#main-form').classList.remove('fadeOutRight')
            section2Back()
            document.querySelector('#main-form').classList.add('fadeInLeft')
        }, 500);

    }

    //Retroceder 
    const handlePrevious = () => {
        fadeInRight();
    }
    //Retroceder 
    const handlePreviousModulo = () => {
        fadeInRight2();
    }

    const handleNextModulo = () => {
        fadeSection2()
    }

    //Avançar
    const handleNext = () => {

        //Dados Pessoais        
        if (section === 1) {
            fade()
        }

        //Formato do Curso
        if (section === 2) {
            fade()
        }

        //Formadores
        if (section === 3) {
            fade()
        }
        if (section === 4) {
            fade()
        }

    }
    // ---------------------------------fim Animações------------------------------------

    return (
        <>
            <main className={(token === "user" || token === "coord") ?"min-h-screen grid-cols-7 mx-auto md:grid xl:grid-cols-10":"min-h-screen"}>
                {(token === "user" || token === "coord") ?<div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    {token === "user" && <NavbarFormador currentPage={current} />}
                    {token === "coord" && <NavbarCoordenador currentPage={current} />}
                </div>: <div className="w-full">
                   {token === "admin1" &&  <NavbarAdmin1 currentPage={current} />}
                   {token === "admin2" &&  <NavbarAdmin2 currentPage={current} />}
                </div>}

                <div className={(token === "user" ||token === "coord") ? "relative h-full col-span-5 mx-4 xl:col-span-8": "flex-grow mt-8"}>

                    {(token === "user" ||token === "coord") &&<Header sideMenu={"true"} />
}
                    <div className={(token === "user" || token === "coord") ?"back-button-noNavbar":"flex-grow mt-8"}>
                        <button onClick={() => navigate(-1)} className={(token === "user" || token === "coord") ?"flex w-8 h-8":"back-button-div-noNavbar"}>
                            <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                        </button>
                    </div>

                    <h1 className="mx-4 mt-8 title sm:mt-0">Adicionar Módulo Pedagógico</h1>
                    <form className="relative max-w-2xl px-10 pt-3 mx-4 mb-12 rounded-lg shadow-2xl bg-secundary-color pb-14 lg:mx-auto" id="main-form" onSubmit={submeter}>
                        <section>
                            {section === 1 && (<div>
                                <div>
                                    <div className="text-text-second-color">Número de Módulos:</div>
                                    <input type="number" onWheel={(e) => e.target.blur()} min={1} id="nModulos" name="nModulos" className="mt-2 text-center input-field-long" defaultValue={nModulos} />
                                    <button onClick={(e) => {
                                        let nMod = document.getElementById("nModulos").value;
                                        if (nMod && nMod > 0) {
                                            setNModulos(nMod);
                                            handleNext()
                                        } else toastError("Insira um número de módulos válido.");
                                    }} type="button" className="button-next-form">
                                        <img alt="Next" className="invert" src={Next} />
                                    </button>
                                </div>
                            </div>)}
                        </section>
                        <section>
                            {section === 2 && <div className="flex flex-col items-center">
                                <div className="mt-3 text-center sm:text-left">
                                    <div className="text-xs uppercase text-text-second-color opacity-90">Data Início (Evento de Iniciação):</div>
                                    <input type="date" id={"Evento0Day"} min={formatDateToMinFormat(new Date(data.dateBegin))} max={formatDateToMinFormat(new Date(data.dateEnd))} name="dataInicioeEvento" defaultValue={evento0Day} onChange={(e) => setEvento0Day(e.target.value)} className="mt-2 text-lg input-field w-[13.5rem]"></input>
                                </div>
                                <div className="mt-3 text-center sm:text-left">
                                    <div className="text-xs uppercase text-text-second-color opacity-90">Hora Início (Evento de Iniciação):</div>
                                    <select onWheel={(e) => e.target.blur()} id={"Evento0Hour"} name="dataInicioeEvento" defaultValue={evento0Hour} onChange={(e) => setEvento0Hour(e.target.value)} className="mt-2 text-lg input-field w-52">
                                        <option disabled value="">Selecione uma opção</option>
                                        <option value="08:00">08:00h</option>
                                        <option value="08:30">08:30h</option>
                                        <option value="09:00">09:00h</option>
                                        <option value="09:30">09:30h</option>
                                        <option value="10:00">10:00h</option>
                                        <option value="10:30">10:30h</option>
                                        <option value="11:00">11:00h</option>
                                        <option value="11:30">11:30h</option>
                                        <option value="12:00">12:00h</option>
                                        <option value="14:00">14:00h</option>
                                        <option value="14:30">14:30h</option>
                                        <option value="15:00">15:00h</option>
                                        <option value="15:30">15:30h</option>
                                        <option value="16:00">16:00h</option>
                                        <option value="16:30">16:30h</option>
                                        <option value="17:00">17:00h</option>
                                        <option value="17:30">17:30h</option>
                                        <option value="18:00">18:00h</option>
                                        <option value="18:30">18:30h</option>
                                        <option value="19:00">19:00h</option>
                                        <option value="19:30">19:30h</option>
                                        <option value="20:00">20:00h</option>
                                        <option value="20:30">20:30h</option>
                                        <option value="21:00">21:00h</option>
                                        <option value="21:30">21:30h</option>
                                        <option value="22:00">22:00h</option>
                                        <option value="22:30">22:30h</option>
                                        <option value="23:00">23:00h</option>
                                    </select>
                                </div>
                                <div className="mt-3 text-center sm:text-left">
                                    <div className="text-xs uppercase text-text-second-color opacity-90 w-[13.5rem]">Duração (Evento de Iniciação):</div>
                                    <select onWheel={(e) => e.target.blur()} className="mt-2 text-lg selector w-52" name="sel" id={"duracaoEvento0"} defaultValue={evento0Duration} onChange={(e) => setEvento0Duration(e.target.value)}>
                                        <option disabled value="">Selecione uma opção</option>
                                        <option value="1">1h</option>
                                        <option value="1.5">1:30h</option>
                                        <option value="2">2h</option>
                                        <option value="2.5">2:30h</option>
                                        <option value="3">3h</option>
                                        <option value="3.5">3:30h</option>
                                        <option value="4">4h</option>
                                        <option value="4.5">4:30h</option>
                                        <option value="5">5h</option>
                                    </select>
                                </div>
                                <button onClick={(e) => { handlePrevious() }} type="button" className="button-back-form">
                                    <img alt="Next" className="invert" src={Next} />
                                </button>
                                <button onClick={(e) => {
                                    if (evento0Day) {
                                        if (evento0Hour) {
                                            if (evento0Duration) {
                                                handleNext()
                                            } else toastError("Preencha a duração do evento de iniciação")
                                        } else toastError("Preencha a hora de início do evento de iniciação!")
                                    } else toastError("Preencha a data de ínicio do evento de iniciação")
                                }} type="button" className="button-next-form">
                                    <img alt="Next" className="invert" src={Next} />
                                </button>
                            </div>}
                        </section>
                        <section>
                            {section === 3 && (<RenderList />)}
                        </section>
                        {section === 4 && (<div>
                            <h1 className="subtitle">Criação concluida!</h1>
                            <h2 className="mb-3 text-lg text-text-second-color">Deseja submeter?</h2>
                            <button className="button-geral" type="submit">Submeter</button>
                            <button onClick={handlePrevious} type="button" className="button-back-form"><img className="invert" alt="Back" src={Next} /></button>
                        </div>)}
                    </form>
                    <button onClick={() => navigate(-1)} className="back-button-link">
                        <img className='back-button' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </button>
                </div>
            </main>
            <Footer />
        </>
    );
}

export default GerarModulos;