import React, { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import Footer from "../../../../../components/Footer";
import 'react-toastify/dist/ReactToastify.css';
import NavbarAdmin1 from "../../../../../components/Navbars/NavbarAdmin1/NavbarAdmin1";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";
import AlterarFormato from "../../../../../components/FormChecks/formatDates/foramatDates";
import toastError from "../../../../../components/Toast/toastError";
import NavbarCoordenador from "../../../../../components/Navbars/NavbarCoordenador/navbarCoordenador";
import Header from "../../../../../components/Header";

export default function UFCDsFullListAdmin() {
    const [data, setData] = useState([]);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [entidadesFormadoras, setEntidadesFormadoras] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const savedSearchTerm = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-trainerUfcdHist-1") : "";
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm ? savedSearchTerm : "");
    const savedTabelas = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-trainerUfcdHist-F1") : ""
    const [tabelas, setTabelas] = useState(savedTabelas ? savedTabelas : "2");
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-trainerUfcdHist-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Admin-trainerUfcdHist-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)
    //Data atual
    const year = new Date().getFullYear()
    const month = new Date().getMonth()
    const day = new Date().getDate();

    const dateNow = new Date(year, month, day)

    //filtra dados
    function filterData(data) {
        return data.filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.codeUfcd.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.percurso[0].name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }


    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }


    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }

    const params = useParams();

    document.data = data

    function fetchUfcds(url) {
        fetch(url, {
            headers: {
                'authorization': localStorage.getItem('admin1')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    paginacaoTabelas(filterData(result));
                    const entidades = [];
                    result.forEach(element => {
                        const entityId = element.percurso.length>0 && element.percurso[0].entFormadora[0]._id;

                        if (element.percurso.length>0 && !entidades.some(item => item._id === entityId)) {
                            entidades.push(element.percurso[0].entFormadora[0]);
                        }
                    });
                    setEntidadesFormadoras(entidades)
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    }
    function getUfcds() {
        setLoading(true);
        if (tabelas === "0") {
            fetchUfcds(`${process.env.REACT_APP_API_URL}/ufcds/trainer/recentes/${params.id_candidato}`);
            setLoading(false);
        }
        if (tabelas === "1") {
            fetchUfcds(`${process.env.REACT_APP_API_URL}/ufcds/trainer/antigas/${params.id_candidato}`);
            setLoading(false);
        }
        if (tabelas === "2") {
            fetchUfcds(`${process.env.REACT_APP_API_URL}/ufcds/trainer/${params.id_candidato}/media`);
            setLoading(false);
        }
    }
    function downloadFile(url) {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');

        if ('download' in link) {
            link.setAttribute('download', '');
        }

        document.body.appendChild(link); // Append the link to the DOM

        // Use a timeout to trigger the click after a short delay
        setTimeout(() => {
            link.click();
            document.body.removeChild(link); // Remove the link from the DOM after the click
        }, 100);
    }
    //Submete estado
    async function exportarDeclaracaoExperiencia() {


        const dateBeginUsed = document.getElementById("date-Begin").value;
        const dateEndUsed = document.getElementById("date-End").value;
        const entFormadoraId = document.getElementById("entidades-Formadoras").value;

        if (dateBeginUsed && dateEndUsed && entFormadoraId) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/formadores/declaracaoExperiencia/${params.id_candidato}`, {
                    method: "PATCH",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': localStorage.getItem("admin1")
                    },
                    body: JSON.stringify({
                        dateBeginUsed,
                        dateEndUsed,
                        entFormadoraId,
                    })
                });

                if (response.ok) {
                    const responseData = await response.json();
                    setLoading(true);
                    downloadFile(responseData);
                } else {
                    // Handle error response
                    console.error('Failed to fetch data:', response.statusText);
                    toastError('Failed to fetch data');
                }
            } catch (error) {
                // Handle fetch error
                console.error('Fetch error:', error);
                toastError('An error occurred while fetching data');
            } finally {
                setLoading(false);
            }
        } else {
            toastError("Insira todos os dados");
            setLoading(false);
        }
    }
    useEffect(() => {
        Cookies.set("Admin-trainerUfcdHist-F1", tabelas);
        Cookies.set("Admin-trainerUfcdHist-Pag", paginaAtual);
        Cookies.set("Admin-trainerUfcdHist-1", searchTerm);
        Cookies.set(`Admin-trainerUfcdHist-itm1`, itemsPag)
        getUfcds()
        // eslint-disable-next-line
    }, [loading, tabelas, paginaAtual, nPaginas, searchTerm, tabelas, itemsPag]); 

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }

    return (
        <>
          <main className="min-h-screen">
                <div className="w-full">
                    <NavbarCoordenador currentPage={"ListaFormador"} />
                </div>
                <Link to={'/menucoordenador/' + params.id_candidatura + '/list'} className="back-button-div-noNavbar">
                    <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                </Link>
                <div className="relative h-full col-span-5 mx-4 xl:col-span-8">
                <Header sideMenu={"true"} />
                    <h1 className="title">Histórico do Formador</h1>
                    <div className="search-div">
                        <label className="search-input-label">Pesquisar: </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input type="text" className="search-input" placeholder="ex: Nome, Código..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                        </div>
                    </div>

                    <div className="mt-4 mb-2">
                        <label className="text-text-second-color">Percursos: </label>
                        <select onWheel={(e) => e.target.blur()} className="mr-3 search-selector" name="tabelas" id="tabelas" value={tabelas} onChange={(e) => setTabelas(e.target.value)}>
                            <option value="0">Ativas</option>
                            <option value="1">Concluidas</option>
                            <option value="2">Todas</option>
                        </select>
                    </div>
                    {data && data.length > 0 ? <>
                        <div className="flex justify-center items-center mb-1">
                            <label className="text-text-main-color">Dados por página: </label>
                            <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                value={itemsPag}
                                onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div className="table-container">
                            <table className="my-table">

                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="p-2 min-w-[150px] border border-white">Código da disciplina</th>
                                        <th className="p-2 min-w-[150px] border border-white">Nome</th>
                                        <th className="p-2 min-w-[150px] border border-white">Nome do curso</th>
                                        <th className="p-2 min-w-[150px] border border-white">Data inicio</th>
                                        <th className="p-2 min-w-[150px] border border-white">Data final</th>
                                        <th className="p-2 min-w-[150px] border border-white">Avaliação</th>
                                        <th className="p-2 min-w-[150px] border border-white">Ações</th>
                                    </tr>
                                </thead>
                                {data.length > 0 ? (
                                    <tbody className="text-text-main-color">
                                        {filterData(dataToDisplay).map((item, index) => (
                                            <tr key={index}>
                                                <td className="border border-white">{item.codeUfcd}</td>
                                                <td className="border border-white">{item.name}</td>
                                                <td className="border border-white">{item.percurso[0] ? <div className="ufcd">{item.percurso[0].name}</div> : "Sem curso"}</td>
                                                <td className="border border-white date-input">{AlterarFormato(item.dateBegin)}</td>
                                                <td className="border border-white date-input">{AlterarFormato(item.dateEnd)}</td>
                                                <td className="border border-white">
                                                    {
                                                        (item.evaluation !== undefined && item.evaluation !== null) ?
                                                            item.evaluation + "%" : "Sem Avaliação"
                                                    }
                                                </td>
                                                {item.dateEnd ? (
                                                    <>
                                                        {(item.evaluation === undefined || item.evaluation === null) && (new Date(item.dateEnd) < dateNow || item.dateEnd === null || item.dateEnd === undefined) ?
                                                            <td className="border border-white">
                                                                <Link className="button-geral" to={'/MenuSuperAdmin/list/Full-ufcds-evaluation/' + params.id_candidato + "/" + item._id}>Avaliar</Link>
                                                            </td> :
                                                            <td className="border border-white">Sem Ações</td>}
                                                    </>
                                                ) : (
                                                    <td className="border border-white">Sem data</td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody />
                                )}
                            </table>
                        </div>
                        <div className="flex items-center justify-center mt-4 mb-8">
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                <AiOutlineDoubleLeft />
                            </button>
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                <AiOutlineLeft />
                            </button>
                            <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                <AiOutlineRight />
                            </button>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                <AiOutlineDoubleRight />
                            </button>
                        </div> </> : <p className="third-title">Não existem dados</p>}
                    <div className="mt-4 ">
                        <div className="subtitle mb-4 uppercase">
                            Exportar Declaração de Experiência Formativa
                        </div>
                        <div className="w-128 m-auto">
                            <div className="flex flex-row justify-between items-center">
                                <label htmlFor="date-Begin" className="label-input mx-2">Data de Ínicio:</label>
                                <input className="w-52 input-field2" type="date" id="date-Begin" />
                            </div>
                            <div className="flex flex-row justify-between items-center my-2">
                                <label htmlFor="date-End" className="label-input mx-2">Data de Fim:</label>
                                <input className="w-52 input-field2" type="date" id="date-End" />
                            </div>
                            <div className="flex flex-row justify-between items-center">
                                <label htmlFor="entidades-Formadoras" className="label-input mx-2">Entidade Formadora:</label>
                                <select onWheel={(e) => e.target.blur()} defaultValue="" className="selector w-52" name="entidades-Formadoras" id="entidades-Formadoras">
                                    <option value=""disabled>Selecione uma Entidade Formadora</option>
                                    {entidadesFormadoras.map((item, index) => (
                                        <option key={index} value={item._id}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <button className="w-40 mt-4 mb-6 button-geral" onClick={exportarDeclaracaoExperiencia}>Exportar</button>
                            </div>
                        </div>
                    </div>
                </div>
                </main>
                <Footer />
        </>
    );
}