import NavbarAdmin1 from "../Navbars/NavbarAdmin1/NavbarAdmin1";
import NavbarAdmin2 from "../Navbars/NavbarAdmin2/NavbarAdmin2";
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Footer from "../Footer";

function OperacaoIndividual(token) {
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [percursos, setPercursos] = useState(null);
    const [loading, setLoading] = useState(true);

    const params = useParams();

    useEffect(() => {
        getOperacao()
        getPercursoOperacao()
    }, [loading]);
    //Obtem percurso
    function getOperacao() {
        fetch(`${process.env.REACT_APP_API_URL}/operacao/${params.id_operacao}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
            method: 'GET',
        })
            .then(res => res.json())
            .then((result) => {
                setData(result)
                setLoading(false)
            })
    }

    function getPercursoOperacao() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/operacao/${params.id_operacao}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
            method: 'GET',
        })
            .then(res => res.json())
            .then((result) => {
                setPercursos(result)
                setLoading(false)
            })
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );

    const route = token === "admin1" ? '/MenuAdmin/' + params.id_candidatura : "/MenuSuperAdmin"
    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    {token === "admin1" && <NavbarAdmin1 currentPage={"manageOperacoesAdmin"} />}
                    {token === "admin2" && <NavbarAdmin2 currentPage={"manageOperacoesAdmin"} />}
                </div>
                <Link to={route + "/operacoes"} className="back-button-div-noNavbar md:mt-5">
                    <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                </Link>
                {data && <div className="grid mt-20 gap-x-10 gap-y-5 sm:grid-cols-2">
                    <div className="text-center sm:text-left"><div className="text-xs uppercase text-text-second-color opacity-90">Nome: </div> <div className="mt-2 text-lg text-text-main-color"> {data.nomeTipologia}</div></div>
                    <div className="text-center sm:text-left"><div className="text-xs uppercase text-text-second-color opacity-90">Código: </div> <div className="mt-2 text-lg text-text-main-color"> {data.codigoOperacao}</div></div>
                    <div className="text-center sm:text-left"><div className="text-xs uppercase text-text-second-color opacity-90">Gestor de Operação: </div > <div className="mt-2 text-lg text-text-main-color">{data.admin.length > 0 ? data.admin.map((admin, index) => (
                        <span key={index}>{admin.fullName}{index < data.admin.length - 1 ? ', ' : ''}</span>
                    )) : 'No admins available'}</div>
                    </div>
                </div>}

                {percursos && <>
                    <h1 className="mt-10 mb-5 subtitle">Cursos/Percursos Associados</h1>
                    <div className="table-containermax-w-lg">
                        <table className="my-table ">
                            <thead className="bg-main-color">
                                <tr className="text-white h-14">
                                    <th className="p-2 min-w-[150px] border border-white">Cursos/Percursos</th>
                                </tr>
                            </thead>
                            <tbody className="text-text-main-color">
                                {percursos.map((item, index) => (
                                    <tr key={index}>
                                        <td className="border border-white">{item.name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="table-containermax-w-lg">
                        <h1 className="mt-10 mb-5 subtitle">Indicadores</h1>
                        <table className="my-table ">
                            <thead className="bg-main-color">
                                <tr className="text-white h-14">
                                    <th className="p-2 min-w-[150px] border border-white">Objetivos Contratualizados</th>
                                    <th className="p-2 min-w-[150px] border border-white">Previsto Candidatura</th>
                                    <th className="p-2 min-w-[150px] border border-white">Em Execução</th>
                                    <th className="p-2 min-w-[150px] border border-white">Finalizado</th>
                                    <th className="p-2 min-w-[150px] border border-white">Em Falta</th>
                                </tr>
                            </thead>
                            <tbody className="text-text-main-color">
                                <tr>
                                    <td className="border border-white">Nº Participantes</td>
                                    <td className="border border-white">{data.participantesPrev && data.participantesPrev}</td>
                                    <td className="border border-white">0</td>
                                    <td className="border border-white">0</td>
                                    <td className="border border-white">{data.participantesPrev && data.participantesPrev - 0}</td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Nº Encaminhados</td>
                                    <td className="border border-white">{data.encaminhadosPrev && data.encaminhadosPrev}</td>
                                    <td className="border border-white">0</td>
                                    <td className="border border-white">0</td>
                                    <td className="border border-white">{data.encaminhadosPrev && data.encaminhadosPrev - 0}</td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Taxa Certificação</td>
                                    <td className="border border-white">{data.taxaCerti && data.taxaCerti}</td>
                                    <td className="border border-white">0</td>
                                    <td className="border border-white">0</td>
                                    <td className="border border-white">{data.taxaCerti && data.taxaCerti}</td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Volume Formação</td>
                                    <td className="border border-white">{data.volumeFormacao && data.volumeFormacao}</td>
                                    <td className="border border-white">0</td>
                                    <td className="border border-white">0</td>
                                    <td className="border border-white">{data.volumeFormacao && data.volumeFormacao}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="table-containermax-w-lg mb-10">
                        <h1 className="mt-10 mb-5 subtitle">Análise</h1>
                        <div className="flex flex col gap-3">
                            <div>
                                <h2 className="label">Data Inicio:</h2>
                                <input type="date" className="input-field" defaultValue={new Date().toISOString().substring(0, 10)} />
                            </div>
                            <div>
                                <h2 className="label">Data Fim:</h2>
                                <input type="date" className="input-field" defaultValue={new Date().toISOString().substring(0, 10)} />
                            </div>
                        </div>
                        <table className="my-table ">
                            <thead className="bg-main-color">
                                <tr className="text-white h-14">
                                    <th className="p-2 min-w-[150px] border border-white">Objetivos Operacionais</th>
                                    <th className="p-2 min-w-[150px] border border-white"></th>
                                </tr>
                            </thead>
                            <tbody className="text-text-main-color">
                                <tr>
                                    <td className="border border-white">Avaliação Satisfação Formandos</td>
                                    <td className="border border-white"></td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Avaliação Satisfação Formadores</td>
                                    <td className="border border-white"></td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Avaliação Satisfação Formandos</td>
                                    <td className="border border-white"></td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Desempenho dos formadores</td>
                                    <td className="border border-white"></td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Desempenho coordenadores e mediadores</td>
                                    <td className="border border-white"></td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Taxa de Desistência</td>
                                    <td className="border border-white"></td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Taxa de Assiduidade</td>
                                    <td className="border border-white"></td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Avaliação de Impacto</td>
                                    <td className="border border-white"></td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Nº Participantes</td>
                                    <td className="border border-white"></td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Nº Encaminhados</td>
                                    <td className="border border-white"></td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Taxa de Certificados</td>
                                    <td className="border border-white"></td>
                                </tr>
                                <tr>
                                    <td className="border border-white">Volume de Formação</td>
                                    <td className="border border-white"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>}
            </main >
            <Footer />
        </>
    );
}

export default OperacaoIndividual