import {useParams} from "react-router-dom";
import gerirTurma from "../../../../components/gerirTurmas/gerirTurmas";
import 'react-toastify/dist/ReactToastify.css';
function Turmas() {
    const params = useParams();
    return(
        gerirTurma("coord", (`/menucoordenador/${params.id_candidatura}/turmas/turma/${params.id_curso}/`),"turmas")
    )
}

export default Turmas