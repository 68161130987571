export default function ArrayNations() {
    const countriesArray = [
        "191 - Portugal",
        "1 - Afeganistão",
        "2 - África do Sul",
        "3 - Albânia",
        "4 - Alemanha",
        "5 - Andorra",
        "6 - Angola",
        "7 - Anguila",
        "8 - Antártida",
        "9 - Antígua e Barbuda",
        "10 - Antilhas Holandesas",
        "11 - Arábia Saudita",
        "12 - Argélia",
        "13 - Argentina",
        "14 - Arménia",
        "15 - Aruba",
        "16 - Austrália",
        "17 - Áustria",
        "18 - Azerbaijão",
        "19 - Bahamas",
        "20 - Bahrein",
        "21 - Bangladesh",
        "22 - Barbados",
        "23 - Bielorrússia",
        "24 - Bélgica",
        "25 - Belize",
        "26 - Benin",
        "27 - Bermudas",
        "28 - Bolívia",
        "29 - Bósnia-Herzegóvina",
        "30 - Botsuana",
        "31 - Brasil",
        "32 - Brunei",
        "33 - Bulgária",
        "34 - Burkina Fasso",
        "35 - Burundi",
        "36 - Butão",
        "37 - Cabo Verde",
        "38 - Camarões",
        "39 - Camboja",
        "40 - Canadá",
        "41 - Cazaquistão",
        "42 - Chade",
        "43 - Chile",
        "44 - China",
        "45 - Chipre",
        "46 - Singapura",
        "47 - Colômbia",
        "48 - Congo",
        "49 - Coreia do Norte",
        "50 - Coreia do Sul",
        "51 - Costa do Marfim",
        "52 - Costa Rica",
        "53 - Croácia (Hrvatska)",
        "54 - Cuba",
        "55 - Dinamarca",
        "56 - Djibuti",
        "57 - Dominica",
        "58 - Egito",
        "59 - El Salvador",
        "60 - Emirados Árabes Unidos",
        "61 - Equador",
        "62 - Eritréia",
        "63 - Eslováquia",
        "64 - Eslovénia",
        "65 - Espanha",
        "66 - Estados Unidos",
        "67 - Estônia",
        "68 - Etiópia",
        "69 - Federação Russa",
        "70 - Fiji",
        "71 - Filipinas",
        "72 - Finlândia",
        "73 - França",
        "74 - Gabão",
        "75 - Gâmbia",
        "76 - Gana",
        "77 - Geórgia",
        "78 - Gibraltar",
        "79 - Reino Unido",
        "80 - Granada",
        "81 - Grécia",
        "82 - Gronelândia",
        "83 - Guadalupe",
        "84 - Guam (Território dos Estados Unidos)",
        "85 - Guatemala",
        "86 - Guernsey",
        "87 - Guiana",
        "88 - Guiana Francesa",
        "89 - Guiné",
        "90 - Guiné Equatorial",
        "91 - Guiné-Bissau",
        "92 - Haiti",
        "93 - Holanda",
        "94 - Honduras",
        "95 - Hong Kong",
        "96 - Hungria",
        "97 - Iêmen",
        "98 - Ilha Bouvet (Território da Noruega)",
        "99 - Ilha do Homem",
        "100 - Ilha Natal",
        "101 - Ilha Pitcairn",
        "102 - Ilha Reunião",
        "103 - Ilhas Aland",
        "104 - Ilhas Cayman",
        "105 - Ilhas Cocos",
        "106 - Ilhas Comores",
        "107 - Ilhas Cook",
        "108 - Ilhas Falkland (Malvinas)",
        "109 - Ilhas Faroes",
        "110 - Ilhas Geórgia do Sul e Sandwich do Sul",
        "111 - Ilhas Heard e McDonald (Território da Austrália)",
        "112 - Ilhas Marianas do Norte",
        "113 - Ilhas Marshall",
        "114 - Ilhas Menores dos Estados Unidos",
        "115 - Ilhas Norfolk",
        "116 - Ilhas Seychelles",
        "117 - Ilhas Solomão",
        "118 - Ilhas Svalbard e Jan Mayen",
        "119 - Ilhas Tokelau",
        "120 - Ilhas Turks e Caicos",
        "121 - Ilhas Virgens (Estados Unidos)",
        "122 - Ilhas Virgens (Inglaterra)",
        "123 - Ilhas Wallis e Futuna",
        "124 - Índia",
        "125 - Indonésia",
        "126 - Irã",
        "127 - Iraque",
        "128 - Irlanda",
        "129 - Islândia",
        "130 - Israel",
        "131 - Itália",
        "132 - Jamaica",
        "133 - Japão",
        "134 - Jersey",
        "135 - Jordânia",
        "136 - Kênia",
        "137 - Kiribati",
        "138 - Kuait",
        "139 - Kyrgyzstan",
        "140 - Laos",
        "141 - Látvia",
        "142 - Lesoto",
        "143 - Líbano",
        "144 - Libéria",
        "145 - Líbia",
        "146 - Liechtenstein",
        "147 - Lituânia",
        "148 - Luxemburgo",
        "149 - Macau",
        "150 - Macedônia (República Yugoslava)",
        "151 - Madagascar",
        "152 - Malásia",
        "153 - Malaui",
        "154 - Maldivas",
        "155 - Mali",
        "156 - Malta",
        "157 - Marrocos",
        "158 - Martinica",
        "159 - Maurício",
        "160 - Mauritânia",
        "161 - Mayotte",
        "162 - México",
        "163 - Micronésia",
        "164 - Moçambique",
        "165 - Moldova",
        "166 - Mônaco",
        "167 - Mongólia",
        "168 - Montenegro",
        "169 - Montserrat",
        "170 - Myanma (Ex-Burma)",
        "171 - Namíbia",
        "172 - Nauru",
        "173 - Nepal",
        "174 - Nicarágua",
        "175 - Níger",
        "176 - Nigéria",
        "177 - Niue",
        "178 - Noruega",
        "179 - Nova Caledônia",
        "180 - Nova Zelândia",
        "181 - Omã",
        "182 - Palau",
        "183 - Panamá",
        "184 - Papua-Nova Guiné",
        "185 - Paquistão",
        "186 - Paraguai",
        "187 - Peru",
        "188 - Polinésia Francesa",
        "189 - Polônia",
        "190 - Porto Rico",
        "192 - Qatar",
        "193 - República Centro-Africana",
        "194 - República Democrática do Congo (ex-Zaire)",
        "195 - República Dominicana",
        "196 - República Tcheca",
        "197 - Romênia",
        "198 - Ruanda",
        "199 - Saara Ocidental (Ex-Spanish Sahara)",
        "200 - Saint Vincente e Granadinas",
        "201 - Samoa Americana",
        "202 - Samoa Ocidental",
        "203 - San Marino",
        "204 - Santa Helena",
        "205 - Santa Lúcia",
        "206 - São Bartolomeu",
        "207 - São Cristóvão e Névis",
        "208 - São Martim",
        "209 - São Tomé e Príncipe",
        "210 - Senegal",
        "211 - Serra Leoa",
        "212 - Sérvia",
        "213 - Síria",
        "214 - Somália",
        "215 - Sri Lanka",
        "216 - St. Pierre and Miquelon",
        "217 - Suazilândia",
        "218 - Sudão",
        "219 - Suécia",
        "220 - Suíça",
        "221 - Suriname",
        "222 - Tadjiquistão",
        "223 - Tailândia",
        "224 - Taiwan",
        "225 - Tanzânia",
        "226 - Território Britânico do Oceano Índico",
        "227 - Territórios do Sul da França",
        "228 - Territórios Palestinos Ocupados",
        "229 - Timor Leste (Ex-East Timor)",
        "230 - Togo",
        "231 - Tonga",
        "232 - Trinidad and Tobago",
        "233 - Tunísia",
        "234 - Turcomenistão",
        "235 - Turquia",
        "236 - Tuvalu",
        "237 - Ucrânia",
        "238 - Uganda",
        "239 - Uruguai",
        "240 - Uzbequistão",
        "241 - Vanuatu",
        "242 - Vaticano",
        "243 - Venezuela",
        "244 - Vietnã",
        "245 - Zâmbia",
        "246 - Zimbábue"
    ];
    return countriesArray
} 
