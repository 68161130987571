import * as XLSX from 'xlsx';

async function ExcelFormadores(formadores) {
    const response = await fetch(process.env.PUBLIC_URL + '/bolsaFormadores.xlsx');
    const arrayBuffer = await response.arrayBuffer();
    const data = new Uint8Array(arrayBuffer);
    const workbook = XLSX.read(data, { type: 'array' });
    const sortedData = formadores.slice().sort((a, b) => a.fullName.localeCompare(b.fullName));
    // Get the target worksheet
    const worksheet = workbook.Sheets['Lista de Formadores'];
    function setMultipleColWidth(worksheet, startCol, endCol, width) {
        if (!worksheet['!cols']) worksheet['!cols'] = [];

        for (let col = startCol; col <= endCol; col++) {
            if (!worksheet['!cols'][col]) worksheet['!cols'][col] = {};
            worksheet['!cols'][col].wch = width;
        }
    }
    setMultipleColWidth(worksheet, 0, 28,20);
    // Make the first row bold and add borders
    for (let col = 0; col <= 27; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
        const cell = worksheet[cellAddress];
        if (cell) {
            if (!cell.s) cell.s = {};
            cell.s.font = { bold: true };
            cell.s.border = {
                top: { style: 'thin', color: { auto: 1 } },
                bottom: { style: 'thin', color: { auto: 1 } },
                left: { style: 'thin', color: { auto: 1 } },
                right: { style: 'thin', color: { auto: 1 } },
            };
        }
    }
    const convertDateFormat2 = (originalDate) => {
        // Split the date into an array using the dash as a separator
        const dateArray = originalDate.split('-');
        // Rearrange the array elements to the desired format
        const formattedDate = `${dateArray[2]}`+`/${dateArray[1]}`+`/${dateArray[0]}`;
        return formattedDate;
    };
    
    const jsonData = sortedData.map(element => ({
        NomeCompleto: element.fullName,
        DataNascimento: convertDateFormat2(element.birthDate),
        Ntelemovel: element.cellNumber,
        Email: element.email,
        Naturalidade: element.parish,
        Localidade: element.locality,
        Concelho: element.county,
        Morada: element.address,
        CodigoPostal: element.postalCode,
        Nacionalidade: element.nationality,
        NumeroDoDocumento: element.ccNumber,
        ValidadeDoc: convertDateFormat2(element.ccExpiration),
        NIF: element.nif,
        CAP: element.cap,
        GrauAcademico: element.academicDegree,
        Curso: element.courseDesignation,
        AnoConclusao: element.conclusionYear,
        Profissao: element.office,
        IVA:element.iva === 1 ? "Sim": "Não",
        IRS:element.irs? "Sim": "Não",
        IBAN:element.iban,
        AreaFormacao1: element.area[0],
        AreaFormacao2: element.area[1],
        AreaFormacao3: element.area[2],
        AreaFormacao4: element.area[3],
        AreaFormacao5: element.area[4],
        AreaFormacao6: element.area[5],
        AreaFormacao7: element.area[6],
        AreaFormacao8: element.area[7],
        AreaFormacao9: element.area[8],
        AreaFormacao10: element.area[9],

    }));
    // Convert JSON data to worksheet format
    /* const jsonSheet = XLSX.utils.json_to_sheet(jsonData); */

    // Append the JSON data to the existing worksheet
    const updatedWorksheet = XLSX.utils.sheet_add_json(worksheet, jsonData, { skipHeader: true, origin: { r: 1, c: 0 } });

    // Update the workbook with the modified worksheet
    workbook.Sheets['Lista de Formadores'] = updatedWorksheet;

    // Write the updated workbook to a new file
    const updatedFileBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const updatedFileData = new Blob([updatedFileBuffer], { type: 'application/octet-stream' });
    const downloadUrl = URL.createObjectURL(updatedFileData);

    // Create a link element to trigger the file download
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'Formadores.xlsx'; // Specify the desired filename for the downloaded file
    link.click();

    // Cleanup: Revoke the download URL
    URL.revokeObjectURL(downloadUrl);
};
export default ExcelFormadores;