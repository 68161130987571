import styled from "styled-components";

export const AreaPassword = styled.div`

main.container{
    padding: 30px;
    background-color: white;
    box-sizing: border-box;
    
}

.title-pass{
    font-size: 1em;
    padding: 1em;
}

.new-pass{
    font-size: 20px;
    font-family: 'Lato';
    padding: 1em;
}

main input[type=password]{
    width: 70%;
    margin: 7px 0px;
    display: inline-block;
    padding: 12px 25px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid lightgrey;
    font-size: 1em;
    font-family: 'Lato';
    background: white;
}

main input[type=text]{
    width: 70%;
    margin: 7px 0px;
    display: inline-block;
    padding: 12px 25px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid lightgrey;
    font-size: 1em;
    font-family: 'Lato';
    background: white;
}

main .button-submit{
    margin-top: 20px;
    background-color: rgb(27, 152, 255);
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 6em;
    height: 3em;
    font-size: 1em;
}

main .button-submit:hover{
    background-color: rgb(17, 101, 170);
}

.password-input-wraper{
    position: relative;
}
.eye-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  .eye-button-container {
  position: relative;
}

.eye-button:focus {
  outline: none;
}

.eye-button i {
  font-size: 1.2rem;
  color: #aaa;
}

.eye-button:hover i {
  color: #333;
}

`