import styled from "styled-components";

export const MenuFormando = styled.div`
    
justify-content: center;
font-family: "Lato";
display: flex;
max-height: 300px;


.App{
    width: 800px;
    z-index: 0;
  }
  
  table, td, th{
    border: 1px solid black;
    background-color: white
  }
}  
#link-box {
  width: 150px;
  height: 200px;
  display: none;
  float: right;
  border: 3px solid #000000;
}

.toggle:focus~#link-box {
 display: block;
}

#link-box > ul > li {
 list-style: none;
 padding: 10px;
}

#link-box > ul > li > a {
 text-decoration: none;
 color: #000000;
}
button.toggle {
    border: 3px solid #000000;
    background: #fff;
    padding: 7px 15px;
    margin-right: 10px;
}
`;
