const Nacoes = (value, type) => {
    const countries = [
        { number: 191, value: 'Portugal', label: 'Português' },
        { number: 1, value: 'Afeganistão', label: 'Afegão' },
        { number: 2, value: 'África do Sul', label: 'Sul-africano' },
        { number: 3, value: 'Albânia', label: 'Albanês' },
        { number: 4, value: 'Alemanha', label: 'Alemão' },
        { number: 5, value: 'Andorra', label: 'Andorrano' },
        { number: 6, value: 'Angola', label: 'Angolano' },
        { number: 7, value: 'Anguila', label: 'Anguilano' },
        { number: 8, value: 'Antártida', label: 'Antártico' },
        { number: 9, value: 'Antígua e Barbuda', label: 'Antiguano' },
        { number: 10, value: 'Antilhas Holandesas', label: 'Neerlandês' },
        { number: 11, value: 'Arábia Saudita', label: 'Saudita' },
        { number: 12, value: 'Argélia', label: 'Argelino' },
        { number: 13, value: 'Argentina', label: 'Argentino' },
        { number: 14, value: 'Armênia', label: 'Armênio' },
        { number: 15, value: 'Aruba', label: 'Arubano' },
        { number: 16, value: 'Austrália', label: 'Australiano' },
        { number: 17, value: 'Áustria', label: 'Austríaco' },
        { number: 18, value: 'Azerbaijão', label: 'Azeri' },
        { number: 19, value: 'Bahamas', label: 'Bahamiano' },
        { number: 20, value: 'Bahrein', label: 'Barenita' },
        { number: 21, value: 'Bangladesh', label: 'Bangladechiano' },
        { number: 22, value: 'Barbados', label: 'Barbadense' },
        { number: 23, value: 'Bielorrússia', label: 'Bielorrusso' },
        { number: 24, value: 'Bélgica', label: 'Belga' },
        { number: 25, value: 'Belize', label: 'Belizenho' },
        { number: 26, value: 'Benin', label: 'Beninense' },
        { number: 27, value: 'Bermudas', label: 'Bermudense' },
        { number: 28, value: 'Bolívia', label: 'Boliviano' },
        { number: 29, value: 'Bósnia e Herzegovina', label: 'Bósnio' },
        { number: 30, value: 'Botsuana', label: 'Botsuanês' },
        { number: 31, value: 'Brasil', label: 'Brasileiro' },
        { number: 32, value: 'Brunei', label: 'Bruneíno' },
        { number: 33, value: 'Bulgária', label: 'Búlgaro' },
        { number: 34, value: 'Burkina Faso', label: 'Burquinês' },
        { number: 35, value: 'Burundi', label: 'Burundiano' },
        { number: 36, value: 'Butão', label: 'Butanês' },
        { number: 37, value: 'Cabo Verde', label: 'Cabo-verdiano' },
        { number: 38, value: 'Camarões', label: 'Camaroneso' },
        { number: 39, value: 'Camboja', label: 'Cambojano' },
        { number: 40, value: 'Canadá', label: 'Canadense' },
        { number: 41, value: 'Cazaquistão', label: 'Cazaque' },
        { number: 42, value: 'Chade', label: 'Chadiano' },
        { number: 43, value: 'Chile', label: 'Chileno' },
        { number: 44, value: 'China', label: 'Chinês' },
        { number: 45, value: 'Chipre', label: 'Cipriota' },
        { number: 46, value: 'Singapura', label: 'Cingapuriano' },
        { number: 47, value: 'Colômbia', label: 'Colombiano' },
        { number: 48, value: 'Congo', label: 'Congolês' },
        { number: 49, value: 'Coreia do Norte', label: 'Norte-coreano' },
        { number: 50, value: 'Coreia do Sul', label: 'Sul-coreano' },
        { number: 51, value: 'Costa do Marfim', label: 'Costamarfinense' },
        { number: 52, value: 'Costa Rica', label: 'Costarriquenho' },
        { number: 53, value: 'Croácia', label: 'Croata' },
        { number: 54, value: 'Cuba', label: 'Cubano' },
        { number: 55, value: 'Dinamarca', label: 'Dinamarquês' },
        { number: 56, value: 'Djibuti', label: 'Djibutiano' },
        { number: 57, value: 'Dominica', label: 'Dominicano' },
        { number: 58, value: 'Egito', label: 'Egípcio' },
        { number: 59, value: 'El Salvador', label: 'Salvadorenho' },
        { number: 60, value: 'Emirados Árabes Unidos', label: 'Emirati' },
        { number: 61, value: 'Equador', label: 'Equatoriano' },
        { number: 62, value: 'Eritreia', label: 'Eritreu' },
        { number: 63, value: 'Eslováquia', label: 'Eslovaco' },
        { number: 64, value: 'Eslovênia', label: 'Esloveno' },
        { number: 65, value: 'Espanha', label: 'Espanhol' },
        { number: 66, value: 'Estados Unidos', label: 'Americano' },
        { number: 67, value: 'Estônia', label: 'Estónio' },
        { number: 68, value: 'Etiópia', label: 'Etíope' },
        { number: 69, value: 'Federação Russa', label: 'Russo' },
        { number: 70, value: 'Fiji', label: 'Fijiano' },
        { number: 71, value: 'Filipinas', label: 'Filipino' },
        { number: 72, value: 'Finlândia', label: 'Finlandês' },
        { number: 73, value: 'França', label: 'Francês' },
        { number: 74, value: 'Gabão', label: 'Gabonês' },
        { number: 75, value: 'Gâmbia', label: 'Gambiano' },
        { number: 76, value: 'Gana', label: 'Ganense' },
        { number: 77, value: 'Geórgia', label: 'Georgiano' },
        { number: 78, value: 'Gibraltar', label: 'Gibraltariano' },
        { number: 79, value: 'Reino Unido', label: 'Britânico' },
        { number: 80, value: 'Granada', label: 'Granadino' },
        { number: 81, value: 'Grécia', label: 'Grego' },
        { number: 82, value: 'Gronelândia', label: 'Groenlandês' },
        { number: 83, value: 'Guadalupe', label: 'Guadalupense' },
        { number: 84, value: 'Guam (Território dos Estados Unidos)', label: 'Guamês' },
        { number: 85, value: 'Guatemala', label: 'Guatemalteco' },
        { number: 86, value: 'Guernsey', label: 'Guernesiano' },
        { number: 87, value: 'Guiana', label: 'Guianense' },
        { number: 88, value: 'Guiana Francesa', label: 'Guianense Francês' },
        { number: 89, value: 'Guiné', label: 'Guineano' },
        { number: 90, value: 'Guiné Equatorial', label: 'Guineense Equatorial' },
        { number: 91, value: 'Guiné-Bissau', label: 'Guineense' },
        { number: 92, value: 'Haiti', label: 'Haitiano' },
        { number: 93, value: 'Holanda', label: 'Holandês' },
        { number: 94, value: 'Honduras', label: 'Hondurenho' },
        { number: 95, value: 'Hong Kong', label: 'Hong Konguês' },
        { number: 96, value: 'Hungria', label: 'Húngaro' },
        { number: 97, value: 'Iêmen', label: 'Iemenita' },
        { number: 98, value: 'Ilha Bouvet (Território da Noruega)', label: 'Bouvetiano' },
        { number: 99, value: 'Ilha do Homem', label: 'Manês' },
        { number: 100, value: 'Ilha Natal', label: 'Natalense' },
        { number: 101, value: 'Ilha Pitcairn', label: 'Pitcairnense' },
        { number: 102, value: 'Ilha Reunião', label: 'Reunionense' },
        { number: 103, value: 'Ilhas Aland', label: 'Alandês' },
        { number: 104, value: 'Ilhas Cayman', label: 'Caimanês' },
        { number: 105, value: 'Ilhas Cocos', label: 'Cocense' },
        { number: 106, value: 'Ilhas Comores', label: 'Comoriano' },
        { number: 107, value: 'Ilhas Cook', label: 'Cookiano' },
        { number: 108, value: 'Ilhas Falkland (Malvinas)', label: 'Falklandiano' },
        { number: 109, value: 'Ilhas Faroes', label: 'Faroense' },
        { number: 110, value: 'Ilhas Geórgia do Sul e Sandwich do Sul', label: 'Georgiano do Sul e Sandwichês' },
        { number: 109, value: 'Ilhas Faroes', label: 'Faroense' },
        { number: 110, value: 'Ilhas Geórgia do Sul e Sandwich do Sul', label: 'Georgiano do Sul e Sandwichês' },
        { number: 111, value: 'Ilhas Heard e McDonald (Território da Austrália)', label: 'Heard e McDonaldense' },
        { number: 112, value: 'Ilhas Marianas do Norte', label: 'Marianense do Norte' },
        { number: 113, value: 'Ilhas Marshall', label: 'Marshallense' },
        { number: 114, value: 'Ilhas Menores dos Estados Unidos', label: 'Ilhas Menores dos EUA' },
        { number: 115, value: 'Ilhas Norfolk', label: 'Norfolkense' },
        { number: 116, value: 'Ilhas Seychelles', label: 'Seichelense' },
        { number: 117, value: 'Ilhas Solomão', label: 'Salomônense' },
        { number: 118, value: 'Ilhas Svalbard e Jan Mayen', label: 'Svalbardense e Jan Mayenês' },
        { number: 119, value: 'Ilhas Tokelau', label: 'Tokelauano' },
        { number: 120, value: 'Ilhas Turks e Caicos', label: 'Turquês e Caicosiano' },
        { number: 121, value: 'Ilhas Virgens (Estados Unidos)', label: 'Virgino (EUA)' },
        { number: 122, value: 'Ilhas Virgens (Inglaterra)', label: 'Virgino (Inglaterra)' },
        { number: 123, value: 'Ilhas Wallis e Futuna', label: 'Wallisiano e Futunano' },
        { number: 124, value: 'Índia', label: 'Indianês' },
        { number: 125, value: 'Indonésia', label: 'Indonésio' },
        { number: 126, value: 'Irã', label: 'Iraniano' },
        { number: 127, value: 'Iraque', label: 'Iraquiano' },
        { number: 128, value: 'Irlanda', label: 'Irlandês' },
        { number: 129, value: 'Islândia', label: 'Islandês' },
        { number: 130, value: 'Israel', label: 'Israelita' },
        { number: 131, value: 'Itália', label: 'Italiano' },
        { number: 132, value: 'Jamaica', label: 'Jamaicano' },
        { number: 133, value: 'Japão', label: 'Japonês' },
        { number: 134, value: 'Jersey', label: 'Jersiano' },
        { number: 135, value: 'Jordânia', label: 'Jordaniano' },
        { number: 136, value: 'Kênia', label: 'Queniano' },
        { number: 137, value: 'Kiribati', label: 'Kiribatiano' },
        { number: 138, value: 'Kuwait', label: 'Kuwaitiano' },
        { number: 139, value: 'Kyrgyzstan', label: 'Quirguiz' },
        { number: 140, value: 'Laos', label: 'Laosiano' },
        { number: 141, value: 'Látvia', label: 'Letão' },
        { number: 142, value: 'Lesoto', label: 'Lesotiano' },
        { number: 143, value: 'Líbano', label: 'Libanês' },
        { number: 144, value: 'Libéria', label: 'Liberiano' },
        { number: 145, value: 'Líbia', label: 'Líbio' },
        { number: 146, value: 'Liechtenstein', label: 'Liechtensteiniano' },
        { number: 147, value: 'Lituânia', label: 'Lituano' },
        { number: 148, value: 'Luxemburgo', label: 'Luxemburguês' },
        { number: 149, value: 'Macau', label: 'Macaense' },
        { number: 150, value: 'Macedônia (República Yugoslava)', label: 'Macedônio' },
        { number: 151, value: 'Madagascar', label: 'Malgaxe' },
        { number: 152, value: 'Malásia', label: 'Malaio' },
        { number: 153, value: 'Malaui', label: 'Malauiense' },
        { number: 154, value: 'Maldivas', label: 'Maldivo' },
        { number: 155, value: 'Mali', label: 'Maliano' },
        { number: 156, value: 'Malta', label: 'Maltês' },
        { number: 157, value: 'Marrocos', label: 'Marroquino' },
        { number: 158, value: 'Martinica', label: 'Martinicano' },
        { number: 159, value: 'Maurício', label: 'Mauriciano' },
        { number: 160, value: 'Mauritânia', label: 'Mauritano' },
        { number: 161, value: 'Mayotte', label: 'Maiotês' },
        { number: 162, value: 'Mexico', label: 'Mexicano' },
        { number: 163, value: 'Micronésia', label: 'Micronésio' },
        { number: 164, value: 'Moçambique', label: 'Moçambicano' },
        { number: 165, value: 'Moldova', label: 'Moldavo' },
        { number: 166, value: 'Mônaco', label: 'Monegasco' },
        { number: 167, value: 'Mongólia', label: 'Mongol' },
        { number: 168, value: 'Montenegro', label: 'Montenegrino' },
        { number: 169, value: 'Montserrat', label: 'Montserratense' },
        { number: 170, value: 'Myanma (Ex-Burma)', label: 'Mianmarense' },
        { number: 171, value: 'Namíbia', label: 'Namibiano' },
        { number: 172, value: 'Nauru', label: 'Nauruano' },
        { number: 173, value: 'Nepal', label: 'Nepalês' },
        { number: 174, value: 'Nicarágua', label: 'Nicaraguense' },
        { number: 175, value: 'Níger', label: 'Nigerino' },
        { number: 176, value: 'Nigéria', label: 'Nigeriano' },
        { number: 177, value: 'Niue', label: 'Niuano' },
        { number: 178, value: 'Noruega', label: 'Norueguês' },
        { number: 179, value: 'Nova Caledônia', label: 'Neocaledôniano' },
        { number: 180, value: 'Nova Zelândia', label: 'Neozelandês' },
        { number: 181, value: 'Omã', label: 'Omanense' },
        { number: 182, value: 'Palau', label: 'Palauano' },
        { number: 183, value: 'Panamá', label: 'Panamenho' },
        { number: 184, value: 'Papua-Nova Guiné', label: 'Papuásio' },
        { number: 185, value: 'Paquistão', label: 'Paquistanês' },
        { number: 186, value: 'Paraguai', label: 'Paraguaio' },
        { number: 187, value: 'Peru', label: 'Peruano' },
        { number: 188, value: 'Polinésia Francesa', label: 'Polinésio' },
        { number: 189, value: 'Polônia', label: 'Polonês' },
        { number: 190, value: 'Porto Rico', label: 'Porto-riquenho' },
        { number: 192, value: 'Qatar', label: 'Catariano' },
        { number: 193, value: 'República Centro-Africana', label: 'Centro-africano' },
        { number: 194, value: 'República Democrática do Congo (ex-Zaire)', label: 'Congoleso' },
        { number: 195, value: 'República Dominicana', label: 'Dominicano' },
        { number: 196, value: 'República Tcheca', label: 'Tcheco' },
        { number: 197, value: 'Romênia', label: 'Romeno' },
        { number: 198, value: 'Ruanda', label: 'Ruandês' },
        { number: 199, value: 'Saara Ocidental (Ex-Spanish Sahara)', label: 'Saarauiano' },
        { number: 200, value: 'Saint Vincente e Granadinas', label: 'São-vicentino' },
        { number: 201, value: 'Samoa Americana', label: 'Samoano Americano' },
        { number: 202, value: 'Samoa Ocidental', label: 'Samoano' },
        { number: 203, value: 'San Marino', label: 'São-marinense' },
        { number: 204, value: 'Santa Helena', label: 'Santa-helenense' },
        { number: 205, value: 'Santa Lúcia', label: 'Santa-lucense' },
        { number: 206, value: 'São Bartolomeu', label: 'São-bartolomeense' },
        { number: 207, value: 'São Cristóvão e Névis', label: 'São-cristovense' },
        { number: 208, value: 'São Martim', label: 'São-martinhense' },
        { number: 209, value: 'São Tomé e Príncipe', label: 'São-tomense' },
        { number: 210, value: 'Senegal', label: 'Senegalês' },
        { number: 211, value: 'Serra Leoa', label: 'Serra-leonês' },
        { number: 212, value: 'Sérvia', label: 'Sérvio' },
        { number: 213, value: 'Síria', label: 'Sírio' },
        { number: 214, value: 'Somália', label: 'Somali' },
        { number: 215, value: 'Sri Lanka', label: 'Cingalês' },
        { number: 216, value: 'St. Pierre and Miquelon', label: 'Saint-pierrês e Miquelonês' },
        { number: 217, value: 'Suazilândia', label: 'Swazilandês' },
        { number: 218, value: 'Sudão', label: 'Sudanês' },
        { number: 219, value: 'Suécia', label: 'Sueco' },
        { number: 220, value: 'Suíça', label: 'Suíço' },
        { number: 221, value: 'Suriname', label: 'Surinamês' },
        { number: 222, value: 'Tadjiquistão', label: 'Tajique' },
        { number: 223, value: 'Tailândia', label: 'Tailandês' },
        { number: 224, value: 'Taiwan', label: 'Taiwanês' },
        { number: 225, value: 'Tanzânia', label: 'Tanzaniano' },
        { number: 226, value: 'Território Britânico do Oceano Índico', label: 'Britânico' },
        { number: 227, value: 'Territórios do Sul da França', label: 'Francês' },
        { number: 228, value: 'Territórios Palestinos Ocupados', label: 'Palestino' },
        { number: 229, value: 'Timor-Leste', label: 'Timorense' },
        { number: 230, value: 'Togo', label: 'Togolês' },
        { number: 231, value: 'Tonga', label: 'Tonganês' },
        { number: 232, value: 'Trindade e Tobago', label: 'Trinitário-tobaguense' },
        { number: 233, value: 'Tunísia', label: 'Tunisino' },
        { number: 234, value: 'Turcomenistão', label: 'Turcomano' },
        { number: 235, value: 'Turquia', label: 'Turco' },
        { number: 236, value: 'Tuvalu', label: 'Tuvaluano' },
        { number: 237, value: 'Ucrânia', label: 'Ucraniano' },
        { number: 238, value: 'Uganda', label: 'Ugandense' },
        { number: 239, value: 'Uruguai', label: 'Uruguaio' },
        { number: 240, value: 'Uzbequistão', label: 'Uzbeque' },
        { number: 241, value: 'Vanuatu', label: 'Vanuatuense' },
        { number: 242, value: 'Vaticano', label: 'Vaticano' },
        { number: 243, value: 'Venezuela', label: 'Venezuelano' },
        { number: 244, value: 'Vietnã', label: 'Vietnamita' },
        { number: 245, value: 'Zâmbia', label: 'Zambiano' },
        { number: 246, value: 'Zimbábue', label: 'Zimbabuense' }
    ];


    if (type === "true") {
        const targetCountry = countries.find(country => country.label === value);
        const defaultLabel = targetCountry ? `${targetCountry.number} - ${targetCountry.label} ` : null;
        return defaultLabel;
    }
    else {
        const targetCountry = countries.find(country => value.includes(country.value));
        const defaultLabel = targetCountry ? targetCountry.label : null;

        return defaultLabel;
    }

};

export default Nacoes;