import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Footer from "../Footer";
import ModalUpload from "../Modal/ModalUpload"
import 'react-toastify/dist/ReactToastify.css';
import NavbarAdmin2 from "../Navbars/NavbarAdmin2/NavbarAdmin2";
import { FiDownload, FiUpload } from "react-icons/fi";
import ModalDownload from "../Modal/ModalDownload";
import ModalDownloadUFCD from "../Modal/ModalDownloadUFCD";
import excelGenerator from "../Excel/cronogramaDisciplina";
import excelGeneratorClone from "../Excel/cronogramaGeralClone";
import ModalUploadUFCD from "../Modal/ModalUploadUFCD";
import excelGeneratorPauta from "../Pauta/pauta";
import ModalUploadFormandos from "../Modal/ModalUploadFormando";
import ModalDownloadFormandos from "../Modal/ModalDownloadFormando";
import ModalUploadEventos from "../Modal/ModalUploadEventos";
import ModalDownloadEventos from "../Modal/ModalDownloadEventos";
import { CgFileDocument } from "react-icons/cg"
import { RiFileEditLine } from "react-icons/ri";
import { PiMicrosoftExcelLogo } from "react-icons/pi";
import NavbarAdmin1 from "../Navbars/NavbarAdmin1/NavbarAdmin1";
import NavbarCoordenador from "../Navbars/NavbarCoordenador/navbarCoordenador";
import NavbarFormador from "../Navbars/NavbarFormador/navbarFormador";
import Header from "../Header";
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import ModalUploadDTPCompleto from "../Modal/ModalUploadDTPCompleto";
import ModalDownloadDTPCompleto from "../Modal/ModalDownloadDTPCompleto";
import PlanoDeSessao from "../Excel/excelPlanoDeSessao/excelPlanoDeSessao";

function GerirDTP(token, type) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingDownloadDTP, setLoadingDownloadDTP] = useState(true);
    const [eventos, setEventos] = useState(null);
    const [tabOppened, setTabOppened] = useState(0); //tabs
    const [subTabOppened, setSubTabOppened] = useState(0); //tabs
    const [sub2TabOppened, setSub2TabOppened] = useState(0); //tabs
    const [sub3TabOppened, setSub3TabOppened] = useState(0); //tabs
    const [sub4TabOppened, setSub4TabOppened] = useState(0); //tabs
    const [cont, setCont] = useState(0);
    const [contDTP, setContDTP] = useState(0);
    const params = useParams();
    const navigate = useNavigate();
    const [files, setFiles] = useState([]);
    const [zipFileName, setZipFileName] = useState('');
    //Obter Percurso
    //Obtem percurso
    function getPercurso() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}/`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
            method: 'GET',
        })
            .then(res => res.json())
            .then((result) => {
                setData(result)

            })
    }
    function GerarDTP() {
        async function downloadFilesAsZip() {
            await setLoadingDownloadDTP(false)
            const zipFileName = data.name;
            const zip = new JSZip();
            const folders = {};

            let topico1 = [];
            let topico2 = [];
            let topico3 = [];
            let topico4 = [];
            let topico5 = [];
            let topico6 = [];
            let topico7 = [];
            let topico8 = [];
            let topico9 = [];
            let topico10 = [];

            // Topico1
            async function Topico1() {
                if (data.indice.topico1 && data.indice.topico1.includes("Check List de Divulgação/Publicidade")) {
                    try {
                        const response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/ChecklistDivulgacao/${params.id_curso}`, {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'authorization': localStorage.getItem(token)
                            },
                        });

                        if (!response.ok) {
                            throw new Error('Network response was not ok.');
                        }

                        const result = await response.json();
                        topico1.push({ name: "CheckListDivulgaçãoTemplate", file: result.divulgacaoPublicidade_path, folder: "CheckListDivulgação" });
                        if (data.divulgacaoPublicidade && data.divulgacaoPublicidade.length > 0) {
                            data.divulgacaoPublicidade.forEach(element => {
                                topico1.push({ ...element, folder: "CheckListDivulgação" });
                            });
                        }

                    } catch (error) {
                        console.error('Error:', error);

                    }
                }
                if (data.indice.topico1 && data.indice.topico1.includes("Check List de verificação das instalações, equipamentos e materiais")) {
                    try {
                        const response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/checklistInstalacoes/${params.id_curso}`, {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'authorization': localStorage.getItem(token)
                            },
                        });

                        if (!response.ok) {
                            throw new Error('Network response was not ok.');
                        }

                        const result = await response.json();
                        topico1.push({ name: "CheckListInstalaçõesTemplate", file: result.checklistInstalacoes_path, folder: "CheckListInstalações" });
                        if (data.checklistInstalacoes && data.checklistInstalacoes.length > 0) {
                            data.checklistInstalacoes.forEach(element => {
                                topico1.push({ ...element, folder: "CheckListInstalações" });
                            });
                        }

                    }
                    catch (error) {
                        console.error('Error:', error);

                    }
                }
                if (data.indice.topico1 && data.indice.topico1.includes("Cronograma Específico")) {
                    data.ufcds.forEach(async element => {

                        await excelGenerator(element, true)
                        await topico1.push({ name: "CronogramaTemplate", file: element.cronogramaEspecifico_path, folder: `CronogramaEspecífico/${element.name}` });
                        if (element.cronogramaEspecifico && element.cronogramaEspecifico.length > 0) {
                            element.cronogramaEspecifico.forEach(element => {
                                topico1.push({ ...element, folder: `CronogramaEspecífico/${element.name}` });
                            });
                        }

                    });
                }
                if (data.indice.topico1 && data.indice.topico1.includes("Ficha Técnica do curso/Programa")) {
                    try {
                        const response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/checklistInstalacoes/${params.id_curso}`, {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'authorization': localStorage.getItem(token)
                            },
                        });

                        if (!response.ok) {
                            throw new Error('Network response was not ok.');
                        }

                        const result = await response.json();
                        topico1.push({ name: "FichaTecnicaTemplate", file: result.fichaTecnicaCurso_path, folder: "FichaTécnica" });
                        if (data.fichaTecnica && data.fichaTecnica.length > 0) {
                            data.fichaTecnica.forEach(element => {
                                topico1.push({ ...element, folder: "FichaTecnicaTemplate" });
                            });
                        }

                    }
                    catch (error) {
                        console.error('Error:', error);

                    }
                }
                if (data.indice.topico1 && data.indice.topico1.includes("Ficha Técnica do curso/Programa")) {


                    topico1.push({ name: "CronogramaOriginal", file: data.cronogramaOriginal, folder: "Cronograma" });
                    if (data.cronograma && data.cronograma.length > 0) {
                        data.cronograma.forEach(element => {
                            topico1.push({ ...element, folder: "Cronograma" });
                        });
                    }


                }
                try {
                    for (const fileLink of topico1) {
                        if (!fileLink) continue; // Skip if fileLink is undefined or null

                        const response = await fetch(fileLink.file);
                        if (!response.ok) {
                            throw new Error('Failed to fetch file content.');
                        }

                        const fileContent = await response.blob();
                        let fileName
                        if (response.url.includes(".xlsx")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".xlsx";

                        }
                        else if (response.url.includes(".pdf")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".pdf";
                        }
                        else if (response.url.includes(".csv")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".csv";
                        }

                        const folderName = "Funcionamento&Organização/" + fileLink.folder;

                        if (!folders[folderName]) {
                            folders[folderName] = zip.folder(folderName);
                        }

                        folders[folderName].file(fileName, fileContent);
                    }


                } catch (error) {
                    console.error('Error:', error);
                }
            }

            // Topico2
            async function Topico2() {
                if (data.indice.topico2 && data.indice.topico2.includes("Apólice de Seguro")) {
                    if (data.apoliceSeguro && data.apoliceSeguro.length > 0) {
                        data.apoliceSeguro.forEach(element => {
                            topico2.push({ ...element, folder: "ApoliceSeguro" });
                        });
                    }
                }
                if (data.indice.topico2 && data.indice.topico2.includes("Check List de verificação dos documentos e elegibilidade dos formandos")) {
                    try {
                        const response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/controloDocsFormandos/${params.id_curso}`, {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'authorization': localStorage.getItem(token)
                            },
                        });

                        if (!response.ok) {
                            throw new Error('Network response was not ok.');
                        }

                        const result = await response.json();
                        topico2.push({ name: "CheckListControloDocsFormandosTemplate", file: result.controloDocsFormandos_path, folder: "CheckListControloDocsFormandos" });
                        if (data.fichaRegisto && data.fichaRegisto.length > 0) {
                            data.fichaRegisto.forEach(element => {
                                topico2.push({ ...element, folder: "CheckListControloDocsFormandos" });
                            });
                        }

                    }
                    catch (error) {
                        console.error('Error:', error);

                    }
                }
                if (data.indice.topico2 && data.indice.topico2.includes("Contratos de formação do organismo intermédio/financiador")) {
                    if (data.contratosFormacaoOrganismo && data.contratosFormacaoOrganismo.length > 0) {
                        data.contratosFormacaoOrganismo.forEach(element => {
                            topico2.push({ ...element, folder: "contratosFormacaoOrganismo" });
                        });
                    }
                }
                if (data.indice.topico2 && data.indice.topico2.includes("Contratos de formação dos formandos")) {
                    try {
                        data.formandos.forEach(async element => {
                            if ((element.valid === "Validado" || element.valid === "Desistiu") && element.contrato_path) {
                                await topico2.push({ name: "ContratoTemplate", file: element.contrato_path, folder: `Contratos/${element.fullName}` });
                                if (element.contrato && element.contrato.length > 0) {
                                    element.contrato.forEach(element => {
                                        topico2.push({ ...element, folder: `Contratos/${element.fullName}` });
                                    });
                                }
                            }

                        });
                    }
                    catch (error) {
                        console.error('Error:', error);

                    }
                }
                if (data.indice.topico2 && data.indice.topico2.includes("Ficha de registo do processo de seleção dos formandos")) {
                    try {
                        const response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/notaExplicativaFormandos/${params.id_curso}`, {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'authorization': localStorage.getItem(token)
                            },
                        });

                        if (!response.ok) {
                            throw new Error('Network response was not ok.');
                        }

                        const result = await response.json();
                        topico2.push({ name: "RegistoProcessoSeleçãoFormandos", file: result.NotaExplicativaSelecaoFormandos_path, folder: "RegistoProcessoSeleçãoFormandos" });

                    }
                    catch (error) {
                        console.error('Error:', error);

                    }
                }
                if (data.indice.topico2 && data.indice.topico2.includes("Fichas de inscrição")) {
                    try {
                        for (const element of data.formandos) {
                            if ((element.valid === "Validado" || element.valid === "Desistiu")) {
                                const response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/FichadeIncricao/${params.id_curso}/formando/${element._id}`, {
                                    method: 'GET',
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json',
                                        'authorization': localStorage.getItem(token)
                                    },
                                });

                                if (!response.ok) {
                                    throw new Error('Network response was not ok.');
                                }

                                const result = await response.json();
                                topico2.push({ name: "FichaInscricaoTemplate", file: result.fichaInscricao_path, folder: `FichaInscricao/${element.fullName}` });
                                if (element.fichaInscricao && element.fichaInscricao.length > 0) {
                                    element.fichaInscricao.forEach(element => {
                                        topico2.push({ ...element, folder: `FichaInscricao/${element.fullName}` });
                                    });
                                }
                            }

                        };
                    }
                    catch (error) {
                        console.error('Error:', error);

                    }
                }
                if (data.indice.topico2 && data.indice.topico2.includes("Listagem de Formandos (c/ dados para seguro)")) {
                    try {
                        for (const element of data.ufcds) {
                            const response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/gerarListagemSeguro/${params.id_curso}/${element._id}`, {
                                method: 'GET',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'authorization': localStorage.getItem(token)
                                },
                            });

                            if (!response.ok) {
                                throw new Error('Network response was not ok.');
                            }

                            const result = await response.json();
                            topico2.push({ name: `ListagemSeguros`, file: result.listagemSeguros_path, folder: `ListagemSeguros/${element.codeUfcd}-${element.name}` });
                        }
                    } catch (error) {
                        console.error('Error:', error);
                    }
                }
                try {
                    for (const fileLink of topico2) {
                        if (!fileLink) continue; // Skip if fileLink is undefined or null

                        const response = await fetch(fileLink.file);
                        if (!response.ok) {
                            throw new Error('Failed to fetch file content.');
                        }
                        let fileName
                        const fileContent = await response.blob();
                        if (response.url.includes(".xlsx")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".xlsx";

                        }
                        else if (response.url.includes(".pdf")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".pdf";
                        }
                        else if (response.url.includes(".csv")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".csv";
                        }

                        const folderName = "Formandos/" + fileLink.folder;

                        if (!folders[folderName]) {
                            folders[folderName] = zip.folder(folderName);
                        }

                        folders[folderName].file(fileName, fileContent);
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            }

            // Topico3
            async function Topico3() {
                if (data.indice.topico31 && data.indice.topico31.includes("Check List de verificação dos documentos dos formadores")) {
                    try {
                        const response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/controloDocsFormadores/${params.id_curso}`, {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'authorization': localStorage.getItem(token)
                            },
                        });

                        if (!response.ok) {
                            throw new Error('Network response was not ok.');
                        }

                        const result = await response.json();
                        topico3.push({ name: "CheckListVerificaçãoFormadoresTemplate", file: result.controloDocsFormadores_path, folder: "Formadores/CheckListVerificaçãoFormadores" });
                        if (data.verificacaoDocumentosFormadores && data.verificacaoDocumentosFormadores.length > 0) {
                            data.verificacaoDocumentosFormadores.forEach(element => {
                                topico3.push({ ...element, folder: "Formadores/CheckListVerificaçãoFormadores" });
                            });
                        }

                    } catch (error) {
                        console.error('Error:', error);

                    }
                }
                if (data.indice.topico31 && data.indice.topico31.includes("Contrato de Prestação de Serviços dos formadores")) {
                    try {
                        data.ufcds.forEach(async element => {
                            await topico3.push({ name: "ContratoTemplate", file: element.contrato_path, folder: `Formadores/Contrato` });
                            if (element.contratoPrestacaoServicos && element.contratoPrestacaoServicos.length > 0) {
                                element.contratoPrestacaoServicos.forEach(element => {
                                    topico3.push({ ...element, folder: `Formadores/Contrato` });
                                });
                            }

                        });
                    }
                    catch (error) {
                        console.error('Error:', error);

                    }
                }
                if (data.indice.topico31 && data.indice.topico31.includes("Contrato de Prestação de Serviços dos formadores do organismo intermédio/financiador")) {

                    if (data.contratoPrestacaoServicosFormadoresOrganismo && data.contratoPrestacaoServicosFormadoresOrganismo.length > 0) {
                        data.contratoPrestacaoServicosFormadoresOrganismo.forEach(element => {
                            topico3.push({ ...element, folder: `Formadores/ContratoFormadoresOrganismo` });
                        });
                    }

                }
                if (data.indice.topico31 && data.indice.topico31.includes("Ficha de registo do processo de seleção dos/as formadores/as")) {
                    try {
                        const response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/notaExplicativaFormadores/${params.id_curso}`, {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'authorization': localStorage.getItem(token)
                            },
                        });

                        if (!response.ok) {
                            throw new Error('Network response was not ok.');
                        }

                        const result = await response.json();
                        topico3.push({ name: "FichaRegistoProcesosFormadoresTemplate", file: result.NotaExplicativaSelecaoFormadores_path, folder: "Formadores/FichaRegistoProcesos" });
                        if (data.fichaRegistoProcessoSelecao && data.fichaRegistoProcessoSelecao.length > 0) {
                            data.fichaRegistoProcessoSelecao.forEach(element => {
                                topico3.push({ ...element, folder: "Formadores/FichaRegistoProcesos" });
                            });
                        }

                    }
                    catch (error) {
                        console.error('Error:', error);

                    }
                }
                if (data.indice.topico32 && data.indice.topico32.includes("Listagem dos Coordenadores/Mediadores/Consultores de Ligação")) {
                    try {
                        const response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/listagemEquipaPedagogica/${params.id_curso}`, {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'authorization': localStorage.getItem(token)
                            },
                        });

                        if (!response.ok) {
                            throw new Error('Network response was not ok.');
                        }

                        const result = await response.json();
                        topico3.push({ name: "ListagemEquipaPedagógica", file: result.listagemEquipaPedagogica_path, folder: "PessoalNãoDocente/ListagemEquipaPedagógica" });

                    }
                    catch (error) {
                        console.error('Error:', error);

                    }

                }
                if (data.indice.topico32 && data.indice.topico32.includes("Contrato de Prestação de Serviços dos Coordenadores/ Mediadores/ Consultor de Ligação")) {
                    try {
                        topico3.push({ name: "ContratoTemplate", file: data.contrato_path, folder: "PessoalNãoDocente/Contrato" });
                        if (data.contrato && data.contrato.length > 0) {
                            data.contrato.forEach(element => {
                                topico3.push({ ...element, folder: "PessoalNãoDocente/Contrato" });
                            });
                        }

                    }
                    catch (error) {
                        console.error('Error:', error);

                    }

                }
                try {
                    for (const fileLink of topico3) {
                        if (!fileLink) continue; // Skip if fileLink is undefined or null

                        const response = await fetch(fileLink.file);
                        if (!response.ok) {
                            throw new Error('Failed to fetch file content.');
                        }

                        const fileContent = await response.blob();
                        let fileName
                        if (response.url.includes(".xlsx")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".xlsx";

                        }
                        else if (response.url.includes(".pdf")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".pdf";
                        }
                        else if (response.url.includes(".csv")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".csv";
                        }

                        const folderName = "Formadores&PessoalNãoDocente/" + fileLink.folder;

                        if (!folders[folderName]) {
                            folders[folderName] = zip.folder(folderName);
                        }

                        folders[folderName].file(fileName, fileContent);
                    }


                } catch (error) {
                    console.error('Error:', error);
                }
            }

            // Topico4
            async function Topico4() {
                if (data.indice.topico4 && data.indice.topico4.includes("Folhas de Sumários e Presenças")) {
                    try {
                        for (const element of data.ufcds) {
                            if (element.eventos.length > 0) {
                                for (const evento of element.eventos) {
                                    let response;
                                    if (data.assinaturas === true) {
                                        response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/folhaDePresencasNorteFor/${element._id}/${evento.title}`, {
                                            method: 'GET',
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json',
                                                'authorization': localStorage.getItem(token)
                                            },
                                        });
                                    } else if (data.assinaturas === false && element.formato === "Online") {
                                        response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/folhaDePresencas/${element._id}/${evento.title}`, {
                                            method: 'GET',
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json',
                                                'authorization': localStorage.getItem(token)
                                            },
                                        });
                                    } else {
                                        response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/folhaDePresencasPresencial/${element._id}/${evento.title}`, {
                                            method: 'GET',
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json',
                                                'authorization': localStorage.getItem(token)
                                            },
                                        });
                                    }
                                    if (!response.ok) {
                                        throw new Error('Network response was not ok.');
                                    }

                                    const result = await response.json();
                                    topico4.push({ name: `FolhaPresencas${result.title}Template`, file: result.folhaPresencas_path, folder: `FolhaPresencas/${element.name}-${element.codeUfcd}/${result.title}` });
                                    if (result.folhaPresencas && result.folhaPresencas.length > 0) {
                                        result.folhaPresencas.forEach(folha => {
                                            topico4.push({ ...folha, folder: `FolhaPresencas/${element.name}-${element.codeUfcd}/${result.title}` });
                                        });
                                    }

                                }

                            }
                        }
                    } catch (error) {
                        console.error('Error:', error);

                    }
                }
                if (data.indice.topico4 && data.indice.topico4.includes("Justificação de faltas")) {
                    try {
                        const response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/FolhaJustificacaoFaltas/${params.id_curso}`, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'authorization': localStorage.getItem(token)
                            },
                        });

                        if (!response.ok) {
                            throw new Error('Network response was not ok.');
                        }

                        const result = await response.json();
                        topico4.push({ name: "JustificacaoFaltasTemplate", file: result.justificacao_path, folder: "JustificacaoFaltas" });
                        if (data.justificacaoFaltas && data.justificacaoFaltas.length > 0) {
                            data.justificacaoFaltas.forEach(element => {
                                topico4.push({ ...element, folder: "JustificacaoFaltas" });
                            });
                        }

                    }
                    catch (error) {
                        console.error('Error:', error);

                    }
                }

                /* if (data.indice.topico4 && data.indice.topico4.includes("Mapas de Assiduidade Mensal/Apoios Sociais")) {
                    try {
                        const response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/checklistInstalacoes/${params.id_curso}`, {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'authorization': localStorage.getItem(token)
                            },
                        });
        
                        if (!response.ok) {
                            throw new Error('Network response was not ok.');
                        }
        
                        const result = await response.json();
                        topico4.push({ name: "FichaTecnicaTemplate", file: result.fichaTecnicaCurso_path, folder: "FichaTécnica" });
                        if (data.fichaTecnica && data.fichaTecnica.length > 0) {
                            data.fichaTecnica.forEach(element => {
                                topico4.push({ ...element, folder: "FichaTecnicaTemplate" });
                            });
                        }
                        
                    }
                    catch (error) {
                        console.error('Error:', error);
                        
                    }
                } */
                if (data.indice.topico4 && data.indice.topico4.includes("Planos de sessão")) {
                    try {
                        for (const element of data.ufcds) {
                            if (element.eventos.length > 0) {
                                for (const evento of element.eventos) {
                                    const response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/planoDeSessao/${params.id_curso}/formador/${element.formadores[0]._id}/evento/${evento._id}`, {
                                        method: 'POST',
                                        headers: {
                                            'Accept': 'application/json',
                                            'Content-Type': 'application/json',
                                            'authorization': localStorage.getItem(token)
                                        },
                                    });

                                    if (!response.ok) {
                                        throw new Error('Network response was not ok.');
                                    }

                                    const result = await response.json();
                                    topico4.push({ name: `PlanoDeSessão${element.name}${result.title}Template`, file: result.sessao_path, folder: `PlanoDeSessão/${element.name}-${element.codeUfcd}/${result.title}` });
                                    if (result.planosSessao && result.planosSessao.length > 0) {
                                        result.planosSessao.forEach(folha => {
                                            topico4.push({ ...folha, folder: `PlanoDeSessão/${element.name}-${element.codeUfcd}/${result.title}` });
                                        });
                                    }

                                }

                            }
                        }
                    } catch (error) {
                        console.error('Error:', error);

                    }

                }
                if (data.indice.topico4 && data.indice.topico4.includes("Roteiros Pedagógicos")) {
                    data.ufcds.forEach(async element => {
                        await topico1.push({ name: `RoteiroPedagogico/${element.name}-${element.codeUfcd}`, file: element.roteiroPedagogico, folder: `RoteiroPedagogico/${element.name}-${element.codeUfcd}` });

                    });
                }
                try {
                    for (const fileLink of topico4) {
                        if (!fileLink) continue; // Skip if fileLink is undefined or null

                        const response = await fetch(fileLink.file);
                        if (!response.ok) {
                            throw new Error('Failed to fetch file content.');
                        }

                        const fileContent = await response.blob();
                        let fileName
                        if (response.url.includes(".xlsx")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".xlsx";

                        }
                        else if (response.url.includes(".pdf")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".pdf";
                        }
                        else if (response.url.includes(".csv")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".csv";
                        }

                        const folderName = "Sumários&Presenças/" + fileLink.folder;

                        if (!folders[folderName]) {
                            folders[folderName] = zip.folder(folderName);
                        }

                        folders[folderName].file(fileName, fileContent);
                    }


                } catch (error) {
                    console.error('Error:', error);
                }
            }

            // Topico5
            async function Topico5() {
                if (data.indice.topico5 && data.indice.topico5.includes("Manuais/Textos e documentação de apoio")) {
                    if (data.manuaisTextosDocumentacao && data.manuaisTextosDocumentacao.length > 0) {
                        data.manuaisTextosDocumentacao.forEach(element => {
                            topico5.push({ ...element, folder: "ManuaisTextosEDocumentacaoDeApoio" });
                        });
                    }

                }
                if (data.indice.topico5 && data.indice.topico5.includes("Outros documentos")) {
                    if (data.outrosDocumentos && data.outrosDocumentos.length > 0) {
                        data.outrosDocumentos.forEach(element => {
                            topico5.push({ ...element, folder: "OutrosDocumentos" });
                        });
                    }

                }
                try {
                    for (const fileLink of topico5) {
                        if (!fileLink) continue; // Skip if fileLink is undefined or null

                        const response = await fetch(fileLink.file);
                        if (!response.ok) {
                            throw new Error('Failed to fetch file content.');
                        }

                        const fileContent = await response.blob();
                        let fileName
                        if (response.url.includes(".xlsx")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".xlsx";

                        }
                        else if (response.url.includes(".pdf")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".pdf";
                        }
                        else if (response.url.includes(".csv")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".csv";
                        }

                        const folderName = "MateriaisDeApoio/" + fileLink.folder;

                        if (!folders[folderName]) {
                            folders[folderName] = zip.folder(folderName);
                        }

                        folders[folderName].file(fileName, fileContent);
                    }


                } catch (error) {
                    console.error('Error:', error);
                }
            }

            // Topico6
            async function Topico6() {

                if (data.indice.topico6 && data.indice.topico6.includes("Evidências de acompanhamento da ação/ Ficha-relatório ou fotos (presencial)")) {
                    if (data.evidenciasAcopanhamentoAcao && data.evidenciasAcopanhamentoAcao.length > 0) {
                        data.evidenciasAcopanhamentoAcao.forEach(element => {
                            topico6.push({ ...element, folder: "EvidenciasDeAcopanhamentoDaAcao" });
                        });
                    }

                }
                if (data.indice.topico6 && data.indice.topico6.includes("Evidências de coordenação da Equipa Pedagógicas/Atas ou outras evidências (presencial)")) {

                    try {
                        const response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/Ata/${params.id_curso}`, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'authorization': localStorage.getItem(token)
                            },
                        });

                        if (!response.ok) {
                            throw new Error('Network response was not ok.');
                        }

                        const result = await response.json();
                        topico6.push({ name: "ATATemplate", file: result.ata_path, folder: "EvidenciasDeCoordenação" });
                        if (data.evidenciasCoordenacaoEquipa && data.evidenciasCoordenacaoEquipa.length > 0) {
                            data.evidenciasCoordenacaoEquipa.forEach(element => {
                                topico6.push({ ...element, folder: "EvidenciasDeCoordenação" });
                            });
                        }

                    }
                    catch (error) {
                        console.error('Error:', error);

                    }
                }
                if (data.indice.topico6 && data.indice.topico6.includes("Ficha de desistências")) {

                    try {
                        const response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/FolhadeDesistencia/${params.id_curso}`, {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'authorization': localStorage.getItem(token)
                            },
                        });

                        if (!response.ok) {
                            throw new Error('Network response was not ok.');
                        }

                        const result = await response.json();
                        topico6.push({ name: "FolhaDeDesistênciaTemplate", file: result, folder: "FolhasDeDesistência" });
                        if (data.fichaDesistencias && data.fichaDesistencias.length > 0) {
                            data.fichaDesistencias.forEach(element => {
                                topico6.push({ ...element, folder: "FolhasDeDesistência" });
                            });
                        }

                    }
                    catch (error) {
                        console.error('Error:', error);

                    }
                }
                if (data.indice.topico6 && data.indice.topico6.includes("Ficha de ocorrências")) {
                    try {
                        const response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/FolhaOcorrencias/${params.id_curso}`, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'authorization': localStorage.getItem(token)
                            },
                        });

                        if (!response.ok) {
                            throw new Error('Network response was not ok.');
                        }

                        const result = await response.json();
                        topico6.push({ name: "FolhaDeOcorrênciasTemplate", file: result.ocorrencias_path, folder: "FolhasDeOcorrências" });
                        if (data.fichaOcorrencias && data.fichaOcorrencias.length > 0) {
                            data.fichaOcorrencias.forEach(element => {
                                topico6.push({ ...element, folder: "FolhasDeOcorrências" });
                            });
                        }

                    }
                    catch (error) {
                        console.error('Error:', error);

                    }
                }
                try {
                    for (const fileLink of topico6) {
                        if (!fileLink) continue; // Skip if fileLink is undefined or null

                        const response = await fetch(fileLink.file);
                        if (!response.ok) {
                            throw new Error('Failed to fetch file content.');
                        }

                        const fileContent = await response.blob();
                        let fileName
                        if (response.url.includes(".xlsx")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".xlsx";

                        }
                        else if (response.url.includes(".pdf")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".pdf";
                        }
                        else if (response.url.includes(".csv")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".csv";
                        }

                        const folderName = "AcompanhamentoDaFormação/" + fileLink.folder;

                        if (!folders[folderName]) {
                            folders[folderName] = zip.folder(folderName);
                        }

                        folders[folderName].file(fileName, fileContent);
                    }


                } catch (error) {
                    console.error('Error:', error);
                }
            }

            // Topico7
            async function Topico7() {
                try {
                    if (data.indice.topico7 && data.indice.topico7.includes("Atividades/Trabalhos/Exercícios (presencial)")) {
                        if (data.atividadesTrabalhosExercicios && data.atividadesTrabalhosExercicios.length > 0) {
                            data.atividadesTrabalhosExercicios.forEach(element => {
                                topico7.push({ ...element, folder: "AtividadesTrabalhosExercicios" });
                            });
                        }
                    }

                    if (data.indice.topico7 && data.indice.topico7.includes("Avaliação diagnóstica")) {
                        if (data.avaliacaoDiagnostica && data.avaliacaoDiagnostica.length > 0) {
                            data.avaliacaoDiagnostica.forEach(element => {
                                topico7.push({ ...element, folder: "AvaliacaoDiagnostica" });
                            });
                        }
                    }

                    if (data.indice.topico7 && data.indice.topico7.includes("Grelhas de Cotação de Prova")) {
                        if (data.grelhasCotacaoProva && data.grelhasCotacaoProva.length > 0) {
                            data.grelhasCotacaoProva.forEach(element => {
                                topico7.push({ ...element, folder: "GrelhasDeCotacaoDeProva" });
                            });
                        }
                    }

                    if (data.indice.topico7 && data.indice.topico7.includes("Testes/Atividades/Trabalhos/Exercícios e corrigendas")) {
                        for (const element of data.ufcds) {
                            try {
                                const response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/FichadeCorrigenda/${params.id_curso}/${element._id}`, {
                                    method: 'GET',
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json',
                                        'authorization': localStorage.getItem(token)
                                    },
                                });

                                if (!response.ok) {
                                    throw new Error('Network response was not ok.');
                                }

                                const result = await response.json();
                                topico7.push({ name: "CorrigendaTemplate", file: result.corrigenda_path, folder: `Corrigenda/${element.name}-${element.codeUfcd}` });

                                if (element.testesAtividadesTrabalhosExercicios && element.testesAtividadesTrabalhosExercicios.length > 0) {
                                    element.testesAtividadesTrabalhosExercicios.forEach(corrigenda => {
                                        topico7.push({ ...corrigenda, folder: `Corrigenda/${element.name}-${element.codeUfcd}` });
                                    });
                                }

                            } catch (error) {
                                console.error('Error:', error);

                            }
                        }
                    }

                    if (data.indice.topico7 && data.indice.topico7.includes("Pautas de Avaliação")) {
                        for (const element of data.ufcds) {
                            try {
                                if (element.pautasAvaliacao && element.pautasAvaliacao.length > 0) {
                                    element.pautasAvaliacao.forEach(element => {
                                        topico7.push({ ...element, folder: `Pauta/${element.name}-${element.codeUfcd}` });
                                    });
                                }

                            } catch (error) {
                                console.error('Error:', error);

                            }
                        }
                    }

                    // Assuming folders and zip are defined somewhere
                    for (const fileLink of topico7) {
                        if (!fileLink) continue; // Skip if fileLink is undefined or null

                        const response = await fetch(fileLink.file);
                        if (!response.ok) {
                            throw new Error('Failed to fetch file content.');
                        }

                        const fileContent = await response.blob();
                        let fileName;
                        if (response.url.includes(".xlsx")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".xlsx";
                        } else if (response.url.includes(".pdf")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".pdf";
                        } else if (response.url.includes(".csv")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".csv";
                        }

                        const folderName = "AvaliaçãoDaAprendizagem/" + fileLink.folder;

                        if (!folders[folderName]) {
                            folders[folderName] = zip.folder(folderName);
                        }

                        folders[folderName].file(fileName, fileContent);
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            }

            // Topico8
            async function Topico8() {
                if (data.indice.topico8 && data.indice.topico8.includes("Avaliação do/a formando/a")) {
                    if (data.avaliacaoFormando && data.avaliacaoFormando.length > 0) {
                        data.avaliacaoFormando.forEach(element => {
                            topico8.push({ ...element, folder: "AvaliacaoFormando" });
                        });
                    }

                }
                if (data.indice.topico8 && data.indice.topico8.includes("Ficha de assiduidade do/a formando/a")) {
                    if (data.fichaAssiduidadeFormando && data.fichaAssiduidadeFormando.length > 0) {
                        data.fichaAssiduidadeFormando.forEach(element => {
                            topico8.push({ ...element, folder: "FichaAssiduidadeFormando" });
                        });
                    }

                }
                if (data.indice.topico8 && data.indice.topico8.includes("Ficha de reunião com entidade acolhedora")) {
                    if (data.fichaReuniaoEntidade && data.fichaReuniaoEntidade.length > 0) {
                        data.fichaReuniaoEntidade.forEach(element => {
                            topico8.push({ ...element, folder: "FichaReuniaoEntidade" });
                        });
                    }

                }
                if (data.indice.topico8 && data.indice.topico8.includes("Outros documentos referentes à FPCT")) {
                    if (data.outrosDoumentosFPCT && data.outrosDoumentosFPCT.length > 0) {
                        data.outrosDoumentosFPCT.forEach(element => {
                            topico8.push({ ...element, folder: "OutrosDoumentosFPCT" });
                        });
                    }

                }
                if (data.indice.topico8 && data.indice.topico8.includes("Plano de estágio")) {
                    if (data.planoEstagio && data.planoEstagio.length > 0) {
                        data.planoEstagio.forEach(element => {
                            topico8.push({ ...element, folder: "PlanoDeEstagio" });
                        });
                    }

                }
                if (data.indice.topico8 && data.indice.topico8.includes("Protocolo de estágio/FPCT")) {
                    if (data.protocoloEstagio && data.protocoloEstagio.length > 0) {
                        data.protocoloEstagio.forEach(element => {
                            topico8.push({ ...element, folder: "ProtocoloEstagio" });
                        });
                    }

                }
                if (data.indice.topico8 && data.indice.topico8.includes("Relatório de estágio")) {
                    if (data.relatorioEstagio && data.relatorioEstagio.length > 0) {
                        data.relatorioEstagio.forEach(element => {
                            topico8.push({ ...element, folder: "RelatorioDeEstagio" });
                        });
                    }

                }

                try {
                    for (const fileLink of topico8) {
                        if (!fileLink) continue; // Skip if fileLink is undefined or null

                        const response = await fetch(fileLink.file);
                        if (!response.ok) {
                            throw new Error('Failed to fetch file content.');
                        }

                        const fileContent = await response.blob();
                        let fileName
                        if (response.url.includes(".xlsx")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".xlsx";

                        }
                        else if (response.url.includes(".pdf")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".pdf";
                        }
                        else if (response.url.includes(".csv")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".csv";
                        }

                        const folderName = "EstágiosFPCT/" + fileLink.folder;

                        if (!folders[folderName]) {
                            folders[folderName] = zip.folder(folderName);
                        }

                        folders[folderName].file(fileName, fileContent);
                    }


                } catch (error) {
                    console.error('Error:', error);
                }
            }

            // Topico9
            async function Topico9() {
                if (data.indice.topico9 && data.indice.topico9.includes("Avaliação da ação pelo/a formador/a")) {
                    if (data.avaliacaoAcaoFormador && data.avaliacaoAcaoFormador.length > 0) {
                        data.avaliacaoAcaoFormador.forEach(element => {
                            topico9.push({ ...element, folder: "AvaliacaoAcaoFormador" });
                        });
                    }

                }
                if (data.indice.topico9 && data.indice.topico9.includes("Avaliação da ação pelo/a formando/a - PERCURSO/EFA")) {
                    if (data.avaliacaoAcaoFormandoPercurso && data.avaliacaoAcaoFormandoPercurso.length > 0) {
                        data.avaliacaoAcaoFormandoPercurso.forEach(element => {
                            topico9.push({ ...element, folder: "AvaliacaoAcaoFormandoPercurso" });
                        });
                    }

                }
                if (data.indice.topico9 && data.indice.topico9.includes("Avaliação da ação pelo/a formando/a por UFCD")) {
                    try {
                        data.ufcds.forEach(async element => {
                            if (element.avaliacaoAcaoFormandoUFCD && element.avaliacaoAcaoFormandoUFCD.length > 0) {
                                element.avaliacaoAcaoFormandoUFCD.forEach(element => {
                                    topico9.push({ ...element, folder: `avaliacaoAcao/${element.name}-${element.codeUfcd}` });
                                });
                            }

                        });
                    }
                    catch (error) {
                        console.error('Error:', error);

                    }

                }
                if (data.indice.topico9 && data.indice.topico9.includes("Avaliação de desempenho dos/as formadores/as")) {
                    try {
                        const response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/FichadedeAvaliacaoCoordenador/${params.id_curso}`, {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'authorization': localStorage.getItem(token)
                            },
                        });
                        if (!response.ok) {
                            throw new Error('Network response was not ok.');
                        }
                        const result = await response.json();
                        topico9.push({ name: "FichadedeAvaliacaoCoordenador", file: result.AvaliacaoCoordenador_path, folder: "FichadedeAvaliacaoCoordenador" });

                    }
                    catch (error) {
                        console.error('Error:', error);

                    }

                }
                if (data.indice.topico9 && data.indice.topico9.includes("Avaliação de desempenho dos/as formadores/as")) {
                    try {
                        data.ufcds.forEach(async element => {
                            const response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/FichadedeAvaliacaoFormador/${params.id_curso}/${element._id}`, {
                                method: 'GET',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'authorization': localStorage.getItem(token)
                                },
                            });
                            if (!response.ok) {
                                throw new Error('Network response was not ok.');
                            }
                            const result = await response.json();
                            topico9.push({ name: "FichadeAvaliacaoFormador", file: result.AvaliacaoFormador_path, folder: `FichadeAvaliacaoFormador/${element.codeUfcd}-${element.name}` });

                        });
                    }
                    catch (error) {
                        console.error('Error:', error);

                    }

                }
                if (data.indice.topico9 && data.indice.topico9.includes("Relatório final de avaliação da ação")) {
                    if (data.relatorioFinalAvaliacaoAcao && data.relatorioFinalAvaliacaoAcao.length > 0) {
                        data.relatorioFinalAvaliacaoAcao.forEach(element => {
                            topico9.push({ ...element, folder: "RelatorioFinalAvaliacaoAcao" });
                        });
                    }
                }

                try {
                    for (const fileLink of topico9) {
                        if (!fileLink) continue; // Skip if fileLink is undefined or null

                        const response = await fetch(fileLink.file);
                        if (!response.ok) {
                            throw new Error('Failed to fetch file content.');
                        }

                        const fileContent = await response.blob();
                        let fileName
                        if (response.url.includes(".xlsx")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".xlsx";

                        }
                        else if (response.url.includes(".pdf")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".pdf";
                        }
                        else if (response.url.includes(".csv")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".csv";
                        }

                        const folderName = "AvaliaçãoDaFormação/" + fileLink.folder;

                        if (!folders[folderName]) {
                            folders[folderName] = zip.folder(folderName);
                        }

                        folders[folderName].file(fileName, fileContent);
                    }


                } catch (error) {
                    console.error('Error:', error);
                }
            }

            // Topico10
            async function Topico10() {
                if (data.indice.topico10 && data.indice.topico10.includes("Comprovativo de entrega de certificados")) {
                    try {
                        const response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/ListaEntregaCertificados/${params.id_curso}`, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'authorization': localStorage.getItem(token)
                            },
                        });
                        if (!response.ok) {
                            throw new Error('Network response was not ok.');
                        }
                        const result = await response.json();
                        topico10.push({ name: "ComprovativoEntregaCertificadosTemplate", file: result.entregaCertificados_path, folder: "ComprovativoEntregaCertificados" });
                        if (data.comprovativoEntregaCertificados && data.comprovativoEntregaCertificados.length > 0) {
                            data.comprovativoEntregaCertificados.forEach(element => {
                                topico10.push({ ...element, folder: "ComprovativoEntregaCertificados" });
                            });
                        }

                    }
                    catch (error) {
                        console.error('Error:', error);

                    }
                }
                if (data.indice.topico10 && data.indice.topico10.includes("Cópia dos certificado")) {
                    if (data.copiaCertificado && data.copiaCertificado.length > 0) {
                        data.copiaCertificado.forEach(element => {
                            topico10.push({ ...element, folder: "CopiaCertificado" });
                        });
                    }

                }
                try {
                    for (const fileLink of topico10) {
                        if (!fileLink) continue; // Skip if fileLink is undefined or null

                        const response = await fetch(fileLink.file);
                        if (!response.ok) {
                            throw new Error('Failed to fetch file content.');
                        }

                        const fileContent = await response.blob();
                        let fileName
                        if (response.url.includes(".xlsx")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".xlsx";

                        }
                        else if (response.url.includes(".pdf")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".pdf";
                        }
                        else if (response.url.includes(".csv")) {
                            fileName = fileLink.name.substring(fileLink.name.lastIndexOf('/') + 1) + ".csv";
                        }

                        const folderName = "Certificados/" + fileLink.folder;

                        if (!folders[folderName]) {
                            folders[folderName] = zip.folder(folderName);
                        }

                        folders[folderName].file(fileName, fileContent);
                    }


                } catch (error) {
                    console.error('Error:', error);
                }
            }
            // Export

            await Topico1()
            await setContDTP("10")
            await Topico2()
            await setContDTP("20")
            await Topico3()
            await setContDTP("30")
            await Topico4()
            await setContDTP("40")
            await Topico5()
            await setContDTP("50")
            await Topico6()
            await setContDTP("60")
            await Topico7()
            await setContDTP("70")
            await Topico8()
            await setContDTP("80")
            await Topico9()
            await setContDTP("90")
            await Topico10()
            await setContDTP("100")
            const content = await zip.generateAsync({ type: 'blob' });
            await FileSaver.saveAs(content, `${zipFileName}.zip`);

            await setLoadingDownloadDTP(true)
        }

        return (
            <>
                <div>
                    <button className="mt-6 button-geral" onClick={downloadFilesAsZip}>Download de todos os Ficheiros</button>
                </div>
            </>
        )
    }

    //Criar folha de presenças
    function gerarFolhaPresencas(id, idEvento, tipo, percursoType) {
        if (percursoType === true) {
            setLoading(false)
            fetch(`${process.env.REACT_APP_API_URL}/percurso/folhaDePresencasNorteFor/${id}/${idEvento}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                },
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    } else {
                        throw new Error('Network response was not ok.');
                    }
                })
                .then((result) => {
                    // Handle the successful response
                    downloadFile(result.folhaPresencas_path)
                    setLoading(true)
                })
                .catch((error) => {
                    // Handle errors
                    setLoading(true)
                });
        }
        else if (percursoType === false && tipo === "Online") {
            setLoading(false)
            fetch(`${process.env.REACT_APP_API_URL}/percurso/folhaDePresencas/${id}/${idEvento}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                },
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    } else {
                        throw new Error('Network response was not ok.');
                    }
                })
                .then((result) => {
                    // Handle the successful response
                    downloadFile(result.folhaPresencas_path)
                    setLoading(true)
                })
                .catch((error) => {
                    // Handle errors
                    console.error('Error:', error);
                    setLoading(true)
                });
        }
        else {
            setLoading(false)
            fetch(`${process.env.REACT_APP_API_URL}/percurso/folhaDePresencasPresencial/${id}/${idEvento}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                },
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    } else {
                        throw new Error('Network response was not ok.');
                    }
                })
                .then((result) => {
                    // Handle the successful response
                    downloadFile(result.folhaPresencas_path)
                    setLoading(true)
                })
                .catch((error) => {
                    // Handle errors
                    setLoading(true)
                });
        }
        return (
            <>
                <div>
                    <button onClick={downloadFilesAsZip}>Download as Zip</button>
                </div>
            </>
        )
    }
    // DTP Indice
    function gerarIndice(id) {
        setLoading(false)
        fetch(`${process.env.REACT_APP_API_URL}/percurso/Indice/${id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .then((result) => {
                // Handle the successful response
                downloadFile(result.indice_path)
                setLoading(true)
            })
            .catch((error) => {
                // Handle errors
                console.error('Error:', error);
            });
    }
    async function downloadFilesAsZip() {
        const zip = new JSZip();
        const folders = {};

        try {
            await gerarFolhadeDesistencia(); // Assuming gerarFolhadeDesistencia is an async function
            await files.forEach((file) => {
                const pathArr = file.webkitRelativePath.split('/');
                const folderName = pathArr[0];

                if (!folders[folderName]) {
                    folders[folderName] = zip.folder(folderName);
                }

                folders[folderName].file(pathArr.slice(1).join('/'), file);
            });

            const content = await zip.generateAsync({ type: 'blob' });
            await FileSaver.saveAs(content, `${zipFileName}.zip`);
        } catch (error) {
            console.error('Error:', error);
        }
    }
    // DTP 1.


    // DTP 2.


    // DTP 3.


    // DTP 4.
    function gerarFolhaJustificacaoFaltas() {
        setLoading(false)
        fetch(`${process.env.REACT_APP_API_URL}/percurso/FolhaJustificacaoFaltas/${params.id_curso}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .then((result) => {
                // Handle the successful response

                downloadFile(result.justificacao_path)
                setLoading(true)
            })
            .catch((error) => {
                // Handle errors
                console.error('Error:', error);
            });
    }

    //Criar Corrigenda
    function gerarCorrigenda(id, idUfcd) {
        setLoading(false)
        fetch(`${process.env.REACT_APP_API_URL}/percurso/FichadeCorrigenda/${id}/${idUfcd}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .then((result) => {
                downloadFile(result.corrigenda_path)
                // Handle the successful response
                setLoading(true)
            })
            .catch((error) => {
                // Handle errors
                console.error('Error:', error);
            });

    }
    //Criar folha de desistencia
    function gerarFolhadeDesistencia() {
        setLoading(false)
        fetch(`${process.env.REACT_APP_API_URL}/percurso/FolhadeDesistencia/${params.id_curso}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .then((result) => {
                files.push(result)
                downloadFile(result)
                setLoading(true)
            })
            .catch((error) => {
                // Handle errors
                console.error('Error:', error);
            });
    }
    //Criar nota explicativa 
    async function gerarNotaExplicativaFormandos() {
        setLoading(false);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/notaExplicativaFormandos/${params.id_curso}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const result = await response.json();
            // Handle the successful response
            await downloadFile(result.NotaExplicativaSelecaoFormandos_path);
            setLoading(true);
        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }
    }
    //Criar nota explicativa de formadores 
    function gerarNotaExplicativaFormadores() {
        setLoading(false)
        fetch(`${process.env.REACT_APP_API_URL}/percurso/notaExplicativaFormadores/${params.id_curso}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .then((result) => {
                // Handle the successful response
                downloadFile(result.NotaExplicativaSelecaoFormadores_path)
                setLoading(true)
            })
            .catch((error) => {
                // Handle errors
                console.error('Error:', error);
            });
    }
    //Gerar controlo de documentos de formandos
    function gerarcontroloDocsFormandos() {
        setLoading(false)
        fetch(`${process.env.REACT_APP_API_URL}/percurso/controloDocsFormandos/${params.id_curso}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .then((result) => {
                // Handle the successful response
                downloadFile(result.controloDocsFormandos_path)
                setLoading(true)
            })
            .catch((error) => {
                // Handle errors
                console.error('Error:', error);
            });
    }
    //Criar folha de ocorrencias
    function gerarFolhadeOcorrencias() {
        setLoading(false)
        fetch(`${process.env.REACT_APP_API_URL}/percurso/FolhaOcorrencias/${params.id_curso}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .then((result) => {
                // Handle the successful response
                downloadFile(result.ocorrencias_path)
                setLoading(true)
            })
            .catch((error) => {
                // Handle errors
                console.error('Error:', error);
            });
    }
    //Criar plano de sessao
    function gerarPlanodeSessao(evento_id, formador_id) {
        setLoading(false)
        // // if (!data.planoSessao_path) {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/planoDeSessao/${params.id_curso}/formador/${formador_id}/evento/${evento_id}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .then((result) => {
                // Handle the successful response
                downloadFile(result.sessao_path)
                setLoading(true)
            })
            .catch((error) => {
                // Handle errors
                console.error('Error:', error);
            });

    }
    //Obter ufcds
    function GetUFCD(index, id) {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/${id}/trainer`, {
            headers: {
                'authorization': localStorage.getItem(token)
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    // Create a temporary object to store unique events using 'name' as the key
                    // Step 1: Collect events into an array

                    const uniqueEvents = {};
                    if (result) {

                        let eventsArray = result.eventos && result.eventos;

                        // Step 2: Sort the array by datebegin
                        eventsArray.sort((a, b) => new Date(a.start) - new Date(b.start));
                        eventsArray.forEach(element => {
                            const eventName = element.title;
                            if (!uniqueEvents[eventName]) {
                                uniqueEvents[eventName] = element;
                            }
                            else {
                                uniqueEvents[eventName].end = element.end;
                            }
                        });
                    }
                    // Convert the temporary object back to an array of unique events
                    const uniqueEventsArray = Object.values(uniqueEvents);

                    // Set the eventos state with the array of unique events
                    setEventos(uniqueEventsArray); // Make sure to use the correct setter function

                },
                (error) => {
                    console.error("Error fetching data: ", error);
                }
            );
    }
    //Gerar lista de formandos
    function gerarListagemFormandos(idUfcd) {
        setLoading(false)
        fetch(`${process.env.REACT_APP_API_URL}/percurso/gerarListagemSeguro/${params.id_curso}/${idUfcd}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .then((result) => {
                // Handle the successful response
                downloadFile(result.listagemSeguros_path)
                setLoading(true)
            })
            .catch((error) => {
                // Handle errors
                console.error('Error:', error);
            });
    }
    //Criar check list de divulgação
    function gerarChecklistDivulgacao() {
        setLoading(false)
        fetch(`${process.env.REACT_APP_API_URL}/percurso/ChecklistDivulgacao/${params.id_curso}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .then((result) => {
                // Handle the successful response
                downloadFile(result.divulgacaoPublicidade_path)
                setLoading(true)
            })
            .catch((error) => {
                // Handle errors
                console.error('Error:', error);
            });
    }
    //Criar CheckList de instalações
    function gerarChecklistInstalacoes() {
        setLoading(false)
        fetch(`${process.env.REACT_APP_API_URL}/percurso/checklistInstalacoes/${params.id_curso}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .then((result) => {
                // Handle the successful response
                downloadFile(result.checklistInstalacoes_path)
                setLoading(true)
            })
            .catch((error) => {
                // Handle errors
                console.error('Error:', error);
            });
    }
    //Criar ficha tercnica do curso
    function gerarFichaTecnicaCurso() {
        setLoading(false)
        fetch(`${process.env.REACT_APP_API_URL}/percurso/fichaTecnicaCurso/${params.id_curso}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .then((result) => {
                // Handle the successful response
                downloadFile(result.fichaTecnicaCurso_path)
                setLoading(true)
            })
            .catch((error) => {
                // Handle errors
                console.error('Error:', error);
            });
    }
    //Criar fichas de inscrição
    function gerarFichasInscricao(formando_id) {
        setLoading(false)
        fetch(`${process.env.REACT_APP_API_URL}/percurso/FichadeIncricao/${params.id_curso}/formando/${formando_id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .then((result) => {
                // Handle the successful response
                downloadFile(result.fichaInscricao_path)
                setLoading(true)
            })
            .catch((error) => {
                // Handle errors
                console.error('Error:', error);
            });
    }
    //Gerar comprovativo de entrega certeficado
    function gerarComprovativoEntregaCertificados() {
        setLoading(false)

        fetch(`${process.env.REACT_APP_API_URL}/percurso/ListaEntregaCertificados/${params.id_curso}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .then((result) => {
                // Handle the successful response
                downloadFile(result.entregaCertificados_path)
                setLoading(true)
            })
            .catch((error) => {
                // Handle errors
                console.error('Error:', error);
            });

    }
    //Criar controlo de documentos formadores
    function gerarControloDocsFormadores() {
        setLoading(false)
        fetch(`${process.env.REACT_APP_API_URL}/percurso/controloDocsFormadores/${params.id_curso}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .then((result) => {
                // Handle the successful response
                downloadFile(result.controloDocsFormadores_path)
                setLoading(true)
            })
            .catch((error) => {
                // Handle errors
                console.error('Error:', error);
            });

    }
    //Criar listagem de equipa pedagogica
    function gerarListagemEquipaPedagogica() {
        setLoading(false)
        fetch(`${process.env.REACT_APP_API_URL}/percurso/listagemEquipaPedagogica/${params.id_curso}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .then((result) => {
                // Handle the successful response
                downloadFile(result.listagemEquipaPedagogica_path)
                setLoading(true)
            })
            .catch((error) => {
                // Handle errors
                console.error('Error:', error);
            });
    }
    //Criar Ata
    function gerarAta() {
        setLoading(false)
        fetch(`${process.env.REACT_APP_API_URL}/percurso/Ata/${params.id_curso}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .then((result) => {
                // Handle the successful response
                downloadFile(result.ata_path)
                setLoading(true)
            })
            .catch((error) => {
                // Handle errors
                console.error('Error:', error);
            });
    }
    //Criar rota pedagogica
    function gerarRoteiroPedagógico(id) {
        setLoading(false)
        fetch(`${process.env.REACT_APP_API_URL}/modulos/roteiro/formador/${id}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .then((result) => {
                // Handle the successful response
                downloadFile(result.roteiroPedagogico)
                setLoading(true)
            })
            .catch((error) => {
                // Handle errors
                console.error('Error:', error);
            });
    }
    //Criar ficha de avaliação do coordenador
    function gerarFichaAvaliacaoCoordenador() {
        setLoading(false)
        fetch(`${process.env.REACT_APP_API_URL}/percurso/FichadedeAvaliacaoCoordenador/${params.id_curso}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .then((result) => {
                // Handle the successful response
                downloadFile(result.AvaliacaoCoordenador_path)
                setLoading(true)
            })
            .catch((error) => {
                // Handle errors
                console.error('Error:', error);
            });
    }
    //Criar avaliação do formador
    async function gerarAvaliacaoFormador(id, idFormador, evaluation) {
        setLoading(false);
        if (idFormador) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/FichadedeAvaliacaoFormador/${params.id_curso}/${id}/${idFormador}`, {
                    method: "PATCH",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': localStorage.getItem(token)
                    },
                    body: JSON.stringify({
                        evaluation: evaluation
                    })
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }

                const result = await response.json();
                // Handle the successful response
                await downloadFile(result.AvaliacaoFormador_path);
                setLoading(true);
            } catch (error) {
                // Handle errors
                console.error('Error:', error);
            }
        }
        else {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/percurso/FichadedeAvaliacaoFormador/${params.id_curso}/${id}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': localStorage.getItem(token)
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }

                const result = await response.json();
                // Handle the successful response
                await downloadFile(result.AvaliacaoFormador_path);
                setLoading(true);
            } catch (error) {
                // Handle errors
                console.error('Error:', error);
            }
        }
    }
    async function downloadFile(url) {
        try {
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('target', '_blank');

            if ('download' in link) {
                link.setAttribute('download', '');
            }

            document.body.appendChild(link); // Append the link to the DOM

            await new Promise(resolve => setTimeout(resolve, 400)); // Wait for 400ms

            link.click();
            document.body.removeChild(link); // Remove the link from the DOM after the click
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }

    useEffect(() => {
        getPercurso()
    }, [cont]);
    useEffect(() => {

    }, [contDTP]);

    //abrir e fechar tab
    function toggleTab(number) {
        if (tabOppened === number) return 0;
        else return number;
    }
    //Abrir e fechar subtab
    function toggleSubTab(number) {
        if (subTabOppened === number) return 0;
        else return number;
    }

    function toggle2SubTab(number) {
        if (sub2TabOppened === number) return 0;
        else return number;
    }

    function toggle3SubTab(number) {
        if (sub3TabOppened === number) return 0;
        else return number;
    }
    function toggle4SubTab(number) {
        if (sub4TabOppened === number) return 0;
        else return number;
    }

    if (!loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    else if (loading && !loadingDownloadDTP) return (
        <div className="flex flex-col items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
            <h1 className="title">{contDTP}%</h1>
        </div>
    );

    const getPositionNumber0 = (topic) => {
        const sortedTopics = [...data.indice.topico0].sort();
        const index = sortedTopics.indexOf(topic) + 1;
        return index.toString();
    };

    const getPositionNumber1 = (topic) => {
        const sortedTopics = [...data.indice.topico1].sort();
        const index = sortedTopics.indexOf(topic) + 1;
        return index.toString();
    };

    const getPositionNumber2 = (topic) => {
        const sortedTopics = [...data.indice.topico2].sort();
        const index = sortedTopics.indexOf(topic) + 1;
        return index.toString();
    };

    const getPositionNumber3 = (topic) => {
        const sortedTopics = [...data.indice.topico31].sort();
        const index = sortedTopics.indexOf(topic) + 1;
        return index.toString();
    };

    const getPositionNumber4 = (topic) => {
        const sortedTopics = [...data.indice.topico4].sort();
        const index = sortedTopics.indexOf(topic) + 1;
        return index.toString();
    };

    const getPositionNumber5 = (topic) => {
        const sortedTopics = [...data.indice.topico5].sort();
        const index = sortedTopics.indexOf(topic) + 1;
        return index.toString();
    };

    const getPositionNumber6 = (topic) => {
        const sortedTopics = [...data.indice.topico6].sort();
        const index = sortedTopics.indexOf(topic) + 1;
        return index.toString();
    };

    const getPositionNumber7 = (topic) => {
        const sortedTopics = [...data.indice.topico7].sort();
        const index = sortedTopics.indexOf(topic) + 1;
        return index.toString();
    };

    const getPositionNumber8 = (topic) => {
        const sortedTopics = [...data.indice.topico8].sort();
        const index = sortedTopics.indexOf(topic) + 1;
        return index.toString();
    };

    const getPositionNumber9 = (topic) => {
        const sortedTopics = [...data.indice.topico9].sort();
        const index = sortedTopics.indexOf(topic) + 1;
        return index.toString();
    };

    const getPositionNumber10 = (topic) => {
        const sortedTopics = [...data.indice.topico10].sort();
        const index = sortedTopics.indexOf(topic) + 1;
        return index.toString();
    };

    const getPositionNumber11 = (topic) => {
        const sortedTopics = [...data.indice.topico11].sort();
        const index = sortedTopics.indexOf(topic) + 1;
        return index.toString();
    };

    const getPositionTopic3 = (topic) => {
        const keys = Object.keys(data.indice)
        const index = keys.indexOf(topic);
        let i = 1
        if (data.indice.topico0) {
            i--
        }
        if (data.indice.topico32) {
            i--
        }
        return index !== -1 ? (index + i).toString() : "Elemento não encontrado no objeto índice.";
    };

    const getPositionTopic = (topic) => {
        const keys = Object.keys(data.indice)
        const index = keys.indexOf(topic);
        let i = 1
        if (data.indice.topico0) {
            i = 0
        }
        return index !== -1 ? (index + i).toString() : "Elemento não encontrado no objeto índice.";
    };

    // ---------------------------------fim Animações------------------------------------
    return (
        <>
            <main className={(token === "coord" || token === "user") ? "min-h-screen grid-cols-7 mx-auto md:grid xl:grid-cols-10" : "min-h-screen"}>
                {token !== "coord" && token !== "user" && <div className="w-full">
                    {localStorage.getItem("admin2") && <NavbarAdmin2 currentPage={type} />}
                    {localStorage.getItem("admin1") && <NavbarAdmin1 currentPage={type} />}
                </div>}
                {token === "coord" && <div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    <NavbarCoordenador currentPage={"gerirCursos"} />
                </div>}
                {token === "user" && <div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    <NavbarFormador currentPage={"cursos"} />
                </div>}
                <div className={(token === "coord" || token === "user") ? "relative h-full col-span-5 mx-4 xl:col-span-8" : undefined}>
                    {(token === "coord" || token === "user") ? <div className="back-button-noNavbar">
                        <button onClick={() => navigate(-1)} className="flex w-8 h-8">
                            <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                        </button>
                    </div>
                        : <div className="back-button-div-noNavbar">
                            <button onClick={() => navigate(-1)} className="back-button-link">
                                <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                            </button>
                        </div>}

                    {token === "coord" && <Header sideMenu={"true"} />}
                    <div className={token === "coord" ? "" : "h-full mb-8 sm:col-span-8"}>
                        <div className="flex flex-col items-center mx-4 mt-8 xl:mx-0 mb-10">
                            <div className="uppercase title">Dossier Técnico-pedagógico</div>
                            {data && <>
                                <div className="my-2 rounded-lg shadow-lg w-68 lg:w-156 xl:w-204">
                                    <div className="mb-0 rounded-t-lg tab-container last-of-type:border-b subtitle" onClick={() => gerarIndice(data._id)}>
                                        Índice
                                        <button className="tooltip" data-tip={"Download"}><FiDownload /></button>
                                    </div>
                                    {
                                        data.indice.topico1 && token != "user" && <><div className={"tab-container last-of-type:border-b rounded-t-lg " + (tabOppened === 1 ? "bg-secundary-color" : undefined)} onClick={() => { setTabOppened(toggleTab(1)); setSubTabOppened(0); setSub2TabOppened(0) }}>
                                            <h2 className="tab-title">{getPositionTopic("topico1")}. FUNCIONAMENTO/ ORGANIZAÇÃO</h2>
                                            <svg className={"w-3 h-3 shrink-0 " + (tabOppened !== 1 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </div>
                                            {tabOppened === 1 &&
                                                <div className="p-5 border border-b-0 border-gray-200 ">
                                                    {data.indice.topico1.includes("Check List de Divulgação/Publicidade") &&
                                                        <div className={"tab-container last-of-type:border-b last-of-type:bg-black " + (subTabOppened === 6 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(6)) }}>
                                                            <h2 className="tab-title">{getPositionTopic("topico1")}.{getPositionNumber1("Check List de Divulgação/Publicidade")}. Check List de Divulgação/Publicidade</h2>
                                                            <div className="flex justify-end items-center min-w-[10%]">
                                                                <button onClick={gerarChecklistDivulgacao} className="mr-1 text-xl text-text-main-color tooltip"
                                                                    variant="gradient" data-tip={"Template Editável"}>
                                                                    <RiFileEditLine />
                                                                </button>
                                                                <ModalUpload icon={<FiUpload />} topicoAPI={"divulgacaoPublicidade"} topicoTitulo={"Divulgação/Publicidade"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                                <ModalDownload icon={<FiDownload />} topicoAPI={"divulgacaoPublicidade"} topicoTitulo={"Divulgação/Publicidade"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                            </div>
                                                        </div>}
                                                    {data.indice.topico1.includes("Check List de verificação das instalações, equipamentos e materiais") && <div className={"tab-container last-of-type:border-b last-of-type:bg-black  rounded-t-lg " + (subTabOppened === 3 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(3)) }}>
                                                        <h2 className="tab-title max-w-[70%] md:max-w-none ">{getPositionTopic("topico1")}.{getPositionNumber1("Check List de verificação das instalações, equipamentos e materiais")}. Check List de verificação das instalações, equipamentos e materiais</h2>
                                                        <div className="flex justify-end items-center min-w-[10%]">
                                                            <button onClick={gerarChecklistInstalacoes} className="mr-1 text-xl text-text-main-color tooltip"
                                                                variant="gradient" data-tip={"Template Editável"}>
                                                                <RiFileEditLine />
                                                            </button>
                                                            <ModalUpload icon={<FiUpload />} topicoAPI={"verificacaoInstalacoes"} topicoTitulo={"Verificação das instalações, equipamentos e materiais"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                            <ModalDownload icon={<FiDownload />} topicoAPI={"verificacaoInstalacoes"} topicoTitulo={"Verificação das instalações, equipamentos e materiais"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />

                                                        </div>
                                                    </div>}
                                                    {data.indice.topico1.includes("Cronograma Específico") && <div className={"tab-container last-of-type:border-b rounded-t-lg " + (subTabOppened === 1 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(1)); setSub2TabOppened(0) }}>
                                                        <h2 className="tab-title">{getPositionTopic("topico1")}.{getPositionNumber1("Cronograma Específico")}. Cronograma Específico</h2>
                                                        <div>
                                                            <svg className={"w-3 h-3 shrink-0 " + (subTabOppened !== 1 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    }
                                                    {tabOppened === 1 && subTabOppened === 1 && <>{data.ufcds
                                                        .filter((item) => item.formadores.length > 0)
                                                        .map((item, index) => (
                                                            <div key={index}>
                                                                <div className={" tab-container last-of-type:border-b text-text-main-color w-11/12 mx-auto my-3 p-2 rounded-lg cursor-pointer hover:bg-gray-100 text-left " + (sub2TabOppened === index + 1 && "bg-fifth-color")} onClick={() => { GetUFCD(index, item._id); setSub2TabOppened(toggle2SubTab(index + 1)) }}>
                                                                    <h3 className="max-w-[70%] md:max-w-none">{getPositionTopic("topico1")}.{getPositionNumber1("Cronograma Específico")}.{index + 1}. {item.codeUfcd} - {item.name}</h3>
                                                                    <div className="flex justify-end items-center min-w-[10%]">
                                                                        <button onClick={() => excelGenerator(item)} className="mr-1 text-xl text-text-main-color tooltip"
                                                                            variant="gradient" data-tip={"Template Editável"}>
                                                                            <PiMicrosoftExcelLogo />
                                                                        </button>
                                                                        <ModalUploadUFCD icon={<FiUpload />} id={"cronogramaEspecifico"} topicoAPI={"cronogramaEspecifico"} topicoTitulo={"Cronograma Específico"} id_curso={item._id} user={token} tooltip={"Upload Ficheiro"} />
                                                                        <ModalDownloadUFCD icon={<FiDownload />} topicoAPI={"cronogramaEspecifico"} topicoTitulo={"Cronograma Específico"} id_curso={item._id} user={token} tooltip={"Download Ficheiro"} />
                                                                    </div>
                                                                </div>
                                                            </div>))}
                                                    </>}
                                                    {data.indice.topico1.includes("Cronograma GERAL") && <div className={"tab-container last-of-type:border-b rounded-t-lg " + (subTabOppened === 4 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(4)) }}>
                                                        <h2 className="tab-title">{getPositionTopic("topico1")}.{getPositionNumber1("Cronograma GERAL")}. Cronograma Geral</h2>
                                                        <div className="flex justify-end items-center min-w-[10%]">
                                                            <button onClick={() => excelGeneratorClone(data)} className="mr-1 text-xl text-text-main-color tooltip"
                                                                variant="gradient" data-tip={"Template Editável"}>
                                                                <PiMicrosoftExcelLogo />
                                                            </button>
                                                            <ModalUpload icon={<FiUpload />} topicoAPI={"cronograma"} id={"cronograma"} topicoTitulo={"Cronograma Geral"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                            <ModalDownload icon={<FiDownload />} topicoAPI={"cronograma"} topicoTitulo={"Cronograma Geral"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                        </div>
                                                    </div>}
                                                    {data.indice.topico1.includes("Ficha Técnica do curso/Programa") && <div className={"tab-container last-of-type:border-b rounded-t-lg " + (subTabOppened === 2 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(2)) }}>
                                                        <h2 className="tab-title">{getPositionTopic("topico1")}.{getPositionNumber1("Ficha Técnica do curso/Programa")}. Ficha Técnica do curso/Programa</h2>
                                                        <div className="flex justify-end items-center min-w-[10%]">
                                                            <button onClick={gerarFichaTecnicaCurso} className="mr-1 text-xl text-text-main-color tooltip"
                                                                variant="gradient" data-tip={"Template Editável"}>
                                                                <RiFileEditLine />
                                                            </button>
                                                            <ModalUpload icon={<FiUpload />} topicoAPI={"fichaTecnica"} id={"fichaTecnica"} topicoTitulo={"Ficha Técnica do curso/Programa"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                            <ModalDownload icon={<FiDownload />} topicoAPI={"fichaTecnica"} topicoTitulo={"Ficha Técnica do curso/Programa"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                        </div>
                                                    </div>}
                                                    {data.indice.topico1.includes("Regras de Conduta") && <div className={"tab-container last-of-type:border-b rounded-b-lg " + (subTabOppened === 8 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(8)) }}>
                                                        <h2 className="tab-title">{getPositionTopic("topico1")}.{getPositionNumber1("Regras de Conduta")}. Regras de Conduta</h2>
                                                    </div>}
                                                    {data.indice.topico1.includes("Regulamento Geral de Funcionamento da Formação") && <div className={"tab-container rounded-t-lg last-of-type:border-b" + (subTabOppened === 5 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(5)) }}>
                                                        <h2 className="tab-title">{getPositionTopic("topico1")}.{getPositionNumber1("Regulamento Geral de Funcionamento da Formação")}. Regulamento Geral de Funcionamento da Formação</h2>
                                                    </div>}
                                                    {data.indice.topico1.includes("Regulamento de Funcionamento da Formação a Distância") && <div className={"tab-container last-of-type:border-b" + (subTabOppened === 7 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(7)) }}>
                                                        <h2 className="tab-title">{getPositionTopic("topico1")}.{getPositionNumber1("Regulamento de Funcionamento da Formação a Distância")}. Regulamento de Funcionamento da Formação a Distância</h2>
                                                    </div>}
                                                </div>}</>
                                    }
                                    {/* TAB 1 END*/}
                                    {/* TAB 2 */}
                                    {data.indice.topico2 && token != "user" && <><div className={"tab-container last-of-type:border-b rounded-t-lg " + (tabOppened === 2 ? "bg-secundary-color" : undefined)} onClick={() => { setTabOppened(toggleTab(2));; setSubTabOppened(0); setSub2TabOppened(0) }}>
                                        <h2 className="tab-title">{getPositionTopic("topico2")}. FORMANDOS/AS</h2>
                                        <svg className={"w-3 h-3 shrink-0 " + (tabOppened !== 2 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                        </svg>
                                    </div>
                                        {tabOppened === 2 &&
                                            <div className="p-5 border border-b-0 border-gray-200">
                                                {data.indice.topico2.includes("Apólice de Seguro") && <div className={"tab-container last-of-type:border-b " + (subTabOppened === 6 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(6)) }}>
                                                    <h2 className="tab-title">{getPositionTopic("topico2")}.{getPositionNumber2("Apólice de Seguro")}. Apólice de Seguro</h2>
                                                    <div>
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"apoliceSeguro"} topicoTitulo={"Apólice de Seguro"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"apoliceSeguro"} topicoTitulo={"Apólice de Seguro"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                                {data.indice.topico2.includes("Check List de verificação dos documentos e elegibilidade dos formandos") && <div className={"tab-container last-of-type:border-b rounded-t-lg " + (subTabOppened === 1 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(1)) }}>
                                                    <h2 className="tab-title">{getPositionTopic("topico2")}.{getPositionNumber2("Check List de verificação dos documentos e elegibilidade dos formandos")}. Check List de verificação dos documentos e elegibilidade dos formandos</h2>
                                                    <div>
                                                        <button onClick={gerarcontroloDocsFormandos} className="mr-1 text-xl text-text-main-color tooltip"
                                                            variant="gradient" data-tip={"Download Direto"}>
                                                            <CgFileDocument />
                                                        </button>
                                                    </div>
                                                </div>}
                                                {data.indice.topico2.includes("Contratos de formação do organismo intermédio/financiador") && <div className={"tab-container last-of-type:border-b " + (subTabOppened === 2 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(2)) }}>
                                                    <h2 className="tab-title">{getPositionTopic("topico2")}.{getPositionNumber2("Contratos de formação do organismo intermédio/financiador")}. Contrato de formação dos formandos de acordo com organismo financiador</h2>
                                                    <div className="flex justify-end items-center min-w-[10%]">
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"contratosFormacaoOrganismo"} topicoTitulo={"Contratos de formação do organismo intermédio/financiador"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"contratosFormacaoOrganismo"} topicoTitulo={"Contratos de formação do organismo intermédio/financiador"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                                {data.indice.topico2.includes("Contratos de formação dos formandos") && <div className={"tab-container last-of-type:border-b " + (subTabOppened === 3 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(3)); setSub2TabOppened(0) }}>
                                                    <h2 className="tab-title">{getPositionTopic("topico2")}.{getPositionNumber2("Contratos de formação dos formandos")}. Contratos de formação dos formandos</h2>
                                                    <svg className={"w-3 h-3 shrink-0 " + (subTabOppened !== 3 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                    </svg>
                                                </div>}
                                                {tabOppened === 2 && subTabOppened === 3 ? <>{data.formandos
                                                    .filter((item) => item.valid === "Validado")
                                                    .sort((a, b) => a.fullName.localeCompare(b.fullName))  // Assuming 'name' is the property to sort by
                                                    .map((item, index) => (
                                                        <div key={index}>
                                                            <div className={" tab-container last-of-type:border-b text-text-main-color w-11/12 mx-auto my-3 p-2 rounded-lg hover:bg-gray-100 text-left " + (sub2TabOppened === index + 1 && "bg-fifth-color")} onClick={() => { GetUFCD(index, item._id); setSub2TabOppened(toggle2SubTab(index + 1)) }}>
                                                                <h3>{getPositionTopic("topico2")}.{getPositionNumber2("Contratos de formação dos formandos")}.{index + 1}.{item.fullName}</h3>
                                                                <div className="flex justify-end items-center min-w-[10%]">
                                                                    {item.contrato_path ?
                                                                        <button onClick={() => downloadFile(item.contrato_path)} className="mr-1 text-xl text-text-main-color tooltip"
                                                                            variant="gradient" data-tip={"Download Direto"}>
                                                                            <CgFileDocument />
                                                                        </button> : <button className="mr-1 text-xl text-gray-400 tooltip"
                                                                            variant="gradient" data-tip={"Sem contrato"}>
                                                                            <CgFileDocument />
                                                                        </button>}
                                                                    <ModalUploadFormandos icon={<FiUpload />} topicoAPI={"contrato"} topicoTitulo={"Contratos de formação dos formandos"} id_curso={item._id} user={token} tooltip={"Upload Ficheiro"} />
                                                                    <ModalDownloadFormandos icon={<FiDownload />} topicoAPI={"contrato"} topicoTitulo={"Contratos de formação dos formandos"} id_curso={item._id} user={token} tooltip={"Download Ficheiro"} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}</> : ""}
                                                {data.indice.topico2.includes("Ficha de registo do processo de seleção dos formandos") && <div className={"tab-container last-of-type:border-b " + (subTabOppened === 4 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(4)) }}>
                                                    <h2 className="tab-title">{getPositionTopic("topico2")}.{getPositionNumber2("Ficha de registo do processo de seleção dos formandos")}. Ficha de registo do processo de seleção dos formandos</h2>
                                                    <div className="flex justify-end items-center min-w-[10%]">
                                                        <button onClick={gerarNotaExplicativaFormandos} className="mr-1 text-xl text-text-main-color tooltip"
                                                            variant="gradient" data-tip={"Template Editável"}>
                                                            <RiFileEditLine />
                                                        </button>
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"fichaRegisto"} topicoTitulo={"Ficha de registo do processo de seleção dos formandos"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"fichaRegisto"} topicoTitulo={"Ficha de registo do processo de seleção dos formandos"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                                {data.indice.topico2.includes("Fichas de inscrição") &&
                                                    <div>
                                                        <div className={"tab-container last-of-type:border-b rounded-t-lg " + (subTabOppened === 7 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(7)); setSub2TabOppened(0) }}>
                                                            <h2 className="tab-title">{getPositionTopic("topico2")}.{getPositionNumber2("Fichas de inscrição")}. Fichas de inscrição</h2>
                                                            <svg className={"w-3 h-3 shrink-0 " + (subTabOppened !== 7 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                            </svg>
                                                        </div>
                                                        {tabOppened === 2 && subTabOppened === 7 ? <>{data.formandos.length>0 && data.formandos
                                                            .filter((item) => item.valid === "Validado")
                                                            .sort((a, b) => a.fullName.localeCompare(b.fullName))  // Assuming 'name' is the property to sort by
                                                            .map((item, index) => (
                                                                <div key={index}>
                                                                    <div className={" tab-container last-of-type:border-b text-text-main-color w-11/12 mx-auto my-3 p-2 rounded-lg cursor-pointer hover:bg-gray-100 text-left " + (sub2TabOppened === index + 1 && "bg-fifth-color")} onClick={() => { setSub2TabOppened(toggle2SubTab(index + 1)) }}>
                                                                        <h3>{getPositionTopic("topico2")}.{getPositionNumber2("Fichas de inscrição")}.{index + 1}.{item.fullName}</h3>
                                                                        <div className="flex justify-end items-center min-w-[10%]">
                                                                            <button onClick={() => gerarFichasInscricao(item._id)} className="mr-1 text-xl text-text-main-color tooltip"
                                                                                variant="gradient" data-tip={"Template Editável"}>
                                                                                <RiFileEditLine />
                                                                            </button>
                                                                            <ModalUploadFormandos icon={<FiUpload />} topicoAPI={"fichaInscricao"} topicoTitulo={"Ficha de Inscrição"} id_curso={item._id} user={token} tooltip={"Upload Ficheiro"} />
                                                                            <ModalDownloadFormandos icon={<FiDownload />} topicoAPI={"fichaInscricao"} topicoTitulo={"Ficha de Inscrição"} id_curso={item._id} user={token} tooltip={"Download Ficheiro"} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}</> : ""}
                                                    </div>}
                                                {data.indice.topico2.includes("Listagem de Formandos (c/ dados para seguro)") && <div className={"tab-container last-of-type:border-b " + (subTabOppened === 5 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(5)) }}>
                                                    <h2 className="tab-title">{getPositionTopic("topico2")}.{getPositionNumber2("Listagem de Formandos (c/ dados para seguro)")}. Listagem de Formandos (c/ dados para seguro)</h2>
                                                    <svg className={"w-3 h-3 shrink-0 " + (subTabOppened !== 5 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                    </svg>
                                                </div>}
                                                <div>
                                                    {
                                                        tabOppened === 2 && subTabOppened === 5 && <>
                                                            {data.ufcds.length > 0 && data.ufcds
                                                                .map((item, index) => (
                                                                    <div key={index}>
                                                                        <div className={" tab-container last-of-type:border-b text-text-main-color w-11/12 mx-auto my-3 p-2 rounded-lg cursor-pointer hover:bg-gray-100 text-left " + (sub2TabOppened === index + 1 && "bg-fifth-color")} onClick={() => { setSub3TabOppened(toggle2SubTab(index + 1)) }}>
                                                                            <h3 className="mx-4 max-w-[60%] md:max-w-none">{getPositionTopic("topico2")}.{getPositionNumber2("Listagem de Formandos (c/ dados para seguro)")}.{index + 1}. {item.codeUfcd} - {item.name}</h3>
                                                                            <div className="flex justify-end items-center min-w-[10%]">
                                                                                <button onClick={() => gerarListagemFormandos(item._id)} className="text-xl text-text-main-color tooltip"
                                                                                    variant="gradient" data-tip={"Download Direto"}>
                                                                                    <CgFileDocument />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>))
                                                            }
                                                        </>
                                                    }

                                                </div>

                                            </div>}</>}
                                    {/* TAB 2 END*/}
                                    {/* TAB 3 */}
                                    {(data.indice.topico31 || data.indice.topico32) && <><div className={"tab-container last-of-type:border-b rounded-t-lg " + (tabOppened === 3 ? "bg-secundary-color" : undefined)} onClick={() => { setTabOppened(toggleTab(3)); setSubTabOppened(toggleSubTab(0)); setSub2TabOppened(0) }}>
                                        <h2 className="tab-title">{getPositionTopic("topico31")}. FORMADORES/AS E PESSOAL NÃO DOCENTE</h2>
                                        <svg className={"w-3 h-3 shrink-0 " + (tabOppened !== 3 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                        </svg>
                                    </div>
                                        {tabOppened === 3 &&
                                            <div className="p-5 border border-b-0 border-gray-200">
                                                {data.indice.topico31 && <div className={"tab-container last-of-type:border-b rounded-t-lg " + (subTabOppened === 1 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(1)) }}>
                                                    <h2 className="tab-title">{getPositionTopic("topico31")}.1 Formadores</h2>
                                                    <svg className={"w-3 h-3 shrink-0 " + (subTabOppened !== 1 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                    </svg>
                                                </div>}


                                                {tabOppened === 3 && subTabOppened === 1 && <div className="p-5 border border-b-0 border-gray-200">
                                                    {token != "user" && data.indice.topico31.includes("Check List de verificação dos documentos dos formadores") && <div className={"tab-container last-of-type:border-b " + (sub2TabOppened === 5 ? "bg-fourth-color" : undefined)} onClick={() => { setSub2TabOppened(toggle2SubTab(5)) }} >
                                                        <h2 className="tab-title">{getPositionTopic("topico31")}.1.{getPositionNumber3("Check List de verificação dos documentos dos formadores")}. Check List de verificação dos documentos dos formadores</h2>
                                                        <div className="flex justify-end items-center min-w-[10%]">
                                                            <button onClick={gerarControloDocsFormadores} className="mr-1 text-xl text-text-main-color tooltip"
                                                                variant="gradient" data-tip={"Template Editável"}>
                                                                <RiFileEditLine />
                                                            </button>
                                                            <ModalUpload icon={<FiUpload />} topicoAPI={"verificacaoDocumentosFormadores"} topicoTitulo={"Verificação dos documentos dos formadores"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                            <ModalDownload icon={<FiDownload />} topicoAPI={"verificacaoDocumentosFormadores"} topicoTitulo={"Verificação dos documentos dos formadores"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                        </div>
                                                    </div>}

                                                    {data.indice.topico31.filter(item => item === "Contrato de Prestação de Serviços dos formadores") && <div className={" tab-container last-of-type:border-b text-text-main-color cursor-pointer hover:bg-gray-100 text-left " + (sub2TabOppened === 1 && "bg-fifth-color")} onClick={() => { setSub2TabOppened(toggle2SubTab(1)); setSub3TabOppened(0) }}>

                                                        <h2 className="tab-title">{getPositionTopic("topico31")}.1.{getPositionNumber3("Contrato de Prestação de Serviços dos formadores")}. Contrato de Prestação de Serviços dos formadores</h2>
                                                        <svg className={"w-3 h-3 shrink-0 " + (sub2TabOppened !== 1 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                        </svg>

                                                    </div>}
                                                    {
                                                        tabOppened === 3 && subTabOppened === 1 && sub2TabOppened === 1 && <>
                                                            {data.ufcds && data.ufcds.length > 0 && data.ufcds
                                                                .filter((item) => token != "user" ? item.formadores.length > 0 : item.formadores.length > 0 && item.formadores.find((formador) => (params.idFormador ? formador._id === params.idFormador : formador._id === params.id_candidatura)))
                                                                .map((item, index) => (
                                                                    <div key={index}>
                                                                        <div className={" tab-container last-of-type:border-b text-text-main-color w-11/12 mx-auto my-3 p-2 rounded-lg cursor-pointer hover:bg-gray-100 text-left " + (sub3TabOppened === index + 1 && "bg-fifth-color")} onClick={() => { setSub3TabOppened(toggle3SubTab(index + 1)) }}>
                                                                            <h3 className="mx-4 max-w-[60%] md:max-w-none">{getPositionTopic("topico31")}.{getPositionNumber3("Contrato de Prestação de Serviços dos formadores")}.{index + 1}. {item.codeUfcd} - {item.name} </h3>
                                                                            <svg className={"w-3 h-3 shrink-0 " + (sub3TabOppened !== index && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                                            </svg>
                                                                        </div>
                                                                        {tabOppened === 3 && subTabOppened === 1 && sub2TabOppened === 1 && sub3TabOppened === index + 1 && item.formadores.map((formador, index2) => (
                                                                            <div className={" tab-container last-of-type:border-b text-text-main-color w-10/12 mx-auto my-3 p-2 rounded-lg cursor-pointer hover:bg-gray-100 text-left " + (sub4TabOppened === index2 + 1 && "bg-fifth-color")} onClick={() => { setSub4TabOppened(toggle4SubTab(index2 + 1)) }} key={item._id}>
                                                                                <h3 className="mx-4 max-w-[60%] md:max-w-none">{getPositionTopic("topico31")}.{getPositionNumber3("Contrato de Prestação de Serviços dos formadores")}.{index + 1}. {formador.fullName}</h3>
                                                                                <div className="flex justify-end items-center min-w-[10%]">
                                                                                    {token != "user" && <> {item.contrato_path && item.contrato_path.findIndex((contrato) => contrato.id === formador._id) != -1 ?
                                                                                        <button onClick={() => {
                                                                                            typeof item.contrato_path === 'string' ?
                                                                                                downloadFile(item.contrato_path)
                                                                                                : item.contrato_path.length > 0 && downloadFile(item.contrato_path[item.contrato_path.findIndex((contrato) => contrato.id === formador._id)].contrato_path)
                                                                                        }} className="text-xl text-text-main-color tooltip"
                                                                                            variant="gradient" data-tip={"Download Direto"}>
                                                                                            <CgFileDocument />
                                                                                        </button> : <button className="mr-1 text-xl text-gray-400 tooltip"
                                                                                            variant="gradient" data-tip={"Sem contrato"}>
                                                                                            <CgFileDocument />
                                                                                        </button>}</>}
                                                                                    <ModalUploadUFCD icon={<FiUpload />} topicoAPI={"contratoPrestacaoServicos"} topicoTitulo={"Contrato de Prestação de Serviços dos formadores"} id_curso={item._id} user={token} tooltip={"Upload Ficheiro"}></ModalUploadUFCD>
                                                                                    {token != "user" && <ModalDownloadUFCD icon={<FiDownload />} topicoAPI={"contratoPrestacaoServicos"} topicoTitulo={"Contrato de Prestação de Serviços dos formadores"} id_curso={item._id} user={token} tooltip={"Download Ficheiro"} />}
                                                                                </div></div>))}

                                                                    </div>))
                                                            }
                                                        </>
                                                    }

                                                    {token != "user" && data.indice.topico31.includes("Contrato de Prestação de Serviços dos formadores do organismo intermédio/financiador") && <div className={"tab-container last-of-type:border-b " + (sub2TabOppened === 6 ? "bg-fourth-color" : undefined)} onClick={() => { setSub2TabOppened(toggle2SubTab(6)) }}   >
                                                        <h2 className="tab-title max-w-[70%] md:max-w-none">{getPositionTopic("topico31")}.1.{getPositionNumber3("Contrato de Prestação de Serviços dos formadores do organismo intermédio/financiador")}. Contrato de prestação de Serviços de acordo com o organismo financiador</h2>
                                                        <div className="flex justify-end items-center min-w-[10%]">
                                                            <ModalUpload icon={<FiUpload />} topicoAPI={"contratoPrestacaoServicosFormadoresOrganismo"} topicoTitulo={"Contrato de Prestação de Serviços dos formadores do organismo intermédio/financiador"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                            <ModalDownload icon={<FiDownload />} topicoAPI={"contratoPrestacaoServicosFormadoresOrganismo"} topicoTitulo={"Contrato de Prestação de Serviços dos formadores do organismo intermédio/financiador"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                        </div>
                                                    </div>}
                                                    {token != "user" && data.indice.topico31.includes("Ficha de registo do processo de seleção dos/as formadores/as") && <div className={"tab-container last-of-type:border-b " + (sub2TabOppened === 4 ? "bg-fourth-color" : undefined)} onClick={() => { setSub2TabOppened(toggle2SubTab(4)) }} >
                                                        <h2 className="tab-title max-w-[70%] md:max-w-none">{getPositionTopic("topico31")}.1.{getPositionNumber3("Ficha de registo do processo de seleção dos/as formadores/as")}. Ficha de registo do processo de seleção dos/as formadores/as</h2>
                                                        <div className="flex justify-end items-center min-w-[10%]">
                                                            <button onClick={gerarNotaExplicativaFormadores} className="mr-1 text-xl text-text-main-color tooltip"
                                                                variant="gradient" data-tip={"Template Editável"}>
                                                                <RiFileEditLine />
                                                            </button>
                                                            <ModalUpload icon={<FiUpload />} topicoAPI={"fichaRegistoProcessoSelecao"} topicoTitulo={"Ficha de registo do processo de seleção dos/as formadores/as"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                            <ModalDownload icon={<FiDownload />} topicoAPI={"fichaRegistoProcessoSelecao"} topicoTitulo={"Ficha de registo do processo de seleção dos/as formadores/as"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                        </div>
                                                    </div>}
                                                </div>}
                                                {token != "user" && data.indice.topico32 && <> <div className={"tab-container last-of-type:border-b " + (subTabOppened === 2 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(2)) }}>
                                                    <h2 className="tab-title">{getPositionTopic("topico31")}.2 Pessoal Não docente</h2>
                                                    <svg className={"w-3 h-3 shrink-0 " + (subTabOppened !== 2 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                    </svg>
                                                </div>
                                                    {tabOppened === 3 && subTabOppened === 2 && <div className="p-5 border border-b-0 border-gray-200">
                                                        {data.indice.topico32.includes("Listagem dos Coordenadores/Mediadores/Consultores de Ligação") && <div className={"tab-container last-of-type:border-b " + (sub2TabOppened === 1 ? "bg-fourth-color" : undefined)} onClick={() => { setSub2TabOppened(toggle2SubTab(1)) }}>
                                                            <h2 className="tab-title">{getPositionTopic("topico31")}.2.1 Listagem dos Coordenadores/ Mediadores/ Consultores de Ligação</h2>
                                                            <div className="flex justify-end items-center min-w-[10%]">
                                                                <button onClick={gerarListagemEquipaPedagogica} className="text-xl text-text-main-color tooltip"
                                                                    variant="gradient" data-tip={"Download Direto"}>
                                                                    <CgFileDocument />
                                                                </button>
                                                            </div>
                                                        </div>}
                                                        {data.indice.topico32.includes("Contrato de Prestação de Serviços dos Coordenadores/Mediadores/Consultor de Ligação") && <div className={"tab-container last-of-type:border-b " + (sub2TabOppened === 3 ? "bg-fourth-color" : undefined)} onClick={() => { setSub2TabOppened(toggle2SubTab(3)) }}>
                                                            <h2 className="tab-title max-w-[65%] md:max-w-none">{getPositionTopic("topico31")}.2.2 Contrato de Prestação de Serviços dos Coordenadores/ Mediadores/ Consultor de Ligação</h2>
                                                            <div className="flex justify-end items-center min-w-[10%]">
                                                                {data.contrato_path ? <button onClick={() => downloadFile(data.contrato_path)} className="text-xl text-text-main-color tooltip"
                                                                    variant="gradient" data-tip={"Download Direto"}>
                                                                    <CgFileDocument />
                                                                </button> : <button className="mr-1 text-xl text-gray-400 tooltip"
                                                                    variant="gradient" data-tip={"Sem contrato"}>
                                                                    <CgFileDocument />
                                                                </button>}
                                                                <ModalUpload icon={<FiUpload />} topicoAPI={"contrato"} topicoTitulo={"Contrato de Prestação de Serviços dos Coordenadores/Mediadores/Consultor de Ligação"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                                <ModalDownload icon={<FiDownload />} topicoAPI={"contrato"} topicoTitulo={"Contrato de Prestação de Serviços dos Coordenadores/Mediadores/Consultor de Ligação"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                            </div>
                                                        </div>}
                                                    </div>}</>}
                                            </div>}</>}
                                    {/* TAB 3 END*/}
                                    {/* TAB 4*/}
                                    {data.indice.topico4 && <><div className={"tab-container last-of-type:border-b " + (tabOppened === 4 ? "bg-secundary-color" : undefined)} onClick={() => { setTabOppened(toggleTab(4)); setSubTabOppened(toggleSubTab(0)); setSub2TabOppened(0) }}>
                                        <h2 className="tab-title">{getPositionTopic3("topico4")}. SUMÁRIOS E PRESENÇAS</h2>
                                        <svg className={"w-3 h-3 shrink-0 " + (tabOppened !== 4 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                        </svg>
                                    </div>
                                        {tabOppened === 4 &&
                                            <div className="p-5 border border-b-0 border-gray-200">
                                                {data.indice.topico4.includes("Folhas de Sumários e Presenças") && <div className={"tab-container last-of-type:border-b rounded-t-lg " + (subTabOppened === 1 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(1)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico4")}.{getPositionNumber4("Folhas de Sumários e Presenças")}. Folhas de Sumários e Presenças</h2>
                                                    <svg className={"w-3 h-3 shrink-0 " + (subTabOppened !== 1 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                    </svg>
                                                </div>}
                                                {tabOppened === 4 && subTabOppened === 1 ? <>{data.ufcds
                                                    .filter((item) => item.formadores.length > 0)
                                                    .map((item, index) => (
                                                        <div key={index}>
                                                            <div className={" tab-container last-of-type:border-b text-text-main-color w-11/12 mx-auto my-3 p-2 rounded-lg cursor-pointer hover:bg-gray-100 text-left " + (sub2TabOppened === index + 1 && "bg-fifth-color")} onClick={() => { GetUFCD(index, item._id); setSub2TabOppened(toggle2SubTab(index + 1)) }}>
                                                                <h3>{getPositionTopic3("topico4")}.{getPositionNumber4("Folhas de Sumários e Presenças")}.{index + 1}. {item.codeUfcd} - {item.name}</h3>
                                                                <div className="flex flex-row gap-1">
                                                                    <ModalUploadUFCD icon={<FiUpload />} topicoAPI={"folhasPresencaGeral"} topicoTitulo={"Folhas de Presença Geral"} id_curso={item._id} user={token} tooltip={"Upload Ficheiro Geral"}></ModalUploadUFCD>
                                                                    <ModalDownloadUFCD icon={<FiDownload />} topicoAPI={"folhasPresencaGeral"} topicoTitulo={"Folhas de Presença Geral"} id_curso={item._id} user={token} tooltip={"Download Ficheiro Geral"} />
                                                                    <svg className={"w-3 h-3 shrink-0 " + (sub2TabOppened !== index + 1 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            {sub2TabOppened === index + 1 && eventos ?
                                                                <div className="px-2 m-3 mx-5 text-left text-text-main-color"> {eventos.map((evento, index1) => (
                                                                    <div className={"cursor-pointer rounded-lg m-1 p-1 border border-gray-200 hover:bg-gray-100 "} key={index1}>
                                                                        <div className="flex items-center justify-between">
                                                                            {getPositionTopic3("topico4")}.{getPositionNumber4("Folhas de Sumários e Presenças")}.{index + 1}.{index1 + 1}. {evento.title}
                                                                            <div className="flex items-center justify-between">
                                                                                <button onClick={() => { gerarFolhaPresencas(item._id, evento.title, evento.ufcds[0].formato, data.assinaturas) }} className="mr-1 text-xl text-text-main-color tooltip"
                                                                                    variant="gradient" data-tip={"Download Direto"}>
                                                                                    <CgFileDocument />
                                                                                </button>
                                                                                <ModalUploadEventos icon={<FiUpload />} topicoAPI={"folhaPresencas"} topicoTitulo={"Folhas de Sumários e Presenças"} id_curso={evento._id} user={token} tooltip={"Upload Ficheiro"} />
                                                                                <ModalDownloadEventos icon={<FiDownload />} topicoAPI={"folhaPresencas"} topicoTitulo={"Folhas de Sumários e Presenças"} id_curso={evento._id} user={token} tooltip={"Download Ficheiro"} />
                                                                            </div>
                                                                        </div>{/* } */}
                                                                    </div>
                                                                ))}
                                                                </div> : ""}
                                                        </div>
                                                    ))}</> : ""}
                                                {data.indice.topico4.includes("Justificação de faltas") && <div className={"tab-container last-of-type:border-b " + (subTabOppened === 3 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(3)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico4")}.{getPositionNumber4("Justificação de faltas")}. Justificação de faltas</h2>
                                                    <div>
                                                        <button onClick={gerarFolhaJustificacaoFaltas} className="mr-1 text-xl text-text-main-color tooltip"
                                                            variant="gradient" data-tip={"Download Direto"}>
                                                            <CgFileDocument />
                                                        </button>
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"justificacaoFaltas"} topicoTitulo={"Justificação de faltas"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"justificacaoFaltas"} topicoTitulo={"Justificação de faltas"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                                {data.indice.topico4.includes("Mapas de Assiduidade Mensal") && token != "user" && <div className={"tab-container last-of-type:border-b " + (subTabOppened === 4 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(4)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico4")}.{getPositionNumber4("Mapas de Assiduidade Mensal")}. Mapas de Assiduidade Mensal/Apoios Sociais</h2>
                                                    <div>
                                                        <button className="mr-1 text-xl text-text-main-color tooltip"
                                                            variant="gradient" data-tip={"Download Direto"}>
                                                            <CgFileDocument />
                                                        </button>
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"mapasAssiduidadeMensal"} topicoTitulo={"Mapas de Assiduidade Mensal"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"mapasAssiduidadeMensal"} topicoTitulo={"Mapas de Assiduidade Mensal"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                                {data.indice.topico4.includes("Planos de sessão") &&
                                                    <div>
                                                        <div className={"tab-container last-of-type:border-b rounded-t-lg " + (subTabOppened === 2 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(2)); setSub2TabOppened(0) }}>
                                                            <h2 className="tab-title">{getPositionTopic3("topico4")}.{getPositionNumber4("Planos de sessão")}. Planos de sessão</h2>
                                                            <svg className={"w-3 h-3 shrink-0 " + (subTabOppened !== 2 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                            </svg>
                                                        </div>
                                                        {tabOppened === 4 && subTabOppened === 2 ? <>{data.ufcds
                                                            .filter((item) => item.formadores.length > 0)
                                                            .map((item, index) => (
                                                                <div key={index}>
                                                                    <div className={" tab-container last-of-type:border-b text-text-main-color w-11/12 mx-auto my-3 p-2 rounded-lg cursor-pointer hover:bg-gray-100 text-left " + (sub2TabOppened === index + 1 && "bg-fifth-color")} onClick={() => { GetUFCD(index, item._id); setSub2TabOppened(toggle2SubTab(index + 1)) }}>
                                                                        <h3>{getPositionTopic3("topico4")}.{getPositionNumber4("Planos de sessão")}.{index + 1}. {item.codeUfcd} - {item.name}</h3>
                                                                        <div className="flex flex-row gap-1">
                                                                            <ModalUploadUFCD icon={<FiUpload />} topicoAPI={"planosSessaoGeral"} topicoTitulo={"Plano de Sessão Geral"} id_curso={item._id} user={token} tooltip={"Upload Ficheiro Geral"}></ModalUploadUFCD>
                                                                            <ModalDownloadUFCD icon={<FiDownload />} topicoAPI={"planosSessaoGeral"} topicoTitulo={"Plano de Sessão Geral"} id_curso={item._id} user={token} tooltip={"Download Ficheiro Geral"} />
                                                                            <svg className={"w-3 h-3 shrink-0 " + (sub2TabOppened !== index + 1 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                    {sub2TabOppened === index + 1 && eventos ?
                                                                        <div className="px-2 m-3 mx-5 text-left text-text-main-color"> {eventos.map((evento, index1) => (
                                                                            <div className={"rounded-lg m-1 p-1 border border-gray-200 hover:bg-gray-100 "} key={index1}>
                                                                                <div className="flex items-center justify-between">
                                                                                    {getPositionTopic3("topico4")}.{getPositionNumber4("Planos de sessão")}.{index + 1}.{index1 + 1}. {evento.title}
                                                                                    <div className="flex items-center justify-between">
                                                                                        <button onClick={() => PlanoDeSessao(data,item,evento)} className="mr-1 text-xl text-text-main-color tooltip"
                                                                                            variant="gradient" data-tip={"Template Editável"}>
                                                                                            <RiFileEditLine />
                                                                                        </button>
                                                                                        <ModalUploadEventos icon={<FiUpload />} topicoAPI={"planosSessao"} topicoTitulo={"Planos de sessão"} id_curso={evento._id} user={token} tooltip={"Upload Ficheiro"} />
                                                                                        <ModalDownloadEventos icon={<FiDownload />} topicoAPI={"planosSessao"} topicoTitulo={"Planos de sessão"} id_curso={evento._id} user={token} tooltip={"Download Ficheiro"} />
                                                                                    </div>
                                                                                </div>{/* } */}
                                                                            </div>
                                                                        ))}
                                                                        </div> : ""}
                                                                </div>
                                                            ))}</> : ""}
                                                    </div>}
                                                {data.indice.topico4.includes("Roteiros Pedagógicos") && <div>
                                                    <div className={"tab-container last-of-type:border-b rounded-t-lg " + (subTabOppened === 5 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(5)) }}>
                                                        <h2 className="tab-title">{getPositionTopic3("topico4")}.{getPositionNumber4("Roteiros Pedagógicos")}. Roteiros Pedagógicos</h2>
                                                        <svg className={"w-3 h-3 shrink-0 " + (subTabOppened !== 5 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                        </svg>
                                                    </div>
                                                    {tabOppened === 4 && subTabOppened === 5 ? <>{data.ufcds
                                                        .filter((item) => item.formadores.length > 0)
                                                        .map((item, index) => (
                                                            <div key={index} className={" tab-container last-of-type:border-b text-text-main-color w-11/12 mx-auto my-3 p-2 rounded-lg cursor-pointer hover:bg-gray-100 text-left" + (sub2TabOppened === index + 1 && "bg-fifth-color")} >
                                                                {/* {item.modulos.length > 0 ? */}
                                                                <div className="flex items-center justify-between w-full">
                                                                    <div>{getPositionTopic3("topico4")}.{getPositionNumber4("Roteiros Pedagógicos")}.{index + 1}. {item.codeUfcd} - {item.name}</div>
                                                                    {/*  <button onClick={() => gerarRoteiroPedagógico(item._id)} className="mr-1 text-xl text-text-main-color tooltip"
                                                                            variant="gradient" data-tip={"Download Direto"}>
                                                                            <CgFileDocument />
                                                                        </button> */}
                                                                    <ModalUploadUFCD icon={<FiUpload />} topicoAPI={"roteiroPedagogicoUfcd"} topicoTitulo={"Roteiro Pedagógico"} id_curso={item._id} user={token} tooltip={"Upload Ficheiro"}></ModalUploadUFCD>
                                                                    <ModalDownloadUFCD icon={<FiDownload />} topicoAPI={"roteiroPedagogicoUfcd"} topicoTitulo={"Roteiro Pedagógico"} id_curso={item._id} user={token} tooltip={"Download Ficheiro"} />
                                                                </div>{/*  :
                                                                    <div>
                                                                        <h3>{getPositionTopic3("topico4")}.{getPositionNumber4("Roteiros Pedagógicos")}.{index + 1}. {item.codeUfcd} - {item.name}</h3>
                                                                        <button>Sem download</button>
                                                                    </div> }*/
                                                                }
                                                            </div>
                                                        ))}</> : ""}
                                                </div>}
                                            </div>}</>}
                                    {/* TAB 4 END*/}
                                    {/* TAB 5*/}
                                    {data.indice.topico5 && <><div className={"tab-container last-of-type:border-b " + (tabOppened === 5 ? "bg-secundary-color" : undefined)} onClick={() => { setTabOppened(toggleTab(5)); setSubTabOppened(0); setSub2TabOppened(0) }}>
                                        <h2 className="tab-title">{getPositionTopic3("topico5")}. MATERIAIS DE APOIO</h2>
                                        <svg className={"w-3 h-3 shrink-0 " + (tabOppened !== 2 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                        </svg>
                                    </div>
                                        {tabOppened === 5 &&
                                            <div className="p-5 border border-b-0 border-gray-200">
                                                {data.indice.topico5.includes("Manuais/Textos e documentação de apoio") && <div className={"tab-container last-of-type:border-b rounded-t-lg " + (subTabOppened === 1 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleTab(1)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico5")}.{getPositionNumber5("Manuais/Textos e documentação de apoio")}. Manuais/Textos e documentação de apoio</h2>
                                                    <div className="flex justify-end items-center min-w-[10%]">
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"manuaisTextosDocumentacao"} topicoTitulo={"Manuais/Textos e documentação de apoio"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"manuaisTextosDocumentacao"} topicoTitulo={"Manuais/Textos e documentação de apoio"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                                {data.indice.topico5.includes("Outros documentos") && <div className={"tab-container last-of-type:border-b rounded-b-lg " + (subTabOppened === 2 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleTab(2)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico5")}.{getPositionNumber5("Outros documentos")}. Outros documentos</h2>
                                                    <div>
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"outrosDocumentos"} topicoTitulo={"Outros documentos"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"outrosDocumentos"} topicoTitulo={"Outros documentos"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                            </div>}</>}
                                    {/* TAB 5 END*/}
                                    {/* TAB 6*/}
                                    {data.indice.topico6 && <><div className={"tab-container last-of-type:border-b " + (tabOppened === 6 ? "bg-secundary-color" : undefined)} onClick={() => { setTabOppened(toggleTab(6)); setSubTabOppened(0); setSub2TabOppened(0) }}>
                                        <h2 className="tab-title">{getPositionTopic3("topico6")}. ACOMPANHAMENTO DA FORMAÇÃO</h2>
                                        <svg className={"w-3 h-3 shrink-0 " + (tabOppened !== 6 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                        </svg>
                                    </div>
                                        {tabOppened === 6 &&
                                            <div className="p-5 border border-b-0 border-gray-200">
                                                {data.indice.topico6.includes("Evidências de acompanhamento da ação/ Ficha-relatório ou fotos (presencial)") && token != "user" && <div className={"tab-container last-of-type:border-b rounded-b-lg " + (subTabOppened === 4 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleTab(4)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico6")}.{getPositionNumber6("Evidências de acompanhamento da ação/ Ficha-relatório ou fotos (presencial)")}. Evidências de acompanhamento da ação/ Ficha-relatório ou fotos (presencial)</h2>
                                                    <div>
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"evidenciasAcopanhamentoAcao"} topicoTitulo={"Evidências de acompanhamento da ação/ Ficha-relatório ou fotos"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"evidenciasAcopanhamentoAcao"} topicoTitulo={"Evidências de acompanhamento da ação/ Ficha-relatório ou fotos"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                                {data.indice.topico6.includes("Evidências de coordenação da Equipa Pedagógicas/Atas ou outras evidências (presencial)") && token != "user" && <div className={"tab-container last-of-type:border-b " + (subTabOppened === 3 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleTab(3)) }}>
                                                    <h2 className="tab-title max-w-[70%] md:max-w-none">{getPositionTopic3("topico6")}.{getPositionNumber6("Evidências de coordenação da Equipa Pedagógicas/Atas ou outras evidências (presencial)")}. Evidências de coordenação da Equipa Pedagógicas/Atas ou outras evidências (presencial)</h2>
                                                    <div className="flex justify-end items-center min-w-[10%]">
                                                        <button onClick={gerarAta} className="mr-1 text-xl text-text-main-color tooltip"
                                                            variant="gradient" data-tip={"Download Direto"}>
                                                            <CgFileDocument />
                                                        </button>
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"evidenciasCoordenacaoEquipa"} topicoTitulo={"Evidências de coordenação da Equipa Pedagógicas/Atas ou outras evidências"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"evidenciasCoordenacaoEquipa"} topicoTitulo={"Evidências de coordenação da Equipa Pedagógicas/Atas ou outras evidências"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                                {data.indice.topico6.includes("Ficha de desistências") && token != "user" && <div className={"tab-container last-of-type:border-b " + (subTabOppened === 2 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleTab(2)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico6")}.{getPositionNumber6("Ficha de desistências")}. Ficha de desistências</h2>
                                                    <div className="flex justify-end items-center min-w-[10%]">
                                                        <button onClick={gerarFolhadeDesistencia} className="mr-1 text-xl text-text-main-color tooltip"
                                                            variant="gradient" data-tip={"Template Editável"}>
                                                            <RiFileEditLine />
                                                        </button>
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"fichaDesistencias"} topicoTitulo={"Ficha de desistências"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"fichaDesistencias"} topicoTitulo={"Ficha de desistências"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                                {data.indice.topico6.includes("Ficha de ocorrências") && <div className={"tab-container last-of-type:border-b rounded-t-lg " + (subTabOppened === 1 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleTab(1)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico6")}.{getPositionNumber6("Ficha de ocorrências")}. Ficha de ocorrências</h2>
                                                    <div className="flex justify-end items-center min-w-[10%]">
                                                        <button onClick={gerarFolhadeOcorrencias} className="mr-1 text-xl text-text-main-color tooltip"
                                                            variant="gradient" data-tip={"Download Direto"}>
                                                            <CgFileDocument />
                                                        </button>
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"fichaOcorrencias"} topicoTitulo={"Ficha de ocorrências"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"fichaOcorrencias"} topicoTitulo={"Ficha de ocorrências"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                            </div>}</>}
                                    {/* TAB 6 END*/}
                                    {/* TAB 7*/}
                                    {data.indice.topico7 && <><div className={"tab-container last-of-type:border-b " + (tabOppened === 7 ? "bg-secundary-color" : undefined)} onClick={() => { setTabOppened(toggleTab(7)); setSubTabOppened(toggleTab(7)); setSub2TabOppened(0) }}>
                                        <h2 className="tab-title">{getPositionTopic3("topico7")}. AVALIAÇÃO DA APRENDIZAGEM</h2>
                                        <svg className={"w-3 h-3 shrink-0 " + (tabOppened !== 7 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                        </svg>
                                    </div>
                                        {tabOppened === 7 &&
                                            <div className="p-5 border border-b-0 border-gray-200">
                                                {data.indice.topico7.includes("Atividades/Trabalhos/Exercícios (presencial)") && <div className={"tab-container last-of-type:border-b rounded-b-lg " + (subTabOppened === 4 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleTab(4)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico7")}.{getPositionNumber7("Atividades/Trabalhos/Exercícios (presencial)")}. Atividades/ Trabalhos/ Exercícios (presencial)</h2>
                                                    <div className="flex justify-end items-center min-w-[10%]">
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"atividadesTrabalhosExercicios"} topicoTitulo={"Atividades/Trabalhos/Exercícios"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"atividadesTrabalhosExercicios"} topicoTitulo={"Atividades/Trabalhos/Exercícios"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                                {data.indice.topico7.includes("Avaliação diagnóstica") && <div className={"tab-container last-of-type:border-b rounded-t-lg " + (subTabOppened === 1 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleTab(1)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico7")}.{getPositionNumber7("Avaliação diagnóstica")}. Avaliação diagnóstica</h2>
                                                    <div className="flex justify-end items-center min-w-[10%]">
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"avaliacaoDiagnostia"} topicoTitulo={"Avaliação diagnóstica"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"avaliacaoDiagnostia"} topicoTitulo={"Avaliação diagnóstica"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                                {data.indice.topico7.includes("Ficha de instrução e registo do PRA") && <div className={"tab-container last-of-type:border-b rounded-b-lg " + (subTabOppened === 6 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleTab(6)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico7")}.{getPositionNumber7("Ficha de instrução e registo do PRA")}. Ficha de instrução e registo do PRA</h2>
                                                </div>}

                                                {data.indice.topico7.includes("Grelhas de Cotação de Prova") && <div className={"tab-container last-of-type:border-b " + (subTabOppened === 3 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleTab(3)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico7")}.{getPositionNumber7("Grelhas de Cotação de Prova")}. Grelhas de Cotação de Prova</h2>
                                                    <div className="flex justify-end items-center min-w-[10%]">
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"grelhasCotacaoProva"} topicoTitulo={"Grelhas de Cotação de Provas"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"grelhasCotacaoProva"} topicoTitulo={"Grelhas de Cotação de Provas"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                                {data.indice.topico7.includes("Pautas de Avaliação") && <div className={"tab-container last-of-type:border-b rounded-t-lg " + (subTabOppened === 5 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(5)); setSub2TabOppened(0) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico7")}.{getPositionNumber7("Pautas de Avaliação")}. Pautas de Avaliação</h2>
                                                    <div>
                                                        <svg className={"w-3 h-3 shrink-0 " + (subTabOppened !== 5 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                }
                                                {tabOppened === 7 && subTabOppened === 5 && <>
                                                    {data.ufcds
                                                        .filter((item) => item.formadores.length > 0)
                                                        .map((item, index) => (
                                                            <div key={index}>
                                                                <div className={" tab-container last-of-type:border-b text-text-main-color w-11/12 mx-auto my-3 p-2 rounded-lg cursor-pointer hover:bg-gray-100 text-left " + (sub2TabOppened === index + 1 && "bg-fifth-color")} onClick={() => { GetUFCD(index, item._id); setSub2TabOppened(toggle2SubTab(index + 1)) }}>
                                                                    <div className="flex items-center justify-between w-full">
                                                                        <h3 className="max-w-[70%] md:max-w-none">{getPositionTopic3("topico7")}.{getPositionNumber7("Pautas de Avaliação")}.{index + 1}. {item.codeUfcd} - {item.name}</h3>
                                                                        {item.fechoFormador ? <div className="flex justify-end items-center min-w-[10%]"> <button onClick={() => excelGeneratorPauta(item)} className="mr-1 text-xl text-text-main-color tooltip"
                                                                            variant="gradient" data-tip={"Template Editável"}>
                                                                            <PiMicrosoftExcelLogo />
                                                                        </button>
                                                                            <ModalUploadUFCD icon={<FiUpload />} topicoAPI={"pautasAvaliacao"} topicoTitulo={"Pautas de Avaliação"} id_curso={item._id} user={token} tooltip={"Upload Ficheiro"}></ModalUploadUFCD>
                                                                            <ModalDownloadUFCD icon={<FiDownload />} topicoAPI={"pautasAvaliacao"} topicoTitulo={"Pautas de Avaliação"} id_curso={item._id} user={token} tooltip={"Download Ficheiro"} />
                                                                        </div> :
                                                                            <div className="flex justify-end items-center min-w-[10%]"><button className="mr-1 text-xl  text-gray-400 tooltip"
                                                                                variant="gradient" data-tip={"Template Editável"}>
                                                                                <PiMicrosoftExcelLogo />
                                                                            </button>
                                                                                <ModalUploadUFCD icon={<FiUpload />} topicoAPI={"pautasAvaliacao"} topicoTitulo={"Pautas de Avaliação"} id_curso={item._id} user={token} tooltip={"Upload Ficheiro"}></ModalUploadUFCD>
                                                                                <ModalDownloadUFCD icon={<FiDownload />} topicoAPI={"pautasAvaliacao"} topicoTitulo={"Pautas de Avaliação"} id_curso={item._id} user={token} tooltip={"Download Ficheiro"} /> </div>}
                                                                    </div>
                                                                </div>
                                                            </div>)
                                                        )
                                                    }
                                                </>}
                                                {data.indice.topico7.includes("Testes/Atividades/Trabalhos/Exercícios e corrigendas") && <div>
                                                    <div className={"tab-container last-of-type:border-b rounded-t-lg " + (subTabOppened === 2 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(2)) }}>
                                                        <h2 className="tab-title">{getPositionTopic3("topico7")}.{getPositionNumber7("Testes/Atividades/Trabalhos/Exercícios e corrigendas")}. Testes/ Atividades/ Trabalhos/ Exercícios e corrigendas</h2>
                                                        <svg className={"w-3 h-3 shrink-0 " + (subTabOppened !== 5 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                        </svg>
                                                    </div>
                                                    {tabOppened === 7 && subTabOppened === 2 ? <>{data.ufcds
                                                        .filter((item) => item.formadores.length > 0)
                                                        .map((item, index) => (
                                                            <div key={index}>
                                                                <div className={" tab-container last-of-type:border-b text-text-main-color w-11/12 mx-auto my-3 p-2 rounded-lg cursor-pointer hover:bg-gray-100 text-left " + (sub2TabOppened === index + 1 && "bg-fifth-color")} onClick={() => { GetUFCD(index, item._id); setSub2TabOppened(toggle2SubTab(index + 1)) }}>
                                                                    <div className="flex items-center justify-between w-full">
                                                                        <h3 className="max-w-[70%] md:max-w-none">{getPositionTopic3("topico7")}.{getPositionNumber7("Testes/Atividades/Trabalhos/Exercícios e corrigendas")}.{index + 1}. {item.codeUfcd} - {item.name}</h3>
                                                                        <div className="flex justify-end items-center min-w-[10%]">
                                                                            <button onClick={() => gerarCorrigenda(data._id, item._id)} className="mr-1 text-xl text-text-main-color tooltip"
                                                                                variant="gradient" data-tip={"Download Direto"}>
                                                                                <CgFileDocument />
                                                                            </button>
                                                                            <ModalUpload icon={<FiUpload />} topicoAPI={"testesAtividadesTrabalhosExercicios"} topicoTitulo={"Testes/Atividades/Trabalhos/ Exercícios e corrigendas"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                                            <ModalDownload icon={<FiDownload />} topicoAPI={"testesAtividadesTrabalhosExercicios"} topicoTitulo={"Testes/Atividades/Trabalhos/ Exercícios e corrigendas"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        ))}</> : ""}
                                                </div>}
                                            </div>}</>}
                                    {/* TAB 7 END*/}
                                    {/* TAB 8*/}
                                    {data.indice.topico8 && token != "user" && <><div className={"tab-container last-of-type:border-b rounded-b-lg " + (tabOppened === 8 ? "bg-secundary-color" : undefined)} onClick={() => { setTabOppened(toggleTab(8)); setSubTabOppened(0); setSub2TabOppened(0) }}>
                                        <h2 className="tab-title">{getPositionTopic3("topico8")}. ESTÁGIOS/FPCT</h2>
                                        <svg className={"w-3 h-3 shrink-0 " + (tabOppened !== 8 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                        </svg>
                                    </div>
                                        {tabOppened === 8 &&
                                            <div className="p-5 border border-b-0 border-gray-200">
                                                {data.indice.topico8.includes("Avaliação do/a formando/a") && <div className={"tab-container last-of-type:border-b rounded-b-lg " + (subTabOppened === 5 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleTab(5)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico8")}.{getPositionNumber8("Avaliação do/a formando/a")}. Avaliação do/a formando/a</h2>
                                                    <div className="flex justify-end items-center min-w-[10%]">
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"avaliacaoFormando"} topicoTitulo={"Avaliação do/a formando/a"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"avaliacaoFormando"} topicoTitulo={"Avaliação do/a formando/a"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                                {data.indice.topico8.includes("Ficha de assiduidade do/a formando/a") && <div className={"tab-container last-of-type:border-b rounded-b-lg " + (subTabOppened === 4 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleTab(4)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico8")}.{getPositionNumber8("Ficha de assiduidade do/a formando/a")}. Ficha de assiduidade do/a formando/a</h2>
                                                    <div className="flex justify-end items-center min-w-[10%]">
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"fichaAssiduidadeFormando"} topicoTitulo={"Ficha de assiduidade do/a formando/a"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"fichaAssiduidadeFormando"} topicoTitulo={"Ficha de assiduidade do/a formando/a"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                                {data.indice.topico8.includes("Ficha de reunião com entidade acolhedora") && <div className={"tab-container last-of-type:border-b " + (subTabOppened === 2 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleTab(2)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico8")}.{getPositionNumber8("Ficha de reunião com entidade acolhedora")}. Ficha de reunião com entidade acolhedora</h2>
                                                    <div className="flex justify-end items-center min-w-[10%]">
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"fichaReuniaoEntidade"} topicoTitulo={"Ficha de reunião com entidade acolhedora"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"fichaReuniaoEntidade"} topicoTitulo={"Ficha de reunião com entidade acolhedora"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                                {data.indice.topico8.includes("Outros documentos referentes à FPCT") && <div className={"tab-container last-of-type:border-b rounded-b-lg " + (subTabOppened === 7 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleTab(7)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico8")}.{getPositionNumber8("Outros documentos referentes à FPCT")}. Outros documentos referentes à FPCT</h2>
                                                    <div className="flex justify-end items-center min-w-[10%]">
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"outrosDoumentosFPCT"} topicoTitulo={"Outros documentos referentes à FPCT"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"outrosDoumentosFPCT"} topicoTitulo={"Outros documentos referentes à FPCT"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                                {data.indice.topico8.includes("Plano de estágio") && <div className={"tab-container last-of-type:border-b rounded-b-lg " + (subTabOppened === 3 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleTab(3)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico8")}.{getPositionNumber8("Plano de estágio")}. Plano de estágio</h2>
                                                    <div className="flex justify-end items-center min-w-[10%]">
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"planoEstagio"} topicoTitulo={"Plano de estágio"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"planoEstagio"} topicoTitulo={"Plano de estágio"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                                {data.indice.topico8.includes("Protocolo de estágio/FPCT") && <div className={"tab-container last-of-type:border-b rounded-t-lg " + (subTabOppened === 1 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleTab(1)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico8")}.{getPositionNumber8("Protocolo de estágio/FPCT")}. Protocolo de estágio/FPCT</h2>
                                                    <div className="flex justify-end items-center min-w-[10%]">
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"protocoloEstagio"} topicoTitulo={"Protocolo de estágio/FPCT"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"protocoloEstagio"} topicoTitulo={"Protocolo de estágio/FPCT"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                                {data.indice.topico8.includes("Relatório de estágio") && <div className={"tab-container last-of-type:border-b rounded-b-lg " + (subTabOppened === 6 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleTab(6)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico8")}.{getPositionNumber8("Relatório de estágio")}. Relatório de estágio</h2>
                                                    <div className="flex justify-end items-center min-w-[10%]">
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"relatorioEstagio"} topicoTitulo={"Relatório de estágio"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"relatorioEstagio"} topicoTitulo={"Relatório de estágio"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                            </div>}</>}
                                    {/* TAB 8 END*/}
                                    {/* TAB 9*/}
                                    {data.indice.topico9 && <>
                                        <div className={"tab-container last-of-type:border-b rounded-b-lg " + (tabOppened === 9 ? "bg-secundary-color" : undefined)} onClick={() => { setTabOppened(toggleTab(9)); setSubTabOppened(0) }}>
                                            <h2 className="tab-title">{getPositionTopic3("topico9")}. AVALIAÇÃO DA FORMAÇÃO</h2>
                                            <svg className={"w-3 h-3 shrink-0 " + (tabOppened !== 9 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                            </svg>
                                        </div>
                                        {tabOppened === 9 &&
                                            <div className="p-5 border border-b-0 border-gray-200">
                                                {data.indice.topico9.includes("Avaliação da ação pelo/a formador/a") && <div className={"tab-container last-of-type:border-b rounded-t-lg " + (subTabOppened === 1 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleTab(1)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico9")}.{getPositionNumber9("Avaliação da ação pelo/a formador/a")}. Avaliação da ação pelo/a formador/a</h2>
                                                    <div className="flex justify-end items-center min-w-[10%]">
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"avaliacaoAcaoFormador"} topicoTitulo={"Avaliação da ação pelo/a formador/a"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"avaliacaoAcaoFormador"} topicoTitulo={"Avaliação da ação pelo/a formador/a"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                                {data.indice.topico9.includes("Avaliação da ação pelo/a formando/a - PERCURSO/EFA") && token != "user" && <div className={"tab-container last-of-type:border-b " + (subTabOppened === 2 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleTab(2)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico9")}.{getPositionNumber9("Avaliação da ação pelo/a formando/a - PERCURSO/EFA")}. Avaliação da ação pelo/a formando/a - PERCURSO/EFA</h2>
                                                    <div className="flex justify-end items-center min-w-[10%]">
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"avaliacaoAcaoFormandoPercurso"} topicoTitulo={"Avaliação da ação pelo/a formando/a - PERCURSO/EFA"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"avaliacaoAcaoFormandoPercurso"} topicoTitulo={"Avaliação da ação pelo/a formando/a - PERCURSO/EFA"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                                {data.indice.topico9.includes("Avaliação da ação pelo/a formando/a por UFCD") && token != "user" && <div>
                                                    <div className={"tab-container last-of-type:border-b rounded-b-lg " + (subTabOppened === 6 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(6)); }}>
                                                        <h2 className="tab-title">{getPositionTopic3("topico9")}.{getPositionNumber9("Avaliação da ação pelo/a formando/a por UFCD")}. Avaliação da ação pelo/a formando/a por UFCD</h2>
                                                        <svg className={"w-3 h-3 shrink-0 " + (subTabOppened !== 6 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                        </svg>
                                                    </div>

                                                </div>
                                                }
                                                {tabOppened === 9 && subTabOppened === 6 && <>
                                                    {
                                                        data.ufcds
                                                            .filter((item) => item.formadores.length > 0)
                                                            .map((item, index) => (
                                                                <div key={index}>
                                                                    <div className={"tab-container last-of-type:border-b text-text-main-color w-11/12 mx-auto my-3 p-2 rounded-lg cursor-pointer hover:bg-gray-100 text-left " + (sub2TabOppened === index + 1 && "bg-fifth-color")} onClick={() => { setSub2TabOppened(toggle2SubTab(index + 1)) }}>
                                                                        <h3>{getPositionTopic3("topico9")}.{getPositionNumber9("Avaliação da ação pelo/a formando/a por UFCD")}.{index + 1}. {item.codeUfcd} - {item.name}</h3>
                                                                        <div className="flex justify-end items-center min-w-[10%]">
                                                                            <ModalUploadUFCD icon={<FiUpload />} topicoAPI={"avaliacaoAcaoFormandoUFCD"} topicoTitulo={"Avaliação da ação pelo/a formando/a por UFCD"} id_curso={item._id} user={token} tooltip={"Upload Ficheiro"}></ModalUploadUFCD>
                                                                            <ModalDownloadUFCD icon={<FiDownload />} topicoAPI={"avaliacaoAcaoFormandoUFCD"} topicoTitulo={"Avaliação da ação pelo/a formando/a por UFCD"} id_curso={item._id} user={token} tooltip={"Download Ficheiro"} />
                                                                        </div>
                                                                    </div>
                                                                </div>)
                                                            )
                                                    }
                                                </>
                                                }
                                                {data.indice.topico9.includes("Avaliação de desempenho dos/as coordenadores/as e/ou mediadores/as (outro)") && token != "user" && <div className={"tab-container last-of-type:border-b rounded-b-lg " + (subTabOppened === 5 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleTab(5)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico9")}.{getPositionNumber9("Avaliação de desempenho dos/as coordenadores/as e/ou mediadores/as (outro)")}. Avaliação de desempenho dos/as coordenadores/as e/ou mediadores/as (outro)</h2>
                                                    <div>
                                                        <button className="mr-1 text-xl text-text-main-color tooltip"
                                                            variant="gradient" data-tip={"Download Direto"} onClick={gerarFichaAvaliacaoCoordenador}>
                                                            <CgFileDocument />
                                                        </button>
                                                    </div>
                                                </div>}
                                                {data.indice.topico9.includes("Avaliação de desempenho dos/as formadores/as") && token != "user" && <div className={"tab-container last-of-type:border-b rounded-b-lg " + (subTabOppened === 10 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleSubTab(10)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico9")}.{getPositionNumber9("Avaliação de desempenho dos/as formadores/as")}. Avaliação de desempenho dos/as formadores/as</h2>
                                                    <div>
                                                        <svg className={"w-3 h-3 shrink-0 " + (tabOppened !== 10 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                        </svg>
                                                    </div>
                                                </div>}
                                                {tabOppened === 9 && subTabOppened === 10 && <>
                                                    {
                                                        data.ufcds
                                                            .filter((item) => item.formadores.length > 0)
                                                            .map((item, index) => (
                                                                <div key={index}>
                                                                    {item.evaluation && (typeof item.evaluation === 'number' || item.evaluation.length === 1) && item.formadores.length === 1 ? <div className={" tab-container last-of-type:border-b text-text-main-color w-11/12 mx-auto my-3 p-2 rounded-lg cursor-pointer hover:bg-gray-100 text-left " + (sub2TabOppened === index + 1 && "bg-fifth-color")} onClick={() => { setSub2TabOppened(toggle2SubTab(index + 1)) }}>
                                                                        <h3>{getPositionTopic3("topico9")}.{getPositionNumber9("Avaliação de desempenho dos/as formadores/as")}.{index + 1}. {item.codeUfcd} - {item.name} - {item.formadores[0].fullName}</h3>
                                                                        <button onClick={() => gerarAvaliacaoFormador(item._id)} className="mr-1 text-xl text-text-main-color tooltip"
                                                                            variant="gradient" data-tip={"Download Direto"}>
                                                                            <CgFileDocument />
                                                                        </button>
                                                                    </div> :
                                                                        item.evaluation && item.formadores.length > 1 ? <>
                                                                            <div className={"tab-container last-of-type:border-b text-text-main-color w-11/12 mx-auto my-3 p-2 rounded-lg cursor-pointer hover:bg-gray-100 text-left " + (sub2TabOppened === index + 1 && "bg-fifth-color")} onClick={() => { setSub2TabOppened(toggle2SubTab(index + 1)) }}>
                                                                                <h3>{getPositionTopic3("topico9")}.{getPositionNumber9("Avaliação de desempenho dos/as formadores/as")}.{index + 1}. {item.codeUfcd} - {item.name}</h3>
                                                                                <div>
                                                                                    <svg className={"w-3 h-3 shrink-0 " + (sub2TabOppened !== index + 1 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                                                    </svg>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                {item.formadores.map((formador, index2) => (
                                                                                    sub2TabOppened === index + 1 && (
                                                                                        <div key={index2}>
                                                                                            <div className={"tab-container last-of-type:border-b text-text-main-color w-10/12 mx-auto my-3 p-2 rounded-lg cursor-pointer hover:bg-gray-100 text-left " + (sub3TabOppened === index2 + 1 && "bg-fifth-color")} onClick={() => { setSub3TabOppened(toggle3SubTab(index2 + 1)) }}>
                                                                                                <h3>{getPositionTopic3("topico9")}.{getPositionNumber9("Avaliação de desempenho dos/as formadores/as")}.{index + 1}.{index2 + 1}. {formador.fullName}</h3>
                                                                                                {item.evaluation.find(evaluation => evaluation.formadorId === formador._id) ? <button onClick={() => gerarAvaliacaoFormador(item._id, formador._id, item.evaluation.find(evaluation => evaluation.formadorId === formador._id) || null)} className="mr-1 text-xl text-text-main-color tooltip" variant="gradient" data-tip={"Download Direto"}>
                                                                                                    <CgFileDocument />
                                                                                                </button> :
                                                                                                    <button className="mr-1 text-xl tooltip text-cancel-color"
                                                                                                        variant="gradient" data-tip={"Sem avaliação"}>
                                                                                                        <CgFileDocument />
                                                                                                    </button>}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                ))}</div>
                                                                        </>
                                                                            : <div className={"tab-container last-of-type:border-b text-text-main-color w-11/12 mx-auto my-3 p-2 rounded-lg cursor-pointer hover:bg-gray-100 text-left " + (sub2TabOppened === index + 1 && "bg-fifth-color")} onClick={() => { setSub2TabOppened(toggle2SubTab(index + 1)) }}>
                                                                                <h3>{getPositionTopic3("topico9")}.{getPositionNumber9("Avaliação de desempenho dos/as formadores/as")}.{index + 1}. {item.codeUfcd} - {item.name} - {item.formadores[0].fullName}</h3>
                                                                                <button className="mr-1 text-xl tooltip text-cancel-color"
                                                                                    variant="gradient" data-tip={"Sem avaliação"}>
                                                                                    <CgFileDocument />
                                                                                </button>
                                                                            </div>
                                                                    }
                                                                </div>)
                                                            )
                                                    }
                                                </>
                                                }

                                                {data.indice.topico9.includes("Relatório final de avaliação da ação") && token != "user" && <div className={"tab-container last-of-type:border-b rounded-b-lg " + (subTabOppened === 4 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleTab(4)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico9")}.{getPositionNumber9("Relatório final de avaliação da ação")}. Relatório final de avaliação da ação</h2>
                                                    <div className="flex justify-end items-center min-w-[10%]">
                                                        {/* <button className="mr-1 text-xl text-text-main-color tooltip"
                                                            variant="gradient" data-tip={"Download Direto"}>
                                                            <CgFileDocument />
                                                        </button> */}
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"relatorioFinalAvaliacaoAcao"} topicoTitulo={"Relatório final de avaliação da ação"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"relatorioFinalAvaliacaoAcao"} topicoTitulo={"Relatório final de avaliação da ação"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                            </div>}</>}
                                    {/* TAB 9 END*/}
                                    {/* TAB 10*/}
                                    {data.indice.topico10 && token != "user" && <><div className={"tab-container last-of-type:border-b rounded-b-lg " + (tabOppened === 10 ? "bg-secundary-color" : undefined)} onClick={() => { setTabOppened(toggleTab(10)); setSubTabOppened(0); setSub2TabOppened(0) }}>
                                        <h2 className="tab-title">{getPositionTopic3("topico10")}. CERTIFICADOS</h2>
                                        <svg className={"w-3 h-3 shrink-0 " + (tabOppened !== 10 && "rotate-180")} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                        </svg>
                                    </div>
                                        {tabOppened === 10 &&
                                            <div className="p-5 border border-b-0 border-gray-200">
                                                {data.indice.topico10.includes("Comprovativo de entrega de certificados") && <div className={"tab-container last-of-type:border-b rounded-t-lg " + (subTabOppened === 2 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleTab(2)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico10")}.{getPositionNumber10("Comprovativo de entrega de certificados")}. Comprovativo de entrega de certificados</h2>
                                                    <div className="flex justify-end items-center min-w-[10%]">
                                                        <button onClick={gerarComprovativoEntregaCertificados} className="mr-1 text-xl text-text-main-color tooltip"
                                                            variant="gradient" data-tip={"Download Direto"}>
                                                            <CgFileDocument />
                                                        </button>
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"comprovativoEntregaCertificados"} topicoTitulo={"Comprovativo de entrega de certificados"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"comprovativoEntregaCertificados"} topicoTitulo={"Comprovativo de entrega de certificados"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                                {data.indice.topico10.includes("Cópia dos certificado") && <div className={"tab-container last-of-type:border-b rounded-t-lg " + (subTabOppened === 1 ? "bg-fourth-color" : undefined)} onClick={() => { setSubTabOppened(toggleTab(1)) }}>
                                                    <h2 className="tab-title">{getPositionTopic3("topico10")}.{getPositionNumber10("Cópia dos certificado")}. Cópia dos certificados</h2>
                                                    <div>
                                                        <ModalUpload icon={<FiUpload />} topicoAPI={"copiaCertificado"} topicoTitulo={"Cópia dos certificado"} id_curso={params.id_curso} user={token} tooltip={"Upload Ficheiro"}></ModalUpload>
                                                        <ModalDownload icon={<FiDownload />} topicoAPI={"copiaCertificado"} topicoTitulo={"Cópia dos certificado"} id_curso={params.id_curso} user={token} tooltip={"Download Ficheiro"} />
                                                    </div>
                                                </div>}
                                            </div>}</>}
                                    {/* TAB 10 END*/}
                                </div>
                            </>}
                            {data && (token === "admin2" || token === "admin1" || token === "coord") && <GerarDTP />}
                            {data && data.type === "Presencial" &&
                                <div className="flex w-60 py-2 items-center justify-between px-2 text-white rounded-lg  bg-main-color mt-2">
                                    <h2 className="tab-title text-white">DTP Completo</h2>
                                    <div>
                                        <ModalUploadDTPCompleto icon={<FiUpload />} topicoAPI={"dtpCompleto"} topicoTitulo={"DTP Completo"} id_curso={params.id_curso} user={token} tooltip={"Upload DTP Completo"}></ModalUploadDTPCompleto>
                                        <ModalDownloadDTPCompleto icon={<FiDownload />} topicoAPI={"dtpCompleto"} topicoTitulo={"DTP Completo"} id_curso={params.id_curso} user={token} tooltip={"Download DTP Completo"} />
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
            </main >
            <Footer />
        </>
    );
}

export default GerirDTP;