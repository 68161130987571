import React from "react";
import { Link, useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { AreaPortalFormandos } from "./styled";
import 'react-toastify/dist/ReactToastify.css';

const PortalFormandos = () => {
    const navigate = useNavigate()
    const jwt = localStorage.getItem("formando")
    function checkLogged() {
        if (localStorage.getItem("formando")) {
            navigate("/menuformando/" + localStorage.getItem("id"))
        }
    }

    return (
        <>
            <Header />
            <AreaPortalFormandos>
                <main>
                    <Link to={'/'} className="back-button-link">
                        <img className='back-button' alt="Back" src={process.env.PUBLIC_URL+ "/img/back_arrow.svg"} />
                    </Link>
                    <div className="form-container">
                        <h1 className="title" >Portal de Formandos</h1>
                        <Link to="/candidacyformandos" className="button-highlight">Candidatura de Formandos</Link>
                        {jwt ? <button className="button-menu" onClick={checkLogged}>Menu</button> : <Link to="/loginformando" className="button-default">Login</Link>}
                    </div>
                    {/* <span className="copyright-label"> 2023 | Tecnisign </span> */}
                </main>
            </AreaPortalFormandos>
            <Footer />
        </>
    );
}

export default PortalFormandos