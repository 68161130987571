import React, { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import toastSuccess from '../../../../components/Toast/toastSuccess';
import 'react-toastify/dist/ReactToastify.css';
import toastError from '../../../../components/Toast/toastError';
import Footer from '../../../../components/Footer';
import NavbarAdmin2 from '../../../../components/Navbars/NavbarAdmin2/NavbarAdmin2';

function ResetPassword() {
    //Password
    const [antigaPassword, setAntigaPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    //Variáveis para mostrar password 
    const [showPassword1, setShowPassword1] = useState(false)
    const [showPassword2, setShowPassword2] = useState(false)
    const [showPassword3, setShowPassword3] = useState(false)

    //Funcionalidades
    const params = useParams();
    const navigate = useNavigate();

    //Função para mostrar password 
    //Mostrar pass
    const toggleShowPassword = (field) => {
        if (field === 1) {
            setShowPassword1(!showPassword1);
        } else if (field === 2) {
            setShowPassword2(!showPassword2);
        } else if (field === 3) {
            setShowPassword3(!showPassword3);
        }
    };

    //Submete a nova password na bd
    //Função de submit
    async function handleSubmit(event) {
        event.preventDefault();
        try {
            if (password !== confirmPassword) {
                toastError('As Palavras-passe não coincidem!');
                return;
            }
            const response = await fetch(`${process.env.REACT_APP_API_URL}/admins/changepassword/${params.id_candidatura}`, {
                method: 'PATCH',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'authorization': localStorage.getItem('admin2') },
                body: JSON.stringify({ password: password, antigaPassword: antigaPassword }),

            });
            toastSuccess('Palavra-passe atualizada com sucesso!')
            navigate(`/MenuSuperAdmin/Profile/` + params.id_candidatura);

        } catch (error) {
            toastError(error);
        }

    };

    return (
        <>

            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin2 currentPage={"myProfile"} />
                </div>
                <Link to={`/MenuSuperAdmin/Profile/` + params.id_candidatura} className="back-button-div-noNavbar">
                    <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                </Link>
                <div className="flex-grow mt-8">
                    <h1 className='mx-4 mt-4 title sm:mt-0'>Redefinir Palavra-passe</h1>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="antigaPassword" className='text-text-main-color'>Palavra-passe antiga</label>
                            <div className='flex items-center justify-center'>
                                <span className='mr-2'>
                                    <i className="fas fa-key text-main-color"></i>
                                </span>
                                <div className='relative'>
                                    <input type={showPassword1 ? 'text' : 'password'} id="password" name="password" className='text-lg input-field' value={antigaPassword} onChange={(e) => setAntigaPassword(e.target.value)} placeholder="Password" required />
                                    <button type="button" onClick={() => toggleShowPassword(1)} className="absolute top-1 right-1">
                                        <i className={showPassword1 ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='mt-3'>
                            <label htmlFor="password" className='text-text-main-color'>Nova Palavra-passe</label>
                            <div className='flex items-center justify-center'>
                                <span className='mr-2'>
                                    <i className="fas fa-key text-main-color"></i>
                                </span>
                                <div className='relative'>
                                    <input type={showPassword2 ? 'text' : 'password'} id="password2" name="password2" className='text-lg input-field' value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
                                    <button type="button" onClick={() => toggleShowPassword(2)} className="absolute top-1 right-1">
                                        <i className={showPassword2 ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='mt-3'>
                            <label htmlFor="confirmPassword" className='text-text-main-color'>Confirmar nova Palavra-passe</label>
                            <div className='flex items-center justify-center'>
                                <span className='mr-2'>
                                    <i className="fas fa-key text-main-color"></i>
                                </span>
                                <div className='relative'>
                                    <input type={showPassword3 ? 'text' : 'password'} id="password3" name="password3" className='text-lg input-field' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Password" required />
                                    <button type="button" onClick={() => toggleShowPassword(3)} className="absolute top-1 right-1">
                                        <i className={showPassword3 ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='mt-5 mb-8'>
                            <button className='button-geral' type="submit">
                                Confirmar
                            </button>
                        </div>
                    </form>
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main>
        </>
    );
}

export default ResetPassword;
