import React, { useState, useEffect } from "react";
import Header from "../../../../../components/Header";
import { useParams, Link, useNavigate } from 'react-router-dom'
import Footer from "../../../../../components/Footer";
import toastSuccess from "../../../../../components/Toast/toastSuccess";
import ExcelGenerator from "../../../../../components/Excel/cronogramaDisciplina/index";
import NavbarFormador from "../../../../../components/Navbars/NavbarFormador/navbarFormador";
import { FiDownload } from "react-icons/fi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";
import AlterarFormato from "../../../../../components/FormChecks/formatDates/foramatDates";
import { FaRegListAlt } from "react-icons/fa";

export default function UFCDsListed() {
    const [data, setData] = useState([]);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [loading, setLoading] = useState(true);
    const savedSearchTerm = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Formadores-disciplinas-1") : "";
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm ? savedSearchTerm : "");
    const [error, setError] = useState(null);
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Formadores-disciplinas-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Formadores-disciplinas-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)

    //filtra dados
    function filterData(data) {
        return data.filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.codeUfcd.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.percurso.name && item.percurso[0].name.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    }

    const params = useParams();
    const navigate = useNavigate()
    document.data = data

    useEffect(() => {
        Cookies.set("Formadores-disciplinas-Pag", paginaAtual);
        Cookies.set("Formadores-disciplinas-1", searchTerm);
        Cookies.set(`Formadores-disciplinas-itm1`, itemsPag);
        getUfcds()
    }, [loading, paginaAtual, nPaginas, searchTerm, itemsPag]);

    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }


    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }

    //Recebe as ufcds associadas a um formador ativas
    function getUfcds() {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/trainer/sorted/${params.id_candidatura}`,
            {
                headers: {
                    'authorization': localStorage.getItem('user')
                }
            })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    paginacaoTabelas(filterData(result));
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    }
    function gerarSistemaAvaliacao(id) {
        fetch(`${process.env.REACT_APP_API_URL}/formadores/sistemaAvaliacao/${id}`, {
            headers: {
                'authorization': localStorage.getItem('user')
            },
        })
        setLoading(false)
        toastSuccess("Sistema de Avaliação enviado com sucesso")
    }
    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }
    return (
        <>
            <main className="min-h-screen grid-cols-7 mx-auto md:grid xl:grid-cols-10">
                <div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    <NavbarFormador currentPage={"ufcdsAtual"} />
                </div>
                <div className="h-full col-span-5 xl:col-span-8">
                    <Header sideMenu={"true"} />
                    <h1 className="title">Lista de disciplinas</h1>
                    {data && data.length > 0 ? <> <div className="search-div">
                        <label className="search-input-label"> Pesquisar: </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input type="search" placeholder="ex: Nome, Código..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="search-input" />
                        </div>
                    </div>
                        <div className="flex justify-center items-center mb-1">
                            <label className="text-text-main-color">Dados por página: </label>
                            <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                value={itemsPag}
                                onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div className="mx-auto table-containerSideMenu">
                            <table className="my-table">

                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="p-2 min-w-[80px] border border-white">Código da disciplina</th>
                                        <th className="p-2 min-w-[80px] border border-white">Nome</th>
                                        <th className="p-2 min-w-[80px] border border-white">Código do Curso</th>
                                        <th className="p-2 min-w-[80px] border border-white">Carga Horária</th>
                                        <th className="p-2 min-w-[80px] border border-white">Data início</th>
                                        <th className="p-2 min-w-[80px] border border-white">Data final</th>
                                        <th className="p-2 min-w-[80px] border border-white">Gerir Eventos</th>
                                        <th className="p-2 min-w-[80px] border border-white">Avliação de Satisfação</th>
                                        <th className="p-2 min-w-[80px] border border-white pr-2">Exportar Cronograma</th>
                                    </tr>
                                </thead>
                                {data.length > 0 ? (
                                    <tbody className="text-text-main-color">
                                        {filterData(dataToDisplay).map((item, index) => (
                                            <tr key={index}>
                                                <td className="border border-white">{item.codeUfcd}</td>
                                                <td className="border border-white">{item.name}</td>
                                                <td className="border border-white">{item.percurso[0] ? item.percurso[0].name : "Sem curso"}</td>
                                                <td className="border border-white">{item.cargaHoraria}</td>
                                                <td className="border border-white" type="date">{AlterarFormato(item.dateBegin)}</td>
                                                <td className="border border-white" type="date">{AlterarFormato(item.dateEnd)}</td>
                                                <td className="border border-white">
                                                    <button className="button-geral" onClick={() => navigate("/menutrainers/" + params.id_candidatura + "/UFCDsListed/ativas/modulos/" + item._id)}>Marcar Eventos</button>
                                                </td>
                                                <td className="border border-white">
                                                    <button onClick={() => navigate(`/menutrainers/${params.id_candidatura}/cursos/${params.id_curso}/Ufcds/${item._id}/FormAvaliacao/formador/${params.id_candidatura}`)} className={(!item.avaliacaoSatisfacaoFormador && item.avaliacaoSatisfacaoFormador.length !== 0) ? "cursor-not-allowed tooltip" : "tooltip"} data-tip={"Formulário de Avaliação"}>
                                                        <FaRegListAlt className={ (item.avaliacaoSatisfacaoFormador && item.avaliacaoSatisfacaoFormador.length === 0) ? "p-2 text-main-color hover:text-text-second-color m-auto" :  "p-2 text-green-600 m-auto" } size={50} />
                                                    </button>
                                                </td>
                                                <td className="border border-white">
                                                    <button className="button-geral" onClick={() => ExcelGenerator(item)}><FiDownload /></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody />
                                )}
                            </table>
                        </div>
                        <div className="flex items-center justify-center mt-4 mb-8">
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                <AiOutlineDoubleLeft />
                            </button>
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                <AiOutlineLeft />
                            </button>
                            <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                <AiOutlineRight />
                            </button>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                <AiOutlineDoubleRight />
                            </button>
                        </div> </> : <p className="third-title">Não existem dados</p>}
                </div>
            </main>
            <Footer />
        </>
    );
}