import ExcelJS from 'exceljs';
import DownloadFile from '../../DownloadFile/downloadFile';


async function ExcelAvaliacaoCoordenadores(id, token) {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/${id._id}/relatorioCoordenadores`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            return response.json();
        })
        .then(data => {
            // Assuming DownloadFile expects the JSON data as an argument
            DownloadFile(data);
        })
    }

export default ExcelAvaliacaoCoordenadores;
