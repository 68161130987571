import React, { useState, useEffect } from "react";
import { MenuFormando } from "./styled";
import { Link, useParams } from 'react-router-dom'
import Footer from "../../../../../../../components/Footer";
import Header from "../../../../../../../components/Header";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ptLocale from '@fullcalendar/core/locales/pt';
import Modal from 'react-modal'
import './modalCronograma.css'

Modal.setAppElement('#root');

function ShowMenuFormando() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [events, setEvents] = useState([]);
    const params = useParams();

    //Recebe todos os eventos
    function getEvento() {
        fetch(`${process.env.REACT_APP_API_URL}/calendar/ufcds/${params.id_ufcd}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "authorization": localStorage.getItem("coord")
            },
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setEvents(result)
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    }

    useEffect(() => {
        getEvento()
    }, [loading]);

    //Componente Calendar
    function Calendario() {
        return (
            <>
                <MenuFormando>
                    <div className="App">
                        <FullCalendar
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            locale={ptLocale}
                            initialView="dayGridMonth"
                            headerToolbar={{
                                center: 'dayGridMonth,timeGridWeek,timeGridDay',
                                end: 'today prev,next',
                            }}
                            events={events}
                            nowIndicator
                        />
                    </div>
                </MenuFormando >
            </>
        );
    }

    return (
        <>
            <Header sideMenu={"true"} />
            <MenuFormando>
                <main>
                    <Link to={"/menucoordenador/" + params.id_candidatura + "/cursos/" + params.id_curso + "/Ufcds/" + params.id_ufcd} className="back-button-link">
                        <img className='back-button' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>
                    <div className="modal2" style={{ display: modalIsOpen ? "block" : "none" }}>
                        <div className="modal2-content">
                            <div className="modal2-header">
                                <h2>Novo Evento</h2>
                            </div>
                            <div className="modal2-body">
                                <div className="evento-row">
                                    <label className="label-evento">Disciplinas</label>
                                    <select onWheel={(e) => e.target.blur()} className="seletor-evento" name="seletor" id="seletor"></select>
                                </div>
                                <div className="evento-row">
                                    <label className="label-evento">Data Início</label>
                                    <input type="datetime-local" className="input-evento" name="start" id="start">
                                    </input>
                                </div>
                                <div className="evento-row">
                                    <div className="label-evento"><label >Data Final</label></div>

                                    <div><input type="datetime-local" className="input-evento" name="end" id="end"></input></div>
                                </div>
                            </div>
                            <div className="buttons-evento">
                                <div className="div-evento-reverse"><button className="button-evento" onClick={(e) => setModalIsOpen(false)}>Cancelar</button></div>
                            </div>
                        </div>
                    </div>
                    <Calendario />
                </main>
            </MenuFormando >
            <Footer />
        </>
    );
}

export default ShowMenuFormando;
