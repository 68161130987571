import { useEffect, useState } from "react";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import useWindowDimensions from '../FormChecks/dimensions/dimensions';

const Footer = () => {

  const { height, width } = useWindowDimensions();


  const [groupedItems, setGroupedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [carrouselLength, setCarrouselLength] = useState(0);



  useEffect(() => {
    getEntFormadora();

  }, [loading, width]);


  // Recebe as fotos das entidades
  function getEntFormadora() {
    let j
    fetch(`${process.env.REACT_APP_API_URL}/EntFormadora`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then((result) => {
        if (width >= 1280) {
          j = 6

        } else if (width >= 649) {
          j = 4
        } else {
          j = 2
        }
        let group = []
        if (j != carrouselLength) {
          for (let i = 0; i < result.length; i += j) {
            group.push(result.slice(i, i + j));
          }
          setGroupedItems(group)
        }
        setLoading(false);
        setCarrouselLength(j)
      });
  }

  return (
    <footer>
      <div className="flex flex-col border-t-4 border-main-color">
        <div className="flex">
          <div className="flex flex-col ml-8">
            <p className="text-left text-main-color">Gerido pela</p>
            <img className="w-24 h-auto" src={process.env.PUBLIC_URL + "/img/logotipo/Logo-cooperativa-noBG.png"} alt="image" />
          </div>
          <div className="flex flex-col ml-8">
            <p className="text-left text-main-color">Cofinanciado por</p>
            <img className="w-28 h-auto" src={process.env.PUBLIC_URL + "/img/logotipo/CASESLogo.jpg"} alt="image" />
          </div>
        </div>
        <div className="m-4 mt-0 ml-8">
          <p className="mb-1 text-left text-main-color">Entidades Formadoras</p>
          {groupedItems.length > 0 && (
            <Carousel showThumbs={false} emulateTouch={true} autoPlay={true} transitionTime={3000} infiniteLoop interval={5000} showStatus={false} showIndicators={false} showArrows={false}>
              {groupedItems.map((group, index) => (
                <div key={index} className="carousel-group" style={{ width: `calc((100% / ${carrouselLength}) * ${group.length})` }}>
                  {group.map((item, itemIndex) => (
                    <div key={itemIndex} className="carousel-item" style={{ width: `calc((100% / ${group.length})` }}>
                      <img className="select-none" src={item.logotipo} alt={item.title} />
                    </div>
                  ))}
                </div>
              ))}
            </Carousel>)}
        </div>
      </div>

    </footer>
  );
};

export default Footer;