import styled from "styled-components";

export const MenuFormando = styled.div`
    
justify-content: center;
font-family: "Lato";
display: flex;

main{
    min-height: 100vh;
    margin-top: 0vh !important;
}

.page {  
    height:80%;
    width:100%;
    display: grid;
    grid-template-columns: 0.4fr 1.6fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "navbar content content"
      "navbar content content"
      "navbar content content";   
  }
  
.navbar { grid-area: navbar; }

.content { 
    margin: 8vh 5vw;
    grid-area: content; 
} 

.navbar {
background-color: #e4e4e4;
border-radius: 12px;
}

h1{
    text-align: center;
}

hr{
    margin: 5vh;
}

.hrProfile{
    margin: 3vh;
}

.table{
    padding:20px;
    display: block;
    background-color: white;
    text-align: center;
    max-width: 80vw;
    overflow: auto;
}

.table thead th {

    padding: 12px 39px;
    background-color: rgb(71 71 71);
    color: white;
}

.buttons {
    margin-top: 0.75em;
    display: flex;
    justify-content: space-around;
}

.profile {
    margin-top: 5vh;
    margin-left: 10vw;
    display: flex;
    align-items: center;
  }

.profilePic{
    border-radius: 12px;
    box-shadow: 0 0 5px #333;
    height:15vh;
}

.infos {
    display: flex;
    justify-content: space-center;
    color: #00000099;
  }
  
  .infos div {
    padding-left:10px;
    margin-bottom: 30px;
    text-align: center;
  }

.rapidAcess{
    text-align: left;
}

.subSubTitle{
    color: #00000099;
}

.aula{
    margin-left: 5vw;
    text-align:center;
    background-color: #E6E6E694;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
    transition: all 0.2s ease;
}

.aula:hover{
    transform: scale(1.1);
}

.grid-container {
    margin: 30px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

.aula > * {
    padding: 3px 12px;
    margin: 0;
  }

.iconAula{
    margin:10px 0;
}

.title{
    margin-left:10px;
}

.back-button-link{
    top: 60px;
}
`;
