import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../../../components/Footer";
import toastSuccess from "../../../../components/Toast/toastSuccess";
import toastError from "../../../../components/Toast/toastError";
import 'react-toastify/dist/ReactToastify.css';
import NavbarAdmin2 from "../../../../components/Navbars/NavbarAdmin2/NavbarAdmin2";

function CriarMoodle() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [host, setHost] = useState("");
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [database, setDatabase] = useState("");
    const [name, setName] = useState("");
    const [nameGenerico, setNameGenerico] = useState("");

    const navigate = useNavigate()

    useEffect(() => {
    }, [loading]);

    function adicionarMoodle() {
        if (host && user && password && database) {
            fetch(`${process.env.REACT_APP_API_URL}/moodle`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        "authorization": localStorage.getItem('admin2')
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        host: host,
                        user: user,
                        password: password,
                        database: database,
                        nameGenerico: nameGenerico,
                        name: name,
                    })
                })
                .then(res => res.json())
                .then((result) => {
                    toastSuccess("Credenciais Moodle inseridas com sucesso.")
                    navigate("/MenuSuperAdmin/Moodle")
                })
        }
        else {
            toastError("Campos obrigatórios em falta.")
        }

    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin2 currentPage={"moodle"} />
                </div>
                <Link to={'/MenuSuperAdmin'} className="back-button-div-noNavbar">
                    <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                </Link>
                <div className="flex-grow mx-8 mt-20 sm:mt-8 sm:mx-0">
                    <h1 className="title">Criar Acessos ao Moodle</h1>
                    <div className="grid mx-auto mb-5 sm:grid-cols-2 sm:gap-4">
                        <div>
                            <div className="flex flex-col items-start mt-2">
                                <label className="label-input">Host*:</label>
                                <input id="host" className="w-full input-field" onChange={(e) => setHost(e.target.value)}></input>
                            </div>
                            <div className="flex flex-col items-start mt-2">
                                <label className="label-input">User*:</label>
                                <input id="user" className="w-full input-field" onChange={(e) => setUser(e.target.value)}></input>
                            </div>
                            <div className="flex flex-col items-start mt-2">
                                <label className="label-input">Palavra-passe*:</label>
                                <input id="pass" type="password" className="w-full input-field" onChange={(e) => setPassword(e.target.value)}></input>
                            </div>
                        </div>
                        <div>
                            <div className="flex flex-col items-start mt-2">
                                <label className="label-input">Database*:</label>
                                <input id="dataBase" className="w-full input-field" onChange={(e) => setDatabase(e.target.value)}></input>
                            </div>
                            <div className="flex flex-col items-start mt-2">
                                <label className="label-input">Nome genérico das tabelas:</label>
                                <input id="nameTables" className="w-full input-field" onChange={(e) => setNameGenerico(e.target.value)}></input>
                            </div>
                            <div className="flex flex-col items-start mt-2">
                                <label className="label-input">Nome do Moodle:</label>
                                <input id="nameMoodle" className="w-full input-field" onChange={(e) => setName(e.target.value)}></input>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button className="mt-4 button-geral" onClick={adicionarMoodle}>Adicionar</button>
                    </div>

                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main>
        </>
    );
}

export default CriarMoodle