import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import Header from "../../Header";
import toastSuccess from "../../Toast/toastSuccess";

export default function FormAvaliacaoImpacto() {
    const [data, setData] = useState("")
    const [cont, setCont] = useState(0)
    const [index, setIndex] = useState(-1)
    const [valor1, setValor1] = useState("")
    const [valor2, setValor2] = useState("")
    const [valor3, setValor3] = useState("")
    const [valor4, setValor4] = useState("")
    const [valor5, setValor5] = useState("")
    const [valor6, setValor6] = useState("")
    const [valor7, setValor7] = useState("")
    const [valor8, setValor8] = useState("")
    const [valor9, setValor9] = useState("")
    const [outros, setOutros] = useState("")
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        getUFCD()
    }, [cont])

    /* 
        function handleClick() {
            navigate(-1);
        } */

    function avaliarformandoUFCD() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_percurso}/avaliacaoImpactoFormando`,
            {
                method: "PATCH",
                body: JSON.stringify({
                    criterio1: valor1,
                    criterio2: valor2,
                    criterio3: valor3,
                    criterio4: valor4,
                    criterio5: valor5,
                    criterio6: valor6,
                    sugestao: valor9,
                    formandoId: params.id_formando
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            }).then((result) => {
                result.json().then((resp) => {
                    toastSuccess("Formulário submetido com sucesso")
                })
                /*                 handleClick(); */
            })
    }

    function getUFCD() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_ufcd}/notVerif`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            }).then((res) => {
                res.json().then(result => {
                    setData(result)
                    const index = result.avaliacaoImpactoFormandosResults.findIndex(item => item.formandoId === params.id_formando);
                    if (index !== -1) { // Check if index is found
                        setIndex(index); // Assuming setIndex is a function to update state
                    }
                    else setIndex(-1)
                }).catch(error => {
                    console.error('Error parsing JSON:', error);
                });
            })
    }
    function resetCriterios() {
        setValor1("")
        setValor2("")
        setValor3("")
        setValor4("")
        setValor5("")
        setValor6("")
        setValor7("")
        setValor8("")
        setValor9("")

    }
    const getOptionText = (option) => {
        switch (option) {
            case 1:
                return "Sim";
            case 2:
                return "Não";
            default:
                return "";
        }
    };

    const getOptionText2 = (option) => {
        switch (option) {
            case 1:
                return "Empregado/a";
            case 2:
                return "Desempregado/a";
            default:
                return "";
        }
    };
    return (
        <>
            {data && (data.formandos.find((item) => item === params.id_formando) && !data.desistencias.find((item) => item.id === params.id_formando)) ? <main className={"min-h-screen"}>
                <div className={"flex-grow mt-8"}>
                    <Header />
                    <div className="w-[80%] m-auto text-start text-[18px]">
                        <div className="mt-10">
                            <h1 className="title">Avaliação de Impacto da Formação</h1>
                        </div>

                        <div className="my-10 text-main-color">
                            Pretendemos ter a sua contribuição para a análise do impacto da formação que frequentou connosco.
                        </div>
                        <div>
                            <div className="flex">
                                <h1 className="font-bold mr-3 text-main-color">Percurso: </h1>
                                <h1>{data && data.name}</h1>
                            </div>
                            <div className="flex">
                                <h1 className="font-bold mr-3 text-main-color">Data de início: </h1>
                                <h1>{data && data.dateBegin}</h1>
                            </div>
                            <div className="flex">
                                <h1 className="font-bold mr-3 text-main-color">Data de fim: </h1>
                                <h1>{data && data.dateEnd}</h1>
                            </div>
                        </div>
                        <div className="my-10 text-main-color">
                            Agradecemos que dedique alguns minutos da sua atenção no preenchimento deste questionário, clicando nos espaços correspondentes à sua situação, tomando em atenção as anotações que se encontram em cada questão.
                        </div>
                        <div className="flex flex-col gap-10">
                            <h1 className="subtitle"> I - AVALIAÇÃO DE IMPACTO DA FORMAÇÃO</h1>
                            <div className="flex flex-col gap-10">
                                <div>
                                    <h4 className="third-title font-bold">1. Antes de iniciar o curso qual era a sua situação face ao emprego?</h4>
                                    <div className="flex flex-row gap-10">
                                        {[1, 2].map(option => (
                                            <div className="flex items-center" key={option}>
                                                <input
                                                    className="m-2"
                                                    type="checkbox"
                                                    checked={((data && index !== -1 && data.avaliacaoImpactoFormandosResults[index].criterio1 === option && !valor1) || (index === -1 && valor1 === option))}
                                                    onClick={() => { index === -1 && setValor1(option) }}
                                                />
                                                <h1>{option} - {getOptionText2(option)}</h1>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <h4 className="third-title font-bold">2. Atualmente qual era a sua situação face ao emprego?</h4>
                                    <div className="flex flex-row gap-10">
                                        {[1, 2].map(option => (
                                            <div className="flex items-center" key={option}>
                                                <input
                                                    className="m-2"
                                                    type="checkbox"
                                                    checked={((data && index !== -1 && data.avaliacaoImpactoFormandosResults[index].criterio2 === option) || valor2 === option)}
                                                    onClick={() => { index === -1 && setValor2(option) }}
                                                />
                                                <h1>{option} - {getOptionText2(option)}</h1>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div>
                                    <h4 className="third-title font-bold">3. O curso que frequentou contribuiu para melhorar a sua situação em termos de empregabilidade (arranjar emprego, melhorar as suas condições no emprego, ter o emprego mais seguro)?</h4>
                                    <div className="flex flex-row gap-10">
                                        {[1, 2].map(option => (
                                            <div className="flex items-center" key={option}>
                                                <input
                                                    className="m-2"
                                                    type="checkbox"
                                                    checked={((data && index !== -1 && data.avaliacaoImpactoFormandosResults[index].criterio3 === option) || valor3 === option)}
                                                    onClick={() => { index === -1 && setValor3(option) }}
                                                />
                                                <h1>{option} - {getOptionText(option)}</h1>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <h4 className="third-title font-bold">4. O curso que frequentou contribuiu para melhorar as suas competências profissionais?</h4>
                                    <div className="flex flex-row gap-10">
                                        {[1, 2].map(option => (
                                            <div className="flex items-center" key={option}>
                                                <input
                                                    className="m-2"
                                                    type="checkbox"
                                                    checked={((data && index !== -1 && data.avaliacaoImpactoFormandosResults[index].criterio4 === option) || valor4 === option)}
                                                    onClick={() => { index === -1 && setValor4(option) }}
                                                />
                                                <h1>{option} - {getOptionText(option)}</h1>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <h4 className="third-title font-bold">5. Aconselharia/aconselhou este curso a outras pessoas?</h4>
                                    <div className="flex flex-row gap-10">
                                        {[1, 2].map(option => (
                                            <div className="flex items-center" key={option}>
                                                <input
                                                    className="m-2"
                                                    type="checkbox"
                                                    checked={((data && index !== -1 && data.avaliacaoImpactoFormandosResults[index].criterio5 === option) || valor5 === option)}
                                                    onClick={() => { index === -1 && setValor5(option) }}
                                                />
                                                <h1>{option} - {getOptionText(option)}</h1>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <h4 className="third-title font-bold">6. Aconselharia/aconselhou a entidade formandoa a outras pessoas?</h4>
                                    <div className="flex flex-row gap-10">
                                        {[1, 2].map(option => (
                                            <div className="flex items-center" key={option}>
                                                <input
                                                    className="m-2"
                                                    type="checkbox"
                                                    checked={((data && index !== -1 && data.avaliacaoImpactoFormandosResults[index].criterio6 === option) || valor6 === option)}
                                                    onClick={() => { index === -1 && setValor6(option) }}
                                                />
                                                <h1>{option} - {getOptionText(option)}</h1>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h4 className="subtitle">SUGESTÕES/ CRÍTICAS/ COMENTÁRIOS</h4>
                                <textarea disabled={(index === -1) ? false : true} defaultValue={(data && index !== -1 && data.avaliacaoImpactoFormandosResults[index].sugestao) ? data.avaliacaoImpactoFormandosResults[index].sugestao : ""}
                                    onChange={(e) => setValor9(e.target.value)} className="input-textarea h-40"></textarea>
                            </div>
                            <div className="my-5 text-main-color">
                                Obrigado pela sua colaboração
                            </div>
                            <div className="mb-10 mx-auto">
                                {index === -1 && <button onClick={avaliarformandoUFCD} className="button-geral">Submeter</button>}
                            </div>
                        </div>
                    </div>
                </div>
            </main> : <main className="flex h-screen">
                <div className="flex flex-col items-center justify-center h-full">
                    <h2 className="text-[250px] font-bold text-main-color tracking-widest">403</h2>
                    <p className="text-lg sm:text-2xl text-text-main-color">O formando não tem acesso ao formulário.</p>
                </div>
            </main >}
            <Footer />
        </>
    )
}