import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom'
import Footer from "../../../../components/Footer";
import toastSuccess from "../../../../components/Toast/toastSuccess";
import Modal from 'react-modal';
import NavbarAdmin2 from "../../../../components/Navbars/NavbarAdmin2/NavbarAdmin2";
import { FiDownload } from "react-icons/fi";
import { AiOutlineCloseCircle } from "react-icons/ai";

export default function Coordinator() {
    const [data, setData] = useState(null);
    const [entFormadorasList, setEntFormadorasList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [cont, setCont] = useState(0);
    const [modal, setModal] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    document.data = data

    const params = useParams();

    useEffect(() => {
        getAdmin()

    }, [cont, loading, modalIsOpen]);


    //Recebe um administrador pelo seu id
    function getAdmin() {
        fetch(`${process.env.REACT_APP_API_URL}/admins/${params.id_admin}`, {
            headers: {
                'authorization': localStorage.getItem('admin2')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    setLoading(false);
                    getEntFormadoras()
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    };

    //Recebe um administrador pelo seu id
    function getEntFormadoras() {
        fetch(`${process.env.REACT_APP_API_URL}/EntFormadora`, {
            headers: {
                'authorization': localStorage.getItem('admin2')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setEntFormadorasList(result);
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    };

    function validarAdmin() {
        fetch(`${process.env.REACT_APP_API_URL}/admins/${params.id_admin}/validar/admin`, {
            method: "PATCH",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem('admin2')
            },
        }).then((result) => {
            result.json().then((resp) => {
                getAdmin()
                toastSuccess('Estado do Admin alterado com sucesso!')
            })

        })
    }

    function desvalidarAdmin() {
        fetch(`${process.env.REACT_APP_API_URL}/admins/${params.id_admin}/desvalidar/admin`, {
            method: "PATCH",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem('admin2')
            },
        }).then((result) => {
            result.json().then((resp) => {
                getAdmin()
                toastSuccess('Estado do Admin alterado com sucesso!')
            })

        })
    }
    //Função validar candidatura por id
    function confirmarEntidadeFormadora(_id) {
        var checkboxes = document.querySelectorAll('input[type="checkbox"]:checked');
        var labels = [];
        checkboxes.forEach(function (checkbox) {
            labels.push(checkbox.value);
        });

        fetch(`${process.env.REACT_APP_API_URL}/admins/${params.id_admin}/entidadeFormadora`,
            {
                method: "PATCH",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "authorization": localStorage.getItem('admin2')
                },
                body: JSON.stringify({
                    entFormadora: labels
                })
            }).then((result) => {
                result.json().then((resp) => {
                    setModalIsOpen(false);
                    setCont(cont + 1)
                    toastSuccess("Candidato validado com sucesso")
                })
            })
    };

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin2 currentPage={"manageAdmin"} />
                </div>
                <Link to={'/MenuSuperAdmin/administradores'} className="back-button-div-noNavbar">
                    <img className='back-button-img' src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} alt="Back" />
                </Link>
                <div className="w-full">
                    <div className="mx-10 mt-8 mb-12">
                        <div className="flex flex-col items-center justify-center w-full">
                            <div className="flex flex-col">
                                <img alt="Profile" className="object-contain mx-auto max-h-40 rounded-3xl sm:m-0" src={data.photo === undefined || !data.photo ? 'https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg' : data.photo} height="120px" />
                                {data.fullName ? <div className="text-2xl font-bold text-text-main-color"> {data.fullName}</div> : <div className="input-error-profile" />}
                            </div>
                        </div>

                        <div className="my-6">
                            <hr />
                        </div>

                        <div className="mb-5 sm:ml-24">
                            <h2 className="text-3xl font-bold text-center text-text-main-color sm:text-left">Dados Pessoais</h2>
                            <div className="grid mt-4 sm:grid-cols-2">
                                <div>
                                    <div className="text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">Número de telefone: </div>
                                        {data.cellNumber ? <div className="mt-1 text-lg text-text-main-color">{data.cellNumber}</div> : <div className="input-error-profile" />}
                                    </div>
                                    <div className="mt-4 text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">E-mail:</div>
                                        {data.email ? <div className="mt-1 text-lg text-text-main-color">  {data.email}</div> : <div className="input-error-profile" />}
                                    </div>
                                    <div className="mt-4 text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">NIF:</div>
                                        {data.nif ? <div className="mt-1 text-lg text-text-main-color">  {data.nif}</div> : <div className="input-error-profile" />}
                                    </div>
                                    <div className="mt-4 text-center sm:text-left">
                                        <div className="mt-1 text-xs uppercase text-text-second-color opacity-90">Estado:</div>
                                        {data.valid === true ?
                                            <div className="mt-2 text-lg text-text-main-color">Autorizado
                                                <button className="mx-auto mt-1 sm:mx-0 button-cancelar" onClick={() => desvalidarAdmin()}>Excluir</button>
                                            </div> : <div className="mt-2 text-lg text-text-main-color">Excluido
                                                <button className="mx-auto mt-1 sm:mx-0 button-confirmar" onClick={() => validarAdmin()}>Autorizar</button>
                                            </div>}
                                    </div>
                                </div>
                                <div>
                                    <div className="mt-4 text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">Localidade:</div>
                                        {data.locality ? <div className="mt-1 text-lg text-text-main-color">{data.locality}</div> : <div className="input-error-profile" />}
                                    </div>
                                    <div className="mt-4 text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">Tipo:</div>
                                        {data.nomeRole ? <div className="mt-1 text-lg text-text-main-color">{data.nomeRole}</div> : <div className="input-error-profile" />}
                                    </div>
                                    <div className="mt-4 text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">Entidades Formadoras:</div>
                                        {data && data.entFormadora && data.entFormadora.length > 0 ? <>
                                            {data.entFormadora.map((item) => (
                                                <div key={item._id} className="mt-1 text-lg text-text-main-color">{item.name}</div>
                                            ))}
                                            <button className="ml-1 button-geral" onClick={() => setModalIsOpen(true)}>Editar</button></>
                                            : <div>
                                                <div className="input-error-profile" />
                                                <button className="ml-1 button-geral" onClick={() => setModalIsOpen(true)}>Adicionar</button>
                                            </div>}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <Modal isOpen={modal} className="fixed inset-0 flex items-center justify-center">
                        <div className="absolute w-full h-full" onClick={() => setModal(false)}></div>
                        <div className="relative flex flex-col items-center px-10 shadow-2xl md:px-20 bg-secundary-color py-7 rounded-2xl">
                            <div>
                                <h1 className="subtitle">Currículos</h1>
                            </div>
                            <div className="modal-content">
                                {data.cv_path.map((value, index) => (
                                    <div key={value}>
                                        <div className="flex items-center m-1.5">
                                            <span className="mr-2 text-text-main-color">CV - {index + 1}:</span>
                                            <a className="mr-2 button-geral" href={value} target="_blank" rel="noreferrer" download>
                                                <FiDownload />
                                            </a>
                                        </div>
                                    </div>
                                ))}</div>
                            <button className="absolute top-1.5 right-1.5" onClick={() => setModal(false)}>
                                <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                            </button>

                        </div>
                    </Modal>

                    <Modal isOpen={modalIsOpen} className="fixed inset-0 flex items-center justify-center">
                        <div className="absolute w-full h-full" onClick={() => setModalIsOpen(false)}></div>
                        <div className="relative flex flex-col items-center px-10 mx-5 my-5 overflow-y-scroll shadow-2xl bg-secundary-color py-7 rounded-2xl max-h-128 sm:max-h-156">
                            <h1 className="title">Entidades Formadoras</h1>
                            {data && entFormadorasList && <div className="modal-context">
                                {entFormadorasList.map((item) => (
                                    <div key={item}>
                                        <input
                                            className="check-box"
                                            type="checkbox"
                                            id={`area_${item._id}`}
                                            name="area"
                                            defaultChecked={data.entFormadora.some(entFormad => entFormad._id === item._id)}
                                            value={item._id}
                                        />
                                        <label htmlFor="area" className="ml-1 text-text-main-color">{item.name}</label>
                                    </div>
                                ))}
                                <button className="absolute top-1.5 right-1.5" onClick={() => setModalIsOpen(false)}>
                                    <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                                </button>
                            </div>}
                            <div className="flex">
                                <button className="button-confirmar" onClick={() => confirmarEntidadeFormadora(data._id)} >Confirmar</button>
                            </div>
                        </div>
                    </Modal>
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main >
        </>
    );
}