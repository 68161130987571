import styled from "styled-components";

export const AreaLoginFormando = styled.div`


.form-container{
    margin-top: 3.5vh;
    padding-top: 75px;
    background: #E4E4E4;
}

.button-login{
    margin-top: 20px;
    background-color: #474747;
    color: white;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
}

.button-login:hover {
  background-color: #757575;
}

.input-container{
  background: white;
  width: 100%;
  display: inline-flex;
  border-radius: 6px;
  padding-top: 3.5px;
  padding-bottom: 3.5px;
}

.password-input-wraper{
  position: relative;
}

.fa-user {
  display: flex;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  align-items: center;
  pointer-events: none;
}

.fas {
  color: #474747;
}

.fa-eye {
  padding-right: 1.1px;
}

.user-icon-container {
  display: flex;
  padding-left: 15px;
  border-radius: 6px;
  pointer-events: none;
}

.login-icon-container {
  display: flex;
  margin-top: 100px;
}

.fa-key {
  display: flex;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  align-items: center;
}

.email-input-wraper{
  position: relative;
  padding-bottom: 8px;
}

.eye-button {
  display: flex;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  align-items: center;
  padding-right: 10px;
}

.eye-button-container {
  display: flex;
  padding: 10px;
}

.eye-button:focus {
  outline: none;
}

.eye-button i {
  font-size: 1.2rem;
  color: #aaa;
}

.eye-button:hover i {
  color: #474747;
}

.forgot-pass-link, forgot-pass-link:visited, forgot-pass-link:active {
  margin-top: 0.5px;
  color: #000000;
  text-decoration: none;
  color: inherit;
  border: none;

}.forgot-pass-link:hover {
  background-color: transparent;
  color: black;
  text-decoration: underline;
  border: none;
  font-weight: 100;
}

.checkbox-form{
  min-height:5px !important;
  min-width: 5px !important;
}

.inline{
  display: inline;
}
`