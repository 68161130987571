import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import Footer from "../Footer";
import NavbarAdmin2 from "../Navbars/NavbarAdmin2/NavbarAdmin2";
import NavbarAdmin1 from "../Navbars/NavbarAdmin1/NavbarAdmin1";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";
import Options from "../FormChecks/areaCodigoFormacao/areaCodigoFormacao";
import toastSuccess from "../Toast/toastSuccess";

function ShowTemplate(token, type) {
    const [data, setData] = useState("");
    const [alterar, setAlterar] = useState(false);
    const [addUfcds, setAddUfcds] = useState(false);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [ufcdsSelecionadas, setUfcdsSelecionadas] = useState([]);
    const [cont, setCont] = useState(0)
    const tipo = token === "admin2" ? "SuperAdmin" : "Admin"
    const savedSearchTerm = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`${tipo}-templateIndividual-1`) : "";
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm ? savedSearchTerm : "");
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`${tipo}-templateIndividual-Pag`) : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`${tipo}-templateIndividual-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)
    const params = useParams();
    const navigate = useNavigate();
    const Areas = Options;
    function RenderList() {
        return (
            <>
                {ufcdsSelecionadas.map((item, index) =>
                    <div key={index} className="flex items-center justify-center gap-x-20 mt-3 text-text-main-color" >
                        <p className="mr-2">{item.codeUfcd} - {item.name}</p>
                        <button className="button-cancelar" type="button" onClick={(e) => { rejeitarUfcd(item.id) }}>
                            Remover
                        </button>
                    </div>)}
            </>
        )
    }

    useEffect(() => {
        Cookies.set(`${tipo}-templateIndividual-Pag`, paginaAtual);
        Cookies.set(`${tipo}-templateIndividual-1`, searchTerm);
        Cookies.set(`${tipo}-templateIndividual-itm1`, itemsPag)
        getUfcdsTemplate()
        if (data) {
            getUfcdsGlobais()

        } if (cont) {
            addUfcdGlobalButton()
        }

    }, [loading, paginaAtual, cont, nPaginas, searchTerm, itemsPag, alterar, addUfcds]);

    //rejeitar ufcds selecionadas
    function rejeitarUfcd(id) {
        const guardar = []
        ufcdsSelecionadas.forEach(ufcds => {
            guardar.push(ufcds.id)
        });
        const index = guardar.indexOf(id)
        if (index !== -1) {
            ufcdsSelecionadas.splice(index, 1);
            setCont(cont + 1)
        }
    }
    //filtra dados
    function filterData(data) {
        if (data) {
            return data.filter((item) =>
                item.codeUfcd.includes(searchTerm) ||
                item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.cargaHoraria.includes(searchTerm)
            );
        }
    }

    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }


    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.ufcdsGlobal.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data.ufcdsGlobal)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data.ufcdsGlobal)
        }
    }

    //Desvalida um formador
    function getUfcdsTemplate() {
        fetch(`${process.env.REACT_APP_API_URL}/templates/${params.id_template}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
            method: 'GET',
        })
            .then(res => res.json())
            .then((result) => {
                setData(result)
                paginacaoTabelas(filterData(result.ufcdsGlobal))
                setLoading(false)
            })
    }


    //Desvalida um formador
    function alterarTemplate() {
        fetch(`${process.env.REACT_APP_API_URL}/templates/${params.id_template}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
            method: 'PATCH',
            body: JSON.stringify({
                duracao: document.getElementById("duracao") ? document.getElementById("duracao").value : data.duracao,
                nivel: document.getElementById("nivel") ? document.getElementById("nivel").value : data.nivel,
                area: document.getElementById("area") ? document.getElementById("area").value : data.area,
                name: document.getElementById("name") ? document.getElementById("name").value : data.name,
                ufcds: addUfcds && ufcdsSelecionadas
            })
        })
            .then(res => res.json())
            .then((result) => {
                toastSuccess("Curso modelo alterado com sucesso")
                setAlterar(false)
                setAddUfcds(false)
            })
    }


    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data.ufcdsGlobal);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data.ufcdsGlobal);
        }
    }

    function addUfcdGlobalButton(el, dl) {
        if (el) {
            var opSelected = dl.querySelector(`[ value="${el.value}"]`);
            var option = document.createElement("option");
            option.value = opSelected.value;
            option.text = opSelected.getAttribute('data-id');
            option.code = opSelected.getAttribute('data-code');
            option.name = opSelected.getAttribute('data-name');
            if (!ufcdsSelecionadas.some(e => e.id === option.text)) {
                ufcdsSelecionadas.push({ id: option.text, name: option.name, codeUfcd: option.code })
                setCont(cont + 1)
            }
        }
    }
    function getUfcdsGlobais() {
        const datalist = document.getElementById('ufcds-globais-por-selecionar');

        fetch(`${process.env.REACT_APP_API_URL}/ufcdsGlobal`, {
            headers: {
                "authorization": localStorage.getItem(token)
            }
        })
            .then(response => response.json())
            .then(result => {
                const idJaUsados = data.ufcdsGlobal.map((item) => item.codeUfcd)
                const idSelecionados = ufcdsSelecionadas.map((item) => item.codeUfcd)
                const todosIds = result.map((item) => item.codeUfcd);
                const diffArray = [];
                // Check elements from todosIds
                for (let i = 0; i < todosIds.length; i++) {
                    if (diffArray.length <= todosIds.length) {
                        if (!idJaUsados.includes(todosIds[i]) && !idSelecionados.includes(todosIds[i])) {
                            diffArray.push(result[i]);
                        }
                    }

                }
                const uniqueArray = [...new Set(diffArray.map(JSON.stringify))].map(JSON.parse);
                if (uniqueArray.length > 0 && datalist) {// Construir o Seletor
                    uniqueArray.forEach(ufcd => {
                        const option = document.createElement('option');
                        option.value = ufcd.codeUfcd + " - " + ufcd.name;
                        option.setAttribute('data-id', ufcd._id); // Use data-id attribute for _id
                        option.setAttribute('data-code', ufcd.codeUfcd);
                        option.setAttribute('data-name', ufcd.name);
                        option.label = ufcd.codeUfcd + " - " + ufcd.name; // Set label attribute to categorias.name
                        datalist.appendChild(option);
                    });
                }
            });
        if (datalist) {
            // Clear existing options
            datalist.innerHTML = '';
        }
    }
    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    {token === "admin2" && <NavbarAdmin2 currentPage={type} />}
                    {token === "admin1" && <NavbarAdmin1 currentPage={type} />}
                </div>
                <div className="flex-grow mt-8">
                    <button onClick={()=>navigate(-1)} className="back-button-div-noNavbar">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </button>
                    {alterar === true ? <input id="name" name="name" className="input-field w-128 title text-center" placeholder={data.name} defaultValue={data.name} required></input> : <h1 className="mt-4 title">{data.name}</h1>}
                    <div className="grid mt-10 sm:grid-cols-2 gap-x-20">
                        <div className="text-center sm:text-left">
                            <div className="text-xs uppercase text-text-second-color opacity-90">Duração:</div>
                            <div className="mt-1 text-lg text-text-main-color">
                                {alterar === true ? <input id="duracao" name="duracao" className="input-field w-56" placeholder={data.duracao} defaultValue={data.duracao} required></input> : data.duracao}
                            </div>
                        </div>
                        <div className="text-center sm:text-left">
                            <div className="text-xs uppercase text-text-second-color opacity-90">Nivel:</div>
                            <div className="mt-1 text-lg text-text-main-color">
                                {alterar === true ? <input id="nivel" name="nivel" className="input-field w-56" placeholder={data.nivel} defaultValue={data.nivel} required></input> : data.nivel}
                            </div>
                        </div>
                        <div className="mt-4 text-center sm:text-left">
                            <div className="text-xs uppercase text-text-second-color opacity-90">Area e Codigo de Formação:</div>
                            <div className="mt-1 text-lg text-text-main-color w-80">
                                {alterar === true ? <select onWheel={(e) => e.target.blur()} className="selector " name="area" id="area" defaultValue={data.area}>
                                    <option value="" disabled>Selecione uma opção</option>
                                    {Areas.map((value) => (
                                        <option key={value}>{value}</option>
                                    ))}
                                </select> : data.area}
                            </div>
                        </div>

                    </div>
                    <div className="flex justify-center my-6 gap-6">
                        <button className={alterar ? "button-cancelar p-2 w-32" : "button-geral p-2 w-32"} onClick={() => { setAlterar(!alterar); setAddUfcds(false) }} >{alterar ? "Cancelar" : "Alterar"}</button>
                        {alterar && <button className="button-geral p-2 w-32" onClick={alterarTemplate} >Confirmar</button>}
                    </div>
                    {data.ufcdsGlobal && data.ufcdsGlobal.length > 0 ? <>
                        <h2 className="my-4 subtitle">Lista de Disciplinas</h2>
                        <div className="search-div">
                            <label className="search-input-label">Pesquisar:</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input type="text" className="search-input" placeholder="ex: Nome, Código..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                            </div>
                        </div>
                        <div className="flex justify-center items-center mb-1">
                            <label className="text-text-main-color my-2">Dados por página: </label>
                            <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                value={itemsPag}
                                onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div className="table-container">
                            <table className="my-table">

                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="p-2 min-w-[80px] border border-white">Código</th>
                                        <th className="p-2 min-w-[150px] border border-white">Nome</th>
                                        <th className="p-2 min-w-[80px] border border-white">Carga Horária</th>
                                    </tr>
                                </thead>
                                {data.ufcdsGlobal.length > 0 ? <tbody className="text-text-main-color">
                                    {filterData(dataToDisplay).map((item, index) => (
                                        <tr key={index}>
                                            <td className="border border-white">{item.codeUfcd}</td>
                                            <td className="border border-white">{item.name}</td>
                                            <td className="border border-white">{item.cargaHoraria}</td>
                                        </tr>
                                    ))}
                                </tbody> : <tbody />}
                            </table>
                        </div>
                        <div className="flex items-center justify-center mt-4 mb-8">
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                <AiOutlineDoubleLeft />
                            </button>
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                <AiOutlineLeft />
                            </button>
                            <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                <AiOutlineRight />
                            </button>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                <AiOutlineDoubleRight />
                            </button>
                        </div>
                    </> : <p className="third-title">Não existem UFCD's associadas</p>}
                    <div className="mb-20 mt-10">
                        {addUfcds && <div className="flex flex-col mt-3">
                            <p className="label-input">Disciplinas:
                                <input
                                    onChange={(e) => {

                                        const input = e.target;
                                        const datalist = document.getElementById('ufcds-globais-por-selecionar');
                                        const optionExists = Array.from(datalist.options).some((option) => option.value === input.value);

                                        if (optionExists) {
                                            addUfcdGlobalButton(input, datalist);
                                            input.value = "";
                                        }
                                    }}
                                    className="w-80 input-field"
                                    list="ufcds-globais-por-selecionar"
                                />
                            </p>
                            <datalist id="ufcds-globais-por-selecionar" className="w-full"></datalist>
                            <div>
                                {ufcdsSelecionadas.length > 0 && <h4 className="mt-3 third-title">Lista de disciplinas</h4>}
                                <RenderList />
                            </div>

                        </div>}
                        <div className="flex justify-center gap-6 mt-4">
                            <button className={addUfcds ? "button-cancelar p-2 w-32" : "button-geral p-2 "} onClick={() => { setAddUfcds(!addUfcds); setAlterar(false) }}>{addUfcds ? "Cancelar" : "Adicionar Ufcds Globais"}</button>
                            {addUfcds && <button className="button-geral p-2 w-32" onClick={alterarTemplate} type="button">Submeter</button>}
                        </div>
                    </div>
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main>
        </>
    );
}
export default ShowTemplate;