import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom"
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import toastSuccess from "../../../../components/Toast/toastSuccess";

const RecandidaturaFormando = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [checkPass, setCheckPass] = useState("")
    const [section, setSection] = useState(1);
    const navigate = useNavigate();
    const ref = useRef();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [recandidatura, setRecandidatura] = useState('')

    const params = useParams();

    const [nif, setNif] = useState("")

    //Secção - 10 - Seleção Curso
    const [percurso, setPercurso] = useState(params.id_curso)


    useEffect(() => {
        getPercurso()
    }, []);
   
    //Obtem percurso
    function getPercurso() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}`)
            .then((res) => res.json())
            .then(
                (result) => {
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    }
//Obtem candidatura
    function getCandidatura(event) {
        event.preventDefault()
        fetch(`${process.env.REACT_APP_API_URL}/formandos/nif/${nif}`)
            .then((res) => res.json())
            .then(
                (result) => {
                    fetch(`${process.env.REACT_APP_API_URL}/formandos`,
                        {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            },
                            method: 'POST',
                            body: JSON.stringify({
                                fullName: result.fullName,
                                gender: result.gender,
                                birthDate: result.birthDate,
                                cellNumber: result.cellNumber,
                                email: result.email,
                                nif: result.nif,
                                niss: result.niss,
                                nationality: result.nationality,
                                parish: result.parish,
                                locality: result.locality,
                                address: result.address,
                                postalCode: result.postalCode,
                                documentType: result.documentType,
                                idNumber: result.idNumber,
                                idExpiration: result.idExpiration,
                                CQEP: result.CQEP,
                                originCQEP: result.originCQEP,
                                academicDegree: result.academicDegree,
                                employmentSituation: result.employmentSituation,
                                employmentSituationDate: result.employmentSituationDate,
                                beneficiarySS: result.beneficiarySS,
                                job: result.job,
                                nifEmployer: result.nifEmployer,
                                dataAuthorization: result.dataAuthorization,
                                dataAuthorization2: result.dataAuthorization2,
                                centroQualifica: result.centroQualifica,
                                rvResult: result.rv_path,
                                dceResult: result.dce_path,
                                ccResult: result.cc_path,
                                chResult: result.ch_path,
                                ibancompResult: result.ibancomp_path,
                                percurso: percurso,
                            }),
                        })
                        .then(res => res.json())
                        .then((result) => {
                            toastSuccess("Recandidatura feita com sucesso")
                            navigate('/')
                        })
                })
    }

    return (
        <>

            <main className="h-screen">
                <Header />
                <div className="mx-4 sm:mx-0">
                    <h1 className="title">Candidatura</h1>
                    <h3 className="subtitle">Já submeteu a alguma candidatura?</h3>

                    <select onWheel={(e) => e.target.blur()} className="selector" defaultValue={recandidatura} onChange={(e) => setRecandidatura(e.target.value)}>
                        <option value="" disabled>Selecione a sua resposta</option>
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                    </select>

                    {recandidatura === "1" && <div className="flex flex-col items-center">
                        <div className="text-text-second-color mt-3">Digite o seu NIF</div>
                        <input type="text" className="text-lg mt-2 input-field w-full" onChange={(e) => setNif(e.target.value)} />
                        <button onClick={getCandidatura} className="button-geral my-4 w-full"> Submeter Candidatura </button>
                    </div>}
                    {recandidatura === "2" && <a onClick={navigate("/inscricao/candidatura/" + params.id_curso)}></a>}
                </div>
            </main>
            <div className="w-full sm:absolute sm:bottom-0">
                <Footer />
            </div>
        </>
    )
}
export default RecandidaturaFormando