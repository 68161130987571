import React from 'react';
import Papa from 'papaparse';

class CSVGeneratorDisciplinas extends React.Component {
  async populateCSVFormandos() {
    const { data } = this.props; // Assuming you pass the data as a prop

    try {
      // Parse the CSV template (loaded from the public directory)
      const templateCSV = await this.fetchCSVTemplate('./moodleCSV/carregarDisciplinas.csv');

      const parsedTemplate = Papa.parse(templateCSV, { header: true });

      // Generate data
      const dataToInsert = this.generateData(data);
        
      // Add data below the headers
      const populatedCSV = this.addRowsToCSV(parsedTemplate, dataToInsert);

      // Trigger CSV download
      this.downloadCSV(populatedCSV);
    } catch (error) {
      console.error('Error reading or processing CSV template:', error);
    }
  }
  generateData(data) {
    const array = [];

    data.forEach(element => {
      // Combine the courses into a single object
      array.push([
        element.codeMoodle,
        element.name,
        element.percurso[0].name,

      ]);
    });

    return array;
  }
  //Adiciona linhas ao csv
  addRowsToCSV(parsedTemplate, dataToInsert) {
    const headerRow = Papa.unparse([parsedTemplate.meta.fields]); // Reconstruct the header row
  
    // Join data rows with newline characters
    const dataRows = dataToInsert.map(row => Papa.unparse([row])).join('\n');
  
    // Concatenate the header row and data rows
    return `${headerRow}\n${dataRows}`;
  }
  downloadCSV(csvData) {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  async fetchCSVTemplate(templatePath) {
    try {
      const response = await fetch(templatePath);
      if (!response.ok) {
        throw new Error(`Failed to fetch CSV template: ${response.status} ${response.statusText}`);
      }
      return await response.text();
    } catch (error) {
      throw new Error(`Error fetching CSV template: ${error.message}`);
    }
  }

  render() {
    return (
      <div>
        <button className="mt-5 button-geral" onClick={() => this.populateCSVFormandos()}>Exportar Disciplinas para CSV</button>
        <p className='mb-10 text-text-error'>É necessário inserir o ID da categoria</p>
      </div>
    );
  }
}

export default CSVGeneratorDisciplinas;
