import { useSpring, animated } from "react-spring";

//Função para mudar a cor caso ja tenha preenchido esse step
const MultiStepForm = ({ steps, currentStep }) => {

    const percentage = (100 * (currentStep + 1)) / steps.length

    /* Animação do react spring */
    const styles = useSpring({
        from: {
            "--value": 0,
        },
        to: {
            "--value": percentage,
        },
    });

    return (
        <>
            <div className='items-center justify-center hidden xl:flex'>
                {steps.map((step, index) => (
                    <div key={index} className='flex items-center justify-center'>
                        <p className={index === currentStep ? 'step-active' : 'step-inactive'}>{step}</p>
                        {index !== steps.length - 1 && <i className="m-1 text-gray-600 fas fa-caret-right"></i>}
                    </div>
                ))}
            </div>

            <div className="flex flex-col items-center justify-center sm:flex-row xl:hidden">{/* Componente abaixo é deste site https://daisyui.com/components/radial-progress/  */}
                {/* Componente deste bibilioteca https://www.npmjs.com/package/react-spring */}
                <animated.div className="border radial-progress bg-secundary-color border-text-main-color text-main-color" style={styles}>
                    {currentStep + 1} de {steps.length}
                </animated.div>
                <div className="flex flex-col items-center justify-center sm:ml-10 md:ml-32">
                    <div className="text-text-second-color">{steps[currentStep]}</div>
                    {steps[currentStep + 1] ? <div className="text-text-second-color">Próximo: {steps[currentStep + 1]}</div> : ""}
                </div>
            </div>

        </>


    );
};

export default MultiStepForm;
