import React, { useState, useEffect } from "react";
import Header from "../../../../components/Header";
import { AreaUFCDsIndividual } from "./styled";
import { useParams, Link } from 'react-router-dom';
import Footer from "../../../../components/Footer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function UFCDsIndividual() {
    const [data, setData] = useState([]);
    const [media, setMedia] = useState(0);
    const [codeUfcd, setCodeUfcd] = useState("");
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");


    //filtra dados
    function filterData(data) {
        return data.filter((item) =>
            item.formadores.some((formador) =>
                formador.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.evaluation.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.percurso[0].name.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }

    const params = useParams();

    document.data = data

    function getUfcds() {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/info/${params.codeUfcd}`,
            {
                headers: {
                    'authorization': localStorage.getItem('admin1')
                }
            })
            .then((res) => res.json())
            .then(
                (result) => {

                    setData(result)
                    if (data[0]) {
                        setLoading(false);
                    }
                    else {
                        setLoading(false);
                    }

                    let mediac = 0
                    let codeUfcd = data[0].codeUfcd
                    let nome = data[0].name

                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            mediac = mediac + data[i].evaluation
                        }
                        mediac = Math.round(mediac / data.length);
                    }
                    setMedia(mediac)
                    setCodeUfcd(codeUfcd)
                    setName(nome)
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    }
    useEffect(() => {
        getUfcds()
    }, [loading]);
    if (loading) return "Loading...";
    if (error) return "Error!";
    return (
        <>
            <Header />

            <AreaUFCDsIndividual>
                <main>
                    <Link to={'/menuadmin/' + params.id_candidatura + '/UFCDs/'} className="back-button-link">
                        <img className='back-button' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>
                    <h1 className="title">'Disciplina {codeUfcd} - {name}'</h1>
                    <div className="search"><label className="pesquisar"> Pesquisar: </label>
                        <img className='lupa' alt="Lupa" src={process.env.PUBLIC_URL + "/img/search.svg"} />
                        <input type="text" className="search-input" placeholder="ex: Nome, Curso..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                    </div>
                    <div className="form-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Formador</th>
                                    <th>Curso/percurso</th>
                                    <th>Data inicio</th>
                                    <th>Data final</th>
                                    <th>Avaliação</th>
                                </tr>
                            </thead>
                            {data.length > 0 ? <tbody>
                                {filterData(data).map((item, index) => (
                                    <tr key={index}>
                                        {item.formadores[0] ? <td><a href={`https://magna.comenius.pt/menuadmin/${params.id_candidatura}/list/${item.formadores[0]._id}`}>{item.formadores[0].fullName}</a></td> : <td></td>}
                                        {item.percurso[0] ? <td className="ufcd">{item.percurso[0].name}</td> : <td>Sem curso</td>}
                                        {item.formadores[0] ? <td>{item.dateBegin}</td> : <td></td>}
                                        {item.formadores[0] ? <td>{item.dateEnd}</td> : <td></td>}
                                        {item.formadores[0] ? <td>{item.evaluation !== null ? item.evaluation + "%" : "Em Atividade"}</td> : <td></td>}
                                    </tr>))}
                            </tbody> : <tbody />}
                        </table>
                    </div>
                </main>
            </AreaUFCDsIndividual>
            <Footer />

        </>
    );
}