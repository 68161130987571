import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer";
import toastSuccess from "../../../components/Toast/toastSuccess";
import toastError from "../../../components/Toast/toastError";
import 'react-toastify/dist/ReactToastify.css';
import containsPostalCode from "../../../components/FormChecks/containsPostalCode/containsPostalCode";
import check_nif_number from "../../../components/FormChecks/checkNif/checkNIf";
import NavbarAdmin2 from "../../../components/Navbars/NavbarAdmin2/NavbarAdmin2";

function EntFormadora() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [name, setName] = useState("");
    const [gestorFormacao, setGestorFormacao] = useState("");
    const [morada, setMorada] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [localidade, setLocalidade] = useState("");
    const [nif, setNif] = useState("");
    const [representanteLegal, setrepresentanteLegal] = useState("");
    const [codDGERT, setCodDGERT] = useState("");
    const [fileDGERT, setfileDGERT] = useState(null);
    const [fileAssinatura, setfileAssiantura] = useState(null);
    const [imageHeightLogo, setimageHeightLogo] = useState(0);
    const [imageHeightAssinatura, setimageHeightAssinatura] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const image = new Image();
    const navigate = useNavigate();

    const handleFileChangeLogo = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onload = function (e) {
                image.onload = function () {
                    setimageHeightLogo(image.height)
                };
                image.src = e.target.result;
            };
            reader.readAsDataURL(file);
        } else setSelectedFile(null);
    };

    const handleFileChangeAssinatura = (event) => {
        const file = event.target.files[0];
        if (file) {
            setfileAssiantura(file);
            const reader = new FileReader();
            reader.onload = function (e) {
                image.onload = function () {
                    setimageHeightAssinatura(image.height)
                };
                image.src = e.target.result;
            };
            reader.readAsDataURL(file);
        }else setfileAssiantura(null)
    };

    const handleSubmit = async () => {
        if (name !== "" && representanteLegal !== "" && codDGERT !== "" && nif !== "" && morada !== "" && localidade !== "" && postalCode !== "" && localidade !== "" && gestorFormacao !== "") {
            if (containsPostalCode(postalCode)) {
                if (check_nif_number(nif)) {
                    if (selectedFile !== null && fileDGERT !== null && fileAssinatura !== null) {
                        if (fileDGERT.type === "application/pdf") {
                            if (selectedFile.type === "image/png" || selectedFile.type === "image/jpeg" || fileAssinatura.type === "image/png" || fileAssinatura.type === "image/png") {
                                if (selectedFile.size <= 200000 || fileAssinatura.size <= 200000) {
                                    if (imageHeightLogo <= 240 || imageHeightAssinatura <= 240) {
                                        setLoading(true)
                                        try {
                                            const options = {
                                                method: "POST",
                                                headers: {
                                                    "Accept": "application/json",
                                                    'authorization': localStorage.getItem('admin2')
                                                },
                                                credentials: "same-origin"
                                            }
                                            let ccForm = new FormData()
                                            ccForm.append('logo', selectedFile)
                                            options.body = ccForm
                                            const logoResponse = await fetch(`${process.env.REACT_APP_API_URL}/logo`, options)
                                            const logoResult = await logoResponse.json()

                                            let certificadoForm = new FormData()
                                            certificadoForm.append('certificado', fileDGERT)
                                            options.body = certificadoForm
                                            const certificadoResponse = await fetch(`${process.env.REACT_APP_API_URL}/certificado`, options)
                                            const certificadoResult = await certificadoResponse.json()

                                            let assinaturaForm = new FormData()
                                            assinaturaForm.append('assinatura', fileAssinatura)
                                            options.body = assinaturaForm
                                            const assinaturaResponse = await fetch(`${process.env.REACT_APP_API_URL}/assinatura`, options)
                                            const assinaturaResult = await assinaturaResponse.json()

                                            fetch(`${process.env.REACT_APP_API_URL}/EntFormadora`,
                                                {
                                                    headers: {
                                                        'Accept': 'application/json',
                                                        'Content-Type': 'application/json',
                                                        'authorization': localStorage.getItem('admin2')
                                                    },
                                                    method: 'POST',
                                                    body: JSON.stringify({
                                                        name: name,
                                                        logotipo: logoResult,
                                                        representanteLegal: representanteLegal,
                                                        gestorFormacao: gestorFormacao,
                                                        codDGERT: codDGERT,
                                                        localidade: localidade,
                                                        postalCode: postalCode,
                                                        morada: morada,
                                                        nif: nif,
                                                        certificado: certificadoResult,
                                                        assinatura: assinaturaResult,
                                                    })
                                                })
                                                .then(res => res.json())
                                                .then((result) => {
                                                    setLoading(false)
                                                    toastSuccess('Entidade Formadora adicionada com sucesso!')
                                                    navigate('/MenuSuperAdmin')
                                                })
                                        } catch (error) {
                                            setLoading(false);
                                            console.error('Error uploading file:', error);
                                        }
                                    } else toastError('A altura da imagem deve ser menor ou igual a 240 píxeis.');
                                } else toastError('Ficheiro demasiado grande.')
                            } else toastError('o logotipo apenas pode ser um png ou jpeg.');;
                        } else toastError('O certificado apenas pode ser um pdf.');;
                    } else toastError('Adicione todos os ficheiros.');
                } else toastError("Número de identificação de pessoa coletiva inválido.")
            } else toastError("O Código Postal é inválido. \nEx:1111-000")
        } else toastError('Preencha todos os campos obrigatórios.');
    };

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin2 currentPage={"criarEntidadeTraining"} />
                </div>
                <Link to={'/MenuSuperAdmin'} className="back-button-div-noNavbar">
                    <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                </Link>
                <div className="flex-grow mt-20 sm:mt-8">
                    <h1 className="title">Criar entidade formadora</h1>
                    <div className="flex justify-center">
                        <div className="grid mx-2 sm:grid-cols-2 gap-x-16">
                            <div>
                                <div className="flex flex-col items-start mx-4 sm:mx-0">
                                    <label className="label-input">Nome*:</label>
                                    <input id="name" className="w-full input-field" onChange={(e) => setName(e.target.value)}></input>
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input">Representante Legal*:</label>
                                    <input id="name" className="w-full input-field" onChange={(e) => setrepresentanteLegal(e.target.value)}></input>
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input">Código DGERT*:</label>
                                    <input id="name" className="w-full input-field" onChange={(e) => setCodDGERT(e.target.value)}></input>
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input" htmlFor="address">Morada:*</label>
                                    <input type="text" className="w-full input-field" id="address" name="address" value={morada} onChange={(e) => { setMorada(e.target.value) }} required></input>
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input" htmlFor="locality">Localidade:*</label>
                                    <input type="text" className="w-full input-field" id="locality" name="locality" value={localidade} onChange={(e) => { setLocalidade(e.target.value) }} required></input>
                                </div>

                            </div>
                            <div>
                                <div className="flex flex-col items-start mx-4 sm:mx-0">
                                    <label className="label-input" htmlFor="gestorFormacao">Gestor de Formação:*</label>
                                    <input type="text" className="w-full input-field" id="gestorFormacao" name="gestorFormacao" value={gestorFormacao} onChange={(e) => { setGestorFormacao(e.target.value) }} required></input>
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input" htmlFor="postalCode">Código Postal:*</label>
                                    <input type="text" className="w-full input-field" id="postalCode" name="postalCode" pattern="[0-9]{4}[\-]?[0-9]{3}" value={postalCode} onChange={(e) => { setPostalCode(e.target.value) }} required></input>
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input" htmlFor="nif">Número de identificação de pessoa coletiva*:</label>
                                    <input type="text" className="w-full input-field" id="nif" name="nif" minLength={9} maxLength={9} value={nif} onChange={(e) => { setNif(e.target.value) }} required></input>
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input">Logotipo*:</label>
                                    <input className="input-file" type="file" accept=".jpeg, .jpg, .png" onChange={handleFileChangeLogo} />
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input">Assinatura*:</label>
                                    <input className="input-file" type="file" accept=".jpeg, .jpg, .png" onChange={handleFileChangeAssinatura} />
                                </div>
                                <div className="flex flex-col items-start mx-4 mt-3 sm:mx-0">
                                    <label className="label-input">Certificação*:</label>
                                    <input className="input-file" type="file" accept=".pdf" onChange={(e) => setfileDGERT(e.target.files[0])} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="my-8 button-geral" onClick={handleSubmit}>Adicionar</button>
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main>
        </>
    );
}

export default EntFormadora