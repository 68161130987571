import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import { Link, useParams } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import NavbarAdmin1 from "../../components/Navbars/NavbarAdmin1/NavbarAdmin1";
import { BsBuildingFillAdd, BsFillBuildingFill, BsPersonFillAdd } from "react-icons/bs";
import { FaCube, FaUserEdit, FaUserGraduate, FaUserTag } from "react-icons/fa";
import { PiCubeFocusLight, PiUserListBold, PiUserListFill } from "react-icons/pi";
import { MdLibraryAdd, MdLibraryBooks } from "react-icons/md";
import { ChalkboardTeacher } from "@phosphor-icons/react";
import { BiSolidBox } from "react-icons/bi";
import { IoWarningOutline } from "react-icons/io5";
import CheckTypeAdmin from "../../components/CheckTypeAdmin/CheckTypeAdmin";
import CheckTypeFinanceiro from "../../components/CheckTypeAdmin/CheckTypeFinanceiro";
import CheckTypeFA from "../../components/CheckTypeAdmin/CheckTypeFA";
import CheckTypeMarketing from "../../components/CheckTypeAdmin/CheckTypeMarketing";
import CheckResponsavelEntidade from "../../components/CheckTypeAdmin/CheckResponsavelEntidade";

const MenuCoordinator = () => {
    const [data, setData] = useState("")
    const [loading, setLoading] = useState(false)
    const [entidades, setEntidades] = useState(-1)
    const params = useParams();
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/formadores`, {
            headers: {
                "authorization": localStorage.getItem('admin1')
            }
        }).then((res) => res.json())
            .then(
                (result) => {
                    let array = []
                    result.forEach(element => {
                        if (element.valid === false) {
                            array.push(element)
                        }
                    });
                    setData(array.length);
                    setLoading(true)
                },
            )
            if(entidades === -1){
                fetch(`${process.env.REACT_APP_API_URL}/admins/${params.id_candidatura}`, {
                    headers: {
                        "authorization": localStorage.getItem('admin1')
                    }
                }).then((res) => res.json())
                    .then(
                        (result) => {

                            setEntidades(result.entFormadora.length)
                        },
                    )
            }
    }, [loading])
    return (
        <>
            <ToastContainer />
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin1 currentPage={"home"} />
                </div>
                <div className="flex-grow mt-8 mb-3">
                    <h1 className="title">Menu do Administrador</h1>

                    <div className="w-full">
                        <div className="mx-20">
                           {CheckTypeFA() === false && CheckTypeMarketing() === false && CheckTypeAdmin() === true  && <><h2 className="m-0 text-center uppercase subtitle xl:text-left">Administradores</h2>
                            <div className="grid grid-cols-1 mb-3 xl:grid-cols-5 justify-items-center xl:justify-items-start">
                                <Link to={"/menuadmin/Profile/" + params.id_candidatura} className="menu-admin-buttons">
                                    <FaUserEdit className="w-6 h-6 mr-2 text-white" />
                                    O meu perfil
                                </Link>
                                <Link to={"/MenuAdmin/addAdministradores/" + params.id_candidatura} className="menu-admin-buttons">
                                    <BsPersonFillAdd className="w-6 h-6 mr-2 text-white" />
                                    Criar administradores
                                </Link>
                                <Link to={"/menuadmin/" + params.id_candidatura + "/listAdmins"} className="menu-admin-buttons">
                                    <FaUserTag className="w-6 h-6 mr-2 text-white" />
                                    Ver Administradores
                                </Link>
                                <Link to={"/menuadmin/" + params.id_candidatura + "/operacoes"} className="menu-admin-buttons">
                                    <BiSolidBox className="w-6 h-6 mr-2 text-white" />
                                    Gerir Operações
                                </Link>
                            </div></>}
                            {CheckTypeFA() === false && CheckTypeMarketing() === false && CheckTypeAdmin() ===true  && <><h2 className="m-0 text-center uppercase subtitle xl:text-left">Coordenadores Pedagógicos</h2>
                            <div className="grid grid-cols-1 mb-3 xl:grid-cols-5 justify-items-center xl:justify-items-start">
                                <Link to={"/menuAdmin/" + params.id_candidatura + "/addCoordinators"} className="menu-admin-buttons">
                                    <BsPersonFillAdd className="w-6 h-6 mr-2 text-white" />
                                    Criar Coordenadores
                                </Link>
                                <Link to={"/menuadmin/" + params.id_candidatura + "/coordinators"} className="menu-admin-buttons">
                                    <FaUserEdit className="w-6 h-6 mr-2 text-white" />
                                    Gerir Coordenadores
                                </Link>
                            </div></>}
                            {CheckTypeFA() === false && CheckTypeMarketing() === false && CheckTypeFinanceiro() ===true  && <><h2 className="m-0 text-center uppercase subtitle xl:text-left">Formadores</h2>
                            <div className="grid grid-cols-1 mb-3 xl:grid-cols-5 justify-items-center xl:justify-items-start">
                                <Link to={"/menuadmin/" + params.id_candidatura + "/candidacies"} className={data === 0 ? "menu-admin-buttons flex justify-between" : "menu-admin-buttons flex justify-between brightness-150 tooltip"}  data-tip={`${data} candidaturas`}>
                                    <div className="flex">
                                        <PiUserListBold className="w-6 h-6 mr-2 text-white" />
                                        Ver Candidaturas
                                    </div>
                                    {data !== 0 && <IoWarningOutline  className="w-6 h-6 mr-2 text-white " />}
                                </Link>
                                <Link to={"/menuadmin/" + params.id_candidatura + "/list"} className="menu-admin-buttons">
                                    <PiUserListFill className="w-6 h-6 mr-2 text-white" />
                                    Bolsa de Formadores
                                </Link>
                            </div></>}
                            {CheckTypeFA() === false && CheckTypeMarketing() === false && CheckTypeAdmin() ===true  && <><h2 className="m-0 text-center uppercase subtitle xl:text-left">Entidades</h2>
                            <div className="grid grid-cols-1 mb-3 xl:grid-cols-5 justify-items-center xl:justify-items-start">
                                <Link to={"/menuadmin/" + params.id_candidatura + "/CriarEntFormadora"} className="menu-admin-buttons">
                                    <img src="/img/school-circle-check-solid.png" className="object-contain w-3 h-4 mr-2 sm:w-5 sm:h-5 invert brightness-0" />
                                    Criar Entidade Formadora
                                </Link>
                                <Link to={"/menuadmin/" + params.id_candidatura + "/entFormadoras"} className="menu-admin-buttons">
                                    <img src="/img/school.svg" className="object-contain w-3 h-4 mr-2 sm:w-5 sm:h-5 invert brightness-0" />
                                    Gerir Entidades Formadoras
                                </Link>
                                <Link to={"/menuadmin/" + params.id_candidatura + "/CriarEntFinanciadora"} className="menu-admin-buttons">
                                    <BsBuildingFillAdd className="w-6 h-6 mr-2 text-white" />
                                    Criar Entidade Financiadora
                                </Link>
                                <Link to={"/menuadmin/" + params.id_candidatura + "/entFinanciadoras"} className="menu-admin-buttons">
                                    <BsFillBuildingFill className="w-5 h-6 mr-1 text-white" />
                                    Gerir Entidades Financiadoras
                                </Link>
                            </div></>}
                            {CheckTypeMarketing() === false && <><h2 className="m-0 text-center uppercase subtitle xl:text-left">Cursos/Percursos</h2>
                            <div className="grid grid-cols-1 mb-3 xl:grid-cols-5 justify-items-center xl:justify-items-start">
                               {CheckTypeAdmin() ===true && entidades > 0 &&<Link to={"/menuadmin/" + params.id_candidatura + "/cursos/addcursos"} className="menu-admin-buttons">
                                    <MdLibraryAdd className="w-6 h-6 mr-2 text-white" />
                                    Criar Curso/Percursos
                                </Link>}
                                {entidades > 0 &&<Link to={"/menuadmin/" + params.id_candidatura + "/cursos"} className="menu-admin-buttons">
                                    <MdLibraryBooks className="w-6 h-6 mr-2 text-white" />
                                    Gerir Cursos/Percursos
                                </Link>}
                                {CheckTypeAdmin() ===true  &&<Link to={"/menuadmin/" + params.id_candidatura + "/templates"} className="menu-admin-buttons">
                                    <FaCube className="w-4 h-6 mr-1 text-white" />
                                    Criar Cursos/Percursos Modelo
                                </Link>}
                                {CheckTypeAdmin() ===true  &&<Link to={"/menuadmin/" + params.id_candidatura + "/UFCDsGlobais"} className="menu-admin-buttons">
                                    <PiCubeFocusLight className="w-6 h-6 mr-2 text-white" />
                                    Criar Disciplinas Modelo
                                </Link>}
                            </div></>}
                            {CheckResponsavelEntidade() ===true  &&<><h2 className="m-0 text-center uppercase subtitle xl:text-left">Formandos</h2>
                            <div className="grid grid-cols-1 mb-3 xl:grid-cols-5 justify-items-center xl:justify-items-start">
                                <Link to={"/menuadmin/" + params.id_candidatura + "/formandos"} className="menu-admin-buttons">
                                    <FaUserGraduate className="w-4 h-6 mr-1 text-white" />
                                    Ver todos Formandos
                                </Link>
                                {(entidades > 0 || CheckTypeMarketing() === true) &&<Link to={"/menuadmin/" + params.id_candidatura + "/turmas"} className="menu-admin-buttons">
                                    <ChalkboardTeacher weight="fill" className="w-6 h-6 mr-1 text-white" />
                                    Gerir Turmas
                                </Link>}
                            </div></>}
                        </div>
                    </div>
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main>
        </>
    );
}

export default MenuCoordinator