import React, { useState } from "react";
import { AreaEmail } from "./styled";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import { toast } from "react-toastify";
import toastError from "../../../../components/Toast/toastError";
import toastSuccess from "../../../../components/Toast/toastSuccess";


function ForgotPassword() {

    const [email, setEmail] = useState('');
    const [data, setData] = useState([]);

    function verifyEmail(e) {
        e.preventDefault()

        getEmail().then((data) => {
            if (data.status === "not_found" || data.status === "not_valid") {
                toastError(data.message)
            }
            else if (data.status === 'valid') {
                toastSuccess(data.message)
            }
            else {
            }
        })
    }

    //Função que verifica todos os emails
    function getEmail() {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}/formandos/login/email`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        email: email
                    }),
                })
                .then(res => res.json())
                .then((result) => {
                    localStorage.setItem('jwt', result.jwt)
                    setData(result)
                    resolve(result)

                }, (error) => {
                    console.error("Error fetching data: ", error);

                    reject(error)
                }
                )
        });
    }

    return (
        <>
            <Header />
            <AreaEmail>
                <main>
                    <div className="container">
                        <div className="title-email">
                            <h1>Insira o seu E-mail para recuperar a Palavra-passe</h1>
                        </div>
                        <div id="email-form">
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                            />
                            <div className="footer">
                                <button className="button-submit" onClick={verifyEmail}>
                                    Enviar
                                </button>
                            </div>
                        </div>
                    </div>
                </main>
            </AreaEmail>
            <Footer />
        </>
    );
}

export default ForgotPassword