import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import 'react-toastify/dist/ReactToastify.css';
import NavbarFormador from "../../../../components/Navbars/NavbarFormador/navbarFormador";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";

function Candidaturas() {
    const [data, setData] = useState(null);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const savedSearchTerm = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Formadores-cursosPercursos-1") : "";
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm ? savedSearchTerm : "");
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Formadores-cursosPercursos-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const navigate = useNavigate();
    const params = useParams();
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Formadores-cursosPercursos-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)

    useEffect(() => {
        Cookies.set("Formadores-cursosPercursos-Pag", paginaAtual);
        Cookies.set("Formadores-cursosPercursos-1", searchTerm);
        Cookies.set(`Formadores-cursosPercursos-itm1`, itemsPag)
        getPercursos()
    }, [loading, paginaAtual, nPaginas, searchTerm, itemsPag]);

    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }


    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };

    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }

    //filtra dados
    function filterData(data) {
        return data.filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.modalidade.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.locality.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }
    // Mudar para Formador
    function getPercursos() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/formador/${params.id_candidatura}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem('user')
            },
            method: 'GET',
        })
            .then(res => res.json())
            .then((result) => {
                setData(result)
                paginacaoTabelas(filterData(result));
                setLoading(false)
            })
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }
    return (
        <>
            <main className="min-h-screen grid-cols-7 mx-auto md:grid xl:grid-cols-10">
                <div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    <NavbarFormador currentPage={"cursos"} />
                </div>

                <div className="h-full col-span-5 xl:col-span-8">
                    <Header sideMenu={"true"} />

                    <h1 className="title">Lista de cursos/percursos</h1>
                    {data && data.length > 0 ? <> <div className="search-div">
                        <label className="search-input-label"> Ver apenas: </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input type="search" placeholder="ex: Nome, Código..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="search-input" />
                        </div>
                    </div>
                        <div className="flex justify-center items-center mb-1">
                            <label className="text-text-main-color">Dados por página: </label>
                            <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                value={itemsPag}
                                onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div className="table-containerSideMenu">
                            <table className="mx-4 my-table">

                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="p-2 min-w-[80px] border border-white pl-2">Operação</th>
                                        <th className="p-2 min-w-[80px] border border-white">Entidade Formadora</th>
                                        <th className="p-2 min-w-[80px] border border-white">Nome</th>
                                        <th className="p-2 min-w-[80px] border border-white">Código Interno</th>
                                        <th className="p-2 min-w-[80px] border border-white">Código SIGO</th>
                                        <th className="p-2 min-w-[80px] border border-white">Forma de Organização</th>
                                        <th className="p-2 min-w-[80px] border border-white">Modalidades</th>
                                        <th className="p-2 min-w-[80px] border border-white">Local</th>
                                        <th className="p-2 min-w-[80px] border border-white">Nº de Disciplinas</th>
                                        <th className="p-2 min-w-[80px] border border-white">Data de Inicio</th>
                                        <th className="p-2 min-w-[80px] border border-white">Data de Fim</th>
                                        <th className="p-2 min-w-[80px] border border-white">Duração</th>
                                        <th className="p-2 min-w-[80px] border border-white pr-2">Avaliação do Coordenador</th>
                                    </tr>
                                </thead>
                                {data ? (
                                    <tbody className="text-text-main-color">
                                        {filterData(dataToDisplay)
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((item, index) => (
                                                <tr key={index} className="row-click" onClick={() => navigate("/menutrainers/" + params.id_candidatura + "/cursos/" + item._id)}>
                                                    <td className="border border-white">{item.operacao[0].codigoOperacao}</td>
                                                    <td className="border border-white">{item.entFormadora[0] ? <>{item.entFormadora[0].name} </> : ""}</td>
                                                    <td className="border border-white">{item.name ? <>{item.name} </> : ""}</td>
                                                    <td className="border border-white">{item.codeInterno ? <>{item.codeInterno} </> : ""}</td>
                                                    <td className="border border-white">{item.codeSIGO ? <>{item.codeSIGO} </> : ""}</td>
                                                    <td className="border border-white">{item.type ? <> {item.type} </> : ""}</td>
                                                    <td className="border border-white">{item.modalidade ? <>{item.modalidade}</> : ""}</td>
                                                    <td className="border border-white">{item.locality ? <>{item.locality}</> : ""}</td>
                                                    <td className="border border-white">{item.ufcds.length > 0 ? <>{item.ufcds.length} </> : ""}</td>
                                                    <td className="border border-white">{item.dateBegin ? <>{item.dateBegin} </> : ""}</td>
                                                    <td className="border border-white">{item.dateEnd ? <>{item.dateEnd} </> : ""}</td>
                                                    <td className="border border-white">{item.duration ? <>{item.duration} </> : ""}</td>
                                                    {item.dateEnd ? (
                                                        <>
                                                            {item.fechoAdmin === true
                                                                ? <>{(item.evaluation || item.evaluation === 0)
                                                                    ? <td className="border border-white">{item.evaluation}%</td>
                                                                    : <td className="border border-white"><Link to={"/menuadmin/" + params.id_candidatura + "/cursos/" + params.id_curso + "/avaliacao/" + item._id}>Avaliar</Link></td>}</>
                                                                : <td className="border border-white">Curso por Fechar</td>}
                                                        </>
                                                    ) : (
                                                        <td className="border border-white">Curso em A</td>
                                                    )}
                                                </tr>
                                            ))}
                                    </tbody>
                                ) : (
                                    <tbody />
                                )}
                            </table>
                        </div>
                        <div className="flex items-center justify-center mt-4 mb-8">
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                <AiOutlineDoubleLeft />
                            </button>
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                <AiOutlineLeft />
                            </button>
                            <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                <AiOutlineRight />
                            </button>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                <AiOutlineDoubleRight />
                            </button>
                        </div> </> : <p className="third-title">Não existem dados</p>}
                </div>
            </main >
            <Footer />
        </>
    );
}

export default Candidaturas