export default function ArrayHabilitacoes() {
    const HabilitacoesArray = [
        "15 - Não sabe ler nem escrever",
        "8 - < 4 anos escolaridade",
        "3 - 2º ciclo (6º ano)",
        "4 - 3º ciclo (9º ano)",
        "5 - Ensino Secundário",
        "16 - Ensino Pós-Secundário não superior (ex CET Cursos de Especialização Tecnológica)",
        "17 - Cursos superiores de curta duração (ex TeSP Cursos Técnicos Superiores Profissionais)",
        "14 - Bacharelato",
        "18 - Licenciatura",
        "19 - Licenciatura pré-Bolonha",
        "12 - Mestrado",
        "21 - Mestrado Pré-Bolonha",
        "9 - Doutoramento"
    ];
    return HabilitacoesArray;
}