import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import 'react-toastify/dist/ReactToastify.css';
import NavbarCoordenador from "../../../components/Navbars/NavbarCoordenador/navbarCoordenador";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";

function Candidaturas() {
    const [data, setData] = useState(null);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const saveEntFormadora = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Coordenadores-cursos-F1") : "";
    const [listaEntFormadora, setListaEntFormadora] = useState([]);
    const saveOperacao = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Coordenadores-cursos-F2") : ""
    const [listaOperacao, setListaOperacao] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [valueEntFormadora, setValueEntFormadora] = useState(saveEntFormadora ? saveEntFormadora : "");
    const [valueOperacao, setValueOperacao] = useState(saveOperacao ? saveOperacao : "");
    const saveTerminados = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Coordenadores-cursos-F3") : "";
    const [valueTerminados, setValueTerminados] = useState(saveTerminados ? saveTerminados : false);
    const savedSearchTerm = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Coordenadores-cursos-1") : "";
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm ? savedSearchTerm : "");
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Coordenadores-cursos-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Coordenadores-cursos-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)

    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        Cookies.set("Coordenadores-cursos-F1", valueEntFormadora);
        Cookies.set("Coordenadores-cursos-F2", valueOperacao)
        Cookies.set("Coordenadores-cursos-F3", valueTerminados)
        Cookies.set("Coordenadores-cursos-Pag", paginaAtual);
        Cookies.set("Coordenadores-cursos-1", searchTerm);
        Cookies.set(`Coordenadores-cursos-itm1`, itemsPag)

        getPercursos()

    }, [loading, valueEntFormadora, valueOperacao, paginaAtual, nPaginas, searchTerm, itemsPag, valueTerminados]);

    //Atribuir numero de páginas
    function paginacaoTabelas(data) {

        data = data.filter((item) => {
            if (!valueOperacao) {
                return true; // Show all values when valueOperacao is empty
            } else {
                if( item.operacao[0]){
                    return item.operacao[0]._id === valueOperacao;
                }
            }
        }).filter((item) => {
            if (!valueEntFormadora) {
                return true; // Show all values when valueOperacao is empty
            } else {
                if( item.entFormadora[0]){
                return item.entFormadora[0]._id === valueEntFormadora;
                }
            }
        });

        if (data.length < 10) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }

        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }


    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }

    //filtra dados
    function filterData(data) {
        return data.filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.entFormadora[0] && item.entFormadora[0].name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.operacao[0] && item.operacao[0].codigoOperacao.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.locality.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.modalidade.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }


    function getPercursos() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/ativos/${params.id_candidatura}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem("coord")
            },
        })
            .then(res => res.json())
            .then((result) => {
                const entidadesFormadoras = {};
                const operacoes = {};

                result.forEach(element => {
                    const entFormadora = element.entFormadora[0];
                    const operacao = element.operacao[0];

                    if (entFormadora && !entidadesFormadoras[entFormadora._id]) {
                        entidadesFormadoras[entFormadora._id] = {
                            entFormadora: entFormadora._id,
                            name: entFormadora.name,
                        };
                    }

                    if (operacao && !operacoes[operacao._id]) {
                        operacoes[operacao._id] = {
                            operacao: operacao._id,
                            codigoOperacao: operacao.codigoOperacao,
                        };
                    }
                });

                const dataFiltered = Object.values(entidadesFormadoras);
                const operacoesArray = Object.values(operacoes);

                let filterTerminados = result
                if (valueTerminados === false || valueTerminados === "false") {
                    filterTerminados = filterTerminados.filter(percursos => !percursos.fechoAdmin)
                }
                setData(filterTerminados);
                setListaOperacao(operacoesArray);
                setListaEntFormadora(dataFiltered);
                paginacaoTabelas(filterData(filterTerminados));
                setLoading(false);
            });
    } 

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }

    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };
    return (
        <>
            <main className="min-h-screen grid-cols-7 mx-auto md:grid xl:grid-cols-10">
                <div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    <NavbarCoordenador currentPage={"cursos"} />
                </div>

                <div className="relative h-full col-span-5 mx-4 xl:col-span-8">
                    <Header sideMenu={"true"} />
                    <h1 className="title">Lista de Cursos/Percursos</h1>
                    {data && data.length > 0 ? <><div className="search-div">
                        <label className="search-input-label">Pesquisar: </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input type="text" placeholder="ex: Nome" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="search-input" />
                        </div>
                    </div>

                        <div className="flex flex-col items-center justify-center mt-4 mb-2 lg:flex-row">
                            <label className="text-text-second-color">Entidades Formadoras: </label>
                            {listaEntFormadora.length > 0 && <select onWheel={(e) => e.target.blur()} defaultValue={valueEntFormadora} className="search-selector w-36" name="sel" id="seletorEnt" onChange={(e) => setValueEntFormadora(e.target.value)}>
                                <option value="">Todas</option>
                                {listaEntFormadora.map((item) => (
                                    <option value={item.entFormadora} key={item.entFormadora}>{item.name}</option>
                                ))}
                            </select>}
                            <label className="text-text-second-color">Operações: </label>
                            {listaOperacao.length > 0 && <select onWheel={(e) => e.target.blur()} defaultValue={valueOperacao} className="search-selector w-36" name="sel" id="seletorOp" onChange={(e) => setValueOperacao(e.target.value)}>
                                <option value="">Todas</option>
                                {listaOperacao.map((item) => (
                                    <option value={item.operacao} key={item.operacao}>{item.codigoOperacao}</option>
                                ))}
                            </select>}
                            <label className="text-text-second-color">Mostrar Terminados: </label>
                            <input
                                defaultChecked={valueTerminados === "true" && true}
                                type="checkbox"
                                className="checkbox"
                                onChange={(e) => {setValueTerminados(e.target.checked)}}
                            />
                        </div>
                        <div className="flex justify-center items-center mb-1">
                            <label className="text-text-main-color">Dados por página: </label>
                            <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                value={itemsPag}
                                onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div className="table-containerSideMenu">
                            <table className="my-table">

                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="p-2 min-w-[80px] border border-white pl-2">Operação</th>
                                        <th className="p-2 min-w-[80px] border border-white">Entidade Formadora</th>
                                        <th className="p-2 min-w-[80px] border border-white">Nome</th>
                                        <th className="p-2 min-w-[80px] border border-white">Código Interno</th>
                                        <th className="p-2 min-w-[80px] border border-white">Código SIGO</th>
                                        <th className="p-2 min-w-[80px] border border-white">Forma de Organização</th>
                                        <th className="p-2 min-w-[80px] border border-white">Modalidades</th>
                                        <th className="p-2 min-w-[80px] border border-white">Local</th>
                                        <th className="p-2 min-w-[80px] border border-white">Nº de Disciplinas</th>
                                        <th className="p-2 min-w-[80px] border border-white">Data de Inicio</th>
                                        <th className="p-2 min-w-[80px] border border-white">Data de Fim</th>
                                        <th className="p-2 min-w-[80px] border border-white pr-2">Duração</th>
                                        <th className="p-2 min-w-[80px] border border-white pr-2">Nível</th>
                                        <th className="p-2 min-w-[80px] border border-white pr-2">Estado</th>
                                    </tr>
                                </thead>
                                {data.length > 0 ? (
                                    <tbody className="text-text-main-color">
                                        {filterData(dataToDisplay)
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((item, index) => (
                                                <tr key={index} className="row-click" onClick={() => navigate("/menucoordenador/" + params.id_candidatura + "/cursos/" + item._id)}>
                                                    <td className="border border-white item">{item.operacao[0] && item.operacao[0].codigoOperacao}</td>
                                                    <td className="border border-white">{item.entFormadora[0] && item.entFormadora[0].name}</td>
                                                    <td className="border border-white">{item.name && item.name}</td>
                                                    <td className="border border-white">{item.codeInterno && item.codeInterno}</td>
                                                    <td className="border border-white">{item.codeSIGO && item.codeSIGO}</td>
                                                    <td className="border border-white">{item.type && item.type}</td>
                                                    <td className="border border-white">{item.modalidade && item.modalidade}</td>
                                                    <td className="border border-white">{item.locality && item.locality}</td>
                                                    <td className="border border-white">{item.ufcds.length > 0 && item.ufcds.length}</td>
                                                    <td className="border border-white">{item.dateBegin && item.dateBegin}</td>
                                                    <td className="border border-white">{item.dateEnd && item.dateEnd}</td>
                                                    <td className="pr-2 border border-white">{item.duration && item.duration}</td>
                                                    <td className="pr-2 border border-white">{item.nivel && item.nivel}</td>
                                                    <td className="pr-2 border border-white">{item.fechoAdmin ? "Fechado" : "Ativo"}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                ) : (
                                    <tbody></tbody>
                                )}
                            </table>
                        </div>
                        <div className="flex items-center justify-center mt-4 mb-8">
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                <AiOutlineDoubleLeft />
                            </button>
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                <AiOutlineLeft />
                            </button>
                            <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                <AiOutlineRight />
                            </button>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                <AiOutlineDoubleRight />
                            </button>
                        </div> </> : <p className="third-title">Não existem dados</p>}
                </div>
            </main >
            <Footer />
        </>
    );
}

export default Candidaturas