import React, { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import Footer from "../../../components/Footer";
import toastSuccess from "../../../components/Toast/toastSuccess";
import onlyString from "../../../components/FormChecks/onlyString/onlyString";
import toastError from "../../../components/Toast/toastError";
import { BsPencilFill } from "react-icons/bs";
import check_nif_number from "../../../components/FormChecks/checkNif/checkNIf";
import NavbarAdmin2 from "../../../components/Navbars/NavbarAdmin2/NavbarAdmin2";
import ConfirmToaster from "../../../components/Toast/toastConfirmChoice";
import isNumber from "../../../components/isNumber/isNumber";

export default function Trainer() {
    const [data, setData] = useState(null);
    const [data2, setData2] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [cont, setCont] = useState(0);
    const [alterar, setAlterar] = useState(false);
    const [entFormadoraList, setEntFormadoraList] = useState([]);
    const [tipologiasList, setTipologiasList] = useState([]);
    const [codigoOperacaoList, setCodigoOperacaoList] = useState([]);
    const [nameOperacaoList, setNameOperacaoList] = useState([]);

    document.data = data

    const params = useParams();

    useEffect(() => {
        getAdmin()
        getEntFormadoras()
        getOperacoes() // eslint-disable-next-line
    }, [cont]);

    function alterarConfirmar() {
        setAlterar(true)
    }

    function getAdmin() {
        fetch(`${process.env.REACT_APP_API_URL}/admins/${params.id_candidatura}`, {
            headers: {
                'authorization': localStorage.getItem('admin2')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    getAllAdmins()
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    };

    function getAllAdmins() {
        fetch(`${process.env.REACT_APP_API_URL}/admins`, {
            headers: {
                'authorization': localStorage.getItem('admin2')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData2(result);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    }

    function getEntFormadoras() {
        fetch(`${process.env.REACT_APP_API_URL}/EntFormadora`, {
            headers: {
                "authorization": localStorage.getItem('admin2')
            },
            method: 'GET',
        })
            .then(response => response.json())
            .then(result => {

                setEntFormadoraList(result)
            });
    }


    function getOperacoes() {
        fetch(`${process.env.REACT_APP_API_URL}/Operacao`, {
            headers: {
                "authorization": localStorage.getItem('admin2')
            },
            method: 'GET',
        })
            .then(response => response.json())
            .then(result => {
                setTipologiasList(result.tipologias)
                setCodigoOperacaoList(result.codigoOperacoes)
                setNameOperacaoList(result.nomeOperacoes)
            });
    }

  

    function alterarPerfil() {
        if (document.getElementById('cellNumber').value !== "" && document.getElementById('email').value !== "" && document.getElementById('locality').value !== "" && document.getElementById('nif').value !== "" && document.getElementById('name').value !== "") {
            let cellNumber = document.getElementById('cellNumber').value;
            if (isNumber(cellNumber) && cellNumber.length < 13 && cellNumber > 0) {
                let cont = 0
                function verifyEmail() {
                    for (var i = 0; i < data2.length; i++) {
                        if (data2[i].email === document.getElementById('email').value) {
                            if (document.getElementById('email').defaultValue === document.getElementById('email').value);
                            else cont++;
                        }
                    }
                }
                verifyEmail()
                // Verificar se o email tem "@" e o "."
                let checkEmail = document.getElementById('email').value.includes("@" && ".")
                if (checkEmail === true && cont === 0) {
                    if (document.getElementById('locality').value) {
                        if (check_nif_number(document.getElementById('nif').value)) {
                            if (onlyString(document.getElementById('name').value)) {

                                const formData = {
                                    fullName: document.getElementById('name').value,
                                    cellNumber: document.getElementById('cellNumber').value,
                                    email: document.getElementById('email').value,
                                    locality: document.getElementById('locality').value,
                                    nif: document.getElementById('nif').value,
                                };
                                fetch(`${process.env.REACT_APP_API_URL}/admins/${params.id_candidatura}`,
                                    {
                                        body: JSON.stringify(
                                            formData
                                        ),
                                        method: "PATCH",
                                        headers: {
                                            'Accept': 'application/json',
                                            'Content-Type': 'application/json',
                                            'authorization': localStorage.getItem('admin2')
                                        }
                                    }).then((result) => {
                                        result.json().then((resp) => {
                                            if (resp === "Error Email") {
                                                toastError("Email já existente.")
                                            } else {
                                                toastSuccess('Dados alterados com sucesso!')
                                                getAdmin();
                                                setAlterar(false);
                                            }
                                        })
                                    })
                            } else toastError("Nome Inválido")
                        } else toastError("NIF Inválido")
                    } else toastError("Localidade inválida")
                } else toastError("Email invalido ou existente")
            } else toastError("Nº de telemóvel inválido")
        } else toastError("Dados não inseridos")
    }

    const handleFileUpload = async (event) => {
        setLoading(true);

        const selectedFile = event.target.files[0];
        let ccForm = new FormData();
        ccForm.append('logo', selectedFile);

        const options = {
            method: "POST",
            headers: { "Accept": "application/json" },
            credentials: "same-origin",
            body: ccForm
        };

        try {
            const logoResponse = await fetch(`${process.env.REACT_APP_API_URL}/logo`, options);
            const logoResult = await logoResponse.json();

            await fetch(`${process.env.REACT_APP_API_URL}/admins/${params.id_candidatura}/photo`, {
                body: JSON.stringify({
                    photo: logoResult,
                }),
                method: "PATCH",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem('admin2')
                }
            });
            setAlterar(false);
            toastSuccess('Foto adicionada com sucesso!');
            setCont(cont + 1)

        } catch (error) {
            console.error('Error uploading image:', error);
            setLoading(false); 
        }
        await setLoading(false);
    };

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin2 currentPage={"myProfile"} />
                </div>
                <div className="flex-grow w-full mt-8 mb-10">
                    <Link to="/MenuSuperAdmin" className="back-button-div-noNavbar">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>
                    <div className="w-full">
                        <div className="mx-10">
                            <div className="flex flex-col items-center justify-center w-full">
                                <div className="relative transition cursor-pointer group hover:opacity-80">
                                    <img alt="Profile" className="mx-auto max-h-40 rounded-3xl sm:m-0" src={data.photo === undefined || data.photo === "" ? 'https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg' : data.photo} height="120px" onClick={() => { document.getElementById('fileInput').click(); }} />
                                    <div className="absolute -translate-x-1/2 -translate-y-1/2 opacity-0 top-1/2 left-1/2 group-hover:opacity-100">
                                        <BsPencilFill className="text-black" />
                                    </div>
                                    <input id="fileInput" type="file" accept=".png, .jpeg, .jpg" style={{ display: 'none' }} onChange={handleFileUpload} />
                                </div>
                                <div className="flex flex-col items-center mt-2">
                                    {alterar === true ?
                                        <input id="name" name="name" className="input-field-long" placeholder={data.fullName} defaultValue={data.fullName} required /> :
                                        <h1 className="text-2xl font-bold text-text-main-color">{data.fullName}</h1>
                                    }
                                    <h1 className="third-title">Super Administrador</h1>
                                </div>
                            </div>
                            <div className="my-6">
                                <hr />
                            </div>
                            <div className="mb-5 sm:ml-24">
                                <h2 className="text-3xl font-bold text-center text-text-main-color sm:text-left">Dados Pessoais</h2>
                                <div className="grid mt-4 sm:grid-cols-2 gap-x-48">
                                    <div>
                                        <div className="text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Número de telefone:</div>
                                            <div className="mt-1 text-lg text-text-main-color">
                                                {alterar === true ? <input id="cellNumber" name="cellNumber" className="input-field-long" placeholder={data.cellNumber} minLength={9} maxLength={14} defaultValue={data.cellNumber} required></input> : data.cellNumber}
                                            </div>
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">E-mail:</div>
                                            <div className="mt-1 text-lg text-text-main-color">
                                                {alterar === true ? <input id="email" name="email" className="input-field-long" placeholder={data.email} defaultValue={data.email} required></input> : data.email}
                                            </div>
                                        </div>
                                        <div className="mt-4 text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">NIF:</div>
                                            <div className="mt-1 text-lg text-text-main-color">
                                                {alterar === true ? <input id="nif" name="nif" className="input-field-long" placeholder={data.nif} minLength={9} maxLength={9} defaultValue={data.nif}></input> : data.nif}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="text-center sm:text-left">
                                            <div className="text-xs uppercase text-text-second-color opacity-90">Localidade:</div>
                                            {alterar === true ?
                                                <div className="mt-1 text-lg text-text-main-color">
                                                    <input id="locality" name="locality" className="input-field-long" placeholder={data.locality} defaultValue={data.locality} required>
                                                    </input>
                                                </div>
                                                : <>{data.locality
                                                    ? <div className="mt-1 text-lg text-text-main-color">  {data.locality}</div>
                                                    : <div className="input-error-profile" />}</>}
                                        </div>
                                        <div className="flex justify-center mt-3 sm:justify-normal">
                                            <button className="button-geral ">
                                                <Link to={"/MenuSuperAdmin/Profile/" + params.id_candidatura + "/changepassword"}>Alterar Palavra-passe</Link>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center justify-center mt-4 sm:items-start sm:justify-normal sm:flex-row row">
                                    {alterar === true ? <button className="sm:mr-5 button-cancelar w-36" onClick={() => { setAlterar(false); }}>Cancelar</button> : ""}
                                    {alterar === true ?
                                        <ConfirmToaster css={"button-confirmar mt-4 sm:mt-0 w-36 hover:brightness-75 tooltip"} icon={"Confirmar"} title={"Alterar Perfil"} message={"Deseja alterar o seu perfil ?"} confirm={alterarPerfil} tooltip={"Alterar Perfil"}></ConfirmToaster> :
                                        <button className="w-36 button-geral" onClick={alterarConfirmar}>Alterar Dados</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main >
        </>
    );

}



