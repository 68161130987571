import Header from "../../../components/Header";
import React, { useState, useEffect } from "react";
import Footer from '../../../components/Footer'
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-modal'
import toastSuccess from "../../../components/Toast/toastSuccess";
import toastError from "../../../components/Toast/toastError";
import ReactModal from "react-modal";
import onlyString from "../../../components/FormChecks/onlyString/onlyString";
import { toast } from "react-toastify";
import check_nif_number from "../../../components/FormChecks/checkNif/checkNIf";
import NavbarAdmin2 from "../../../components/Navbars/NavbarAdmin2/NavbarAdmin2";
import isNumber from "../../../components/isNumber/isNumber";
import { AiOutlineCloseCircle } from "react-icons/ai";
import CapitalizarNomes from "../../../components/capitalizeNames/capitalizeNames";
ReactModal.setAppElement('#root');

function ShowCoordinators() {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    //Dados guardados
    // Secção - 1 - Dados Informartivos
    const [fullName, setFullName] = useState("")
    const [locality, setLocality] = useState("")
    const [cellNumber, setCellNumber] = useState("")
    const [email, setEmail] = useState("")
    const [nomeTipo, setNomeTipo] = useState("")
    const [nif, setNif] = useState("")
    const [tipo, setTipo] = useState("")
    const navigate = useNavigate()
    useEffect(() => {
        return () => {
            toast.dismiss();
        }
    }, [loading])


    //Higienização e Continuação
    const handleNext = () => {
        if (fullName !== "" && cellNumber !== "" && (tipo !== "" || (nomeTipo === "" && tipo === "1")) && email !== "" && nif !== "" && locality !== "") {
            if (onlyString(fullName)) {

                fetch(`${process.env.REACT_APP_API_URL}/admins/emails/${email}`)
                    .then((res) => res.json())
                    .then(
                        (result) => {
                            // Verificar se o email tem "@" e o "."
                            let checkEmail = email.includes("@" && ".")
                            if (checkEmail === true && result === true) {
                                if (isNumber(cellNumber)) {
                                    if (cellNumber.length < 13 && cellNumber.length >= 9) {
                                        if (check_nif_number(nif) === true) {
                                            setModalIsOpen(true);

                                        } else toastError("Número de nif inválido.")
                                    } else toastError("Nº de telemóvel inválido  ou com caracteres inválidos.")
                                } else toastError("Nº de telemóvel inválido  ou com caracteres inválidos.")
                            } else toastError("Email incorreto ou Email existente.")
                        },
                        (error) => {
                            console.error("Error fetching data: ", error);
                            setError(error);
                        }
                    );
            } else toastError("Nome inválido.");
        } else toastError("Preencha todos os dados obrigatórios.")

    }

    //transforma data em json
    function formDataToJson(formData) {
        var object = {};
        formData.forEach((value, key) => object[key] = value);
        return JSON.stringify(object);
    }


    //Submete candidatura
    async function submeterCandidatura() {
        await setLoading(true)
        let form = document.getElementById("main-form")
        let formDataInfo = new FormData()

        let names = fullName.split(" ");
        let firstWord = names[0];
        let lastWord = names[names.length - 1];
        let salt = Math.random().toString(36).slice(2, 6);
        let password = firstWord + lastWord + salt;

        // Secção - 1
        formDataInfo.append('fullName', CapitalizarNomes(fullName))
        formDataInfo.append('cellNumber', cellNumber)
        formDataInfo.append('email', email)
        formDataInfo.append('locality', locality)
        formDataInfo.append('nif', nif)
        formDataInfo.append('password', password)
        formDataInfo.append('role', tipo)
        formDataInfo.append('nomeRole', nomeTipo === "Super Administrador" ? "" : nomeTipo)

        fetch(`${process.env.REACT_APP_API_URL}/admins`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'authorization': localStorage.getItem('admin2') },
                body: formDataToJson(formDataInfo)
            })
            .then(res => res.json())
            .then(
                (result) => {
                    toastSuccess("Administrador inserido com sucesso")
                    navigate(`/`)
                },
                (error) => {
                    console.error(error)
                }
            )
        //Submeter dados do candidato
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );

    if (error) return "Error!";
    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin2 currentPage={"addAdmin"} />
                </div>
                <Link to="/MenuSuperAdmin" className="back-button-div-noNavbar">
                    <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                </Link>
                <div className="flex-grow mt-8">
                    <div className="mx-4 mt-8 title sm:mt-0">CRIAR ADMINISTRADOR/A</div>
                    <form className="container-form w-[600px]" id="main-form" encType="multipart/form-data">
                        <section>
                            <div>
                                <label className="label-input" htmlFor="fullName">Nome Completo:*</label>
                                <input className="w-full input-field2" type="text" id="fullName" name="fullName" value={fullName} onChange={(e) => { setFullName(e.target.value) }}></input>
                            </div>
                            <div>
                                <label className="label-input" htmlFor="locality">Localidade:*</label>
                                <input className="w-full input-field2" type="text" id="locality" name="locality" value={locality} onChange={(e) => { setLocality(e.target.value) }}></input>
                            </div>
                            <div>
                                <label className="label-input" htmlFor="email">E-mail:*</label>
                                <input className="w-full input-field2" type="email" id="email" name="email" value={email} onChange={(e) => { setEmail(e.target.value) }}></input>
                            </div>
                            <div>
                                <label className="label-input" htmlFor="cellNumber">Nº de telemóvel:*</label>
                                <input className="w-full input-field2" type="number" id="cellNumber" name="cellNumber" minLength={9} maxLength={13} value={cellNumber} onChange={(e) => { setCellNumber(e.target.value) }} />
                            </div>
                            <div>
                                <label className="label-input" htmlFor="nif">Número de Contribuinte*</label>
                                <input className="w-full input-field2" type="text" id="nif" name="nif" minLength={9} maxLength={9} value={nif} onChange={(e) => { setNif(e.target.value) }}></input>
                            </div>
                            <div className={"" + (tipo === 2 && "flex justify-between")}>
                                <div>
                                    <label className="label-input" htmlFor="tipo">Permissões:*</label>
                                    <select onWheel={(e) => e.target.blur()} className="selector2" id="tipo" value={tipo} onChange={(e) => { setTipo(e.target.value) }} >
                                        <option disabled value="">Selecione uma das opções</option>
                                        <option value="1">Permissões limitadas</option>
                                        <option value="2">Permissões totais</option>
                                    </select>
                                </div>

                                {tipo && tipo === "2" &&
                                    <div>
                                        <label className="label-input" htmlFor="tipo">Tipo de Administrador:*</label>
                                        <select onWheel={(e) => e.target.blur()} className="selector2" id="tipo" value={nomeTipo} onChange={(e) => { setNomeTipo(e.target.value) }}  >
                                            <option disabled value="">Selecione uma das opções</option>
                                            <option value="Super Administrador">Super Administrador</option>
                                            <option value="Administrador - Geral">Administrador - Geral</option>
                                        </select>
                                    </div>}
                                {tipo && tipo === "1" &&
                                    <div>
                                        <label className="label-input" htmlFor="tipo">Tipo de Administrador:*</label>
                                        <select onWheel={(e) => e.target.blur()} className="selector2" id="tipo" value={nomeTipo} onChange={(e) => { setNomeTipo(e.target.value) }}  >
                                            <option disabled value="">Selecione uma das opções</option>
                                            <option value="Administrador - Geral">Administrador - Geral</option>
                                            <option value="Administrador - Diretor de Departamento">Administrador - Diretor de Departamento</option>
                                            <option value="Administrador - Gestor de Operação">Administrador - Gestor de Operação</option> 
                                            <option value="FA">Formação - Empresas</option> 
                                            <option value="Gestor Financeiro">Gestor Financeiro</option>
                                            <option value="Marketing">Administrador - Marketing</option> 
                                            <option value="Entidade de Ligação">Entidade de Ligação</option> 
                                            <option value="Técnico Administrativo">Técnico Administrativo</option>
                                            <option value="TORVC">TORVC</option>
                                            <option value="Outro">Outro</option>    
                                        </select>
                                    </div>
                                }
                            </div>
                            <button className="my-4 button-geral w-28" onClick={handleNext} type="button" >Submeter</button>
                        </section>

                        <Modal isOpen={modalIsOpen} className="fixed inset-0 flex items-center justify-center">
                            <div className="absolute w-full h-full" onClick={() => setModalIsOpen(false)}></div>
                            <div className="relative flex flex-col items-center px-10 shadow-2xl bg-secundary-color py-7 rounded-2xl">
                                <h1 className="title">Dados do Formulário</h1>
                                <div>
                                    <p className="p-1 text-text-second-color"><strong>Nome Completo:</strong> {fullName}</p>
                                    <p className="p-1 text-text-second-color"><strong>Número de telemóvel:</strong> {cellNumber}</p>
                                    <p className="p-1 text-text-second-color"><strong>Localidade:</strong> {locality}</p>
                                    <p className="p-1 text-text-second-color"><strong>Email:</strong> {email}</p>
                                    <p className="p-1 text-text-second-color"><strong>Número Contribuinte:</strong> {nif}</p>
                                    <p className="p-1 text-text-second-color"><strong>Cargo:</strong> {nomeTipo}</p>
                                </div>
                                <div className="flex flex-row justify-center w-full mt-5">
                                    <button className="w-24 button-confirmar" onClick={submeterCandidatura}>Confirmar</button>
                                </div>
                                <button className="absolute top-1.5 right-1.5" onClick={() => setModalIsOpen(false)}>
                                    <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                                </button>
                            </div>

                        </Modal>
                        <div className="ml-4">
                            <h3 className="m-1 text-main-color">Legenda</h3>
                            <p className="m-1 text-xs text-text-second-color">Todos os campos identificados com * são obrigatórios.</p>
                            <p className="m-1 text-xs text-text-second-color">Os dados pessoais serão facultados às Entidades Reguladoras e/ou Financiadoras das Ações de Formação.</p>

                        </div>
                    </form>
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main>

        </>
    );
}

export default ShowCoordinators;