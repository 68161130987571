import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from 'react-router-dom'
import Header from "../../../../../components/Header";
import Footer from "../../../../../components/Footer";
import ExcelGenerator from "../../../../../components/Excel/cronogramaDisciplina/index";
import toastSuccess from "../../../../../components/Toast/toastSuccess";
import toastError from "../../../../../components/Toast/toastError";
import Modal from 'react-modal'
import NavbarCoordenador from "../../../../../components/Navbars/NavbarCoordenador/navbarCoordenador";
import { FiDownload } from "react-icons/fi";
import Cookies from "js-cookie";
import { FaEye } from "react-icons/fa";
import AlterarFormato from "../../../../../components/FormChecks/formatDates/foramatDates";
Modal.setAppElement('#root');

function ShowUfcd() {
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);
    const [formadoresList, setFormadoresList] = useState(0)
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [cont, setCont] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [ufcdsGlobais, setUfcdsGlobais] = useState(null);
    const [itemSelecionado, setitemSelecionado] = useState(null);

    let id = 0
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getUfcds()
        getFormadores()
        getUfcdsGlobais()
    }, [loading, cont]);


    function getUfcds() {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/${params.id_ufcd}`, {
            headers: {
                'authorization': localStorage.getItem("coord")
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {

                    setData(result);
                    setData2(result.formandos.sort((a, b) => {
                        return a.fullName.localeCompare(b.fullName);
                    }));

                    if (!result.dateBegin && !result.dateEnd || result.dateBegin !== result.percurso[0].dateBegin && result.dateEnd !== result.percurso[0].dateEnd) {
                        fetch(`${process.env.REACT_APP_API_URL}/ufcds/percurso/pra/${result._id}`, {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'authorization': localStorage.getItem("coord")
                            },
                            method: 'PATCH',
                            body: JSON.stringify({
                                dateBegin: result.percurso[0].dateBegin,
                                dateEnd: result.percurso[0].dateEnd
                            })
                        })
                            .then((res) => res.json())
                            .then(
                                (result) => {
                                    setLoading(false);
                                },
                                (error) => {
                                    console.error("Error fetching data: ", error);
                                }
                            );
                    }
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                }
            );
    }

    //Obter formadores validados
    function getFormadores() {
        fetch(`${process.env.REACT_APP_API_URL}/formadores/validados`, {
            headers: {
                'authorization': localStorage.getItem("coord")
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setFormadoresList(result)
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                }
            );
    }

    function postDateBegin(id, dBegin, dEnd, pDBegin, pDEnd) {
        if (dBegin) {
            if (dBegin >= pDBegin && dBegin <= pDEnd) {
                fetch(`${process.env.REACT_APP_API_URL}/ufcds/dateBegin/${id}`,
                    {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            "authorization": localStorage.getItem("coord")
                        },
                        body: JSON.stringify({
                            dateBegin: dBegin
                        })
                    }).then((result) => {
                        result.json().then((resp) => {

                            setCont(cont + 1)
                        })
                    })
                toastSuccess("Data de início inserida com sucesso")
            } else toastError("A data de início deve estar dentro do tempo do Curso")
        } else toastError("O campo não pode estar vazio")
    };

    function postDateEnd(id, dBegin, dEnd, pDBegin, pDEnd) {
        if (dEnd) {
            if (dEnd <= pDEnd) {
                if (dBegin) {
                    if (dBegin <= dEnd) {
                        fetch(`${process.env.REACT_APP_API_URL}/ufcds/dateEnd/${id}`,
                            {
                                method: "POST",
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    "authorization": localStorage.getItem("coord")
                                },
                                body: JSON.stringify({
                                    dateEnd: dEnd
                                })
                            }).then((result) => {
                                result.json().then((resp) => {
                                    toastSuccess("Data de fim inserida com sucesso")
                                    setCont(cont + 1)
                                })
                            })
                    } else toastError("A data de fim tem de ser superior à data de início")
                } else toastError("Insira primeiro a data de início")
            } else toastError("A data de fim não pode ultrapassar a data do Curso")
        } else toastError("O campo não pode estar vazio")
    };


    //Obtem todas as ufcds globais
    function getUfcdsGlobais() {
        fetch(`${process.env.REACT_APP_API_URL}/ufcdsGlobal`, {
            headers: {
                "authorization": localStorage.getItem("coord")
            }
        })
            .then(response => response.json())
            .then(result => {
                setUfcdsGlobais(result)
            })
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    return (
        <>
            <main className="min-h-screen grid-cols-7 mx-auto md:grid xl:grid-cols-10">
                <div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    <NavbarCoordenador currentPage={"cursos"} />
                </div>

                <div className="relative w-full h-full col-span-5 xl:col-span-8">
                    <div className="back-button-noNavbar">
                        <Link to={'/menucoordenador/' + params.id_candidatura + "/cursos/" + params.id_curso} className="back-button-link">
                            <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                        </Link>
                    </div>
                    <Header sideMenu={"true"} />
                    <h1 className="title">Gestão de Pra</h1>
                    <h1 className="mt-4 subtitle">Dados gerais</h1>
                    {
                        data &&
                        <div className="justify-center gap-5 mt-2 font-medium sm:flex text-text-second-color">
                            <div className="px-1">
                                <p>Data Inicio</p>
                                {data.dateBegin ? (
                                    <div className="mt-1 text-lg text-text-main-color">
                                        {AlterarFormato(data.dateBegin)}
                                    </div>
                                ) : (
                                    <div>
                                        <input className="input-field" id={"dateBegin"} type="date" />
                                        <button className="mt-2 button-geral" onClick={() => postDateBegin(data._id, document.getElementById("dateBegin").value, document.getElementById("dateEnd").value, data.percurso[0].dateBegin, data.percurso[0].dateEnd)}>Confirmar</button>
                                    </div>
                                )}
                            </div>
                            <div className="px-1">
                                <p>Data Fim</p>
                                {data.dateEnd ? (
                                    <div className="mt-1 text-lg text-text-main-color">
                                        {AlterarFormato(data.dateEnd)}
                                    </div>
                                ) : (
                                    <div>
                                        <input className="input-field" id={"dateEnd"} type="date" />
                                        <button className="mt-2 button-geral" onClick={() => postDateEnd(data._id, data.dateBegin, document.getElementById("dateEnd").value, data.percurso[0].dateBegin, data.percurso[0].dateEnd)}>Confirmar</button>
                                    </div>
                                )}
                            </div>
                            <div className="px-1">
                                <p>Duração</p>
                                <div className="mt-1 text-lg text-text-main-color">
                                    {data.cargaHoraria}
                                </div>
                            </div>
                            <div className="px-1">
                                <p>Fecho Coordenador</p>
                                {data.fechoCoordenador === true ?
                                    <div className="mt-1 text-lg text-text-main-color">Sim</div> :
                                    <div className="mt-1 text-lg text-text-main-color">Não</div>
                                }
                            </div>
                        </div>
                    }
                    <h1 className="mt-4 subtitle">Turma</h1>
                    {data ? <> <div className="table-containerSideMenu">
                        <table className="my-table">
                            <thead className="bg-main-color">
                                <tr className="text-white h-14">
                                    <th className="p-2 min-w-[80px] border border-white pl-2">Número:</th>
                                    <th className="p-2 min-w-[150px] border border-white">Nome:</th>
                                </tr>
                            </thead>
                            {data ? (
                                <tbody className="text-text-main-color">
                                    {data2.map((item, index) => (
                                        <tr key={index} className="row-click">
                                            <td className="border border-white">{index + 1}</td>
                                            <td className="border border-white">{item.fullName}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            ) : (
                                <tbody></tbody>
                            )}
                        </table>
                    </div> </> : <p className="third-title">Não existem dados</p>}
                </div>
            </main>
            <Footer />
        </>
    );
}

export default ShowUfcd;
