export default function CapitalizarNomes(nomeCompleto) {
    const palavrasIgnoradas = ['de', 'da', 'do', 'e', 'o', 'a'];
    const palavras = nomeCompleto.split(' ');

    const nomeCapitalizado = palavras.map((palavra, index) => {
        if (!palavrasIgnoradas.includes(palavra.toLowerCase())) {
            return palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase();
        } else {
            return palavra.toLowerCase();
        }
    });
    return nomeCapitalizado.join(' ');

}


