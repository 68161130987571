import styled from "styled-components";

export const UFCDs = styled.div`

justify-content: center;
font-family: "Lato";
display: flex;
margin-top: 14vh;


.table-container{   
    min-width: 600px;
    overflow-x: scroll;
    background-color: white;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 5px 5px 20px #00000022;   
}    

.row-click{
    cursor: pointer;
}
.row-click:hover{
    background-color: rgba(0, 0, 0, 0.15);
}

h1{
    text-align: center;
    
}

table{
    padding:20px;
    display: block;
    background-color: white;
    text-align: center;
    max-width: 80vw;
    max-height: 500px;
    overflow:auto;
}

table thead th {

    padding: 12px 39px;
    background-color: rgb(71 71 71);
    color: white;
}

.adicionar-table{
    background-color: white;
    padding: 10px 20px 10px 20px;
    display: block;
    max-width: 80vw;
    margin-bottom: 50px;
    min-height:90px;
}

.adicionar-table td{
    width: 200px;
}

.adicionar-table tr{
    height: 60px;
    background-color: #eeeeee;
}

.table td{
    width: 200px;
}

table tr{
    height: 50px;
}

    table .remove-ufcd{
        border-radius: 50px;
        width: 30px;
        height: 30px;
        border: none;
        background-color: #ff000088;
        cursor: pointer;
        color: white;
        transition: all 0.25s ease;
    }

    table .remove-ufcd:hover{
        background-color: #ff0000cc;
        width: 35px;
        height: 35px;
    }

    table tr:nth-child(odd)
    {
        background-color: #00000011;
    }

    #reg-form{
        width: 50%;
        background-color: white;
        padding: 30px;
        border-radius: 12px;
        text-align: center;
    }

    .search{    
        margin: 10px;
    }


.back-button-link{
    top: 60px;
}
`;
