import React from "react";
import { Link, useParams } from 'react-router-dom'
import { NavbarFormandoStyle } from './styled'
import { AiOutlineHome } from "react-icons/ai";
import { BsBookmark } from "react-icons/bs";
import { AiOutlineFile } from "react-icons/ai";
import { MdGroups2 } from "react-icons/md";
import { GrHelp } from "react-icons/gr";

function handleLogout() {
    var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }
    localStorage.removeItem('formando');
    localStorage.removeItem('id');
    window.location.replace('/')
}

//Componente navbar
function NavbarFormando({currentPage}) {
    const params = useParams();
    return (
        <NavbarFormandoStyle>
            <div className="container-navbar">
                <Link to={"/menuformando/" + params.id_formando} className={`link ${currentPage === "home" ? "current" : ""}`}>
                    <AiOutlineHome />
                    <span>Início</span>
                </Link>
                <Link to={"/menuformando/" + params.id_formando + "/avaliacao"} className={`link ${currentPage === "disciplinas" ? "current" : ""}`}>
                    <BsBookmark />
                    <span>Disciplinas</span>
                </Link>
                <Link to={"/menuformando/" + params.id_formando + "/horario"} className={`link ${currentPage === "horario" ? "current" : ""}`}>
                    <i className="fas fa-clock"></i>
                    <span>Horário</span>
                </Link>
                <Link className={`link ${currentPage === "ficheiros" ? "current" : ""}`}>
                    <AiOutlineFile />
                    <span>Ficheiros</span>
                </Link>
                <Link to={"/menuformando/" + params.id_formando + "/profile"} className={`link ${currentPage === "perfil" ? "current" : ""}`}>
                    <i className="fas fa-user"></i>
                    <span>Perfil</span>
                </Link>
                <Link className={`link ${currentPage === "grupos" ? "current" : ""}`}>
                    <MdGroups2 />
                    <span>Grupos</span>
                </Link>
                <Link className={`link ${currentPage === "ajuda" ? "current" : ""}`}>
                    <GrHelp />
                    <span>Ajuda e Suporte</span>
                </Link>

                <button className='logout-button' onClick={handleLogout}>
                    <img className='logout-icon' src={process.env.PUBLIC_URL + "/img/logout.png"} alt="Logout" />
                    Logout
                </button>
            </div>
        </NavbarFormandoStyle>
    )
}

export default NavbarFormando