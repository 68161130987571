import styled from "styled-components";

export const AreaHeader = styled.div`
    .container{
        box-sizing: border-box;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logo{
        margin: auto;
        height: 50px;
        display: block;
    }
`;
