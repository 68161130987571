import React, { useState, useEffect } from "react";
import { AreaFormando } from "./styled";
import { useParams } from 'react-router-dom';
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import toastSuccess from "../../../../components/Toast/toastSuccess";
import toastError from "../../../../components/Toast/toastError";
import onlyString from "../../../../components/FormChecks/onlyString/onlyString";
import NavbarFormando from "../../../../components/Navbars/NavbarFormando/navbarFormando";
import ConfirmToaster from "../../../../components/Toast/toastConfirmChoice";

export default function PerfilFormando() {
    const [data, setData] = useState(null);
    const [data2, setData2] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [alterar, setAlterar] = useState(false);

    document.data = data

    const params = useParams()

    useEffect(() => {
        getFormandos()
    }, []);


    function alterarConfirmar() {
        setAlterar(true)
    }

    //Recebe um formando pelo seu id
    //Obtem formandos
    function getFormandos() {
        fetch(`${process.env.REACT_APP_API_URL}/formandos/${params.id_formando}`, {
            headers: {
                'authorization': localStorage.getItem('formando')
            }
        })

            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    getCandidaturasFormandos()
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    }

    function getCandidaturasFormandos() {
        fetch(`${process.env.REACT_APP_API_URL}/formandos`, {
            headers: {
                'authorization': localStorage.getItem('formando')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData2(result);
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    }

    //Altera os campos na bd do formando pelo id
    function alterarPerfil() {
        function verifyCellFone(str) {
            return Boolean(str.match(/[+]?[0-9]{3}?[0-9]{9}/));
        }
        if (document.getElementById('cellNumber').value !== "" && document.getElementById('email').value !== "" && document.getElementById('address').value !== "" && document.getElementById('locality').value !== "" && document.getElementById('postalCode').value !== "" && document.getElementById('county').value !== "") {
            if (verifyCellFone(document.getElementById('cellNumber').value)) {
                let cont = 0
                function verifyEmail() {
                    for (var i = 0; i < data2.length; i++) {
                        if (data2[i].email === document.getElementById('email').value) {
                            if (document.getElementById('email').defaultValue === document.getElementById('email').value);
                            else cont++;
                        }
                    }
                }
                verifyEmail()

                // Verificar se o email tem "@" e o "."
                let checkEmail = document.getElementById('email').value.includes("@" && ".")
                if (checkEmail === true && cont === 0) {
                    function containsPostalCode(str) {
                        return Boolean(str.match(/[0-9]{4}[\-]?[0-9]{3}/));
                    }
                    if (onlyString(document.getElementById('locality').value)) {
                        if (containsPostalCode(document.getElementById('postalCode').value)) {
                            if (onlyString(document.getElementById('county').value)) {
                                let a = document.getElementById('cellNumber').value
                                let b = document.getElementById('email').value
                                let address = document.getElementById('address').value
                                let locality = document.getElementById('locality').value
                                let postalCode = document.getElementById('postalCode').value
                                let county = document.getElementById('county').value
                                fetch(`${process.env.REACT_APP_API_URL}/formandos/${params.id_formando}/alterarPerfil`,
                                    {
                                        method: "PUT",
                                        headers: {
                                            'Accept': 'application/json',
                                            'Content-Type': 'application/json',
                                            'authorization': localStorage.getItem('formando')
                                        },
                                        body: JSON.stringify({
                                            cellNumber: document.getElementById('cellNumber').value,
                                            email: document.getElementById('email').value,
                                            address: document.getElementById('address').value,
                                            locality: document.getElementById('locality').value,
                                            postalCode: document.getElementById('postalCode').value,
                                            county: document.getElementById('county').value,
                                        }),


                                    }).then((result) => {
                                        result.json().then((resp) => {
                                            setAlterar(false);
                                        })
                                        toastSuccess('Dados alterados com sucesso!')
                                        getFormandos()
                                    })
                            } else toastError("Concelho Inválido")
                        } else toastError("Código Postal Inválido")
                    } else toastError("Localidade inválida")
                } else toastError("Email Inválido ou Existente")
            } else toastError("Numero Inválido")
        } else toastError("Dados não inseridos")
    }

    return (
        <>
            <Header />
            <AreaFormando>
                <main>
                    <div className="page">

                        <div className="navbar">
                            <NavbarFormando currentPage={"perfil"} />
                        </div>

                        <div className="content">

                            <div className="dadosCandidato">
                                {data ? <div>
                                    <div className="dado"><div>Nome Completo: </div> <div> {data.fullName}</div></div>
                                    <div className="dado"><div>Data de nascimento: </div> <div> {data.birthDate}</div></div>
                                    <div className="dado"><div>Número de telefone: </div><div>{alterar === true ? <input id="cellNumber" name="cellNumber" placeholder={data.cellNumber} minLength={9} maxLength={14} defaultValue={data.cellNumber} required></input> : data.cellNumber}</div></div>
                                    <div className="dado"><div>E-mail:</div> <div>{alterar === true ? <input id="email" name="email" placeholder={data.email} defaultValue={data.email} required></input> : data.email}</div></div>
                                    <div className="dado"><div>Morada:</div> <div>{alterar === true ? <input id="address" name="address" placeholder={data.address} defaultValue={data.address} required></input> : data.address}</div></div>
                                    <div className="dado"><div>Localidade: </div><div>{alterar === true ? <input id="locality" name="locality" placeholder={data.locality} defaultValue={data.locality} required></input> : data.locality}</div></div>
                                    <div className="dado"><div>Código Postal: </div><div>{alterar === true ? <input id="postalCode" name="postalCode" placeholder={data.postalCode} defaultValue={data.postalCode} required></input> : data.postalCode}</div></div>
                                    <div className="dado"><div>Concelho: </div><div>{alterar === true ? <input id="county" name="county" placeholder={data.county} defaultValue={data.county} required></input> : data.county}</div></div>
                                </div> : <div></div>}

                                <div>{alterar === true ? <ConfirmToaster css={"button-confirmar"} icon={"Confirmar"} title={"Confirmar"} message={"Deseja confirmar as alterações?"} confirm={alterarPerfil} tooltip={"Alterar Perfil"}></ConfirmToaster> : <button className="button-alterar" onClick={alterarConfirmar}>Alterar</button>}
                                    {alterar === true && <button className="button-cancelar" onClick={() => { setAlterar(false) }}>Cancelar</button>}</div>
                            </div>
                        </div>
                    </div>
                </main>
            </AreaFormando>
            <Footer />
        </>
    );
}