import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import NavbarAdmin1 from "../../Navbars/NavbarAdmin1/NavbarAdmin1";
import NavbarAdmin2 from "../../Navbars/NavbarAdmin2/NavbarAdmin2";
import NavbarCoordenador from "../../Navbars/NavbarCoordenador/navbarCoordenador";
import Header from "../../Header";
import NavbarFormador from "../../Navbars/NavbarFormador/navbarFormador";
import toastSuccess from "../../Toast/toastSuccess";

export default function FormAvaliacao({ token }) {
  const [data, setData] = useState("");
  const [cont, setCont] = useState(0);
  const [valor1, setValor1] = useState("");
  const [valor2, setValor2] = useState("");
  const [valor3, setValor3] = useState("");
  const [valor4, setValor4] = useState("");
  const [valor5, setValor5] = useState("");
  const [valor6, setValor6] = useState("");
  const [valor7, setValor7] = useState("");
  const [valor8, setValor8] = useState("");
  const [valor9, setValor9] = useState("");
  const [outros, setOutros] = useState("");
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getUFCD();
  }, [cont]);

  /* 
        function handleClick() {
            navigate(-1);
        } */

  function avaliarFormadorUFCD() {
    fetch(
      `${process.env.REACT_APP_API_URL}/ufcds/${params.id_ufcd}/avaliacaoSatisfacaoCoordenador`,
      {
        method: "PATCH",
        body: JSON.stringify({
          criterio1: valor1,
          criterio2: valor2,
          criterio3: valor3,
          criterio4: valor4,
          criterio5: valor5,
          criterio6: valor6,
          criterio7: valor7,
          criterio8: valor8,
          sugestao: valor9,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: localStorage.getItem(token),
        },
      }
    ).then((result) => {
      result.json().then((resp) => {
        toastSuccess("Formulário submetido com sucesso");
      });
      /*                 handleClick(); */
    });
  }

  function getUFCD() {
    fetch(`${process.env.REACT_APP_API_URL}/ufcds/${params.id_ufcd}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
    }).then((res) => {
      res
        .json()
        .then((result) => {
          setData(result);
        })
        .catch((error) => {
          console.error("Error parsing JSON:", error);
        });
    });
  }

  const getOptionText = (option) => {
    switch (option) {
      case 1:
        return "Muito Insuficiente";
      case 2:
        return "Insuficiente";
      case 3:
        return "Suficiente";
      case 4:
        return "Bom";
      case 5:
        return "Muito Bom";
      default:
        return "";
    }
  };
  return (
    <>
      <main
        className={
          token === "coord"
            ? "md:grid sm:grid-cols-10 min-h-screen mx-auto"
            : "min-h-screen"
        }
      >
        {token !== "coord" && (
          <div className="w-full">
            {token === "admin2" && <NavbarAdmin2 currentPage={"gerirCursos"} />}
            {token === "admin1" && <NavbarAdmin1 currentPage={"gerirCursos"} />}
          </div>
        )}
        {token === "coord" && (
          <div className="h-full sm:col-span-2 md:bg-secundary-color">
            <NavbarCoordenador currentPage={"cursos"} />
          </div>
        )}

        {token === "coord" && (
          <div className="back-button-noNavbar">
            <button
              onClick={() => navigate(-1)}
              className="back-button-div-noNavbar"
            >
              <img
                className="back-button-img"
                alt="Back"
                src={process.env.PUBLIC_URL + "/img/back_arrow.svg"}
              />
            </button>
          </div>
        )}
        <div
          className={
            token === "coord" ? "sm:col-span-8 h-full" : "flex-grow mt-8"
          }
        >
          {token === "coord" && <Header />}
          {token !== "coord" && (
            <button
              onClick={() => navigate(-1)}
              className="back-button-div-noNavbar"
            >
              <img
                className="back-button-img"
                alt="Back"
                src={process.env.PUBLIC_URL + "/img/back_arrow.svg"}
              />
            </button>
          )}
          <div className="w-[80%] m-auto text-start text-[18px]">
            <div>
              <h1 className="title">
                Avaliação da Satisfação do Coordenador/a ou Mediador/a com a
                Formação
              </h1>
            </div>
            <div className="my-10 text-main-color">
              Pretendemos conhecer qual a sua satisfação sobre a forma como
              decorreu esta unidade de formação. Por favor, assinale a sua
              resposta em cada um dos itens abaixo mediante a seguinte escala de
              cotação.
            </div>
            <div className="flex flex-col gap-10">
              <h1 className="subtitle"> I - AVALIAÇÃO DO GRUPO DE FORMANDOS</h1>
              <div className="flex flex-col gap-10">
                <div>
                  <h4 className="third-title font-bold">
                    1. Adequação das caraterísticas do grupo quanto aos
                    objetivos do curso/qualificação a obter
                  </h4>
                  <div className="flex flex-row gap-10">
                    {[1, 2, 3, 4, 5].map((option) => (
                      <div className="flex items-center" key={option}>
                        <input
                          className="m-2"
                          type="checkbox"
                          checked={
                            (data &&
                              data.avaliacaoSatisfacaoCoordenador.length > 0 &&
                              data.avaliacaoSatisfacaoCoordenador[0]
                                .criterio1 === option &&
                              !valor1) ||
                            valor1 === option
                          }
                          onClick={() => {
                            token === "coord" && setValor1(option);
                          }}
                        />
                        <h1>
                          {option} - {getOptionText(option)}
                        </h1>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <h4 className="third-title font-bold">
                    2. Motivação dos membros do grupo para a certificação
                    final/qualificação
                  </h4>
                  <div className="flex flex-row gap-10">
                    {[1, 2, 3, 4, 5].map((option) => (
                      <div className="flex items-center" key={option}>
                        <input
                          className="m-2"
                          type="checkbox"
                          checked={
                            (data &&
                              data.avaliacaoSatisfacaoCoordenador.length > 0 &&
                              data.avaliacaoSatisfacaoCoordenador[0]
                                .criterio2 === option) ||
                            valor2 === option
                          }
                          onClick={() => {
                            token === "coord" && setValor2(option);
                          }}
                        />
                        <h1>
                          {option} - {getOptionText(option)}
                        </h1>
                      </div>
                    ))}
                  </div>
                </div>

                <div>
                  <h4 className="third-title font-bold">
                    3. Competências de relacionamento dos/as formandos/as na
                    ação (para com os colegas e com a Coordenação/Mediação)
                  </h4>
                  <div className="flex flex-row gap-10">
                    {[1, 2, 3, 4, 5].map((option) => (
                      <div className="flex items-center" key={option}>
                        <input
                          className="m-2"
                          type="checkbox"
                          checked={
                            (data &&
                              data.avaliacaoSatisfacaoCoordenador.length > 0 &&
                              data.avaliacaoSatisfacaoCoordenador[0]
                                .criterio3 === option) ||
                            valor3 === option
                          }
                          onClick={() => {
                            token === "coord" && setValor3(option);
                          }}
                        />
                        <h1>
                          {option} - {getOptionText(option)}
                        </h1>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <h4 className="third-title font-bold">
                    4. Resultados alcançados pelos formandos/as em termos de
                    aprendizagem
                  </h4>
                  <div className="flex flex-row gap-10">
                    {[1, 2, 3, 4, 5].map((option) => (
                      <div className="flex items-center" key={option}>
                        <input
                          className="m-2"
                          type="checkbox"
                          checked={
                            (data &&
                              data.avaliacaoSatisfacaoCoordenador.length > 0 &&
                              data.avaliacaoSatisfacaoCoordenador[0]
                                .criterio4 === option) ||
                            valor4 === option
                          }
                          onClick={() => {
                            token === "coord" && setValor4(option);
                          }}
                        />
                        <h1>
                          {option} - {getOptionText(option)}
                        </h1>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-10">
                <h1 className="subtitle">
                  II - AVALIAÇÃO DA ENTIDADE FORMADORA
                </h1>
                <div>
                  <h4 className="third-title font-bold">
                    5. Qualidade das instalações disponíveis{" "}
                    <p>
                      PRESENCIAL: Instalações físicas (insonorização,
                      luminosidade, temperatura, etc).
                    </p>{" "}
                    <p>ON-LINE: Qualidade da Plataforma LMS.</p>
                  </h4>
                  <div className="flex flex-row gap-10">
                    {[1, 2, 3, 4, 5].map((option) => (
                      <div className="flex items-center" key={option}>
                        <input
                          className="m-2"
                          type="checkbox"
                          checked={
                            (data &&
                              data.avaliacaoSatisfacaoCoordenador.length > 0 &&
                              data.avaliacaoSatisfacaoCoordenador[0]
                                .criterio5 === option) ||
                            valor5 === option
                          }
                          onClick={() => {
                            token === "coord" && setValor5(option);
                          }}
                        />
                        <h1>
                          {option} - {getOptionText(option)}
                        </h1>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <h4 className="third-title font-bold">
                    6. Qualidade dos meios audiovisuais e recursos ao dispor
                    <p>
                      PRESENCIAL: Equipamentos disponibilizados (Vídeo Projetor,
                      Quadros, Marcadores, etc).
                    </p>
                    <p>
                      ON-LINE: Funcionalidade da Plataforma LMS e da Plataforma
                      de videoconferência.
                    </p>
                  </h4>
                  <div className="flex flex-row gap-10">
                    {[1, 2, 3, 4, 5].map((option) => (
                      <div className="flex items-center" key={option}>
                        <input
                          className="m-2"
                          type="checkbox"
                          checked={
                            (data &&
                              data.avaliacaoSatisfacaoCoordenador.length > 0 &&
                              data.avaliacaoSatisfacaoCoordenador[0]
                                .criterio6 === option) ||
                            valor6 === option
                          }
                          onClick={() => {
                            token === "coord" && setValor6(option);
                          }}
                        />
                        <h1>
                          {option} - {getOptionText(option)}
                        </h1>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <h4 className="third-title font-bold">
                    7. Apoio/acompanhamento da Gestão da Operação
                  </h4>
                  <div className="flex flex-row gap-10">
                    {[1, 2, 3, 4, 5].map((option) => (
                      <div className="flex items-center" key={option}>
                        <input
                          className="m-2"
                          type="checkbox"
                          checked={
                            (data &&
                              data.avaliacaoSatisfacaoCoordenador.length > 0 &&
                              data.avaliacaoSatisfacaoCoordenador[0]
                                .criterio7 === option) ||
                            valor7 === option
                          }
                          onClick={() => {
                            token === "coord" && setValor7(option);
                          }}
                        />
                        <h1>
                          {option} - {getOptionText(option)}
                        </h1>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <h4 className="third-title font-bold">
                    8. Satisfação global com a entidade formadora
                  </h4>
                  <div className="flex flex-row gap-10">
                    {[1, 2, 3, 4, 5].map((option) => (
                      <div className="flex items-center" key={option}>
                        <input
                          className="m-2"
                          type="checkbox"
                          checked={
                            (data &&
                              data.avaliacaoSatisfacaoCoordenador.length > 0 &&
                              data.avaliacaoSatisfacaoCoordenador[0]
                                .criterio8 === option) ||
                            valor8 === option
                          }
                          onClick={() => {
                            token === "coord" && setValor8(option);
                          }}
                        />
                        <h1>
                          {option} - {getOptionText(option)}
                        </h1>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <h4 className="subtitle">SUGESTÕES/ CRÍTICAS/ COMENTÁRIOS</h4>
                <textarea
                  disabled={token === "coord" ? false : true}
                  defaultValue={
                    data &&
                    data.avaliacaoSatisfacaoCoordenador.length > 0 &&
                    data.avaliacaoSatisfacaoCoordenador[0].sugestao
                      ? data.avaliacaoSatisfacaoCoordenador[0].sugestao
                      : ""
                  }
                  onChange={(e) => setValor9(e.target.value)}
                  className="input-textarea h-40"
                ></textarea>
              </div>
              <div className="my-5 text-main-color">
                Obrigado pela sua colaboração
              </div>
              <div className="mb-10 mx-auto">
                {token === "coord" &&
                  data.avaliacaoSatisfacaoCoordenador &&
                  data.avaliacaoSatisfacaoCoordenador.length === 0 && (
                    <button
                      onClick={avaliarFormadorUFCD}
                      className="button-geral"
                    >
                      Submeter
                    </button>
                  )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
