import styled from "styled-components";

export const NavbarFormandoStyle = styled.div`

.container-navbar{
    box-shadow: 12px 0px 6px -4px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: #e4e4e4;
    padding: 0 3vw 0 2vw;
    border-radius:12px;
    height: 100vh;
}

.link{
    display: inline-flex;
    font-size: 20px;
    color: inherit;
    text-decoration: none;
    border-radius: 24px;
    padding: 25px 0 25px  15px;
    transition: all 0.2s ease;
}

.link:first-child{
margin-top: 15px;
}

.link:hover{
    transform: scale(1.1);
}

.current{
    background-color:#ccc;
    color: #009647;
}

span{
    margin-left:1vw;
}

`;