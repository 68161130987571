import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../../components/Footer";
import toastSuccess from "../../../components/Toast/toastSuccess";
import NavbarAdmin1 from "../../../components/Navbars/NavbarAdmin1/NavbarAdmin1";
import { BsFillSendFill } from "react-icons/bs";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { Tooltip } from "react-tooltip";
import Cookies from "js-cookie";
import ModalEnvioContrato from "../../../components/Modal/ModalEnvioContrato";
import CheckTypeAdmin from "../../../components/CheckTypeAdmin/CheckTypeAdmin";
import ExcelUFCDs from "../../../components/Excel/excelUFCDs/excelUFCDs";
import CheckTypeFinanceiro from "../../../components/CheckTypeAdmin/CheckTypeFinanceiro";
import AlterarFormato from "../../../components/FormChecks/formatDates/foramatDates";

function Candidaturas() {
    const [data, setData] = useState(null);
    const [admin, setAdmin] = useState(null);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const saveEntFormadora = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-Courses-F1") : "";
    const [listaEntFormadora, setListaEntFormadora] = useState([]);
    const saveOperacao = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-Courses-F2") : ""
    const [listaOperacao, setListaOperacao] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [valueEntFormadora, setValueEntFormadora] = useState(saveEntFormadora ? saveEntFormadora : "");
    const [valueOperacao, setValueOperacao] = useState(saveOperacao ? saveOperacao : "");
    const saveTerminados = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("SuperAdmin-Courses-F3") : "";
    const [valueTerminados, setValueTerminados] = useState(saveTerminados ? saveTerminados : false);
    const savedSearchTerm = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-Courses-1") : "";
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm ? savedSearchTerm : "");
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-Courses-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Admin-Courses-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)


    const params = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        Cookies.set("Admin-Courses-F1", valueEntFormadora);
        Cookies.set("Admin-Courses-F2", valueOperacao)
        Cookies.set("Admin-Courses-F3", valueTerminados)
        Cookies.set("Admin-Courses-Pag", paginaAtual)
        Cookies.set("Admin-Courses-1", searchTerm);
        Cookies.set(`Admin-Courses-itm1`, itemsPag)
        if (!admin) {
            getAdmin()
        }
        else {
            if (!data) {
                getPercursos()
            }
            else {
                paginacaoTabelas(filterData(data));
            }
        }


    }, [valueEntFormadora, valueOperacao, nPaginas, paginaAtual, searchTerm, itemsPag, loading, admin, valueTerminados]);
    useEffect(() => {
        getPercursos()
    }, [valueTerminados]);
    async function excelUfcds() {
        try {
            setLoading(true);
            await ExcelUFCDs("admin1", admin);
        } finally {
            setLoading(false);
        }
    }
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };

    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        data = data.filter((item) => {
            if (!valueOperacao) {
                return true; // Show all values when valueOperacao is empty
            } else {
                if (item.operacao[0]) {
                    return item.operacao[0]._id === valueOperacao;
                }
            }
        }).filter((item) => {
            if (!valueEntFormadora) {
                return true; // Show all values when valueOperacao is empty
            } else {
                if (item.entFormadora[0]) {
                    return item.entFormadora[0]._id === valueEntFormadora;
                }
            }
        });

        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }

        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }

        updatePagination(data) // n é o state por causa das tabelas
    }

    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }

    //filtra dados
    function filterData(data) {
        return data.filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.entFormadora[0] && item.entFormadora[0].name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.operacao[0] && item.operacao[0].codigoOperacao.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.locality.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.codeInterno.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.codeSIGO && item.codeSIGO.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
            item.modalidade.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }

    const handleClick = (event, item) => {
        // Check if the clicked element is a <td>
        if (event.target.tagName === 'TD') {
            // Check if the <td> has a 'clickable' class
            if (!event.target.classList.contains('item')) {
                // Navigate to the desired location
                // Replace 'destination' with your actual navigation logic
            }
            else navigate("/menuadmin/" + params.id_candidatura + "/cursos/" + item._id)
        }
    };
    //Obter percursos
    function getPercursos() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/getCursos`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem('admin1')
            },
        })
            .then(res => res.json())
            .then((result) => {
                const entidadesFormadoras = {};
                const operacoes = {};
                let allresults = []
                if (admin /* && (admin.nomeRole.includes("Administrador -") || admin.nomeRole === "Outros"|| admin.nomeRole === "Gestor Financeiro"|| admin.nomeRole === "Marketing") */) {
                    result.forEach(element => {
                        // Check if any entFormadora ID in element matches with admin's entFormadora
                        if (admin.entFormadora.some(entFormadora => element.entFormadora[0]._id === entFormadora._id)) {
                            allresults.push(element);
                        }
                    });
                }
                /*  else {
                     allresults = result
                 } */
                allresults.forEach(element => {
                    const entFormadora = element.entFormadora[0];
                    const operacao = element.operacao[0];

                    if (entFormadora && !entidadesFormadoras[entFormadora._id]) {
                        entidadesFormadoras[entFormadora._id] = {
                            entFormadora: entFormadora._id,
                            name: entFormadora.name,
                        };
                    }

                    if (operacao && !operacoes[operacao._id]) {
                        operacoes[operacao._id] = {
                            operacao: operacao._id,
                            codigoOperacao: operacao.codigoOperacao,
                        };
                    }
                });

                const dataFiltered = Object.values(entidadesFormadoras);
                const operacoesArray = Object.values(operacoes);

                let filterTerminados = allresults
                if (valueTerminados === false || valueTerminados === "false") {
                    filterTerminados = filterTerminados.filter(percursos => !percursos.fechoAdmin)
                }
                setData(filterTerminados);
                setListaOperacao(operacoesArray);
                setListaEntFormadora(dataFiltered);
                paginacaoTabelas(filterData(filterTerminados));
                setLoading(false);
            });
    }
    function getAdmin() {
        fetch(`${process.env.REACT_APP_API_URL}/admins/${params.id_candidatura}`, {
            headers: {
                'authorization': localStorage.getItem('admin1')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setAdmin(result);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    };
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }
    function addSixMonthsToDate(dateString) {
        const date = new Date(dateString);
        date.setMonth(date.getMonth() + 6);

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin1 currentPage={"gerirCursos"} />
                </div>
                <Link to={'/menuadmin/' + params.id_candidatura} className="back-button-div-noNavbar">
                    <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                </Link>
                <div className="flex-grow mt-8">
                    <h1 className="title">Lista de cursos/percursos</h1>
                    <div className="search-div">
                        <label className="search-input-label">Ver apenas:</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input type="text" className="search-input" placeholder="ex: Nome, Local ..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                        </div>
                    </div>
                    <div className="flex flex-col justify-center mt-6 mb-2 sm:flex-row">
                        {CheckTypeAdmin() === false && <div className="flex flex-col sm:mr-5">
                            <label className="text-text-second-color" htmlFor="templateCheck">Entidades Formadoras: </label>
                            {listaEntFormadora.length > 0 && <select onWheel={(e) => e.target.blur()} defaultValue={valueEntFormadora} className="mx-auto w-52 search-selector" name="sel" id="seletorEnt" onChange={(e) => setValueEntFormadora(e.target.value)}>
                                <option value="">Todas</option>
                                {listaEntFormadora.map((item) => (
                                    <option value={item.entFormadora} key={item.entFormadora}>{item.name}</option>
                                ))}
                            </select>}
                        </div>}
                        <div className="flex flex-col">
                            <label className="text-text-second-color" htmlFor="templateCheck">Operações: </label>
                            {listaOperacao.length > 0 && <select onWheel={(e) => e.target.blur()} defaultValue={valueOperacao} className="mx-auto w-52 search-selector" name="sel" id="seletorOp" onChange={(e) => setValueOperacao(e.target.value)}>
                                <option value="">Todas</option>
                                {listaOperacao.map((item) => (
                                    <option value={item.operacao} key={item.operacao}>{item.codigoOperacao}</option>
                                ))}
                            </select>}
                        </div>
                        <div className="flex flex-col">
                            <label className="text-text-second-color">Mostrar Terminados: </label>
                            <div className="m-auto">
                                <input
                                    defaultChecked={valueTerminados === "true" && true}
                                    type="checkbox"
                                    className="checkbox"
                                    onChange={(e) => { setValueTerminados(e.target.checked) }}
                                />
                            </div>
                        </div>
                    </div>
                    {dataToDisplay && dataToDisplay.length > 0 ?
                        <>
                            <div className="flex justify-center items-center mb-1">
                                <label className="text-text-main-color">Dados por página: </label>
                                <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                    value={itemsPag}
                                    onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div className="table-container">
                                <table className="my-table">
                                    <thead className="bg-main-color">
                                        <tr className="text-white h-14">
                                            <th className="p-2 min-w-[80px] border border-white">Operação</th>
                                            <th className="p-2 min-w-[80px] border border-white">Entidade Formadora</th>
                                            <th className="p-2 min-w-[150px] border border-white">Nome</th>
                                            <th className="p-2 min-w-[80px] border border-white">Código Interno</th>
                                            <th className="p-2 min-w-[80px] border border-white">Código SIGO</th>
                                            <th className="p-2 min-w-[80px] border border-white">Forma de Organização</th>
                                            <th className="p-2 min-w-[80px] border border-white">Modalidades</th>
                                            <th className="p-2 min-w-[80px] border border-white">Local</th>
                                            <th className="p-2 min-w-[80px] border border-white">Nº de Disciplinas</th>
                                            <th className="p-2 min-w-[80px] border border-white">Data de Inicio</th>
                                            <th className="p-2 min-w-[80px] border border-white">Data de Fim</th>
                                            <th className="p-2 min-w-[80px] border border-white">Avaliação Impacto</th>
                                            <th className="p-2 min-w-[80px] border border-white">Duração</th>
                                            <th className="p-2 min-w-[80px] border border-white">Nível</th>
                                            <th className="p-2 min-w-[150px] border border-white">Estado</th>
                                            <th className="p-2 min-w-[80px] border border-white">Coordenador Pedagógico</th>
                                            {CheckTypeAdmin() === true && <th className="p-2 min-w-[80px] border border-white">Avaliação do Coordenador</th>}
                                            {CheckTypeAdmin() === true && <th className="p-2 min-w-[80px] border border-white">Contrato</th>}
                                        </tr>
                                    </thead>
                                    {data.length > 0 ? (
                                        <tbody className="text-text-main-color">
                                            {filterData(dataToDisplay)
                                                .sort((a, b) => a.name.localeCompare(b.name))
                                                .map((item, index) => (
                                                    <tr key={index} className="row-click" onClick={(e) => handleClick(e, item)}>
                                                        <td className="border border-white item">{item.operacao[0] && item.operacao[0].codigoOperacao}</td>
                                                        <td className="border border-white item">{item.entFormadora[0] ? <>{item.entFormadora[0].name} </> : ""}</td>
                                                        <td className="border border-white item">{item.name ? <>{item.name} </> : ""}</td>
                                                        <td className="border border-white item">{item.codeInterno ? <>{item.codeInterno} </> : ""}</td>
                                                        <td className="border border-white item">{item.codeSIGO ? <>{item.codeSIGO} </> : ""}</td>
                                                        <td className="border border-white item">{item.type ? <> {item.type} </> : ""}</td>
                                                        <td className="border border-white item">{item.modalidade ? <>{item.modalidade}</> : ""}</td>
                                                        <td className="border border-white item">{item.locality ? <>{item.locality}</> : ""}</td>
                                                        <td className="border border-white item">{item.ufcds.length > 0 ? <>{item.ufcds.length} </> : ""}</td>
                                                        <td className="border border-white item">{item.dateBegin ? <>{AlterarFormato(item.dateBegin)} </> : ""}</td>
                                                        <td className="border border-white item">{item.dateEnd ? <>{AlterarFormato(item.dateEnd)} </> : ""}</td>
                                                        <td className="border border-white item">{item.dateEnd ? <>{AlterarFormato(addSixMonthsToDate(item.dateEnd))} </> : ""}</td>
                                                        <td className="border border-white item">{item.duration ? <>{item.duration} </> : ""}</td>
                                                        <td className="border border-white item">{item.nivel ? <>{item.nivel} </> : ""}</td>
                                                        <td className="border border-white item">{item.fechoAdmin ? "Fechado" : "Ativo"}</td>
                                                        <td className="border border-white item">{item.Coordenador[0] ? <>{item.Coordenador[0].fullName}</> : ""}</td>
                                                        {CheckTypeAdmin() === true && item.dateEnd ? (
                                                            <>
                                                                {item.fechoAdmin === true ? (
                                                                    <>{item.evaluation || item.evaluation === 0 ? (
                                                                        <td>{item.evaluation}%</td>
                                                                    ) : (
                                                                        <td> <button className="button-geral">
                                                                            <Link to={"/menuadmin/" + params.id_candidatura + "/cursos/avaliacao/" + item._id}>Avaliar</Link>
                                                                        </button></td>
                                                                    )}</>
                                                                ) : (
                                                                    <td>Curso por Fechar</td>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <> {CheckTypeAdmin() === true && <td>Curso em Atividade</td>}</>
                                                        )}
                                                        {CheckTypeAdmin() === true && <td className="border border-white">
                                                            <ModalEnvioContrato tipo={item.modalidade} data={item} token={"admin1"} />
                                                        </td>}
                                                    </tr>
                                                ))}
                                        </tbody>
                                    ) : (
                                        <tbody />
                                    )}
                                </table>
                            </div>
                            <Tooltip id="my-tooltip" />
                            <div className="flex items-center justify-center mt-4 mb-8">
                                <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                    <AiOutlineDoubleLeft />
                                </button>
                                <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                    <AiOutlineLeft />
                                </button>
                                <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                                <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                    <AiOutlineRight />
                                </button>
                                <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                    <AiOutlineDoubleRight />
                                </button>
                            </div>
                        </> : <p className="third-title">Não existem dados</p>}
                    {CheckTypeFinanceiro() === true && <button className="button-geral mt-6 mb-10" onClick={() => excelUfcds()}>
                        <h3 className="text-white group-hover:text-white uppercase">Download Informações das Disciplinas de Todos os Percursos</h3>
                    </button>}
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main>
        </>
    );
}

export default Candidaturas