import styled from "styled-components";

export const AreaCourseCreated = styled.div`

height: calc(100vh - 120px);
align-items: center;
justify-content: center;
flex-direction: column;
font-family: "Lato";
display: flex;
margin-top: 14vh;

h1 {
    margin-top: 1em;
    font-size: 30px;
    text-align: center;
}


.table {
    min-width: 650px;
    height: 180px;
    overflow: auto;
    font-size: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    width: 35%;
    box-shadow: 5px 5px 15px #00000022;
    background-color: white;
}


.botao {    
    margin-top: 0.5em;
    display: flex;
    justify-content: space-around;
    text-decoration: none;
    font-size: 18px;
    align-self: center;
    color: white;
    background-color: rgb(27, 152, 255);
    border-radius: 50px;
    padding: 15px;
    box-shadow: 5px 5px 10px #22222222;
    transition: transform 0.2s ease;
    border: 1px solid rgb(27, 152, 255);
}

.botao:hover {
    background-color: rgb(17, 101, 170);
}

`