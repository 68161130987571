import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../../components/Footer";
import NavbarAdmin1 from "../../../components/Navbars/NavbarAdmin1/NavbarAdmin1";
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { IoMdRemoveCircle } from "react-icons/io";
import Cookies from "js-cookie";
import toastSuccess from "../../../components/Toast/toastSuccess";
import ConfirmToaster from "../../../components/Toast/toastConfirmChoice";
import CheckTypeAdmin from "../../../components/CheckTypeAdmin/CheckTypeAdmin";

function Formandos() {
    const [data, setData] = useState([]);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const savedSearchTerm = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-allFormandos-1") : "";
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm ? savedSearchTerm : "");
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-allFormandos-Pag") : ""
    const saveList = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-allFormandos-F1") : ""
    const [ischecked, setIschecked] = useState(saveList ? saveList : "0");
    const [nPaginas, setNumPaginas] = useState(0);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Admin-allFormandos-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)


    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        Cookies.set("Admin-allFormandos-Pag", paginaAtual);
        Cookies.set("Admin-allFormandos-1", searchTerm);
        Cookies.set("Admin-allFormandos-F1", ischecked);
        Cookies.set(`Admin-allFormandos-itm1`, itemsPag)
        getFormandos()
    }, [loading, ischecked, paginaAtual, nPaginas, searchTerm, itemsPag]);

    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };

    //filtra dados
    function filterData(data) {
        return data.filter((item) =>
            item.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.gender.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.birthDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.cellNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.locality.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.idNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.academicDegree.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.nif.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.percurso && item.percurso[0] && item.percurso[0].name && item.percurso[0].name.includes(searchTerm)) ||
            (item.percurso && item.percurso[0] && item.percurso[0].entFormadora && item.percurso[0].entFormadora[0] && item.percurso[0].entFormadora[0].name && item.percurso[0].entFormadora[0].name.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (item.percurso && item.percurso[0] && item.percurso[0].entFinanciadora && item.percurso[0].entFinanciadora[0] && item.percurso[0].entFinanciadora[0].name && item.percurso[0].entFinanciadora[0].name.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    }

    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        if (paginaAtual >( data.length / itemsPag)) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }

    function deleteFormando(_id) {
        fetch(`${process.env.REACT_APP_API_URL}/formandos/${_id}`, {
            method: "DELETE",
            headers: {
                'authorization': localStorage.getItem('admin1')
            }
        }).then((result) => {
            result.json().then((resp) => {
                toastSuccess('Candidatura removida com sucesso!')
                getFormandos()
            })
        })
    }
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }

    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }

    const handleClick = (event, item) => {
        // Check if the clicked element is a <td>
        if (event.target.tagName === 'TD') {
            // Check if the <td> has a 'clickable' class
            if (!event.target.classList.contains('candidate')) {
                // Navigate to the desired location
                // Replace 'destination' with your actual navigation logic
            }
            else navigate("/menuadmin/" + params.id_candidatura + "/individual/formando/" + item._id)
        }
    };

    //Função para mostrar todos os formandos
    //Obtem formandos
    function getFormandos() {
        if (ischecked === "0") {
            fetch(`${process.env.REACT_APP_API_URL}/formandos`, {
                headers: {
                    'authorization': localStorage.getItem('admin1')
                }
            }).then((res) => res.json())
                .then(
                    (result) => {
                        setData(result.sort((a, b) => {
                            return a.fullName.localeCompare(b.fullName);
                        }));
                        paginacaoTabelas(filterData(result.sort((a, b) => {
                            return a.fullName.localeCompare(b.fullName);
                        })));
                        setLoading(false);
                    },
                    (error) => {
                        console.error("Error fetching data: ", error);
                        setError(error);
                    }
                )
        }

        //Selector que filtra os formandos validados
        if (ischecked === "1") {
            fetch(`${process.env.REACT_APP_API_URL}/formandos/formandosValidados`, {
                headers: {
                    'authorization': localStorage.getItem('admin1')
                }
            }).then((res) => res.json())
                .then(
                    (result) => {
                        setData(result.sort((a, b) => {
                            return a.fullName.localeCompare(b.fullName);
                        }));
                        paginacaoTabelas(filterData(result.sort((a, b) => {
                            return a.fullName.localeCompare(b.fullName);
                        })));
                        setLoading(false);
                    },
                    (error) => {
                        console.error("Error fetching data: ", error);
                        setError(error);
                    }
                )
        }

        if (ischecked === "2") {
            fetch(`${process.env.REACT_APP_API_URL}/formandos/formandosNaoValidados`, {
                headers: {
                    'authorization': localStorage.getItem('admin1')
                }
            }).then((res) => res.json())
                .then(
                    (result) => {
                        setData(result.sort((a, b) => {
                            return a.fullName.localeCompare(b.fullName);
                        }));
                        paginacaoTabelas(filterData(result.sort((a, b) => {
                            return a.fullName.localeCompare(b.fullName);
                        })));
                        setLoading(false);
                    },
                    (error) => {
                        console.error("Error fetching data: ", error);
                        setError(error);
                    }
                )
        }
        if (ischecked === "3") {
            fetch(`${process.env.REACT_APP_API_URL}/formandos/formandosExcluidos`, {
                headers: {
                    'authorization': localStorage.getItem('admin1')
                }
            }).then((res) => res.json())
                .then(
                    (result) => {
                        setData(result.sort((a, b) => {
                            return a.fullName.localeCompare(b.fullName);
                        }));
                        paginacaoTabelas(filterData(result.sort((a, b) => {
                            return a.fullName.localeCompare(b.fullName);
                        })));
                        setLoading(false);
                    },
                    (error) => {
                        console.error("Error fetching data: ", error);
                        setError(error);
                    }
                )
        }

    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin1 currentPage={"verFormandos"} />
                </div>
                <div className="flex-grow mt-8">
                    <Link to={'/menuadmin/' + params.id_candidatura} className="back-button-div-noNavbar">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>
                    <h1 className="mx-4 mt-4 title sm:mt-0">Lista de Formandos</h1>
                    <div className="search-div">
                        <label className="search-input-label">Pesquisar: </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input type="text" className="search-input" placeholder="ex: Nome, Email ..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                        </div>
                    </div>
                    <div className="mt-4 mb-2">
                        <label className="text-text-main-color" htmlFor="templateCheck">Formandos: </label>
                        <select onWheel={(e) => e.target.blur()} className="mr-3 search-selector" name="sel" id="sel" defaultValue={ischecked} onChange={(e) => setIschecked(e.target.value)}>
                            <option value="0">Todos</option>
                            <option value="1">Validados</option>
                            <option value="2">Não Validados</option>
                            <option value="3">Excluídos</option>
                        </select>
                    </div>
                    {data && data.length > 0 ? <>
                        <div className="flex justify-center items-center mb-1">
                            <label className="text-text-main-color">Dados por página: </label>
                            <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                value={itemsPag}
                                onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div className="table-container">
                            <table className="my-table">

                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="p-2 min-w-[80px] border border-white">Nome Completo</th>
                                        <th className="p-2 min-w-[80px] border border-white">Género</th>
                                        <th className="p-2 min-w-[80px] border border-white">Data nascimento</th>
                                        <th className="p-2 min-w-[80px] border border-white">Número de telefone</th>
                                        <th className="p-2 min-w-[80px] border border-white">E-mail</th>
                                        <th className="p-2 min-w-[80px] border border-white">Habilitações Académicas</th>
                                        <th className="p-2 min-w-[80px] border border-white">Localidade</th>
                                        <th className="p-2 min-w-[80px] border border-white">Nif</th>
                                        <th className="p-2 min-w-[80px] border border-white">Estado</th>
                                        <th className="p-2 min-w-[80px] border border-white">Curso</th>
                                        <th className="p-2 min-w-[80px] border border-white">Entidade Formadora</th>
                                        <th className="p-2 min-w-[80px] border border-white">Entidade Financiadora</th>
                                        {CheckTypeAdmin() ===true  &&<th className="p-2 min-w-[80px] border border-white">Remover Formando</th>}
                                    </tr>
                                </thead>
                                {data.length > 0 ? (
                                    <tbody className="text-text-main-color">
                                        {filterData(dataToDisplay).map((item, index) => (
                                            <tr key={index} className="row-click" onClick={(e) => handleClick(e, item)}>
                                                <td className="border border-white candidate">{item.fullName}</td>
                                                <td className="border border-white candidate">{item.gender}</td>
                                                <td className="border border-white candidate">{item.birthDate}</td>
                                                <td className="border border-white candidate">{item.cellNumber}</td>
                                                <td className="border border-white candidate">{item.email}</td>
                                                <td className="border border-white candidate">{item.academicDegree}</td>
                                                <td className="border border-white candidate">{item.locality}</td>
                                                <td className="border border-white candidate">{item.nif}</td>
                                                {item.valid === "Validado" ? <td className="border border-white candidate">Validado</td> : <td className="border border-white">Por Validar</td>}
                                                {item.percurso.length > 0 && item.percurso[0].name ? <td className="border border-white candidate">{item.percurso[0].name}</td> : <td className="border border-white">Sem Curso</td>}
                                                {item.percurso.length > 0 && item.percurso[0].entFormadora.length > 0 ? <td className="border border-white candidate">{item.percurso[0].entFormadora[0].name}</td> : <td className="border border-white">Sem Curso</td>}
                                                {item.percurso.length > 0 && item.percurso[0].entFinanciadora.length > 0 ? <td className="border border-white candidate">{item.percurso[0].entFinanciadora[0].name}</td> : <td className="border border-white">Sem Curso</td>}
                                                {CheckTypeAdmin() ===true  &&<td className="border border-white">
                                                    <ConfirmToaster css={"button-geral hover:brightness-75"} icon={<IoMdRemoveCircle size={24} />} title={"Eliminar"} message={"Deseja eliminar a candidatura ? (Se a eliminá-la perderá o acesso à esta.)"} confirm={deleteFormando} id={item._id}></ConfirmToaster>
                                                </td>}
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody />
                                )}
                            </table>
                        </div>
                        <div className="flex items-center justify-center mt-4 mb-8">
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                <AiOutlineDoubleLeft />
                            </button>
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                <AiOutlineLeft />
                            </button>
                            <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                <AiOutlineRight />
                            </button>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                <AiOutlineDoubleRight />
                            </button>
                        </div> </> : <p className="third-title">Não existem dados</p>}
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main >
        </>
    );
}

export default Formandos