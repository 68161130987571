import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from 'react-router-dom'
import { AiOutlineHome } from "react-icons/ai";
import { Books, Student } from "@phosphor-icons/react";
import { FiBookOpen } from "react-icons/fi";
import Logout from "../../logout/logout";

//Componente navbar
function NavbarCoordenador({ currentPage }) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [name, setName] = useState(null);
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const id = localStorage.getItem('id');
        getProfilePhoto(id)
    }, []);

    //Abrir e fechar sideMenu
    function toogleSideMenu() {
        let sideBar = document.getElementById("sidebar");
        let sideContent = document.getElementById("sidebarContent");
        if (sideBar && sideContent) {
            sideBar.classList.toggle("md:w-ful");
            sideBar.classList.toggle("w-0");
            sideContent.classList.toggle("md:px-3");
            sideContent.classList.toggle("px-1");
        }
    }

    //Função para buscar a foto do administrador pelo seu id
    function getProfilePhoto(id) {
        fetch(`${process.env.REACT_APP_API_URL}/coordenadores/${id}/photo`, {
            headers: {
                'authorization': localStorage.getItem("coord"),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    nomeApelido(result.fullName)
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    };

    //Fuunção para separar 1º nome e o último nome do nome completo do utilizador
    //Separa nome e aplido
    function nomeApelido(fullname) {
        let names = fullname.split(" ");
        if (names.length === 1) {
            setName(names[0])
        } else {
            let firstName = names[0];
            let lastName = names.pop();
            setName(firstName + " " + lastName)
        }
    }

    return (
        <div className="w-full">
            <aside id="sidebar" className="fixed top-0 left-0 z-40 h-full transition-transform md:relative sm:translate-x-0" aria-label="Sidebar">
                <button onClick={toogleSideMenu} type="button" className="absolute top-0 left-0 inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200">
                    <span className="sr-only">Open sidebar</span>
                    <svg className="top-0 w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                    </svg>
                </button>
                <div id="sidebarContent" className="h-full px-1 py-4 overflow-y-auto md:px-3 bg-secundary-color bg-opacity-80 md:bg-opacity-10">
                    <ul className="mt-12 space-y-2 font-medium md:mt-0">
                        <li className="flex flex-col items-center">
                            <Link to={"/menucoordenador/Profile/" + params.id_candidatura}>
                                <img className={"rounded-full cursor-pointer object-cover h-16 w-16 mx-auto border-main-color " + (currentPage === "perfil" ? "border-[3px]" : "border")} src={data && data.photo ? data.photo : 'https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg'} />
                                <p className="pt-2 text-center text-text-main-color">{name && name}</p>
                            </Link>
                        </li>
                        <li>
                            <Link to={"/menucoordenador/" + params.id_candidatura}>
                                <div className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage === "home" ? " navbarItemSelected" : "hover:bg-white")}>
                                    <AiOutlineHome className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage === "home" && "text-white")} />
                                    <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage === "home" && "text-white")}>Início</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to={"/menucoordenador/" + params.id_candidatura + "/turmas"}>
                                <div className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage === "turmas" ? "navbarItemSelected" : "hover:bg-white")}>
                                    <Student className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage === "turmas" && "text-white")} />
                                    <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage === "turmas" && "text-white")}>Gerir Turmas</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to={"/menucoordenador/" + params.id_candidatura + "/disciplinas"}>
                                <div className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage === "disciplinas" ? "navbarItemSelected" : "hover:bg-white")}>
                                    <Books className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage === "disciplinas" && "text-white")} />
                                    <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage === "disciplinas" && "text-white")}>Gerir Disciplinas</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to={"/menucoordenador/" + params.id_candidatura + "/cursos"}>
                                <div className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage === "cursos" ? "navbarItemSelected" : "hover:bg-white")}>
                                    <FiBookOpen className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage === "cursos" && "text-white")} />
                                    <span className={"flex-1 ml-3 text-left text-xs sm:text-base break-all text-text-main-color " + (currentPage === "cursos" && "text-white")}>Ver Cursos/Percursos</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to={"/menucoordenador/" + params.id_candidatura + "/list"}>
                                <div className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage === "ListaFormador" ? "navbarItemSelected" : "hover:bg-white")}>
                                    <FiBookOpen className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage === "ListaFormador" && "text-white")} />
                                    <span className={"flex-1 ml-3 text-left text-xs sm:text-base break-all text-text-main-color " + (currentPage === "ListaFormador" && "text-white")}>Ver Bolsa de Formadores</span>
                                </div>
                            </Link>
                        </li>
                    </ul>
                    <ul className="pt-4 mt-4 space-y-2 font-medium border-t border-gray-200">
                        <li>
                            <button className='w-full logoutButton' onClick={() => { Logout("coord"); navigate("/") }}>
                                <img className='h-4 sm:h-5' src={process.env.PUBLIC_URL + "/img/logout.png"} alt="Logout" /><p className="ml-2 sm:ml-3">Logout</p>
                            </button>
                        </li>
                    </ul>
                </div>
            </aside>
        </div>
    )
}

export default NavbarCoordenador