import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import NavbarAdmin1 from "../../Navbars/NavbarAdmin1/NavbarAdmin1";
import NavbarAdmin2 from "../../Navbars/NavbarAdmin2/NavbarAdmin2";
import NavbarCoordenador from "../../Navbars/NavbarCoordenador/navbarCoordenador";
import Header from "../../Header";
import NavbarFormador from "../../Navbars/NavbarFormador/navbarFormador";
import toastSuccess from "../../Toast/toastSuccess";

export default function FormAvaliacaoFormador({ token }) {
  const [data, setData] = useState("");
  const [cont, setCont] = useState(0);
  const [index, setIndex] = useState(-1);
  const [valor1, setValor1] = useState("");
  const [valor2, setValor2] = useState("");
  const [valor3, setValor3] = useState("");
  const [valor4, setValor4] = useState("");
  const [valor5, setValor5] = useState("");
  const [valor6, setValor6] = useState("");
  const [valor7, setValor7] = useState("");
  const [valor8, setValor8] = useState("");
  const [valor9, setValor9] = useState("");
  const [formador, setFormador] = useState("");
  const [outros, setOutros] = useState("");
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getUFCD();
  }, [cont, formador]);

  /* 
        function handleClick() {
            navigate(-1);
        } */

  function avaliarFormadorUFCD() {
    fetch(
      `${process.env.REACT_APP_API_URL}/ufcds/${params.id_ufcd}/avaliacaoSatisfacaoFormador`,
      {
        method: "PATCH",
        body: JSON.stringify({
          criterio1: valor1,
          criterio2: valor2,
          criterio3: valor3,
          criterio4: valor4,
          criterio5: valor5,
          criterio6: valor6,
          criterio7: valor7,
          criterio8: valor8,
          sugestao: valor9,
          formadorId: params.id_avaliador,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: localStorage.getItem(token),
        },
      }
    ).then((result) => {
      result.json().then((resp) => {
        toastSuccess("Formulário submetido com sucesso");
      });
      /*                 handleClick(); */
    });
  }

  function getUFCD() {
    fetch(`${process.env.REACT_APP_API_URL}/ufcds/${params.id_ufcd}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: localStorage.getItem(token),
      },
    }).then((res) => {
      res
        .json()
        .then((result) => {
          setData(result);
          if (result.formadores.length > 1) {
            if (!formador) {
              setFormador(result.formadores[0]._id);
            }
            const index = result.avaliacaoSatisfacaoFormador.findIndex(
              (item) => item.formadorId === formador
            );
            if (index !== -1) {
              // Check if index is found
              setIndex(index); // Assuming setIndex is a function to update state
            } else setIndex(-1);
          } else if (result.formadores.length === 1) {
            if (!formador) {
              setFormador(result.formadores[0]._id);
            }
            const index = result.avaliacaoSatisfacaoFormador.findIndex(
              (item) => item.formadorId === result.formadores[0]._id
            );
            if (index !== -1) {
              // Check if index is found
              setIndex(index); // Assuming setIndex is a function to update state
            } else setIndex(-1);
          }
        })
        .catch((error) => {
          console.error("Error parsing JSON:", error);
        });
    });
  }
  function resetCriterios() {
    setValor1("");
    setValor2("");
    setValor3("");
    setValor4("");
    setValor5("");
    setValor6("");
    setValor7("");
    setValor8("");
    setValor9("");
  }
  const getOptionText = (option) => {
    switch (option) {
      case 1:
        return "Muito Insuficiente";
      case 2:
        return "Insuficiente";
      case 3:
        return "Suficiente";
      case 4:
        return "Bom";
      case 5:
        return "Muito Bom";
      default:
        return "";
    }
  };
  return (
    <>
      <main
        className={
          token === "coord" || token === "user"
            ? "md:grid sm:grid-cols-10 min-h-screen mx-auto"
            : "min-h-screen"
        }
      >
        {token !== "coord" && token !== "user" && (
          <div className="w-full">
            {token === "admin2" && <NavbarAdmin2 currentPage={"gerirCursos"} />}
            {token === "admin1" && <NavbarAdmin1 currentPage={"gerirCursos"} />}
          </div>
        )}
        {token === "coord" && (
          <div className="h-full sm:col-span-2 md:bg-secundary-color">
            <NavbarCoordenador currentPage={"cursos"} />
          </div>
        )}
        {token === "user" && (
          <div className="h-full sm:col-span-2 md:bg-secundary-color">
            <NavbarFormador currentPage={"cursos"} />
          </div>
        )}
        {token === "coord" && (
          <div className="back-button-noNavbar">
            <button
              onClick={() => navigate(-1)}
              className="back-button-div-noNavbar"
            >
              <img
                className="back-button-img"
                alt="Back"
                src={process.env.PUBLIC_URL + "/img/back_arrow.svg"}
              />
            </button>
          </div>
        )}
        <div
          className={
            token === "coord" || token === "user"
              ? "sm:col-span-8 h-full"
              : "flex-grow mt-8"
          }
        >
          {(token === "coord" || token === "user") && <Header />}
          {token !== "coord" && token !== "user" && (
            <button
              onClick={() => navigate(-1)}
              className="back-button-div-noNavbar"
            >
              <img
                className="back-button-img"
                alt="Back"
                src={process.env.PUBLIC_URL + "/img/back_arrow.svg"}
              />
            </button>
          )}
          <div className="w-[80%] m-auto text-start text-[18px]">
            <div>
              <h1 className="title">
                Avaliação da Satisfação do Formador/a com a Formação
              </h1>
            </div>
            {token !== "user" && data && data.formadores.length > 0 && (
              <div>
                <select
                  name="formadores"
                  id="formadores"
                  onChange={(e) => {
                    setFormador(e.target.value);
                    resetCriterios();
                  }}
                  defaultValue={formador}
                >
                  {data.formadores.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.fullName}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="my-10 text-main-color">
              Pretendemos conhecer qual a sua satisfação sobre a forma como
              decorreu esta unidade de formação. Por favor, assinale a sua
              resposta em cada um dos itens abaixo mediante a seguinte escala de
              cotação.
            </div>
            <div className="flex flex-col gap-10">
              <h1 className="subtitle"> I - AVALIAÇÃO DO GRUPO DE FORMANDOS</h1>
              <div className="flex flex-col gap-10">
                <div>
                  <h4 className="third-title font-bold">
                    1. Adequação das caraterísticas do grupo quanto aos
                    conhecimentos/competências de partida.
                  </h4>
                  <div className="flex flex-row gap-10">
                    {[1, 2, 3, 4, 5].map((option) => (
                      <div className="flex items-center" key={option}>
                        <input
                          className="m-2"
                          type="checkbox"
                          checked={
                            (data &&
                              index !== -1 &&
                              data.avaliacaoSatisfacaoFormador[index]
                                .criterio1 === option &&
                              !valor1) ||
                            (index === -1 && valor1 === option)
                          }
                          onClick={() => {
                            index === -1 &&
                              token === "user" &&
                              setValor1(option);
                          }}
                        />
                        <h1>
                          {option} - {getOptionText(option)}
                        </h1>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <h4 className="third-title font-bold">
                    2. Motivação dos membros do grupo para a aprendizagem
                  </h4>
                  <div className="flex flex-row gap-10">
                    {[1, 2, 3, 4, 5].map((option) => (
                      <div className="flex items-center" key={option}>
                        <input
                          className="m-2"
                          type="checkbox"
                          checked={
                            (data &&
                              index !== -1 &&
                              data.avaliacaoSatisfacaoFormador[index]
                                .criterio2 === option) ||
                            valor2 === option
                          }
                          onClick={() => {
                            index === -1 &&
                              token === "user" &&
                              setValor2(option);
                          }}
                        />
                        <h1>
                          {option} - {getOptionText(option)}
                        </h1>
                      </div>
                    ))}
                  </div>
                </div>

                <div>
                  <h4 className="third-title font-bold">
                    3. Competências de relacionamento dos/as formandos/as na
                    ação (para com os colegas e com o/a Formador/a)
                  </h4>
                  <div className="flex flex-row gap-10">
                    {[1, 2, 3, 4, 5].map((option) => (
                      <div className="flex items-center" key={option}>
                        <input
                          className="m-2"
                          type="checkbox"
                          checked={
                            (data &&
                              index !== -1 &&
                              data.avaliacaoSatisfacaoFormador[index]
                                .criterio3 === option) ||
                            valor3 === option
                          }
                          onClick={() => {
                            index === -1 &&
                              token === "user" &&
                              setValor3(option);
                          }}
                        />
                        <h1>
                          {option} - {getOptionText(option)}
                        </h1>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <h4 className="third-title font-bold">
                    4. Resultados alcançados pelos formandos/as em termos de
                    aprendizagem
                  </h4>
                  <div className="flex flex-row gap-10">
                    {[1, 2, 3, 4, 5].map((option) => (
                      <div className="flex items-center" key={option}>
                        <input
                          className="m-2"
                          type="checkbox"
                          checked={
                            (data &&
                              index !== -1 &&
                              data.avaliacaoSatisfacaoFormador[index]
                                .criterio4 === option) ||
                            valor4 === option
                          }
                          onClick={() => {
                            index === -1 &&
                              token === "user" &&
                              setValor4(option);
                          }}
                        />
                        <h1>
                          {option} - {getOptionText(option)}
                        </h1>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-10">
                <h1 className="subtitle">
                  II - AVALIAÇÃO DA ENTIDADE FORMADORA
                </h1>
                <div>
                  <h4 className="third-title font-bold">
                    5. Qualidade das instalações disponíveis:
                    <p>
                      PRESENCIAL: Instalações físicas (insonorização,
                      luminosidade, temperatura, etc).
                    </p>
                    <p>ON-LINE: Qualidade da Plataforma LMS.</p>
                  </h4>
                  <div className="flex flex-row gap-10">
                    {[1, 2, 3, 4, 5].map((option) => (
                      <div className="flex items-center" key={option}>
                        <input
                          className="m-2"
                          type="checkbox"
                          checked={
                            (data &&
                              index !== -1 &&
                              data.avaliacaoSatisfacaoFormador[index]
                                .criterio5 === option) ||
                            valor5 === option
                          }
                          onClick={() => {
                            index === -1 &&
                              token === "user" &&
                              setValor5(option);
                          }}
                        />
                        <h1>
                          {option} - {getOptionText(option)}
                        </h1>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <h4 className="third-title font-bold">
                    6. Qualidade dos meios audiovisuais e recursos ao dispor:
                    <p>
                      PRESENCIAL: Equipamentos disponibilizados (Vídeo Projetor,
                      Quadros, Marcadores, etc).
                    </p>
                    <p>
                      ON-LINE: Funcionalidade da Plataforma LMS e da Plataforma
                      de videoconferência.
                    </p>
                  </h4>
                  <div className="flex flex-row gap-10">
                    {[1, 2, 3, 4, 5].map((option) => (
                      <div className="flex items-center" key={option}>
                        <input
                          className="m-2"
                          type="checkbox"
                          checked={
                            (data &&
                              index !== -1 &&
                              data.avaliacaoSatisfacaoFormador[index]
                                .criterio6 === option) ||
                            valor6 === option
                          }
                          onClick={() => {
                            index === -1 &&
                              token === "user" &&
                              setValor6(option);
                          }}
                        />
                        <h1>
                          {option} - {getOptionText(option)}
                        </h1>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <h4 className="third-title font-bold">
                    7. Apoio/acompanhamento da Coordenação /Mediação Pedagógica
                  </h4>
                  <div className="flex flex-row gap-10">
                    {[1, 2, 3, 4, 5].map((option) => (
                      <div className="flex items-center" key={option}>
                        <input
                          className="m-2"
                          type="checkbox"
                          checked={
                            (data &&
                              index !== -1 &&
                              data.avaliacaoSatisfacaoFormador[index]
                                .criterio7 === option) ||
                            valor7 === option
                          }
                          onClick={() => {
                            index === -1 &&
                              token === "user" &&
                              setValor7(option);
                          }}
                        />
                        <h1>
                          {option} - {getOptionText(option)}
                        </h1>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <h4 className="third-title font-bold">
                    8. Satisfação global com a entidade formadora
                  </h4>
                  <div className="flex flex-row gap-10">
                    {[1, 2, 3, 4, 5].map((option) => (
                      <div className="flex items-center" key={option}>
                        <input
                          className="m-2"
                          type="checkbox"
                          checked={
                            (data &&
                              index !== -1 &&
                              data.avaliacaoSatisfacaoFormador[index]
                                .criterio8 === option) ||
                            valor8 === option
                          }
                          onClick={() => {
                            index === -1 &&
                              token === "user" &&
                              setValor8(option);
                          }}
                        />
                        <h1>
                          {option} - {getOptionText(option)}
                        </h1>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <h4 className="subtitle">SUGESTÕES/ CRÍTICAS/ COMENTÁRIOS</h4>
                <textarea
                  disabled={index === -1 && token === "user" ? false : true}
                  defaultValue={
                    data &&
                    index !== -1 &&
                    data.avaliacaoSatisfacaoFormador[index].sugestao
                      ? data.avaliacaoSatisfacaoFormador[index].sugestao
                      : ""
                  }
                  onChange={(e) => setValor9(e.target.value)}
                  className="input-textarea h-40"
                ></textarea>
              </div>
              <div className="my-5 text-main-color">
                Obrigado pela sua colaboração
              </div>
              <div className="mb-10 mx-auto">
                {token === "user" && index === -1 && (
                  <button
                    onClick={avaliarFormadorUFCD}
                    className="button-geral"
                  >
                    Submeter
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
