import toastNotify from "../Toast/toastNotify";
//Expirar login
function isTokenExpired(token, warningThresholdInMinutes = 10) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = atob(base64);
    
    const { exp } = JSON.parse(jsonPayload);
    const expirationTime = exp * 1000;
    const currentTime = Date.now();
    const timeDifferenceInMilliseconds = expirationTime - currentTime;
    const timeDifferenceInMinutes = Math.ceil(timeDifferenceInMilliseconds / (1000 * 60));
    if (timeDifferenceInMinutes <= warningThresholdInMinutes && timeDifferenceInMinutes > 0) {
        toastNotify(`O seu acesso expira em ${timeDifferenceInMinutes} minutos.`);
    }
    else if(timeDifferenceInMinutes === 0){
        toastNotify(`O seu acesso expirou.`);
    }

    const expired = currentTime >= expirationTime;
    return expired;
}

export default isTokenExpired;