import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import { BsFillPencilFill } from "react-icons/bs";
import 'react-toastify/dist/ReactToastify.css';
import toastSuccess from "../../../../components/Toast/toastSuccess";
import excelGeneratorClone from "../../../../components/Excel/cronogramaGeralClone";
import NavbarCoordenador from "../../../../components/Navbars/NavbarCoordenador/navbarCoordenador";
import { AiFillCopy, AiOutlineCalendar } from "react-icons/ai";
import { BiCalendarEdit } from "react-icons/bi";
import { BsCalendar3 } from "react-icons/bs";
import { LuFileCog, LuFiles, LuFileText, LuBookOpen, LuBook } from "react-icons/lu";
import { Books, Student, ChalkboardTeacher } from "@phosphor-icons/react";
import copyText from "../../../../components/FormChecks/copyText/copyText";
import AlterarFormato from "../../../../components/FormChecks/formatDates/foramatDates";
import toastError from "../../../../components/Toast/toastError";

function Candidaturas() {
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [pra, setPra] = useState("")
    const [fechoCoordenador, setFechoCoordenador] = useState(false);
    const [fechoCoordenadorUfcd, setFechoCoordenadorUfcd] = useState(false);
    const [alterarDataInicio, setAlterarDataInicio] = useState(false)
    const [alterarDataFinal, setAlterarDataFinal] = useState(false)
    const [alterar2, setAlterar2] = useState(false)
    const [dateBegin, setDateBegin] = useState("")
    const [dateEnd, setDateEnd] = useState("")
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const params = useParams();

    useEffect(() => {
        getPercurso()
    }, [loading]);

    //Download do ficheiro
    function downloadFile(url) {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank'); // Opens the link in a new tab/window
        link.setAttribute('download', ''); // Optional, sets the download attribute to trigger download in some browsers
        link.click();
    }
    //Obtem percurso
    function getPercurso() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "authorization": localStorage.getItem("coord")
            },
            method: 'GET',
        })
            .then(res => res.json())
            .then((result) => {
                let cont = 0
                result.ufcds.forEach(ufcds => {
                    if ((ufcds.fechoCoordenador && ufcds.fechoFormador) || (ufcds.fechoCoordenador === true && ufcds.fechoFormador === true)) {
                        cont++
                    }
                    if (ufcds.name === "PRA") {
                        setPra(ufcds._id)
                    }
                });
                if (result.ufcds.length === 1 && result.ufcds[0].fechoFormador === true) {
                    setFechoCoordenadorUfcd(true)
                    setFechoCoordenador(true)
                }
                if (cont === result.ufcds.length) {
                    setFechoCoordenador(true)
                }
                setData(result)
                setDateBegin(result.dateBegin)
                setDateEnd(result.dateEnd)
                setLoading(false)
            })
    }

    function alterarSIGO(value) {

        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}/SIGO`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem('coord')
            },
            body: JSON.stringify({
                codeSIGO: value ? value : data.codeSIGO,
            }),
            method: 'PATCH',
        }).then((result) => {
            result.json().then((resp) => {
                toastSuccess('Código SIGO alterado com sucesso!')
            })
            getPercurso();
            setAlterar2(false)
        })
    }

    function changeDataInicio() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}/dateBegin`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem("coord")
            },
            body: JSON.stringify({
                dateBegin: dateBegin,
            }),
            method: 'PATCH',
        }).then((result) => {
            result.json().then((resp) => {
                toastSuccess('Data alterada com sucesso!')
            })
            getPercurso();
            setAlterarDataInicio(false)
        })
    }

    function changeDataFinal() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}/dateEnd`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem("coord")
            },
            body: JSON.stringify({
                dateEnd: dateEnd,
            }),
            method: 'PATCH',
        }).then((result) => {
            result.json().then((resp) => {
                toastSuccess('Data alterada com sucesso!')
            })
            getPercurso();
            setAlterarDataFinal(false)
        })
    }
    function fecharCurso() {
        if (data.fechoCoordenador) {
            fetch(`${process.env.REACT_APP_API_URL}/percurso/admin/fechoCurso/${params.id_curso}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem("coord")
                },
                method: 'POST',
                body: JSON.stringify({
                    fechoAdmin: true
                })
            })
                .then(res => res.json())
                .then((result) => {
                    toastSuccess('Curso fechado com sucesso')
                })
        }
        else toastError("Existem disciplinas por fechar")
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    return (
        <>
            <main className="min-h-screen grid-cols-7 mx-auto md:grid xl:grid-cols-10">
                <div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    <NavbarCoordenador currentPage={"cursos"} />
                </div>

                <div className="relative w-full h-full col-span-5 xl:col-span-8">
                    <div className="mx-4 back-button-noNavbar">
                        <Link to={"/menucoordenador/" + params.id_candidatura + "/cursos"} className="flex w-8 h-8">
                            <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                        </Link>
                    </div>
                    <Header sideMenu={"true"} />
                    <h1 className="mx-4 mt-8 title sm:mt-0">Menu curso - {data.name}</h1>
                    <div className="grid mx-4 lg:grid-cols-2 ">
                        <div>
                            <div className="flex justify-center" >
                                <div className="element">Nome: </div>
                                <div className="value">{data.name}</div>
                            </div>
                            <div className="flex justify-center" >
                                <div className="element">Operação: </div>
                                <div className="value">{data.operacao[0] && data.operacao[0].codigoOperacao}</div>
                            </div>
                            <div className="flex justify-center">
                                <div className="element">Modalidade: </div>
                                {data.modalidade ? <div className="value">{data.modalidade}</div> : <div className="value" />}
                            </div>
                            <div className="flex justify-center">
                                <div className="element">Area Código de Formação: </div>
                                {data.areaCodigoFormacao ? <div className="value">{data.areaCodigoFormacao}</div> : <div className="value" />}
                            </div>
                            <div className="flex justify-center">
                                <div className="element">Forma de organização: </div>
                                <div className="value">{data.type}</div>
                            </div>
                            <div className="flex justify-center">
                                <div className="element">Entidade formadora: </div>
                                {data.entFormadora[0] ? <div className="value">{data.entFormadora[0].name}</div> : <div className="value" />}
                            </div>
                            <div className="flex justify-center" >
                                <div className="element">Código Interno: </div>
                                <div className="value">{data.codeInterno}</div>
                            </div>
                            <div className="flex justify-center" >
                                <div className="element">Código SIGO: </div>
                                <div className="value">{alterar2 === false ? <> {data.codeSIGO}
                                    <a className="tooltip" onClick={() => setAlterar2(true)} data-tip="Alterar Código SIGO">
                                        <BsFillPencilFill id="icon" className="m-2 cursor-pointer" />
                                    </a> </> : <div id="dropdown"><input className="input-field" defaultValue={data.codeSIGO} id="SIGO" />
                                    <div className="flex justify-center mt-1" >
                                        <button className="p-1 button-cancelar" onClick={() => setAlterar2(false)}>Cancelar</button>
                                        <button className="p-1 ml-1 button-confirmar" onClick={() => alterarSIGO(document.getElementById("SIGO").value)}>Confirmar</button>
                                    </div>
                                </div>
                                }
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="flex justify-center">
                                <div className="element">Entidade financiadora: </div>
                                {data.entFinanciadora[0] ? <div className="value">{data.entFinanciadora[0].name}</div> : <div className="value" />}
                            </div>
                            <div className="flex justify-center">
                                <div className="element">Data-Inicio: </div>
                                <div className="value">
                                    {alterarDataInicio === false ? <> {data.dateBegin && <span id="dataInicio">{AlterarFormato(data.dateBegin)}</span>}
                                        <a className="tooltip" onClick={() => setAlterarDataInicio(true)} data-tip="Alterar Data">
                                            <BsFillPencilFill id="icon" className="m-2 cursor-pointer" />
                                        </a></> :
                                        <>
                                            <div id="dropdown">
                                                <input className="input-field" type="date" name="dateBegin" id="dateBegin" defaultValue={dateBegin} onChange={(e) => setDateBegin(e.target.value)}></input>
                                                <div className="flex justify-center mt-1" >
                                                    <button className="p-1 button-cancelar" onClick={() => setAlterarDataInicio(false)}>Cancelar</button>
                                                    <button className="p-1 ml-1 button-confirmar" onClick={() => changeDataInicio()}>Confirmar</button>
                                                </div>
                                            </div>
                                        </>}
                                </div>
                            </div>
                            <div className="flex justify-center">
                                <div className="element">Data-Fim:</div>
                                <div className="value">
                                    {alterarDataFinal === false ? <> {data.dateEnd && <span id="dataInicio">{AlterarFormato(data.dateEnd)}</span>}
                                        <a className="tooltip" onClick={() => setAlterarDataFinal(true)} data-tip="Alterar Data">
                                            <BsFillPencilFill id="icon" className="m-2 cursor-pointer" />
                                        </a></> :
                                        <>
                                            <div id="dropdown">
                                                <input className="input-field" type="date" name="dateEnd" id="dateEnd" defaultValue={dateEnd} onChange={(e) => setDateEnd(e.target.value)}></input>
                                                <div className="flex justify-center mt-1" >
                                                    <button className="p-1 button-cancelar" onClick={() => setAlterarDataFinal(false)}>Cancelar</button>
                                                    <button className="p-1 ml-1 button-confirmar" onClick={() => changeDataFinal()}>Confirmar</button>
                                                </div>
                                            </div>
                                        </>}
                                </div>
                            </div>
                            <div className="flex justify-center">
                                <div className="element">Nível: </div>
                                {data.nivel ? <div className="value">{data.nivel}</div> : <div className="value" />}
                            </div>
                            <div className="flex justify-center" >
                                <div className="element">Horário: </div>
                                {data.horario ? <div className="value">{data.horario}</div> : <div className="value" />}
                            </div>
                            <div className="flex justify-center">
                                <div className="element">Edição: </div>
                                {data.edicoes ? <div className="value">{data.edicoes}</div> : <div className="value" />}
                            </div>
                            <div className="flex justify-center">
                                <div className="element">Duração:</div>
                                <div className="value">{data.duration}</div>
                            </div>
                            <div className="flex justify-center">
                                <div className="element">Local: </div>
                                <div className="value">{data.locality}</div>
                            </div>
                            <div className="flex justify-center">
                                <div className="element">Morada: </div>
                                <div className="value">{data.address}, {data.postalCode}</div>
                            </div>
                            <div className="flex justify-center" >
                                <div className="element">Estado: </div>
                                <div className="value">{data && (data.fechoAdmin === false || !data.fechoAdmin) ? "Ativo" : "Fechado"}</div>
                            </div>
                            <div className="flex justify-center" >
                                <div className="element">Link Candidaturas:</div>
                                <div className="value">
                                    <a className="mr-2 link tooltip" data-tip="Abrir Link" href={`${process.env.PUBLIC_URL}/#/inscricao/candidatura/` + params.id_curso} target="_blank">Link </a>
                                    <div className="tooltip" data-tip="Copiar" onClick={() => copyText("https://magna.comenius.pt/#/inscricao/candidatura/" + params.id_curso)}>
                                        <AiFillCopy />
                                    </div>
                                </div>
                            </div>
                            {data.type !== "Presencial" && <div className="flex justify-center" >
                                    <div className="element">Moodle: </div>
                                    <div className="value">{data.moodle[0] && <>{data.moodle[0].name}</>}</div>
                                </div>}
                        </div>
                    </div>

                    <div className="grid grid-cols-1 mt-8 mb-32 2xl:grid-cols-2 justify-items-center sm:mb-8 sm:mr-4">
                        <div>
                            <h2 className="subtitle 2xl:-mb-3">Cronogramas</h2>
                            <div className="grid grid-cols-1 sm:grid-cols-2">
                                {data.cronogramaOriginal && <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => downloadFile(data.cronogramaOriginal)}>
                                    <AiOutlineCalendar size={24} className="text-main-color group-hover:text-white" />
                                    <h3 className="mt-1 text-main-color group-hover:text-white">DOWNLOAD CRONOGRAMA GERAL ORIGINAL</h3>

                                </button>}

                                <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => excelGeneratorClone(data)}>
                                    <BiCalendarEdit size={24} className="text-main-color group-hover:text-white" />
                                    <h3 className="mt-1 text-main-color group-hover:text-white">DOWNLOAD CRONOGRAMA GERAL ATUALIZADO</h3>
                                </button>

                                <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => navigate("/menucoordenador/" + params.id_candidatura + "/cursos/" + params.id_curso + "/Cronograma")}>
                                    <BsCalendar3 size={24} className="text-main-color group-hover:text-white" />
                                    <h3 className="mt-1 text-main-color group-hover:text-white">VISUALIZAR/MARCAR CRONOGRAMA DE SESSÕES DE FORMAÇÃO</h3>
                                </button>
                            </div>
                        </div>
                        <div className="mt-8 2xl:mt-0">
                            <h2 className="subtitle 2xl:-mb-3">Gestão do curso</h2>
                            <div className="grid grid-cols-1 lg:grid-cols-2">
                                <div className="flex flex-col sm:flex-row 2xl:flex-col">
                                    {data && data.indice && <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => navigate("/menucoordenador/" + params.id_candidatura + "/cursos/" + params.id_curso + "/verDTP")}>
                                        <LuFiles size={24} className="text-main-color group-hover:text-white" />
                                        <h3 className="text-main-color group-hover:text-white">VISUALIZAR DTP</h3>
                                    </button>}

                                    <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => navigate("/menucoordenador/" + params.id_candidatura + "/cursos/" + params.id_curso + "/gerirDTP")}>
                                        <LuFileCog size={24} className="text-main-color group-hover:text-white" />
                                        <h3 className="mt-1 text-main-color group-hover:text-white">GERIR DTP</h3>
                                    </button>
                                    <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => navigate("/menucoordenador/" + params.id_candidatura + "/cursos/" + params.id_curso + "/Ufcds")}>
                                        <Books size={24} className="text-main-color group-hover:text-white" />
                                        <h3 className="text-main-color group-hover:text-white">GERIR DISCIPLINAS</h3>
                                    </button>

                                    {fechoCoordenador ? <>
                                        {data.fechoAdmin === false || data.ufcds.length === 1 && data.fechoAdmin === false ?
                                            <button className="button-paginas-individual 2xl:mt-8 group" onClick={(e) => { fecharCurso() }}>
                                                <LuBookOpen size={24} className="text-main-color group-hover:text-white" />
                                                <h3 className="text-main-color group-hover:text-white">FECHAR CURSO</h3>
                                            </button> : <div className="button-paginas-individual-fechado 2xl:mt-8 group ">
                                                <LuBook size={24} className="text-white " />
                                                <h3 className="text-white ">CURSO FECHADO</h3>
                                            </div>}
                                    </> : ""}
                                </div>
                                <div>
                                    <div className="flex flex-col sm:flex-row 2xl:flex-col">
                                        {data.modalidade === "Cursos de Educação e Formação de Adultos" && <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => navigate("/menucoordenador/" + params.id_candidatura + "/cursos/" + params.id_curso + "/Pra/" + pra)}>
                                            <LuFileText size={24} className="text-main-color group-hover:text-white" />
                                            <h3 className="text-main-color group-hover:text-white">GERIR PRA</h3>
                                        </button>}
                                        <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => navigate("/menucoordenador/" + params.id_candidatura + "/cursos/" + params.id_curso + "/formadores")}>
                                            <ChalkboardTeacher size={24} className="text-main-color group-hover:text-white" />
                                            <h3 className="text-main-color group-hover:text-white">VISUALIZAR FORMADORES</h3>
                                        </button>
                                        <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => navigate("/menucoordenador/" + params.id_candidatura + "/cursos/" + params.id_curso + "/turma")}>
                                            <Student size={24} className="text-main-color group-hover:text-white" />
                                            <h3 className="text-main-color group-hover:text-white">GERIR TURMA</h3>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>



                    </div>
                </div>
            </main >
            <Footer />
        </>
    );
}

export default Candidaturas