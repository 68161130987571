export default function ArrayNivel() {
    const NivelArray = [
        "0 - Sem Qualificação",
        "1 - Nível 1",
        "2 - Nível 2",
        "3 - Nível 3",
        "4 - Nível 4",
        "5 - Nível 5",
        "6 - Nível 6",
        "7 - Nível 7",
        "8 - Nível 8"
    ];
    return NivelArray;
}