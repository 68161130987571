import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import toastSuccess from "../../../../components/Toast/toastSuccess";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";

function ShowUfcd() {
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);
    const [disciplina, setDisciplina] = useState([]);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [link, setLink] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [duration, setDuration] = useState(null);
    const [cont, setCont] = useState(0)
    const [presencas, setPresencas] = useState([])
    const [assinaturas, setAssinaturas] = useState(null)
    const params = useParams();
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Formador-coursesPresencas-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const [hasCheckedPresencas, setHasCheckedPresencas] = useState(false);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Formadores-coursesPresencas-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)

    let token
    if (localStorage.getItem('user')) token = "user"
    else if (localStorage.getItem('coord')) token = "coord"
    else if (localStorage.getItem('admin1')) token = "admin1"
    else if (localStorage.getItem('admin2')) token = "admin2"

    useEffect(() => {
        Cookies.set("Formador-coursesPresencas-Pag", paginaAtual);
        Cookies.set(`Formadores-coursesPresencas-itm1`, itemsPag)
        getEvento()
        getFormandos()

    }, [loading, cont, presencas, paginaAtual, nPaginas, itemsPag]);

    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }


    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }

    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };

    //Obtem formandos
    function getFormandos() {
        fetch(`${process.env.REACT_APP_API_URL}/calendar/${params.id_ufcds}/formandos`, {
            headers: {
                'authorization': localStorage.getItem(token)
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setAssinaturas(result.ufcds.percurso[0].assinaturas)
                    const array = [];
                    const set = new Set();
                    result.formandos.forEach(element => {
                        const isValid = element.valid === "Validado" || 
                                        (element.valid === "Desistiu" && new Date(data2.start) <= new Date(element.desistencia[0].dataDesistencia));
                                        
                        const hasNoNInscricaoOrEquivalencia = (!element.nInscricao || !element.nInscricao.some(n => n.idUfcd === params.id_ufcds)) && 
                                                              (!element.equivalencia || !element.equivalencia.some(n => n.idUfcd === params.id_ufcds));
                    
                        if (isValid && !set.has(element) && hasNoNInscricaoOrEquivalencia) {
                            array.push(element);
                            set.add(element);
                        }
                    });
                    // Sorting the array by fullName
                    array.sort((a, b) => {
                        if (a.fullName < b.fullName) return -1;
                        if (a.fullName > b.fullName) return 1;
                        return 0;
                    });
                    setData(array);
                    paginacaoTabelas(array);
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                }
            );
    }

    function adicionarPresenca(e, id, i) {
        if (assinaturas === true) {
            if (e.target.checked) {
                // Check if the object {formando: id, presenca: i} exists in presencas
                const exists = presencas.some((item) => item.formando === id && item.presenca === i);

                if (!exists) {
                    // If it doesn't exist, add it to the array
                    presencas.push({ formando: id, presenca: i });
                    setCont(cont + 1);
                }
            } else {
                // Find the index of the object to remove
                const index = presencas.findIndex((item) => item.formando === id && item.presenca === i);

                if (index !== -1) {
                    // If it exists, remove it from the array
                    presencas.splice(index, 1);
                    setCont(cont + 1);
                }
            }
        }
        else {
            if (e.target.checked) {
                // Check if the object {formando: id, presenca: i} exists in presencas
                const exists = presencas.some((item) => item.formando === id);

                if (!exists) {
                    // If it doesn't exist, add it to the array
                    presencas.push({ formando: id });
                    setCont(cont + 1);
                }
            } else {
                // Find the index of the object to remove
                const index = presencas.findIndex((item) => item.formando === id);

                if (index !== -1) {
                    // If it exists, remove it from the array
                    presencas.splice(index, 1);
                    setCont(cont + 1);
                }
            }
        }
    }
    async function checkPresencas(id, i) {
        if (data2) {
            let index = data2.presencas.some((item) => item.formando === id && item.presenca === i);
            if (index) {
                presencas.push({ formando: id, presenca: i });
                setCont(cont + 1);
                return true;
            } else return false;
        }
        else setCont(cont + 1)
    }
    function adicionarPresencas() {

        fetch(`${process.env.REACT_APP_API_URL}/calendar/presenca/${params.id_evento}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({
                    formando: presencas

                })
            })
            .then(res => res.json())
            .then((result) => {

                toastSuccess("Presenças inseridas com Sucesso")
            })
    }

    function adicionarRemoverTodasPresencas(i) {
        if (assinaturas === true) {
            if (presencas.filter(item => item.presenca === i).length < data.length) {
                // Add all items for the given id if they don't exist already
                const toAdd = [];
                data.forEach(element => {
                    const exists = presencas.some(item => item.formando === element._id && item.presenca === i);
                    if (!exists) {
                        toAdd.push({ formando: element._id, presenca: i });
                    }
                })
                presencas.push(...toAdd);
            } else {
                setPresencas(presencas.filter(item => item.presenca !== i))
            }
        } else {
            if (presencas.length < data.length) {
                const toAdd = [];
                // Add the item for the given id if it doesn't exist already
                data.forEach(element => {
                    const exists = presencas.some(item => item.formando === element._id);
                    if (!exists) {
                        toAdd.push({ formando: element._id });
                    }
                })
                presencas.push(...toAdd);
            } else {
                setPresencas([])
            }
        }
        setCont(cont + 1);
    }

    function calculateHoursDifference(timestamp1, timestamp2) {
        const date1 = new Date(timestamp1);
        const date2 = new Date(timestamp2);

        // Calculate the time difference in milliseconds
        const timeDifferenceInMilliseconds = date1 - date2;

        // Convert milliseconds to hours and round to the nearest whole number
        const hoursDifference = Math.round(timeDifferenceInMilliseconds / (1000 * 60 * 60));

        return hoursDifference;
    }
    async function getEvento() {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/calendar/${params.id_evento}`, {
                headers: {
                    'authorization': localStorage.getItem(token)
                }
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch data. Status: ${response.status}`);
            }

            const result = await response.json();
            setData2(result); // Await here to make sure it's set before processing

            setDuration(calculateHoursDifference(result.end, result.start));
            setDisciplina(result.ufcds[0]);
            setLink(result.urlFormando);
            setLoading(false);
            if (data2) {
                if (!hasCheckedPresencas) {
                    for (const element of result.presencas) {
                        await checkPresencas(element.formando, element.presenca);
                    }
                    setHasCheckedPresencas(true);
                }
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }

    function addHoursToTimestamp(timestamp, hoursToAdd) {
        const date = new Date(timestamp);

        // Add the specified number of hours
        date.setHours(date.getHours() + hoursToAdd);

        // Get the updated hours and minutes
        const updatedHours = String(date.getHours()).padStart(2, '0');
        const updatedMinutes = String(date.getMinutes()).padStart(2, '0');

        // Combine hours and minutes in the HH:MM format
        const updatedTimestamp = `${updatedHours}:${updatedMinutes}`;

        return updatedTimestamp;
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }
    return (
        <>
            <main className="min-h-screen">
                <Header />
                <div className="flex-grow">
                    {data2 && <h1 className="mt-8 mb-1 title">{disciplina.name} - {data2.title} </h1>}
                    <div className="mt-5">
                        <h2 className="subtitle">Lista de Presenças</h2>
                        {data && data.length > 0 ? <>
                            <div className="flex items-center justify-center mb-1">
                                <label className="text-text-main-color">Dados por página: </label>
                                <select onWheel={(e) => e.target.blur()} className="ml-1 selector w-14"
                                    value={itemsPag}
                                    onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div className="table-containerSideMenu">
                                <table className="my-table">

                                    <thead className="bg-main-color">
                                        <tr className="text-white h-14">
                                            <th className="p-2 min-w-[150px] border border-white">Nome Completo</th>
                                            {assinaturas === false ? <th className="p-2 min-w-[80px] border border-white">Presença<br />
                                                <input type="checkbox" checked={presencas.length === data.length} onChange={(e) => adicionarRemoverTodasPresencas()} className="mt-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" /></th> : <>
                                                {
                                                    Array(duration).fill().map((_, i) => (

                                                        <th className="p-2 min-w-[80px] border border-white">Presença<br />{addHoursToTimestamp(data2.start, i)}<br /> <input type="checkbox" checked={presencas.filter(item => item.presenca === i).length === data.length} onChange={(e) => adicionarRemoverTodasPresencas(i)} className="mt-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" /></th>
                                                    ))
                                                }
                                            </>}
                                        </tr>
                                    </thead>
                                    {data && (
                                        <tbody className="text-text-second-color">
                                            {dataToDisplay.map((item, index) => (<>
                                                {assinaturas === false ? <tr className="h-14" key={index}>
                                                    <td className="border border-white ufcd">{item.fullName}</td>
                                                    <td className="border border-white ufcd">
                                                        <input type="checkbox" checked={presencas.some((presenca) => presenca.formando === item._id)} onChange={(e) => adicionarPresenca(e, item._id)} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" />
                                                    </td>
                                                </tr> : <tr className="h-14" key={index}>
                                                    <td className="border border-white ufcd">{item.fullName}</td>
                                                    {Array(duration).fill().map((_, i) => (
                                                        <td className="border border-white ufcd" key={i}>

                                                            <input type="checkbox" checked={presencas.some((presenca) => presenca.formando === item._id && presenca.presenca === i)} onChange={(e) => adicionarPresenca(e, item._id, i)} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" />
                                                        </td>
                                                    ))}
                                                </tr>}</>
                                            ))}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                            <div className="flex items-center justify-center mt-4 mb-8">
                                <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                    <AiOutlineDoubleLeft />
                                </button>
                                <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                    <AiOutlineLeft />
                                </button>
                                <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                                <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                    <AiOutlineRight />
                                </button>
                                <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                    <AiOutlineDoubleRight />
                                </button>
                            </div> <button className="my-3 button-geral w-fit" onClick={adicionarPresencas}>Submeter</button></> : <p className="third-title">Não existem dados</p>}

                    </div>
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main>


        </>


    );
}

export default ShowUfcd;
