import { useNavigate } from 'react-router-dom';
//Fechar sessão
function Logout(token) {

    // Split cookies into an array
    const cookies = document.cookie.split(';');

    // Loop through the cookies and delete them
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }

    // Clear local storage
    localStorage.removeItem(token);
    localStorage.removeItem("id");
    localStorage.removeItem("TipoAdmin");
    // Redirect to the home page
    

    return null; // You can return null since this component doesn't render anything
}

export default Logout;
