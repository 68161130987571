import React, { useState, useEffect } from "react";
import Header from "../../../../../../../../components/Header";
import { useParams, Link, useNavigate } from 'react-router-dom'
import Footer from "../../../../../../../../components/Footer";
import NavbarFormador from "../../../../../../../../components/Navbars/NavbarFormador/navbarFormador";
import toastError from "../../../../../../../../components/Toast/toastError";
import toastSuccess from "../../../../../../../../components/Toast/toastSuccess";

function ShowUfcd() {
    const [data, setData] = useState(null);
    const [percurso, setPercurso] = useState(null);
    const [duration, setDuration] = useState(null);
    const [eventos, setEventos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [cont, setCont] = useState(0)
    const params = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        getPercurso()
        getEvento()
        getUfcd()
    }, []);

    //Obtem percurso
    function getPercurso() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}`, {
            headers: {
                'authorization': localStorage.getItem('user')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setPercurso(result);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                }
            );
    }

    function getEvento() {
        fetch(`${process.env.REACT_APP_API_URL}/calendar/${params.id_evento}`, {
            headers: {
                'authorization': localStorage.getItem('user')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                }
            );
    }

    function getUfcd() {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/${params.id_ufcds}/trainer`, {
            headers: {
                'authorization': localStorage.getItem('user')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    // Create a temporary object to store unique events using 'name' as the key
                    const uniqueEvents = {};
                    result.eventos.forEach(element => {
                        const eventName = element.title;
                        if (!uniqueEvents[eventName]) {
                            uniqueEvents[eventName] = element;
                        }
                        else {
                            uniqueEvents[eventName].end = element.end
                        }
                    });

                    // Convert the temporary object back to an array of unique events
                    const uniqueEventsArray = Object.values(uniqueEvents);
                    uniqueEventsArray.forEach(element => {
                        if (element._id === params.id_evento) {
                            const startTime = new Date(element.start); // Convert element.start to a Date object
                            const endTime = new Date(element.end); // Convert element.end to a Date object

                            const durationInMilliseconds = endTime - startTime;
                            const hours = durationInMilliseconds / (1000 * 60 * 60);
                            setDuration(hours)
                        }
                    });
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                }
            );
    }

    async function submeter(event) {
        event.preventDefault();

        //Introdução
        const especificosValueI = document.getElementById("esp-I").value;
        const conteudosValueI = document.getElementById("cont-I").value;
        const metodosValueI = document.getElementById("met-I").value;
        const recursosValueI = document.getElementById("rec-I").value;
        const tempoValueI = document.getElementById("tem-I").value;
        const avaliacaoValueI = document.getElementById("ava-I").value;

        //Desenvolvimento
        const especificosValueD = document.getElementById("esp-D").value;
        const conteudosValueD = document.getElementById("cont-D").value;
        const metodosValueD = document.getElementById("met-D").value;
        const recursosValueD = document.getElementById("rec-D").value;
        const tempoValueD = document.getElementById("tem-D").value;
        const avaliacaoValueD = document.getElementById("ava-D").value;

        //Conclusão
        const especificosValueC = document.getElementById("esp-C").value;
        const conteudosValueC = document.getElementById("cont-C").value;
        const metodosValueC = document.getElementById("met-C").value;
        const recursosValueC = document.getElementById("rec-C").value;
        const tempoValueC = document.getElementById("tem-C").value;
        const avaliacaoValueC = document.getElementById("ava-C").value;

        const gerais = document.getElementById("gerais").value;

        const introducao = {
            especificos: especificosValueI,
            conteudos: conteudosValueI,
            metodos: metodosValueI,
            recursos: recursosValueI,
            tempo: tempoValueI,
            avaliacao: avaliacaoValueI
        }
        const desenvolvimento = {
            especificos: especificosValueD,
            conteudos: conteudosValueD,
            metodos: metodosValueD,
            recursos: recursosValueD,
            tempo: tempoValueD,
            avaliacao: avaliacaoValueD
        }
        const conclusao = {
            especificos: especificosValueC,
            conteudos: conteudosValueC,
            metodos: metodosValueC,
            recursos: recursosValueC,
            tempo: tempoValueC,
            avaliacao: avaliacaoValueC
        }
        const tempoTotal = parseInt(introducao.tempo) + parseInt(desenvolvimento.tempo) + parseInt(conclusao.tempo)
        if (tempoTotal !== (duration * 60)) {
            toastError("Errou Miserável")
        }
        else {
            setLoading(true)
            fetch(`${process.env.REACT_APP_API_URL}/percurso/PlanodeSessao/${params.id_curso}/${params.id_candidatura}/${params.id_evento}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem('user')
                },
                body: JSON.stringify({
                    gerais: gerais,
                    introducao: introducao,
                    desenvolvimento: desenvolvimento,
                    conclusao: conclusao,
                    dataEvento: convertDateString(data.start),
                    duration: duration,
                    nSessao: extractNumbersFromString(data.title),
                })
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    } else {
                        throw new Error('Network response was not ok.');
                    }
                })
                .then((result) => {
                    // Handle the successful response
                    downloadFile(result.sessao_path)
                    setLoading(false)
                })
                .catch((error) => {
                    // Handle errors
                    console.error('Error:', error);
                });
        }
    }

    //Download do ficheiro
    function downloadFile(url) {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank'); // Opens the link in a new tab/window
        link.setAttribute('download', ''); // Optional, sets the download attribute to trigger download in some browsers
        link.click();
    }

    function convertDateString(dateTimeString) {
        const dateTime = new Date(dateTimeString);
        // Extract date and time parts
        const date = dateTime.toLocaleDateString(); // Format: MM/DD/YYYY
        return date;
    }
    function extractNumbersFromString(inputString) {
        const numbersInString = inputString.match(/\d+/g);
        return numbersInString ? Number(numbersInString[0]) : null;
    }


    if (loading) return (
        <div className="bg-secundary-color h-screen w-screen flex justify-center items-center">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";
    const checked = document.getElementById('check');
    return (
        <>
            <main className="md:grid sm:grid-cols-10 min-h-screen mx-auto">
                <div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    <NavbarFormador currentPage={"cursos"} />
                </div>

                <div className="back-button-noNavbar">
                    <Link to={"/menutrainers/" + params.id_candidatura + "/cursos/" + params.id_curso + "/Ufcds/" + params.id_ufcds + "/eventos"} className="flex w-8 h-8">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>
                </div>

                <div className="sm:col-span-8 h-full">
                    <Header sideMenu={"true"} />
                    {data && <h1 className="title max-w-full"> Plano de Sessão {data.ufcds[0].codeUfcd} - {data.ufcds[0].name} - {data.title} </h1>}
                    <div className="p-2 bg-secundary-color grid grid-cols-2 mx-8 ">
                        {percurso &&
                            <>
                                <div>
                                    <div className="flex">
                                        <div className="text-main-color font-bold uppercase">Modalidade de Formação:</div>
                                        <div className="pl-1 text-text-second-color">{percurso.modalidade}</div>
                                    </div>
                                    <div className="flex">
                                        <div className="text-main-color font-bold uppercase">Área de Formação: </div>
                                        <div className="pl-1 text-text-second-color">{percurso.areaCodigoFormacao}</div>
                                    </div>
                                    <div className="flex">
                                        <div className="text-main-color font-bold uppercase">Designação do Curso/Módulo/Ufcd:</div>
                                        <div className="pl-1 text-text-second-color">{percurso.name}</div>
                                    </div>
                                    <div className="flex">
                                        <div className="text-main-color font-bold uppercase">Ação nº:</div>
                                        <div className="pl-1 text-text-second-color"></div>
                                    </div>
                                    <div className="flex">
                                        <div className="text-main-color font-bold uppercase">Nível:</div>
                                        <div className="pl-1 text-text-second-color">{percurso.nivel}</div>
                                    </div>
                                </div>
                                <div>


                                    <div className="flex">
                                        <div className="text-main-color font-bold uppercase">Horas: </div>
                                        <div className="pl-1 text-text-second-color">{percurso.duration}</div>
                                    </div>
                                    <div className="flex">
                                        <div className="text-main-color font-bold uppercase">Local: </div>
                                        <div className="pl-1 text-text-second-color">{percurso.locality}</div>
                                    </div>
                                    <div className="flex">
                                        <div className="text-main-color font-bold uppercase">Data: </div>
                                        {data && <div className="pl-1 text-text-second-color">{convertDateString(data.start)}</div>}
                                    </div>
                                    <div className="flex">
                                        <div className="text-main-color font-bold uppercase">Nº da Sessão: </div>
                                        {data && <div className="pl-1 text-text-second-color">{extractNumbersFromString(data.title)}</div>}
                                    </div>
                                    <div className="flex">
                                        <div className="text-main-color font-bold uppercase">Duração da Sessão: </div>
                                        {duration && <div className="pl-1 text-text-second-color">{duration}h</div>}
                                    </div>
                                </div>

                            </>}

                    </div>
                    {data && <form className="my-4" action="PlanodeSessao">
                        <div className="subtitle uppercase mb-0">Geral</div>
                        <div>
                            <div className="third-title">Objetivos Gerais</div>
                            <textarea defaultValue={data.gerais} id="gerais" className="input-field w-72 h-24 min-h-16" type="text" />
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-3 mx-auto">
                            <div>
                                <div className="subtitle uppercase mb-0">Introdução</div>
                                <div>
                                    <div className="third-title">Objetivos especificos:</div>
                                    <textarea defaultValue={data.introducao.especificos} id="esp-I" className="input-field w-72 h-24 min-h-16" type="text" />
                                </div>
                                <div>
                                    <div className="third-title">Conteúdos:</div>
                                    <textarea defaultValue={data.introducao.conteudos} id="cont-I" className="input-field w-72 h-24 min-h-16" type="text" />
                                </div>
                                <div>
                                    <div className="third-title">Métodos e Técnicas Pedagógicas:</div>
                                    <textarea defaultValue={data.introducao.metodos} id="met-I" className="input-field w-72 h-24 min-h-16" type="text" />
                                </div>
                                <div>
                                    <div className="third-title">Recursos Técnico Pedagógicos:</div>
                                    <textarea defaultValue={data.introducao.recursos} id="rec-I" className="input-field w-72 h-24 min-h-16" type="text" />
                                </div>
                                <div>
                                    <div className="third-title">Tempo (minutos):</div>
                                    <input defaultValue={data.introducao.tempo} id="tem-I" className="input-field" min="1" type="number" />
                                </div>
                                <div>
                                    <div className="third-title">Avaliação:</div>
                                    <textarea defaultValue={data.introducao.avaliacao} id="ava-I" className="input-field w-72 h-24 min-h-16" type="text" />
                                </div>
                            </div>
                            <div>
                                <div className="subtitle uppercase mb-0">Desenvolvimento</div>
                                <div>
                                    <div className="third-title">Objetivos especificos:</div>
                                    <textarea defaultValue={data.desenvolvimento.especificos} id="esp-D" className="input-field w-72 h-24 min-h-16" type="text" />
                                </div>
                                <div>
                                    <div className="third-title">Conteúdos:</div>
                                    <textarea defaultValue={data.desenvolvimento.conteudos} id="cont-D" className="input-field w-72 h-24 min-h-16" type="text" />
                                </div>
                                <div>
                                    <div className="third-title">Métodos e Técnicas Pedagógicas:</div>
                                    <textarea defaultValue={data.desenvolvimento.metodos} id="met-D" className="input-field w-72 h-24 min-h-16" type="text" />
                                </div>
                                <div>
                                    <div className="third-title">Recursos Técnico Pedagógicos:</div>
                                    <textarea defaultValue={data.desenvolvimento.recursos} id="rec-D" className="input-field w-72 h-24 min-h-16" type="text" />
                                </div>
                                <div>
                                    <div className="third-title">Tempo (minutos):</div>
                                    <input defaultValue={data.desenvolvimento.tempo} id="tem-D" className="input-field" min="1" type="number" />
                                </div>
                                <div>
                                    <div className="third-title">Avaliação:</div>
                                    <textarea defaultValue={data.desenvolvimento.avaliacao} id="ava-D" className="input-field w-72 h-24 min-h-16" type="text" />
                                </div>
                            </div>
                            <div>
                                <div className="subtitle uppercase mb-0">Conclusão</div>
                                <div>
                                    <div className="third-title">Objetivos especificos:</div>
                                    <textarea defaultValue={data.conclusao.especificos} id="esp-C" className="input-field w-72 h-24 min-h-16" type="text" />
                                </div>
                                <div>
                                    <div className="third-title">Conteúdos:</div>
                                    <textarea defaultValue={data.conclusao.conteudos} id="cont-C" className="input-field w-72 h-24 min-h-16" type="text" />
                                </div>
                                <div>
                                    <div className="third-title">Métodos e Técnicas Pedagógicas:</div>
                                    <textarea defaultValue={data.conclusao.metodos} id="met-C" className="input-field w-72 h-24 min-h-16" type="text" />
                                </div>
                                <div>
                                    <div className="third-title">Recursos Técnico Pedagógicos:</div>
                                    <textarea defaultValue={data.conclusao.recursos} id="rec-C" className="input-field w-72 h-24 min-h-16" type="text" />
                                </div>
                                <div>
                                    <div className="third-title">Tempo (minutos):</div>
                                    <input defaultValue={data.conclusao.tempo} id="tem-C" className="input-field" min="1" type="number" />
                                </div>
                                <div>
                                    <div className="third-title">Avaliação:</div>
                                    <textarea defaultValue={data.conclusao.avaliacao} id="ava-C" className="input-field w-72 h-24 min-h-16" type="text" />
                                </div>
                            </div>
                        </div>
                        <button className="bg-main-color text-white w-40 text-lg p-2 my-2 mt-4 rounded-md" type="submit" onClick={submeter}> Submeter </button>
                    </form>}

                </div>
            </main>
            <Footer />
        </>


    );
}

export default ShowUfcd;
