import React, { useState, useEffect } from "react";
import { UFCDs } from "./styled";
import { Link, useNavigate } from 'react-router-dom'
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import ConfirmToaster from "../../../components/Toast/toastConfirmChoice";
import toastSuccess from "../../../components/Toast/toastSuccess";
//import toastError from "../../../components/Toast/toastError";

function ShowUfcd() {
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");

    //filtra dados
    function filterData(data) {
        return data.filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.codeUfcd.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.percurso[0].name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }

    useEffect(() => {
        getUfcds()
    }, [loading]);

    const handleClick = (event, item) => {
        // Check if the clicked element is a <td>
        if (event.target.tagName === 'TD') {
            // Check if the <td> has a 'clickable' class
            if (!event.target.classList.contains('ufcd')) {
                // Navigate to the desired location
                // Replace 'destination' with your actual navigation logic
            }
            else navigate("/MenuSuperAdmin/UFCDs/code-ufcds/" + item.codeUfcd)
        }
    };

    //Recebe todas as ufcds
    function getUfcds() {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds`, {
            headers: {
                'authorization': localStorage.getItem('admin2')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                    setLoading(false);
                }
            );
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    //Adiciona uma nova ufcd
    /* function submeterUfcd(e) {
        e.preventDefault()

        let ufcd = {

            codeUfcd: document.querySelector("#codeUfcd").value.trim(),
            name: document.querySelector("#name").value.trim(),
            courseCode: document.querySelector("#courseCode").value.trim(),
        }

        if (!ufcd.codeUfcd || !ufcd.name || !ufcd.courseCode) {
            toastError("Alguns dos campos não foram inseridos")
            return;
        }

        fetch(`${process.env.REACT_APP_API_URL}/ufcds`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'authorization': localStorage.getItem('admin2') },
                body: JSON.stringify(ufcd)
            })
            .then(res => res.json())
            .then(
                (result) => {
                    toastSuccess('Disciplinas inserida com sucesso!')
                    getUfcds()
                },
                (error) => {
                    console.error(error)
                }
            )
    } */

    //Elimina uma ufcd pelo seu id
    function deleteUfcd(_id) {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/${_id}`, {
            method: 'DELETE',
            headers: {
                'authorization': localStorage.getItem('admin2')
            }
        }).then((result) => {
            result.json().then((resp) => {

                toastSuccess('Disciplinas removida com sucesso!')
                getUfcds()
            })
        })
    }

    return (
        <>
            <Header />
            <UFCDs>
                <main>
                    <h1>Lista de disciplinas</h1>
                    <div className="search">
                        <label className="pesquisar"> Pesquisar: </label>
                        <img className='lupa' alt="Lupa" src={process.env.PUBLIC_URL + "/img/search.svg"} />
                        <input type="text" className="search-input" placeholder="ex: Nome, Código..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Código Disciplina</th>
                                <th>Nome</th>
                                <th>Nome do curso</th>
                                <th>Remover</th>
                            </tr>
                        </thead>
                        {data.length > 0 ? <tbody>
                            {filterData(data).map((item, index) => (
                                <tr key={index} className="row-click" onClick={(e) => handleClick(e, item)}>
                                    <td className="ufcd">{item.codeUfcd}</td>
                                    <td className="ufcd">{item.name}</td>
                                    {item.percurso[0] ? <td className="ufcd">{item.percurso[0].name}</td> : <td>Sem curso</td>}
                                    <td>
                                        <ConfirmToaster css={"remove-ufcd"} icon={"-"} title={"Remover"} message={"Tem a certeza que deseja eliminar a disciplina ? (Se a eliminá-la perderá o acesso a esta.)"} confirm={deleteUfcd} id={item._id} tooltip={"Remover Disciplina"}></ConfirmToaster>
                                    </td>
                                </tr>
                            ))}
                        </tbody> : <tbody />}
                    </table>
                    <br />
                    <Link to={'/MenuSuperAdmin'} className="back-button-link">
                        <img className='back-button' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>
                </main>
            </UFCDs>
            <Footer />
        </>
    );
}

export default ShowUfcd;
