import React, { useRef, forwardRef } from 'react';
const EventBox = forwardRef(({ position, eventInfo, onClose, onLinkClick }, ref) => {
  const startInUTC = new Date(eventInfo.start.getTime() + eventInfo.start.getTimezoneOffset() * 60 * 1000);
  const startHours = startInUTC.getHours();
  let startMin = startInUTC.getMinutes();
  if (startMin === 0) {
    startMin = "00"
  }
  const endInUTC = new Date(eventInfo.end.getTime() + eventInfo.end.getTimezoneOffset() * 60 * 1000);
  const endHours = endInUTC.getHours();
  let endMin = endInUTC.getMinutes();
  if (endMin === 0) {
    endMin = "00"
  }
  //Fechar tooltip do calendario caso tire o rato de cima
  const handleEventBoxMouseLeave = (event) => {
    const { clientX, clientY } = event;
    const boxRect = ref.current.getBoundingClientRect();
    if (
      clientX < boxRect.left ||
      clientX > boxRect.right ||
      clientY < boxRect.top ||
      clientY > boxRect.bottom
    ) {
      onClose();
    }
  }
  return (
    <div onClick={() => onLinkClick(eventInfo.url)}
      ref={ref}
      className="event-box"
      style={{
        position: 'fixed',
        left: position.left + 1,
        top: position.top + 1,
        backgroundColor: '#8994e799',
        color: 'white',
        padding: '5px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        zIndex: 9999,
      }}
      onMouseLeave={handleEventBoxMouseLeave}
    >
      <h3>{eventInfo.title}</h3>
      <p>Início: {startHours}:{startMin}</p>
      <p>Fim: {endHours}:{endMin}</p>
      <p>{eventInfo.extendedProps.description}</p>
    </div>
  );
});

export default EventBox;
