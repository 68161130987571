import styled from "styled-components";

export const MenuFormando = styled.div`
    
font-family: "Lato";

main{
  min-height: 100vh;
  margin-top: 0vh !important;
}

.page {  
  height:100vh;
  width:100%;
  display: grid;
  grid-template-columns: 0.4fr 1.6fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "navbar content content"
    "navbar content content"
    "navbar content content";   
}

.navbar { grid-area: navbar; }

.content { 
  display:flex;
  justify-content:center;
  margin: 10vh ;
  grid-area: content; 
} 

.navbar {
background-color: #e4e4e4;
border-radius: 12px;
}

.App{
    margin-top: 10vh;
    width: 800px;
  }
  
  table, td, th{
    border: 1px solid black;
    background-color: white
  }

}  
`;
