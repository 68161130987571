export default function ArraySituacaoEmprego() {
    const  SituacaoEmpregoArray = [
        "6 - Desempregados à procura de novo emprego - DLD",
        "7 - Desempregados à procura de novo emprego - Não DLD",
        "5 - Desempregados à procura do 1º emprego",
        "2 - Empregados por conta de outrem - Externos",
        "1 - Empregados por conta de outrem - Internos",
        "3 - Empregados por conta própria",
        "8 - Inativos - A frequentarem acções de educação ou formação",
        "9 - Inativos - Outros"
    ];
    return  SituacaoEmpregoArray;
}