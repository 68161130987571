export default async function DownloadFile(url) {
    try {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');

        if ('download' in link) {
            link.setAttribute('download', '');
        }

        document.body.appendChild(link); // Append the link to the DOM

        await new Promise(resolve => setTimeout(resolve, 400)); // Wait for 400ms

        link.click();
        document.body.removeChild(link); // Remove the link from the DOM after the click
    } catch (error) {
        console.error('Error downloading file:', error);
    }
}