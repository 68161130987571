import styled from "styled-components";

export const AreaPortalFormandos = styled.div`
    
margin-top: 23vh;

.button-menu{
    min-height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border: 1px solid rgb(27, 152, 255);
    color: rgb(27, 152, 255);
    border-radius: 50px;
    margin: 1em 0;
    background-color: white;
    text-align: center;
    font-size: 16px;
    font-family: "Lato";
    padding:0;
}
.button-menu:hover{
    color: white;
    border: 1px solid #1b98ff;
    font-weight: bold;
    background-color: #1b98ff;
    cursor: pointer;
}

a{
    top: -110px
}
`