import React, { useEffect, useState } from "react";
import { BiSolidCookie } from "react-icons/bi";
//Mensagem de aceitar ou rejeitar cookies
const CookiesDisclaimer = () => {
  const [accepted, setAccepted] = useState("");

  //Função caso o user aceite cookies
  const handleAcceptCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setAccepted(true);
  };
  //Função caso o user rejeite cookies
  const handleRejectCookies = () => {
    localStorage.setItem("cookiesAccepted", "false");
    setAccepted(false);
  };
  
  useEffect(()=> {
    
  },[accepted])

  if (localStorage.getItem("cookiesAccepted")) {
    return null; // Don't render the disclaimer if already accepted
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="flex flex-col items-center max-w-sm px-8 mx-5 my-5 shadow-2xl bg-secundary-color py-7 rounded-2xl max-h-128 sm:max-h-156">
        <BiSolidCookie className="mb-5 text-main-color" size={50} />
        <h1 className="mb-2 subtitle">Política de cookies</h1>
        <p className="text-justify text-text-main-color">Este site utiliza cookies para melhorar a sua experiência de navegação.<br /> Ao continuar a utilizar este site, você concorda com o nosso uso de cookies.</p>
        <div className="flex justify-between w-full">
          <button className="w-24 mt-4 button-cancelar" onClick={handleRejectCookies}>
            Rejeitar
          </button>
          <button className="w-24 mt-4 button-geral" onClick={handleAcceptCookies}>
            Aceito
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookiesDisclaimer;
