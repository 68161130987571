import React, { useState, useEffect, useRef } from "react";
import { MenuFormando } from "./styled";
import { useParams } from 'react-router-dom'
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ptLocale from '@fullcalendar/core/locales/pt';
import Modal from 'react-modal'
import 'react-toastify/dist/ReactToastify.css';
import './modal.css'
import NavbarFormador from "../../../../components/Navbars/NavbarFormador/navbarFormador";
import EventBox from "../../../../components/eventBox/eventBox";

Modal.setAppElement('#root');

function ShowMenuFormando() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [events, setEvents] = useState([]);
  const [eventBoxPosition, setEventBoxPosition] = useState({ left: 0, top: 0 });
  const [eventInfo, setEventInfo] = useState(null);
  const [currentView, setCurrentView] = useState('dayGridMonth');
  const eventBoxRef = useRef(null);
  const params = useParams();

  // Fetch events
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/calendar/formador/${params.id_candidatura}`, {
      headers: {
        authorization: localStorage.getItem('user'),
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setEvents(result);
        },
        (error) => {
          console.error('Error fetching data: ', error);
          setError(error);
        }
      )
      .finally(() => setLoading(false));
  }, [params.id_candidatura]);

  const handleEventMouseEnter = (arg) => {
    if (arg.view.type === 'dayGridMonth' && !eventInfo) {
      // When the cursor enters an event and the event box is not already open,
      // show the event box and set the event information
      setEventBoxPosition({ left: arg.jsEvent.pageX, top: arg.jsEvent.pageY });
      setEventInfo(arg.event);
    }
  };

  const handleEventMouseLeave = () => {
    setEventInfo(null);
  };

  const handleLinkClick = (info) => {
    info.jsEvent.preventDefault();
    window.open(`/#/Presencas/${info.event._def.extendedProps.ufcds[0]}/${info.event._def.extendedProps._id}`, '_blank');
  };

  const handleViewChange = (view) => {
    setCurrentView(view);
  };

  return (
    <>
      <main className="md:grid grid-cols-7 xl:grid-cols-10 mx-auto min-h-screen">
        <div className='h-full sm:col-span-2 md:bg-secundary-color bg-opacity-60'>
          <NavbarFormador currentPage={"horario"} />
        </div>

        <div className="col-span-5 xl:col-span-8 h-full w-full">
          <Header sideMenu={"true"} />
          <MenuFormando>

            <div className="mt-8">
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                locale={ptLocale}
                timeZone="UTC"
                allDayText="Dia"
                themeSystem="standard"
                aspectRatio="1.5"
                initialView={currentView}
                headerToolbar={{
                  left: 'dayGridMonth,timeGridWeek,timeGridDay',
                  center: 'title',
                  end: 'today prev,next',
                }}
                events={events}
                eventClick={function (info) {
                  info.jsEvent.preventDefault();
                  window.open(`/#/Presencas/${info.event._def.extendedProps.ufcds[0]}/${info.event._def.extendedProps._id}`, '_blank');
                }}
                eventMouseEnter={handleEventMouseEnter}
                eventMouseLeave={handleEventMouseLeave}
                viewDidMount={(view) => setCurrentView(view.type)}
                datesSet={(view) => setCurrentView(view.view.type)}
              />
              {eventInfo && (
                <EventBox
                  ref={eventBoxRef}
                  position={eventBoxPosition}
                  eventInfo={eventInfo}
                  onLinkClick={handleLinkClick}
                  onClose={handleEventMouseLeave}
                />
              )}
            </div>
          </MenuFormando>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default ShowMenuFormando;