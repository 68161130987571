import Header from "../../../components/Header";
import React, { useState, useEffect, useRef } from "react";
import Footer from '../../../components/Footer'
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal'
import toastSuccess from "../../../components/Toast/toastSuccess";
import toastError from "../../../components/Toast/toastError";
import onlyString from "../../../components/FormChecks/onlyString/onlyString";
import containsPostalCode from "../../../components/FormChecks/containsPostalCode/containsPostalCode";
import { toast } from "react-toastify";
import CountryDropdown from "../../../components/FormChecks/countryDropdown/countryDropdown";
import check_nif_number from "../../../components/FormChecks/checkNif/checkNIf";
import isNumber from "../../../components/isNumber/isNumber";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useSpring, animated } from "react-spring";
import CapitalizarNomes from "../../../components/capitalizeNames/capitalizeNames";
Modal.setAppElement('#root');

//Passos do formulario
const steps = [
    "DADOS DO COORDENADOR",
    "NACIONALIDADE",
    "RESIDÊNCIA",
    "IDENTIFICAÇÃO",
    "DOCUMENTOS",
];

function ShowCoordinators() {
    const [checkStep, setCheckStep] = useState({ section1: false, section2: false, section3: false, section4: false })
    const [section, setSection] = useState(1);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const modoCriacao = "nova"

    const navigate = useNavigate();
    const ref = useRef();
    let Next = process.env.PUBLIC_URL + "/Next.svg"

    //Data atual
    const year = new Date().getFullYear()
    const month = new Date().getMonth()
    const day = new Date().getDate();

    //Filtros data
    const datemin = new Date(0, 0, 1)
    const dateMax = new Date(year - 18, month, day)
    const dateCcMax = new Date(year + 10, month, day)
    const dateToday = new Date(year, month, day)

    const params = useParams();



    //Dados guardados
    // Secção - 0 - TIPO DE COORDENADOR
    const [tipoCoordenador, setTipoCoordenador] = useState("")

    // Secção - 1 - Dados Informartivos
    const [fullName, setFullName] = useState("")
    const [birthDate, setBirthDate] = useState("")
    const [cellNumber, setCellNumber] = useState("")
    const [nif, setNif] = useState("")
    const [mailPersonalizado, setMailPersonalizado] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")

    //Secção - 2 - Dados Registo
    const [email, setEmail] = useState("")

    //Secção - 3  - Dados Naturalidade
    const [parish, setParish] = useState("")
    const [countyNat, setCountyNat] = useState("")
    const [nationality, setNationality] = useState("")

    //Secção - 4 -  Dados Morada
    const [locality, setLocality] = useState("")
    const [address, setAddress] = useState("")
    const [postalCode, setPostalCode] = useState("")
    const [county, setCounty] = useState("")
    const [irs, setIrs] = useState("")
    const [iva, setIva] = useState("")

    //Secção - 5 -  Dados Identificativos
    const [ccNumber, setCcNumber] = useState("")
    const [ccExpiration, setCcExpiration] = useState("")

    useEffect(() => {
        return () => {
            toast.dismiss();
        }
    }, [section])

    //Verificar cc
    function check_pt_cc_number(numero) {
        // Verificar se o número fornecido é uma string com 8 dígitos
        if (typeof numero !== 'string' || numero.length !== 8 || isNaN(numero)) {
            return false;
        } else {
            return true;
        }
    }

    //Proxima secção
    function sectionNext(step) {
        setSection(step);
    }
    //Secção anterior
    function sectionBack(step) {
        setSection(step);
    }
    //Animação fade
    function fade(step) {
        setTimeout(() => {
            document.querySelector('#main-form').classList.remove('fadeInLeft')
            document.querySelector('#main-form').classList.remove('fadeInRight')
            document.querySelector('#main-form').classList.add('fadeOutLeft')
        }, 0);
        setTimeout(() => {
            document.querySelector('#main-form').classList.remove('fadeOutLeft')
            sectionNext(step)
            document.querySelector('#main-form').classList.add('fadeInRight')
        }, 500);
    }

    //Animação fade reverse
    function fadeInRight(step) {
        setTimeout(() => {
            document.querySelector('#main-form').classList.remove('fadeInRight')
            document.querySelector('#main-form').classList.remove('fadeInLeft')
            document.querySelector('#main-form').classList.add('fadeOutRight')
        }, 0);
        setTimeout(() => {
            document.querySelector('#main-form').classList.remove('fadeOutRight')
            sectionBack(step)
            document.querySelector('#main-form').classList.add('fadeInLeft')
        }, 500);
    }

    //Função para mudar a cor caso ja tenha preenchido esse step
    const MultiStepForm = ({ steps, currentStep }) => {

        const percentage = (100 * (currentStep + 1)) / steps.length

        /* Animação do react spring */
        const styles = useSpring({
            from: {
                "--value": 0,
            },
            to: {
                "--value": percentage,
            },
        });

        return (
            <>
                <div className='items-center justify-center hidden xl:flex'>
                    {steps.map((step, index) => (
                        <div key={index} onClick={() => { handleNext(index + 1) }} className='flex items-center justify-center' >
                            <p className={index === currentStep ? 'step-active' : checkStep[`section${index + 1}`] == true ? 'step-done' : 'step-inactive'}>{step}</p>
                            {index !== steps.length - 1 && <i className="m-1 text-gray-600 fas fa-caret-right"></i>}
                        </div>
                    ))}
                </div>

                <div className="flex flex-col items-center justify-center sm:flex-row xl:hidden">{/* Componente abaixo é deste site https://daisyui.com/components/radial-progress/  */}
                    {/* Componente deste bibilioteca https://www.npmjs.com/package/react-spring */}
                    <animated.div className="border radial-progress bg-secundary-color border-text-main-color text-main-color" style={styles}>
                        {currentStep + 1} de {steps.length}
                    </animated.div>
                    <div className="flex flex-col items-center justify-center sm:ml-10 md:ml-32">
                        <div className="text-text-second-color">{steps[currentStep]}</div>
                        {steps[currentStep + 1] ? <div className="text-text-second-color">Próximo: {steps[currentStep + 1]}</div> : ""}
                    </div>
                </div>
            </>
        );
    };
    //Higienização e Continuação
    const handleNext = (newStep) => {
        if (section === 1) {
            if (fullName !== "" && email !== "" && nif !== "" && cellNumber !== "") {
                if (onlyString(fullName)) {
                    fetch(`${process.env.REACT_APP_API_URL}/coordenadores/emails/${email}`)
                        .then((res) => res.json())
                        .then(
                            (result) => {
                                // Verificar se o email tem "@" e o "."
                                let checkEmail = email.includes("@" && ".")
                                if (checkEmail === true && result === true) {
                                    if (isNumber(cellNumber) || cellNumber === "") {
                                        if (cellNumber.length > 8 || cellNumber.length < 13 && cellNumber.length > 0) {
                                            if (check_nif_number(nif)) {
                                                checkStep.section1 = true
                                                if (!newStep) {
                                                    fade(section + 1)
                                                } else {
                                                    if (section < newStep) {
                                                        if (checkStep[`section${newStep - 1}`] == true) {
                                                            fade(newStep)
                                                        } else toastError("Preencha todas as informações necessárias antes de avançar.")
                                                    } else if (section > newStep) {
                                                        fadeInRight(newStep)
                                                    }
                                                }
                                            } else {
                                                toastError("Número de nif inválido.")
                                                checkStep.section1 = false
                                            }
                                        } else {
                                            toastError("Nº de telemóvel inválido ou com caracteres inválidos.")
                                            checkStep.section1 = false
                                        }
                                    } else {
                                        toastError("Nº de telemóvel inválido ou com caracteres inválidos.")
                                        checkStep.section1 = false
                                    }
                                } else {
                                    toastError("Email incorreto ou Email existente")
                                    checkStep.section1 = false
                                }
                            },
                            (error) => {
                                console.error("Error fetching data: ", error);
                            }
                        );
                } else {
                    toastError("Nome inválido");
                    checkStep.section1 = false
                }
            } else {
                toastError("Preencha todos os dados obrigatórios.")
                checkStep.section1 = false
            }
        }
        else if (section === 2) {
            setNationality(document.getElementById("nationality").value);
            if (parish !== "" && document.getElementById("nationality").value !== "0") {
                checkStep.section2 = true
                if (!newStep) {
                    fade(section + 1)
                } else {
                    if (section < newStep) {
                        if (checkStep[`section${newStep - 1}`] == true) {
                            fade(newStep)
                        }
                        else {
                            checkStep.section2 = false
                            if (newStep && newStep < section) {
                                fadeInRight(newStep)
                            } else toastError("Preencha todas as informações necessárias antes de avançar.")
                        }
                    } else if (section > newStep) {
                        fadeInRight(newStep)
                    }

                }
            }
            else {
                checkStep.section2 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else toastError("Preencha todos os dados obrigatórios.")
            }
        }
        else if (section === 3) {
            if (locality !== "" && address !== "" && postalCode !== "" && county !== "") {
                // Verificar se Código postal corresponde a formato "0000-000"
                if (onlyString(county)) {
                    if (onlyString(locality)) {
                        if (containsPostalCode(postalCode)) {
                            checkStep.section3 = true
                            if (!newStep) {
                                fade(section + 1)
                            } else {
                                if (section < newStep) {
                                    if (checkStep[`section${newStep - 1}`] == true) {
                                        fade(newStep)
                                    } else toastError("Preencha todas as informações necessárias antes de avançar.")
                                } else if (section > newStep) {
                                    fadeInRight(newStep)
                                }
                            }
                        } else {
                            checkStep.section3 = false
                            if (newStep && newStep < section) {
                                fadeInRight(newStep)
                            } else {
                                toastError("O Código Postal é inválido.\nEx: 1111-000")
                            }
                        }
                    } else {
                        checkStep.section3 = false
                        if (newStep && newStep < section) {
                            fadeInRight(newStep)
                        } else {
                            toastError("Localidade inválida.")
                        }
                    }
                } else {
                    checkStep.section3 = false
                    if (newStep && newStep < section) {
                        fadeInRight(newStep)
                    } else {
                        toastError("Concelho inválido.")
                    }
                }
            } else {
                checkStep.section3 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else {
                    toastError("Preencha todos os dados obrigatórios.")
                }
            }
        }
        else if (section === 4) {
            if (ccNumber !== "" && ccExpiration !== "" && nif !== "" && irs !== "" && iva !== "") {
                ccExpiration.replace("-", ',')
                const datenow = new Date(ccExpiration)
                if (datenow.getTime() > dateToday.getTime()) {
                    if (datenow.getTime() < dateCcMax.getTime()) {
                        if (check_pt_cc_number(ccNumber)) {
                            if (check_nif_number(nif)) {
                                checkStep.section4 = true
                                if (!newStep) {
                                    fade(section + 1)
                                } else {
                                    if (section < newStep) {
                                        if (checkStep[`section${newStep - 1}`] == true) {
                                            fade(newStep)
                                        } else toastError("Preencha todas as informações necessárias antes de avançar.")
                                    } else if (section > newStep) {
                                        fadeInRight(newStep)
                                    }
                                }
                            } else {
                                checkStep.section4 = false
                                if (newStep && newStep < section) {
                                    fadeInRight(newStep)
                                } else toastError("Número de nif inválido.")
                            }

                        } else {
                            checkStep.section4 = false
                            if (newStep && newStep < section) {
                                fadeInRight(newStep)
                            } else toastError("Número de CC inválido.")
                        }
                    } else {
                        checkStep.section4 = false
                        if (newStep && newStep < section) {
                            fadeInRight(newStep)
                        } else toastError("Data do CC inválida.")
                    }
                } else {
                    checkStep.section4 = false
                    if (newStep && newStep < section) {
                        fadeInRight(newStep)
                    } else toastError("Data do CC caducada.")
                }
            } else {
                checkStep.section4 = false
                if (newStep && newStep < section) {
                    fadeInRight(newStep)
                } else toastError("Preencha todos os dados obrigatórios.")
            }
        }
    }

    //Retroceder 
    const handlePrevious = (step) => {
        fadeInRight(step);
    }

    //transforma data em json
    function formDataToJson(formData) {
        var object = {};
        formData.forEach((value, key) => object[key] = value);
        return JSON.stringify(object);
    }


    //Submete candidatura
    async function submeterCandidatura() {
        let form = document.getElementById("main-form")
        let formDataInfo = new FormData()

        // Secção - 1
        formDataInfo.append('fullName', CapitalizarNomes(fullName))
        formDataInfo.append('birthDate', birthDate)
        formDataInfo.append('cellNumber', cellNumber)
        formDataInfo.append('mailPersonalizado', mailPersonalizado)

        // Secção - 2
        formDataInfo.append('email', email)
        let salt = Math.random().toString(36).slice(2, 6);

        let name;
        let names = fullName.split(" ");
        if (names.length === 1) {
            name = names[0]
        } else {
            let firstName = names[0];
            let lastName = names.pop();
            name = firstName + lastName
        }

        let password = name + salt;
        formDataInfo.append('password', password)
        formDataInfo.append('role', modoCriacao)

        // Secção - 3
        if (nationality === "0") {
            setNationality("");
        }
        formDataInfo.append('nationality', nationality)
        let parishNat = parish + " - " + countyNat
        formDataInfo.append('parish', parishNat)

        //Secção - 4
        formDataInfo.append('locality', locality)
        formDataInfo.append('address', address)
        formDataInfo.append('county', county)
        formDataInfo.append('postalCode', postalCode)
        formDataInfo.append('irs', irs)
        formDataInfo.append('iva', iva)
        // Secção - 5 
        formDataInfo.append('ccNumber', ccNumber)
        formDataInfo.append('ccExpiration', ccExpiration)
        formDataInfo.append('nif', nif)

        //Secção - 6 -  Documentos
        if (tipoCoordenador === "externo") {
            const cc = form.cc.files[0]
            const cv = form.cv.files[0]
            const ccp = form.ccp.files[0]
            const ch = form.ch.files[0]
            const iban = form.iban.files[0]

            const options = {
                method: "POST",
                headers: { "Accept": "application/json" },
                credentials: "same-origin"
            }

            if (cc) {
                let ccForm = new FormData()
                ccForm.append('cc', cc)
                options.body = ccForm
                const ccResponse = await fetch(`${process.env.REACT_APP_API_URL}/mcc`, options)
                const ccResult = await ccResponse.json()
                formDataInfo.append('cc', ccResult)
            }


            let cvForm = new FormData()
            cvForm.append('cv', cv)
            options.body = cvForm
            const cvResponse = await fetch(`${process.env.REACT_APP_API_URL}/mcv`, options)
            const cvResult = await cvResponse.json()
            formDataInfo.append('cv', cvResult)

            if (ch) {
                let chForm = new FormData()
                chForm.append('ch', ch)
                options.body = chForm
                const chResponse = await fetch(`${process.env.REACT_APP_API_URL}/mch`, options)
                const chResult = await chResponse.json()

                formDataInfo.append('ch', chResult)
            }

            if (ccp) {
                let ccpForm = new FormData()
                ccpForm.append('ccp', ccp)
                options.body = ccpForm
                const ccpResponse = await fetch(`${process.env.REACT_APP_API_URL}/mccp`, options)
                const ccpResult = await ccpResponse.json()
                formDataInfo.append('ccp', ccpResult)
            }

            if (iban) {
                let ccpForm = new FormData()
                ccpForm.append('iban', iban)
                options.body = ccpForm
                const ccpResponse = await fetch(`${process.env.REACT_APP_API_URL}/mibancomp`, options)
                const ccpResult = await ccpResponse.json()
                formDataInfo.append('iban', ccpResult)
            }
        }
        // Submeter dados do candidato
        fetch(`${process.env.REACT_APP_API_URL}/coordenadores/externo`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: formDataToJson(formDataInfo)
            })
            .then(res => res.json())
            .then(
                (result) => {
                    toastSuccess("Coordenador inserido com sucesso")
                    navigate('/')
                },
                (error) => {
                    console.error(error)
                }
            )
    }

    //Altera nacionalidade
    const handleNationalityChange = (selectedNationality) => {
        setNationality(selectedNationality);
    };

    return (
        <>
            <main className="min-h-screen">
                <Header></Header>
                <div className="flex-grow">
                    <div className="mt-8 title">CRIAR EXTERNO COORDENADOR/A</div>
                    <div>
                        <MultiStepForm steps={steps} currentStep={section - 1} />
                    </div>
                    <form className="mx-auto container-form" id="main-form" encType="multipart/form-data"  >
                        <section>
                            {section === 1 && (<div>
                                <div className="flex flex-col mt-2">
                                    <label className="label-input" htmlFor="fullName">Nome Completo:*</label>
                                    <input className="input-field2" type="text" id="fullName" name="fullName" value={fullName} onChange={(e) => { setFullName(e.target.value) }}></input>
                                </div>
                                <div className="flex flex-col mt-2">
                                    <label className="label-input" htmlFor="email">Email:*</label>
                                    <input className="input-field2" type="text" id="email" name="email" value={email} onChange={(e) => { setEmail(e.target.value) }} required></input>
                                </div>
                                <div className="flex flex-col mt-2">
                                    <label className="label-input" htmlFor="cellNumber">Nº de telemóvel:*</label>
                                    <input className="input-field2" type="number" id="cellNumber" name="cellNumber" minLength={9} maxLength={13} value={cellNumber} onChange={(e) => { setCellNumber(e.target.value) }} required></input>
                                </div>
                                <div className="flex flex-col mt-2">
                                    <label className="label-input" htmlFor="nif">Número de Contribuinte*</label>
                                    <input className="w-full input-field2" type="text" id="nif" name="nif" minLength={9} maxLength={9} value={nif} onChange={(e) => { setNif(e.target.value) }} required></input>
                                </div>
                                <div className="flex flex-col mt-2">
                                    <label className="label-input" htmlFor="address">Personalização do email de inscrição:</label>
                                    <textarea className="input-textarea" type="text" id="address" name="address" value={mailPersonalizado} onChange={(e) => { setMailPersonalizado(e.target.value) }} placeholder="Escreva uma mensagem personalizada e esta será enviada para o mail do coordenador." ></textarea>
                                </div>
                            </div>)}
                        </section>
                        <section>
                            {section === 2 && (
                                <div>
                                    <div>
                                        <label className="label-input">Nacionalidade:*</label>
                                        <CountryDropdown defaultValue={nationality} onChange={handleNationalityChange} />
                                    </div>
                                    <div className="mt-2">
                                        <label className="text-xs label-input" htmlFor="parish">Naturalidade (Distrito de Nascença):*</label>
                                        <input className="w-full input-field2" type="text" id="parish" name="parish" value={parish} onChange={(e) => { setParish(e.target.value) }} required></input>
                                    </div>
                                    <div className="mt-2">
                                        <label className="text-xs label-input" htmlFor="parish">Naturalidade (Concelho):*</label>
                                        <input className="w-full input-field2" type="text" id="countyNat" name="countyNat" value={countyNat} onChange={(e) => { setCountyNat(e.target.value) }} required></input>
                                    </div>
                                </div>)}
                        </section>
                        <section>
                            {section === 3 && (<div>
                                <div className="flex flex-col">
                                    <label className="label-input" htmlFor="county">Concelho:*</label>
                                    <input className="input-field2" type="text" id="county" name="county" value={county} onChange={(e) => { setCounty(e.target.value) }} required />
                                </div>
                                <div className="flex flex-col mt-2">
                                    <label className="label-input" htmlFor="address">Morada:*</label>
                                    <input className="input-field2" type="text" id="address" name="address" value={address} onChange={(e) => { setAddress(e.target.value) }} required />
                                </div>
                                <div className="flex flex-col mt-2">
                                    <label className="label-input" htmlFor="locality">Localidade:*</label>
                                    <input className="input-field2" type="text" id="locality" name="locality" value={locality} onChange={(e) => { setLocality(e.target.value) }} required />
                                </div>
                                <div className="flex flex-col mt-2">
                                    <label className="label-input" htmlFor="postalCode">Código Postal:*</label>
                                    <input className="input-field2" type="text" id="postalCode" name="postalCode" pattern="[0-9]{4}[\-]?[0-9]{3}" value={postalCode} onChange={(e) => { setPostalCode(e.target.value) }} required></input>
                                </div>
                                <div className="flex flex-col mt-2">
                                    <div className="mb-3">
                                        <label className="label-input" htmlFor="irs">IRS (retenção na fonte)*</label>
                                        <select onWheel={(e) => e.target.blur()} className="selector2" id="irs" name="irs" value={irs} onChange={(e) => { setIrs(e.target.value) }} required>
                                            <option value="" disabled>Selecione a sua opção</option>
                                            <option value="1">Sim</option>
                                            <option value="2">Não</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="flex flex-col mt-2">
                                    <div className="mb-3">
                                        <label className="label-input" htmlFor="irs">IVA*</label>
                                        <select onWheel={(e) => e.target.blur()} className="selector2" id="iva" name="iva" value={iva} onChange={(e) => { setIva(e.target.value) }} required>
                                            <option value="" disabled>Selecione a sua opção</option>
                                            <option value="1">Sim</option>
                                            <option value="2">Não</option>
                                        </select>
                                    </div>
                                </div>
                            </div>)}
                        </section>
                        <section>
                            {section === 4 && (<div>
                                <div className="flex flex-col">
                                    <label className="label-input" htmlFor="ccNumber">Número cartão de Cidadão:*</label>
                                    <input className="input-field2" type="text" id="ccNumber" name="ccNumber" value={ccNumber} onChange={(e) => { setCcNumber(e.target.value) }} required></input>
                                </div>
                                <div className="flex flex-col mt-2">
                                    <label className="label-input" htmlFor="ccExpiration">Data de validade Cartão Cidadão:*</label>
                                    <input className="input-field2" type="text" id="ccExpiration" name="ccExpiration" ref={ref} onFocus={() => (ref.current.type = "date")}
                                        onBlur={() => (ref.current.type = "date")} value={ccExpiration} onChange={(e) => { setCcExpiration(e.target.value) }} required></input>
                                </div>
                            </div>
                            )}
                            {section === 5 && (<div className="section">
                                <div className="flex flex-col">
                                    <label className="label-input" htmlFor="cc">Cartão de Cidadão: </label>
                                    <input className="input-file" type="file" id="cc" name="cc" accept=".pdf" ></input>
                                </div>
                                <div className="flex flex-col mt-2">
                                    <label className="label-input" htmlFor="cvDoc">Curriculum Vitae:* </label>
                                    <input className="input-file" type="file" id="cv" name="cv" accept=".pdf" ></input>
                                </div>
                                <div className="flex flex-col mt-2">
                                    <label className="label-input" htmlFor="certificate_doc">Certificado de Habilitações:</label>
                                    <input className="input-file" type="file" id="ch" name="ch" accept=".pdf" ></input>
                                </div>
                                <div className="flex flex-col mt-2">
                                    <label className="label-input" htmlFor="ccp_doc">CCP / CAP:</label>
                                    <input className="input-file" type="file" id="ccp" name="ccp" accept=".pdf"></input>
                                </div>
                                <div className="flex flex-col mt-2">
                                    <label className="label-input" htmlFor="iban_doc">IBAN:</label>
                                    <input className="input-file" type="file" id="iban" name="iban" accept=".pdf"></input>
                                </div>

                                <p className="mt-4 text-text-second-color">*Formato suportado .pdf</p>
                                <div className="mt-4">
                                    <button onClick={() => setModalIsOpen(true)} className="button-geral" type="button" >Submeter</button>
                                </div>
                            </div>
                            )}
                        </section>
                        <Modal isOpen={modalIsOpen} className="fixed inset-0 flex items-center justify-center">
                            <div className="absolute w-full h-full" onClick={() => setModalIsOpen(false)}></div>
                            <div className="relative flex flex-col items-center px-10 mx-5 my-5 overflow-y-scroll shadow-2xl bg-secundary-color py-7 rounded-2xl max-h-128 sm:max-h-156">
                                <div>
                                    <h1 className="title">Dados do Formulário</h1>
                                </div>
                                <div className="modal-content">
                                    <p className="p-1 text-text-second-color"><strong>Nome Completo:</strong> {fullName}</p>
                                    <p className="p-1 text-text-second-color"><strong>Número de telemóvel:</strong> {cellNumber}</p>
                                    <p className="p-1 text-text-second-color"><strong>Email:</strong> {email}</p>
                                    <p className="p-1 text-text-second-color"><strong>Morada:</strong> {address}</p>
                                    <p className="p-1 text-text-second-color"><strong>Nacionalidade:</strong> {nationality}</p>
                                    <p className="p-1 text-text-second-color"><strong>Naturalidade:</strong> {parish} - {countyNat}</p>
                                    <p className="p-1 text-text-second-color"><strong>Concelho:</strong> {county}</p>
                                    <p className="p-1 text-text-second-color"><strong>Localidade:</strong> {locality}</p>
                                    <p className="p-1 text-text-second-color"><strong>Concelho:</strong> {county}</p>
                                    <p className="p-1 text-text-second-color"><strong>Código postal:</strong> {postalCode}</p>
                                    <p className="p-1 text-text-second-color"><strong>Número cartão de cidadão:</strong> {ccNumber}</p>
                                    <p className="p-1 text-text-second-color"><strong>Data de validade CC:</strong> {ccExpiration}</p>
                                    <p className="p-1 text-text-second-color"><strong>Número Contribuinte:</strong> {nif}</p>
                                    <p className="p-1 text-text-second-color"><strong>IRS:</strong> {irs === "1" ? "Sim" : "Não"}</p>
                                    <p className="p-1 text-text-second-color"><strong>IVA:</strong> {iva === "1" ? "Sim" : "Não"}</p>
                                </div>
                                <div className="flex flex-row justify-center w-full mt-5">
                                    <button className="w-24 button-confirmar" onClick={submeterCandidatura}>Confirmar</button>
                                </div>
                                <button className="absolute top-1.5 right-1.5" onClick={() => setModalIsOpen(false)}>
                                    <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                                </button>
                            </div>
                        </Modal>
                        <div className="mt-3">
                            <h3 className="m-1 text-main-color">Legenda</h3>
                            <p className="m-1 text-xs text-text-second-color">Todos os campos identificados com * são obrigatórios.</p>
                            <p className="m-1 text-xs text-text-second-color">Os dados pessoais serão facultados às Entidades Reguladoras e/ou Financiadoras das Ações de Formação.</p>
                        </div>
                        {section !== 1 && (<button onClick={() => handlePrevious(section - 1)} type="button" className="button-back-form"><img className="invert" src={Next} alt="Back" /></button>)}
                        <div>
                            {(section !== 5 && section !== 0) && (<button onClick={() => handleNext(null)} type="button" className="button-next-form"><img className="invert" src={Next} alt="Next" /></button>)}
                        </div>
                    </form>
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main>
        </>
    );
}

export default ShowCoordinators;