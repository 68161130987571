import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import axios from 'axios';


const excelGenerator = async (userData) => {
    //Data de Inicio e Data final
    const inicio = new Date(userData.dateBegin)
    const mesInicio = inicio.getMonth()
    const anoInicio = inicio.getFullYear()
    const diaInicio = inicio.getDate()
    const fim = new Date(userData.dateEnd)
    const mesFim = fim.getMonth()
    const anoFim = fim.getFullYear()
    const diaFim = fim.getDate()
    const daysOfWeek = ["Sab", "Dom", "Seg", "Ter", "Qua", "Qui", "Sex"];
    const convertDateFormat2 = (originalDate) => {
        // Split the date into an array using the dash as a separator
        const dateArray = originalDate.split('-');
        // Rearrange the array elements to the desired format
        const formattedDate = `${dateArray[2]}`+`/${dateArray[1]}`+`/${dateArray[0]}`;
        return formattedDate;
    };
    
    const color1 = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFA500' },
    };

    const color2 = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFADD8E6' },
    };
    const colorH = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF' + "#efecbd".substring(1) }
    }
    const color3 = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF7FFFD4' }
    };
    const color4 = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFFE0' }
    };

    // Variaveis de estilização
    const alignment = {
        horizontal: 'center',
        vertical: 'middle',
        wrapText: true,
    };
    const bold = { bold: true };
    const bold2 = { bold: true, size: 10 };
    const title = { bold: true, name: "DejaVu Sans", size: 14 };

    const borderThin = {
        top: { style: 'thin' },    // Top border
        bottom: { style: 'thin' }, // Bottom border
        left: { style: 'thin' },   // Left border
        right: { style: 'thin' },  // Right border
    };

    const saveDisciplinas = []
    const disciplinas = (userData.ufcds)
    const sortedData = disciplinas.slice().sort((a, b) => a.hierarquia - b.hierarquia);
    //dados
    sortedData.forEach(ufcds => {
        saveDisciplinas.push({
            name: ufcds.name,
            codeUfcd: ufcds.codeUfcd,
            dateBegin: ufcds.dateBegin,
            dateEnd: ufcds.dateEnd,
            dayStart: (new Date(ufcds.dateBegin)).getDate(),
            monthStart: (new Date(ufcds.dateBegin)).getMonth() + 1,
            yearStart: (new Date(ufcds.dateBegin)).getFullYear(),
            monthEnd: (new Date(ufcds.dateEnd)).getMonth() + 1,
            dayEnd: (new Date(ufcds.dateEnd)).getDate(),
            yearEnd: (new Date(ufcds.dateEnd)).getFullYear(),
            cargaHoraria: ufcds.cargaHoraria,
            TTime: ufcds.TTime,
            PTime: ufcds.PTime,
            color: ufcds.color
        })
    });

    // FUNÇÕES

    
    //calcular a diferença dos meses
    function DateDifference(datea, dateb) {
        const year1 = new Date(datea).getFullYear()
        const month1 = new Date(datea).getMonth() + 1
        const year2 = new Date(dateb).getFullYear()
        const month2 = new Date(dateb).getMonth() + 1

        const yearDiff = year2 - year1;
        const monthDiff = month2 - month1;

        const totalMonthsDiff = yearDiff * 12 + monthDiff;

        return totalMonthsDiff;
    }
    const difMeses = DateDifference(inicio, fim)

    // Receber o primeiro dia do mês
    function getFirstDayOfMonth(year, month) {
        // Create a new Date object for the 1st day of the specified month and year
        let day = 0
        const firstDay = new Date(year, month, 1);
        switch (firstDay.getDay()) {
            case 0:
                day = 1;
                break;
            case 1:
                day = 2;
                break;
            case 2:
                day = 3;
                break;
            case 3:
                day = 4;
                break;
            case 4:
                day = 5;
                break;
            case 5:
                day = 6;
                break;
            case 6:
                day = 0;
                break;
            default:
                day = 0;
                break;
        }
        // Get the day of the week (0: Sunday, 1: Monday, ..., 6: Saturday)
        return day
    }

    // Receber o ultimo dia do mês
    const getLastUtilDayOfMonth = (year, month) => {
        const lastDayOfMonth = new Date(year, month + 1, 0);
        const dayOfWeek = lastDayOfMonth.getDay();

        if (dayOfWeek === 0) {
            // If the last day is Sunday, return the previous Friday
            return lastDayOfMonth.getDate() - 2;
        } else if (dayOfWeek === 6) {
            // If the last day is Saturday, return the previous Friday
            return lastDayOfMonth.getDate() - 1;
        } else {
            // If the last day is a weekday, return that day
            return lastDayOfMonth.getDate();
        }
    };

    //Verifica os dias úteis da semana
    function isWeekday(year, month, day) {
        const date = new Date(year, month, day);
        const dayOfWeek = date.getDay(); // 0 (Sunday) to 6 (Saturday)
        return dayOfWeek !== 0 && dayOfWeek !== 6; // Exclude weekends
    }

    // Feriados flexiveis em Portugal
    function calculateEasterDate(year) {
        // Cálculo da data da Páscoa (algoritmo de Gauss)
        const a = year % 19;
        const b = Math.floor(year / 100);
        const c = year % 100;
        const d = Math.floor(b / 4);
        const e = b % 4;
        const f = Math.floor((b + 8) / 25);
        const g = Math.floor((b - f + 1) / 3);
        const h = (19 * a + b - d - g + 15) % 30;
        const i = Math.floor(c / 4);
        const k = c % 4;
        const l = (32 + 2 * e + 2 * i - h - k) % 7;
        const m = Math.floor((a + 11 * h + 22 * l) / 451);
        const month = Math.floor((h + l - 7 * m + 114) / 31);
        const day = ((h + l - 7 * m + 114) % 31) + 1;

        return new Date(year, month - 1, day);
    }

    // Todos os feriados em Portugal
    function isPortugueseHoliday(year, month, day) {
        // Feriados fixos em Portugal
        const fixedHolidays = [
            [0, 1],   // Ano Novo
            [3, 25],  // Dia da Liberdade
            [4, 1],   // Dia do Trabalhador
            [5, 10],  // Dia de Portugal
            [7, 15],  // Assunção de Nossa Senhora
            [9, 5],  // Implantação da República
            [11, 1],  // Dia de Todos os Santos
            [11, 8],  // Dia da Imaculada Conceição
            [11, 25], // Dia de Natal
        ];

        // Feriados móveis em Portugal (exemplo simplificado)
        const easterDate = calculateEasterDate(year);
        const movableHolidays = [
            [easterDate.getMonth(), easterDate.getDate()], // Domingo de Páscoa
            [easterDate.getMonth(), easterDate.getDate() - 2], // Sexta-feira Santa
        ];

        for (const [holidayMonth, holidayDay] of fixedHolidays.concat(movableHolidays)) {
            if (month === holidayMonth && day === holidayDay) {
                return true;
            }
        }

        return false;
    }

    // Conta os dias úteis
    function getWeekdayCountWithHolidays(year, month) {
        const lastDay = new Date(year, month, 0);
        let weekdayCount = 0;

        for (let day = 1; day <= lastDay.getDate(); day++) {
            if (isWeekday(year, month - 1, day) && !isPortugueseHoliday(year, month - 1, day)) {
                weekdayCount++;
            }
        }

        return weekdayCount;
    }

    // Receber a letra equivalente á posição
    function getColumnLetterFromIndex(index) {
        const columnLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let columnName = '';

        while (index > 0) {
            const remainder = (index - 1) % 26;
            columnName = columnLetters[remainder] + columnName;
            index = Math.floor((index - 1) / 26);
        }

        return columnName;
    }

    // Receber o nome do mês        
    function getMonthName(monthNumber) {
        const date = new Date();
        date.setMonth(monthNumber - 1);

        const monthName = date.toLocaleString('pt-Pt', { month: 'long' });
        const capitalizedMonthName = capitalizeFirstLetter(monthName);

        return capitalizedMonthName;
    }

    // Começar em Uppercase
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    // função gerar cores
    function generateRandomColor() {
        const a = Math.floor(Math.random() * 256); // Random alpha value between 0 and 255
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);

        let argbColor = `#${(a << 24 | r << 16 | g << 8 | b).toString(16).padStart(8, '0')}`;
        argbColor = argbColor.replace("-", "")
        return argbColor;
    }

    // Tratar das cores
    function applyFillToCell(worksheet, cells) {
        const color = cells[0];
        cells.border = borderThin;
        cells.slice(1).forEach(cell => {
            worksheet.getCell(cell).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: color },
            };
        });

        for (let col = 3; col <= 39; col++) {
            for (let row = 15; row <= 26; row++) {
                const cell = worksheet.getCell(row, col);
                cell.border = borderThin
                // Set the fill color of the cell
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF' + "#B2B2B2".substring(1) }
                };
            }
        }

        for (let col = 1; col <= 39; col++) {
            const cell = worksheet.getCell(13, col);
            cell.border = borderThin
            cell.font = bold
        }
    }

    // Função de gerar dias da semana
    function fillDaysOfWeek(worksheet, j) {
        // Generate an array with the days of the week 
        for (let i = 0; i <= 36; i++) {
            let cell
            let nRow = 67 + i;
            if (nRow > 90) {
                let row = String.fromCharCode(nRow - 26);
                cell = worksheet.getCell(`A${row}${j}`);
            } else {
                let row = String.fromCharCode(nRow);
                cell = worksheet.getCell(`${row}${j}`);
            }
            const dayOfWeekIndex = i % 7;
            cell.value = daysOfWeek[dayOfWeekIndex];
        }
    }

    // Receber o fim do mês em dia da semana
    function getEndingDay(month, year) {
        // Validate month and year inputs
        if (month < 1 || month > 12) {
            return 'Invalid month';
        }

        // Create a new Date object with the provided month and year
        const date = new Date(year, month - 1, 1);

        // Get the day of the week for the first day of the month (0-6, where 0 represents Sunday)
        const endingDay = date.getDay();

        // Create an array of days of the week
        const daysOfWeek = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
        // Return the starting day of the month
        return daysOfWeek[endingDay];
    }

    // Atribuir os dias do mês
    function fillDaysOfMonth(worksheet, diasSemana, j, ano, mes) {
        let control = 0;
        switch (diasSemana) {
            case daysOfWeek[0]:
                control = 0;
                break;
            case daysOfWeek[1]:
                control = 1;
                break;
            case daysOfWeek[2]:
                control = 2;
                break;
            case daysOfWeek[3]:
                control = 3;
                break;
            case daysOfWeek[4]:
                control = 4;
                break;
            case daysOfWeek[5]:
                control = 5;
                break;
            case daysOfWeek[6]:
                control = 6;
                break;
            default:
                control = 0;
                break;
        }
        if (mes === 1 || mes === 3 || mes === 5 || mes === 7 || mes === 8 || mes === 10 || mes === 12) {
            const daysOfMonth = Array.from({ length: 31 }, (_, i) => i + 1);
            for (let i = 0; i < daysOfMonth.length; i++) {
                let cell
                let nRow = 67 + i + control;
                if (nRow > 90) {
                    let row = String.fromCharCode(nRow - 26);
                    cell = worksheet.getCell(`A${row}${j + 1}`);
                } else {
                    let row = String.fromCharCode(nRow);
                    cell = worksheet.getCell(`${row}${j + 1}`);
                }

                cell.value = daysOfMonth[i];
            }
        }
        else if (mes === 2 && isLeapYear(ano)) {
            const daysOfMonth = Array.from({ length: 29 }, (_, i) => i + 1);
            for (let i = 0; i < daysOfMonth.length; i++) {
                let cell
                let nRow = 67 + i + control;
                if (nRow > 90) {
                    let row = String.fromCharCode(nRow - 26);
                    cell = worksheet.getCell(`A${row}${j + 1}`);
                } else {
                    let row = String.fromCharCode(nRow);
                    cell = worksheet.getCell(`${row}${j + 1}`);
                }

                cell.value = daysOfMonth[i];
            }
        }
        else if (mes === 2 && !isLeapYear(ano)) {
            const daysOfMonth = Array.from({ length: 28 }, (_, i) => i + 1);
            for (let i = 0; i < daysOfMonth.length; i++) {
                let cell
                let nRow = 67 + i + control;
                if (nRow > 90) {
                    let row = String.fromCharCode(nRow - 26);
                    cell = worksheet.getCell(`A${row}${j + 1}`);
                } else {
                    let row = String.fromCharCode(nRow);
                    cell = worksheet.getCell(`${row}${j + 1}`);
                }

                cell.value = daysOfMonth[i];
            }
        }
        else {
            const daysOfMonth = Array.from({ length: 30 }, (_, i) => i + 1);
            for (let i = 0; i < daysOfMonth.length; i++) {
                let cell
                let nRow = 67 + i + control;
                if (nRow > 90) {
                    let row = String.fromCharCode(nRow - 26);
                    cell = worksheet.getCell(`A${row}${j + 1}`);
                } else {
                    let row = String.fromCharCode(nRow);
                    cell = worksheet.getCell(`${row}${j + 1}`);
                }

                cell.value = daysOfMonth[i];
            }
        }
        for (let col = 1; col <= 39; col++) {
            const cell = worksheet.getCell(j, col);
            cell.border = borderThin
            // Set the fill color of the cell
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF' + "#efecbd".substring(1) }
            };
            cell.font = bold
        }

        for (let col = 1; col <= 39; col++) {
            const cell = worksheet.getCell(j - 1, col);
            cell.border = borderThin
            // Set the fill color of the cell
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF' + "#efecbd".substring(1) }
            };
        }

        for (let col = 1; col <= 39; col++) {
            const cell = worksheet.getCell(j + 1, col);
            // Set the fill color of the cell
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF' + "#B2B2B2".substring(1) }
            }
        }
        for (let col = 3; col <= 39; col++) {
            for (let row = j + 2; row <= j + 13; row++) {
                const cell = worksheet.getCell(row, col);

                // Set the fill color of the cell
                cell.border = borderThin
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF' + "#B2B2B2".substring(1) }
                };
            }
        }
        setMultipleRowHeight(worksheet, j, j + 12, 18);
    }

    // Verificação de ano biSexto
    function isLeapYear(year) {
        if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
            return true; // É bissexto
        } else {
            return false; // Não é bissexto
        }
    }

    // Estilizar a altura das linhas
    function setMultipleRowHeight(worksheet, startRow, endRow, height) {
        for (let row = startRow; row <= endRow; row++) {
            worksheet.getRow(row).height = height;
        }
    }

    // Inicio Excel
    const workbook = new ExcelJS.Workbook();

    //INICIO DA PÁGINA CRONOGRAMA
    const worksheet = workbook.addWorksheet('Cronograma');

    // Função Cronograma
    async function cronograma() {
        // Imagens
        const imageUrlEntFormador = userData.entFormadora[0].logotipo;
        const imageBufferEFor = await axios.get(imageUrlEntFormador, { responseType: 'arraybuffer' });


        // Create a function to load and add the image
        async function loadImageAndAddToWorksheet() {
            // Create a new Image object
            const img = new Image();

            // Create a promise to resolve when the image has loaded
            return new Promise((resolve, reject) => {
                img.onload = function () {
                    const originalWidth = img.width;
                    const originalHeight = img.height;

                    // Calculate the scaling factor based on the desired width (e.g., 150)

                    const scalingFactor = originalWidth / originalHeight;

                    // Calculate the scaled height
                    const scaledHeight = 50;
                    const desiredWidth = scalingFactor * 50;

                    const imageIdEFor = workbook.addImage({
                        buffer: imageBufferEFor.data,
                        extension: 'png',
                    });

                    worksheet.addImage(imageIdEFor, {
                        tl: { col: 0.5, row: 0.4 },
                        ext: { width: desiredWidth, height: scaledHeight },
                    });

                    // Resolve the promise
                    resolve();
                };

                // Set the source of the image to your image file
                img.src = imageUrlEntFormador;
            });
        }

        // Call the function to load and add the image
        await loadImageAndAddToWorksheet();

        if (userData.entFinanciadora.length > 0) {
            if (userData.entFinanciadora[0].logotipo1) {
                const imageUrlEntFinanciadora = userData.entFinanciadora[0].logotipo1;
                const imageBuffer = await axios.get(imageUrlEntFinanciadora, { responseType: 'arraybuffer' });

                async function loadImage2AndAddToWorksheet() {
                    // Create a new Image object
                    const img = new Image();

                    // Create a promise to resolve when the image has loaded
                    return new Promise((resolve, reject) => {
                        img.onload = function () {
                            const originalWidth = img.width;
                            const originalHeight = img.height;


                            const scalingFactor = originalWidth / originalHeight;

                            // Calculate the scaled height
                            const scaledHeight = 50;
                            const desiredWidth = scalingFactor * 50;


                            const imageId2 = workbook.addImage({
                                buffer: imageBuffer.data,
                                extension: 'png',
                            });
                            worksheet.addImage(imageId2, {
                                tl: { col: 32, row: 0.4 },
                                ext: { width: desiredWidth, height: scaledHeight },
                            });

                            // Resolve the promise
                            resolve();
                        };

                        // Set the source of the image to your image file
                        img.src = imageUrlEntFinanciadora;
                    });
                }
                await loadImage2AndAddToWorksheet();
            }
            if (userData.entFinanciadora[0].logotipo2) {
                const imageUrlEntFinanciadora = userData.entFinanciadora[0].logotipo2;
                const imageBuffer = await axios.get(imageUrlEntFinanciadora, { responseType: 'arraybuffer' });

                async function loadImage2AndAddToWorksheet() {
                    // Create a new Image object
                    const img = new Image();

                    // Create a promise to resolve when the image has loaded
                    return new Promise((resolve, reject) => {
                        img.onload = function () {
                            const originalWidth = img.width;
                            const originalHeight = img.height;


                            const scalingFactor = originalWidth / originalHeight;

                            // Calculate the scaled height
                            const scaledHeight = 50;
                            const desiredWidth = scalingFactor * 50;


                            const imageId2 = workbook.addImage({
                                buffer: imageBuffer.data,
                                extension: 'png',
                            });
                            worksheet.addImage(imageId2, {
                                tl: { col: 32, row: ((difMeses * 13) + 14 + 15) },
                                ext: { width: desiredWidth, height: scaledHeight },
                            });

                            // Resolve the promise
                            resolve();
                        };

                        // Set the source of the image to your image file
                        img.src = imageUrlEntFinanciadora;
                    });
                }
                await loadImage2AndAddToWorksheet();
            }

        }
        // Celulas de texto - Cabeçalho
        const A1 = worksheet.getCell('A1');
        const A2 = worksheet.getCell('A2');
        const A3 = worksheet.getCell('A3');
        const A4 = worksheet.getCell('A4');
        const A5 = worksheet.getCell('A5');
        const A6 = worksheet.getCell('A6');
        const A7 = worksheet.getCell('A7');
        const A9 = worksheet.getCell('A9');
        const A10 = worksheet.getCell('A10');


        const D2 = worksheet.getCell('D2');
        const D3 = worksheet.getCell('D3');
        const D4 = worksheet.getCell('D4');
        const D5 = worksheet.getCell('D5');
        const D10 = worksheet.getCell('D10');

        const E6 = worksheet.getCell('E6');
        const E7 = worksheet.getCell('E7');
        const E9 = worksheet.getCell('E9');

        const L6 = worksheet.getCell('L6');
        const L7 = worksheet.getCell('L7');
        const L9 = worksheet.getCell('L9');

        const V3 = worksheet.getCell('V3');
        const V4 = worksheet.getCell('V4');

        const S6 = worksheet.getCell('S6');
        const S7 = worksheet.getCell('S7');
        const S9 = worksheet.getCell('S9');

        const Z6 = worksheet.getCell('Z6');
        const Z7 = worksheet.getCell('Z7');
        const Z9 = worksheet.getCell('Z9');

        const AD2 = worksheet.getCell('AD2');
        const AD3 = worksheet.getCell('AD3');
        const AD4 = worksheet.getCell('AD4');
        const AD5 = worksheet.getCell('AD5');
        const AD6 = worksheet.getCell('AD6');

        const AG6 = worksheet.getCell('AG6');
        const AG7 = worksheet.getCell('AG7');
        const AG9 = worksheet.getCell('AG9');


        // Valor das Celulas
        A1.value = 'Cronograma';
        A2.value = 'Entidade Formadora:'; D2.value = userData.entFormadora[0].name
        A3.value = 'Tipologia de Operação:'; D3.value = userData.operacao[0].nomeTipologia; V3.value = "Modalidade de Formação:"; AD3.value = userData.modalidade
        A4.value = 'Operação:'; D4.value = userData.operacao[0].codigoOperacao; V4.value = "Código e Área de Formação:"; AD4.value = userData.areaCodigoFormacao
        A5.value = 'Curso/Percurso:'; D5.value = userData.name;
        A6.value = 'Código Administrativo (em SIGO):'; E6.value = 'Código Interno:'; L6.value = 'Nível:'; S6.value = 'Carga Horária:'; Z6.value = 'N.º Curso (em SIIFSE):'; AG6.value = 'N.º Ação (em SIIFSE):';
        A7.value = userData.codeSIGO; E7.value = userData.codeInterno; L7.value = userData.nivel; S7.value = userData.duration; Z7.value = userData.cursoN;; AG7.value = userData.acaoN;;
        A9.value = "Data de Início:"; E9.value = convertDateFormat2(userData.dateBegin); L9.value = "Data de Fim:"; S9.value = convertDateFormat2(userData.dateEnd); Z9.value = 'Horário:'; AG9.value = userData.horario;

        A10.value = 'Local de Formação:'; D10.value = userData.locality

        // Centrar
        A1.alignment = alignment
        A2.alignment = alignment
        A3.alignment = alignment
        A4.alignment = alignment
        A5.alignment = alignment
        A6.alignment = alignment
        A7.alignment = alignment
        A9.alignment = alignment
        A10.alignment = alignment

        E6.alignment = alignment
        E7.alignment = alignment
        E9.alignment = alignment

        L6.alignment = alignment
        L7.alignment = alignment
        L9.alignment = alignment

        V3.alignment = alignment
        V4.alignment = alignment

        S6.alignment = alignment
        S7.alignment = alignment
        S9.alignment = alignment

        Z6.alignment = alignment
        Z7.alignment = alignment
        Z9.alignment = alignment

        AG6.alignment = alignment
        AG7.alignment = alignment
        AG9.alignment = alignment

        // Negrito
        A1.font = title
        A2.font = bold
        A3.font = bold
        A4.font = bold
        A5.font = bold
        A6.font = bold
        A7.font = bold
        A9.font = bold
        A10.font = bold

        E6.font = bold

        L6.font = bold
        L9.font = bold

        V3.font = bold
        V4.font = bold

        S6.font = bold

        Z6.font = bold
        Z9.font = bold

        AG6.font = bold

        // Collumn Width
        worksheet.getColumn('A').width = 12
        worksheet.getColumn('B').width = 8
        for (let columnNumber = 3; columnNumber <= 39; columnNumber++) {
            worksheet.getColumn(columnNumber).width = 6.5;
        }
        // Row height
        worksheet.getRow('1').height = 50
        worksheet.getRow('6').height = 35

        // Estutura - Cabeçalho 
        worksheet.mergeCells('A1:AM1');
        worksheet.mergeCells('A2:C2'); worksheet.mergeCells('D2:AM2');
        worksheet.mergeCells('A3:C3'); worksheet.mergeCells('D3:U3'); worksheet.mergeCells('V3:AC3'); worksheet.mergeCells('AD3:AM3');
        worksheet.mergeCells('A4:C4'); worksheet.mergeCells('D4:U4'); worksheet.mergeCells('V4:AC4'); worksheet.mergeCells('AD4:AM4');
        worksheet.mergeCells('A5:C5'); worksheet.mergeCells('D5:AM5');
        worksheet.mergeCells('A6:D6'); worksheet.mergeCells('E6:K6'); worksheet.mergeCells('L6:R6'); worksheet.mergeCells('S6:Y6'); worksheet.mergeCells('Z6:AF6'); worksheet.mergeCells('AG6:AM6');
        worksheet.mergeCells('A7:D8'); worksheet.mergeCells('E7:K8'); worksheet.mergeCells('L7:R8'); worksheet.mergeCells('S7:Y8'); worksheet.mergeCells('Z7:AF8'); worksheet.mergeCells('AG7:AM8');
        worksheet.mergeCells('A9:D9'); worksheet.mergeCells('E9:K9'); worksheet.mergeCells('L9:R9'); worksheet.mergeCells('S9:Y9'); worksheet.mergeCells('Z9:AF9'); worksheet.mergeCells('AG9:AM9');
        worksheet.mergeCells('A10:C10'); worksheet.mergeCells('D10:AM10');

        // Estilização - Cabeçalho
        const startCell = worksheet.getCell('A1');
        const endCell = worksheet.getCell('AD6');

        worksheet.eachRow({ from: startCell.row, to: endCell.row }, (row) => {
            row.eachCell((cell) => {
                cell.border = borderThin
            });
        });
        const cells = ['efefbd', 'A2', 'A3', 'A4', 'A5', 'A6', 'A9', 'A10', 'E6', 'L6', 'S6', 'Z6', 'AG6', 'V3', 'V4', 'L9', 'Z9', 'A13', 'B13', 'C13', 'D13', 'E13', 'F13', 'G13', 'H13', 'I13', 'J13', 'K13', 'L13', 'M13', 'N13', 'O13', 'P13', 'Q13', 'R13', 'S13', 'T13', 'U13', 'V13', 'W13', 'X13', 'Y13', 'Z13', 'AA13', 'AB13', 'AC13', 'AD13', 'AE13', 'AF13', 'AG13', 'AH13', 'AI13', 'AJ13', 'AK13', 'AL13', 'AM13'];

        const cells2 = ['b2b2b2', 'A14', 'B14', 'C14', 'D14', 'E14', 'F14', 'G14', 'H14', 'I14', 'J14', 'K14', 'L14', 'M14', 'N14', 'O14', 'P14', 'Q14', 'R14', 'S14', 'T14', 'U14', 'V14', 'W14', 'X14', 'Y14', 'Z14', 'AA14', 'AB14', 'AC14', 'AD14', 'AE14', 'AF14', 'AG14', 'AH14', 'AI14', 'AJ14', 'AK14', 'AL14', 'AM14'];
        //Palete de Cores
        applyFillToCell(worksheet, cells);
        applyFillToCell(worksheet, cells2);


        // Utilizar data de inicio da UFCD e a data Final caso seja em meses diferentes

        let j = 13
        let ind = 0
        let cont = 0
        let mes = 0
        for (let i = 0; i <= difMeses; i++) {
            ind++
            // Posição correta


            // variaveis
            const Aj = worksheet.getCell("A" + (j + 2));
            const Bj1 = worksheet.getCell('B' + (j + 2));
            const Bj2 = worksheet.getCell('B' + (j + 3));
            const Bj3 = worksheet.getCell('B' + (j + 4));
            const Bj4 = worksheet.getCell('B' + (j + 5));
            const Bj5 = worksheet.getCell('B' + (j + 6));
            const Bj6 = worksheet.getCell('B' + (j + 7));
            const Bj7 = worksheet.getCell('B' + (j + 8));
            const Bj8 = worksheet.getCell('B' + (j + 9));
            const Bj9 = worksheet.getCell('B' + (j + 10));
            const Bj10 = worksheet.getCell('B' + (j + 11));
            const Bj11 = worksheet.getCell('B' + (j + 12));
            const Bj12 = worksheet.getCell('B' + (j + 13));
            // valores das variaveis

            Bj1.value = userData.horario === "Laboral"?"08h/09h": "08h/13h"
            Bj2.value =  userData.horario === "Laboral"?"09h/10h": "13h/14h"
            Bj3.value =  userData.horario === "Laboral"?"10h/11h": "14h/15h"
            Bj4.value =  userData.horario === "Laboral"?"11h/12h": "15h/16h"
            Bj5.value =  userData.horario === "Laboral"?"12h/13h": "16h/17h"
            Bj6.value =  userData.horario === "Laboral"?"13h/14h": "17h/18h"
            Bj7.value =  userData.horario === "Laboral"?"14h/15h": "18h/19h"
            Bj8.value =  userData.horario === "Laboral"?"15h/16h": "19h/20h"
            Bj9.value =  userData.horario === "Laboral"?"16h/17h": "20h/21h"
            Bj10.value =  userData.horario === "Laboral"?"17h/18h": "21h/22h"
            Bj11.value = userData.horario === "Laboral"?"18h/19h": "22h/23h"
            Bj12.value = userData.horario === "Laboral"?"19h/23h": "23h/24h"


            // Border das variáveis

            Bj1.border = borderThin
            Bj2.border = borderThin
            Bj3.border = borderThin
            Bj4.border = borderThin
            Bj5.border = borderThin
            Bj6.border = borderThin
            Bj7.border = borderThin
            Bj8.border = borderThin
            Bj9.border = borderThin
            Bj10.border = borderThin
            Bj11.border = borderThin
            Bj12.border = borderThin
            Aj.border = borderThin

            worksheet.mergeCells(`A${j + 2}:A${j + 13}`);
            fillDaysOfWeek(worksheet, j)

            // Utilizar data de inicio da UFCD e a data Final caso seja em meses diferentes
            if (i!= 0 && (i + mesInicio) % 12 === 0) {
                cont++
            }
            if ((i + 1) + mesInicio > 12) {
                mes = (i + 1) + mesInicio - (12 * cont)
            }
            else mes = (i + 1) + mesInicio
            const endingDay = getEndingDay(mes, (anoInicio + cont));
            fillDaysOfMonth(worksheet, endingDay, j, anoInicio + cont, mes);
            Aj.value = (getMonthName(mes) + "\n" + (anoInicio + cont));
            Aj.alignment = alignment
            Aj.font = bold
            j = j + 15
        }
        let index = 0
        saveDisciplinas.forEach(ufcd => {
            //Begin
            let cont = 0
            if ((ufcd.mesInicio + mesInicio) % 12 === 0) {
                cont++
            }
            if (ufcd.name !== "PRA") {
                const diff1 = DateDifference(inicio, ufcd.dateBegin)
                const startCol1 = ufcd.dayStart + 2 + (getFirstDayOfMonth(ufcd.yearStart, ufcd.monthStart - 1))
                let cellBegin = worksheet.getCell((15 + (diff1 * 15)), startCol1);
                if (cellBegin.value) {
                    cellBegin = worksheet.getCell(15 + diff1 * 15 + 1, startCol1);
                    for (let i = 1; i <= 11; i++) {
                        if (cellBegin.value) {
                            cellBegin = worksheet.getCell(15 + diff1 * 15 + i, startCol1);
                        }
                        else break;
                    }
                }
                cellBegin.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFA500' },
                };
                cellBegin.font = {
                    size: 6
                };
                cellBegin.alignment = {
                    horizontal: 'center',
                    vertical: 'middle',
                    wrapText: true,
                };
                cellBegin.value = ufcd.codeUfcd
                const diff2 = DateDifference(inicio, ufcd.dateEnd)
                const startCol2 = ufcd.dayEnd + 2 + (getFirstDayOfMonth(ufcd.yearEnd, ufcd.monthEnd - 1))
                let cellEnd = worksheet.getCell((26 + (diff2 * 15)), startCol2);
                if (cellEnd.value) {
                    cellEnd = worksheet.getCell(26 + diff2 * 15, startCol2);
                    for (let i = 1; i <= 11; i++) {
                        if (cellEnd.value) {
                            cellEnd = worksheet.getCell(26 + diff2 * 15 - i, startCol2);
                        }
                        else break;
                    }
                }
                cellEnd.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFA500' },
                };
                cellEnd.font = {
                    size: 6
                };
                cellEnd.alignment = {
                    horizontal: 'center',
                    vertical: 'middle',
                    wrapText: true,
                };
                cellEnd.value = ufcd.codeUfcd
            }
            else {
                for (let i = 0; i <= (DateDifference(inicio, fim)); i++) {
                    const start = 3 + difMeses + 1 + 2
                    const praduration = worksheet2.getCell(`${getColumnLetterFromIndex(8 + (saveDisciplinas.length - 1))}${(start + 3) + (i)}`);
                    if (i === (DateDifference(inicio, fim))) {
                        let soma = 0
                        let cont = 0
                        for (let k = 0; k < Math.ceil(praduration.value / 7); k++) {
                            let lastday = (getLastUtilDayOfMonth(anoInicio, (mesInicio + i))) - k
                            const day = new Date(anoInicio, (mesInicio + i), lastday)
                            if (day.getDay() === 0) {
                                cont++
                                cont++
                                lastday = lastday - cont
                            }
                            else {
                                lastday = lastday - cont
                            }
                            const startCol = (getFirstDayOfMonth(anoInicio, (mesInicio + i))) + 2 + lastday
                            let cellEnd = worksheet.getCell((15 + (i * 15)), startCol);

                            let final = praduration.value - soma
                            if (k === Math.ceil(praduration.value / 7) - 1) {
                                if (cellEnd.value) {
                                    cellEnd = worksheet.getCell((15 + (i * 15) + 1), startCol);
                                    worksheet.mergeCells(`${getColumnLetterFromIndex(startCol)}${(15 + (i * 15) + 1)}:${getColumnLetterFromIndex(startCol)}${(15 + (i * 15) + final)}`);
                                    cellEnd.fill = {
                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FFADD8E6' },
                                    };
                                    cellEnd.font = {
                                        size: 6
                                    };
                                    cellEnd.alignment = {
                                        horizontal: 'center',
                                        vertical: 'middle',
                                        wrapText: true,
                                    };
                                    cellEnd.value = "PRA"
                                } else {
                                    worksheet.mergeCells(`${getColumnLetterFromIndex(startCol)}${(15 + (i * 15))}:${getColumnLetterFromIndex(startCol)}${(15 + (i * 15) + (final - 1))}`);
                                    cellEnd.fill = {
                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FFADD8E6' },
                                    };
                                    cellEnd.font = {
                                        size: 6
                                    };
                                    cellEnd.alignment = {
                                        horizontal: 'center',
                                        vertical: 'middle',
                                        wrapText: true,
                                    };
                                    cellEnd.value = "PRA"
                                }
                            } else {
                                soma = soma + 7

                                if (cellEnd.value) {

                                    cellEnd = worksheet.getCell((15 + (i * 15) + 1), startCol);
                                    worksheet.mergeCells(`${getColumnLetterFromIndex(startCol)}${(15 + (i * 15) + 1)}:${getColumnLetterFromIndex(startCol)}${(15 + (i * 15) + 7)}`);
                                    cellEnd.fill = {
                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FFADD8E6' },
                                    };
                                    cellEnd.font = {
                                        size: 6
                                    };
                                    cellEnd.alignment = {
                                        horizontal: 'center',
                                        vertical: 'middle',
                                        wrapText: true,
                                    };
                                    cellEnd.value = "PRA"
                                }
                                else {
                                    worksheet.mergeCells(`${getColumnLetterFromIndex(startCol)}${(15 + (i * 15))}:${getColumnLetterFromIndex(startCol)}${(15 + (i * 15) + (7 - 1))}`);
                                    cellEnd.fill = {
                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FFADD8E6' },
                                    };
                                    cellEnd.font = {
                                        size: 6
                                    };
                                    cellEnd.alignment = {
                                        horizontal: 'center',
                                        vertical: 'middle',
                                        wrapText: true,
                                    };
                                    cellEnd.value = "PRA"
                                }
                            }
                        }
                    }
                    else {
                        const startCol = (getFirstDayOfMonth(anoInicio, (mesInicio + i))) + 2 + (getLastUtilDayOfMonth(anoInicio, (mesInicio + i)))
                        let cellEnd = worksheet.getCell((15 + (i * 15)), startCol);
                        if (cellEnd.value) {
                            cellEnd = worksheet.getCell((15 + (i * 15) + 1), startCol);
                            worksheet.mergeCells(`${getColumnLetterFromIndex(startCol)}${(15 + (i * 15) + 1)}:${getColumnLetterFromIndex(startCol)}${(15 + (i * 15) + 7)}`);
                            cellEnd.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFADD8E6' },
                            };
                            cellEnd.font = {
                                size: 6
                            };
                            cellEnd.alignment = {
                                horizontal: 'center',
                                vertical: 'middle',
                                wrapText: true,
                            };
                            cellEnd.value = "PRA"
                        }
                        else {
                            worksheet.mergeCells(`${getColumnLetterFromIndex(startCol)}${(15 + (i * 15))}:${getColumnLetterFromIndex(startCol)}${(15 + (i * 15) + (7 - 1))}`);
                            cellEnd.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFADD8E6' },
                            };
                            cellEnd.font = {
                                size: 6
                            };
                            cellEnd.alignment = {
                                horizontal: 'center',
                                vertical: 'middle',
                                wrapText: true,
                            };
                            cellEnd.value = "PRA"
                        }
                    }
                }
            }
            index++
        });
    }

    //Fim da Página Cronograma

    //INÍCIO DA PÁGINA DISTRIBUIÇÃO
    const worksheet2 = workbook.addWorksheet('Distribuição');
    worksheet2.getColumn('C').width = 12
    worksheet2.getColumn('G').width = 15
    async function distribuicao() {
        //tabela 1 - Teóricas
        function tabelaTeorica() {


            const B1 = worksheet2.getCell('B1');
            const C1 = worksheet2.getCell('C1');
            const D1 = worksheet2.getCell('D1');
            const E1 = worksheet2.getCell('E1');
            const F1 = worksheet2.getCell('F1');

            const G1 = worksheet2.getCell('G1');
            const G2 = worksheet2.getCell('G2');
            const G3 = worksheet2.getCell('G3');

            worksheet2.mergeCells("B1:B3")
            worksheet2.mergeCells("C1:C3")
            worksheet2.mergeCells("D1:D3")
            worksheet2.mergeCells("E1:E3")
            worksheet2.mergeCells("F1:F3")

            B1.value = "Ano"
            C1.value = "Mês"
            D1.value = "Dias Úteis"
            E1.value = "Horas"
            F1.value = "Total Mensal"

            G1.value = "UFCD"
            G2.value = "Horas UFCD"
            G3.value = "Horas Teóricas"

            B1.alignment = alignment
            C1.alignment = alignment
            D1.alignment = alignment
            E1.alignment = alignment
            F1.alignment = alignment

            G1.alignment = alignment
            G2.alignment = alignment
            G3.alignment = alignment

            B1.font = bold
            C1.font = bold
            D1.font = bold
            E1.font = bold
            F1.font = bold

            G1.font = bold
            G2.font = bold
            G3.font = bold

            B1.border = borderThin
            C1.border = borderThin
            D1.border = borderThin
            E1.border = borderThin
            F1.border = borderThin

            G1.border = borderThin
            G2.border = borderThin
            G3.border = borderThin


            B1.fill = colorH
            C1.fill = colorH
            D1.fill = colorH
            E1.fill = colorH
            F1.fill = colorH

            G1.fill = colorH
            G2.fill = colorH
            G3.fill = colorH

            let i = 0
            saveDisciplinas.forEach(ufcd => {
                if (ufcd.name !== "PRA") {

                    const diferenca = DateDifference(ufcd.dateBegin, ufcd.dateEnd)
                    const code = worksheet2.getCell(`${getColumnLetterFromIndex(8 + i)}${1}`);
                    const hora = worksheet2.getCell(`${getColumnLetterFromIndex(8 + i)}${2}`);
                    const horaT = worksheet2.getCell(`${getColumnLetterFromIndex(8 + i)}${3}`);
                    let somaValue = 0
                    for (let j = ufcd.monthStart; j <= diferenca + ufcd.monthStart; j++) {
                        const dist = worksheet2.getCell(`${getColumnLetterFromIndex(8 + i)}${3 + (j - mesInicio)}`);
                        if (j === diferenca + ufcd.monthStart) {
                            dist.value = ufcd.TTime - somaValue
                        }
                        else {
                            dist.value = parseInt(ufcd.TTime / (diferenca + 1))
                            somaValue = somaValue + parseInt(ufcd.TTime / (diferenca + 1))
                        }
                    }

                    code.value = ufcd.codeUfcd
                    hora.value = parseInt(ufcd.cargaHoraria)
                    horaT.value = ufcd.TTime

                    code.border = borderThin
                    hora.border = borderThin
                    horaT.border = borderThin

                    code.alignment = alignment
                    hora.alignment = alignment
                    horaT.alignment = alignment

                    code.fill = color3
                    hora.fill = color3
                    horaT.fill = color1

                    i++

                }
            });
            let pracont = 0
            if (userData.modalidade === "Cursos de Educação e Formação de Adultos") {
                const PraCell = worksheet2.getCell(`${getColumnLetterFromIndex(8 + i)}${1}`);
                const PraCellValue = worksheet2.getCell(`${getColumnLetterFromIndex(8 + i)}${2}`);
                const PraCellValue2 = worksheet2.getCell(`${getColumnLetterFromIndex(8 + i)}${3}`);
                const FPCT = worksheet2.getCell(`${getColumnLetterFromIndex(8 + i + 1)}${1}`);
                const FPCTValue = worksheet2.getCell(`${getColumnLetterFromIndex(8 + i + 1)}${2}`);
                const FPCTValue2 = worksheet2.getCell(`${getColumnLetterFromIndex(8 + i + 1)}${3}`);

                PraCell.value = "PRA"
                PraCellValue.value = 65
                PraCellValue2.value = 0
                FPCT.value = "FPCT"
                FPCTValue.value = userData.duration
                FPCTValue2.value = 0

                PraCell.border = borderThin
                PraCellValue.border = borderThin
                PraCellValue2.border = borderThin
                FPCT.border = borderThin
                FPCTValue.border = borderThin
                FPCTValue2.border = borderThin

                PraCell.alignment = alignment
                PraCellValue.alignment = alignment
                PraCellValue2.alignment = alignment
                FPCT.alignment = alignment
                FPCTValue.alignment = alignment
                FPCTValue2.alignment = alignment

                PraCell.fill = color3
                PraCellValue.fill = color3
                PraCellValue2.fill = color1
                FPCT.fill = color3
                FPCTValue.fill = color3
                FPCTValue2.fill = color1

                pracont++
            }
            for (let row = 4; row <= 4 + difMeses; row++) {
                const cell1 = worksheet2.getCell(row, 2);
                cell1.border = borderThin
                if ((mesInicio + 1) + (row - (4)) > 12) {
                    let cont = 0
                    for (let c = 1; c <= ((mesInicio + 1) + (row - (4))); c++) {
                        if (c <= 14) {
                            if (c % 12 === 0) {
                                cont++
                                cell1.value = anoInicio + cont
                            }
                        }
                        else {
                            if ((c - 1) % 12 === 0) {
                                cont++
                                cell1.value = anoInicio + cont
                            }
                        }
                    }
                }
                else cell1.value = anoInicio
                cell1.fill = color4
                cell1.alignment = alignment
                const cell2 = worksheet2.getCell(row, 3);
                cell2.border = borderThin
                cell2.alignment = alignment
                cell2.fill = color4
                cell2.value = getMonthName(mesInicio + 1 + (row - 4))

                const cell3 = worksheet2.getCell(row, 4);
                cell3.border = borderThin
                cell3.alignment = alignment
                cell3.fill = color4
                cell3.value = getWeekdayCountWithHolidays(cell1.value, mesInicio + 1 + (row - 4))

                const cell4 = worksheet2.getCell(row, 5);
                cell4.border = borderThin
                cell4.alignment = alignment
                cell4.fill = color4
                cell4.value = cell3 * 7

                const cell5 = worksheet2.getCell(row, 6);
                cell5.border = borderThin
                cell5.alignment = alignment
                cell5.fill = color4
                let total = 0
                const cell5Teo = worksheet2.getCell(row + (difMeses - i) + 4 + (i + 1), 6);
                for (let col = 7; col <= 2 + 5 + i + pracont; col++) {
                    const cell = worksheet2.getCell(row, col);
                    total = total + cell.value
                }
                cell5.value = total
                for (let col = 2 + 5 + i; col >= 7; col--) {
                    const cell = worksheet2.getCell(row, col);
                    if (col === 2 + 5) {
                        cell.fill = color4
                    }
                    const cellNext = worksheet2.getCell(row + 1, col);
                    cell.alignment = alignment
                    cellNext.alignment = alignment
                    if (cell.value && cell.value > 5) {
                        for (let j = cell.value; j > 5; j--) {
                            if (cell5.value + cell5Teo.value > cell4.value - 1) {
                                cell.value--;
                                cell5.value--;
                                cellNext.value++;
                            }
                        }
                    }
                }
                for (let col = 7; col <= 2 + 5 + i + (2 * pracont); col++) {
                    const restCells = worksheet2.getCell(row, col);
                    restCells.alignment = alignment
                    restCells.border = borderThin
                }
            }
        }

        function tabelaPratica() {
            const start = 3 + difMeses + 1 + 2 // 3 espaços de cabeçalho + Quantidade de meses + 1 (Adiciona pois os meses começam no 0) + 2 de espaçamento
            const B1 = worksheet2.getCell('B' + (start));
            const C1 = worksheet2.getCell('C' + (start));
            const D1 = worksheet2.getCell('D' + (start));
            const E1 = worksheet2.getCell('E' + (start));
            const F1 = worksheet2.getCell('F' + (start));

            const G1 = worksheet2.getCell('G' + (start));
            const G2 = worksheet2.getCell('G' + (start + 1));
            const G3 = worksheet2.getCell('G' + (start + 2));

            worksheet2.mergeCells(`${"B" + (start)}:${"B" + (start + 2)}`)
            worksheet2.mergeCells(`${"C" + (start)}:${"C" + (start + 2)}`)
            worksheet2.mergeCells(`${"D" + (start)}:${"D" + (start + 2)}`)
            worksheet2.mergeCells(`${"E" + (start)}:${"E" + (start + 2)}`)
            worksheet2.mergeCells(`${"F" + (start)}:${"F" + (start + 2)}`)

            B1.value = "Ano"
            C1.value = "Mês"
            D1.value = "Dias Úteis"
            E1.value = "Horas"
            F1.value = "Total Mensal"

            G1.value = "UFCD"
            G2.value = "Horas UFCD"
            G3.value = "Horas Práticas"

            B1.alignment = alignment
            C1.alignment = alignment
            D1.alignment = alignment
            E1.alignment = alignment
            F1.alignment = alignment

            G1.alignment = alignment
            G2.alignment = alignment
            G3.alignment = alignment

            B1.font = bold
            C1.font = bold
            D1.font = bold
            E1.font = bold
            F1.font = bold

            G1.font = bold
            G2.font = bold
            G3.font = bold

            B1.border = borderThin
            C1.border = borderThin
            D1.border = borderThin
            E1.border = borderThin
            F1.border = borderThin

            G1.border = borderThin
            G2.border = borderThin
            G3.border = borderThin

            B1.fill = colorH
            C1.fill = colorH
            D1.fill = colorH
            E1.fill = colorH
            F1.fill = colorH

            G1.fill = colorH
            G2.fill = colorH
            G3.fill = colorH

            let i = 0
            saveDisciplinas.forEach(ufcd => {
                if (ufcd.name !== "PRA") {
                    const diferenca = DateDifference(ufcd.dateBegin, ufcd.dateEnd)
                    const code = worksheet2.getCell(`${getColumnLetterFromIndex(8 + i)}${start}`);
                    const hora = worksheet2.getCell(`${getColumnLetterFromIndex(8 + i)}${start + 1}`);
                    const horaT = worksheet2.getCell(`${getColumnLetterFromIndex(8 + i)}${start + 2}`);
                    let somaValue = 0
                    let ind = 0
                    let time = ufcd.PTime
                    for (let j = ufcd.monthStart; j <= diferenca + ufcd.monthStart; j++) {
                        ind++
                        const dist = worksheet2.getCell(`${getColumnLetterFromIndex(8 + i)}${(start + 2) + (j - mesInicio)}`);
                        if (j === diferenca + ufcd.monthStart) {
                            dist.value = ufcd.PTime - somaValue
                        }
                        else {
                            if (ufcd.PTime < 7) {
                                if (time === ufcd.PTime) {
                                    dist.value = ufcd.PTime
                                    somaValue = somaValue + ufcd.PTime
                                    time = 0
                                } else dist.value = 0
                            }

                            else {
                                let cont = (diferenca + 1)
                                dist.value = parseInt(ufcd.PTime / cont)
                                if (dist.value <= 10 && dist.value >= 6) {
                                    dist.value = 7
                                }
                                else if (dist.value > 10) {
                                    const differ = parseInt(dist.value / 7)
                                    dist.value = 7 * differ
                                }
                                else dist.value = 4
                                somaValue = somaValue + dist.value
                            }

                        }
                    }

                    code.value = ufcd.codeUfcd
                    hora.value = parseInt(ufcd.cargaHoraria)
                    horaT.value = ufcd.PTime

                    code.border = borderThin
                    hora.border = borderThin
                    horaT.border = borderThin

                    code.alignment = alignment
                    hora.alignment = alignment
                    horaT.alignment = alignment

                    code.fill = color3
                    hora.fill = color3
                    horaT.fill = color2

                    i++
                }
            });
            let pracont = 0
            if (userData.modalidade === "Cursos de Educação e Formação de Adultos") {
                const PraCell = worksheet2.getCell(`${getColumnLetterFromIndex(8 + i)}${start}`);
                const PraCellValue = worksheet2.getCell(`${getColumnLetterFromIndex(8 + i)}${start + 1}`);
                const PraCellValue2 = worksheet2.getCell(`${getColumnLetterFromIndex(8 + i)}${start + 2}`);
                const FPCT = worksheet2.getCell(`${getColumnLetterFromIndex(8 + i + 1)}${start}`);
                const FPCTValue = worksheet2.getCell(`${getColumnLetterFromIndex(8 + i + 1)}${start + 1}`);
                const FPCTValue2 = worksheet2.getCell(`${getColumnLetterFromIndex(8 + i + 1)}${start + 2}`);

                PraCell.value = "PRA"
                PraCellValue.value = 65
                PraCellValue2.value = 65
                FPCT.value = "FPCT"
                FPCTValue.value = userData.duration
                FPCTValue2.value = 0

                PraCell.border = borderThin
                PraCellValue.border = borderThin
                PraCellValue2.border = borderThin
                FPCT.border = borderThin
                FPCTValue.border = borderThin
                FPCTValue2.border = borderThin

                PraCell.alignment = alignment
                PraCellValue.alignment = alignment
                PraCellValue2.alignment = alignment
                FPCT.alignment = alignment
                FPCTValue.alignment = alignment
                FPCTValue2.alignment = alignment

                PraCell.fill = color3
                PraCellValue.fill = color3
                PraCellValue2.fill = color2
                FPCT.fill = color3
                FPCTValue.fill = color3
                FPCTValue2.fill = color2

                let somaValue = 0
                const diff = DateDifference(inicio, fim)
                let ind = 0
                for (let j = start; j <= diff + start; j++) {
                    const dist = worksheet2.getCell(`${getColumnLetterFromIndex(8 + i)}${(start + 3) + (ind)}`);

                    if (j === diff + start) {
                        const remainingValue = PraCellValue.value - somaValue;
                        const adjustedValue = Math.max(remainingValue, 4);
                        dist.value = adjustedValue;
                    } else {
                        const maxAllowedValue = 7;
                        const minAllowedValue = 4;
                        const remainingValue = PraCellValue.value - somaValue;
                        const maxPossibleValue = Math.min(maxAllowedValue, remainingValue);

                        const calculatedValue = parseInt(remainingValue / (diff - (j - start)));
                        const adjustedValue = Math.min(Math.max(calculatedValue, minAllowedValue), maxPossibleValue);

                        dist.value = adjustedValue;
                        somaValue += adjustedValue;
                    }
                    ind++;
                }
                pracont++;
            }

            for (let row = 3 + start; row <= 3 + difMeses + start; row++) {
                const cell1 = worksheet2.getCell(row, 2);
                cell1.border = borderThin
                if ((mesInicio + 1) + (row - (3 + start)) > 12) {
                    let cont = 0
                    for (let c = 1; c <= (mesInicio + 1) + (row - (3 + start)); c++) {
                        if (c <= 14) {
                            if (c % 12 === 0) {
                                cont++
                                cell1.value = anoInicio + cont
                            }
                        }
                        else {
                            if ((c - 1) % 12 === 0) {
                                cont++
                                cell1.value = anoInicio + cont
                            }
                        }
                    }
                }
                else cell1.value = anoInicio
                cell1.fill = color4
                cell1.alignment = alignment
                const cell2 = worksheet2.getCell(row, 3);
                cell2.border = borderThin
                cell2.alignment = alignment
                cell2.fill = color4
                cell2.value = getMonthName(mesInicio + 1 + (row - (3 + start)))

                const cell3 = worksheet2.getCell(row, 4);
                cell3.border = borderThin
                cell3.alignment = alignment
                cell3.fill = color4
                cell3.value = getWeekdayCountWithHolidays(cell1.value, mesInicio + 1 + (row - (3 + start)))

                const cell4 = worksheet2.getCell(row, 5);
                cell4.border = borderThin
                cell4.alignment = alignment
                cell4.fill = color4
                cell4.value = cell3 * 7

                const cell5 = worksheet2.getCell(row, 6);
                cell5.border = borderThin
                cell5.alignment = alignment
                cell5.fill = color4
                let total = 0
                for (let col = 7; col <= 2 + 5 + i + pracont; col++) {
                    const cell = worksheet2.getCell(row, col);
                    total = total + cell.value
                }
                cell5.value = total

                for (let col = 2 + 5 + i; col >= 7; col--) {
                    const cellTotal = worksheet2.getCell(row + 1 - start, 6);
                    const cell = worksheet2.getCell(row, col);
                    if (col === 2 + 5) {
                        cell.fill = color4
                    }
                    const cellNext = worksheet2.getCell(row + 1, col);
                    cell.alignment = alignment
                    cellNext.alignment = alignment
                    if (cell.value && cell.value > 4) {
                        for (let j = cell.value; j > 4; j--) {
                            if (cell5.value + cellTotal.value > cell4.value + 5) {
                                cell.value--;
                                cell5.value--;
                                cellNext.value++;
                            }
                        }
                    }
                }

                for (let col = 7; col <= 2 + 5 + i + (2 * pracont); col++) {
                    const restCells = worksheet2.getCell(row, col);
                    restCells.alignment = alignment
                    restCells.border = borderThin
                }
            }
        }
        await tabelaPratica()
        await tabelaTeorica()
    }
    await distribuicao()
    await cronograma()
    //FIM DA PÁGINA DISTRIBUIÇÃO

    // Generate the Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Convert the buffer to a Blob
    const blob = new Blob([buffer], { type: 'application/octet-stream' });

    // Convert the Blob to a FormData object
    const formData = new FormData();
    formData.append('file', blob, `cronogramaOriginal${userData.name}.xlsx`); // Explicitly set the filename with .xlsx extension

    fetch(`${process.env.REACT_APP_API_URL}/cronograma/Geral/${userData._id}`, {
        method: "POST",
        credentials: "same-origin",
        body: formData
    })
        .then((res) => res.json())
        .then(
            (result) => {
                // Handle the response from the server
            },
            (error) => {
                // Handle any errors
            }
        );
};


export default excelGenerator;