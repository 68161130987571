import React, { useState } from 'react';
import Header from '../../../../components/Header';
import { AreaPassword } from './styled';
import { useParams, useNavigate } from 'react-router-dom';
import toastSuccess from '../../../../components/Toast/toastSuccess';
import 'react-toastify/dist/ReactToastify.css';
import toastError from '../../../../components/Toast/toastError';
import Footer from '../../../../components/Footer';

function ResetPassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const params = useParams();
    const navigate = useNavigate();
    const [showPassword1, setShowPassword1] = useState(false)
    const [showPassword2, setShowPassword2] = useState(false)
    //Mostrar pass
    const toggleShowPassword = (field) => {
        if (field === 1) {
            setShowPassword1(!showPassword1);
        } else if (field === 2) {
            setShowPassword2(!showPassword2);
        }
    };

    //Submete a nova password na bd
    //Função de submit
    async function handleSubmit(event) {
        event.preventDefault();

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/formandos/login/password/${params.token}`, {
                method: 'PATCH',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                body: JSON.stringify({ password: password, confirmPassword: confirmPassword }),

            });
            if (password !== confirmPassword) {
                toastError('As Palavras-passe não coincidem!');
                return;
            }
            const data = await response.json();
            toastSuccess('Palavra-passe atualizada com sucesso!')
            localStorage.removeItem('jwt');
            navigate(`/loginformando`);

        } catch (error) {
            toastError('Ocorreu um erro ao atualizar a Palavra-passe. Tente novamente mais tarde!');
        }

    };

    return (
        <>
            <Header />
            <AreaPassword>
                <main>
                    <div className="container">
                        <div className='title-pass'>
                            <h1>Redefinir Palavra-passe</h1>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className='new-pass'>
                                <label htmlFor="password">Nova Palavra-passe</label>
                                <div className='password-input-wraper'>
                                    <input
                                        type={showPassword1 ? 'text' : 'password'}
                                        id="password"
                                        minLength={8}
                                        value={password}
                                        onChange={(event) => setPassword(event.target.value)}
                                        required
                                    />
                                    <span className='eye-button-container'>
                                        <button type="button" onClick={() => toggleShowPassword(1)} className="eye-button">
                                            <i className={showPassword1 ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                            <div className='new-pass'>
                                <label htmlFor="confirmPassword">Confirmar nova Palavra-passe</label>
                                <div className='password-input-wraper'>
                                    <input
                                        type={showPassword2 ? 'text' : 'password'}
                                        id="confirmPassword"
                                        minLength={8}
                                        value={confirmPassword}
                                        onChange={(event) => setConfirmPassword(event.target.value)}
                                        required
                                    />
                                    <span className='eye-button-container'>
                                        <button type="button" onClick={() => toggleShowPassword(2)} className="eye-button">
                                            <i className={showPassword2 ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                            <div >
                                <button className='button-submit' type="submit">
                                    Confirmar
                                </button>
                            </div>
                        </form>
                    </div>
                </main>
            </AreaPassword>
            <Footer />
        </>
    );
}

export default ResetPassword;
