import React, { useState } from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import { Link } from "react-router-dom";
import toastError from "../../../../components/Toast/toastError";
import toastSuccess from "../../../../components/Toast/toastSuccess";

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [data, setData] = useState([]);

    //Função que mostra mensagens de erros
    function verifyEmail(e) {
        e.preventDefault()
        getEmail().then((data) => {
            if (data.status === "not_found" || data.status === "not_valid") {
                toastError(data.message)
            }
            else if (data.status === 'valid') {
                toastSuccess(data.message)
            }
        })
    }

    //Função que verifica se o mail existe 
    function getEmail() {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}/coordenadores/login/email`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        email: email
                    }),
                })
                .then(res => res.json())
                .then((result) => {
                    localStorage.setItem('jwt', result.jwt)
                    setData(result)
                    resolve(result)

                }, (error) => {
                    console.error("Error fetching data: ", error);
                    reject(error)
                }
                )
        });
    }

    return (
        <>
            <main className='min-h-screen'>
                <Header />
                <div className='back-button-div-noNavbar'>
                    <Link to={'/logincoordenador'} className="">
                        <img className='back-button-img' src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} alt="Back" />
                    </Link>
                </div>
                <div className='flex flex-col flex-grow items-center justify-center -mt-16'>
                    <h1 className='title'>Enviar mail de recuperação</h1>
                    <div>
                        <div className="text-text-second-color">
                            <h1>Insira o seu E-mail para recuperar a Palavra-passe</h1>
                        </div>
                        <div id="email-form">
                            <input type="email" id="email" className="input-field-long" value={email} onChange={(event) => setEmail(event.target.value)} />
                        </div>
                        <button className="button-geral mt-5 sm:mb-0 mb-16" onClick={verifyEmail}>Enviar</button>
                    </div>
                </div>
                <div className="w-full sm:bottom-0 mt-24 sm:mt-0">
                    <Footer />
                </div>
            </main>
        </>
    );
}

export default ForgotPassword