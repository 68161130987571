import React, { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import Footer from "../../../../../components/Footer";
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";
import AlterarFormato from "../../../../../components/FormChecks/formatDates/foramatDates";
import NavbarCoordenador from "../../../../../components/Navbars/NavbarCoordenador/navbarCoordenador";
import Header from "../../../../../components/Header";
export default function UFCDsIndividual() {
    const [data, setData] = useState([]);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [loading, setLoading] = useState(true);
    const [media, setMedia] = useState(0);
    const [error, setError] = useState(null);
    const savedSearchTerm = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Coord-listTrainerUfcdInd-1") : "";
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm ? savedSearchTerm : "");
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Coord-listTrainerUfcdInd-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Coord-listTrainerUfcdInd-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)
    //Data atual
    const year = new Date().getFullYear()
    const month = new Date().getMonth()
    const day = new Date().getDate();

    const dateNow = new Date(year, month, day)
    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        updatePagination(data) // n é o state por causa das tabelas
    }


    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }

    //filtra dados
    function filterData(data) {
        return data.filter((item) => {
            const normalizedSearchTerm = searchTerm.toLowerCase();

            return (
                (item.name.toLowerCase().includes(normalizedSearchTerm)) ||
                (item.codeUfcd.toLowerCase().includes(normalizedSearchTerm)) ||
                (item.percurso[0].name.toLowerCase().includes(normalizedSearchTerm)) ||
                (!item.evaluation ? "Em Atividade".toLowerCase().includes(normalizedSearchTerm) : item.evaluation.toLowerCase().includes(normalizedSearchTerm))
            );

        });
    }

    const params = useParams();

    document.data = data

    function UFCDsSemFormador() {
        const seletor = document.getElementById('seletor')
        if (seletor) {
            // Display Inicial
            seletor.innerHTML = '<option value="" disabled selected>Selecionar UFCD</option>';
            fetch(`${process.env.REACT_APP_API_URL}/ufcds/semFormador`, {
                headers: {
                    "authorization": localStorage.getItem('coord')
                }
            })
                .then(response => response.json())
                .then(result => {
                    // Construir o Seletor
                    result.forEach(ufcd => {
                        const option = document.createElement('option');

                        option.value = ufcd._id;
                        option.innerHTML = ufcd.name ?? "vazio";
                        seletor.appendChild(option);
                    });
                });
        }

    }

    function alterarEstadoAtivo() {
        const id = params.id_candidato;
        fetch(`${process.env.REACT_APP_API_URL}/admins/${id}/estados/ativo`,
            {
                method: "PATCH",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "authorization": localStorage.getItem('coord')
                }
            }).then((result) => {
                result.json().then((resp) => {

                })

            })
    }

    function alterarEstadoInativo() {
        const id = params.id_candidato;
        fetch(`${process.env.REACT_APP_API_URL}/admins/${id}/estados/inativo`,
            {
                method: "PATCH",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "authorization": localStorage.getItem('coord')
                }
            }).then((result) => {
                result.json().then((resp) => {

                })
            })
    }

    function addOneYear(date) {
        date.setFullYear(date.getFullYear() + 1);
        return date;
    }


    function alterarEstado(date) {
        AlterarFormato(date)
        let dateLastUfcd = new Date(date)
        let dateLastUfcdMax = addOneYear(dateLastUfcd)
        if (dateLastUfcdMax > dateNow) {
            alterarEstadoAtivo()
        }
        else {
            alterarEstadoInativo()
        }
    }
    function getUfcds() {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/trainer/sorted/${params.id_candidato}`,
            {
                headers: {
                    'authorization': localStorage.getItem('coord')
                }
            })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result)
                    paginacaoTabelas(filterData(result));
                    let data2 = []
                    function getUfcdsAll() {
                        fetch(`${process.env.REACT_APP_API_URL}/ufcds/trainer/${params.id_candidato}`,
                            {
                                headers: {
                                    'authorization': localStorage.getItem('coord')
                                }
                            })
                            .then((res) => res.json())
                            .then(
                                (resultado) => {
                                    data2 = resultado
                                    if (data2.length > 0) {
                                        alterarEstado(data2[0].dateEnd)
                                        setLoading(false);
                                    }
                                    else {
                                        alterarEstadoInativo()
                                        setLoading(false);
                                    }
                                    let mediac = 0
                                    if (data.length > 0) {
                                        for (let i = 0; i < data.length; i++) {
                                            mediac = mediac + data[i].evaluation
                                        }
                                        mediac = Math.round(mediac / data.length);
                                    }
                                    setMedia(mediac)
                                },
                                (error) => {
                                    console.error("Error fetching data: ", error);
                                    setError(error);
                                }
                            );
                    }
                    UFCDsSemFormador()
                    getUfcdsAll()


                    setLoading(false)
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );

    }
    useEffect(() => {
        Cookies.set("Coord-listTrainerUfcdInd-Pag", paginaAtual);
        Cookies.set("Coord-listTrainerUfcdInd-1", searchTerm);
        Cookies.set(`Coord-listTrainerUfcdInd-itm1`, itemsPag)
        getUfcds()

    }, [loading, paginaAtual, nPaginas, searchTerm, itemsPag]);

    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }
    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarCoordenador currentPage={"ListaFormador"} />
                </div>
                <Link to={'/menucoordenador/' + params.id_candidatura + '/list'} className="back-button-div-noNavbar">
                    <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                </Link>
                <div className="relative h-full col-span-5 mx-4 xl:col-span-8">
                <Header sideMenu={"true"} />
                    <h1 className="mt-3 title">Lista de disciplinas recentes do Formador</h1>
                    {data && data.length > 0 ? <> <div className="search-div">
                        <label className="search-input-label">Pesquisar: </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input type="text" className="search-input" placeholder="ex: Nome, Código..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                        </div>
                    </div>
                        <div className="flex flex-col">
                            <div className="flex justify-center items-center mb-1">
                                <label className="text-text-main-color">Dados por página: </label>
                                <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                    value={itemsPag}
                                    onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div className="table-container">
                                <table className="my-table">

                                    <thead className="bg-main-color">
                                        <tr className="text-white h-14">
                                            <th className="p-2 min-w-[150px] border border-white">Código da disciplina</th>
                                            <th className="p-2 min-w-[150px] border border-white">Nome</th>
                                            <th className="p-2 min-w-[150px] border border-white">Nome do curso</th>
                                            <th className="p-2 min-w-[150px] border border-white">Data inicio</th>
                                            <th className="p-2 min-w-[150px] border border-white">Data final</th>
                                            <th className="p-2 min-w-[150px] border border-white">Avaliação</th>
                                        </tr>
                                    </thead>
                                    {data.length > 0 ? (
                                        <tbody className="text-text-main-color">
                                            {filterData(dataToDisplay).map((item, index) => (
                                                <tr key={index}>
                                                    <td className="border border-white">{item.codeUfcd}</td>
                                                    <td className="border border-white">{item.name}</td>
                                                    {item.percurso[0] ? <td className="border border-white ufcd">{item.percurso[0].name}</td> : <td className="border border-white">Sem curso</td>}
                                                    <td className="border border-white date-input">{AlterarFormato(item.dateBegin)}</td>
                                                    <td className="border border-white date-input">{AlterarFormato(item.dateEnd)}</td>
                                                    <td className="border border-white">{item.evaluation !== null ? item.evaluation + "%" : "Em Atividade"}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    ) : (
                                        <tbody></tbody>
                                    )}
                                </table>

                            </div>
                            <div className="font-bold text-text-main-color">
                                MÉDIA: {media}%
                            </div>
                        </div>
                        <div className="flex items-center justify-center mt-4 mb-8">
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                <AiOutlineDoubleLeft />
                            </button>
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                <AiOutlineLeft />
                            </button>
                            <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                <AiOutlineRight />
                            </button>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                <AiOutlineDoubleRight />
                            </button>
                        </div> </> : <p className="third-title">Não existem dados</p>}
                </div>
                </main>
                <Footer />
        </>

    );
}