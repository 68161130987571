import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../Footer";
import toastSuccess from "../Toast/toastSuccess";
import 'react-toastify/dist/ReactToastify.css';
import NavbarAdmin1 from "../Navbars/NavbarAdmin1/NavbarAdmin1";
import NavbarCoordenador from "../Navbars/NavbarCoordenador/navbarCoordenador";
import Header from "../Header";
import NavbarAdmin2 from "../Navbars/NavbarAdmin2/NavbarAdmin2";

function GerirDTP(token) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState("");
    const [cont, setCont] = useState(0)

    // State to keep track of selected checkboxes
    const [selectedCheckboxes0, setSelectedCheckboxes0] = useState([]);
    const [selectedCheckboxes1, setSelectedCheckboxes1] = useState([]);
    const [selectedCheckboxes2, setSelectedCheckboxes2] = useState([]);
    const [selectedCheckboxes31, setSelectedCheckboxes31] = useState([]);
    const [selectedCheckboxes32, setSelectedCheckboxes32] = useState([]);
    const [selectedCheckboxes4, setSelectedCheckboxes4] = useState([]);
    const [selectedCheckboxes5, setSelectedCheckboxes5] = useState([]);
    const [selectedCheckboxes6, setSelectedCheckboxes6] = useState([]);
    const [selectedCheckboxes7, setSelectedCheckboxes7] = useState([]);
    const [selectedCheckboxes8, setSelectedCheckboxes8] = useState([]);
    const [selectedCheckboxes9, setSelectedCheckboxes9] = useState([]);
    const [selectedCheckboxes10, setSelectedCheckboxes10] = useState([]);
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        if (!data) {
            getPercurso()
        }
        verifySelected()
    }, [loading, selectedCheckboxes0.length, selectedCheckboxes1.length, selectedCheckboxes2.length, selectedCheckboxes31.length, selectedCheckboxes32.length, selectedCheckboxes4.length, selectedCheckboxes5.length, selectedCheckboxes6.length, selectedCheckboxes7.length, selectedCheckboxes8.length, selectedCheckboxes9.length, selectedCheckboxes10.length]);

    //Função que obtem box checked
    const handleCheckboxChange = (event, setSelectedCheckboxes) => {
        const { value, checked } = event.target;
        setSelectedCheckboxes((prevCheckboxes) => {
            const updatedCheckboxes = checked
                ? [...prevCheckboxes, value]
                : prevCheckboxes.filter((item) => item !== value);
            return updatedCheckboxes.sort((a, b) => a.localeCompare(b)); // Ordenar alfabéticamente
        });
    };
    //Dar check a todas as boxes
    function setAll(e) { //Checkar todas as checkboxes 

        let checkbox0 = document.getElementById("select0");
        let checkbox1 = document.getElementById("select1");
        let checkbox2 = document.getElementById("select2");
        let checkbox3 = document.getElementById("select3");
        let checkbox4 = document.getElementById("select4");
        let checkbox5 = document.getElementById("select5");
        let checkbox6 = document.getElementById("select6");
        let checkbox7 = document.getElementById("select7");
        let checkbox8 = document.getElementById("select8");
        let checkbox9 = document.getElementById("select9");
        let checkbox10 = document.getElementById("select10");

        let array0 = [];
        let array1 = [];
        let array2 = [];
        let array31 = [];
        let array32 = [];
        let array4 = [];
        let array5 = [];
        let array6 = [];
        let array7 = [];
        let array8 = [];
        let array9 = [];
        let array10 = [];

        if (e.target.checked === true) {
            if (data.modalidade === "Formação Ação" || data.modalidade === "Formação Empresarial Conjunta e Formação Ação") {
                topico0.forEach(topico0 => {
                    array0.push(topico0.value);
                });
                setSelectedCheckboxes0(array0);
            }
            topico1.forEach(topico1 => {
                array1.push(topico1.value);
            });
            setSelectedCheckboxes1(array1);

            topico2.forEach(topico2 => {
                array2.push(topico2.value);
            });
            setSelectedCheckboxes2(array2);

            topico31.forEach(topico31 => {
                array31.push(topico31.value);
            });
            setSelectedCheckboxes31(array31);

            topico32.forEach(topico32 => {
                array32.push(topico32.value);
            });
            setSelectedCheckboxes32(array32);

            topico4.forEach(topico4 => {
                array4.push(topico4.value);
            });
            setSelectedCheckboxes4(array4);

            topico5.forEach(topico5 => {
                array5.push(topico5.value);
            });
            setSelectedCheckboxes5(array5);

            topico6.forEach(topico6 => {
                array6.push(topico6.value);
            });
            setSelectedCheckboxes6(array6);

            topico7.forEach(topico7 => {
                array7.push(topico7.value);
            });
            setSelectedCheckboxes7(array7);

            topico8.forEach(topico8 => {
                array8.push(topico8.value);
            });
            setSelectedCheckboxes8(array8);

            topico9.forEach(topico9 => {
                array9.push(topico9.value);
            });
            setSelectedCheckboxes9(array9);

            topico10.forEach(topico10 => {
                array10.push(topico10.value);
            });
            setSelectedCheckboxes10(array10);

            if (data.modalidade === "Formação Ação" || data.modalidade === "Formação Empresarial Conjunta e Formação Ação") {
                checkbox0.checked = true
            }
            checkbox1.checked = true
            checkbox2.checked = true
            checkbox3.checked = true
            checkbox4.checked = true
            checkbox5.checked = true
            checkbox6.checked = true
            checkbox7.checked = true
            checkbox8.checked = true
            checkbox9.checked = true
            checkbox10.checked = true

        } else {
            if (data.modalidade === "Formação Ação" || data.modalidade === "Formação Empresarial Conjunta e Formação Ação") {
                setSelectedCheckboxes0([]);
                checkbox0.checked = false
            }

            setSelectedCheckboxes1([]);
            setSelectedCheckboxes2([]);
            setSelectedCheckboxes31([]);
            setSelectedCheckboxes32([]);
            setSelectedCheckboxes4([]);
            setSelectedCheckboxes5([]);
            setSelectedCheckboxes6([]);
            setSelectedCheckboxes7([]);
            setSelectedCheckboxes8([]);
            setSelectedCheckboxes9([]);
            setSelectedCheckboxes10([]);


            checkbox1.checked = false
            checkbox2.checked = false
            checkbox3.checked = false
            checkbox4.checked = false
            checkbox5.checked = false
            checkbox6.checked = false
            checkbox7.checked = false
            checkbox8.checked = false
            checkbox9.checked = false
            checkbox10.checked = false
        }
    }

    function selectTopico0(e) { // Selecionar todos os pontos do tópico 0 
        let checkbox = document.getElementById("selectALL")
        let array0 = [];

        if (e.target.checked === true) {
            topico0.forEach(topico0 => {
                array0.push(topico0.value);
            });
            setSelectedCheckboxes0(array0);
        } else {
            setSelectedCheckboxes0([]);
            checkbox.checked = false
        }
    }

    function selectTopico1(e) { // Selecionar todos os pontos do tópico 1 
        let checkbox = document.getElementById("selectALL")
        let array1 = [];

        if (e.target.checked === true) {
            topico1.forEach(topico1 => {
                array1.push(topico1.value);
            });
            setSelectedCheckboxes1(array1);
        } else {
            setSelectedCheckboxes1([]);
            checkbox.checked = false
        }
    }

    function selectTopico2(e) { // Selecionar todos os pontos do tópico 2 
        let checkbox = document.getElementById("selectALL")
        let array2 = [];

        if (e.target.checked === true) {
            topico2.forEach(topico2 => {
                array2.push(topico2.value);
            });
            setSelectedCheckboxes2(array2);
        } else {
            setSelectedCheckboxes2([]);
            checkbox.checked = false
        }
    }

    function selectTopico3(e) { // Selecionar todos os pontos do tópico 3 
        let checkbox = document.getElementById("selectALL")
        let array31 = [];
        let array32 = [];

        if (e.target.checked === true) {
            topico31.forEach(topico31 => {
                array31.push(topico31.value);
            });
            setSelectedCheckboxes31(array31);
            topico32.forEach(topico32 => {
                array32.push(topico32.value);
            });
            setSelectedCheckboxes32(array32);
        } else {
            setSelectedCheckboxes31([]);
            setSelectedCheckboxes32([]);
            checkbox.checked = false
        }
    }

    function selectTopico4(e) { // Selecionar todos os pontos do tópico 4 
        let checkbox = document.getElementById("selectALL")
        let array4 = [];

        if (e.target.checked === true) {
            topico4.forEach(topico4 => {
                array4.push(topico4.value);
            });
            setSelectedCheckboxes4(array4);
        } else {
            setSelectedCheckboxes4([]);
            checkbox.checked = false
        }
    }

    function selectTopico5(e) { // Selecionar todos os pontos do tópico 4 
        let checkbox = document.getElementById("selectALL")
        let array5 = [];

        if (e.target.checked === true) {
            topico5.forEach(topico5 => {
                array5.push(topico5.value);
            });
            setSelectedCheckboxes5(array5);
        } else {
            setSelectedCheckboxes5([]);
            checkbox.checked = false
        }
    }

    function selectTopico6(e) { // Selecionar todos os pontos do tópico 6 
        let checkbox = document.getElementById("selectALL")
        let array6 = [];

        if (e.target.checked === true) {
            topico6.forEach(topico6 => {
                array6.push(topico6.value);
            });
            setSelectedCheckboxes6(array6);

        } else {
            setSelectedCheckboxes6([]);
            checkbox.checked = false
        }
    }

    function selectTopico7(e) { // Selecionar todos os pontos do tópico 7 
        let checkbox = document.getElementById("selectALL")
        let array7 = [];

        if (e.target.checked === true) {
            topico7.forEach(topico7 => {
                array7.push(topico7.value);
            });
            setSelectedCheckboxes7(array7);
        } else {
            setSelectedCheckboxes7([]);
            checkbox.checked = false
        }
    }

    function selectTopico8(e) { // Selecionar todos os pontos do tópico 8
        let checkbox = document.getElementById("selectALL")
        let array8 = [];

        if (e.target.checked === true) {
            topico8.forEach(topico8 => {
                array8.push(topico8.value);
            });
            setSelectedCheckboxes8(array8);
        } else {
            setSelectedCheckboxes8([]);
            checkbox.checked = false
        }
    }

    function selectTopico9(e) { // Selecionar todos os pontos do tópico 9 
        let checkbox = document.getElementById("selectALL")
        let array9 = [];

        if (e.target.checked === true) {
            topico9.forEach(topico9 => {
                array9.push(topico9.value);
            });
            setSelectedCheckboxes9(array9);
        } else {
            setSelectedCheckboxes9([]);
            checkbox.checked = false
        }
    }

    function selectTopico10(e) { // Selecionar todos os pontos do tópico 9 
        let checkbox = document.getElementById("selectALL")
        let array10 = [];

        if (e.target.checked === true) {
            topico10.forEach(topico10 => {
                array10.push(topico10.value);
            });
            setSelectedCheckboxes10(array10);
        } else {
            setSelectedCheckboxes10([]);
            checkbox.checked = false
        }
    }

    //Obter Percurso
    //Obtem percurso
    function getPercurso() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "authorization": localStorage.getItem('admin2')
            },
            method: 'GET',
        })
            .then(res => res.json())
            .then((result) => {
                setData(result)
                if (result.indice.topico1) {
                    setSelectedCheckboxes1(result.indice.topico1)
                }
                if (result.indice.topico2) {
                    setSelectedCheckboxes2(result.indice.topico2)
                }
                if (result.indice.topico31) {
                    setSelectedCheckboxes31(result.indice.topico31)
                }
                if (result.indice.topico32) {
                    setSelectedCheckboxes32(result.indice.topico32)
                }
                if (result.indice.topico4) {
                    setSelectedCheckboxes4(result.indice.topico4)
                }
                if (result.indice.topico5) {
                    setSelectedCheckboxes5(result.indice.topico5)
                }
                if (result.indice.topico6) {
                    setSelectedCheckboxes6(result.indice.topico6)
                }
                if (result.indice.topico7) {
                    setSelectedCheckboxes7(result.indice.topico7)
                }
                if (result.indice.topico8) {
                    setSelectedCheckboxes8(result.indice.topico8)
                }
                if (result.indice.topico9) {
                    setSelectedCheckboxes9(result.indice.topico9)
                }
                if (result.indice.topico10) {
                    setSelectedCheckboxes10(result.indice.topico10)
                }

                setLoading(false)
            })
    }
    //Verificar quais boxes estão selecionadas
    function verifySelected() {

        let checkbox = document.getElementById("selectALL")
        let checkbox0 = document.getElementById("select0");
        let checkbox1 = document.getElementById("select1");
        let checkbox2 = document.getElementById("select2");
        let checkbox3 = document.getElementById("select3");
        let checkbox4 = document.getElementById("select4");
        let checkbox5 = document.getElementById("select5");
        let checkbox6 = document.getElementById("select6");
        let checkbox7 = document.getElementById("select7");
        let checkbox8 = document.getElementById("select8");
        let checkbox9 = document.getElementById("select9");
        let checkbox10 = document.getElementById("select10");

        let checkSum = selectedCheckboxes1.length + selectedCheckboxes2.length + selectedCheckboxes31.length + selectedCheckboxes32.length + selectedCheckboxes4.length + selectedCheckboxes5.length + selectedCheckboxes6.length + selectedCheckboxes7.length + selectedCheckboxes8.length + selectedCheckboxes9.length + selectedCheckboxes10.length

        if (data && (data.modalidade === "Formação Ação" || data.modalidade === "Formação Empresarial Conjunta e Formação Ação")) {
            //Checkar grupo a grupo se este está totalmente preenchido
            if (selectedCheckboxes0.length < 2) {
                checkbox0.checked = false;
            } else {
                checkbox0.checked = true;
            }
        }

        if (selectedCheckboxes1.length < 8) {

            checkbox1.checked = false;
        } else {
            checkbox1.checked = true;
        }
        if (selectedCheckboxes2.length < 7) {
            checkbox2.checked = false;
        } else {
            checkbox2.checked = true;
        }
        if (selectedCheckboxes31.length + selectedCheckboxes32.length < 6) {
            checkbox3.checked = false;
        } else {
            checkbox3.checked = true;
        }
        if (selectedCheckboxes4.length < 5) {
            checkbox4.checked = false;
        } else {
            checkbox4.checked = true;
        }
        if (selectedCheckboxes5.length < 2) {
            checkbox5.checked = false;
        } else {
            checkbox5.checked = true;
        }
        if (selectedCheckboxes6.length < 4) {
            checkbox6.checked = false;
        } else {
            checkbox6.checked = true;
        }
        if (selectedCheckboxes7.length < 6) {
            checkbox7.checked = false;
        } else {
            checkbox7.checked = true;
        }
        if (selectedCheckboxes8.length < 7) {
            checkbox8.checked = false;
        } else {
            checkbox8.checked = true;
        }
        if (selectedCheckboxes9.length < 6) {
            checkbox9.checked = false;
        } else {
            checkbox9.checked = true;
        }
        if (selectedCheckboxes10.length < 2) {
            checkbox10.checked = false;
        } else {
            checkbox10.checked = true;
        }
        //Verificar se estão todos as checkbox checked
        if (data && (data.modalidade === "Formação Ação" || data.modalidade === "Formação Empresarial Conjunta e Formação Ação")) {
            checkSum += selectedCheckboxes0;
            if (checkSum === 55) {
                checkbox.checked = true;
            } else {
                checkbox.checked = false;
            }
        } else {
            if (checkSum === 53) {
                checkbox.checked = true;
            } else {
                checkbox.checked = false;
            }
        }
    }

    async function getAll() {
        try {
            const selectedCheckboxesAll = {};

            if (selectedCheckboxes0.length > 0) {
                selectedCheckboxesAll["topico0"] = selectedCheckboxes0;
            }

            if (selectedCheckboxes1.length > 0) {
                selectedCheckboxesAll["topico1"] = selectedCheckboxes1;
            }

            if (selectedCheckboxes2.length > 0) {
                selectedCheckboxesAll["topico2"] = selectedCheckboxes2;
            }

            if (selectedCheckboxes31.length > 0) {
                selectedCheckboxesAll["topico31"] = selectedCheckboxes31;
            }

            if (selectedCheckboxes32.length > 0) {
                selectedCheckboxesAll["topico32"] = selectedCheckboxes32;
            }

            if (selectedCheckboxes4.length > 0) {
                selectedCheckboxesAll["topico4"] = selectedCheckboxes4;
            }

            if (selectedCheckboxes5.length > 0) {
                selectedCheckboxesAll["topico5"] = selectedCheckboxes5;
            }

            if (selectedCheckboxes6.length > 0) {
                selectedCheckboxesAll["topico6"] = selectedCheckboxes6;
            }

            if (selectedCheckboxes7.length > 0) {
                selectedCheckboxesAll["topico7"] = selectedCheckboxes7;
            }

            if (selectedCheckboxes8.length > 0) {
                selectedCheckboxesAll["topico8"] = selectedCheckboxes8;
            }

            if (selectedCheckboxes9.length > 0) {
                selectedCheckboxesAll["topico9"] = selectedCheckboxes9;
            }

            if (selectedCheckboxes10.length > 0) {
                selectedCheckboxesAll["topico10"] = selectedCheckboxes10;
            }

            fetch(`${process.env.REACT_APP_API_URL}/percurso/gerarDTP/${params.id_curso}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "authorization": localStorage.getItem('admin2')
                },
                method: 'POST',
                body: JSON.stringify({
                    indice: selectedCheckboxesAll
                })
            })
                .then(res => res.json())
                .then((result) => {
                    setCont(cont + 1)
                    toastSuccess("DTP gerado com sucesso.")
                    navigate(-1)
                })

        } catch (error) {
            // Handle any errors that might occur during the async operations
            console.error("Error occurred:", error);
        }
    }

    // Sample list of options, you can customize it as per your needs
    const topico0 = [
        { value: 'Acordo de adesão', label: 'Acordo de adesão' },
        { value: 'Documentação da empresa', label: 'Documentação da empresa' },
    ];
    const topico1 = [
        { value: 'Ficha Técnica do curso/Programa', label: 'Ficha Técnica do curso/Programa' },
        { value: 'Check List de verificação das instalações, equipamentos e materiais', label: 'Check List de verificação das instalações, equipamentos e materiais' },
        { value: 'Check List de Divulgação/Publicidade', label: 'Check List de Divulgação/Publicidade' },
        { value: 'Cronograma GERAL', label: 'Cronograma GERAL' },
        { value: 'Cronograma Específico', label: 'Cronograma Específico' },
        { value: 'Regulamento Geral de Funcionamento da Formação', label: 'Regulamento Geral de Funcionamento da Formação' },
        { value: 'Regulamento de Funcionamento da Formação a Distância', label: 'Regulamento de Funcionamento da Formação a Distância' },
        { value: 'Regras de Conduta', label: 'Regras de Conduta' },
    ];
    const topico2 = [
        { value: 'Listagem de Formandos (c/ dados para seguro)', label: 'Listagem de Formandos (c/ dados para seguro)' },
        { value: 'Apólice de Seguro', label: 'Apólice de Seguro' },
        { value: 'Ficha de registo do processo de seleção dos formandos', label: 'Ficha de registo do processo de seleção dos formandos' },
        { value: 'Check List de verificação dos documentos e elegibilidade dos formandos', label: 'Check List de verificação dos documentos e elegibilidade dos formandos' },
        { value: 'Fichas de inscrição', label: 'Fichas de inscrição' },
        { value: 'Contratos de formação dos formandos', label: 'Contratos de formação dos formandos' },
        { value: 'Contratos de formação do organismo intermédio/financiador', label: 'Contrato de formação dos formandos de acordo com organismo financiador' },
    ];
    const topico31 = [
        { value: 'Check List de verificação dos documentos dos formadores', label: 'Check List de verificação dos documentos dos formadores' },
        { value: 'Ficha de registo do processo de seleção dos/as formadores/as', label: 'Ficha de registo do processo de seleção dos/as formadores/as' },
        { value: 'Contrato de Prestação de Serviços dos formadores', label: 'Contrato de Prestação de Serviços dos formadores' },
        { value: 'Contrato de Prestação de Serviços dos formadores do organismo intermédio/financiador', label: 'Contrato de prestação de Serviços de acordo com o organismo financiador' },
    ];
    const topico32 = [
        { value: 'Listagem dos Coordenadores/Mediadores/Consultores de Ligação', label: 'Listagem dos Coordenadores/Mediadores/Consultores de Ligação' },
        { value: 'Contrato de Prestação de Serviços dos Coordenadores/Mediadores/Consultor de Ligação', label: 'Contrato de Prestação de Serviços dos Coordenadores/Mediadores/Consultor de Ligação' },
    ];
    const topico4 = [
        { value: 'Folhas de Sumários e Presenças', label: 'Folhas de Sumários e Presenças' },
        { value: 'Planos de sessão', label: 'Planos de sessão' },
        { value: 'Roteiros Pedagógicos', label: 'Roteiros Pedagógicos' },
        { value: 'Justificação de faltas', label: 'Justificação de faltas' },
        { value: 'Mapas de Assiduidade Mensal', label: 'Mapas de Assiduidade Mensal' },
    ];
    const topico5 = [
        { value: 'Manuais/Textos e documentação de apoio', label: 'Manuais/Textos e documentação de apoio' },
        { value: 'Outros documentos', label: 'Outros documentos' },
    ];
    const topico6 = [
        { value: 'Ficha de ocorrências', label: 'Ficha de ocorrências' },
        { value: 'Ficha de desistências', label: 'Ficha de desistências' },
        { value: 'Evidências de coordenação da Equipa Pedagógicas/Atas ou outras evidências (presencial)', label: 'Evidências de coordenação da Equipa Pedagógicas/Atas ou outras evidências (presencial)' },
        { value: 'Evidências de acompanhamento da ação/ Ficha-relatório ou fotos (presencial)', label: 'Evidências de acompanhamento da ação/ Ficha-relatório ou fotos (presencial)' },
    ];
    const topico7 = [
        { value: 'Avaliação diagnóstica', label: 'Avaliação diagnóstica' },
        { value: 'Testes/Atividades/Trabalhos/Exercícios e corrigendas', label: 'Testes/Atividades/Trabalhos/Exercícios e corrigendas' },
        { value: 'Atividades/Trabalhos/Exercícios (presencial)', label: 'Atividades/Trabalhos/Exercícios (presencial)' },
        { value: 'Grelhas de Cotação de Prova', label: 'Grelhas de Cotação de Prova' },
        { value: 'Pautas de Avaliação', label: 'Pautas de Avaliação' },
        { value: 'Ficha de instrução e registo do PRA', label: 'Ficha de instrução e registo do PRA' },
    ];
    const topico8 = [
        { value: 'Protocolo de estágio/FPCT', label: 'Protocolo de estágio/FPCT' },
        { value: 'Ficha de reunião com entidade acolhedora', label: 'Ficha de reunião com entidade acolhedora' },
        { value: 'Plano de estágio', label: 'Plano de estágio' },
        { value: 'Ficha de assiduidade do/a formando/a', label: 'Ficha de assiduidade do/a formando/a' },
        { value: 'Avaliação do/a formando/a', label: 'Avaliação do/a formando/a' },
        { value: 'Relatório de estágio', label: 'Relatório de estágio' },
        { value: 'Outros documentos referentes à FPCT', label: 'Outros documentos referentes à FPCT' },
    ];
    const topico9 = [
        { value: 'Avaliação da ação pelo/a formando/a por UFCD', label: 'Avaliação da ação pelo/a formando/a por UFCD' },
        { value: 'Avaliação da ação pelo/a formando/a - PERCURSO/EFA', label: 'Avaliação da ação pelo/a formando/a - PERCURSO/EFA' },
        { value: 'Avaliação da ação pelo/a formador/a', label: 'Avaliação da ação pelo/a formador/a' },
        { value: 'Avaliação de desempenho dos/as formadores/as', label: 'Avaliação de desempenho dos/as formadores/as' },
        { value: 'Avaliação de desempenho dos/as coordenadores/as e/ou mediadores/as (outro)', label: 'Avaliação de desempenho dos/as coordenadores/as e/ou mediadores/as (outro)' },
        { value: 'Relatório final de avaliação da ação', label: 'Relatório final de avaliação da ação' },
    ];
    const topico10 = [
        { value: 'Cópia dos certificado', label: 'Cópia dos certificado' },
        { value: 'Comprovativo de entrega de certificados', label: 'Comprovativo de entrega de certificados' },
    ];


    return (
        <>
            <main className={token === "coord" ? "min-h-screen grid-cols-7 mx-auto md:grid xl:grid-cols-10" : "min-h-screen"}>
                {token !== "coord" && <div className="w-full">
                    {token === "admin1" && <NavbarAdmin1 currentPage={"gerirCursos"} />}
                    {token === "admin2" && <NavbarAdmin2 currentPage={"gerirCursos"} />}
                </div>}

                {token === "coord" &&
                    <div className='h-full sm:col-span-2 md:bg-secundary-color'>
                        <NavbarCoordenador currentPage={"cursos"} />
                    </div>}
                <div className={localStorage.getItem("coord") && "relative w-full h-full col-span-5 xl:col-span-8"}>
                    <div className={localStorage.getItem("coord") ? "mx-4 back-button-noNavbar" : "back-button-div-noNavbar"}>
                        <button onClick={() => navigate(-1)} className="back-button-link">
                            <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                        </button>
                    </div>
                    {token === "coord" && <Header sideMenu={"true"} />}
                    <div className="max-w-xs mx-auto mt-16 sm:max-w-none">
                        <input id="selectALL" className="check-box" type="checkbox" onChange={(e) => setAll(e)} />
                        <label className="ml-1 text-main-color">Selecionar Tudo</label>
                        {data && (data.modalidade === "Formação Ação" || data.modalidade === "Formação Empresarial Conjunta e Formação Ação") ? <div className="flex flex-col max-[640px]:items-center max-[640px]:justify-center sm:flex-row">
                            <div className="flex flex-col items-center justify-center w-full py-1 pl-1 border sm:m-3 sm:w-2/5">
                                <h1 className="text-sm uppercase subtitle lg:text-xl">0. Identificação da Entidade Beneficiaria</h1>
                                <div className="flex py-1 pl-1">
                                    <input
                                        className="check-box " type="checkbox"
                                        id="select0"
                                        onChange={(e) => selectTopico0(e)}
                                    />
                                    <label className="ml-1 text-xs text-main-color">Selecionar tópico completo</label>
                                </div>
                            </div>
                            <div className="flex flex-col flex-wrap items-start w-full m-3 border sm:w-3/5">
                                {topico0.map((option) => (
                                    <div key={option.value} className="flex py-1 pl-1 text-left">
                                        <input
                                            className="check-box" type="checkbox"
                                            value={option.value}
                                            onChange={(e) => handleCheckboxChange(e, setSelectedCheckboxes0, 0)}
                                            checked={selectedCheckboxes0.includes(option.value)}
                                        />
                                        <label className="ml-1 text-xs text-text-main-color">{option.label}</label>
                                    </div>
                                ))}
                            </div>
                        </div> : ""}
                        <div className="flex flex-col max-[640px]:items-center max-[640px]:justify-center sm:flex-row">
                            <div className="flex flex-col items-center justify-center w-full py-1 pl-1 border sm:m-3 sm:w-2/5">
                                <h1 className="text-sm uppercase subtitle lg:text-xl">1. FUNCIONAMENTO/ ORGANIZAÇÃO</h1>
                                <div className="flex py-1 pl-1">
                                    <input
                                        className="check-box " type="checkbox"
                                        id="select1"
                                        onChange={(e) => selectTopico1(e)}
                                    />
                                    <label className="ml-1 text-xs text-main-color">Selecionar tópico completo</label>
                                </div>
                            </div>
                            <div className="flex flex-col flex-wrap items-start w-full m-3 border sm:w-3/5">
                                {topico1.map((option) => (
                                    <div key={option.value} className="flex py-1 pl-1 text-left">
                                        <input
                                            className="check-box" type="checkbox"
                                            value={option.value}
                                            onChange={(e) => handleCheckboxChange(e, setSelectedCheckboxes1, 1)}
                                            checked={selectedCheckboxes1.includes(option.value)}
                                        />
                                        <label className="ml-1 text-xs text-text-main-color">{option.label}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex flex-col max-[640px]:items-center max-[640px]:justify-center sm:flex-row">
                            <div className="flex flex-col items-center justify-center w-full py-1 pl-1 border sm:m-3 sm:w-2/5">
                                <h1 className="text-sm uppercase subtitle lg:text-xl">2. FORMANDOS/AS</h1>
                                <div className="flex py-1 pl-1">
                                    <input
                                        className="check-box " type="checkbox"
                                        id="select2"
                                        onChange={(e) => selectTopico2(e)}
                                    />
                                    <label className="ml-1 text-xs text-main-color">Selecionar tópico completo</label>
                                </div>
                            </div>

                            <div className="flex flex-col flex-wrap items-start w-full m-3 border sm:w-3/5">
                                {topico2.map((option) => (
                                    <div key={option.value} className="flex py-1 pl-1 text-left">
                                        <input
                                            className="check-box" type="checkbox"
                                            value={option.value}
                                            onChange={(e) => handleCheckboxChange(e, setSelectedCheckboxes2, 2)}
                                            checked={selectedCheckboxes2.includes(option.value)}
                                        />
                                        <label className="ml-1 text-xs text-text-main-color">{option.label}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex flex-col max-[640px]:items-center max-[640px]:justify-center sm:flex-row">
                            <div className="flex flex-col items-center justify-center w-full py-1 pl-1 border sm:m-3 sm:w-2/5">
                                <h1 className="text-sm uppercase subtitle lg:text-xl">3. FORMADORES/AS E PESSOAL NÃO DOCENTE</h1>
                                <div className="flex py-1 pl-1">
                                    <input
                                        className="check-box " type="checkbox"
                                        id="select3"
                                        onChange={(e) => selectTopico3(e)}
                                    />
                                    <label className="ml-1 text-xs text-main-color">Selecionar tópico completo</label>
                                </div>
                            </div>
                            <div className="flex flex-col flex-wrap items-start w-full m-3 border sm:w-3/5">
                                <h1 className="font-bold text-text-main-color">Formadores</h1>
                                {topico31.map((option) => (
                                    <div key={option.value} className="flex py-1 pl-1 text-left">
                                        <input
                                            className="check-box" type="checkbox"
                                            value={option.value}
                                            onChange={(e) => handleCheckboxChange(e, setSelectedCheckboxes31, 3)}
                                            checked={selectedCheckboxes31.includes(option.value)}
                                        />
                                        <label className="ml-1 text-xs text-text-main-color">{option.label}</label>
                                    </div>
                                ))}
                                <h1 className="font-bold text-text-main-color">Pessoal Não docente</h1>
                                {topico32.map((option) => (
                                    <div key={option.value} className="flex py-1 pl-1 text-left">
                                        <input
                                            className="check-box" type="checkbox"
                                            value={option.value}
                                            onChange={(e) => handleCheckboxChange(e, setSelectedCheckboxes32, 3)}
                                            checked={selectedCheckboxes32.includes(option.value)}
                                        />
                                        <label className="ml-1 text-xs text-text-main-color">{option.label}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex flex-col max-[640px]:items-center max-[640px]:justify-center sm:flex-row">
                            <div className="flex flex-col items-center justify-center w-full py-1 pl-1 border sm:m-3 sm:w-2/5">
                                <h1 className="text-sm uppercase subtitle lg:text-xl">4. SUMÁRIOS E PRESENÇAS</h1>
                                <div className="flex py-1 pl-1">
                                    <input
                                        className="check-box " type="checkbox"
                                        id="select4"
                                        onChange={(e) => selectTopico4(e)}
                                    />
                                    <label className="ml-1 text-xs text-main-color">Selecionar tópico completo</label>
                                </div>
                            </div>
                            <div className="flex flex-col flex-wrap items-start w-full m-3 border sm:w-3/5">
                                {topico4.map((option) => (
                                    <div key={option.value} className="flex py-1 pl-1 text-left">
                                        <input
                                            className="check-box" type="checkbox"
                                            value={option.value}
                                            onChange={(e) => handleCheckboxChange(e, setSelectedCheckboxes4, 4)}
                                            checked={selectedCheckboxes4.includes(option.value)}
                                        />
                                        <label className="ml-1 text-xs text-text-main-color">{option.label}</label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="flex flex-col max-[640px]:items-center max-[640px]:justify-center sm:flex-row">
                            <div className="flex flex-col items-center justify-center w-full py-1 pl-1 border sm:m-3 sm:w-2/5">
                                <h1 className="text-sm uppercase subtitle lg:text-xl">5. MATERIAIS DE APOIO </h1>
                                <div className="flex py-1 pl-1">
                                    <input
                                        className="check-box " type="checkbox"
                                        id="select5"
                                        onChange={(e) => selectTopico5(e)}
                                    />
                                    <label className="ml-1 text-xs text-main-color">Selecionar tópico completo</label>
                                </div>
                            </div>
                            <div className="flex flex-col flex-wrap items-start w-full m-3 border sm:w-3/5">
                                {topico5.map((option) => (
                                    <div key={option.value} className="flex py-1 pl-1 text-left">
                                        <input
                                            className="check-box" type="checkbox"
                                            value={option.value}
                                            onChange={(e) => handleCheckboxChange(e, setSelectedCheckboxes5, 5)}
                                            checked={selectedCheckboxes5.includes(option.value)}
                                        />
                                        <label className="ml-1 text-xs text-text-main-color">{option.label}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex flex-col max-[640px]:items-center max-[640px]:justify-center sm:flex-row">
                            <div className="flex flex-col items-center justify-center w-full py-1 pl-1 border sm:m-3 sm:w-2/5">
                                <h1 className="text-sm uppercase subtitle lg:text-xl">6. ACOMPANHAMENTO DA FORMAÇÃO</h1>
                                <div className="flex py-1 pl-1">
                                    <input
                                        className="check-box " type="checkbox"
                                        id="select6"
                                        onChange={(e) => selectTopico6(e)}
                                    />
                                    <label className="ml-1 text-xs text-main-color">Selecionar tópico completo</label>
                                </div>
                            </div>
                            <div className="flex flex-col flex-wrap items-start w-full m-3 border sm:w-3/5">
                                {topico6.map((option) => (
                                    <div key={option.value} className="flex py-1 pl-1 text-left">
                                        <input
                                            className="check-box" type="checkbox"
                                            value={option.value}
                                            onChange={(e) => handleCheckboxChange(e, setSelectedCheckboxes6, 6)}
                                            checked={selectedCheckboxes6.includes(option.value)}
                                        />
                                        <label className="ml-1 text-xs text-text-main-color">{option.label}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex flex-col max-[640px]:items-center max-[640px]:justify-center sm:flex-row">
                            <div className="flex flex-col items-center justify-center w-full py-1 pl-1 border sm:m-3 sm:w-2/5">
                                <h1 className="text-sm uppercase subtitle lg:text-xl">7. AVALIAÇÃO DA APRENDIZAGEM</h1>
                                <div className="flex py-1 pl-1">
                                    <input
                                        className="check-box " type="checkbox"
                                        id="select7"
                                        onChange={(e) => selectTopico7(e)}
                                    />
                                    <label className="ml-1 text-xs text-main-color">Selecionar tópico completo</label>
                                </div>
                            </div>
                            <div className="flex flex-col flex-wrap items-start w-full m-3 border sm:w-3/5">
                                {topico7.map((option) => (
                                    <div key={option.value} className="flex py-1 pl-1 text-left">
                                        <input
                                            className="check-box" type="checkbox"
                                            value={option.value}
                                            onChange={(e) => handleCheckboxChange(e, setSelectedCheckboxes7, 7)}
                                            checked={selectedCheckboxes7.includes(option.value)}
                                        />
                                        <label className="ml-1 text-xs text-text-main-color">{option.label}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex flex-col max-[640px]:items-center max-[640px]:justify-center sm:flex-row">
                            <div className="flex flex-col items-center justify-center w-full py-1 pl-1 border sm:m-3 sm:w-2/5">
                                <h1 className="text-sm uppercase subtitle lg:text-xl">8. ESTÁGIOS/FPCT</h1>
                                <div className="flex py-1 pl-1">
                                    <input
                                        className="check-box " type="checkbox"
                                        id="select8"
                                        onChange={(e) => selectTopico8(e)}
                                    />
                                    <label className="ml-1 text-xs text-main-color">Selecionar tópico completo</label>
                                </div>
                            </div>
                            <div className="flex flex-col flex-wrap items-start w-full m-3 border sm:w-3/5">
                                {topico8.map((option) => (
                                    <div key={option.value} className="flex py-1 pl-1 text-left">
                                        <input
                                            className="check-box" type="checkbox"
                                            value={option.value}
                                            onChange={(e) => handleCheckboxChange(e, setSelectedCheckboxes8, 8)}
                                            checked={selectedCheckboxes8.includes(option.value)}
                                        />
                                        <label className="ml-1 text-xs text-text-main-color">{option.label}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex flex-col max-[640px]:items-center max-[640px]:justify-center sm:flex-row">
                            <div className="flex flex-col items-center justify-center w-full py-1 pl-1 border sm:m-3 sm:w-2/5">
                                <h1 className="text-sm uppercase subtitle lg:text-xl">9. AVALIAÇÃO DA FORMAÇÃO</h1>
                                <div className="flex py-1 pl-1">
                                    <input
                                        className="check-box " type="checkbox"
                                        id="select9"
                                        onChange={(e) => selectTopico9(e)}
                                    />
                                    <label className="ml-1 text-xs text-main-color">Selecionar tópico completo</label>
                                </div>
                            </div>
                            <div className="flex flex-col flex-wrap items-start w-full m-3 border sm:w-3/5">
                                {topico9.map((option) => (
                                    <div key={option.value} className="flex py-1 pl-1 text-left">
                                        <input
                                            className="check-box" type="checkbox"
                                            value={option.value}
                                            onChange={(e) => handleCheckboxChange(e, setSelectedCheckboxes9, 9)}
                                            checked={selectedCheckboxes9.includes(option.value)}
                                        />
                                        <label className="ml-1 text-xs text-text-main-color">{option.label}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex flex-col max-[640px]:items-center max-[640px]:justify-center sm:flex-row">
                            <div className="flex flex-col items-center justify-center w-full py-1 pl-1 border sm:m-3 sm:w-2/5">
                                <h1 className="text-sm uppercase subtitle lg:text-xl">10. CERTIFICADOS</h1>
                                <div className="flex py-1 pl-1">
                                    <input
                                        className="check-box " type="checkbox"
                                        id="select10"
                                        onChange={(e) => selectTopico10(e)}
                                    />
                                    <label className="ml-1 text-xs text-main-color">Selecionar tópico completo</label>
                                </div>
                            </div>
                            <div className="flex flex-col flex-wrap items-start w-full m-3 border sm:w-3/5">
                                {topico10.map((option) => (
                                    <div key={option.value} className="flex py-1 pl-1 text-left">
                                        <input
                                            className="check-box" type="checkbox"
                                            value={option.value}
                                            onChange={(e) => handleCheckboxChange(e, setSelectedCheckboxes10, 10)}
                                            checked={selectedCheckboxes10.includes(option.value)}
                                        />
                                        <label className="ml-1 text-xs text-text-main-color">{option.label}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <button className="my-4 button-geral" onClick={() => getAll()}>Gerar</button>
                    </div>
                </div>
            </main >
            <Footer />
        </>
    );
}

export default GerirDTP