export default function extractWord(input) {
  // Find the index of the first hyphen
  const hyphenIndex = input.indexOf('-');
  
  // If there is no hyphen, return an empty string or handle it as needed
  if (hyphenIndex === -1) {
    return '';
  }
  
  // Extract the substring after the first hyphen and trim any leading/trailing whitespaces
  const extractedWord = input.substring(hyphenIndex + 1).trim();
  return extractedWord;
}