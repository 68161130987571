import Header from "../../../../../components/Header";
import React, { useState, useEffect } from "react";
import Footer from "../../../../../components/Footer";
import { Link, useParams } from 'react-router-dom'
import NavbarAdmin1 from "../../../../../components/Navbars/NavbarAdmin1/NavbarAdmin1";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";
import AlterarFormato from "../../../../../components/FormChecks/formatDates/foramatDates";

export default function Atividade() {
    const [data, setData] = useState([]);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const savedSearchTerm = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-CoordenadorAtividade-1") : "";
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm ? savedSearchTerm : "");

    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-CoordenadorAtividade-Pag") : "";
    const saveList = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-CoordenadorAtividade-F1") : ""
    const [tabelas, setTabelas] = useState(saveList ? saveList : "0");
    const [nPaginas, setNumPaginas] = useState(0);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Admin-CoordenadorAtividade-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)

    //Data atual
    const year = new Date().getFullYear()
    const month = new Date().getMonth()
    const day = new Date().getDate();

    const dateNow = new Date(year, month, day)

    //filtra dados
    function filterData(data) {
        return data.filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.codeUfcd.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.percurso[0].name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }

    const params = useParams();

    document.data = data


    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }


    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }

//Obtem atividade
    function getAtividade() {
        if (tabelas === "2") {
            fetch(`${process.env.REACT_APP_API_URL}/percurso/coordenador/ativas/${params.id}`,
                {
                    headers: {
                        'authorization': localStorage.getItem('admin1')
                    }
                })

                .then((res) => res.json())
                .then(
                    (result) => {
                        setData(result)
                        paginacaoTabelas(filterData(result));
                        setLoading(false);
                    },
                    (error) => {
                        console.error("Error fetching data: ", error);
                        setError(error);
                    }

                );
        }
        else if (tabelas === "1") {
            fetch(`${process.env.REACT_APP_API_URL}/percurso/coordenador/antigos/${params.id}`,
                {
                    headers: {
                        'authorization': localStorage.getItem('admin1')
                    }
                })
                .then((res) => res.json())
                .then(
                    (result) => {
                        setData(result)
                        paginacaoTabelas(filterData(result));
                        setLoading(false);
                    },
                    (error) => {
                        console.error("Error fetching data: ", error);
                        setError(error);
                    }

                );
        }
        else if (tabelas === "0") {
            fetch(`${process.env.REACT_APP_API_URL}/percurso/coordenador/${params.id}`,
                {
                    headers: {
                        'authorization': localStorage.getItem('admin1')
                    }
                })
                .then((res) => res.json())
                .then(
                    (result) => {
                        setData(result)
                        paginacaoTabelas(filterData(result));
                        setLoading(false);
                    },
                    (error) => {
                        console.error("Error fetching data: ", error);
                        setError(error);
                    }
                );
        }
    }

    useEffect(() => {
        Cookies.set("Admin-CoordenadorAtividade-Pag", paginaAtual);
        Cookies.set("Admin-CoordenadorAtividade-1", searchTerm);
        Cookies.set("Admin-CoordenadorAtividade-F1", tabelas);
        Cookies.set(`Admin-CoordenadorAtividade-itm1`, itemsPag)
        getAtividade()
    }, [loading, tabelas, paginaAtual, nPaginas, searchTerm, itemsPag]);

    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };

    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";


    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin1 currentPage={"manageCoordinator"} />
                </div>

                <div className="flex-grow mt-8">
                    <Link to={"/menuadmin/" + params.id_candidatura + "/coordinators"} className="back-button-div-noNavbar">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>
                    <h1 className="title">Histórico do Coordenador</h1>
                    <div className="search-div">
                        <label className="search-input-label">Pesquisar: </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input type="text" className="search-input" placeholder="ex: Nome, Código..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                        </div>
                    </div>

                    <div className="mt-4 mb-1">
                        <label className="text-text-second-color">Percursos: </label>
                        <select onWheel={(e) => e.target.blur()} className="mr-3 search-selector" name="tabelas" id="tabelas" value={tabelas} onChange={(e) => setTabelas(e.target.value)}>
                            <option value="0">Todos</option>
                            <option value="1">Concluidos</option>
                            <option value="2">Ativos</option>
                        </select>
                    </div>
                    {data && data.length > 0 ? <>
                        <div className="flex justify-center items-center mb-1">
                            <label className="text-text-main-color">Dados por página: </label>
                            <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                value={itemsPag}
                                onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div className="table-container">

                            <table className="my-table">

                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="p-2 min-w-[150px] border border-white">Nome</th>
                                        <th className="p-2 min-w-[80px] border border-white">Operação</th>
                                        <th className="p-2 min-w-[80px] border border-white">Tipo</th>
                                        <th className="p-2 min-w-[80px] border border-white">Modalidade</th>
                                        <th className="p-2 min-w-[80px] border border-white">Entidade Formadora</th>
                                        <th className="p-2 min-w-[80px] border border-white">Data inicio</th>
                                        <th className="p-2 min-w-[80px] border border-white">Data final</th>
                                        <th className="p-2 min-w-[80px] border border-white">Avaliação</th>
                                        <th className="p-2 min-w-[80px] border border-white">Ações</th>
                                    </tr>
                                </thead>
                                {data.length > 0 ? (
                                    <tbody className="text-text-main-color">
                                        {filterData(dataToDisplay).map((item, index) => (
                                            <tr key={index}>
                                                <td className="border border-white">{item.name}</td>
                                                <td className="border border-white">{item.operacao[0].codigoOperacao}</td>
                                                <td className="border border-white">{item.modalidade}</td>
                                                <td className="border border-white">{item.type}</td>
                                                {item.entFormadora.length > 0 ? <td className="border border-white ufcd">{item.entFormadora[0].name}</td> : <td className="border border-white">Sem Entidade Formadora</td>}
                                                <td className="border border-white date-input">{AlterarFormato(item.dateBegin)}</td>
                                                <td className="border border-white date-input">{AlterarFormato(item.dateEnd)}</td>
                                                <td className="border border-white">{item.evaluation || item.evaluation === 0 ? item.evaluation + "%" : "Em Atividade"}</td>
                                                {item.dateEnd ? (
                                                    <>
                                                        {(!item.evaluation && item.evaluation !== 0) && (new Date(item.dateEnd) < dateNow || item.dateEnd) && item.fechoCoordenador === true ? (
                                                            <td className="border border-white">
                                                                <Link className="button-geral" to={"/menuadmin/" + params.id_candidatura + "/coordinators/atividade/" + params.id + "/avaliar/" + item._id}>Avaliar</Link>
                                                            </td>
                                                        ) : (
                                                            <td className="border border-white">Sem Ações</td>
                                                        )}
                                                    </>
                                                ) : (
                                                    <td className="border border-white">Sem data</td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody />
                                )}
                            </table>
                        </div>
                        <div className="flex items-center justify-center mt-4 mb-8">
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                <AiOutlineDoubleLeft />
                            </button>
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                <AiOutlineLeft />
                            </button>
                            <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                <AiOutlineRight />
                            </button>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                <AiOutlineDoubleRight />
                            </button>
                        </div> </> : <p className="third-title">Não existem dados</p>}
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main>
        </>
    )
}