import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom'
import NavbarAdmin1 from "../Navbars/NavbarAdmin1/NavbarAdmin1";
import Footer from "../Footer";
import Cookies from "js-cookie";
import toastError from "../Toast/toastError";
import NavbarAdmin2 from "../Navbars/NavbarAdmin2/NavbarAdmin2";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

function AssiduidadeFormador({ token }) {
    const [idUfcd, setIdUfcd] = useState("");
    const [data, setData] = useState(null);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [data2, setData2] = useState([]);
    const [loading, setLoading] = useState(true);
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`${token}-coursesUfcdAssiduidade-Pag`) : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const params = useParams();
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`${token}-coursesUfcdAssiduidade-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)

    const navigate = useNavigate()

    useEffect(() => {
        Cookies.set(`${token}-coursesUfcdAssiduidade-Pag`, paginaAtual);
        Cookies.set(`${token}-coursesUfcdAssiduidade-itm1`, itemsPag)
        getUfcd()
        getAssiduidadeUserMoodle()
    }, [paginaAtual, nPaginas, itemsPag, idUfcd, savedItemsPag]);

    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }

    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {
        const newNumPaginas = Math.ceil(dataToDisplay.length / newItemsPag);
        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;
        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };

    //Passa para a ultima pagina
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(dataToDisplay);
        }
    }
    //Passa para a 1a pagina
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(dataToDisplay);
        }
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(dataToDisplay)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(dataToDisplay)
        }
    }

    function getUfcd() {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/${params.id_ufcd}/trainer`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    setLoading(false);
                    fetch(`${process.env.REACT_APP_API_URL}/moodle/cursos/${result.percurso[0].moodle[0]._id}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': localStorage.getItem(token)
                        },
                        method: 'POST',
                        body: JSON.stringify({
                            idDisciplina: result.codeMoodle
                        })
                    })
                        .then((res) => res.json())
                        .then(
                            (resultado) => {
                                if (resultado.length > 0) {
                                    setIdUfcd(resultado[0].id)
                                }
                            },
                            (error) => {
                                console.error("Error fetching data: ", error);
                                toastError("Dados de Moodle inválidos.")
                            }
                        );
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                }
            );
    }

    async function getAssiduidadeUserMoodle() {
        try {
            // Fetch user assiduidade data
            let formadores = [];
            if (data) {
                // Create an array of promises for fetching user data
                const userPromises = data.formadores.map(async element => {
                    const userResponse = await fetch(`${process.env.REACT_APP_API_URL}/moodle/users/${data.percurso[0].moodle[0]._id}`, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'authorization': localStorage.getItem(token)
                        },
                        body: JSON.stringify({
                            nif: element.nif
                        })
                    });
                    if (!userResponse.ok) {
                        throw new Error('Network response was not ok for user assiduidade data');
                    }

                    const userAssiduidadeData = await userResponse.json();
                    if (idUfcd) {
                        const assiduidadeResponse = await fetch(`${process.env.REACT_APP_API_URL}/moodle/dedicacao/${data.percurso[0].moodle[0]._id}`, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'authorization': localStorage.getItem(token)
                            },
                            body: JSON.stringify({
                                userid: userAssiduidadeData[0].id,
                                courseId: idUfcd
                            })
                        });

                        if (!assiduidadeResponse.ok) {
                            throw new Error('Network response was not ok for assiduidade data');
                        }

                        const assiduidadeData = await assiduidadeResponse.json();

                        // Process assiduidadeData and return
                        return Promise.all(assiduidadeData.map(async element => {
                            try {
                                const name = await getFormadores(element.userid); // Await the promise result
                                return { ...element, name };
                            } catch (error) {
                                console.error('Error processing element:', error);
                                return null;
                            }
                        }));
                    }
                });

                // Wait for all promises to resolve
                const formadoresArrays = await Promise.all(userPromises);

                // Flatten the array of arrays into a single array
                formadores = formadores.concat(...formadoresArrays.filter(Boolean));
            }
            setData2(formadores);
            paginacaoTabelas(formadores);

        } catch (error) {
            console.error('Error in getAssiduidadeUserMoodle:', error);
        }
    }


    async function getFormadores(userid) {
        // Example async function that fetches formadores data based on userid
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/moodle/usersID/${data.percurso[0].moodle[0]._id}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token) // Ensure 'token' is quoted
                },
                body: JSON.stringify({
                    userId: userid
                })
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const formadores = await response.json();
            return `${formadores[0].firstname} ${formadores[0].lastname}`;
        } catch (error) {
            console.error('Error fetching formadores data:', error);
            return null;
        }
    }



    function convertSecondsToHoursAndMinutes(seconds) {
        const totalMinutes = seconds / 60;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = Math.floor(totalMinutes % 60);
        return `${hours}:${minutes < 10 ? "0" + minutes : minutes}`;
    }
    function convertTimestampToDate(timestamp) {
        const date = new Date(timestamp * 1000); // Convert from seconds to milliseconds
        return date.toLocaleString(); // Format the date to a readable string
    }
     if (loading) return (
         <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
             <div className="loading"></div>
         </div>
     );

    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    {token === "admin1" && <NavbarAdmin1 currentPage={"gerirCursos"} />}
                    {token === "admin2" && <NavbarAdmin2 currentPage={"gerirCursos"} />}
                </div>
                <div className="flex-grow mt-8">
                    {data ? <h1 className="mx-4 title" >{data.codeUfcd} - {data.name}</h1> : ""}

                    <button onClick={() => navigate(-1)} className="back-button-div-noNavbar">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </button>

                    <h2 className="subtitle">Eventos</h2>
                    {data2 && data2.length > 0 ? <>
                        <div className="flex justify-center items-center mb-1">
                            <label className="text-text-main-color">Dados por página: </label>
                            <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                value={itemsPag}
                                onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div className="flex flex-col table-container">
                            <table className="my-table">

                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="p-2 min-w-[150px] border border-white">Nome do Formador</th>
                                        <th className="p-2 min-w-[80px] border border-white">Data de Acesso</th>
                                        <th className="p-2 min-w-[80px] border border-white">Tempo Dedicado</th>
                                    </tr>
                                </thead>
                                {data2 && data2.length > 0 ? <tbody>
                                    {dataToDisplay.map((item, index) => (
                                        <tr key={index}>
                                            <td className="border border-white">{item.name}</td>
                                            <td className="border border-white">{convertTimestampToDate(item.timestart)}</td>
                                            <td className="border border-white">{convertSecondsToHoursAndMinutes(item.timespent)}h</td>
                                        </tr>
                                    ))}
                                </tbody> : <tbody />}
                            </table>
                            <div className="flex items-center justify-center mt-4 mb-8">
                                <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                    <AiOutlineDoubleLeft />
                                </button>
                                <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                    <AiOutlineLeft />
                                </button>
                                <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                                <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                    <AiOutlineRight />
                                </button>
                                <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                    <AiOutlineDoubleRight />
                                </button>
                            </div>
                        </div> </> : <p className="third-title">Não existem dados</p>}
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main>
        </>
    );
}

export default AssiduidadeFormador;