import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { AreaLoginFormando } from './styled';
import Header from "../../../components/Header";
import Footer from '../../../components/Footer'
import toastError from '../../../components/Toast/toastError';
import { toast } from 'react-toastify'

export default function LoginFormando() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showPassword, setShowPassword] = useState(false)
    //Mostrar pass
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    document.data = data

    const navigate = useNavigate();

    let idLogged = 0

    // dados do input
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [stayLogin, setStayLogin] = useState(false)

    // função login em for, no entanto o login não está local e apenas é um acesso e não uma autorização
    // para aceder rapidamente ao login deixo aqui um login modelo para teste  email:a   pass: 2134
    function submeterLogin(e) {
        e.preventDefault();
        getCandidaturasValidados().then((data) => {
            if (data.status === "not_found" || data.status === "not_valid") {
                toastError(data.message)
            } else {
                if (data.valid === true) {
                    idLogged = data.id
                    navigate(`/menuformando/${idLogged}`)
                }
            };
        })
    }

    //Obter candidaturas validas
    function getCandidaturasValidados() {

        const handleErrors = response => {

            const data = response.json()

            if (!response.ok) {
                toastError(data)
            }

            return data;
        }

        return new Promise((resolve, reject) => {

            fetch(`${process.env.REACT_APP_API_URL}/formandos/login`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        email: email,
                        password: password,
                        stayLogin: stayLogin
                    })
                })
                .then(handleErrors)
                .then(
                    (result) => {
                        if (result.valid === true) {
                            localStorage.setItem('formando', result.jwt)
                            localStorage.setItem("id", result.id)
                        }
                        setData(result);
                        setLoading(false);
                        resolve(result)
                    },
                    (error) => {
                        reject(error)
                    }
                );
        })

    }

    useEffect(() => {
        return () => {
            toast.dismiss();
        }
    }, []);

    return (
        <>

            <AreaLoginFormando>
                <main>
                    <Header />
                    <Link to={'/'} className="back-button-link">
                        <img className='back-button' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>
                    <div>
                        <label>
                            <span className='login-icon-container'>
                                <i className="fas fa-user fa-3x"></i>
                            </span>
                        </label>
                    </div>
                    <h1 className="title-login">Login Formando</h1>
                    <div className="form-container">
                        <form id="login-form" onSubmit={submeterLogin}>
                            <div className='email-input-wraper'>
                                <label className='input-container'>
                                    <span className='user-icon-container'>
                                        <i className="fas fa-user"></i>
                                    </span>
                                    <input type="email" id="email" name="email" onChange={(e) => { setEmail(e.target.value) }} placeholder="E-mail" required />
                                </label>
                            </div>
                            <div className='password-input-wraper'>
                                <label className='input-container'>
                                    <span className='user-icon-container'>
                                        <i className="fas fa-key"></i>
                                    </span>
                                    <input type={showPassword ? 'text' : 'password'} id="password" name="password" onChange={(e) => { setPassword(e.target.value) }} placeholder="Password" required />
                                    <span className='eye-button-container'>
                                        <button type="button" onClick={toggleShowPassword} className="eye-button">
                                            <i className={showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                                        </button>
                                    </span>
                                </label>
                            </div>
                            <div className='inline'>
                                <input className='checkbox-form' type="checkbox" name="stayLogin" id="stayLogin" value="True" checked={stayLogin === true} onChange={(e) => { setStayLogin(e.target.checked ? true : false) }} /><label for="manterlogin">Manter sessão de login iniciada.</label>
                            </div>
                            <Link className="forgot-pass-link" to="/forgotpassword/formando">Esqueci-me da Palavra-passe</Link>
                            <input className="button-login" type="submit" value="Login"></input>
                        </form>
                    </div>
                </main>
            </AreaLoginFormando>
            <Footer />
        </>
    )
}

