import React from "react";
import { QRCodeCanvas } from "qrcode.react";

const QRCodeGenerator = ({link}) => {

  return (
    <div style={{ textAlign: "center", marginTop: "10px" }}>
      <QRCodeCanvas
            value={link}
            size={120}  // QR code size
            bgColor={"#0000"}  // Background color
            fgColor={"#172554"}  // QR code color
            level={"H"}  // Error correction level (L, M, Q, H)
          />
    </div>
  );
};

export default QRCodeGenerator;