import React from 'react';
import Papa from 'papaparse';
import removeAccents from '../FormChecks/removeAccents/removeAccents';


class CSVGeneratorFormandos extends React.Component {
  async populateCSVFormandos() {
      const { data } = this.props;
      try {
        // Parse the CSV template (loaded from the public directory)
        const templateCSV = await this.fetchCSVTemplate('./moodleCSV/carregarUtilizadores.csv');
        const parsedTemplate = Papa.parse(templateCSV, { header: true, encoding: 'utf-8' });
    
        // Generate data
        const dataToInsert = this.generateData(data);
    
        // Add data below the headers
        const populatedCSV = this.addRowsToCSV(parsedTemplate, dataToInsert);
        // Trigger CSV download
        this.downloadCSV(populatedCSV);
      } catch (error) {
        console.error('Error reading or processing CSV template:', error);
      }
    }
    
  generateData(data) {
    const array = [];

    data.forEach((element) => {
      let name = element.fullName.split(' ');
      let firstWord = name[0];
      let lastWord = name[name.length - 1];
      const dateNow = new Date();
      const yearNow = dateNow.getFullYear();
      let password = `Formacao_${yearNow}`;
      // Initialize an array to store the courses
      let courses = [];

      // Iterate through the ufcds array and push the course property to the courses array
      element.ufcds.forEach((ufcd, index) => {
        courses.push(ufcd.codeMoodle);
      });

      // Combine the courses into a single object
      array.push([
        element.nif,
        password,
        firstWord,
        lastWord,
        element.email,
        ...courses,
      ]);
    });

    return array;
  }

  addRowsToCSV(parsedTemplate, dataToInsert) {
    const headerRow = Papa.unparse([parsedTemplate.meta.fields]); // Reconstruct the header row

    // Join data rows with newline characters
    const dataRows = dataToInsert.map((row) => Papa.unparse([row])).join('\n');
    // Concatenate the header row and data rows
    return `${headerRow}\n${dataRows}`;
  }

  downloadCSV(csvData) {
    const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvData], { type: 'text/csv; charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  async fetchCSVTemplate(templatePath) {
    try {
      const response = await fetch(templatePath);
      if (!response.ok) {
        throw new Error(`Failed to fetch CSV template: ${response.status} ${response.statusText}`);
      }
      const csvText = await response.text();

      // Check if the CSV text starts with a BOM, if not, prepend it
      return csvText.charCodeAt(0) === 0xFEFF ? csvText : '\uFEFF' + csvText;
    } catch (error) {
      throw new Error(`Error fetching CSV template: ${error.message}`);
    }
  }

  render() {
    return (
      <div>
        <button className="mt-4 button-geral w-72" onClick={() => this.populateCSVFormandos()}>
        Exportar Turma para Moodle
        </button>
      </div>
    );
  }
}

// Include your removeAccents function here or import it from a separate file if it's not part of this file.

export default CSVGeneratorFormandos;
