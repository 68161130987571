import React from "react";
import { Tooltip } from 'react-tooltip'
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";
import toastError from "../Toast/toastError";

export default function ConfirmInscricao({ css, icon, title, message, confirm, id, tooltip, type, token, data }) {
    const [open, setOpen] = React.useState(false);
    const [extaNum, setExtaNum] = React.useState(false);
    const handleOpen = () => {
        setOpen(!open);
        setExtaNum(false)
    }
    function confirmCondition() {
        if (!data) {
            return submeterExtraNum(id)
        }
        else {
            if (type === "aceitar") {
                data.forEach(element => {
                    submeterExtraNum(element._id)
                });
            }
        }
    }
    function submeterExtraNum(id) {
        if (!extaNum) {
            confirm(id)
        }
        else {
            if (document.getElementById("dateInsc").value) {
                fetch(`${process.env.REACT_APP_API_URL}/formandos/${id}/extraNumerario`,
                    {
                        method: "PATCH",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'authorization': localStorage.getItem(token)
                        },
                        body: JSON.stringify({
                            dateInscricao: document.getElementById("dateInsc").value,
                            outro: document.getElementById("outra").value,
                        })
                    }).then((result) => {
                        result.json().then((resp) => {
                            confirm(id)
                        })
                    })
            }
            else toastError("Para os alunos extra-numerários a data de inscrição é um elemento obrigatório")
        }
    }

    return (
        <>
            <button className={css} onClick={handleOpen} data-tooltip-id={"tooltipUnic" + id + type}
                // data-tooltip-content={tooltip}
                data-tooltip-place="top">
                {icon}
            </button>
            {tooltip &&
                <Tooltip id={"tooltipUnic" + id + type}>{tooltip}</Tooltip>
            }
            <Dialog className="fixed inset-0 w-screen h-screen m-0 bg-opacity-20 backdrop-blur-sm" open={open} handler={handleOpen}>
                <div className="fixed inset-0 h-full bg-opacity-5"></div>

                <div className="flex items-center justify-center h-screen">
                    <div className="z-50 p-4 rounded-lg shadow-lg max-h-300 w-128 bg-main-color brightness-150 shadow-gray-600 ">
                        <DialogHeader className="mb-0 text-white title" >{title}</DialogHeader>
                        <DialogBody className="text-white" divider>{message}
                            <div className="mt-2 flex text-center items-center">
                                <input className="check-box mx-2" type="checkbox" id="extraNume" onChange={(e) => setExtaNum(e.target.checked)} />Extra-numerário
                            </div>
                            <div className="mt-2 mx-2 flex text-center items-center w-full">
                                {extaNum && (
                                    <div className="w-full">
                                        <div className="flex items-center">
                                            <p>Data de Inscrição*:</p>
                                            <input className="ml-2 input-field" type="date" name="dateInsc" id="dateInsc" />
                                        </div>
                                        <div className="flex ">
                                            <p>Outra Informação:</p>

                                        </div>
                                        <textarea className=" input-field text-s w-full" id="outra" />
                                    </div>
                                )}
                            </div>
                        </DialogBody>
                        <DialogFooter className="flex justify-between">
                            <Button
                                variant="text"
                                color="red"
                                onClick={handleOpen}
                                className="w-32 p-2 text-sm text-white bg-text-error hover:brightness-75"
                            >
                                Cancelar
                            </Button>

                            {id ? (
                                <Button
                                    className="w-32 p-2 text-sm text-white bg-text-correct hover:brightness-75"
                                    variant="gradient"
                                    color="green"
                                    onClick={() => { confirmCondition(); handleOpen() }}
                                >
                                    {data ? `Confirmar candidaturas` : "Confirmar"}
                                </Button>
                            ) : (
                                <Button
                                    className="w-32 p-2 text-sm tracking-wide text-white bg-text-correct hover:brightness-75"
                                    variant="gradient"
                                    color="green"
                                    onClick={() => { confirmCondition(); handleOpen() }}
                                >
                                    {data ? `Confirmar candidaturas` : "Confirmar"}
                                </Button>
                            )}
                        </DialogFooter>
                    </div>
                </div>
            </Dialog>
        </>
    );
}