import styled from "styled-components";

export const AreaUFCDsIndividual = styled.div`

align-items: center;
justify-content: center;
flex-direction: column;
font-family: "Lato";
display: flex;
margin-top: 14vh;

.form-container{   
    min-width: 600px;
    overflow: auto;
    background-color: white;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 5px 5px 20px #00000022;   
    max-height: 610px;
}    

main .form-container a {
    min-height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border: none;
    color: rgb(71 71 71);
    margin: 1em 0;
    background-color: #00000000;
}

h1{
    text-align: center;
}

.media{
    background-color: white;
}

table{
    display: block;
    background-color: white;
    text-align: center;
    max-width: 80vw;
    overflow: auto;
    max-height: 400px;
}

table thead th {

    padding: 12px;
    background-color: rgb(71 71 71);
    color: white

}
table tr{
    /* height: 50px; */
}

table .remove-ufcd{
    border-radius: 50px;
    width: 30px;
    height: 30px;
    border: none;
    background-color: #ff000088;
    cursor: pointer;
    color: white;
    transition: all 0.25s ease;
}

table .remove-ufcd:hover{
    background-color: #ff0000cc;
    width: 35px;
    height: 35px;
}

table tr:nth-child(odd)
{
    background-color: #00000011;
}

.button-action{
    display:flex;
    border: 0.5px solid;
    border-radius: 2px;
    min-width:35px;
    align-items:center;
    justify-content: center;
    margin:2px;
    min-height: 1em;
    color: rgb(27, 152, 255);
    background-color:white;
    cursor: pointer;
    box-sizing: border-box;
    font-family: 'Lato';
    font-size: 1em;
    padding: 5px;
    width: 100%;
}

.button-action:hover {
    background-color: rgb(220, 237, 250);
}

.selector-Ufcd{
    cursor: pointer;
    font-size: 0.9em;
    min-width: 100px;
    background-color: #00000011;
    color: black;
    border: 0.5px solid;
    border-radius: 0.2em;
    padding: 0.5em;
    transition: all 0.2s ease;
    margin-top: 0.75em;
    margin-right: 0.75em;
    margin-bottom: 0.75em;
    box-shadow: 10px 10px 15px #00000022;
    text-decoration: none;
    font-family:'Lato'
}
.button-associar{
    
    cursor: pointer;
    font-size: 0.9em;
    min-width: 100px;
    background-color: #00000011;
    color: black;
    border: 0.5px solid;
    border-radius: 0.2em;
    padding: 0.5em;
    transition: all 0.2s ease;
    box-shadow: 10px 10px 15px #00000022;
    text-decoration: none;
    font-family:'Lato'
}

.button-associar:hover {
    background-color: rgb(117, 118, 119);
}

.botoes{
    display: flex;
    align-items: center;
    margin: auto;
}



`;
