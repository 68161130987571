import React, { useState, useEffect } from "react";
import { MenuFormando } from "./styled";
import { useParams } from 'react-router-dom'
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import NavbarFormando from "../../../components/Navbars/NavbarFormando/navbarFormando";
import { RxEnter } from "react-icons/rx";
import { AiOutlineUpload } from "react-icons/ai";

function ShowMenuFormando() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    document.data = data

    //Recebe um formando pelo seu id
    //Obtem formandos
    function getFormandos() {
        fetch(`${process.env.REACT_APP_API_URL}/formandos/${params.id_formando}`, {
            headers: {
                'authorization': localStorage.getItem('formando')
            }
        })

            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    setLoading(false);

                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    }

    const params = useParams();
    useEffect(() => {
        getFormandos()
    }, []);

    return (
        <>
            <Header />
            <MenuFormando>
                <main>
                    <div className="page">
                        <div className="navbar">
                            <NavbarFormando currentPage={"home"} />
                        </div>
                        <div className="content">
                            <div className="profile">
                                <img className="profilePic" src="https://img.freepik.com/fotos-gratis/retrato-de-um-jovem-executiva-segurando-oculos-em-mao-contra-cinzento-fundo_23-2148029483.jpg" alt="Foto" />
                                <div>
                                    <h2 className="title" >Olá, {data && data.fullName}!</h2>
                                    <div className="infos">
                                        <div>
                                            <p>Disciplinas</p>
                                            {data && data.ufcds.length}
                                        </div>
                                        <div>
                                            <p>Módulos</p>
                                            10
                                        </div>
                                        <div>
                                            <p>Horas</p>
                                            60
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr className="hrProfile" />
                            <div className="rapidAcess">
                                <h2>Acesso rápido</h2>
                                <h3 className="subSubTitle">Aulas seguintes</h3>
                                <div className="grid-container">
                                    <div className="aula">
                                        <h4>Sala Aberta</h4>
                                        <p>09:30H</p>
                                        <RxEnter size={50} className="iconAula" />
                                        <p>Manuel Domingues</p>
                                        <p className="subSubTitle">UFCD 8742</p>
                                    </div>
                                    <div className="aula">
                                        <h4>Sala Aberta</h4>
                                        <p>09:30H</p>
                                        <RxEnter size={50} className="iconAula" />
                                        <p>Manuel Domingues</p>
                                        <p className="subSubTitle">UFCD 8742</p>
                                    </div>
                                    <div className="aula">
                                        <h4>Sala Aberta</h4>
                                        <p>09:30H</p>
                                        <RxEnter size={50} className="iconAula" />
                                        <p>Manuel Domingues</p>
                                        <p className="subSubTitle">UFCD 8742</p>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="rapidAcess">
                                <h2>Trabalhos a entregar</h2>
                                <div className="grid-container">
                                    <div className="aula">
                                        <h4>Marketing Digital</h4>
                                        <p className="subSubTitle">UFCD 8742</p>
                                        <AiOutlineUpload size={50} className="iconAula" />
                                        <p>Manuel Domingues</p>
                                    </div>
                                    <div className="aula">
                                        <h4>Marketing Digital</h4>
                                        <p className="subSubTitle">UFCD 8742</p>
                                        <AiOutlineUpload size={50} className="iconAula" />
                                        <p>Manuel Domingues</p>
                                    </div>
                                    <div className="aula">
                                        <h4>Marketing Digital</h4>
                                        <p className="subSubTitle">UFCD 8742</p>
                                        <AiOutlineUpload size={50} className="iconAula" />
                                        <p>Manuel Domingues</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </MenuFormando >
            <Footer />
        </>
    );
}

export default ShowMenuFormando;
