import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Footer from "../../../../../../components/Footer";
import Header from "../../../../../../components/Header";
import NavbarAdmin2 from "../../../../../../components/Navbars/NavbarAdmin2/NavbarAdmin2";
import ConfirmToaster from "../../../../../../components/Toast/toastConfirmChoice";

export default function Evaluation() {
    const [valor1, setValor1] = useState("")
    const [valor2, setValor2] = useState("")
    const [valor3, setValor3] = useState("")
    const [valor4, setValor4] = useState("")
    const navigate = useNavigate();
    const params = useParams();
    let total = parseInt((((parseInt(valor1) - 1) * 5 + (parseInt(valor2) - 1) * 4 + (parseInt(valor3) - 1) * 3 + (parseInt(valor4) - 1) * 2) * 100) / 28) || 0;

    function handleClick() {
        navigate(`/MenuSuperAdmin/list/Full-ufcds/${params.id_candidatura}`);
    }

    useEffect(() => {

    }, [total]);

    function avaliarFormadorUFCD() {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/trainer/${params.id_candidatura}/avaliar/${params.id_ufcd}`,
            {
                method: "PATCH",
                body: JSON.stringify({
                    evaluation: total
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem('admin2')
                }
            }).then((result) => {
                result.json().then((resp) => {
                })
                handleClick();
            })
    }

    return (
        <>
            <main className="bg-white">
                <div className="w-full">
                    <NavbarAdmin2 currentPage={"ListaFormador"} />
                </div>
                <Link to={'/MenuSuperAdmin/list/Full-ufcds/' + params.id_candidatura} className="back-button-div-noNavbar">
                    <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                </Link>
                <div className="mx-16 mt-8 max-w-7xl">
                    <h2 className="title">Avaliação do formador</h2>
                    <div className="mt-8">
                        <table className="w-full mx-auto overflow-auto text-base bg-white rounded-lg ">
                            <thead className="bg-main-color">
                                <tr className="text-white h-14">
                                    <th>Critérios</th>
                                    <th>Pontos por grau de resultado</th>
                                    <th>Ponderação</th>
                                    <th className="w-40">Resultado</th>
                                </tr>
                            </thead>
                            <tbody className="text-text-main-color">
                                <tr>
                                    <td><h4>Critério 1 Qualidade dos Materiais Pedagógicos</h4></td>
                                    <td>
                                        <tr><td><p>
                                            2 PONTOS - O/a formador/a desenvolve materiais pedagógicos (vídeo aulas, atividades, ou outros)
                                            que fomentam a aprendizagem e geram curiosidade pela aprendizagem nos formandos. Cumpre sempre
                                            a regra 20 Objetos de Aprendizagem para 25 horas e 40 Objetos de Aprendizagem para 50 horas.
                                        </p></td></tr>
                                        <tr><td><p>
                                            1 PONTO - Embora o/a formador/a cumpra a regra 20 Objetos de Aprendizagem para 25 horas e
                                            40 Objetos de Aprendizagem para 50 horas, as vídeo aulas devem ser mais centradas nas aprendizagens técnicas,
                                            ou então, devem ser mais aprofundadas. A duração das vídeo-aulas é adequada e não ultrapassa os 10 minutos.
                                        </p></td></tr>
                                        <tr><td><p>
                                            0 PONTOS - OU o/a formador/a não cumpre a regra dos 20 Objetos de Aprendizagem para 25 horas e
                                            40 Objetos de Aprendizagem para 50 horas, OU tem que melhorar as vídeo aulas, mesmo no básico como:
                                            sincronizar a animação com a locução ou devem ser mais curtas ou reduzir os slides têm excesso de conteúdos.
                                        </p></td></tr>
                                    </td>
                                    <td><h1>5</h1></td>
                                    <td>
                                        <select onWheel={(e) => e.target.blur()} className="selector" id="valor1" name="valor1" onChange={(e) => { setValor1(e.target.value) }}>
                                            <option value="" disabled selected>Selecione a nota</option>
                                            <option value={1}>0</option>
                                            <option value={2}>1</option>
                                            <option value={3}>2</option>
                                        </select>
                                    </td>

                                </tr>
                                <tr>
                                    <td><h4>Critério 2  Acompanhamento aos participantes</h4></td>
                                    <td>
                                        <tr><td><p>
                                            2 PONTOS - O/a formador/a dá feedback aos participantes de forma consistente, acrescentando valor
                                            ao debate e suscitando reflexão aos formandos. Cumpre a regra de dar feedback a 100% das intervenções dos
                                            participantes no FÓRUM GERAL.
                                        </p></td></tr>
                                        <tr><td><p>
                                            1 PONTO - Embora o/a formador/a cumpra a regra de dar feedback a 100% das intervenções dos participantes
                                            FÓRUM GERAL, o tipo de feedback tem de acrescentar mais valor e suscitar aprendizagens nos formandos.
                                        </p></td></tr>
                                        <tr><td><p>
                                            0 PONTOS - OU o/a formador/a não cumpre a regra de dar feedback a 100% das intervenções dos participantes,
                                            OU, genericamente, não contribui para as aprendizagens nesses feedbacks.
                                        </p></td></tr>
                                    </td>
                                    <td><h1>4</h1></td>
                                    <td>
                                        <select onWheel={(e) => e.target.blur()} className="selector" id="valor2" name="valor2" onChange={(e) => { setValor2(e.target.value) }}>
                                            <option value="" disabled selected>Selecione a nota</option>
                                            <option value={1}>0</option>
                                            <option value={2}>1</option>
                                            <option value={3}>2</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td><h4>Critério 3 Cumprimento de preceitos administrativos e prazos</h4></td>
                                    <td>
                                        <tr><td><p>
                                            2 PONTOS - O/a formador/a entrega a PAUTA com dados corretamente transferidos da plataforma e no prazo máximo
                                            de 15 dias (consecutivos) após o último dia de formação, à Coordenação Pedagógica/Mediação.A restante
                                            documentação do DTP é entregue dentro desse prazo (inclsive o Registo de Assiduidade Assíncrona do Formador e as Folhas de Sumário da Formação Síncrona).
                                        </p></td></tr>
                                        <tr><td><p>
                                            1 PONTO - Embora o/a formador/a entregue a documentação atempadamente, comete/eu falhas (não sistemáticas) na construção da PAUTA ou na fidedignidade da informação constante nos registos de assiduidade.
                                        </p></td></tr>
                                        <tr><td><p>
                                            0 PONTOS - OU o/a formador/a não cumpre prazos de entrega de documentos OU a PAUTA e registos de assiduidade têm erros.
                                        </p></td></tr>
                                    </td>
                                    <td><h1>3</h1></td>
                                    <td>
                                        <select onWheel={(e) => e.target.blur()} className="selector" id="valor3" name="valor3" onChange={(e) => { setValor3(e.target.value) }}>
                                            <option value="" disabled selected>Selecione a nota</option>
                                            <option value={1}>0</option>
                                            <option value={2}>1</option>
                                            <option value={3}>2</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td><h4>Critério 4 Satisfação dos formandos com o desempenho do formador</h4></td>
                                    <td>
                                        <tr><td><p>
                                            2 PONTOS – Na “Ficha de Reação à Formação”, em todos os critérios relacionados com o “formador”, obtém uma taxa de notas “4” e “5” superior a 90%.
                                        </p></td></tr>
                                        <tr><td><p>
                                            1 PONTO - Na “Ficha de Reação à Formação”, em todos os critérios relacionados com o “formador”, obtém uma taxa de notas “4” e “5” superior a 80%.
                                        </p></td></tr>
                                        <tr><td><p>
                                            0 PONTOS - Na “Ficha de Reação à Formação”, em todos os critérios relacionados com o “formador”, NÃO obtém uma taxa de notas “4” e “5” superior a 80%.
                                        </p></td></tr>
                                    </td>
                                    <td><h1>2</h1></td>
                                    <td>
                                        <select onWheel={(e) => e.target.blur()} className="selector" id="valor4" name="valor4" onChange={(e) => { setValor4(e.target.value) }}>
                                            <option value="" disabled selected>Selecione a nota</option>
                                            <option value={1}>0</option>
                                            <option value={2}>1</option>
                                            <option value={3}>2</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex flex-col items-center my-8">
                        {total === null ? <h2></h2> : <h2 className="third-title">TOTAL FINAL = {total}%</h2>}
                        <ConfirmToaster css={"button-confirmar mt-2 hover:brightness-75 tooltip"} icon={"Confirmar"} title={"Avaliar"} message={"Deseja avaliar o formador ? (Esta ação é irreversível)"} confirm={avaliarFormadorUFCD} tooltip={"Avaliar Formador"}></ConfirmToaster>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    )
}