import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../../../../components/Footer";
import Header from "../../../../../components/Header";
import 'react-toastify/dist/ReactToastify.css';
import NavbarCoordenador from "../../../../../components/Navbars/NavbarCoordenador/navbarCoordenador";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";
import { FaEye } from "react-icons/fa";

function Candidaturas() {
    const [data, setData] = useState(null);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [loading, setLoading] = useState(true);
    const [percurso, setPercurso] = useState(null);
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Coordenadores-courseIndFormadorPerc-Pag") : "";
    const savedSearchTerm = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Coordenadores-Formadores-1") : "";
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm ? savedSearchTerm : "");
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Coordenadores-courseIndFormadorPerc-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        Cookies.set("Coordenadores-courseIndFormadorPerc-Pag", paginaAtual);
        Cookies.set(`Coordenadores-courseIndFormadorPerc-itm1`, itemsPag)
        Cookies.set("Coordenadores-Formadores-1", searchTerm);
        getFormadores()
        getPercurso()
        // eslint-disable-next-line
    }, [loading, paginaAtual, nPaginas, itemsPag, searchTerm]);

    //filtra dados
    function filterData(data) {
        return data.filter((item) =>
            item.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.nif.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }
    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }


    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }

    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };
    function getFormadores() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}/formadores`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "authorization": localStorage.getItem("coord")
            },
            method: 'GET',
        })
            .then(res => res.json())
            .then((result) => {
                setData(result)
                paginacaoTabelas(filterData(result));
                setLoading(false)
            })
    }

    //Obtem percurso
    function getPercurso() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "authorization": localStorage.getItem("coord")
            },
            method: 'GET',
        })
            .then(res => res.json())
            .then((result) => {
                setPercurso(result.name)
                setLoading(false)
            })
    }

    const handleClick = (event, item) => {
        // Check if the clicked element is a <td>
        if (event.target.tagName === 'TD') {
            // Check if the <td> has a 'clickable' class
            if (!event.target.classList.contains('candidate')) {
                // Navigate to the desired location
                // Replace 'destination' with your actual navigation logic
            }
            else navigate("/menucoordenador/" + params.id_candidatura + "/cursos/" + params.id_curso + "/formadores/" + item._id)
        }
    };
    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }
    return (
        <>
            <main className="min-h-screen grid-cols-7 mx-auto md:grid xl:grid-cols-10">
                <div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    <NavbarCoordenador currentPage={"cursos"} />
                </div>

                <div className="relative h-full col-span-5 mx-4 xl:col-span-8">
                    <div className="back-button-noNavbar">
                        <Link to={'/menucoordenador/' + params.id_candidatura + "/cursos/" + params.id_curso} className="back-button-link">
                            <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                        </Link>
                    </div>
                    <Header sideMenu={"true"} />
                    <h1 className="mx-4 mt-8 title sm:mt-0">Formadores - {percurso}</h1>
                    <div className="search-div">
                        <label className="search-input-label">Ver apenas:</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input type="text" className="search-input" placeholder="ex: Nome, Local ..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                        </div>
                    </div>
                    {data && data.length > 0 ? <>
                        <div className="flex justify-center items-center mb-1">
                            <label className="text-text-main-color">Dados por página: </label>
                            <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                value={itemsPag}
                                onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div className="table-containerSideMenu">
                            <table className="my-table">


                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="p-2 min-w-[80px] border border-white">Nome Completo</th>
                                        <th className="p-2 min-w-[80px] border border-white">Email</th>
                                        <th className="p-2 min-w-[80px] border border-white">NIF</th>
                                        <th className="p-2 min-w-[80px] border border-white">Histórico</th>
                                    </tr>
                                </thead>
                                {data ? (
                                    <tbody className="text-text-main-color">
                                        {dataToDisplay.map((item, index) => (
                                            <tr key={index} className="row-click" onClick={(e) => handleClick(e, item)}>
                                                <td className="border candidate border-white">{item.fullName}</td>
                                                <td className="pr-2 candidate border border-white">{item.email}</td>  
                                                <td className="border candidate border-white">{item.nif}</td>
                                                <td className="border border-white cursor-pointer hover:bg-gray-300 group" onClick={(e) => navigate("/menucoordenador/" + params.id_candidatura + "/cursos/" + params.id_curso + "/formadoresHistorico/" + item._id)}>
                                                    <div className="flex items-center justify-center group-hover:brightness-150">
                                                        <FaEye size={24} />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody></tbody>
                                )}
                            </table>
                        </div>
                        <div className="flex items-center justify-center mt-4 mb-8">
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                <AiOutlineDoubleLeft />
                            </button>
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                <AiOutlineLeft />
                            </button>
                            <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                <AiOutlineRight />
                            </button>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                <AiOutlineDoubleRight />
                            </button>
                        </div> </> : <p className="third-title">Não existem dados</p>}

                </div>
            </main >
            <Footer />
        </>
    );
}

export default Candidaturas