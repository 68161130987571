import styled from "styled-components";

export const AreaEmail = styled.div`

main.container{
    padding: 30px;
    background-color: white;
    box-sizing: border-box;
    
}

main input[type=email]{
    width: 80%;
    margin: 7px 0px;
    display: inline-block;
    padding: 12px 25px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid lightgrey;
    font-size: 1em;
    font-family: 'Lato';
    background: white;
}

main .button-submit{
    margin-top: 20px;
    background-color: rgb(27, 152, 255);
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 6em;
    height: 3em;
    font-size: 1em;
}

main .button-submit:hover{
    background-color: rgb(17, 101, 170);
}

`