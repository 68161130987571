function CheckTypeAdmin() {
  if(localStorage.getItem("TipoAdmin") === "Gestor Financeiro"){
    return false
  }
  else if(localStorage.getItem("TipoAdmin") === "Técnico Administrativo"){
    return false
  }
  else if(localStorage.getItem("TipoAdmin") === "TORVC"){
    return false
  }
  else return true
}
export default CheckTypeAdmin;