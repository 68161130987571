import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';


async function ExcelUtilizadoresExternos(formadores, formandos, coordenadores) {

    // Variaveis de estilização
    const alignment = {
        horizontal: 'center',
        vertical: 'middle',
        wrapText: true,
    };

    // Inicio Excel
    const workbook = new ExcelJS.Workbook();

    function getColumnLetterFromIndex(index) {
        const columnLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let columnName = '';

        while (index > 0) {
            const remainder = (index - 1) % 26;
            columnName = columnLetters[remainder] + columnName;
            index = Math.floor((index - 1) / 26);
        }

        return columnName;
    }
    //Define largura de varias colunas
    function setMultipleColWidth(worksheet, startCol, endCol, width) {
        for (let col = startCol; col <= endCol; col++) {
            worksheet.getColumn(getColumnLetterFromIndex(col)).width = width;
        }
    }
    const convertDateFormat2 = (originalDate) => {
        // Split the date into an array using the dash as a separator
        const dateArray = originalDate.split('-');
        // Rearrange the array elements to the desired format
        const formattedDate = `${dateArray[2]}`+`/${dateArray[1]}`+`/${dateArray[0]}`;
        return formattedDate;
    };
    
    function dadosFormadores() {
        //INICIO DA PÁGINA FORMADORES
        const worksheet = workbook.addWorksheet('Formadores');

        setMultipleColWidth(worksheet, 1, 28, 20)

        const filteredData = formadores.filter(element => element.valid === true);
        const sortedData = filteredData.slice().sort((a, b) => a.fullName - b.fullName);

        const jsonData = sortedData.map(element => ({
            NomeCompleto: element.fullName,
            DataNascimento: convertDateFormat2(element.birthDate),
            Ntelemovel: element.cellNumber,
            Email: element.email,
            Naturalidade: element.parish,
            Localidade: element.locality,
            Concelho: element.county,
            Morada: element.address,
            CodigoPostal: element.postalCode,
            Nacionalidade: element.nationality,
            NumeroDoDocumento: element.ccNumber,
            ValidadeDoc: convertDateFormat2(element.ccExpiration),
            NIF: element.nif,
            CAP: element.cap,
            IRS: element.iva,
            Iva: element.iva,
            GrauAcademico: element.academicDegree,
            Curso: element.courseDesignation,
            AnoConclusao: element.conclusionYear,
            Profissao: element.office,
        }));

        // Celulas de texto - Cabeçalho
        const A1 = worksheet.getCell('A1');
        const B1 = worksheet.getCell('B1');
        const C1 = worksheet.getCell('C1');
        const D1 = worksheet.getCell('D1');
        const E1 = worksheet.getCell('E1');
        const F1 = worksheet.getCell('F1');
        const G1 = worksheet.getCell('G1');
        const H1 = worksheet.getCell('H1');
        const I1 = worksheet.getCell('I1');
        const J1 = worksheet.getCell('J1');
        const K1 = worksheet.getCell('K1');
        const L1 = worksheet.getCell('L1');
        const M1 = worksheet.getCell('M1');
        const N1 = worksheet.getCell('N1');
        const O1 = worksheet.getCell('O1');
        const P1 = worksheet.getCell('P1');
        const Q1 = worksheet.getCell('Q1');
        const R1 = worksheet.getCell('R1');
        const S1 = worksheet.getCell('S1');
        const T1 = worksheet.getCell('T1');

        A1.alignment = alignment;
        B1.alignment = alignment;
        C1.alignment = alignment;
        D1.alignment = alignment;
        E1.alignment = alignment;
        F1.alignment = alignment;
        G1.alignment = alignment;
        H1.alignment = alignment;
        I1.alignment = alignment;
        J1.alignment = alignment;
        K1.alignment = alignment;
        L1.alignment = alignment;
        M1.alignment = alignment;
        N1.alignment = alignment;
        O1.alignment = alignment;
        P1.alignment = alignment;
        Q1.alignment = alignment;
        R1.alignment = alignment;
        S1.alignment = alignment;
        T1.alignment = alignment;

        // Valor das Celulas
        A1.value = 'Nome Completo';
        B1.value = 'Data de Nascimento';
        C1.value = 'Número de telemóvel';
        D1.value = 'E-mail';
        E1.value = 'Naturalidade';
        F1.value = 'Localidade';
        G1.value = 'Concelho';
        H1.value = 'Morada';
        I1.value = 'Código Postal';
        J1.value = 'Nacionalidade';
        K1.value = 'Número do Documento de Identificação';
        L1.value = 'Validade do CC';
        M1.value = 'NIF';
        N1.value = 'CAP';
        O1.value = 'IRS';
        P1.value = 'IVA';
        Q1.value = 'Grau Academico';
        R1.value = 'Curso';
        S1.value = 'Ano de Conclusão';
        T1.value = 'Profissão';

        //Dados
        let index = 2
        jsonData.forEach(element => {
            const nome = worksheet.getCell("A" + index);
            const dataDeNascimento = worksheet.getCell("B" + index);
            const nTelemovel = worksheet.getCell("C" + index);
            const email = worksheet.getCell("D" + index);
            const naturalidade = worksheet.getCell("E" + index);
            const localidade = worksheet.getCell("F" + index);
            const concelho = worksheet.getCell("G" + index);
            const morada = worksheet.getCell("H" + index);
            const codigoPostal = worksheet.getCell("I" + index);
            const nacionalidade = worksheet.getCell("J" + index);
            const numberCC = worksheet.getCell("K" + index);
            const dateCC = worksheet.getCell("L" + index);
            const nNif = worksheet.getCell("M" + index);
            const nCap = worksheet.getCell("N" + index);
            const nIrs = worksheet.getCell("O" + index);
            const nIva = worksheet.getCell("P" + index);
            const grauAcademico = worksheet.getCell("Q" + index);
            const curso = worksheet.getCell("R" + index);
            const anoConclusao = worksheet.getCell("S" + index);
            const profissao = worksheet.getCell("T" + index);

            nome.value = element.NomeCompleto;
            dataDeNascimento.value = element.DataNascimento;
            nTelemovel.value = element.Ntelemovel;
            email.value = element.Email;
            naturalidade.value = element.Naturalidade;
            localidade.value = element.Localidade;
            concelho.value = element.Concelho;
            morada.value = element.Morada;
            codigoPostal.value = element.CodigoPostal;
            nacionalidade.value = element.Nacionalidade;
            numberCC.value = element.NumeroDoDocumento;
            dateCC.value = element.ValidadeDoc;
            nNif.value = element.NIF;
            nCap.value = element.CAP;
            nIrs.value = element.IRS === 1 ? "Sim" : "Não";
            nIva.value = element.IVA === 1 ? "Sim" : "Não";
            grauAcademico.value = element.GrauAcademico;
            curso.value = element.Curso;
            anoConclusao.value = element.AnoConclusao;
            profissao.value = element.Profissao;

            index++;
        });

    }

    function dadosCoordenadores() {
        //INICIO DA PÁGINA COORDENADORES
        const worksheet = workbook.addWorksheet('Coordenadores');

        setMultipleColWidth(worksheet, 1, 28, 20)

        const filteredData = coordenadores.filter(element => element.tipo === false);
        const sortedData = filteredData.slice().sort((a, b) => a.fullName - b.fullName);

        const jsonData = sortedData.map(element => ({
            NomeCompleto: element.fullName,
            Ntelemovel: element.cellNumber,
            Email: element.email,
            Naturalidade: element.parish,
            Localidade: element.locality,
            Concelho: element.county,
            Morada: element.address,
            CodigoPostal: element.postalCode,
            Nacionalidade: element.nationality,
            NumeroDoDocumento: element.ccNumber,
            NIF: element.nif,
            Tipo: element.tipo,
            Estado: element.value,
            Iva: element.iva,
            Irs: element.irs
        }));

        // Celulas de texto - Cabeçalho
        const A1 = worksheet.getCell('A1');
        const B1 = worksheet.getCell('B1');
        const C1 = worksheet.getCell('C1');
        const D1 = worksheet.getCell('D1');
        const E1 = worksheet.getCell('E1');
        const F1 = worksheet.getCell('F1');
        const G1 = worksheet.getCell('G1');
        const H1 = worksheet.getCell('H1');
        const I1 = worksheet.getCell('I1');
        const J1 = worksheet.getCell('J1');
        const K1 = worksheet.getCell('K1');
        const L1 = worksheet.getCell('L1');
        const M1 = worksheet.getCell('M1');

        A1.value = "Nome Completo";
        B1.value = "Número Telemóvel";
        C1.value = "Email";
        D1.value = "Naturalidade";
        E1.value = "Localidade";
        F1.value = "Concelho";
        G1.value = "Morada";
        H1.value = "Código Postal";
        I1.value = "Nacionalidade";
        J1.value = "Número do Documento de Identificação";
        K1.value = "NIF";
        L1.value = "IVA";
        M1.value = "IRS";

        A1.alignment = alignment;
        B1.alignment = alignment;
        C1.alignment = alignment;
        D1.alignment = alignment;
        E1.alignment = alignment;
        F1.alignment = alignment;
        G1.alignment = alignment;
        H1.alignment = alignment;
        I1.alignment = alignment;
        J1.alignment = alignment;
        K1.alignment = alignment;
        L1.alignment = alignment;
        M1.alignment = alignment;

        let index = 2;
        jsonData.forEach(element => {
            const nome = worksheet.getCell("A" + index);
            const nTelemovel = worksheet.getCell("B" + index);
            const email = worksheet.getCell("C" + index);
            const naturalidade = worksheet.getCell("D" + index);
            const localidade = worksheet.getCell("E" + index);
            const concelho = worksheet.getCell("F" + index);
            const morada = worksheet.getCell("G" + index);
            const codigoPostal = worksheet.getCell("H" + index);
            const nacionalidade = worksheet.getCell("I" + index);
            const numberCC = worksheet.getCell("J" + index);
            const nNif = worksheet.getCell("K" + index);
            const nIrs = worksheet.getCell("L" + index);
            const nIva = worksheet.getCell("M" + index);

            nome.value = element.NomeCompleto;
            nTelemovel.value = element.Ntelemovel;
            email.value = element.Email;
            naturalidade.value = element.Naturalidade;
            localidade.value = element.Localidade;
            concelho.value = element.Concelho;
            morada.value = element.Morada;
            codigoPostal.value = element.CodigoPostal;
            nacionalidade.value = element.Nacionalidade;
            numberCC.value = element.NumeroDoDocumento;
            nNif.value = element.NIF;
            nIrs.value = element.IRS === 1 ? "Sim" : "Não";
            nIva.value = element.IVA === 1 ? "Sim" : "Não";

            index++
        })
    }

    function dadosFormandos() {
        //INICIO DA PÁGINA FORMADORES
        const worksheet = workbook.addWorksheet('Formandos');
        setMultipleColWidth(worksheet, 1, 28, 20)

        const filteredData = formandos.filter(element => element.valid === "Validado");
        const sortedData = filteredData.slice().sort((a, b) => a.fullName - b.fullName);

        const jsonData = sortedData.map(element => ({
            NomeCompleto: element.fullName,
            DataNascimento: element.birthDate,
            Ntelemovel: element.cellNumber,
            Email: element.email,
            Genero: element.gender,
            Naturalidade: element.parish,
            Localidade: element.locality,
            Concelho: element.county,
            Morada: element.address,
            CodigoPostal: element.postalCode,
            Nacionalidade: element.nationality,
            TipoDoc: element.documentType,
            NumeroDoDocumento: element.idNumber,
            ValidadeDoc: element.idExpiration,
            NIF: element.nif,
            NISS: element.niss,
            Cqep: element.CQEP,
            GrauAcademico: element.academicDegree,
            SituacaoLaborar: element.employmentSituation,
            DataDesemprego: element.employmentSituationDate,
            BeneficiarioSitSocial: element.beneficiarySS
        }));

        // Celulas de texto - Cabeçalho
        const A1 = worksheet.getCell('A1');
        const B1 = worksheet.getCell('B1');
        const C1 = worksheet.getCell('C1');
        const D1 = worksheet.getCell('D1');
        const E1 = worksheet.getCell('E1');
        const F1 = worksheet.getCell('F1');
        const G1 = worksheet.getCell('G1');
        const H1 = worksheet.getCell('H1');
        const I1 = worksheet.getCell('I1');
        const J1 = worksheet.getCell('J1');
        const K1 = worksheet.getCell('K1');
        const L1 = worksheet.getCell('L1');
        const M1 = worksheet.getCell('M1');
        const N1 = worksheet.getCell('N1');
        const O1 = worksheet.getCell('O1');
        const P1 = worksheet.getCell('P1');
        const Q1 = worksheet.getCell('Q1');
        const R1 = worksheet.getCell('R1');
        const S1 = worksheet.getCell('S1');
        const T1 = worksheet.getCell('T1');
        const U1 = worksheet.getCell('U1');

        A1.alignment = alignment;
        B1.alignment = alignment;
        C1.alignment = alignment;
        D1.alignment = alignment;
        E1.alignment = alignment;
        F1.alignment = alignment;
        G1.alignment = alignment;
        H1.alignment = alignment;
        I1.alignment = alignment;
        J1.alignment = alignment;
        K1.alignment = alignment;
        L1.alignment = alignment;
        M1.alignment = alignment;
        N1.alignment = alignment;
        O1.alignment = alignment;
        P1.alignment = alignment;
        Q1.alignment = alignment;
        R1.alignment = alignment;
        S1.alignment = alignment;
        T1.alignment = alignment;
        U1.alignment = alignment;

        // Valor das Celulas
        A1.value = 'Nome Completo';
        B1.value = 'Data de Nascimento';
        C1.value = 'Número de Telemóvel';
        D1.value = 'E-mail';
        E1.value = 'Género';
        F1.value = 'Naturalidade';
        G1.value = 'Localidade';
        H1.value = 'Concelho';
        I1.value = 'Morada';
        J1.value = 'Codigo Postal';
        K1.value = 'Nacionalidade';
        L1.value = 'Tipo de Documento';
        M1.value = 'Número de Documento';
        N1.value = 'Validade do Documento';
        O1.value = 'NIF';
        P1.value = 'NISS';
        Q1.value = 'CQ';
        R1.value = 'Grau Academico';
        S1.value = 'Situação Laboral';
        T1.value = 'Data de Desemprego';
        U1.value = 'Beneficiário Social'

        //Dados
        let index = 2
        jsonData.forEach(element => {
            const nome = worksheet.getCell("A" + index);
            const dataDeNascimento = worksheet.getCell("B" + index);
            const nTelemovel = worksheet.getCell("C" + index);
            const email = worksheet.getCell("D" + index);
            const genero = worksheet.getCell("E" + index);
            const naturalidade = worksheet.getCell("F" + index);
            const localidade = worksheet.getCell("G" + index);
            const concelho = worksheet.getCell("H" + index);
            const morada = worksheet.getCell("I" + index);
            const codigoPostal = worksheet.getCell("J" + index);
            const nacionalidade = worksheet.getCell("K" + index);
            const tipoDoc = worksheet.getCell("L" + index);
            const numeroDoc = worksheet.getCell("M" + index);
            const validadeDoc = worksheet.getCell("N" + index);
            const nNIF = worksheet.getCell("O" + index);
            const nNISS = worksheet.getCell("P" + index);
            const nCqep = worksheet.getCell("Q" + index);
            const grauAcademico = worksheet.getCell("R" + index);
            const situacaoLaboral = worksheet.getCell("S" + index);
            const dataDesemprego = worksheet.getCell("T" + index);
            const beneficiarioSocial = worksheet.getCell("U" + index);

            nome.value = element.NomeCompleto;
            dataDeNascimento.value = element.DataNascimento;
            nTelemovel.value = element.Ntelemovel;
            email.value = element.Email;
            genero.value = element.Genero;
            naturalidade.value = element.Naturalidade;
            localidade.value = element.Localidade;
            concelho.value = element.Concelho;
            morada.value = element.Morada;
            codigoPostal.value = element.CodigoPostal;
            nacionalidade.value = element.Nacionalidade;
            tipoDoc.value = element.TipoDoc;
            numeroDoc.value = element.NumeroDoDocumento;
            validadeDoc.value = element.ValidadeDoc;
            nNIF.value = element.NIF;
            nNISS.value = element.NISS
            nCqep.value = element.Cqep;
            grauAcademico.value = element.GrauAcademico;
            situacaoLaboral.value = element.SituacaoLaborar;
            dataDesemprego.value = element.DataDesemprego;
            beneficiarioSocial.value = element.BeneficiarioSitSocial;

            index++;
        });

    }


    dadosFormadores()
    dadosCoordenadores()
    dadosFormandos()

    // Generate the Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Convert the buffer to a Blob
    const blob = new Blob([buffer], { type: 'application/octet-stream' });

    // Save the Blob to the user's computer
    FileSaver.saveAs(blob, 'UtilizadoresExternos.xlsx');
};
export default ExcelUtilizadoresExternos;