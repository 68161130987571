import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../../components/Footer";
import NavbarAdmin2 from "../../../components/Navbars/NavbarAdmin2/NavbarAdmin2";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";
import AlterarFormato from "../../../components/FormChecks/formatDates/foramatDates";

function Candidaturas() {
    const [data, setData] = useState(null);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const savedSearchTerm = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("SuperAdmin-turmas-1") : "";
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm ? savedSearchTerm : "");
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("SuperAdmin-turmas-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`SuperAdmin-turmas-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)
    const params = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        Cookies.set("SuperAdmin-turmas-Pag", paginaAtual);
        Cookies.set("SuperAdmin-turmas-1", searchTerm);
        Cookies.set(`SuperAdmin-turmas-itm1`, itemsPag)
        getTurmas()
    }, [loading, paginaAtual, nPaginas, searchTerm, itemsPag]);

    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };

    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }


    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }

    //filtra dados
    function filterData(data) {
        return data.filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }

    function getTurmas() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/ativos`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem('admin2')
            },
            method: 'GET',
        })
            .then(res => res.json())
            .then(async (result) => {
                const percursos = await Promise.all(result.map((element) => fetchCandidaturas(element)));
                setData(percursos)
                paginacaoTabelas(filterData(percursos));
                setLoading(false)
            })
    }

    const fetchCandidaturas = async (element) => {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/percurso/${element._id}/candidatos`,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: localStorage.getItem('admin2'),
                },
                method: 'GET',
            }
        );
        const result = await response.json();
        return { ...element, nCandidaturas: result };
    };

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }
    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin2 currentPage={"gerirTurmasFormandos"} />
                </div>
                <div className="flex-grow mt-8">
                    <Link to={'/MenuSuperAdmin'} className="back-button-div-noNavbar">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>
                    <h1 className="title">Lista de turmas</h1>
                    {data && data.length > 0 ? <> <div className="search-div">
                        <label className="search-input-label">Pesquisar: </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input type="text" className="search-input" placeholder="ex: Nome" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                        </div>
                    </div>
                        <div className="flex justify-center items-center mb-1">
                            <label className="text-text-main-color">Dados por página: </label>
                            <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                value={itemsPag}
                                onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div className="table-container">
                            <table className="my-table">
                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="p-2 min-w-[80px] border border-white">Operação</th>
                                        <th className="p-2 min-w-[150px] border border-white">Nome</th>
                                        <th className="p-2 min-w-[80px] border border-white">Forma de Organização</th>
                                        <th className="p-2 min-w-[80px] border border-white">Modalidades</th>
                                        <th className="p-2 min-w-[80px] border border-white">Local</th>
                                        <th className="p-2 min-w-[80px] border border-white">Nº de Candidatos</th>
                                        <th className="p-2 min-w-[80px] border border-white">Nº de Inscritos</th>
                                        <th className="p-2 min-w-[80px] border border-white">Nº de Disciplinas</th>
                                        <th className="p-2 min-w-[80px] border border-white">Data de Início</th>
                                        <th className="p-2 min-w-[80px] border border-white">Data de Fim</th>
                                        <th className="p-2 min-w-[80px] border border-white">Duração</th>
                                        <th className="p-2 min-w-[150px] border border-white">Coordenador Pedagógico</th>
                                    </tr>
                                </thead>
                                {data.length > 0 ? (
                                    <tbody className="text-text-main-color">
                                        {filterData(dataToDisplay)
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((item, index) => (
                                                <tr key={index} className="row-click" onClick={() => navigate("/MenuSuperAdmin/turmas/turma/" + item._id)}>
                                                    <td className="border border-white">{item.operacao[0].codigoOperacao}</td>
                                                    <td className="border border-white">{item.name}</td>
                                                    <td className="border border-white">{item.type}</td>
                                                    <td className="border border-white">{item.modalidade}</td>
                                                    <td className="border border-white">{item.locality}</td>
                                                    <td className="border border-white">{item.nCandidaturas}</td>
                                                    {item.formandos ?
                                                        <td className="border border-white">
                                                            {item.formandos.filter((formando) => formando.percurso.some(percursoItem => percursoItem._id === item._id)).length}
                                                        </td>
                                                        :
                                                        <td className="border border-white">0</td>
                                                    }                                                       <td className="border border-white">{item.ufcds.length}</td>
                                                    <td className="border border-white">{AlterarFormato(item.dateBegin)}</td>
                                                    <td className="border border-white">{AlterarFormato(item.dateEnd)}</td>
                                                    <td className="border border-white">{item.duration}</td>
                                                    <td className="border border-white">{item.Coordenador && item.Coordenador[0] ? item.Coordenador[0].fullName : "Sem Coordenador"}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                ) : (
                                    <tbody />
                                )}
                            </table>
                        </div>
                        <div className="flex items-center justify-center mt-4 mb-8">
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                <AiOutlineDoubleLeft />
                            </button>
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                <AiOutlineLeft />
                            </button>
                            <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                <AiOutlineRight />
                            </button>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                <AiOutlineDoubleRight />
                            </button>
                        </div> </> : <p className="third-title">Não existem dados</p>}
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main >

        </>
    );
}

export default Candidaturas