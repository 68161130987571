import styled from "styled-components";
export const AreaIndividual = styled.div`
align-items: center;
justify-content: center;
flex-direction: column;
font-family: "Lato";
display: flex;
margin-top: 14vh;

.container-all{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}
    .dadosCandidato {
        min-width: 600px;
        overflow: auto;
        font-size: 30px;
        max-height: 65vh;
        text-align: center;
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        width: 35%;
        box-shadow: 5px 5px 15px #00000022;
        padding: 1em;
        padding-top: 0em;
        background-color: white;
    }

    .dadosCandidato h1 {
        font-size: 40px;
        margin: 1em;
    }

    .dadosCandidato .dado{
        display: flex;
        justify-content: center;
        margin-bottom: 2px;
        font-size: 18px;
    }

    .dadosCandidato .dado div:first-child{
        flex: 1;
        text-align: right;
        padding: 10px 15px 10px 10px;
        background-color: rgb(71 71 71);
        color: white;
        font-weight: 700;
        letter-spacing: 2px;
    }

    .dadosCandidato .dado div:last-child {
        flex: 1;
        text-align: left;
        padding: 10px 10px 10px 15px;
        background-color: #00000011;
    }
.buttons {
        margin-top: 0.75em;
        display: flex;
        justify-content: space-around;
        
    }

    .buttons button {
        font-size: 0.75em;
        min-width: 100px;
        background-color: #898989;
        color: white;
        border: none;
        border-radius: 0.2em;
        padding: 0.5em;
        transition: all 0.2s ease;
        margin-bottom: 0.75em;
        box-shadow: 10px 10px 15px #00000022;
    }

    #validar-botao {
        cursor: pointer;
        font-size: 0.75em;
        min-width: 130px;
        background-color: #1b98ff;
        color: white;
        font-weight: 550;
        border: none;
        border-radius: 0.2em;
        padding: 0.5em;
        transition: all 0.2s ease;
        box-shadow: 10px 10px 15px #00000022;
    }
    .formato{
        display: flex;
        font-size: 14px;
        color: red;
        justify-content:center;
    }
    
    #alterar-botao {
        margin: 0 auto;
        display:flex;
        justify-content:center;
        cursor: pointer;
        font-size: 0.75em;
        min-width: 130px;
        background-color:#575757;
        color: white;
        font-weight: 550;
        border: none;
        border-radius: 0.2em;
        padding: 0.5em;
        transition: all 0.2s ease;
        margin-top:1em;
        margin-bottom: 1em;
        box-shadow: 10px 10px 15px #00000022;
    }
    #alterar-botao:hover{
        background-color:#3a3a3a
    }

    #validar-botao:hover {
        background-color: #008bfd;
    }
    .buttons .filed{
        background-color: #5d97d0;
        color: white;
        cursor: pointer;
    }
    .buttons .filed:hover{
        background-color: #3d658c !important;
        color: white;
    }

    .files .dado{
        display: flex;
        justify-content: center;
        margin-bottom: 2px;
        font-size: 18px;
    }
    
    .files .dado div:first-child{
        display: block;
        flex: 1;
        text-align: right;
        padding: 10px 15px 10px 10px;
        background-color: rgb(71 71 71);
        color: white;
        font-weight: 700;
        letter-spacing: 2px;
    }

    .files .dado div:last-child {
        flex: 1;
        text-align: left;
        padding: 10px 10px 10px 15px;
        background-color: #00000011;
    }
    .submeter{
        margin: 0 auto;
        display:flex;
        justify-content:center;
        cursor: pointer;
        font-size: 0.75em;
        min-width: 130px;
        background-color:#898989;
        color: white;
        font-weight: 550;
        border: none;
        border-radius: 0.2em;
        padding: 0.5em;
        transition: all 0.2s ease;
        margin-top:1em;
        margin-bottom: 1em;
        box-shadow: 10px 10px 15px #00000022;
    }
    .submeter:hover{
        background-color:#4f4f4f
    }
    .botoes{
        display:flex;
    }

    .table-container{
        margin-top:20px;
        border-radius: 12px;  
    }    

    table{
        width:100%;
        display: block;
        text-align: center; 
    }
    table thead th {
        width:300px;
        padding: 12px;
        background-color: rgb(71 71 71);
        color: white;
        font-size: 18px;
    }

    table tr td{
        font-size: 18px;
        flex: 1;
        text-align: center;
        padding:0;
        background-color: #00000011;
    }
    `
