import Header from "../../../components/Header";
import React, { useState, useEffect, useRef } from "react";
import Footer from '../../../components/Footer'
import { useNavigate, useParams, Link } from 'react-router-dom';
import Modal from 'react-modal'
import toastSuccess from "../../../components/Toast/toastSuccess";
import toastError from "../../../components/Toast/toastError";
import MultiStepForm from "../../../components/FormChecks/MultiStepForm/MultiStepForm";
import ReactModal from "react-modal";
import onlyString from "../../../components/FormChecks/onlyString/onlyString";
import { toast } from "react-toastify";
import check_nif_number from "../../../components/FormChecks/checkNif/checkNIf";
import NavbarAdmin1 from "../../../components/Navbars/NavbarAdmin1/NavbarAdmin1";
import isNumber from "../../../components/isNumber/isNumber";
import removeAccents from "../../../components/FormChecks/removeAccents/removeAccents";
import { AiOutlineCloseCircle } from "react-icons/ai";
import CapitalizarNomes from "../../../components/capitalizeNames/capitalizeNames";
ReactModal.setAppElement('#root');

//Passos do formulario
const steps = [
    "TIPO DE COORDENADOR",
    "DADOS DO COORDENADOR",
    "REGISTO",
    "NACIONALIDADE",
    "RESIDÊNCIA",
    "IDENTIFICAÇÃO",
    "DOCUMENTOS",
];

const stepsInterno = [
    "TIPO DE COORDENADOR",
    "DADOS DO COORDENADOR",
];

function ShowCoordinators() {
    const [currentStep, setCurrentStep] = useState(0);
    const [section, setSection] = useState(-1);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [searchAdm, setSearchAdm] = useState("")
    const [modoCriacao, setModoCriacao] = useState("");
    const [tipoConta, setTipoConta] = useState("");
    const params = useParams()
    const navigate = useNavigate();
    const ref = useRef();
    let Next = process.env.PUBLIC_URL + "/Next.svg"


    //Aumentar form steps
    const handleNextStep = () => {
        if (section !== -1 && section !== 0) {
            setCurrentStep(currentStep + 1);
        }
    };

    //Diminuir form steps
    const handleBackStep = () => {
        if (section !== 1 && section !== 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    //Dados guardados
    // Secção - 0 - TIPO DE COORDENADOR
    const [tipoCoordenador, setTipoCoordenador] = useState("")

    // Secção - 1 - Dados Informartivos
    const [fullName, setFullName] = useState("")
    const [email, setEmail] = useState("")
    const [cellNumber, setCellNumber] = useState("")
    const [nif, setNif] = useState("")
    const [mailPersonalizado, setMailPersonalizado] = useState("")

    useEffect(() => {
        return () => {
            toast.dismiss();
        }
    }, [section])
    function sectionNext() {
        if (section === -1 && modoCriacao === "nova") {
            setSection(section + 2);
        } else {
            setSection(section + 1);
        }
    }
    //Secção anterior
    function sectionBack() {
        if (section === 1 && modoCriacao === "nova") {
            setSection(section - 2);
        } else {
            setSection(section - 1);
        }
    }
    //Animação fade
    function fade() {
        setTimeout(() => {
            document.querySelector('#main-form').classList.remove('fadeInLeft')
            document.querySelector('#main-form').classList.remove('fadeInRight')
            document.querySelector('#main-form').classList.add('fadeOutLeft')
        }, 0);
        setTimeout(() => {
            document.querySelector('#main-form').classList.remove('fadeOutLeft')
            sectionNext()
            document.querySelector('#main-form').classList.add('fadeInRight')
        }, 500);
        handleNextStep();
    }
//Animação fade reverse
    function fadeInRight() {
        setTimeout(() => {
            document.querySelector('#main-form').classList.remove('fadeInRight')
            document.querySelector('#main-form').classList.remove('fadeInLeft')
            document.querySelector('#main-form').classList.add('fadeOutRight')
        }, 0);
        setTimeout(() => {
            document.querySelector('#main-form').classList.remove('fadeOutRight')
            sectionBack()
            document.querySelector('#main-form').classList.add('fadeInLeft')
        }, 500);
        handleBackStep();
    }



    //Higienização e Continuação
    const handleNext = () => {
        if (section === -1) {
            if (modoCriacao === "nova") {
                fade()
            } else if (modoCriacao === "existente") {
                fade()
            } else toastError("Selecione uma opção para prosseguir.")

        } //Não existe higinização para a section 0;
        if (section === 1) {
            if (tipoCoordenador !== "") {
                fade()
            } else toastError("Selecione o tipo de coordenador.")
        }
        if (section === 2) {
            if (fullName !== "" && email !== "" && nif !== "" && cellNumber !== "") {
                if (onlyString(fullName)) {
                    fetch(`${process.env.REACT_APP_API_URL}/coordenadores/emails/${email}`)
                        .then((res) => res.json())
                        .then(
                            (result) => {
                                // Verificar se o email tem "@" e o "."
                                let checkEmail = email.includes("@" && ".")
                                if (checkEmail === true && result === true) {
                                    if (isNumber(cellNumber) || cellNumber === "") {
                                        if (cellNumber.length < 13 && cellNumber.length > 0) {
                                            if (check_nif_number(nif)) {
                                                setModalIsOpen(true);
                                            } else toastError("Número de nif inválido.")
                                        } else toastError("Nº de telemóvel inválido  ou com caracteres inválidos.")
                                    } else toastError("Nº de telemóvel inválido  ou com caracteres inválidos.")
                                } else toastError("Email incorreto ou Email existente.")
                            },
                            (error) => {
                                console.error("Error fetching data: ", error);
                            }
                        );
                } else toastError("Nome completo inválido.");
            } else toastError("Preencha todos os dados obrigatórios.")
        }
    }

    //Retroceder 
    const handlePrevious = () => {
        fadeInRight();
    }

    //transforma data em json
    function formDataToJson(formData) {
        var object = {};
        formData.forEach((value, key) => object[key] = value);
        return JSON.stringify(object);
    }

    //Adiciona um novo coordenador a partir de uma conta existente
    function addCoordinator(e) {
        e.preventDefault()
        if (searchAdm !== "") {
            if (searchAdm.includes("@" && ".")) {
                fetch(`${process.env.REACT_APP_API_URL}/coordenadores/emails/${searchAdm}`)
                    .then((res) => res.json())
                    .then(
                        (result) => {
                            if (result === true) {
                                fetch(`${process.env.REACT_APP_API_URL}/coordenadores`,
                                    {
                                        method: 'POST',
                                        headers: { 'Content-Type': 'application/json', 'authorization': localStorage.getItem('admin1') },
                                        body: JSON.stringify({
                                            email: searchAdm,
                                            tipo: tipoConta,
                                            mailPersonalizado: mailPersonalizado
                                        })
                                    })
                                    .then(res => res.json())
                                    .then(
                                        (result) => {
                                            if (result.length === 0) {
                                                toastError("Resultado não encontrado")
                                            } else {
                                                toastSuccess("Coordenador inserido com sucesso")
                                                navigate("/menuAdmin/" + params.id_candidatura)
                                            }
                                        },
                                        (error) => {
                                            console.error(error)
                                        }
                                    )
                            } else toastError("Já existe um coordenador com este email")
                        },
                        (error) => {
                            console.error("Error fetching data: ", error);
                        }
                    );
            } else {
                if (check_nif_number(searchAdm)) {
                    fetch(`${process.env.REACT_APP_API_URL}/coordenadores`,
                        {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json', 'authorization': localStorage.getItem('admin1') },
                            body: JSON.stringify({
                                nif: searchAdm,
                                tipo: tipoConta,
                                mailPersonalizado: mailPersonalizado
                            })
                        })
                        .then(res => res.json())
                        .then(
                            (result) => {
                                if (result === "") {
                                    toastError("Resultado não encontrado")
                                } else {
                                    toastSuccess("Coordenador inserido com sucesso")
                                    navigate("/menuAdmin/" + params.id_candidatura)
                                }
                            },
                            (error) => {
                                console.error(error)
                            }
                        )
                } else toastError("Número de nif inválido.")
            }
        } else toastError("O campo não aceita valores nulos.")
    }

    //Submete candidatura
    async function submeterCandidatura() {
        let form = document.getElementById("main-form")
        let formDataInfo = new FormData()

        // Secção - 1
        let tipo = true
        formDataInfo.append("tipo", tipo)
        formDataInfo.append('fullName', CapitalizarNomes(fullName))
        formDataInfo.append('cellNumber', cellNumber)
        formDataInfo.append('mailPersonalizado', mailPersonalizado)

        // Secção - 2
        formDataInfo.append('email', email)
        let salt = Math.random().toString(36).slice(2, 6);

        let name = removeAccents(fullName).split(" ")
        let firstWord = name[0];
        let lastWord = name[name.length - 1];
        name = firstWord + lastWord
        let password = name.replace(/\s+/g, '') + salt;

        formDataInfo.append('password', password)
        formDataInfo.append('role', modoCriacao)
        formDataInfo.append('nif', nif)

        // Submeter dados do candidato
        fetch(`${process.env.REACT_APP_API_URL}/coordenadores/interno`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'authorization': localStorage.getItem('admin1') },
                body: formDataToJson(formDataInfo)
            })
            .then(res => res.json())
            .then(
                (result) => {
                    toastSuccess("Coordenador inserido com sucesso")
                    navigate("/menuAdmin/" + params.id_candidatura)

                },
                (error) => {
                    console.error(error)
                }
            )
    }

    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin1 currentPage={"addCoordinator"} />
                </div>

                <Link to={`/menuadmin/` + params.id_candidatura} className="back-button-div-noNavbar">
                    <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                </Link>

                <div className="flex-grow mt-8">
                    <div className="mt-2 title">CRIAR COORDENADOR/A</div>
                    {tipoCoordenador ? (
                        <div>
                            {section !== -1 && section !== 0 && (
                                <div>
                                    {tipoCoordenador === "interno" && < MultiStepForm steps={stepsInterno} currentStep={currentStep} />}
                                </div>
                            )}
                        </div>
                    ) : ""}
                    <form className="mx-auto container-form" id="main-form" encType="multipart/form-data">
                        <section>
                            {section === -1 && (<div className="section">
                                <div className="campo">
                                    <label htmlFor="Modalidade" className="mb-1 label-input">Modo de criação:</label>
                                    <select onWheel={(e) => e.target.blur()} type="text" id="tipo" name="tipo" className="selector2" value={modoCriacao} onChange={(e) => { setModoCriacao(e.target.value) }} required>
                                        <option value={""} disabled> Selecionar o modo desejado</option>
                                        <option value="nova">Criar a partir do zero</option>
                                        <option value="existente">Criar a partir de uma conta já existente</option>
                                    </select>
                                </div>
                            </div>)}
                        </section>
                        <section>
                            {section === 0 && (<div>
                                <div>
                                    <label htmlFor="Modalidade" className="my-1 label-input">Criar coordenador a partir do:</label>

                                    <select onWheel={(e) => e.target.blur()} type="text" id="tipo" name="tipo" className="selector2" value={tipoConta} onChange={(e) => { setTipoConta(e.target.value) }} required>
                                        <option value={""} disabled> Selecionar a tipo desejado</option>
                                        <option value="administrador">Administrador</option>
                                        <option value="formador">Formador</option>
                                    </select>
                                </div>
                                <div className="flex flex-col mt-2">
                                    <label className="mb-1 label-input">NIF ou Email:</label>
                                    <input type="text" id="fullName" className="input-field2" name="fullName" onChange={(e) => { setSearchAdm(e.target.value) }} required />
                                </div>
                                <div>
                                    <label htmlFor="address" className="mb-1 label-input">Personalização do email de inscrição:</label>
                                    <textarea className="input-textarea" type="text" id="address" name="address" value={mailPersonalizado} onChange={(e) => { setMailPersonalizado(e.target.value) }} placeholder="Escreva uma mensagem personalizada e esta será enviada para o mail do coordenador." required></textarea>
                                </div>
                                <div className="mt-4">
                                    <button className="button-geral" onClick={addCoordinator} type="button" value="Adicionar">Submeter</button>
                                </div>
                            </div>)}
                        </section>
                        <section>
                            {section === 1 && (<div>
                                <div>
                                    <label className="label-input">Tipo de coordenador:</label>
                                    <select onWheel={(e) => e.target.blur()} className="selector2" type="text" id="tipo" name="tipo" value={tipoCoordenador} onChange={(e) => { setTipoCoordenador(e.target.value) }} required>
                                        <option value={""} disabled> Selecionar o tipo:</option>
                                        <option value="interno">Interno</option>
                                        <option value="externo">Externo</option>
                                    </select>
                                </div>
                                {tipoCoordenador === "externo" && <div>
                                    <div className="mt-2">
                                        <p className="text-text-main-color">Preencha a candidatura no link abaixo:</p>
                                        {tipoCoordenador === "externo" ? <Link className="link" to={"/addExternCoordinators"}>Link</Link> : ""}
                                    </div>
                                    <Link to={'/MenuSuperAdmin'}>
                                        <div className="mt-4">
                                            <input className="button-geral" type="submit" value={"Fechar"} />
                                        </div>
                                    </Link>
                                </div>}
                            </div>)}
                        </section>
                        <div>
                            <section>
                                {section === 2 && (<div>
                                    <div className="flex flex-col">
                                        <label className="label-input" htmlFor="fullName">Nome Completo:*</label>
                                        <input className="input-field2" type="text" id="fullName" name="fullName" value={fullName} onChange={(e) => { setFullName(e.target.value) }} />
                                    </div>
                                    <div className="flex flex-col mt-2">
                                        <label className="label-input" htmlFor="email">Email:*</label>
                                        <input className="input-field2" type="text" id="email" name="email" value={email} onChange={(e) => { setEmail(e.target.value) }} required />
                                    </div>
                                    <div className="flex flex-col mt-2">
                                        <label className="label-input" htmlFor="cellNumber">Nº de telemóvel:*</label>
                                        <input className="input-field2" type="number" id="cellNumber" name="cellNumber" minLength={9} maxLength={13} value={cellNumber} onChange={(e) => { setCellNumber(e.target.value) }} required></input>
                                    </div>
                                    <div className="flex flex-col mt-2">
                                        <label className="label-input" htmlFor="nif">Número de Contribuinte*</label>
                                        <input className="input-field2" type="text" id="nif" name="nif" minLength={9} maxLength={9} defaultValue={nif} onChange={(e) => { setNif(e.target.value) }} required></input>
                                    </div>
                                    <div className="mt-2">
                                        <label className="label-input" htmlFor="address">Personalização do email de inscrição:</label>
                                        <textarea className="input-textarea" type="text" id="address" name="address" value={mailPersonalizado} onChange={(e) => { setMailPersonalizado(e.target.value) }} placeholder="Escreva uma mensagem personalizada e esta será enviada para o mail do coordenador." ></textarea>
                                    </div>
                                    <div className="mt-4">
                                        <button className="button-geral" onClick={handleNext} type="button" >Submeter</button>
                                    </div>
                                </div>)}
                            </section>
                        </div>
                        {tipoCoordenador === "interno" && (
                            <Modal isOpen={modalIsOpen} className="fixed inset-0 flex items-center justify-center mx-4">
                                <div className="absolute w-full h-full" onClick={() => setModalIsOpen(false)}></div>
                                <div className="relative flex flex-col items-center px-10 shadow-2xl bg-secundary-color py-7 rounded-2xl">
                                    <div className="modal-header">
                                        <h1 className="title">Dados do Formulário</h1>
                                    </div>
                                    <div className="modal-content">
                                        <p className="p-1 text-text-second-color"><strong>Nome Completo:</strong> {fullName}</p>
                                        <p className="p-1 text-text-second-color"><strong>Número de Telemóvel:</strong> {cellNumber}</p>
                                        <p className="p-1 text-text-second-color"><strong>Email:</strong> {email}</p>
                                        <p className="p-1 text-text-second-color"><strong>Número Contribuinte:</strong> {nif}</p>
                                    </div>
                                    <div className="flex flex-row justify-center w-full mt-5">

                                        <button className="w-24 button-confirmar" onClick={(e) => submeterCandidatura()}>Confirmar</button>
                                    </div>
                                    <button className="absolute top-1.5 right-1.5" onClick={() => setModalIsOpen(false)}>
                                        <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                                    </button>
                                </div>
                            </Modal>
                        )}
                        <div className="mt-3">
                            <h3 className="m-1 text-main-color">Legenda</h3>
                            <p className="m-1 text-xs text-text-second-color">Todos os campos identificados com * são obrigatórios</p>
                            <p className="m-1 text-xs text-text-second-color">Os seus dados pessoais serão facultados às Entidades Reguladoras e/ou Financiadoras das ações de Formação.</p>
                        </div>
                        {section !== -1 && (<button onClick={handlePrevious} type="button" className="button-back-form"><img className="invert" src={Next} alt="Back" /></button>)}
                        {tipoCoordenador === "interno" ?
                            <div>
                                {(section !== 2 && section !== 0) && (<button onClick={handleNext} type="button" className="button-next-form"><img className="invert" src={Next} alt="Next" /></button>)}
                            </div>
                            : <div>
                                {(section !== 1 && section !== 0) && (<button onClick={handleNext} type="button" className="button-next-form"><img className="invert" src={Next} alt="Next" /></button>)}
                            </div>}
                    </form>
                </div>
                <div className="w-full">
                    <Footer />
                </div>
            </main>
        </>
    );
}


export default ShowCoordinators;