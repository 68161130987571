import React, { useState, useEffect } from "react";
import Header from "../../../../../../../components/Header";
import { useParams, Link, useNavigate } from 'react-router-dom'
import Footer from "../../../../../../../components/Footer";
import NavbarFormador from "../../../../../../../components/Navbars/NavbarFormador/navbarFormador";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";

function ShowUfcd() {
    const [data, setData] = useState(null);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [eventos, setEventos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [cont, setCont] = useState(0)
    const params = useParams();
    const navigate = useNavigate();
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Formador-coursesIndUfcdEventos-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Formadores-coursesIndUfcdEventos-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)

    const year = new Date().getFullYear()
    const month = new Date().getMonth()
    const day = new Date().getDate();

    const dateNow = new Date(year, month, day)

    useEffect(() => {
        Cookies.set("Formador-coursesIndUfcdEventos-Pag", paginaAtual);
        Cookies.set(`Formadores-coursesIndUfcdEventos-itm1`, itemsPag)
        getUfcd()
    }, [loading, cont, paginaAtual, nPaginas, itemsPag]);

    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }


    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }

    function getUfcd() {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/${params.id_ufcds}/trainer`, {
            headers: {
                'authorization': localStorage.getItem('user')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    paginacaoTabelas(result);
                    // Create a temporary object to store unique events using 'name' as the key
                    const uniqueEvents = {};
                    result.eventos.forEach(element => {
                        const eventName = element.title;
                        if (!uniqueEvents[eventName]) {
                            uniqueEvents[eventName] = element;
                        }
                        else {
                            uniqueEvents[eventName].end = element.end
                        }
                    });
                    // Convert the temporary object back to an array of unique events
                    const uniqueEventsArray = Object.values(uniqueEvents);
                    setEventos(uniqueEventsArray
                    );
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                }
            );
    }
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };

    function gerarFolhaPresencas(id, idEvento, tipo) {
        if (tipo === "Presencial") {
            setLoading(false)
            fetch(`${process.env.REACT_APP_API_URL}/percurso/folhaDePresencasPresencial/${id}/${idEvento}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem('user')
                },
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    } else {
                        throw new Error('Network response was not ok.');
                    }
                })
                .then((result) => {
                    // Handle the successful response
                    downloadFile(result.folhaPresencas_path)
                    setLoading(true)
                })
                .catch((error) => {
                    // Handle errors
                });
        }
        else {
            setLoading(false)
            fetch(`${process.env.REACT_APP_API_URL}/percurso/folhaDePresencas/${id}/${idEvento}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem('user')
                },
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    } else {
                        throw new Error('Network response was not ok.');
                    }
                })
                .then((result) => {

                    // Handle the successful response
                    downloadFile(result.folhaPresencas_path)
                    setLoading(true)
                })
                .catch((error) => {
                    // Handle errors
                    console.error('Error:', error);
                });
        }
    }


    //Download do ficheiro
    function downloadFile(url) {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank'); // Opens the link in a new tab/window
        link.setAttribute('download', ''); // Optional, sets the download attribute to trigger download in some browsers
        link.click();
    }

    function convertTimeString(dateTimeString, dateTimeString2) {
        const dateTime = new Date(dateTimeString);
        // Extract date and time parts
        const time = dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Format: HH:mm   

        const dateTime2 = new Date(dateTimeString2);
        const time2 = dateTime2.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Format: HH:mm         
        return time + " - " + time2;
    }
    function convertDateString(dateTimeString) {
        const dateTime = new Date(dateTimeString);
        // Extract date and time parts
        const date = dateTime.toLocaleDateString(); // Format: MM/DD/YYYY
        return date;
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";
    const checked = document.getElementById('check');
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }
    return (
        <>
            <main className="min-h-screen mx-auto md:grid sm:grid-cols-10">
                <div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    <NavbarFormador currentPage={"cursos"} />
                </div>

                <div className="back-button-noNavbar">
                    <Link to={"/menutrainers/" + params.id_candidatura + "/cursos/" + params.id_curso + "/Ufcds"} className="flex w-8 h-8">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>
                </div>

                <div className="h-full sm:col-span-8">
                    <Header sideMenu={"true"} />
                    {data ? <h1 className="title">{data.codeUfcd} - {data.name}</h1> : ""}
                    {data && data.length > 0 ? <>
                        <div className="flex justify-center items-center mb-1">
                            <label className="text-text-main-color">Dados por página: </label>
                            <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                value={itemsPag}
                                onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div className="table-containerSideMenu">
                            <table className="my-table">

                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="p-2 min-w-[80px] border border-white">Titulo</th>
                                        <th className="p-2 min-w-[80px] border border-white">Data</th>
                                        <th className="p-2 min-w-[80px] border border-white">Horário</th>
                                        <th className="p-2 min-w-[80px] border border-white">Tipo</th>
                                        <th className="p-2 min-w-[80px] border border-white">Plano de Sessão</th>
                                        <th className="p-2 min-w-[80px] border border-white">Folha de Presenças</th>
                                    </tr>
                                </thead>
                                {eventos.length > 0 ? (
                                    <tbody className="text-main-color">
                                        {dataToDisplay.map((item, index) => (
                                            <tr key={index}>
                                                <td className="border border-white">{item.title}</td>
                                                <td className="border border-white">{convertDateString(item.start)}</td>
                                                <td className="border border-white">{convertTimeString(item.start, item.end)}</td>
                                                <td className="border border-white">{item.tipo}</td>
                                                <td className="border border-white">
                                                    {item.sessao_path ? (
                                                        <div className="flex flex-col">
                                                            <button className="p-1 my-1 button-geral" onClick={() => downloadFile(item.sessao_path)}>Download</button>
                                                            <button className="p-1 button-geral" onClick={() => navigate("/menutrainers/" + params.id_candidatura + "/cursos/" + params.id_curso + "/Ufcds/" + params.id_ufcds + "/eventos/" + item._id)}>Editar</button>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {item.tipo === "Presencial" ? (
                                                                <button className="button-geral" onClick={() => navigate("/menutrainers/" + params.id_candidatura + "/cursos/" + params.id_curso + "/Ufcds/" + params.id_ufcds + "/eventos/" + item._id)}>Gerir</button>
                                                            ) : (
                                                                "Nda"
                                                            )}
                                                        </>
                                                    )}
                                                </td>
                                                <td className="border border-white">
                                                    {item.folhaPresencas_path ? (
                                                        <div className="flex flex-col flex-wrap justify-center">
                                                            <button className="p-1 my-1 button-geral" onClick={() => downloadFile(item.folhaPresencas_path)}>Download</button>
                                                            <button className="p-1 button-geral" onClick={() => gerarFolhaPresencas(data._id, item.title, item.tipo)}>Atualizar</button>
                                                        </div>
                                                    ) : (
                                                        <button className="p-1 button-geral" onClick={() => gerarFolhaPresencas(data._id, item.title, item.tipo)}>Gerar</button>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody />
                                )}
                            </table>
                        </div>
                        <div className="flex items-center justify-center mt-4 mb-8">
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                <AiOutlineDoubleLeft />
                            </button>
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                <AiOutlineLeft />
                            </button>
                            <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                <AiOutlineRight />
                            </button>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                <AiOutlineDoubleRight />
                            </button>
                        </div> </> : <p className="third-title">Não existem dados</p>}
                </div>
            </main>
            <Footer />
        </>


    );
}

export default ShowUfcd;
