import { Outlet, Navigate, useNavigate } from 'react-router-dom'
import isTokenExpired from '../expireToken/expireToken'
import React, { useEffect } from 'react';

const PrivateRoutesCoordinatorFormador = () => {
    const auth = localStorage.getItem("coord") || localStorage.getItem("user") || localStorage.getItem("admin1") || localStorage.getItem("admin2");
    const navigate = useNavigate()
    useEffect(() => {
        if (auth) {
            if (isTokenExpired(auth)) {
                localStorage.removeItem("coord");
                localStorage.removeItem("user");
                localStorage.removeItem("admin1");
                localStorage.removeItem("admin2");
                localStorage.removeItem("id");
                navigate("/")
            }
        }
        const tokenCheckInterval = setInterval(() => {
            if (auth) {
                const token = auth;
                const expired = isTokenExpired(token);
                if (expired) {
                    localStorage.removeItem("coord");
                    localStorage.removeItem("user");
                    localStorage.removeItem("admin1");
                    localStorage.removeItem("admin2");
                    localStorage.removeItem("id");
                    clearInterval(tokenCheckInterval);
                    navigate("/")
                }
            }
        }, 60000);

        return () => {
            clearInterval(tokenCheckInterval);
        };
    }, [auth]);

    if (!auth) {
        return <Navigate to="/" />;
    }

    return <Outlet />;
};

export default PrivateRoutesCoordinatorFormador;