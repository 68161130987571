export default function ArraySituacaoParticipante() {
    const SituacaoParticipanteArray = [
        "1 - Selecionado",
        "2 - Em Formação",
        "3 - Transitou de ano curricular",
        "4 - Retido no ano curricular",
        "5 - Desistente",
        "6 - Reprovado",
        "7 - Aprovado",
        "8 - Concluiu a formação",
        "10 - A não considerar"
    ];
    return SituacaoParticipanteArray;
}