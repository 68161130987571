import React, { useState, useEffect } from "react";
import { MenuFormando } from "./styled";
import { Link, useParams } from 'react-router-dom'
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ptLocale from '@fullcalendar/core/locales/pt';
import NavbarFormando from "../../../../components/Navbars/NavbarFormando/navbarFormando";


function ShowMenuFormando() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const idArray = []
    let array = []
    const [events, setEvents] = useState([]);

    useEffect(() => {
        getFormadoresByUfcd()
    }, [loading])

    function getEventos() {
        for (let i = 0; i < idArray.length; i++) {
            fetch(`${process.env.REACT_APP_API_URL}/calendar/ufcds/${idArray[i]}`, {
                headers: {
                    'authorization': localStorage.getItem('formando')
                }
            })
                .then((res) => res.json())
                .then(
                    (result) => {
                        array[i] = result
                        setEvents(prevState => [...prevState, ...array[i]])
                    },
                    (error) => {
                        console.error("Error fetching data: ", error);
                        setError(error);
                    }
                );
        };
    }

    function getFormadoresByUfcd() {
        fetch(`${process.env.REACT_APP_API_URL}/formandos/ufcds/${params.id_formando}`, {
            headers: {
                'authorization': localStorage.getItem('formando')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    if (result) {
                        result.forEach(ufcds => {
                            idArray.push(ufcds._id)
                        });
                        getEventos()
                    }

                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    }

    //Componente Calendar
    function Calendario() {
        return (
            <>
                <MenuFormando>
                    <div className="App">
                        <FullCalendar
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            locale={ptLocale}
                            initialView="dayGridMonth"
                            headerToolbar={{
                                center: 'dayGridMonth,timeGridWeek,timeGridDay'/* new */,
                                end: 'today prev,next',
                            }}
                            events={events}
                            nowIndicator
                            eventClick={function (event) {
                                event.jsEvent.preventDefault()
                                window.open(event.event._def.extendedProps.urlFormando, "_blank");
                            }}
                        />
                    </div>
                </MenuFormando>
            </>
        );
    }

    const params = useParams();
    useEffect(() => {

    }, [loading]);

    return (
        <>
            <Header />
            <MenuFormando>
                <main>
                    <div className="page">
                        <div className="navbar">
                            <NavbarFormando currentPage={"horario"} />
                        </div>
                        <div className="content">
                            <Calendario />
                        </div>
                    </div>
                </main>
            </MenuFormando>
            <Footer />
        </>
    );
}

export default ShowMenuFormando;
