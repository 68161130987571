import React, { useState } from "react";
import { Link, useParams } from "react-router-dom"
import Header from "../../../../../components/Header";
import Footer from "../../../../../components/Footer";
import { AreaCourseCreated } from "./styled";

//Página de curso criado com sucesso
const CourseCreated = () => {

    const params = useParams();
    return (
        <>
            <Header />
            <AreaCourseCreated>
                <div className="table">
                    <h1>O curso foi criado com sucesso!</h1>
                    <Link to={'/MenuSuperAdmin'}  className="botao">Retornar à página principal</Link>
                </div>
            </AreaCourseCreated>
            <Footer />
        </>
    )
}
export default CourseCreated