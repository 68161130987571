import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Footer from "../../../../components/Footer";
import NavbarAdmin2 from "../../../../components/Navbars/NavbarAdmin2/NavbarAdmin2";
import ConfirmToaster from "../../../../components/Toast/toastConfirmChoice";

export default function Evaluation() {
    const [valor1, setValor1] = useState("")
    const [valor2, setValor2] = useState("")
    const [valor3, setValor3] = useState("")
    const [valor4, setValor4] = useState("")
    const [valor5, setValor5] = useState("")
    const navigate = useNavigate();
    const params = useParams();
    let total = parseInt((((parseInt(valor1) - 1) * 5 + (parseInt(valor2) - 1) * 4 + (parseInt(valor3) - 1) * 3 + (parseInt(valor4) - 1) * 2 + (parseInt(valor5) - 1)) * 100) / 30) || 0;

    function handleClick() {
        navigate("/MenuSuperAdmin/cursos");
    }

    useEffect(() => {

    }, [total]);
    function avaliarCoordenador() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/evaluation/${params.id_percurso}`,
            {
                method: "PATCH",
                body: JSON.stringify({
                    evaluationTopics:{
                        criterio1: parseInt(valor1) - 1,
                        criterio2: parseInt(valor2) - 1,
                        criterio3: parseInt(valor3) - 1,
                        criterio4: parseInt(valor4) - 1,
                        criterio5: parseInt(valor5) - 1,
                    },
                    evaluation: total
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem('admin1')
                }
            }).then((result) => {
                result.json().then((resp) => {
                })
                handleClick();
            })
    }


    return (
        <>
            <main className="bg-white">
                <div className="w-full">
                    <NavbarAdmin2 currentPage={"gerirCursos"} />
                </div>
                <Link to={"/MenuSuperAdmin/cursos/"} className="back-button-div-noNavbar">
                    <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                </Link>

                <div className="mx-16 mt-8 max-w-7xl">
                    <div className="mx-auto mt-8">
                        <h2 className="title">REGULAMENTO DO SISTEMA DE AVALIAÇÃO DE COORDENADORES/MEDIADORES/AS</h2>
                        <div className="leading-7 text-justify text-text-main-color">
                            <p><span className="font-bold">1</span> - O presente regulamento clarifica o “sistema de avaliação de coordenadores/as e mediadores/as de formação”.</p>
                            <p><span className="font-bold">2</span> – Os princípios para aplicação deste “sistema de avaliação de coordenadores/as e mediadores/as” são os seguintes:</p>
                            <div className="ml-3">
                                <p><span className="font-bold">2.1</span> – Racionalidade – para que o processo de avaliação deixe de ser subjetivo e passe a ser claro para coordenadores/as e mediadores/as o que é valorizado no seu desempenho</p>
                                <p><span className="font-bold">2.2</span> - Transparência - possibilitando aos envolvidos compreender antecipadamente o que serão os critérios de avaliação e qual o juízo que a entidade formadora emite sobre o seu desempenho</p>
                                <p><span className="font-bold">2.3</span> – Previsibilidade – para que coordenadores/as e mediadores/as possam ter clara a previsibilidade de continuar a prestar serviços a esta entidade formadora</p>
                                <p><span className="font-bold">2.4</span> – Elevada qualidade – para que a entidade e todos os colaboradores progridam no sentido de fornecerem serviços com cada vez mais elevada qualidade</p>
                            </div>
                            <p><span className="font-bold">3</span> – O sistema é composto por 5 critérios, com importâncias diferenciadas e cujo impacto na classificação final é proporcional à sua importância, segundo a tabela que se segue:</p>
                        </div>
                    </div>

                    <div className="mt-8">
                        <table className="w-full mx-auto overflow-auto text-base bg-white rounded-lg ">
                            <thead className="bg-main-color">
                                <tr className="text-white h-14">
                                    <th rowspan={2}>CRITÉRIO</th>
                                    <th rowspan={2}>IMPORTÂNCIA</th>
                                    <th rowspan={2}>FATOR DE PONDERAÇÃO</th>
                                    <th colSpan={3}>
                                        <tr style={{ backgroundColor: '#15234e' }}>
                                            <td colSpan={3}>VALORES DOS PONTOS</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>1</td>
                                            <td>0</td>
                                        </tr>

                                    </th>

                                    <th rowspan={2}>VALOR PARA A CLASSIFICAÇÃO FINAL</th>
                                </tr>
                            </thead>
                            <tbody className="text-text-main-color">
                                <tr>
                                    <td>Critério 1 - Acompanhamento dos/as formadores/as do curso</td>
                                    <td>Muito elevada importância</td>
                                    <td>5</td>

                                    <td>10</td>
                                    <td>5</td>
                                    <td>0</td>

                                    <td>33,35</td>
                                </tr>
                                <tr >
                                    <td >Critério 2 - Acompanhamento pedagógico/psicossocial dos formandos/as do curso</td>
                                    <td>Elevada importância</td>
                                    <td>4</td>
                                    <td>8</td>
                                    <td>4</td>
                                    <td>0</td>
                                    <td>26,65</td>
                                </tr>
                                <tr >
                                    <td >Critério 3 - Cumprimentos dos prazos com o/a Gestor/a Projeto</td>
                                    <td>Bastante importância</td>
                                    <td>3</td>
                                    <td>6</td>
                                    <td>3</td>
                                    <td>0</td>
                                    <td>20</td>
                                </tr>
                                <tr >
                                    <td >Critério 4 - Satisfação dos formandos/as com o desempenho do coordenador/mediador/a</td>
                                    <td>Muito importante</td>
                                    <td>2</td>
                                    <td>4</td>
                                    <td>2</td>
                                    <td>0</td>
                                    <td>13,33</td>
                                </tr>
                                <tr >
                                    <td >Critério 5 - Interesse pela formação interna</td>
                                    <td>Importante</td>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>1</td>
                                    <td>0</td>
                                    <td>6,67</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="my-4 leading-7 text-justify text-text-main-color">
                        <p><span className="font-bold">4</span> – <span className="font-semibold">ESCALA</span>: O resultado final do desempenho de coordenadores/as e mediadores/as de formação é apresentado numa escala de 0-100.</p>
                        <p><span className="font-bold">5</span> – <span className="font-semibold">AVALIADOR</span>: A pessoa responsável pela avaliação de coordenadores/as e mediadores/as é o/a Gestor/a do Projeto responsável pela Operação em causa.</p>
                        <p><span className="font-bold">6</span> – <span className="font-semibold">SUPERVISOR</span>: O/a Diretor do Departamento de Formação é responsável por validar a avaliação efetuada pelo/a Gestor/a do Projeto, antes desta ser comunicada a coordenadores/as e mediadores/as.</p>
                        <p><span className="font-bold">7</span> – <span className="font-semibold">IMPACTO</span>: Como resultado da avaliação, coordenadores/as e mediadores/as serão sujeito a uma ordenação interna em que são ordenados/as em termos relativos a todos os outros coordenadores/as e mediadores/as ao serviço da entidade formadora. Nessa ordenação serão tidos em consideração os últimos 5 resultados na avaliação de coordenadores/as e mediadores/as, estabelecendo-se a média destes. Nota: caso coordenadores/as e mediadores/as não tenha menos de 5 resultados registados, serão considerados todos os desempenhos registados.</p>
                        <p><span className="font-bold">8</span> – A entidade formador permitirá o acesso ao posicionamento relativo dos/as coordenadores/as e mediadores/as da entidade formadora/afetos ao Sistema de Gestão baseado na IS0 9001 em vigor.</p>
                        <p><span className="font-bold">9</span> – <span className="font-semibold">RECURSO</span>: Caso coordenadores/as e mediadores/as considerem estar a ser sujeitos a erros de avaliação deve registar esse ponto de vista por meio de uma Ficha de Ocorrência, a qual será analisada segundo os procedimentos internos previstos.</p>
                    </div>

                    <div className="mt-8">
                        <table className="w-full mx-auto overflow-auto text-base bg-white rounded-lg ">
                            <thead className="bg-main-color">
                                <tr className="text-white h-14">
                                    <th>Critérios</th>
                                    <th>Pontos por grau de resultado</th>
                                    <th>Ponderação</th>
                                    <th className="w-40">Resultado</th>
                                </tr>
                            </thead>
                            <tbody className="text-text-main-color">
                                <tr>
                                    <td><h4>Critério 1 Acompanha-mento dos/as formadores/as do curso</h4></td>
                                    <td>
                                        <tr><td><p>
                                            2 PONTOS - O/a coordenador/mediador/a discute com o formador e valida (de acordo com o Modelo Pedagógico COM-TEC)
                                            o Cronograma e o Roteiro Pedagógico antes do início da ação. Também controla a qualidade dos materiais pedagógicos
                                            produzidos, antes e ao longo do curso (promovendo sempre a autonomia do formador e nunca o substituindo nas suas tarefas,
                                            nem mesmo na produção final da pauta final).
                                        </p></td></tr>
                                        <tr><td><p>
                                            1 PONTO - Embora o/a coordenador/mediador/a valide adequadamente os Roteiros Pedagógicos e os Cronogramas, há muitas
                                            tarefas do formador que é ele/a que realiza porque tem dificuldade em promover a autonomia destes.
                                        </p></td></tr>
                                        <tr><td><p>
                                            0 PONTOS - OU o/a coordenador/mediador/a não verifica adequadamente a qualidade dos materiais pedagógicos
                                            (inclusive Roteiro Pedagógico e Cronograma) OU não consegue influenciar o formador a ter tempos de dedicação adequados
                                            à duração do curso/UFCD OU não consegue influenciar o/a formador/a a ser adequado (em termos de qualidade quantidade) nos
                                            feedback dados no Fórum Geral do curso/UFCD.
                                        </p></td></tr>
                                    </td>
                                    <td><h1>5</h1></td>
                                    <td>
                                        <select onWheel={(e) => e.target.blur()} className="selector" id="valor1" name="valor1" onChange={(e) => { setValor1(e.target.value) }}>
                                            <option value="" disabled selected>Selecione a nota</option>
                                            <option value={1}>0</option>
                                            <option value={2}>1</option>
                                            <option value={3}>2</option>
                                        </select>
                                    </td>

                                </tr>
                                <tr>
                                    <td><h4>Critério 2 Acompanha-mento pedagógico/ psicossocial dos/as formandos/as do curso</h4></td>
                                    <td>
                                        <tr><td><p>
                                            2 PONTOS - O/a coordenador/mediador/a verifica regularmente o envolvimento efetivo dos/as formandos/as nas
                                            atividades de aprendizagem, visando o fornecimento de alertas aos/às formadores/as e intervindo diretamente
                                            junto dos/as formandos/as para a sua motivação e resolução de de situações particulares, tendo em vista o
                                            sucesso destes nas aprendizagens/aprovação final. Reporta sempre aos/às formadores/as as suas ações.
                                        </p></td></tr>
                                        <tr><td><p>
                                            1 PONTO - Embora o/a coordenador/mediador/a acompanhe efetivamente todos os acontecimentos significativos
                                            com formandos/as, não resolve situações particulares que podiam ter resoluções e contribuir para o sucesso
                                            das aprendizagens/aprovação final dos/as formandos/as.
                                        </p></td></tr>
                                        <tr><td><p>
                                            0 PONTOS - OU o/a coordenador/mediador/a não fornece feedback aos/às formadores/as sobre as suas intervenções
                                            com os/as formandos/as OU os/as formandos/as não têm resolvidas as suas situações particulares que ajudariam
                                            no processo de aprendizagens/aprovação no curso.
                                        </p></td></tr>
                                    </td>
                                    <td><h1>4</h1></td>
                                    <td>
                                        <select onWheel={(e) => e.target.blur()} className="selector" id="valor2" name="valor2" onChange={(e) => { setValor2(e.target.value) }}>
                                            <option value="" disabled selected>Selecione a nota</option>
                                            <option value={1}>0</option>
                                            <option value={2}>1</option>
                                            <option value={3}>2</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td><h4>Critério 3 Cumprimentos prazos com o Gestor de Projeto</h4></td>
                                    <td>
                                        <tr><td><p>
                                            2 PONTOS - O/a coordenador/mediador/a cumpre o prazo de entrega de mapas de controlo intermédio e de fecho de
                                            curso, e relatório de satisfação, de acordo com o procedimento 35 ou procedimento 36 ou segundo o pedido particular do/a Gestor/a do Projeto.
                                        </p></td></tr>
                                        <tr><td><p>
                                            1 PONTO - O/a coordenador/mediador/a não cumpre prazos por razões imputáveis aos/às formadores/as ou outros.
                                        </p></td></tr>
                                        <tr><td><p>
                                            0 PONTOS - OU o/a coordenador/mediador/a não cumpre prazos OU não consegue resolver no prazo de 8 dias o não
                                            cumprimento de prazos imputáveis aos/às formadores/as ou outros.
                                        </p></td></tr>
                                    </td>
                                    <td><h1>3</h1></td>
                                    <td>
                                        <select onWheel={(e) => e.target.blur()} className="selector" id="valor3" name="valor3" onChange={(e) => { setValor3(e.target.value) }}>
                                            <option value="" disabled selected>Selecione a nota</option>
                                            <option value={1}>0</option>
                                            <option value={2}>1</option>
                                            <option value={3}>2</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td><h4>Critério 4 Satisfação dos/as formandos/as com o desempenho do/a coordenador/mediador/a</h4></td>
                                    <td>
                                        <tr><td><p>
                                            2 PONTOS – Na “Ficha de Reação à Formação”, em todos os critérios relacionados com o “coordenador/mediador”, obtém uma taxa de notas “4” e “5” superior a 90%.
                                        </p></td></tr>
                                        <tr><td><p>
                                            1 PONTO - Na “Ficha de Reação à Formação”, em todos os critérios relacionados com o “coordenador/mediador”, obtém uma taxa de notas “4” e “5” superior a 80%.
                                        </p></td></tr>
                                        <tr><td><p>
                                            0 PONTOS - Na “Ficha de Reação à Formação”, em todos os critérios relacionados com o “coordenador/mediador”, NÃO obtém uma taxa de notas “4” e “5” superior a 80%.
                                        </p></td></tr>
                                    </td>
                                    <td><h1>2</h1></td>
                                    <td>
                                        <select onWheel={(e) => e.target.blur()} className="selector" id="valor4" name="valor4" onChange={(e) => { setValor4(e.target.value) }}>
                                            <option value="" disabled selected>Selecione a nota</option>
                                            <option value={1}>0</option>
                                            <option value={2}>1</option>
                                            <option value={3}>2</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td><h4>Critério 5 Interesse pela formação interna</h4></td>
                                    <td>
                                        <tr><td><p>
                                            2 PONTOS – O/a coordenador/mediador/a realizou a formação interna com muito interesse.
                                        </p></td></tr>
                                        <tr><td><p>
                                            1 PONTO - O/a coordenador/mediador/a realizou a formação interna com interesse.
                                        </p></td></tr>
                                        <tr><td><p>
                                            0 PONTOS - O/a coordenador/mediador/a realizou a formação interna com pouco interesse OU não frequentou qualquer formação interna no último ano.
                                        </p></td></tr>
                                    </td>
                                    <td><h1>1</h1></td>
                                    <td>
                                        <select onWheel={(e) => e.target.blur()} className="selector" id="valor5" name="valor5" onChange={(e) => { setValor5(e.target.value) }}>
                                            <option value="" disabled selected>Selecione a nota</option>
                                            <option value={1}>0</option>
                                            <option value={2}>1</option>
                                            <option value={3}>2</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table >
                    </div>
                    <div className="flex flex-col items-center my-8">
                        {total === null ? <h2></h2> : <h2 className="third-title">TOTAL FINAL = {total}%</h2>}
                        <ConfirmToaster css={"button-confirmar mt-2 hover:brightness-75 tooltip"} icon={"Confirmar"} title={"Avaliar"} message={"Deseja avaliar o coordenador? (Esta ação é irreversível)"} confirm={avaliarCoordenador} tooltip={"Avaliar Formador"}></ConfirmToaster>
                    </div>
                </div>
            </main >
            <Footer />
        </>
    )
}