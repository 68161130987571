import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../../../../components/Footer";
import NavbarAdmin2 from "../../../../../components/Navbars/NavbarAdmin2/NavbarAdmin2";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";

function Candidaturas() {
    const [data, setData] = useState(null);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [percurso, setPercurso] = useState("");
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("SuperAdmin-CoursesIndFormadoresPerc-Pag") : ""
    const savedSearchTerm = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("SuperAdmin-Formadores-1") : "";
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm ? savedSearchTerm : "");
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const params = useParams();
    const navigate = useNavigate()
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`SuperAdmin-CoursesIndFormadoresPerc-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)

    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }

    //filtra dados
    function filterData(data) {
        return data.filter((item) =>
            item.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.nif.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }

    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }

    useEffect(() => {
        Cookies.set("SuperAdmin-CoursesIndFormadoresPerc-Pag", paginaAtual);
        Cookies.set(`SuperAdmin-CoursesIndFormadoresPerc-itm1`, itemsPag);
        Cookies.set("SuperAdmin-Formadores-1", searchTerm);
        getFormadores()
        getPercurso()
    }, [loading, paginaAtual, nPaginas, itemsPag, searchTerm]);


    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);
        updatePagination(dataToDisplay);
    };

    function getFormadores() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}/formadores`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem('admin2')
            },
            method: 'GET',
        })
            .then(res => res.json())
            .then((result) => {
                setData(result);
                paginacaoTabelas(filterData(result));
                setLoading(false)
            })
    }

    //Obtem percurso
    function getPercurso() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem('admin2')
            },
            method: 'GET',
        })
            .then(res => res.json())
            .then((result) => {
                setPercurso(result.name)
                setLoading(false)
            })
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }
    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin2 currentPage={"gerirCursos"} />
                </div>

                <div className="flex-grow mt-8">
                    <Link to={'/MenuSuperAdmin/cursos/' + params.id_curso} className="back-button-div-noNavbar">
                        <img className='back-button-img' src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} alt="Back" />
                    </Link>

                    <h1 className="title">Turma - {percurso}</h1>
                    <div className="search-div">
                        <label className="search-input-label">Ver apenas:</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input type="text" className="search-input" placeholder="ex: Nome, Local ..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                        </div>
                    </div>
                    {data && data.length > 0 ? <>
                        <div className="flex justify-center items-center mb-1">
                            <label className="text-text-main-color">Dados por página: </label>
                            <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                value={itemsPag}
                                onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div className="table-container">
                            <table className="my-table">

                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="p-2 min-w-[150px] border border-white">Nome Completo</th>
                                        <th className="p-2 min-w-[150px] border border-white">Email</th>
                                        <th className="p-2 min-w-[150px] border border-white">NIF</th>
                                    </tr>
                                </thead>
                                {data ? <tbody className="text-text-main-color" >
                                    {dataToDisplay.map((item, index) => (
                                        <tr key={index} className="row-click" onClick={(e) => navigate("/MenuSuperAdmin/cursos/" + params.id_curso + "/formadores/" + item._id)}>
                                            <td className="border border-white candidate">{item.fullName}</td>
                                            <td className="border border-white candidate">{item.email}</td>
                                            <td className="border border-white candidate">{item.nif}</td>
                                        </tr>))}
                                </tbody> : <tbody></tbody>}
                            </table>
                        </div>
                        <div className="flex items-center justify-center mt-4 mb-8">
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                <AiOutlineDoubleLeft />
                            </button>
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                <AiOutlineLeft />
                            </button>
                            <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                <AiOutlineRight />
                            </button>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                <AiOutlineDoubleRight />
                            </button>
                        </div> </> : <p className="third-title">Não existem dados</p>}
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main>
        </>
    );
}

export default Candidaturas