import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../../../components/Footer";
import toastSuccess from "../../../../components/Toast/toastSuccess";
import 'react-toastify/dist/ReactToastify.css';
import NavbarAdmin2 from "../../../../components/Navbars/NavbarAdmin2/NavbarAdmin2";
import ConfirmToaster from "../../../../components/Toast/toastConfirmChoice";

import { IoMdRemoveCircle } from "react-icons/io";

function GerirMoodle() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");

    const navigate = useNavigate()

    useEffect(() => {
        getMoodle()
    }, [loading]);

    //filtra dados
    function filterData(data) {
        return data.filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }

    function getMoodle() {
        fetch(`${process.env.REACT_APP_API_URL}/moodle`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "authorization": localStorage.getItem('admin2')
            },
            method: 'GET',
        })
            .then(res => res.json())
            .then((result) => {
                setData(result)

            })
        setLoading(false)
    }


    function deleteMoodle(_id) {

        fetch(`${process.env.REACT_APP_API_URL}/moodle/${_id}`, {
            method: 'DELETE',
            headers: {
                "authorization": localStorage.getItem('admin2')
            }
        }).then((result) => {
            result.json().then((resp) => {
                toastSuccess('Entidade Financiadora removida com sucesso!')
                getMoodle()
            })
        })
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin2 currentPage={"moodle"} />
                </div>
                <div className="flex-grow mx-5 mt-20 sm:mt-8 sm:mx-0">
                    <Link to={'/MenuSuperAdmin'} className="back-button-div-noNavbar">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>
                    <h1 className="title">Moodles</h1>
                    <div className="search-div">
                        <label className="search-input-label">Pesquisar: </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input type="text" className="search-input" placeholder="ex: Nome" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                        </div>
                    </div>

                    {data && data.length > 0 ?
                        <div className="table-container">
                            <table className="my-table">
                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="p-2 min-w-[150px] border border-white">Nome</th>
                                        <th className=" min-w-[120px] border border-white">Ações</th>
                                    </tr>
                                </thead>
                                <tbody className="text-text-main-color">
                                    {filterData(data).map((item, index) => (
                                        <tr key={item._id}>
                                            <td onClick={() => navigate("/MenuSuperAdmin/Moodle/" + item._id)} className="px-6 row-click border border-white coordinators"> {item.name}</td>
                                            <td className="px-6 border border-white ufcd"><ConfirmToaster css={"button-geral hover:brightness-75 tooltip"} icon={<span className="justify-center flex text-base"><IoMdRemoveCircle size={24} /> </span>} title={"Eliminar"} message={"Tem a certeza que deseja eliminar os acessos a este moodle ? (Se os eliminar perderá o acesso a este.)"} confirm={deleteMoodle} id={item._id} tooltip={"Eliminar Acessos Moodle"}></ConfirmToaster></td>
                                        </tr>
                                    ))}</tbody>
                            </table>
                        </div> : ""}
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main>
        </>
    );
}

export default GerirMoodle