
import styled from "styled-components";

export const AreaAvaliação = styled.div`


align-items: center;
justify-content: center;
flex-direction: column;
font-family: "Lato";
display: flex;
margin-top: 14vh;

.table-container{   
    min-width: 600px;
    overflow-x: scroll;
    background-color: white;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 5px 5px 20px #00000022;   
}    



h1{
    text-align: center;
    
    
}

table{
    padding:20px;
    display: block;
    background-color: white;
    text-align: center;
    max-width: 80vw;
    overflow: auto;
    max-height: 590px;
}

table thead th {

    padding: 12px;
    background-color: rgb(27, 152, 255);
    color: white

}

table tr{
    margin:60px;
    height: 50px;
    background-color: rgb(222, 221, 221);
}
table tr:nth-child(odd)
{
    background-color: #00000011;
}
h4{
   padding:2em;
}
p{
    padding: 0em 1em;
    text-align: justify;
}

.selector-avaliação{
    cursor: pointer;
    font-size: 0.8em;
    background-color: #00000011;
    color: black;
    border: 0.5px solid;
    border-radius: 0.2em;
    padding: 0.5em;
    transition: all 0.2s ease;
    margin: 0.75em 10px;
    box-shadow: 10px 10px 15px #00000022;
    text-decoration: none;
    font-family:'Lato'
}

.button-confirmar{
    
    cursor: pointer;
    font-size: 1em;
    min-width: 100px;
    color: black;
    border: 0.5px solid;
    border-radius: 0.2em;
    padding: 0.5em;
    transition: all 0.2s ease;
    margin-bottom: 0.75em;
    box-shadow: 10px 10px 15px #00000022;
    text-decoration: none;
    font-family:'Lato'
}
.button-confirmar:hover {
    background-color: rgb(117, 118, 119);
}
`