import React, { useState, useEffect } from "react";
import Header from "../../../../../components/Header";
import { useParams, useNavigate } from 'react-router-dom'
import Footer from "../../../../../components/Footer";
import ExcelGenerator from "../../../../../components/Excel/cronogramaDisciplina/index";
import NavbarFormador from "../../../../../components/Navbars/NavbarFormador/navbarFormador";
import { FiDownload } from "react-icons/fi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";
import AlterarFormato from "../../../../../components/FormChecks/formatDates/foramatDates";
import toastError from "../../../../../components/Toast/toastError";

export default function UFCDsListed() {
    const [data, setData] = useState([]);
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const [entidadesFormadoras, setEntidadesFormadoras] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [media, setMedia] = useState(0);
    const savedSearchTerm = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Formadores-disciplinasTotal-1") : "";
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm ? savedSearchTerm : "");
    const savedFiltro = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Formadores-disciplinasTotal-2") : "";
    const [filtro, setFiltro] = useState(savedFiltro ? savedFiltro : "1");
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Formadores-disciplinas-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Formadores-disciplinasTotal-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)

    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }


    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0

        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }

    //filtra dados
    function filterData(data) {
        return data.filter((item) =>
            (item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (item.codeUfcd && item.codeUfcd.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (item.percurso && item.percurso.length > 0 && item.percurso[0].name.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    }

    const params = useParams();
    const navigate = useNavigate()

    document.data = data

    function downloadFile(url) {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');

        if ('download' in link) {
            link.setAttribute('download', '');
        }

        document.body.appendChild(link); // Append the link to the DOM

        // Use a timeout to trigger the click after a short delay
        setTimeout(() => {
            link.click();
            document.body.removeChild(link); // Remove the link from the DOM after the click
        }, 100);
    }
    useEffect(() => {
        Cookies.set("Formadores-disciplinas-Pag", paginaAtual);
        Cookies.set("Formadores-disciplinasTotal-1", searchTerm);
        Cookies.set("Formadores-disciplinasTotal-2", filtro);
        Cookies.set(`Formadores-disciplinasTotal-itm1`, itemsPag)
        getUfcds()
        // eslint-disable-next-line
    }, [paginaAtual, nPaginas, searchTerm, filtro, itemsPag]);

    function fetchUfcds(url) {
        fetch(url, {
            headers: {
                'authorization': localStorage.getItem('user')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    paginacaoTabelas(filterData(result));
                    if (filtro === "3") {
                        let dados = result
                        let mediac = 0
                        if (dados.length > 0) {
                            for (let i = 0; i < dados.length; i++) {
                                mediac = mediac + dados[i].evaluation
                            }
                            mediac = Math.round(mediac / dados.length);
                        }
                        setMedia(mediac)
                    }
                    const entidades = [];
                    result.forEach(element => {
                        if (element && element.percurso && element.percurso.length > 0) {
                            const entityId = element.percurso[0].entFormadora[0]._id

                            if (!entidades.some(item => item._id === entityId)) {
                                entidades.push(element.percurso[0].entFormadora[0]);
                            }
                        }
                    });
                    setEntidadesFormadoras(entidades)
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    }

    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };

    function getUfcds() {
        setLoading(true);
        if (filtro === "1") {
            fetchUfcds(`${process.env.REACT_APP_API_URL}/ufcds/trainer/${params.id_candidatura}`);
            setLoading(false);
        }
        if (filtro === "2") {
            fetchUfcds(`${process.env.REACT_APP_API_URL}/ufcds/trainer/sorted/${params.id_candidatura}`);
            setLoading(false);
        }
        if (filtro === "3") {
            fetchUfcds(`${process.env.REACT_APP_API_URL}/ufcds/trainer/${params.id_candidatura}/media`);
            setLoading(false);
        }
    }
    //Submete estado
    async function exportarDeclaracaoExperiencia() {


        const dateBeginUsed = document.getElementById("date-Begin").value;
        const dateEndUsed = document.getElementById("date-End").value;
        const entFormadoraId = document.getElementById("entidades-Formadoras").value;

        if (dateBeginUsed && dateEndUsed && entFormadoraId) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/formadores/declaracaoExperiencia/${params.id_candidatura}`, {
                    method: "PATCH",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': localStorage.getItem("user")
                    },
                    body: JSON.stringify({
                        dateBeginUsed,
                        dateEndUsed,
                        entFormadoraId,
                    })
                });

                if (response.ok) {
                    const responseData = await response.json();
                    setLoading(true);
                    downloadFile(responseData);
                } else {
                    // Handle error response
                    console.error('Failed to fetch data:', response.statusText);
                    toastError('Failed to fetch data');
                }
            } catch (error) {
                // Handle fetch error
                console.error('Fetch error:', error);
                toastError('An error occurred while fetching data');
            } finally {
                setLoading(false);
            }
        } else {
            toastError("Insira todos os dados");
            setLoading(false);
        }
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }
    return (
        <>
            <main className="min-h-screen">
                <div className="flex-grow w-screen grid-cols-7 mx-0 md:grid xl:grid-cols-10 sm:w-full">
                    <div className='h-full md:col-span-2 md:bg-secundary-color'>
                        <NavbarFormador currentPage={"ufcdsHist"} />
                    </div>
                    <div className="w-full col-span-5 xl:col-span-8">
                        <Header sideMenu={"true"} />
                        <h1 className="title">Lista de disciplinas</h1>
                        {data && data.length > 0 ? <> <div className="search-div">
                            <label className="search-input-label"> Pesquisar: </label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input type="search" placeholder="ex: Nome, Código..." value={searchTerm} onChange={(e) => { setSearchTerm(e.target.value); setPaginaAtual(1); }} className="search-input" />
                            </div>
                        </div>
                            <div className="mt-4 mb-2">
                                <label className="text-text-second-color">UFCDS: </label>
                                <select onWheel={(e) => e.target.blur()} className="mr-3 search-selector" name="sel" id="sel" defaultValue={filtro} onChange={async (e) => { await setFiltro(e.target.value); await getUfcds(e.target.value) }}>
                                    <option value="1">Todos</option>
                                    <option value="2">Mais Recentes</option>
                                    <option value="3">Em Atividade</option>
                                </select>
                            </div>

                            <div className="flex justify-center items-center mb-1">
                                <label className="text-text-main-color">Dados por página: </label>
                                <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                    value={itemsPag}
                                    onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div className="mx-auto table-containerSideMenu">
                                <table className="m-4 my-table">

                                    <thead>
                                        <tr className="text-white h-14">
                                            <th className="p-2 min-w-[80px] border border-white">Código da disciplina</th>
                                            <th className="p-2 min-w-[150px] border border-white">Nome</th>
                                            <th className="p-2 min-w-[80px] border border-white">Código do Curso</th>
                                            <th className="p-2 min-w-[80px] border border-white">Carga Hórario</th>
                                            <th className="p-2 min-w-[80px] border border-white">Data inicio</th>
                                            <th className="p-2 min-w-[80px] border border-white">Data final</th>
                                            <th className="p-2 min-w-[80px] border border-white">Estado</th>
                                            <th className="p-2 min-w-[80px] border border-white">Avaliação</th>
                                            <th className="p-2 min-w-[150px] border border-white">Gerir Eventos</th>
                                            <th className="p-2 min-w-[80px] border border-white">Exportar Cronograma</th>
                                        </tr>
                                    </thead>
                                    {data.length > 0 ? <tbody className="text-text-main-color">
                                        {filterData(dataToDisplay).map((item, index) => (
                                            <tr key={index}>
                                                <td className="border border-white">{item.codeUfcd}</td>
                                                <td className="border border-white">{item.name}</td>
                                                <td className="border border-white"> {item.percurso[0] ? <>{item.percurso[0].name}</> : "Sem curso"}</td>
                                                <td className="border border-white">{item.cargaHoraria}</td>
                                                <td className="border border-white" type="date">{AlterarFormato(item.dateBegin)}</td>
                                                <td className="border border-white" type="date">{AlterarFormato(item.dateEnd)}</td>
                                                <td className="border border-white">
                                                    {item.fechoCoordenador ? "Finalizada" : <>
                                                        {item.fechoFormador === true ? "Em avaliação" : "Em Atividade"}</>
                                                    }
                                                </td>
                                                {item.evaluation.length>0? <td className="border border-white">{item.evaluation[0].evaluationFinal}%</td> : <td className="border border-white w-36">Em Atividade</td>}

                                                <td className="border border-white">
                                                    <button className="w-32 button-geral" onClick={() => navigate("/menutrainers/" + params.id_candidatura + "/UFCDsListed/historico/modulos/" + item._id)}>Marcar Eventos</button>
                                                </td>
                                                <td className="border border-white">
                                                    <button className="button-geral" onClick={() => ExcelGenerator(item)}>
                                                        <FiDownload />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody> : <tbody />}
                                </table>
                                {document.getElementById("sel") && document.getElementById("sel").value === 1 && media > 0 ? <div>Média: {media}%</div> : ""}
                            </div>
                            <div className="flex items-center justify-center mt-4 mb-8">
                                <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                    <AiOutlineDoubleLeft />
                                </button>
                                <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                    <AiOutlineLeft />
                                </button>
                                <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                                <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                    <AiOutlineRight />
                                </button>
                                <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                    <AiOutlineDoubleRight />
                                </button>
                            </div> </> : <p className="third-title">Não existem dados</p>}
                        <div className="mt-4 ">
                            <div className="subtitle mb-4 uppercase">
                                Exportar Declaração de Experiência Formativa
                            </div>
                            <div className="w-128 m-auto">
                                <div className="flex flex-row justify-between items-center">
                                    <label htmlFor="date-Begin" className="label-input mx-2">Data de Ínicio:</label>
                                    <input className="w-52 input-field2" type="date" id="date-Begin" />
                                </div>
                                <div className="flex flex-row justify-between items-center my-2">
                                    <label htmlFor="date-End" className="label-input mx-2">Data de Fim:</label>
                                    <input className="w-52 input-field2" type="date" id="date-End" />
                                </div>
                                <div className="flex flex-row justify-between items-center">
                                    <label htmlFor="entidades-Formadoras" className="label-input mx-2">Entidade Formadora:</label>
                                    <select onWheel={(e) => e.target.blur()} defaultValue="" className="selector w-52" name="entidades-Formadoras" id="entidades-Formadoras">
                                        <option value="" disabled>Selecione uma Entidade Formadora</option>
                                        {entidadesFormadoras.map((item, index) => (
                                            <option key={index} value={item._id}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <button className="w-40 mt-4 mb-6 button-geral" onClick={exportarDeclaracaoExperiencia}>Exportar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
}