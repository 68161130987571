import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../../../../../components/Footer";
import 'react-toastify/dist/ReactToastify.css';
import NavbarAdmin2 from "../../../../../components/Navbars/NavbarAdmin2/NavbarAdmin2";
import toastSuccess from "../../../../../components/Toast/toastSuccess";

function MoodleIndividual() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [alterar, setAlterar] = useState(false);
    const [alterarPass, setAlterarPass] = useState(false);
    const [user, setUser] = useState(null);
    const [database, setDatabase] = useState(null);
    const [host, setHost] = useState(null);
    const [pass, setPass] = useState(null);
    const [nameGenerico, setNameGenerico] = useState(null);
    const [name, setName] = useState(null);
    const params = useParams();

    useEffect(() => {
        getMoodle()
    }, [loading, alterar, user, database, host, nameGenerico, alterarPass]);
    function alterarChange() {
        setAlterar(!alterar)
    }
    function alterarPassChange() {
        setAlterarPass(!alterarPass)
    }

    function getMoodle() {
        fetch(`${process.env.REACT_APP_API_URL}/moodle/` + params.id, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "authorization": localStorage.getItem('admin2')
            },
            method: 'GET',
        })
            .then(res => res.json())
            .then((result) => {
                setData(result)
                setLoading(false)
            })

    }

    function confirmarAlteracoesMoodle() {
        fetch(`${process.env.REACT_APP_API_URL}/moodle/` + params.id, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "authorization": localStorage.getItem('admin2')
            },
            method: 'PATCH',
            body: JSON.stringify({
                name: name ? name : data.name,
                host: host ? host : data.host,
                user: user ? user : data.user,
                database: database ? database : data.database,
                nameGenerico: nameGenerico ? nameGenerico : data.nameGenerico,
                password: pass ? pass : data.password,
            })
        })
            .then(res => res.json())
            .then((result) => {
                toastSuccess("Alterado com Sucesso")
                setAlterar(false)
                setAlterarPass(false)
            })

    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin2 currentPage={"moodle"} />
                </div>
                <Link to={"/MenuSuperAdmin/moodle"} className="back-button-div-noNavbar">
                    <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                </Link>
                <div className="flex-grow mx-5 mt-10 sm:mt-8 sm:mx-0">
                    <div>
                        <h1 className="title">Credenciais Moodle - {data.name}</h1>
                        <div className="grid mt-4 sm:grid-cols-2">
                            <div>

                                <div className="text-center sm:text-left">
                                    <div className="text-xs uppercase text-text-second-color opacity-90">Nome:</div>
                                    <div className="mt-1 text-lg text-text-main-color">
                                        {alterar === true ?
                                            <input className="input-field" defaultValue={data.name} onChange={(e) => setName(e.target.value)} /> :
                                            data.name
                                        }
                                    </div>
                                </div>
                                <div className="mt-4 text-center sm:text-left">
                                    <div className="text-xs uppercase text-text-second-color opacity-90">User:</div>
                                    <div className="mt-1 text-lg text-text-main-color">
                                        {alterar === true ?
                                            <input className="input-field" defaultValue={data.user} onChange={(e) => setUser(e.target.value)} /> :
                                            data.user
                                        }
                                    </div>
                                </div>
                                <div className="mt-4 text-center sm:text-left">
                                    <div className="text-xs uppercase text-text-second-color opacity-90">Database:</div>
                                    <div className="mt-1 text-lg text-text-main-color">
                                        {alterar === true ?
                                            <input className="input-field" defaultValue={data.database} onChange={(e) => setDatabase(e.target.value)} /> :
                                            data.database
                                        }
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="text-center sm:text-left">
                                    <div className="text-xs uppercase text-text-second-color opacity-90">Host:</div>
                                    <div className="mt-1 text-lg text-text-main-color">
                                        {alterar === true ?
                                            <input className="input-field" defaultValue={data.host} onChange={(e) => setHost(e.target.value)} /> :
                                            data.host
                                        }
                                    </div>
                                </div>
                                <div className="mt-4 text-center sm:text-left">
                                    <div className="text-xs uppercase text-text-second-color opacity-90">Nome Genérico:</div>
                                    <div className="mt-1 text-lg text-text-main-color">
                                        {alterar === true ?
                                            <input className="input-field" defaultValue={data.nameGenerico} onChange={(e) => setNameGenerico(e.target.value)} /> :
                                            data.nameGenerico
                                        }
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
       
                        {alterar === false ?
                            <div className="flex items-center justify-center mt-4 max-h-10" >
                                <button className="w-24 mr-1 button-geral" onClick={alterarChange}>Alterar</button>
                                <button className="w-24 button-cancelar">Remover</button>
                            </div> :
                            <div className="flex items-center justify-center mt-4 max-h-10" >
                                       <button className="w-24 mr-1 button-cancelar" onClick={alterarChange}>Cancelar</button>
                                <button className="w-24 button-confirmar" onClick={() => confirmarAlteracoesMoodle()}>Confirmar</button>
                         
                            </div>
                        }
                        <div className="flex items-center justify-center mt-4">
                            {alterarPass === false ?
                                <div>
                                    <button className="button-geral w-26" onClick={alterarPassChange}>Alterar Palavra-passe</button>
                                </div> :
                                <div className="text-center sm:text-left">
                                    <div className="text-xs uppercase text-text-second-color opacity-90">Nova Palavra-passe:</div>
                                    <input type="password" className="mt-1 input-field" onChange={(e) => setPass(e.target.value)} />
                                    <div className="flex mt-2">
                                    <button className="w-24 mr-1 button-cancelar" onClick={() => setAlterarPass(false)}>Cancelar</button>
                                        <button className="w-24 button-confirmar" onClick={() => confirmarAlteracoesMoodle()}>Confirmar</button>
                                      
                                    </div>
                                </div>
                            }
                        </div>
              


                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main >
        </>
    );
}

export default MoodleIndividual