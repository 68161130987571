import React, { useState, useEffect } from "react";
import { Avaliacao } from "./styled";
import { Link, useParams } from 'react-router-dom'
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import NavbarFormando from "../../../../components/Navbars/NavbarFormando/navbarFormando";

function ShowUfcd() {
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");

    const params = useParams();

    //filtra dados
    function filterData(data) {
        return data.filter((item) =>
            item.codeUfcd.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.presencas.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.evaluation.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
    }

    function getPresencas() {
        fetch(`${process.env.REACT_APP_API_URL}/avaliacao/${params.id_formando}/ufcds`, {
            headers: {
                'authorization': localStorage.getItem('formando')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result)
                    setLoading(false)
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    }


    useEffect(() => {
        getPresencas()
    }, [loading]);

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    return (
        <>
            <Header />
            <Avaliacao>
                <main>
                    <h1>Disciplinas</h1>
                    <div className="search">
                        <label className="pesquisar"> Pesquisar: </label>
                        <img className='lupa' alt="Lupa" src={process.env.PUBLIC_URL + "/img/search.svg"} />
                        <input type="text" className="search-input" placeholder="ex: Nome, Código..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Código da UFCD</th>
                                <th>Nome da UFCD</th>
                                <th>Assiduidade</th>
                                <th>Presenças</th>
                                <th>Avaliação</th>
                                <th>Pra</th>
                            </tr>
                        </thead>
                        {data ? <tbody>
                            {data.ufcds.map((item, index) => (
                                <tr key={index}>
                                    <td className="ufcd">{item.codeUfcd}</td>
                                    <td className="ufcd">{item.name}</td>
                                    <td className="ufcd">Exemplo3</td>
                                    {item.presencas != 0 ? <td className="ufcd">{(item.presencas / item.eventos.length) * 100}%</td> : <td>0%</td>}
                                    <td className="ufcd">Exemplo3</td>
                                    <td className="ufcd">Exemplo3</td>
                                </tr>
                            ))}
                        </tbody> : <tbody />}
                    </table>
                    <Link to={'/menuformando/' + params.id_formando} className="back-button-link">
                        <img className='back-button' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>
                </main>
            </Avaliacao>
            <Footer />
        </>
    );
}

export default ShowUfcd;
