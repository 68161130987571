import React from "react";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

const Page404 = () => {
    
    const navigate = useNavigate();

    return (
        <>
            <main className="flex h-screen">
                <button onClick={() => navigate(-1)} className="back-button-div-noNavbar">
                    <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                </button>
                <div className="flex flex-col items-center justify-center h-full">
                    <img src={process.env.PUBLIC_URL + "/img/404.png"} alt="404" className="h-32 sm:h-64 " />
                    <p className="text-lg sm:text-2xl text-text-main-color"> A página à qual está a tentar aceder não existe.</p>
                </div>
            </main >
        </>
    );
}

export default Page404