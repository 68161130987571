import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { AreaHeader } from './styled'
import { AreaHeaderSide } from "./styledSideMenu";


//Componente header
function Header(sideMenu) {
    const navigate = useNavigate();
    if (Object.keys(sideMenu).length === 0) {
        return (
            <AreaHeader>
                <div className="mx-auto my-3">
                    <img className="cursor-pointer logo" src={process.env.PUBLIC_URL + "/img/logotipo/Logo.svg"} alt="logo" onClick={() => navigate("/")} />
                </div>
            </AreaHeader>
        )
    } else {
        return (
            <AreaHeaderSide>
                <div className="mx-auto my-3">
                    <img className="cursor-pointer logo" src={process.env.PUBLIC_URL + "/img/logotipo/Logo.svg"} alt="logo" onClick={() => navigate("/")} />
                </div>
            </AreaHeaderSide>
        )
    }

}

export default Header