import React, { useState } from 'react';
import Header from '../../../../components/Header';
import { useParams, useNavigate, Link } from 'react-router-dom';
import toastSuccess from '../../../../components/Toast/toastSuccess';
import 'react-toastify/dist/ReactToastify.css';
import toastError from '../../../../components/Toast/toastError';
import Footer from '../../../../components/Footer';
import NavbarCoordenador from '../../../../components/Navbars/NavbarCoordenador/navbarCoordenador';

function ResetPassword() {
    //Password
    const [antigaPassword, setAntigaPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    //Variáveis para mostrar password 
    const [showPassword1, setShowPassword1] = useState(false)
    const [showPassword2, setShowPassword2] = useState(false)
    const [showPassword3, setShowPassword3] = useState(false)

    //Funcionalidades
    const params = useParams();
    const navigate = useNavigate();

    //Função para mostrar password 
    //Mostrar pass
    const toggleShowPassword = (field) => {
        if (field === 1) {
            setShowPassword1(!showPassword1);
        } else if (field === 2) {
            setShowPassword2(!showPassword2);
        } else if (field === 3) {
            setShowPassword3(!showPassword3);
        }
    };

    //Submete a nova password na DB
    //Função de submit
    async function handleSubmit(event) {
        event.preventDefault();

        if (password !== confirmPassword) {
            toastError('As Palavras-passe não coincidem!');
            return;
        }
        if (password.length >= 8 && password.length <= 20) {
            // Verificar se tem Uppercase
            function containsUppercase(str) {
                return Boolean(str.match(/[A-Z]/));
            }
            // Verificar se tem Special caracters
            function containsSpecial(str) {
                return Boolean(str.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/));
            }
            var upperPass = containsUppercase(password)
            var specialPass = containsSpecial(password)
            if (specialPass === true) {
                if (upperPass === true) {
                    fetch(`${process.env.REACT_APP_API_URL}/coordenadores/changepassword/${params.id_candidatura}`,
                        {
                            method: "PATCH",
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'authorization': localStorage.getItem("coord")
                            },
                            body: JSON.stringify({ password: password, antigaPassword: antigaPassword })
                        }).then((result) => {
                            if (result.status === 200) {
                                toastSuccess('Palavra-passe atualizada com sucesso!')
                                navigate(`/menucoordenador/Profile/` + params.id_candidatura);
                            }
                            else {
                                toastError('Palavra-passe antiga incorreta.')
                            }
                        })
                } else toastError("A Palavra-passe necessita de uma letra maiúscula")
            } else toastError("A Palavra-passe necessita de um carácter especial")
        } else toastError(`Não cumpre os requisitos [8-20] digitos`)
    };

    return (
        <>
            <main className="min-h-screen grid-cols-7 mx-auto md:grid xl:grid-cols-10">
                <div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    <NavbarCoordenador currentPage={"perfil"} />
                </div>
                <div className="relative w-full h-full col-span-5 xl:col-span-8">
                    <Header sideMenu={"true"} />
                    <div className="mx-4 back-button-noNavbar">
                        <Link to={`/menucoordenador/Profile/` + params.id_candidatura} className="back-button-link">
                            <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                        </Link>
                    </div>
                    <h1 className='title'>Redefinir Palavra-passe</h1>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="antigaPassword" className='text-text-main-color'>Palavra-passe Antiga</label>
                            <div className='flex items-center justify-center'>
                                <span className='mr-2'>
                                    <i className="fas fa-key text-main-color"></i>
                                </span>
                                <div className='relative'>
                                    <input type={showPassword1 ? 'text' : 'password'} id="password" name="password" className='text-lg input-field' value={antigaPassword} onChange={(e) => setAntigaPassword(e.target.value)} placeholder="Palavra-passe" required />
                                    <button type="button" onClick={() => toggleShowPassword(1)} className="absolute top-1 right-1">
                                        <i className={showPassword1 ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='mt-3'>
                            <label htmlFor="password" className='text-text-main-color'>Nova Palavra-passe</label>
                            <div className='flex items-center justify-center'>
                                <span className='mr-2'>
                                    <i className="fas fa-key text-main-color"></i>
                                </span>
                                <div className='relative'>
                                    <input type={showPassword2 ? 'text' : 'password'} id="password2" name="password2" className='text-lg input-field' value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Palavra-passe" required />
                                    <button type="button" onClick={() => toggleShowPassword(2)} className="absolute top-1 right-1">
                                        <i className={showPassword2 ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='mt-3'>
                            <label htmlFor="confirmPassword" className='text-text-main-color'>Confirmar nova Palavra-passe</label>
                            <div className='flex items-center justify-center'>
                                <span className='mr-2'>
                                    <i className="fas fa-key text-main-color"></i>
                                </span>
                                <div className='relative'>
                                    <input type={showPassword3 ? 'text' : 'password'} id="password3" name="password3" className='text-lg input-field' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Palavra-passe" required />
                                    <button type="button" onClick={() => toggleShowPassword(3)} className="absolute top-1 right-1">
                                        <i className={showPassword3 ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <button className='button-geral' type="submit">
                                Confirmar
                            </button>
                        </div>
                    </form>
                </div>
            </main>
            <Footer />
        </>
    );
}

export default ResetPassword;
