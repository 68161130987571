import React, { useState } from 'react';
import { useParams } from 'react-router-dom'
import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import { ToastContainer } from "react-toastify";
import { MdOutlineVideoCall } from "react-icons/md";
import NavbarFormador from "../../../components/Navbars/NavbarFormador/navbarFormador";
import { useEffect } from 'react';

const MenuTrainers = () => {
    const [data, setData] = useState(null);
    const [incomingEvents, setIncomingEvents] = useState([]);
    const [name, setName] = useState("");

    document.data = data

    //Recebe um formador pelo seu id
    function getFormadores() {
        fetch(`${process.env.REACT_APP_API_URL}/formadores/${params.id_candidatura}`, {
            headers: {
                'authorization': localStorage.getItem('user')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    nomeApelido(result.fullName)
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                }
            );

    };

    //Recebe todos os eventos
    function getEventos() {
        try {
            fetch(`${process.env.REACT_APP_API_URL}/calendar/formador/${params.id_candidatura}`, {
                headers: {
                    'authorization': localStorage.getItem('user')
                }
            })
                .then((res) => res.json())
                .then(
                    (result) => {
                        getUpcomingEvents(result);
                    },
                    (error) => {
                        console.error("Error fetching data: ", error);
                    }
                );
        } catch (error) {
            console.error('Error fetching data: ', error);
        }
    }

    async function getUpcomingEvents(events) {
        const today = new Date();
        const futureEvents = events.filter(event => new Date(event.start) > today);
        futureEvents.sort((a, b) => new Date(a.start) - new Date(b.start));
        setIncomingEvents(futureEvents.slice(0, 3));
    }

    function nomeApelido(nomeCompleto) {
        if (nomeCompleto) {
            var names = nomeCompleto.split(" ");
            var firstName = names[0];
            var lastName = names.pop();
            setName(firstName + " " + lastName)
        }
    }

    const params = useParams();

    useEffect(() => {
        getFormadores()
        getEventos()
    }, []);

    //Apenas é acessivel se o login é TRUE, no entato o login não está diferenciado por ID
    return (
        <>
            <ToastContainer />
            <main className="min-h-screen grid-cols-7 mx-auto md:grid xl:grid-cols-10">

                <div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    <NavbarFormador currentPage={"home"} />
                </div>

                <div className="h-full col-span-5 xl:col-span-8">
                    <Header sideMenu={"true"} />
                    <div className='mx-10 mt-8'>
                        <div className="items-center sm:flex lg:ml-24 ">
                            <div>
                                {data && <img alt="profile" className="object-contain mx-auto max-h-40 rounded-3xl sm:m-0" src={data.photo === undefined || data.photo === "" ? 'https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg' : data.photo} />}
                            </div>
                            <div className='mx-auto sm:ml-16'>
                                <h2 className="text-2xl font-bold text-text-main-color" >Olá, {name}!</h2>
                                <div className="justify-around mt-2 font-medium sm:flex text-text-second-color">
                                    <div>
                                        <p>Disciplinas</p>
                                        {data && data.ufcds.length}
                                    </div>
                                    <div>
                                        <p>Percursos</p>
                                        {data && data.percurso.length}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className='my-6' />
                        <div className="mb-5 lg:ml-24">
                            <div className='ml-2'>
                                <h2 className='text-lg font-bold text-center sm:text-left text-text-main-color'>Acesso rápido</h2>
                                <h3 className="mb-2 text-center sm:text-left text-text-second-color">Videoconferências</h3>
                            </div>

                            {incomingEvents && <div className="grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 ">
                                {incomingEvents.map((event) => {
                                    let horas = event.start.substring(11, 16);
                                    let dia = event.start.substring(0, 10);
                                    return (
                                        <div key={event._id} onClick={() => window.open(`/#/Presencas/${event.ufcds[0]}/${event._id}`, '_blank')} className="flex flex-col items-center justify-center w-full p-2 py-5 mx-auto transition shadow-lg bg-secundary-color rounded-3xl duration-250 hover:cursor-pointer hover:scale-105" >
                                            <div>
                                                <h4 className='font-semibold text-text-main-color'>{event.title}</h4>
                                            </div>
                                            <div>
                                                <p className='text-text-third-color'>{dia}</p>
                                            </div>
                                            <div>
                                                <MdOutlineVideoCall size={40} className='text-main-color' />
                                            </div>
                                            <div>
                                                <p className='text-text-third-color'>{horas}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>}
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    )
}

export default MenuTrainers