import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-modal'
import Footer from "../../../components/Footer";
import toastSuccess from "../../../components/Toast/toastSuccess";
import NavbarAdmin2 from "../../../components/Navbars/NavbarAdmin2/NavbarAdmin2";
import { IoMdRemoveCircle } from "react-icons/io";
import ConfirmToaster from "../../../components/Toast/toastConfirmChoice";
import { AiOutlineCloseCircle, AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ExcelFormadores from "../../../components/Excel/excelBolsaFormadores/excelBolsaFormadores";
import toastError from "../../../components/Toast/toastError";
import Cookies from "js-cookie";
import AlterarFormato from "../../../components/FormChecks/formatDates/foramatDates";

function ShowFormadores() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [source, setSource] = useState("down");
    const [loading, setLoading] = useState(true);
    const [cont, setCont] = useState(0);
    const [error, setError] = useState(null);
    const savedSearchTerm = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("SuperAdmin-ListTrainers-1") : "";
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm ? savedSearchTerm : "")
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("SuperAdmin-ListTrainers-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalData, setModalData] = useState([]); // Recebe os dados para apresentar no modal
    const [checkedTrainers, setCheckedTrainers] = useState([]);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`SuperAdmin-ListTrainers-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)


    const year = new Date().getFullYear()
    const month = new Date().getMonth()
    const day = new Date().getDate();

    const dateNow = new Date(year, month, day)
    function addOneYear(date) {
        date.setFullYear(date.getFullYear() + 1);
        return date;
    }
    useEffect(() => {
        Cookies.set("SuperAdmin-ListTrainers-Pag", paginaAtual);
        Cookies.set("SuperAdmin-ListTrainers-1", searchTerm);
        Cookies.set(`SuperAdmin-ListTrainers-itm1`, itemsPag)
        getFormadores() // eslint-disable-next-line
    }, [loading, cont, paginaAtual, nPaginas, searchTerm, itemsPag]);

    const handleSelectAllChange = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setCheckedTrainers(dataToDisplay.map((item) => item));
        } else {
            setCheckedTrainers([]);
        }
    };

    //filtra dados
    function filterData(data) {
        return data.filter((item) =>
            item.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.office.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.area.some((area) => area.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    }

    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }

    const associarFormadores = (e, item) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setCheckedTrainers((prevChecked) => [...prevChecked, item]);
        } else {
            setCheckedTrainers((prevChecked) => prevChecked.filter((element) => element._id !== item._id));
        }
    };

    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }

    //Altera o estado do formador para ativo
    function alterarEstadoAtivo(id) {
        fetch(`${process.env.REACT_APP_API_URL}/admins/${id}/estados/ativo`,
            {
                method: "PATCH",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem('admin2')
                }
            }).then((result) => {
                result.json().then((resp) => {
                })

            })
    }

    //Altera o estado do formador para inativo
    function alterarEstadoInativo(id) {
        fetch(`${process.env.REACT_APP_API_URL}/admins/${id}/estados/inativo`,
            {
                method: "PATCH",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem('admin2')
                }
            }).then((result) => {
                result.json().then((resp) => {

                })
            })
    }

    //Envia mail ao formador para inscreve
    function sendEmail() {
        const email = document.getElementById("emailTo").value;
        if (email) {
            fetch(`${process.env.REACT_APP_API_URL}/formadores/mailInscricao`, {
                method: "POST",
                body: JSON.stringify({
                    email: email
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem('admin2')
                }
            }).then((result) => {
                toastSuccess("Email enviado com sucesso");
                document.getElementById("emailTo").value = "";
            }).catch((error) => {
                console.error('Error:', error);
                toastError("Erro ao enviar o email");
            });
        } else {
            toastError("Email inválido");
        }
    }
    //Função automatizada do estado
    function alterarEstado(date, id) {
        AlterarFormato(date)
        let dateLastUfcd = new Date(date)
        let dateLastUfcdMax = addOneYear(dateLastUfcd)
        if (dateLastUfcdMax > dateNow) {
            alterarEstadoAtivo(id)
        }
        else {
            alterarEstadoInativo(id)
        }
    }

    //Recebe todos os formadores
    async function getFormadores() {
        fetch(`${process.env.REACT_APP_API_URL}/formadores/validados`, {
            headers: {
                'authorization': localStorage.getItem('admin2')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    result.map(id => {
                        fetch(`${process.env.REACT_APP_API_URL}/ufcds/trainer/sorted/ufcd/${id._id}`,
                            {
                                headers: {
                                    'authorization': localStorage.getItem('admin2')
                                }
                            })
                            .then((res) => res.json())
                            .then(
                                (result) => {
                                    if (result.length > 0) {
                                        alterarEstado(result[0].dateEnd, id._id)
                                        setCont(cont + 1);
                                    }
                                    else {
                                        alterarEstadoInativo(id._id)
                                    }
                                    setCont(cont + 1);
                                },
                                (error) => {
                                    console.error("Error fetching data: ", error);
                                    setError(error);
                                }
                            );
                        let mediac = null
                        let cont = 0
                        let data2 = []
                        let filtro = []
                        if (id.ufcds.length > 0 && id.ufcds) {
                            if (id.ufcds.dateEnd) {
                                id.ufcds.sort((a, b) => b.dateEnd.localeCompare(a.dateEnd));
                            }
                            data2 = id.ufcds
                            filtro = data2.filter(ufcds => ufcds.evaluation !== null);
                            if (filtro.length >= 5) {
                                filtro.length = 5
                            }
                            for (let i = 0; i < filtro.length; i++) {
                                if (filtro[i].evaluation === null) {
                                    cont--
                                }
                                else {
                                    mediac = mediac + parseInt(filtro[i].evaluation)
                                }

                            }
                            if (mediac !== null) {
                                id.media = Math.round(mediac / (filtro.length + (cont)))
                            }
                            else id.media = -1
                        }
                        return id.media
                    });
                    const sortedData = result.slice().sort((a, b) => a.fullName.localeCompare(b.fullName));
                    setData(sortedData);
                    paginacaoTabelas(filterData(sortedData));
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    }

     //Filtra por ordem de média
     function getFormadoresOrder() {
        let numAscending = [...data].sort((a, b) => a.media - b.media);
        if (source === "down") {
            numAscending= [...data].sort((a, b) => a.media - b.media);
            setSource("up")
        } else {
            numAscending= [...data].sort((a, b) => b.media - a.media);
            setSource("down")
        }
        setData(numAscending);
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    //Desvalida um formador
    function unvalidFormador(id_candidatura) {
        fetch(`${process.env.REACT_APP_API_URL}/admins/${id_candidatura}/desvalidar`,
            {
                method: "PATCH",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem('admin2')
                }
            }).then((result) => {
                result.json().then((resp) => {
                })
                toastSuccess('Formador removido com sucesso!')
                getFormadores();
            })
    }
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };

    //Abre o modal e atribui ao state modal data os dados para apresentar no modal
    function openModal(item) {
        setModalData(item);
        setModalIsOpen(true);
    }
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }
    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin2 currentPage={"ListaFormador"} />
                </div>
                <div className="flex-grow mt-8">
                    <Link to={'/MenuSuperAdmin'} className="back-button-div-noNavbar">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>
                    <h1 className="mt-3 sm:mt-0 title">Bolsa de Formadores</h1>
                    {data && data.length > 0 ? <> <div className="flex flex-col">
                        <div className="search-div">
                            <label className="search-input-label">Pesquisar: </label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input type="text" className="search-input" placeholder="ex: Nome, Email..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                            </div>
                        </div>
                    </div>
                        <div className="flex justify-center select-all-checkbox">
                            <input
                                type="checkbox"
                                onChange={handleSelectAllChange}
                                checked={checkedTrainers.length === dataToDisplay.length}
                            />
                            <label className="pl-1 text-main-color">Selecionar Tudo</label>
                        </div>
                        <div className="flex items-center justify-center mb-1">
                            <label className="text-text-main-color">Dados por página: </label>
                            <select onWheel={(e) => e.target.blur()} className="ml-1 selector w-14"
                                value={itemsPag}
                                onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div className="table-container">
                            <table className="my-table">

                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="min-w-[80px] border border-white">Selecionar</th>
                                        <th className="p-2 min-w-[150px] border border-white">Nome Completo</th>
                                        <th className="p-2 min-w-[80px] border border-white">Email</th>
                                        <th className="p-2 min-w-[80px] border border-white">Estado</th>
                                        <th className="p-2 min-w-[80px] border border-white">Habilitações</th>
                                        <th className="p-2 min-w-[80px] border border-white">Área de Educação Formação</th>
                                        <th className="p-2 min-w-[80px] border border-white">Área de Formação Académica</th>
                                        <th className="p-2 min-w-[120px] border border-white">
                                            <div className="flex items-center justify-around ">
                                                Classificação {source === "down" ? <img alt="arrow" className="arrow" id="arrow" onClick={getFormadoresOrder} src={process.env.PUBLIC_URL + "/img/down.png"} /> : <img className="arrow" alt="arrow" id="arrow" onClick={getFormadoresOrder} src={process.env.PUBLIC_URL + "/img/up.png"} />}
                                            </div>
                                        </th>
                                        <th className="p-2 min-w-[80px] border border-white">Perfil</th>
                                        <th className="p-2 min-w-[80px] border border-white">Histórico</th>
                                        <th className="p-2 min-w-[80px] border border-white">Avaliação</th>
                                        <th className="p-2 min-w-[80px] border border-white">Invalidar</th>
                                    </tr>
                                </thead>
                                {data.length > 0 ? <tbody className="text-text-main-color">
                                    {filterData(dataToDisplay).map((item, index) => (
                                        <tr key={index}>
                                            <td className="min-w-[80px] border border-white">
                                                <input type="checkbox"
                                                    onChange={(e) => associarFormadores(e, item)}
                                                    checked={checkedTrainers.some(trainer => trainer._id === item._id)} />
                                            </td>
                                            <td className="border border-white">{item.fullName}</td>
                                            <td className="border border-white">{item.email}</td>
                                            <td className="border border-white">{item.status === "true" ? "Ativo" : "Inativo"}</td>
                                            <td className="border border-white">{item.academicDegree}</td>
                                            <td className="border border-white">{item.courseDesignation}</td>
                                            {
                                                item.area.length > 0 ?
                                                    <td className="border border-white cursor-pointer hover:bg-gray-300 group" onClick={(e) => openModal(item.area)}>
                                                        <div className="flex items-center justify-center group-hover:brightness-150">
                                                            <FaEye size={24} />
                                                        </div>
                                                    </td>
                                                    :
                                                    <td className="border border-white cursor-not-allowed">
                                                        <div className="flex items-center justify-center opacity-25 ">
                                                            <FaEyeSlash size={24} />
                                                        </div>
                                                    </td>
                                            }
                                            <td className="border border-white">{item.media !== -1 ? <>
                                                {item.media ? <div>{item.media}%</div> : "Sem disciplinas"}</> : "Em atividade"}
                                            </td>
                                            <td className="border border-white cursor-pointer hover:bg-gray-300 group" onClick={(e) => navigate("/MenuSuperAdmin/list/" + item._id)}>
                                                <div className="flex tooltip items-center justify-center group-hover:brightness-150" data-tip={!item.profileValid ? "Perfil Incompleto": "Perfil Completo"}>
                                                    <FaEye className={item.profileValid?"":"text-red-800"} size={24} />
                                                </div>
                                            </td>
                                            <td className="border border-white cursor-pointer hover:bg-gray-300 group" onClick={(e) => navigate("/MenuSuperAdmin/list/Full-ufcds/" + item._id)}>
                                                <div className="flex items-center justify-center group-hover:brightness-150">
                                                    <FaEye size={24} />
                                                </div>
                                            </td>
                                            <td className="border border-white cursor-pointer hover:bg-gray-300 group" onClick={(e) => navigate("/MenuSuperAdmin/list/ufcds/" + item._id)}>
                                                <div className="flex items-center justify-center group-hover:brightness-150">
                                                    <FaEye size={24} />
                                                </div>
                                            </td>
                                            <td className="border border-white">
                                                <ConfirmToaster css={"hover:brightness-75"} icon={<IoMdRemoveCircle size={24} />} title={"Invalidar"} message={"Tem a certeza que deseja invalidar o formador ?"} confirm={unvalidFormador} id={item._id}></ConfirmToaster>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody> : <tbody />}
                            </table>
                        </div>
                        <div className="mt-4">
                            <button onClick={() => { checkedTrainers.length > 0 ? ExcelFormadores(checkedTrainers) : toastError("Sem formadores selecionados.") }} className="button-geral tooltip" data-tip={"Exportar Formadores Selecionados em Excel"}>Exportar</button>
                        </div>
                        <div className="flex items-center justify-center mt-4 mb-8">
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                <AiOutlineDoubleLeft />
                            </button>
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                <AiOutlineLeft />
                            </button>
                            <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                <AiOutlineRight />
                            </button>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                <AiOutlineDoubleRight />
                            </button>
                        </div>
                    </> : <p className="third-title">Não existem dados</p>}

                    <div className="mt-10 mb-28">
                        <div className="subtitle">Envio de Email de Inscrição</div>
                        <div className="third-title"> Digite o mail:</div>
                        <input className="input-field-long" type="text" name="emailTo" id="emailTo" />
                        <button className="button-geral" onClick={sendEmail}>Submeter</button>
                    </div>
                </div>
                {/* Modal das Áreas de formação de um formador */}
                <Modal isOpen={modalIsOpen} className="fixed inset-0 flex items-center justify-center">
                    <div className="absolute w-full h-full" onClick={() => setModalIsOpen(false)}></div>
                    <div className="relative flex flex-col items-center px-10 mx-4 shadow-2xl bg-secundary-color py-7 rounded-2xl">
                        <h1 className="title">Áreas de Ação-Formação</h1>
                        {modalData.map((item, itemIndex) => (
                            <div className="flex flex-col items-start w-full" key={itemIndex}>
                                <p className="text-left text-text-main-color">{item}</p>
                            </div>
                        ))}
                        <div className="flex items-center mt-3">
                            <button className="w-24 button-cancelar" onClick={() => setModalIsOpen(false)}>Voltar</button>
                        </div>
                        <button className="absolute top-1.5 right-1.5" onClick={() => setModalIsOpen(false)}>
                            <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                        </button>
                    </div>
                </Modal>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main>
        </>
    );
}

export default ShowFormadores;