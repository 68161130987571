import React from "react";
import { Link, useNavigate, Navigate } from 'react-router-dom'
import Header from '../../components/Header'
import 'react-toastify/dist/ReactToastify.css';
import { FaUserAlt, FaUserTie, FaUsers, FaUserPlus } from "react-icons/fa";
import Footer from "../../components/Footer";


const Home = () => {

    const navigate = useNavigate()
    const jwt = localStorage.getItem("coord") || localStorage.getItem("admin1") || localStorage.getItem("user") || localStorage.getItem("admin2")

    //Função que verifica o tipo de utilizador e o redireciona para a página correspondente
    function checkLogged() {
        if (localStorage.getItem("admin1")) {
            return (
                <Navigate to={"/menuAdmin/" + localStorage.getItem("id")} />
            )
        } else if (localStorage.getItem("admin2")) {
            return (
                <Navigate to={"/MenuSuperAdmin"} />
            )
        } else if (localStorage.getItem("coord")) {
            return (
                <Navigate to={"/MenuCoordenador/" + localStorage.getItem("id")} />
            )
        } else if (localStorage.getItem("user")) {
            return (
                <Navigate to={"/menutrainers/" + localStorage.getItem("id")} />
            )
        } else if (localStorage.getItem("formando")) {
            navigate("/menuformando/" + localStorage.getItem("id"))
        }
    }

    return (
        <>
            <main className="flex min-h-screen">
                <Header sideMenu={"true"} />

                <div className="w-full flex-grow md:max-w-[1140px] lg:px-[0%] px-[15%] ml-8">
                    <div className="flex flex-col justify-start mt-8">
                        <h1 className="mb-0 text-4xl text-center sm:text-left title">Bem-vindo</h1>
                    </div>
                    <p className="mt-4 text-center sm:text-left text-text-second-color">Estou a aceder como:</p>
                    <div className="flex flex-col items-center justify-start gap-4 mt-3 sm:items-start sm:flex-row">
                        {jwt ? checkLogged() : (
                            <>
                                <div className="flex flex-col items-center">
                                    <Link to="/login" className="flex items-center justify-center w-32 h-32 bg-main-color 2xl:w-32 2xl:h-32 rounded-3xl hover:opacity-90">
                                        <FaUserTie className="p-2 text-secundary-color" size={60} />
                                    </Link>
                                    <span className="mt-2 text-main-color text-md">Administrador</span>
                                </div>
                                <div className="flex flex-col items-center">
                                    <Link to="/logincoordenador" className="flex items-center justify-center w-32 h-32 bg-main-color 2xl:w-32 2xl:h-32 rounded-3xl hover:opacity-90">
                                        <FaUsers className="p-2 text-secundary-color" size={60} />
                                    </Link>
                                    <span className="mt-2 text-main-color text-md">Coordenador</span>
                                </div>
                                <div className="flex flex-col items-center">
                                    <Link to="/loginformador" className="flex items-center justify-center w-32 h-32 bg-main-color 2xl:w-32 2xl:h-32 rounded-3xl hover:opacity-90">
                                        <FaUserAlt className="p-2 text-secundary-color" size={60} />
                                    </Link>
                                    <span className="mt-2 text-main-color text-md">Formador</span>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="flex flex-col mt-8 mb-6">
                        <p className="text-center sm:text-left text-text-second-color">Quer inscrever-se na nossa Bolsa de Formadores?</p>
                        <Link to="/candidacy" className="flex items-center self-center px-4 mt-2 text-left text-white sm:self-start gap-x-4 button-geral text-md">
                            <p>Inscrição de Formador</p>
                            <FaUserPlus className="p-2 text-secundary-color" size={50} />
                        </Link>
                    </div>
                </div>
                <div className="w-full mt-24 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main >
        </>
    );
}

export default Home