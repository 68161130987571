import React, { useState } from 'react';
import Header from '../../../../components/Header';
import { useParams, useNavigate, Link } from 'react-router-dom';
import toastSuccess from '../../../../components/Toast/toastSuccess';
import 'react-toastify/dist/ReactToastify.css';
import toastError from '../../../../components/Toast/toastError';
import Footer from '../../../../components/Footer';

function ResetPassword() {
    //Password
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    //Variáveis para mostrar password 
    const [showPassword1, setShowPassword1] = useState(false)
    const [showPassword2, setShowPassword2] = useState(false)

    //Funcionalidades
    const params = useParams();
    const navigate = useNavigate();

    //Função para mostrar password 
    //Mostrar pass
    const toggleShowPassword = (field) => {
        if (field === 1) {
            setShowPassword1(!showPassword1);
        } else if (field === 2) {
            setShowPassword2(!showPassword2);
        }
    };

    //Submete a nova password na bd
    //Função de submit
    async function handleSubmit(event) {
        event.preventDefault();

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/admins/login/password/${params.token}`, {
                method: 'PATCH',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                body: JSON.stringify({ password: password, confirmPassword: confirmPassword }),

            });
            if (password !== confirmPassword) {
                toastError('As Palavras-passe não coincidem!');
                return;
            }
            const data = await response.json();
            toastSuccess('Palavra-passe atualizada com sucesso!')
            localStorage.removeItem('jwt');
            navigate(`/login`);

        } catch (error) {
            toastError('Ocorreu um erro ao atualizar a Palavra-passe. Tente novamente mais tarde!');
        }

    };

    return (
        <>
            <main className='min-h-screen'>
                <Header />
                <div className='back-button-div-noNavbar'>
                    <Link to={'/login'}>
                        <img className='back-button-img' src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} alt="Back" />
                    </Link>
                </div>
                <div className='flex flex-col items-center justify-center flex-grow -mt-16'>
                    <h1 className='title'>Redefinir Palavra-passe</h1>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="password" className='text-text-main-color'>Nova Palavra-passe</label>
                            <div className='flex items-center justify-center'>
                                <span className='mr-2'>
                                    <i className="fas fa-key text-main-color"></i>
                                </span>
                                <div className='relative'>
                                    <input type={showPassword1 ? 'text' : 'password'} id="password" name="password" className='text-lg input-field' onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
                                    <button type="button" onClick={() => toggleShowPassword(1)} className="absolute top-1 right-1">
                                        <i className={showPassword1 ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className='mt-3'>
                            <label htmlFor="confirmPassword" className='text-text-main-color'>Confirmar nova Palavra-passe</label>

                            <div className='flex items-center justify-center'>
                                <span className='mr-2'>
                                    <i className="fas fa-key text-main-color"></i>
                                </span>
                                <div className='relative'>
                                    <input type={showPassword2 ? 'text' : 'password'} id="password" name="password" className='text-lg input-field' onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirmar nova Palavra-passe" required />
                                    <button type="button" onClick={() => toggleShowPassword(2)} className="absolute top-1 right-1">
                                        <i className={showPassword2 ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='mt-3'>
                            <button className='button-geral' type="submit">Confirmar</button>
                        </div>
                    </form>
                </div>
                <div className="w-full mt-24 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main >
        </>
    );
}

export default ResetPassword;
