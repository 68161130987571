import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Componente toastSuccess
export default function toastSuccess (success) {
  toast.success(success, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000
  });
};
