export default function AlterarFormato(d) {
    if (d) {
        // Check if the browser is Safari
        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        if (!isSafari) {
            
            if (d.split("-")[0].length === 2) {
                d = d.replaceAll("-", '/'); // Use / to ensure valid date format
                d = d.split('/').reverse().join('/'); // Adjust the format to YYYY/MM/DD
                d = new Date(d);
                d = d.toLocaleDateString("pt");
            }
            else {                
                let e = new Date (d)
                if (!isNaN(e.getTime())) { // Check if the date is valid
                    d = e.toLocaleDateString("pt");
                } 
            }           
        } else {
            // For other browsers, replace "-" with "," and manually format the date
            d = new Date(d);
            d = d.toLocaleDateString("pt", d);
        }

        return d;
    }
}