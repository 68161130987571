import React, { useState, useEffect } from "react";
import { UFCDs } from "./styled";
import { Link, useParams, useNavigate } from 'react-router-dom'
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import toastSuccess from "../../../components/Toast/toastSuccess";
import toastError from "../../../components/Toast/toastError";
import { ToastContainer } from "react-toastify";


function ShowUfcd() {
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);
    const [courseCode, setCourseCode] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");

    let id = 0
    const params = useParams();
    const navigate = useNavigate();
    //filtra dados
    function filterData(data) {
        return data.filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.codeUfcd.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.percurso[0].name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }

    useEffect(() => {
        getUfcds()
        percursosExistentes()
    }, [loading]);

    function getUfcds() {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds`, {
            headers: {
                "authorization": localStorage.getItem('admin1')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);

                    setError(error);
                    percursosExistentes()
                    setLoading(false);
                }
            );
    }


    // async function criarCurso() {

    //     await fetch(`${process.env.REACT_APP_API_URL}/moodle/course`,
    //         {
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json',
    //                 'authorization': localStorage.getItem('admin1')
    //             },
    //             method: 'POST',
    //             body: JSON.stringify({
    //                 fullname: document.querySelector("#codeUfcd").value.trim(),
    //                 shortname: document.querySelector("#name").value.trim(),
    //                 categoryid: document.querySelector("#seletor").value.trim(),
    //                 cargaHoraria: document.querySelector("#cargahoraria").value.trim()
    //             })
    //         })
    //         .then(res => res.json())
    //         .then((res) => {
    //            
    //             id =  res[0].id 
    //         })
    //     await submeter()
    // }

    function percursosExistentes() {
        const seletor = document.getElementById('seletor')
        if (seletor) {
            seletor.innerHTML = '<option value="" disabled selected>Selecionar Percurso</option>';
            fetch(`${process.env.REACT_APP_API_URL}/percurso`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem('admin1')
                },
            })
                .then(res => res.json())
                .then((result) => {
                    result.forEach(ufcd => {
                        const option = document.createElement('option')
                        option.value = ufcd.id;
                        option.innerHTML = ufcd.name ?? "vazio";
                        setCourseCode(ufcd.name)
                        seletor.appendChild(option);

                    });
                });
        }
    }

    function submeter() {

        let ufcd = {
            codeUfcd: document.querySelector("#codeUfcd").value.trim(),
            name: document.querySelector("#name").value.trim(),
            courseCode: courseCode,
            cargaHoraria: document.querySelector("#cargaHoraria").value.trim(),
            id_ufcd: id
        }

        if (!ufcd.codeUfcd || !ufcd.name || !ufcd.courseCode) {
            toastError("Alguns dos campos não foram inseridos")
            return;
        }

        fetch(`${process.env.REACT_APP_API_URL}/ufcds`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', "authorization": localStorage.getItem('admin1') },
                body: JSON.stringify(ufcd)
            })
            .then(res => res.json())
            .then(
                (res) => {
                    toastSuccess('UFCD inserida com sucesso!')
                    getUfcds()

                },
                (error) => {
                    console.error(error)
                }
            )


    }

    if (loading) return (
        <div className="bg-secundary-color h-screen w-screen flex justify-center items-center">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    return (
        <>
            <Header />
            <UFCDs>
                <main>
                    <h1>Lista de disciplinas</h1>
                    <div className="search"><label className="pesquisar"> Pesquisar: </label>
                        <img className='lupa' alt="Lupa" src={process.env.PUBLIC_URL + "/img/search.svg"} />
                        <input type="text" className="search-input" placeholder="ex: Nome, Código..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Código disciplina</th>
                                <th>Nome</th>
                                <th>Nome do curso</th>
                            </tr>
                        </thead>
                        {data.length > 0 ? <tbody>
                            {filterData(data).map((item, index) => (
                                <tr key={index} className="row-click" onClick={() => navigate("/menuadmin/" + params.id_candidatura + "/UFCDs/code-ufcds/" + item.codeUfcd)}>
                                    <td className="ufcd">{item.codeUfcd}</td>
                                    <td className="ufcd">{item.name}</td>
                                    {item.percurso[0] ? <td className="ufcd">{item.percurso[0].name}</td> : <td>Sem curso</td>}
                                </tr>
                            ))}
                        </tbody> : <tbody />}

                    </table>

                    <br />
                    <Link to={'/menuadmin/' + params.id_candidatura} className="back-button-link">
                        <img className='back-button' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>
                </main>

            </UFCDs>
            <Footer />
        </>


    );
}

export default ShowUfcd;
