import React from "react";
import { Tooltip } from 'react-tooltip'
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";

export default function ConfirmToasterReason({ css, icon, title, message, confirm, id, tooltip, type, motivoInicial }) {
    const [open, setOpen] = React.useState(false);
    const [motivo, setMotivo] = React.useState("");
    const [motivoOutro, setMotivoOutro] = React.useState("");
    const handleOpen = () => {
        setOpen(!open);
    }

    return (
        <>
            <button className={css} onClick={handleOpen} data-tooltip-id={"tooltipUnic" + id + type}
                // data-tooltip-content={tooltip}
                data-tooltip-place="top">
                {icon}
            </button>
            {tooltip &&
                <Tooltip id={"tooltipUnic" + id + type}>{tooltip}</Tooltip>
            }
            <Dialog className="fixed inset-0 w-screen h-screen m-0 bg-opacity-20 backdrop-blur-sm" open={open} handler={handleOpen}>
                {/* Overlay with backdrop blur */}
                <div className="fixed inset-0 h-full bg-opacity-5"></div>

                {/* Dialog content */}
                <div className="flex items-center justify-center h-screen">
                    <div className="z-50 p-4 rounded-lg shadow-lg max-h-128 w-128 bg-main-color brightness-150 shadow-gray-600 ">
                        <DialogHeader className="mb-0 text-white title" >{title}</DialogHeader>
                        <DialogBody className="text-white" divider>{message}</DialogBody>
                        <DialogBody className="text-white" divider>Digite o motivo:</DialogBody>
                        <select onWheel={(e) => e.target.blur()} className="flex resize-y max-h-44 mx-4 w-[calc(100%-1rem)] justify-centerselector"
                            defaultValue={motivoInicial?motivoInicial:""}
                            onChange={(e) => setMotivo(e.target.value)}>
                            <option value={""} disabled>Digite um motivo</option>
                            <option value={"Por residir em Lisboa, Ilhas ou Algarve, não se encontra elegível para esta ação formativa;"}>1. Por residir em Lisboa, Ilhas ou Algarve, não se encontra elegível para esta ação formativa;</option>
                            <option value={"É dada prioridade a formandos/as de escolaridade reduzida, podendo não ter sido selecionado/a caso apresente escolaridade superior ao 12º ano;"}>2. É dada prioridade a formandos/as de escolaridade reduzida, podendo não ter sido selecionado/a caso apresente escolaridade superior ao 12º ano;</option>
                            <option value={"Ausência de envio de toda a documentação necessária;"}>3. Ausência de envio de toda a documentação necessária;</option>
                            <option value={"A formação não avançou por falta de nº mínimo de inscritos/as ou preenchimento total da turma por ordem cronológica de inscrição, podendo ser contactado/a no caso de existência de uma ação futura."}>4. A formação não avançou por falta de nº mínimo de inscritos/as ou preenchimento total da turma por ordem cronológica de inscrição, podendo ser contactado/a no caso de existência de uma ação futura.</option>
                            <option value={"Outro"}>5. Outro</option>
                        </select>
                        {motivo === "Outro" && <textarea onChange={(e)=> setMotivoOutro(e.target.value)} className="input-label mt-2 flex resize-y max-h-44 mx-4 w-[calc(100%-1rem)] justify-centerselector"/> }
                        <DialogFooter className="flex justify-between">
                            <Button
                                variant="text"
                                color="red"
                                onClick={handleOpen}
                                className="w-32 p-2 text-sm text-white bg-text-error hover:brightness-75"
                            >
                                Cancelar
                            </Button>
                            {id ? (
                                <Button
                                    className="w-32 p-2 text-sm text-white bg-text-correct hover:brightness-75"
                                    variant="gradient"
                                    color="green"
                                    onClick={() => { confirm(id, motivo === "5. Outro"? motivoOutro : motivo); handleOpen() }}
                                >
                                    Confirmar
                                </Button>
                            ) : (
                                <Button
                                    className="w-32 p-2 text-sm tracking-wide text-white bg-text-correct hover:brightness-75"
                                    variant="gradient"
                                    color="green"
                                    onClick={() => { confirm(); handleOpen() }}
                                >
                                    Confirmar
                                </Button>
                            )}
                        </DialogFooter>
                    </div>
                </div>
            </Dialog>
        </>
    );
}