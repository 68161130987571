import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BsFillPencilFill } from "react-icons/bs";
import Footer from "../../../components/Footer";
import toastSuccess from "../../../components/Toast/toastSuccess";
import toastError from "../../../components/Toast/toastError";
import NavbarAdmin1 from "../../../components/Navbars/NavbarAdmin1/NavbarAdmin1";
import { IoMdRemoveCircle } from "react-icons/io";
import ConfirmToaster from "../../../components/Toast/toastConfirmChoice";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";

function Operacoes() {
    const [data, setData] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [admin, setAdmin] = useState("");
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(-1);
    const [cont, setCont] = useState(0);
    const [error, setError] = useState(null);
    const [adminType, setAdminType] = useState(null);
    const [alterar, setAlterar] = useState(0);
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-operacoes-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const params = useParams();
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Admin-operacoes-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)
    
    const navigate = useNavigate();
    
    useEffect(() => {
        Cookies.set("Admin-operacoes-Pag", paginaAtual);
        Cookies.set(`Admin-operacoes-itm1`, itemsPag)
        getNome()
    }, [loading, paginaAtual, nPaginas, paginaAtual, itemsPag, modal, cont]);

    const handleClick = (event, item) => {
        // Check if the clicked element is a <td>
        if (event.target.tagName === 'TD') {
            // Check if the <td> has a 'clickable' class
            if (!event.target.classList.contains('operacao')) {
                // Navigate to the desired location
                // Replace 'destination' with your actual navigation logic
            }
            else navigate("/menuAdmin/"+params.id_candidatura+"/operacoes/"+ item._id)
        }
    };

    useEffect(() => {
        getAdmin()
        getAdmins()
    }, [loading, admin]);
    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }


    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };
    function getNome() {
        fetch(`${process.env.REACT_APP_API_URL}/operacao`, {
            headers: {
                "authorization": localStorage.getItem('admin1')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    paginacaoTabelas(result)
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                    setLoading(false);
                }
            );
    }

    function getAdmin() {
        fetch(`${process.env.REACT_APP_API_URL}/admins/${params.id_candidatura}`, {
            headers: {
                "authorization": localStorage.getItem('admin1')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setAdminType(result.nomeRole);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    }

    function addAdminOperacao(id, admin) {
        fetch(`${process.env.REACT_APP_API_URL}/operacao/associarAdmin/${id}`,
            {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json', "authorization": localStorage.getItem('admin1') },
                body: JSON.stringify({
                    admin: admin,
                })
            })
            .then((res) => res.json())
            .then(
                (result) => {
                    setLoading(false);
                    setModal(-1);
                    setCont(cont+1)
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                   
                }
            );
    }
    function getAdmins() {
        fetch(`${process.env.REACT_APP_API_URL}/admins`, {
            headers: {
                "authorization": localStorage.getItem('admin1')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    const array = result.filter(admin => (admin.nomeRole === "Administrador - Gestor de Operação" || admin.nomeRole === "Entidade de Ligação" ))
                    setAdmins(array)
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    }

    function submeterNomeOp() {
        const tipologia = document.getElementById("nameTipologia").value
        const codigoOperacao = document.getElementById("codigoOperacao").value
        if (!tipologia) {
            toastError("Alguns dos campos não foram inseridos")
            return;
        }
        setLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/operacao`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', "authorization": localStorage.getItem('admin1') },
                body: JSON.stringify({
                    nomeTipologia: tipologia,
                    codigoOperacao: codigoOperacao
                })
            })
            .then(res => res.json())
            .then(
                (res) => {
                    toastSuccess('Operação inserida com sucesso!')
                    setLoading(false)
                },
                (error) => {
                    console.error(error)
                    setLoading(false)
                    return
                }
            )
        getNome()
        document.getElementById("nameTipologia").value = ""
        document.getElementById("codigoOperacao").value = ""
    }

    //Elimina o nome pelo seu id
    function deleteNome(_id) {

        fetch(`${process.env.REACT_APP_API_URL}/operacao/${_id}`, {
            method: 'DELETE',
            headers: {
                'authorization': localStorage.getItem('admin1')
            }
        }).then((result) => {
            result.json().then((resp) => {
                toastSuccess('Operação removida com sucesso!')
                getNome()
            })
        })
    }

    function changeOperacao(id, index) {
        const nomeOperacao = document.getElementById(`nomeOperacao${index}`).value
        const codigoOperacao = document.getElementById(`codigoOperacao${index}`).value

        fetch(`${process.env.REACT_APP_API_URL}/operacao/${id}`,
            {
                body: JSON.stringify({
                    nomeOperacao: nomeOperacao,
                    codigoOperacao: codigoOperacao
                }),
                method: "PATCH",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem('admin1')
                }
            }).then((result) => {
                result.json().then((resp) => {
                    setAlterar(false);
                    toastSuccess('Dados alterados com sucesso!')
                    getNome();
                })
            })
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }
    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin1 currentPage={"manageOperacoesAdmin"} />
                </div>
                <div className="flex-grow mt-8">
                    <Link to={'/menuadmin/' + params.id_candidatura} className="back-button-div-noNavbar">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>
                    <h1 className="title">Gerir Operações</h1>
                    <div className="flex flex-col">
                        <div className="flex flex-col items-center">
                            <h2 className="subtitle">Lista de Operações</h2>
                            {data && data.length > 0 ? <>
                                <div className="flex justify-center items-center mb-1">
                                    <label className="text-text-main-color">Dados por página: </label>
                                    <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                        value={itemsPag}
                                        onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                    </select>
                                </div>
                                <div className="table-container">
                                    <table className="my-table">

                                        <thead className="bg-main-color">
                                            <tr className="text-white h-14">
                                                <th className="p-2 min-w-[80px] border border-white">Código da operação</th>
                                                <th className="p-2 min-w-[150px] border border-white">Tipologia</th>
                                                <th className="p-2 min-w-[150px] border border-white">Gestor de Operação</th>
                                                <th className="p-2 min-w-[80px] border border-white">Remover</th>
                                            </tr>
                                        </thead>
                                        {data.length > 0 ? <tbody className="text-text-main-color">
                                            {dataToDisplay.map((item, index) => (
                                                <tr key={index} className="row-click" onClick={(e) => handleClick(e, item)} >
                                                    {alterar === index + 1 ? (
                                                        <td className="operacao">
                                                            <input
                                                                className="input-field"
                                                                placeholder={item.codigoOperacao}
                                                                defaultValue={item.codigoOperacao}
                                                                id={"codigoOperacao" + index}
                                                                type="text"
                                                            />
                                                        </td>
                                                    ) : (
                                                        <td className="border operacao border-white">{item.codigoOperacao}</td>
                                                    )}
                                                    {alterar === index + 1 ? (
                                                        <td className="operacao">
                                                            <input
                                                                className="input-field"
                                                                placeholder={item.nomeTipologia}
                                                                defaultValue={item.nomeTipologia}
                                                                id={"nomeOperacao" + index}
                                                                type="text"
                                                            />
                                                        </td>
                                                    ) : (
                                                        <td className="border operacao border-white">{item.nomeTipologia}</td>
                                                    )}
                                                    {(adminType === "Administrador - Gestor de Operação" || adminType === "Administrador - Diretor de Departamento"|| adminType === "Gestor Financeiro") ? <td className="operacao">
                                                        {item.admin && item.admin.length > 0  && modal !== index ? <>{item.admin[0].fullName}  
                                                        <button className="mx-2 button-geral " onClick={() => setModal(index)}>
                                                            <BsFillPencilFill />
                                                        </button>
                                                        <button className="mx-2 button-geral " onClick={() => addAdminOperacao(item._id)}>
                                                            <IoMdRemoveCircle />
                                                        </button></> :
                                                            modal === index ? <><select defaultValue={admin? admin : item.admin.length>0 ?item.admin[0]._id: ""} onChange={(e) => setAdmin(e.target.value)}>
                                                                <option disabled value="">Selecione um Gestor de Operação</option>
                                                                {admins.map((adm, index) => (
                                                                    <option key={index} value={adm._id}>{adm.fullName}</option>
                                                                ))}
                                                            </select>
                                                                <button className="mx-2 button-geral " onClick={() => {addAdminOperacao(item._id, admin) }}> Confimar </button> </>
                                                                : <button className="mx-2 button-geral " onClick={() => setModal(index)}> Adicionar </button>}
                                                    </td>: <td>{item.admin && item.admin.lenght> 0 && item.admin[0].fullName}  </td>}

                                                    {alterar === index + 1 ? (
                                                        <td className="border border-white">
                                                            <div className="flex items-center">
                                                                <button className="mr-2 button-cancelar" onClick={() => setAlterar(0)}>
                                                                    Cancelar
                                                                </button>
                                                                <ConfirmToaster
                                                                    icon={"Confirmar"}
                                                                    css={"button-confirmar hover:brightness-75"}
                                                                    title={"Alterar Operação"}
                                                                    message={"Deseja realizar as alterações?"}
                                                                    confirm={() => changeOperacao(item._id, index)}
                                                                ></ConfirmToaster>
                                                            </div>
                                                        </td>
                                                    ) : (
                                                        <td className="border border-white">
                                                            <div className="flex items-center justify-center">
                                                                {item.nomeTipologia === "Não Financiada" ? (
                                                                    <button className="mr-2 button-disabled" disabled>
                                                                        <BsFillPencilFill />
                                                                    </button>
                                                                ) : (
                                                                    <button className="mr-2 button-geral " onClick={() => setAlterar(index + 1)}>
                                                                        <BsFillPencilFill />
                                                                    </button>
                                                                )}
                                                                {item.nomeTipologia === "Não Financiada" ? (
                                                                    <button className="button-disabled" disabled>
                                                                        <IoMdRemoveCircle />
                                                                    </button>
                                                                ) : (
                                                                    <ConfirmToaster
                                                                        css={"button-geral hover:brightness-75"}
                                                                        icon={<IoMdRemoveCircle />}
                                                                        title={"Eliminar"}
                                                                        message={"Deseja eliminar a operação ? (Esta ação é irreversível)"}
                                                                        confirm={() => deleteNome(item._id)}
                                                                    ></ConfirmToaster>
                                                                )}
                                                            </div>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody> : <tbody />}
                                    </table>
                                </div>
                                <div className="flex items-center justify-center mt-4 mb-8">
                                    <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                        <AiOutlineDoubleLeft />
                                    </button>
                                    <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                        <AiOutlineLeft />
                                    </button>
                                    <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                                    <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                        <AiOutlineRight />
                                    </button>
                                    <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                        <AiOutlineDoubleRight />
                                    </button>
                                </div> </> : <p className="third-title">Não existem dados</p>}
                            <div>
                                <h2 className="subtitle">Adicionar Operação</h2>
                                <div className="flex flex-col items-center">
                                    <div className="flex flex-col">
                                        <label className="text-left label-input">Nome da Tipologia:</label>
                                        <input type="text" id="nameTipologia" name="nameTipologia" className="input-field" required />
                                    </div>
                                    <div className="flex flex-col mt-2">
                                        <label className="text-left label-input">Código:</label>
                                        <input type="text" id="codigoOperacao" name="codigoOperacao" className="input-field" required />
                                    </div>
                                </div>
                                <button className="my-4 button-geral" onClick={submeterNomeOp} type="button" >Submeter</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>

            </main>
        </>
    );
}

export default Operacoes;
