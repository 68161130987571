import styled from "styled-components";
export const AreaFormando = styled.div`

font-family: "Lato";

main{
  min-height: 100vh;
  margin-top: 0vh !important;
}

.page {  
  height:100vh;
  width:100%;
  display: grid;
  grid-template-columns: 0.4fr 1.6fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "navbar content content"
    "navbar content content"
    "navbar content content";   
}

.navbar { grid-area: navbar; }

.content { 
  display:flex;
  justify-content:center;
  margin: 10vh ;
  grid-area: content; 
} 

.navbar {
background-color: #e4e4e4;
border-radius: 12px;
}

.form{
  grid-area: 1 / 2 / 2 / 6; 
  background-color: #8f8f8f;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.dadosCandidato {
  max-height: 80%;
  min-width: 600px;
  overflow: auto ;
  font-size: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  width: 35%;
  box-shadow: 5px 5px 15px #00000022;
  padding: 1em;
  padding-top: 0em;
  background-color: #e4e4e4;
}

.dadosCandidato .dado{
  margin-bottom: 2px;
  font-size: 18px;
}

.dadosCandidato .dado div:first-child{
    text-align: left;
    padding: 10px 15px 10px 10px;
    color: #000;
    font-weight: 700;
    letter-spacing: 2px;
}

.dadosCandidato .dado div:last-child {
    flex: 1;
    text-align: left;
    padding: 10px 10px 10px 15px;
    background-color: #fff;
}

.form-dados{
    margin-top: 30px;
}

input{
    width: 100%;
    appearance: none;
    border: none;
    box-sizing: border-box;
}
input:focus {
    outline: none;
  }

.button-cancelar {
    cursor: pointer;
    font-size: 0.75em;
    min-width: 100px;
    background-color: #474747;
    color: white;
    border-radius: 0.2em;
    padding: 10px;
    transition: all 0.2s ease;
    margin-top: 10px;
    margin-bottom: 0.75em;
    box-shadow: 10px 10px 15px #00000022;
    text-decoration: none;
    float: left;
}

.button-confirmar:hover {
    opacity: 0.8; 
}

.button-confirmar {
    cursor: pointer;
    font-size: 0.75em;
    min-width: 100px;
    background-color: #474747;
    color: white;
    border-radius: 0.2em;
    padding: 10px;
    transition: all 0.2s ease;
    margin-top: 10px;
    margin-bottom: 0.75em;
    box-shadow: 10px 10px 15px #00000022;
    text-decoration: none;
    float: right;
}

.button-cancelar:hover {
    opacity: 0.8; 
}

.div-dados{
        display: flex;
        justify-content: space-between;
        align-items: center;
}

.button-alterar {
    cursor: pointer;
    font-size: 0.75em;
    min-width: 100%;
    background-color: #474747;
    color: white;
    font-weight: 550;
    border: none;
    border-radius: 0.2em;
    padding: 0.5em;
    transition: all 0.2s ease;
    margin-top: 1em;
    box-shadow: 10px 10px 15px #00000022;
  }
  
  .button-alterar:hover {
    opacity: 0.8; 
  }
`