import React from "react";
import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom'
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import Modal from 'react-modal';
import NavbarAdmin1 from "../../../../components/Navbars/NavbarAdmin1/NavbarAdmin1";
import { FiDownload } from "react-icons/fi";
import { AiOutlineCloseCircle } from "react-icons/ai";

export default function Individual() {
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modal, setModal] = useState(false);
    document.data = data

    const params = useParams();

    useEffect(() => {
        getCandidaturas()
    }, []);
    
    //Obtem candidaturas
    function getCandidaturas() {
        fetch(`${process.env.REACT_APP_API_URL}/admins/${params.id_candidato}`, {
            headers: {
                authorization: localStorage.getItem('admin1')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    setLoading(false)
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    };
    //modal com os cvs
    function cv_files() {
        setModal(true)
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";
    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin1 currentPage={"home"} />
                </div>
                <Link to={"/menuadmin/" + params.id_candidatura + "/listAdmins"} className="back-button-div-noNavbar">
                    <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                </Link>
                <div className="flex-grow w-full mb-10">
                    <div className="mx-10 mt-8">
                        <div className="flex flex-col items-center justify-center w-full">
                            <div className="flex flex-col">
                                <img alt="Profile" className="object-contain mx-auto max-h-40 rounded-3xl sm:m-0" src={data.photo === undefined || data.photo === "" ? 'https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg' : data.photo} height="120px" />
                                {data.fullName ? <div className="mt-2 text-2xl font-bold text-text-main-color"> {data.fullName}</div> : <div className="input-error-profile" />}
                            </div>
                        </div>
                        <div className="my-6">
                            <hr />
                        </div>
                        <div className="mb-5 sm:ml-24">
                            <h2 className="text-3xl font-bold text-center text-text-main-color sm:text-left">Dados Pessoais</h2>
                            <div className="grid mt-4 sm:grid-cols-2">
                                <div>
                                    <div className="text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">Número de telefone: </div>
                                        {data.cellNumber ? <div className="mt-1 text-lg text-text-main-color">{data.cellNumber}</div> : <div className="input-error-profile" />}
                                    </div>
                                    <div className="mt-4 text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">E-mail:</div>
                                        {data.email ? <div className="mt-1 text-lg text-text-main-color">  {data.email}</div> : <div className="input-error-profile" />}
                                    </div>
                                    <div className="mt-4 text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">NIF:</div>
                                        {data.nif ? <div className="mt-1 text-lg text-text-main-color">  {data.nif}</div> : <div className="input-error-profile" />}
                                    </div>
                                    <div className="mt-4 text-center sm:text-left">
                                        <div className="mt-1 text-xs uppercase text-text-second-color opacity-90">Estado:</div>
                                        {data.valid === true ?
                                            <div className="mt-1 text-lg text-text-main-color">Autorizado</div> :
                                            <div className="mt-1 text-lg text-text-main-color">Excluido</div>
                                        }
                                    </div>
                                </div>
                                <div>
                                    <div className="mt-4 text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">Localidade:</div>
                                        {data.locality ? <div className="mt-1 text-lg text-text-main-color">{data.locality}</div> : <div className="input-error-profile" />}
                                    </div>
                                    <div className="mt-4 text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">Tipo:</div>
                                        {data.nomeRole ? <div className="mt-1 text-lg text-text-main-color">{data.nomeRole}</div> : <div className="input-error-profile" />}
                                    </div>
                                </div>
                            </div>
                            {data.nomeRole === "Administrador -Outro" && <> <h3 className="mt-8 text-3xl font-bold text-center text-text-main-color">Ficheiros</h3>
                                <div className="mt-3">
                                    <div className="grid w-1/2 grid-cols-1 mx-auto sm:grid-cols-2 lg:grid-cols-4">
                                        {data.cc_path ?
                                            <a href={data.cc_path} target="_blank" rel="noreferrer" download>
                                                <button className="button-files">Documento de Identificação </button>
                                            </a> :
                                            <div className="tooltip" data-tip="Sem Documento">
                                                <button className="button-files-empty">Documento de Identificação </button>
                                            </div>}
                                        {data.cv_path.length > 0 ?
                                            <a onClick={cv_files} target="_blank" rel="noreferrer" download>
                                                <button className="button-files">Curriculum Vitae</button>
                                            </a> :
                                            <div className="tooltip" data-tip="Sem Documento">
                                                <button className="button-files-empty">Curriculum Vitae</button>
                                            </div>}
                                        {data.ch_path ?
                                            <a href={data.ch_path} target="_blank" rel="noreferrer" download>
                                                <button className="button-files"> Certificado de Habilitações</button>
                                            </a> :
                                            <div className="tooltip" data-tip="Sem Documento">
                                                <button className="button-files-empty"> Certificado de Habilitações</button>
                                            </div>}
                                        {data.ibancomp_path ?
                                            <a href={data.ibancomp_path} target="_blank" rel="noreferrer" download>
                                                <button className="button-files"> IBAN </button>
                                            </a> :
                                            <div className="tooltip" data-tip="Sem Documento">
                                                <button className="button-files-empty"> IBAN </button>
                                            </div>}
                                        {data.outros_path.length > 0 ? data.outros_path.map((value) => (
                                            <a href={value.file} target="_blank" rel="noreferrer" download><button className="button-files">{value.name} </button></a>)) : ""}
                                    </div>
                                </div>
                            </>}
                        </div>
                        <Modal isOpen={modal} className="fixed inset-0 flex items-center justify-center">
                            <div className="absolute w-full h-full" onClick={() => setModal(false)}></div>
                            <div className="flex flex-col items-center px-10 shadow-2xl md:px-20 bg-secundary-color py-7 rounded-2xl">
                                <div>
                                    <h1 className="subtitle">Currículos</h1>
                                </div>
                                <div className="modal-content">
                                    {data.cv_path.map((value, index) => (
                                        <div key={value}>
                                            <div className="flex items-center m-1.5">
                                                <span className="mr-2 text-text-main-color">CV - {index + 1}:</span>
                                                <a className="mr-2 button-geral" href={value} target="_blank" rel="noreferrer" download>
                                                    <FiDownload />
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <button className="absolute top-1.5 right-1.5" onClick={() => setModal(false)}>
                                    <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                                </button>
                            </div>
                        </Modal>
                    </div>
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main >
        </>
    );

}

