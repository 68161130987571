import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import Footer from "../../Footer";
import NavbarAdmin1 from "../../Navbars/NavbarAdmin1/NavbarAdmin1";
import toastError from "../../Toast/toastError";
import toastSuccess from "../../Toast/toastSuccess";
import NavbarAdmin2 from "../../Navbars/NavbarAdmin2/NavbarAdmin2";
import NavbarCoordenador from "../../Navbars/NavbarCoordenador/navbarCoordenador";
import { FaSyncAlt } from "react-icons/fa";
import NavbarFormador from "../../Navbars/NavbarFormador/navbarFormador";
import Header from "../../Header";
import excelGeneratorPauta from "../pauta";

function GerirPauta(token, type) {
    const [data, setData] = useState(null);
    const [data2, setData2] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [cont, setCont] = useState(0)
    const [idUfcd, setIdUfcd] = useState(0)
    const [forum, setForum] = useState(0)
    const [forumLength, setForumLength] = useState(0)
    const [assiduidade, setAssiduidade] = useState(0)
    const [assiduidadeLength, setAssiduidadeLength] = useState(0)
    const [totalAulasSinc, setTotalAulasSinc] = useState(0)
    const [alterar, setAlterar] = useState(false)
    const params = useParams();

    const navigate = useNavigate();
    const [pauta, setPauta] = useState([])
    useEffect(() => {
        if (!idUfcd) {
            getUfcd();
        }
        if (data && data.formato !== "Presencial" && data.percurso && data.percurso.length > 0 && data.percurso[0].moodle) {
            getDisciplinaMoodle();
        }
    }, [loading, cont, idUfcd]);

    //alterar se esta a alterar pauta ou não
    function alterarPauta() {
        setAlterar(!alterar)
    }

    function getUfcd() {
        fetch(`${process.env.REACT_APP_API_URL}/ufcds/${params.id_ufcd}/trainer`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    const turma = []
                    let eventos = 0
                    result.eventos.forEach(element => {
                        const duration = DateTimeDifference(element.start, element.end)
                        eventos = eventos + (duration)
                    });
                    setTotalAulasSinc(eventos)
                    result.formandos.forEach(element => {
                        if (element.valid === "Validado" || element.valid === "Desistiu") {
                            turma.push(element)
                        }
                    });
                    let inscricaoNormal = []
                    let inscricaoExtra = []
                    turma.forEach(element => {
                        if (!element.extraNum) {
                            inscricaoNormal.push(element)
                        }
                        else inscricaoExtra.push(element)
                    });
                    const sortedInscricaoNormal = inscricaoNormal.sort((a, b) => {
                        return a.fullName.localeCompare(b.fullName);
                    });

                    const sortedInscricaoExtra = inscricaoExtra.sort((a, b) => {
                        return a.dateInscricao.getTime() - b.dateInscricao.getTime();
                    });
                    const combinedResult = [...sortedInscricaoNormal, ...sortedInscricaoExtra];
                    setData2(combinedResult.filter(item => !item.nInscricao || !item.nInscricao.some(n => n.idUfcd === params.id_ufcd)));
                    // Create a copy of the pauta array
                    const updatedPauta = [...pauta];
                    combinedResult.filter(item => !item.nInscricao || !item.nInscricao.some(n => n.idUfcd === params.id_ufcd)).forEach((element) => {
                        const nif = element.nif;
                        // Check if an entry with the same 'nif' already exists in 'pauta'
                        const isDuplicate = updatedPauta.some(item => item.nif === nif);
                        // Find the index of the matching 'idUfcd' in 'data2.pauta'
                        if (element.pauta && element.pauta.length > 0) {
                            const index = element.pauta.findIndex((item) => item.idUfcd === params.id_ufcd);
                            if (index !== -1) {
                                if (!isDuplicate) {
                                    // If it's not a duplicate and 'idUfcd' exists in 'data2.pauta', add it to 'pauta'
                                    updatedPauta.push({
                                        nif,
                                        teste: element.pauta[index].teste,
                                        media: element.pauta[index].media,
                                        assiduidade: element.pauta[index].assiduidade,
                                        presencas: element.pauta[index].presencas,
                                        forum: element.pauta[index].forum,
                                        forumTotal: element.pauta[index].forumTotal,
                                        sincrona: element.pauta[index].sincrona,
                                        assincrona: element.pauta[index].assincrona,
                                        idUfcd: params.id_ufcd,
                                        notaFinal: params.notaFinal,
                                        pra: element.pauta[index].pra
                                    });
                                }
                            }
                            else {
                                if (!isDuplicate) {
                                    // If it's not a duplicate and 'idUfcd' does not exist in 'data2.pauta', add with default values
                                    updatedPauta.push({
                                        nif,
                                        teste: [],
                                        media: 0,
                                        assiduidade: 0,
                                        presencas: 0,
                                        forum: 0,
                                        forumTotal: 0,
                                        sincrona: 0,
                                        assincrona: 0,
                                        idUfcd: params.id_ufcd,
                                        notaFinal: 0,
                                        pra: false
                                    });
                                }
                            }
                        } else {
                            if (!isDuplicate) {
                                // If it's not a duplicate and 'idUfcd' does not exist in 'data2.pauta', add with default values
                                updatedPauta.push({
                                    nif,
                                    teste: [],
                                    media: 0,
                                    assiduidade: 0,
                                    presencas: 0,
                                    forum: 0,
                                    forumTotal: 0,
                                    sincrona: 0,
                                    assincrona: 0,
                                    idUfcd: params.id_ufcd,
                                    notaFinal: 0,
                                    pra: false
                                });
                            }
                        }
                    });
                    // Update the 'pauta' array with the updatedPauta
                    setPauta(updatedPauta);
                    setLoading(false);
                    fetch(`${process.env.REACT_APP_API_URL}/moodle/cursos/${result.percurso[0].moodle[0]._id}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': localStorage.getItem(token)
                        },
                        method: 'POST',
                        body: JSON.stringify({
                            idDisciplina: result.codeMoodle
                        })
                    })
                        .then((res) => res.json())
                        .then(
                            (resultado) => {
                                if (resultado.length > 0) {
                                    setIdUfcd(resultado[0].id)
                                }
                            },
                            (error) => {
                                console.error("Error fetching data: ", error);
                                toastError("Dados de Moodle inválidos.")
                            }
                        );
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                }
            );
    }
    //Obter moodle da disciplina
    async function getDisciplinaMoodle() {
        try {
            // Fetch forum data
            const forumResponse = await fetch(`${process.env.REACT_APP_API_URL}/moodle/forumUfcd/${data.percurso[0].moodle[0]._id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                },
                method: 'POST',
                body: JSON.stringify({
                    courseId: idUfcd
                })
            });

            const forumResult = await forumResponse.json();
            setForum(forumResult);
            setForumLength(forumResult.length);

            // Fetch assiduidade data
            const assiduidadeResponse = await fetch(`${process.env.REACT_APP_API_URL}/moodle/assiduidade/${data.percurso[0].moodle[0]._id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                },
                method: 'POST',
                body: JSON.stringify({
                    courseId: idUfcd,
                })
            });

            const assiduidadeResult = await assiduidadeResponse.json();
            setAssiduidadeLength(assiduidadeResult.length);
            setAssiduidade(assiduidadeResult);
        } catch (error) {
            console.error("Error fetching data: ", error);
            // Handle the error as needed, such as displaying an error message to the user
        }
    }
    //Obter forum do user moodle
    async function getForumUserMoodle() {
        try {
            const pautaPromises = data2.map(async (user) => {
                try {
                    // Fetch user forum data
                    const userForumData = await fetch(`${process.env.REACT_APP_API_URL}/moodle/users/${data.percurso[0].moodle[0]._id}`, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'authorization': localStorage.getItem(token)
                        },
                        body: JSON.stringify({
                            nif: user.nif
                        })
                    }).then((res) => res.json());

                    if (!userForumData || userForumData.length === 0) {
                        return {
                            forum: 0,
                            forumTotal: 0,
                        };
                    }

                    // Map over forum discussions and fetch forum data for each
                    const forumPromises = forum.map(async (element) => {
                        // Fetch forum data for the current discussion
                        const forumData = await fetch(`${process.env.REACT_APP_API_URL}/moodle/forum/${data.percurso[0].moodle[0]._id}`, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'authorization': localStorage.getItem(token)
                            },
                            body: JSON.stringify({
                                userid: userForumData[0].id, // replace with the actual userid from userForumData
                                discussion: element.id
                            })
                        }).then((res) => res.json());

                        // Check if forumData is not empty
                        return forumData.length > 0 ? 1 : 0;
                    });

                    // Wait for all forum promises to resolve
                    const forumResults = await Promise.all(forumPromises);

                    // Calculate the sum of forum results
                    const forumCount = forumResults.reduce((sum, value) => sum + value, 0);

                    // Return forum count and total forum count
                    return {
                        forum: forumCount,
                        forumTotal: forum.length,
                    };
                } catch (error) {
                    console.error('Error fetching user forum data:', error);
                    return {
                        forum: 0,
                        forumTotal: 0,
                    };
                }
            });

            // Wait for all user promises to resolve
            const pautaResults = await Promise.all(pautaPromises);

            // Update pauta array with forum counts
            pautaResults.forEach((result, i) => {
                pauta[i].forum = result.forum;
                pauta[i].forumTotal = result.forumTotal;
            });
        } catch (error) {
            console.error('Error fetching forum data:', error);
            // Handle error here
        }
    }

    async function getAssiduidadeUserMoodle() {
        try {
            const pautaPromises = data2.map(async (user) => {
                try {
                    // Fetch user assiduidade data
                    const userAssiduidadeData = await fetch(`${process.env.REACT_APP_API_URL}/moodle/users/${data.percurso[0].moodle[0]._id}`, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'authorization': localStorage.getItem(token)
                        },
                        body: JSON.stringify({
                            nif: user.nif
                        })
                    }).then(res => res.json());

                    const assiduidadeResults = [];

                    for (let j = 0; j < assiduidade.length; j++) {
                        const element = assiduidade[j];
                        const assiduidadeDataLength = await fetch(`${process.env.REACT_APP_API_URL}/moodle/assiduidadeFormandoLength/${data.percurso[0].moodle[0]._id}`, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'authorization': localStorage.getItem(token)
                            },
                            body: JSON.stringify({
                                userid: userAssiduidadeData[0].id,
                                coursemoduleId: idUfcd,
                                topicoid: element.id
                            })
                        }).then(res => res.json());

                        const assiduidadeCount = assiduidadeDataLength.length > 0 ? 1 : 0;
                        assiduidadeResults.push(assiduidadeCount);
                        /*  // Fetch assiduidade data for the current element
                         const assiduidadeData = await fetch(`${process.env.REACT_APP_API_URL}/moodle/assiduidadeFormando/${data.percurso[0].moodle[0]._id}`, {
                             method: 'POST',
                             headers: {
                                 'Accept': 'application/json',
                                 'Content-Type': 'application/json',
                                 'authorization': localStorage.getItem(token)
                             },
                             body: JSON.stringify({
                                 userid: userAssiduidadeData[0].id,
                                 coursemoduleId: element.id
                             })
                         }).then(res => res.json());
 
                         // Check if assiduidadeData is not empty
                         const assiduidadeCount = assiduidadeData.length > 0 ? 1 : 0;
                         assiduidadeResults.push(assiduidadeCount); */
                    }

                    // Calculate the sum of assiduidade results
                    const assiduidadeCount = assiduidadeResults.reduce((sum, value) => sum + value, 0);
                    // Return the assiduidade count and total assiduidade count
                    return {
                        assiduidade: Math.floor((assiduidadeCount / assiduidade.length) * 100),
                        assiduidadeTotal: assiduidade.length
                    };
                } catch (error) {
                    console.error('Error fetching assiduidade data for user:', user, error);
                    // Return default values or handle the error as needed
                    return {
                        assiduidade: 0,
                        assiduidadeTotal: 0
                    };
                }
            });

            // Wait for all user promises to resolve
            const pautaResults = await Promise.all(pautaPromises);

            // Update pauta array with assiduidade counts
            pautaResults.forEach((result, i) => {
                pauta[i].assiduidade = result.assiduidade;
                pauta[i].assiduidadeTotal = result.assiduidadeTotal;
            });
        } catch (error) {
            console.error('Error fetching assiduidade data:', error);
            // Handle error here
        }
    }
    function getAssincronaseUser() {
        for (let index = 0; index < data2.length; index++) {
            let horasAssincronas = 0;
            fetch(`${process.env.REACT_APP_API_URL}/moodle/users/${data.percurso[0].moodle[0]._id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                },
                method: 'POST',
                body: JSON.stringify({
                    nif: data2[index].nif
                })
            })
                .then((userResponse) => {
                    if (!userResponse.ok) {
                        throw new Error("Error fetching user data");
                    }
                    return userResponse.json();
                })
                .then((user) => {
                    if (user && user.length > 0) {

                        return fetch(`${process.env.REACT_APP_API_URL}/moodle/assincronas/${data.percurso[0].moodle[0]._id}`, {
                            headers: {
                                'authorization': localStorage.getItem(token),
                                'Content-Type': 'application/json'
                            },
                            method: 'POST',
                            body: JSON.stringify({
                                userid: user[0].id,
                                courseId: idUfcd
                            })
                        })
                            .then((testeResponse) => {
                                if (!testeResponse.ok) {
                                    throw new Error("Error fetching test data");
                                }
                                return testeResponse.json();
                            })
                            .then((teste) => {
                                if (teste) {
                                    teste.forEach(element => {
                                        horasAssincronas += element.timespent;
                                    });
                                }
                                pauta[index].assincrona = Math.floor(horasAssincronas / 3600);
                            })
                            .catch((error) => {
                                console.error("Error fetching data: ", error);
                            });
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data: ", error);
                });
        }
    }


    function gettestesEUser() {
        let testesFiltered;
        fetch(`${process.env.REACT_APP_API_URL}/moodle/testes/${data.percurso[0].moodle[0]._id}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
            method: 'POST',
            body: JSON.stringify({
                idDisciplina: idUfcd
            })
        })
            .then((response) => response.json()) // Parse response as JSON
            .then((result) => {
                testesFiltered = result;
                fetch(`${process.env.REACT_APP_API_URL}/testes`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': localStorage.getItem(token)
                    },
                    method: 'PATCH',
                    body: JSON.stringify({
                        idUfcd: params.id_ufcd,
                        testes: result
                    })
                });
            })
            .then((testes) => {
                return Promise.all(data2.map((element, index) => {
                    return fetch(`${process.env.REACT_APP_API_URL}/moodle/users/${data.percurso[0].moodle[0]._id}`, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'authorization': localStorage.getItem(token)
                        },
                        method: 'POST',
                        body: JSON.stringify({
                            nif: element.nif
                        })
                    })
                        .then(res => res.json())
                        .then(async (user) => {
                            if (user.length > 0) {
                                const results = [];
                                for (const test of testesFiltered) {
                                    try {
                                        const testeResponse = await fetch(`${process.env.REACT_APP_API_URL}/moodle/resultadosTestes/${data.percurso[0].moodle[0]._id}`, {
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'authorization': localStorage.getItem(token),
                                            },
                                            method: 'POST',
                                            body: JSON.stringify({
                                                userid: user[0].id,
                                                assignment: test.oldid
                                            })
                                        });
                                        if (!testeResponse.ok) {
                                            throw new Error("Error fetching test data");
                                        }
                                        const teste = await testeResponse.json();
                                        let resultadofinal = 0;
                                        if (teste && teste[0] && teste[0].rawgrade) {
                                            resultadofinal = Math.round((teste[0].rawgrade * 100 / teste[0].rawgrademax) * 0.20);
                                        }
                                        results.push(resultadofinal);
                                    } catch (error) {
                                        console.error("Error fetching or processing test data:", error);
                                    }
                                }
                                let i = 0;
                                pauta[index].teste = results.slice();
                                let soma = 0;
                                pauta[index].teste.forEach(teste => {
                                    soma += teste;
                                });
                                pauta[index].media = Math.round(soma / results.length);
                            }
                        })
                        .catch((error) => {
                            console.error("Error fetching user data:", error);
                        });
                }));
            })
            .then(() => {
                // All fetch requests completed successfully
            })
            .catch((error) => {
                console.error("Error fetching data: ", error);
                toastError("Error fetching data: " + error.message);
            });
    }
    function fecharFormadorPauta(e) {
        if (e.target.checked === true) {
            fetch(`${process.env.REACT_APP_API_URL}/percurso/formador/pauta/${params.id_ufcd}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                },
                method: 'POST',
                body: JSON.stringify({
                    fechoFormador: true
                })
            })
                .then(res => res.json())
                .then((result) => {
                    setCont(cont + 1)
                })
        }
        else {
            fetch(`${process.env.REACT_APP_API_URL}/percurso/formador/pauta/${params.id_ufcd}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                },
                method: 'POST',
                body: JSON.stringify({
                    fechoFormador: false
                })
            })
                .then(res => res.json())
                .then((result) => {
                    setCont(cont + 1)
                })
        }
    }

    function fecharCoordenadorPauta(e) {
        if (e.target.checked === true) {
            fetch(`${process.env.REACT_APP_API_URL}/percurso/Coordenador/pauta/${params.id_ufcd}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                },
                method: 'POST',
                body: JSON.stringify({
                    fechoCoordenador: true
                })
            })
                .then(res => res.json())
                .then((result) => {
                    setCont(cont + 1)
                })
        }
        else {
            fetch(`${process.env.REACT_APP_API_URL}/percurso/Coordenador/pauta/${params.id_ufcd}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem(token)
                },
                method: 'POST',
                body: JSON.stringify({
                    fechoCoordenador: false
                })
            })
                .then(res => res.json())
                .then((result) => {
                    setCont(cont + 1)
                })
        }
    }

    function DateTimeDifference(start, end) {
        // Calculate the difference in milliseconds
        const a = new Date(start);
        const b = new Date(end);

        const differenceInMilliseconds = b - a;

        // Convert milliseconds to hours
        const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);

        return differenceInHours
    };
    async function getPresencas() {
        setLoading(true);
        let completedRequests = 0; // Track the number of completed fetch requests
        const totalRequests = data2.length; // Total number of fetch requests to be made

        const count = [];
        try {
            // Fetch data for all users
            const response = await fetch(`${process.env.REACT_APP_API_URL}/calendar/ufcds/${params.id_ufcd}`, {
                headers: {
                    'authorization': localStorage.getItem(token)
                }
            });
            const result = await response.json();

            // Process the fetched data for each user
            data2.forEach((user, index) => {
                if (data.percurso[0].assinaturas) {
                    result.forEach(element => {
                        element.presencas.forEach(presenca => {
                            if (presenca.formando === user._id) {
                                count.push(user._id);
                                pauta[index].sincrona = count.length;
                            }
                        });
                    });
                    // Increment completed fetch requests
                    completedRequests++;
                } else {
                    let sessões = 0;
                    result.forEach(element => {
                        if (element.presencas.some(presenca => presenca.formando === user._id)) {
                            const duration = DateTimeDifference(element.start, element.end);
                            sessões += duration; // Use += to accumulate the total duration
                        }
                    });
                    pauta[index].sincrona = sessões;
                    sessões = 0;
                    // Increment completed fetch requests
                    completedRequests++;
                }
            });

            // Check if all fetch requests have completed
            if (completedRequests === totalRequests) {
                setLoading(false); // Set loading to false
                postDadosTotal();
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
            setLoading(false); // Set loading to false in case of error
        }
    }

    async function postDadosTotal() {
        try {
            for (let i = 0; i < data2.length; i++) {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/formandos/${data2[i]._id}/pauta`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': localStorage.getItem(token)
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        idUfcd: params.id_ufcd,
                        pauta: pauta[i]
                    })
                });

                if (!response.ok) {
                    // Handle non-successful responses (e.g., 404 Not Found, 500 Internal Server Error)
                    console.error(`Failed to update data for element at index ${i}. Status: ${response.status}`);
                    // You may want to throw an error or handle it in another way
                }
                const result = await response.json();
                setAlterar(false);
                getDisciplinaMoodle()

            }
        } catch (error) {
            console.error("Error during fetch:", error);
            // Handle other errors (e.g., network issues, JSON parsing)
            // You may want to throw an error or handle it in another way
        }
    }

    function updateForumTotal(newValue) {
        const updatedPauta = [...pauta]; // Create a copy of the pauta array
        updatedPauta.forEach((item) => {
            item.forumTotal = newValue;
        });
        setPauta(updatedPauta); // Update the state with the updated array
    }

    async function getInfoMoodle() {

        if (!idUfcd) {
            toastError("Dados de Moodle não encontrados.");
        } else {
            if (data2 && forum) {
                try {
                    setLoading(true);

                    const promises = [];
                    /* promises.push(new Promise(resolve => setTimeout(resolve, 2000))); // Placeholder promise for demonstration */

                    promises.push(gettestesEUser());
                    promises.push(getForumUserMoodle());
                    promises.push(getAssiduidadeUserMoodle());
                    promises.push(getAssincronaseUser());

                    // Wait for all promises to resolve
                    await Promise.all(promises);

                    // Call postDadosTotal after all promises have resolved
                    await postDadosTotal();
                    await postDadosTotal();
                    await setLoading(false);
                    await toastSuccess("Alterado com Sucesso");

                } catch (error) {
                    console.error("Error in getInfoMoodle:", error);
                    // Handle the error as needed
                    setLoading(false);
                }
            }
        }
    }

    const getStatus = (index, pauta, data, item, params) => {
        if (pauta[index] && data.fechoFormador && item.valid !== "Desistiu" && !item.nInscricao.some(subItem => subItem.idUfcd === params.id_ufcd) && !item.equivalencia.some(subItem => subItem.idUfcd === params.id_ufcd) && !item.desistencia.some(subItem => subItem.idUfcd === params.id_ufcd)) {
            if (pauta[index].notaFinal) {
                return pauta[index].notaFinal >= 9.5 && pauta[index].assiduidade >= 90 ? "Aprovado" : "Reprovado";
            } else {
                const media = pauta[index].media - (data.formato !== "Presencial" ? Math.max(pauta[index].forumTotal - pauta[index].forum, 0) : 0);
                return media >= 9.5 ? "Aprovado" : "Reprovado";
            }
        } else if (pauta[index] && data.fechoFormador && item.valid !== "Desistiu") {
            return "Desistiu";
        }
        else {
            if (item.nInscricao.some(subItem => subItem.idUfcd === params.id_ufcd)) {
                return "Não inscrito";
            } else if (item.equivalencia.some(subItem => subItem.idUfcd === params.id_ufcd)) {
                return "Disciplina Realizada";
            }
            else if (item.desistencia.some(subItem => subItem.idUfcd === params.id_ufcd)) {
                return "Desistiu";
            }
            else {
                return "Em Avaliação";
            }
        }
    };
    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";
    return (
        <>
            <main className={(token === "coord" || token === "user") ? "min-h-screen grid-cols-7 mx-auto md:grid xl:grid-cols-10" : "min-h-screen"}>
                {(token !== "coord" && token !== "user") && (<div className="w-full">
                    {token === "admin1" && <NavbarAdmin1 currentPage={type} />}
                    {token === "admin2" && <NavbarAdmin2 currentPage={type} />}
                </div>)}
                {token === "coord" && (<div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    <NavbarCoordenador currentPage={type} />
                </div>)}
                {token === "user" && (<div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    <NavbarFormador currentPage={type} />
                </div>)}
                <div className={token === "coord" ? "relative w-full h-full col-span-5 xl:col-span-8" : token === "user" ? "relative h-full col-span-5 mx-4 xl:col-span-8" : undefined}>
                    <div className="flex-grow mt-8">
                        <button onClick={() => navigate(-1)} className="back-button-div-noNavbar">
                            <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                        </button>
                        {(token === "coord" || token === "user") && <Header sideMenu={"true"} />}

                        {data ? <h1 className="mx-4 mt-8 title sm:mt-0">{data.codeUfcd} - {data.name}</h1> : ""}

                        <h2 className="subtitle">Pauta</h2>
                        {data.percurso[0].type !== "Presencial" && (
                            <button className="mr-2 button-geral w-32 " onClick={() => { getDisciplinaMoodle(); getInfoMoodle(); setCont(cont + 1) }}>
                                <div className="flex items-center tooltip w-full" data-tip="Sicronizar com o Moodle">
                                    <span  ><FaSyncAlt /></span>
                                    <span className=" w-full text-center">Moodle</span>
                                </div>
                            </button>
                        )}
                        <button className=" button-geral w-32 " onClick={() => { getPresencas(); setCont(cont + 1) }}>
                            <div className="flex items-center tooltip w-full " data-tip="Sincronizar Presenças com a Plataforma">
                                <span ><FaSyncAlt /></span>
                                <span className=" w-full text-center">Presenças</span>
                            </div>
                        </button>


                        <div className="mt-4 table-pauta ">
                            <table className="my-table">
                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="p-2 min-w-[140px] border border-white">Nome Completo</th>
                                        {(data && data.testes.length > 0) && data.testes.map((item, index2) => (
                                            <th key={index2} className="p-2 min-w-[80px] border border-white">{item.name ? item.name : `Teste ${index2 + 1}`}</th>
                                        ))}
                                        <th className="p-2 min-w-[80px] border border-white">Média Testes</th>
                                        {data.percurso[0] && data.percurso[0].modalidade === "Cursos de Educação e Formação de Adultos" && (
                                            <th className="p-2 min-w-[80px] border border-white">PRA</th>
                                        )}
                                        <th className="p-2 min-w-[150px] border border-white">Atividades Realizadas/Total Atividades</th>
                                        {data.formato !== "Presencial" && <th className="p-2 min-w-[80px] border border-white">Penalização</th>}
                                        {data.formato !== "Presencial" && <th className="p-2 min-w-[80px] border border-white">Assiduidade Assíncrona(%)</th>}
                                        {data.formato !== "Presencial" && <th className="p-2 min-w-[80px] border border-white">Horas Síncronas</th>}
                                        {data.formato !== "Presencial" && <th className="p-2 min-w-[80px] border border-white">Horas Assíncronas</th>}
                                        <th className="p-2 min-w-[80px] border border-white">Horas Totais</th>
                                        {data && (data.formato === "Online" || data.formato === "Misto") && <th className="p-2 min-w-[80px] border border-white">Dedicação à Disciplina</th>}
                                        <th className="p-2 min-w-[80px] border border-white">Nota Final</th>
                                        <th className="p-2 min-w-[80px] border border-white">Avaliação</th>
                                    </tr>
                                </thead>
                                {data2.length > 0 ? (
                                    <tbody className="text-main-color">
                                        {data2.map((item, index) => (<>
                                            {alterar === false ? <tr key={index}>
                                                <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}>{item.fullName}</td>
                                                {data.testes.map((item2, index2) => (
                                                    <td key={index2} id={"teste" + index2 + item.nif} className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"} >{pauta[index] && pauta[index].teste.length > 0 ? parseFloat(pauta[index].teste[index2]) : 0}</td>
                                                ))}
                                                <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}>{pauta[index] && pauta[index].media ? (pauta[index].media) : 0}</td>
                                                {data.percurso[0] && data.percurso[0].modalidade === "Cursos de Educação e Formação de Adultos" && (
                                                    <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}> <input type="checkbox" defaultChecked={pauta[index] ? pauta[index].pra : false} readOnly /> </td>
                                                )}
                                                <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}>{pauta[index] && (pauta[index].forum || pauta[index].forumTotal) ? pauta[index].forum + "/" + pauta[index].forumTotal : 0}</td>
                                                {data.formato !== "Presencial" && <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}>{pauta[index] && (pauta[index].forum || pauta[index].forumTotal) ? (pauta[index].forumTotal - pauta[index].forum) * -1 : 0}</td>}
                                                {data.formato !== "Presencial" && <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}>{pauta[index] && pauta[index].assiduidade ? pauta[index].assiduidade : 0}
                                                    %</td>}
                                                {data && data.formato !== "Presencial" && <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}>{pauta[index] && pauta[index].sincrona ? pauta[index].sincrona : 0}h</td>}
                                                {data.formato !== "Presencial" && <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}>{pauta[index] && pauta[index].assiduidade ? Math.floor((pauta[index].assiduidade / 100) * (data.cargaHoraria - totalAulasSinc)) : 0}h</td>}
                                                <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}>{pauta[index] ? (((pauta[index].sincrona ? pauta[index].sincrona : 0) + Math.floor(((pauta[index].assiduidade / 100) * (data.cargaHoraria - totalAulasSinc)) ? (pauta[index].assiduidade / 100) * (data.cargaHoraria - totalAulasSinc) : 0)) <= data.cargaHoraria) ? (pauta[index].sincrona ? pauta[index].sincrona : 0) + (Math.floor((pauta[index].assiduidade / 100) * (data.cargaHoraria - totalAulasSinc)) ? Math.round((pauta[index].assiduidade / 100) * (data.cargaHoraria - totalAulasSinc)) : 0) : data.cargaHoraria : 0}h</td>
                                                {data.formato !== "Presencial" && <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}>{pauta[index] && pauta[index].assincrona ? pauta[index].assincrona : 0}h</td>}
                                                {data.formato !== "Presencial" ? <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}>
                                                    {pauta[index] && Math.round(pauta[index].media - Math.max(pauta[index].forumTotal - pauta[index].forum), 0)}
                                                </td> : <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}>
                                                    {pauta[index] && pauta[index].notaFinal}
                                                </td>}
                                                <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}>
                                                    {pauta[index] && data.fechoFormador &&
                                                        getStatus(index, pauta, data, item, params)}
                                                </td></tr>
                                                : <tr key={index}>
                                                    <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}>{item.fullName}</td>
                                                    {data.testes.map((item2, index2) => (
                                                        <td key={index2} id={"teste" + index2 + item.nif} className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}>
                                                            <input
                                                                className="input-field"
                                                                type="number"
                                                                min={0}
                                                                max={20}
                                                                defaultValue={pauta[index].teste[index2]}
                                                                onChange={(e) => {
                                                                    pauta[index].teste[index2] = parseInt(e.target.value);
                                                                    const sum = pauta[index].teste.reduce((acc, value) => acc + value, 0);
                                                                    const media = Math.round(sum / pauta[index].teste.length);
                                                                    pauta[index].media = media;
                                                                    setPauta([...pauta]);
                                                                }}
                                                            />
                                                        </td>
                                                    ))}
                                                    <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}>{pauta[index] && (parseFloat(pauta[index].media).toFixed(2))}</td>

                                                    {data.percurso[0] && data.percurso[0].modalidade === "Cursos de Educação e Formação de Adultos" && (
                                                        <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}> <input type="checkbox" defaultChecked={pauta[index] && pauta[index].pra} onChange={() => pauta[index].pra = !pauta[index].pra} /> </td>
                                                    )}
                                                    <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}><input className="input-field" type="number" min={0} max={pauta[index] && pauta[index].forumTotal} defaultValue={pauta[index] && pauta[index].forum} onChange={(e) => { pauta[index].forum = parseInt(e.target.value); setCont(cont + 1) }} />/<input className="input-field" type="number" min={0} max={99} defaultValue={pauta[index] && pauta[index].forumTotal} onChange={(e) => { updateForumTotal(e.target.value) }} /></td>
                                                    {data.formato !== "Presencial" && <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}>{pauta[index] ? (pauta[index].forumTotal - pauta[index].forum) * -1 : ""}</td>}

                                                    {data.formato !== "Presencial" && <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}><input className="input-field" type="number" min={0} max={100} defaultValue={pauta[index] && pauta[index].assiduidade} onChange={(e) => pauta[index].assiduidade = parseInt(e.target.value)} /></td>}

                                                    {data.formato !== "Presencial" && <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}><input className="input-field" type="number" min={0} max={data.cargaHoraria} defaultValue={pauta[index] && pauta[index].sincrona} onChange={(e) => pauta[index].sincrona = parseInt(e.target.value)} /></td>}
                                                    {data.formato !== "Presencial" && <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}>{pauta[index] && pauta[index].assiduidade ? Math.floor((pauta[index].assiduidade / 100) * (data.cargaHoraria - totalAulasSinc)) : 0}h</td>}
                                                    {data.formato !== "Presencial" ? (
                                                        <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}>
                                                            {pauta[index] && parseInt(pauta[index].sincrona) + Math.floor(((pauta[index].assiduidade / 100) * (data.cargaHoraria - totalAulasSinc)))}h
                                                        </td>
                                                    ) : (
                                                        <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}>
                                                            {pauta[index] && parseInt(pauta[index].sincrona)}h
                                                        </td>
                                                    )}
                                                    {data.formato !== "Presencial" && <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}><input className="input-field" type="number" min={0} max={data.cargaHoraria} defaultValue={pauta[index] && pauta[index].assincrona} onChange={(e) => pauta[index].assincrona = parseInt(e.target.value)} /></td>}
                                                    {data.formato !== "Presencial" ?
                                                        <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}>
                                                            {pauta[index] && (pauta[index].media - (data.formato !== "Presencial" ? Math.max(pauta[index].forumTotal - pauta[index].forum) : 0), 0)}
                                                        </td>
                                                        : <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}>
                                                            <input
                                                                className="input-field"
                                                                type="number"
                                                                min={0}
                                                                max={20}
                                                                defaultValue={pauta[index].notaFinal}
                                                                onChange={(e) => {
                                                                    pauta[index].notaFinal = parseInt(e.target.value);
                                                                    setPauta([...pauta]);
                                                                }}
                                                            />
                                                        </td>}
                                                    <td className={(item.valid === "Desistiu" || item.nInscricao.some(item => item.idUfcd === params.id_ufcd) || item.equivalencia.some(item => item.idUfcd === params.id_ufcd)) ? "bg-red-300 border border-white" : "border border-white"}>
                                                        {pauta[index] && data.fechoFormador &&
                                                            getStatus(index, pauta, data, item, params)}
                                                    </td>
                                                </tr>
                                            }
                                        </>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody />
                                )}
                            </table>
                        </div>

                        <div className="grid mt-8 sm:grid-cols-3 text-text-main-color">
                            <div className="flex flex-row-reverse items-center justify-center">
                                Disciplina finalizada{data && new Date(data.dateEnd) < new Date() ?
                                    <label className="label">
                                        <input id="check" type="checkbox" checked readOnly />
                                        <div className="cursor-not-allowed checkmark"></div>
                                    </label>
                                    : <label className="label">
                                        <input id="check" type="checkbox" disabled="disabled" />
                                        <div className="cursor-not-allowed checkmark"></div>
                                    </label>}
                            </div>

                            {(token === "user") || (token === "coord")|| (token === "admin1")|| (token ==="admin2") ? <div className="flex flex-row-reverse items-center justify-center">Fecho Formador{data && new Date(data.dateEnd) < new Date() ?
                                <>{!data.fechoFormador ?
                                    <label className="label">
                                        <input id="check" type="checkbox" onClick={(e) => { fecharFormadorPauta(e) }} />
                                        <div className="cursor-pointer checkmark"></div>
                                    </label> :
                                    <label className="label">
                                        <input id="check" type="checkbox" defaultChecked="true" onClick={(e) => { fecharFormadorPauta(e) }} />
                                        <div className="cursor-pointer checkmark"></div>
                                    </label>
                                }</> : <label className="label">
                                    <input id="check" type="checkbox" disabled="disabled" />
                                    <div className="cursor-not-allowed checkmark"></div>
                                </label>}
                            </div>
                                :
                                <div className="flex flex-row-reverse items-center justify-end sm:justify-center">
                                    Fecho Formador{data && data.fechoFormador === true ?
                                        <label className="label">
                                            <input type="checkbox" disabled="disabled" checked readOnly />
                                            <div className="cursor-not-allowed checkmark"></div>
                                        </label>
                                        :
                                        <label className="label">
                                            <input type="checkbox" disabled="disabled" />
                                            <div className="cursor-not-allowed checkmark"></div>
                                        </label>}
                                </div>}
                            {token === "coord" ? <div className="flex flex-row-reverse items-center justify-center">Fecho Coordenador {data && data.fechoFormador && new Date(data.dateEnd) < new Date() ?
                                <>{!data.fechoCoordenador ?
                                    <label className="label">
                                        <input id="check" type="checkbox" onClick={(e) => { fecharCoordenadorPauta(e) }} />
                                        <div className="cursor-pointer checkmark"></div>
                                    </label> :
                                    <label className="label">
                                        <input id="check" type="checkbox" defaultChecked="true" onClick={(e) => { fecharCoordenadorPauta(e) }} />
                                        <div className="cursor-pointer checkmark"></div>
                                    </label>
                                }</> : <label className="label">
                                    <input id="check" type="checkbox" disabled="disabled" />
                                    <div className="cursor-not-allowed checkmark"></div>
                                </label>}
                            </div>
                                :
                                <div className="flex flex-row-reverse items-center sm:justify-center">
                                    Fecho Coordenador{data && data.fechoCoordenador === true ?
                                        <label className="label">
                                            <input type="checkbox" disabled="disabled" checked readOnly />
                                            <div className="cursor-not-allowed checkmark"></div>
                                        </label> :
                                        <label className="label">
                                            <input type="checkbox" className="checkbox-fechos" disabled="disabled" />
                                            <div className="cursor-not-allowed checkmark"></div>
                                        </label>}
                                </div>}
                        </div>
                        {alterar ? <div className="mt-4">
                            <button className="inline mr-2 button-cancelar" onClick={alterarPauta}>Cancelar</button>
                            <button className="mr-2 button-geral" onClick={() => postDadosTotal()}>Confirmar</button>
                        </div> :
                            <button className="mt-4 button-geral w-32" onClick={alterarPauta}>Alterar Pauta</button>
                        }
                        <div className="my-8">
                            {/* {data && data.fechoCoordenador === true ?  */}
                            <div>
                                <button className="button-geral w-32" onClick={() => excelGeneratorPauta(data, data2, totalAulasSinc)}>Exportar Excel</button>
                            </div>
                            {/*  :
                                <div>
                                    <button className="button-disabled w-32">Exportar Excel</button>
                                </div>} */ }
                        </div>
                    </div>
                </div>
            </main >
            <Footer />
        </>
    );
}

export default GerirPauta;

