import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import Nacoes from '../../FormChecks/Nacoes/nacoes';
import ArrayNations from '../../ArraysExcel/arrayNacoes';
import ArrayHabilitacoes from '../../ArraysExcel/arrayHabilitacoes';
import ArraySituacaoEmprego from '../../ArraysExcel/arraySituacaoEmprego';
import ArraySituacaoParticipante from '../../ArraysExcel/arraySituacaoParticipante';
import ArrayNivel from '../../ArraysExcel/arrayNivel';
import AlterarFormato from '../../FormChecks/formatDates/foramatDates';


async function ExcelFormandosSimples(formandos) {
    // Variaveis de estilização
    const alignment = {
        horizontal: 'center',
        vertical: 'middle',
        wrapText: true,
    };

    // Inicio Excel
    const workbook = new ExcelJS.Workbook();

    function getColumnLetterFromIndex(index) {
        const columnLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let columnName = '';

        while (index > 0) {
            const remainder = (index - 1) % 26;
            columnName = columnLetters[remainder] + columnName;
            index = Math.floor((index - 1) / 26);
        }

        return columnName;
    }
    //Define largura de varias colunas
    function setMultipleColWidth(worksheet, startCol, endCol, width) {
        for (let col = startCol; col <= endCol; col++) {
            worksheet.getColumn(getColumnLetterFromIndex(col)).width = width;
        }
    }
    function referecias() {
        //INICIO DA PÁGINA FORMADORES
        const worksheet = workbook.addWorksheet('Referencias');
        setMultipleColWidth(worksheet, 1, 28, 20)

        const arrayNations = ArrayNations()
        for (let index = 0; index <= arrayNations.length; index++) {
            const cell = worksheet.getCell(`A${index}`);
            cell.value = arrayNations[index];
        }

        const arrayHabilitacoes = ArrayHabilitacoes()
        for (let index = 0; index <= arrayHabilitacoes.length; index++) {
            const cell = worksheet.getCell(`B${index}`);
            cell.value = arrayHabilitacoes[index];
        }

        const arraySituacaoEmprego = ArraySituacaoEmprego()
        for (let index = 0; index <= arraySituacaoEmprego.length; index++) {
            const cell = worksheet.getCell(`C${index}`);
            cell.value = arraySituacaoEmprego[index];
        }

        const arraySituacaoParticipante = ArraySituacaoParticipante()
        for (let index = 0; index <= arraySituacaoParticipante.length; index++) {
            const cell = worksheet.getCell(`D${index}`);
            cell.value = arraySituacaoParticipante[index];
        }

        const arrayNivel = ArrayNivel()
        for (let index = 0; index <= arrayNivel.length; index++) {
            const cell = worksheet.getCell(`E${index}`);
            cell.value = arrayNivel[index];
        }
    }


    function dadosFormandos() {
        //INICIO DA PÁGINA FORMADORES
        const worksheet = workbook.addWorksheet('Formandos');
        setMultipleColWidth(worksheet, 1, 14, 30)
        worksheet.getRow(1).height = 30;  
        const sortedData = formandos.slice().sort((a, b) => a.fullName - b.fullName);
        const jsonData = sortedData.map(element => ({
            Curso: element.percurso[0].name,
            Turma: element.percurso[0].name,
            DataInicio: AlterarFormato(element.percurso[0].dateBegin),
            DataFim: AlterarFormato(element.percurso[0].dateEnd),
            NomeCompleto: element.fullName,
            DataNascimento: AlterarFormato(element.birthDate),
            Ntelemovel: element.cellNumber,
            Email: element.email,
            Genero: element.gender,
            Naturalidade: element.parish,
            Localidade: element.locality,
            Concelho: element.county,
            Morada: element.address,
            CodigoPostal: element.postalCode,
            Nacionalidade: element.nationality,
            TipoDoc: element.documentType,
            NumeroDoDocumento: element.idNumber,
            ValidadeDoc: AlterarFormato(element.idExpiration),
            NifEmpresa: element.nifEmployer,
            NIF: element.nif,
            Niss: element.niss,
            ProvemCQ: element.CQEP,
            NomeCQ: element.originCQEP,
            GrauAcademico: element.academicDegree,
            SituacaoLaborar: element.employmentSituation,
            Comentario: element.comentario,
            DataDesemprego:element.employmentSituationDate? element.employmentSituationDate: "",
            BeneficiarioSitSocial: element.beneficiarySS,
            Estado:element.valid,
        }));
        function colocarSimNao(place, value) {
            worksheet.dataValidations.add(place._address, {
                type: 'list',
                allowBlank: false,
                formulae: ['"S - Sim, N - Não"'],
                showErrorMessage: true,
                errorStyle: 'error',
                error: 'Valor Inválido',
            });
            if (value === "Sim") {
                place.value = "S - Sim"
            }
            else if (value === "Não") {
                place.value = "N - Não"
            }
        }

        function colocarGenero(place, value) {
            worksheet.dataValidations.add(place._address, {
                type: 'list',
                allowBlank: false,
                formulae: ['"M - Masculino, F - Feminino"'],
                showErrorMessage: true,
                errorStyle: 'error',
                error: 'Valor Inválido',
            });
            if (value === "Masculino") {
                place.value = "M - Masculino"
            }
            else if (value === "Feminino") {
                place.value = "F - Feminino"
            }
        }

      
        function colocarHabilitacoes(place, value) {
            worksheet.dataValidations.add(place._address, {
                type: 'list',
                allowBlank: false,
                formulae: ['Referencias!$B$1:$B$12'],
                showErrorMessage: true,
                errorStyle: 'error',
                error: 'Valor Inválido',
            });
            if (value === "Não sabe ler nem escrever") {
                place.value = "15 - Não sabe ler nem escrever"
            }
            if (value === "Menos de 4 anos escolaridade" || value === "< 4 anos escolaridade") {
                place.value = "8 - < 4 anos escolaridade"
            }
            if (value === "1º ciclo (4º ano)") {
                place.value = "2 - 1º ciclo (4º ano)"
            }
            if (value === "2º ciclo (6º ano)") {
                place.value = "3 - 2º ciclo (6º ano)"
            }
            if (value === "3º ciclo (9º ano)") {
                place.value = "4 - 3º ciclo (9º ano)"
            }
            if (value === "Ensino Secundário") {
                place.value = "5 - Ensino Secundário"
            }
            if (value === "Ensino Pós-Secundário não superior (ex CET Cursos de Especialização Tecnológica)") {
                place.value = "16 - Ensino Pós-Secundário não superior (ex CET Cursos de Especialização Tecnológica)"
            }
            if (value === "Cursos superiores de curta duração (ex TeSP Cursos Técnicos Superiores Profissionais)") {
                place.value = "17 - Cursos superiores de curta duração (ex TeSP Cursos Técnicos Superiores Profissionais)"
            }
            if (value === "Bacharelato") {
                place.value = "14 - Bacharelato"
            }
            if (value === "Licenciatura") {
                place.value = "18 - Licenciatura"
            }
            if (value === "Licenciatura pré-Bolonha") {
                place.value = "19 - Licenciatura pré-Bolonha"
            }
            if (value === "Mestrado") {
                place.value = "12 - Mestrado"
            }
            if (value === "Mestrado Pré-Bolonha") {
                place.value = "21- Mestrado Pré-Bolonha"
            }
            if (value === "Doutoramento") {
                place.value = "9 - Doutoramento"
            }
        }

        function colocarSituacaoEmprego(place, value) {
            worksheet.dataValidations.add(place._address, {
                type: 'list',
                allowBlank: false,
                formulae: ['Referencias!$C$1:$B$7'],
                showErrorMessage: true,
                errorStyle: 'error',
                error: 'Valor Inválido',
            });
            if (value === "Desempregados à procura de novo emprego - DLD") {
                place.value = "8 - Desempregados à procura de novo emprego - DLD"
            }
            if (value === "Desempregados à procura de novo emprego - Não DLD") {
                place.value = "7 - Desempregados à procura de novo emprego - Não DLD"
            }
            if (value === "Desempregados à procura do 1º emprego") {
                place.value = "5 - Desempregados à procura do 1º emprego"
            }
            if (value === "Empregados por conta de outrem - Externos") {
                place.value = "2 - Empregados por conta de outrem - Externos"
            }
            if (value === "Empregados por conta de outrem - Internos" ) {
                place.value = "1 - Empregados por conta de outrem - Internos"
            }
            if (value === "Empregados por conta própria") {
                place.value = "3 - Empregados por conta própria"
            }
            if (value === "Inativos - A frequentarem acções de educação ou formação") {
                place.value = "8 - Inativos - A frequentarem acções de educação ou formação"
            }
            if (value === "Inativos - Outros") {
                place.value = "9 - Inativos - Outros"
            }
        }

        function colocarNacionalidade(place, value) {

            worksheet.dataValidations.add(place._address, {
                type: 'list',
                allowBlank: false,
                formulae: ['Referencias!$A$1:$A$245'],
                showErrorMessage: true,
                errorStyle: 'error',
                error: 'Valor Inválido',
            });
            if (value) {
                place.value = Nacoes(value, "true");
            }
        }



        // Celulas de texto - Cabeçalho
        const A1 = worksheet.getCell('A1');
        const B1 = worksheet.getCell('B1');
        const C1 = worksheet.getCell('C1');
        const D1 = worksheet.getCell('D1');
        const E1 = worksheet.getCell('E1');
        const F1 = worksheet.getCell('F1');
        const G1 = worksheet.getCell('G1');
        const H1 = worksheet.getCell('H1');
        const I1 = worksheet.getCell('I1');
        const J1 = worksheet.getCell('J1');
        const K1 = worksheet.getCell('K1');
        const L1 = worksheet.getCell('L1');
        const M1 = worksheet.getCell('M1');
        const N1 = worksheet.getCell('N1');
        const O1 = worksheet.getCell('O1');
        const P1 = worksheet.getCell('P1');
        const Q1 = worksheet.getCell('Q1');
        const R1 = worksheet.getCell('R1');
        const S1 = worksheet.getCell('S1');
        const T1 = worksheet.getCell('T1');
        const U1 = worksheet.getCell('U1');
        const V1 = worksheet.getCell('V1');
        const W1 = worksheet.getCell('W1');
        const X1 = worksheet.getCell('X1');
        const Y1 = worksheet.getCell('Y1');
        const Z1 = worksheet.getCell('Z1');
        const AA1 = worksheet.getCell('AA1');
        const AB1 = worksheet.getCell('AB1');
        const AC1 = worksheet.getCell('AC1');
        const AD1 = worksheet.getCell('AD1');
        const AE1 = worksheet.getCell('AE1');
        const AF1 = worksheet.getCell('AF1');
        const AG1 = worksheet.getCell('AG1');
        const AH1 = worksheet.getCell('AH1');
        const AI1 = worksheet.getCell('AI1');
        const AJ1 = worksheet.getCell('AJ1');
        const AK1 = worksheet.getCell('AK1');
        const AL1 = worksheet.getCell('AL1');
        const AM1 = worksheet.getCell('AM1');
        const AN1 = worksheet.getCell('AN1');
        const AO1 = worksheet.getCell('AO1');
        const AP1 = worksheet.getCell('AP1');
        const AQ1 = worksheet.getCell('AQ1');
        const AR1 = worksheet.getCell('AR1');
        const AS1 = worksheet.getCell('AS1');
        const AT1 = worksheet.getCell('AT1');
        const AU1 = worksheet.getCell('AU1');
        const AV1 = worksheet.getCell('AV1');
        const AW1 = worksheet.getCell('AW1');
        const AX1 = worksheet.getCell('AX1');
        const AY1 = worksheet.getCell('AY1');
        const AZ1 = worksheet.getCell('AZ1');
        const BA1 = worksheet.getCell('BA1');
        const BB1 = worksheet.getCell('BB1');
        const BC1 = worksheet.getCell('BC1');
        const BD1 = worksheet.getCell('BD1');
        const BE1 = worksheet.getCell('BE1');
        const BF1 = worksheet.getCell('BF1');
        const BG1 = worksheet.getCell('BG1');
        const BH1 = worksheet.getCell('BH1');
        const BI1 = worksheet.getCell('BI1');



        for (let charCode = 1; charCode <= 14; charCode++) {
            const columnName = getColumnLetterFromIndex(charCode)
            const cell = worksheet.getCell(`${columnName}1`);
            cell.alignment = alignment;
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF' + "#03035c".substring(1) } // Blue color
            };
            cell.border = {
                top: { style: 'thin', color: { argb: 'FFFFFF' } }, // White color
                left: { style: 'thin', color: { argb: 'FFFFFF' } }, // White color
                bottom: { style: 'thin', color: { argb: 'FFFFFF' } }, // White color
                right: { style: 'thin', color: { argb: 'FFFFFF' } } // White color
            };
            cell.font = {
                color: { argb: 'FFFFFF' },
                bold: true,
            };
        }


        A1.value = 'Nome';
        B1.value = 'Género';
        C1.value = 'Data Nascimento';
        D1.value = 'Nacionalidade';
        E1.value = 'Morada';
        F1.value = 'Código Postal';
        G1.value = 'NIF';
        H1.value = 'Email';
        I1.value = 'Telefone ';
        J1.value = 'Habilitações';
        K1.value = 'Situação Face Emprego';
        L1.value = 'Data de inicio da situação face ao Emprego';
        M1.value = "Estado";
        N1.value = "Comentário";

        //Dados
        let index = 2
        jsonData.forEach(element => {

            for (let charCode = 1; charCode <= 14; charCode++) {
                const columnName = getColumnLetterFromIndex(charCode)
                const cell = worksheet.getCell(`${columnName}${index}`);
                cell.alignment = alignment;
                cell.border = {
                    top: { style: 'thin' }, 
                    left: { style: 'thin'}, 
                    bottom: { style: 'thin' }, 
                    right: { style: 'thin'} 
                };
            }

            const nome = worksheet.getCell("A" + index);
            const genero = worksheet.getCell("B" + index);
            const dataDeNascimento = worksheet.getCell("C" + index);
            const nacionalidade = worksheet.getCell("D" + index);
            const morada = worksheet.getCell("E" + index);
            const codigoPostal = worksheet.getCell("F" + index);
            const nNIF = worksheet.getCell("G" + index);
            const email = worksheet.getCell("H" + index);
            const nTelemovel = worksheet.getCell("I" + index);
            const grauAcademico = worksheet.getCell("J" + index);
            const situacaoLaboral = worksheet.getCell("K" + index);
            const dataDesemprego = worksheet.getCell("L" + index);
            const estado = worksheet.getCell("M" + index);
            const comentario = worksheet.getCell("N" + index);
        
            nNIF.value = element.NIF;
            nome.value = element.NomeCompleto;
            colocarGenero(genero, element.Genero);
            dataDeNascimento.value = element.DataNascimento;
            colocarNacionalidade(nacionalidade, element.Nacionalidade)
            morada.value = element.Morada;
            codigoPostal.value = element.CodigoPostal;
            nTelemovel.value = element.Ntelemovel;
            email.value = element.Email;
            colocarHabilitacoes(grauAcademico, element.GrauAcademico)
            colocarSituacaoEmprego(situacaoLaboral, element.SituacaoLaborar)
            dataDesemprego.value = element.DataDesemprego && AlterarFormato(element.DataDesemprego);
            estado.value = element.Estado
            comentario.value = element.Comentario


            index++;
        });

    }

    dadosFormandos()
    referecias()
    // Generate the Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Convert the buffer to a Blob
    const blob = new Blob([buffer], { type: 'application/octet-stream' });

    // Save the Blob to the user's computer
    FileSaver.saveAs(blob, 'Formandos.xlsx');
};
export default ExcelFormandosSimples;