import styled from "styled-components";

export const MenuFormando = styled.div`
    

    table, td, th{
  border: 1px solid grey;
}

#link-box {
  width: 150px;
  height: 200px;
  display: none;
  float: right;
}

.toggle:focus~#link-box {
  display: block;
}

#link-box > ul > li {
  list-style: none;
}

#link-box > ul > li > a {
  text-decoration: none;
  color: #000000;
}

button.toggle {
  background: #fff;
  padding: 7px 15px;
  margin-right: 10px;
}

.back-button-link{
  position: absolute;
  top: -6%;
  left: 5%;
}

.back-button{
  box-shadow: none !important;
}
.fc-v-event .fc-event-main{
  color:#172554;
  font-family: "Lato";
  font-size: medium;
  font-weight: bold;
  border: none;
}
.fc-v-event .fc-event-main:hover{
  border: none;
}
.fc {
  background-color: #E6F0F9; /* Light grey */
  width: 60vw !important; /* Set maximum width to 50% of viewport width */
  margin: 0 auto; /* Center the calendar horizontally */
  height: 80vh;
}

/* Header color */
.fc-toolbar {
  background-color: #172554; 
  color: #fff; /* White text */
}

/* Event color */
.fc-event {
  background-color: #fff; 
  color: #172554;
  border: none;
}

/* Hover and active states for events */
.fc-event:hover,
.fc-event-active {
  background-color: #8994e7; /* Darker grey */
  cursor: pointer;
}

.fc-header-toolbar{
  margin-bottom:0 !important;
}

.fc th  {
  background-color: #172554;
  color: #ffffff; /* White text */
  font-size: 16px; /* Increase font size */
  text-transform: uppercase; /* Convert text to uppercase */
}

/* Table header hover state */
.fc th:hover {
  background-color: #8994e7;
  cursor: default;
} 

.fc-daygrid-day-frame:hover {
    cursor: cell;
    background-color: #677bbd;
}

@media (max-width: 768px) {
  /* Adjust the size of calendar elements or hide non-essential components */
  .fc {
    width: 80vh;
    height: 60vh !important; /* Set maximum width to 50% of viewport width */
  }

  .fc-event {
    font-size: 12px;
  }

  .fc-button-group{
    font-size: 10px;
    display: inline-flex;
    position: relative;
    vertical-align: middle;
    flex-direction: column;
  }

  .fc-toolbar-title{
    font-size: 12px !important;
    text-align: center;
  }

  div.fc-toolbar-chunk:nth-child(3) .fc-button-group{
    display: contents;
  }

  .fc-today-button{
    display: flex;
    margin: auto;
    font-size: 10px;
  }

  .fc-col-header-cell-cushion{
    font-size: 10px;
  }

  .fc-timeGridWeek-view  .fc-col-header-cell-cushion{
    font-size: 8px;
  }

  .fc-daygrid-day-top{
    font-size: 10px;
  }

  .fc-timeGridWeek-view .fc-slats tr:first-child {   width: 1px; /* Set the desired width for the first column */ }
}

/* Prev button (grey) */

.fc-button-primary{
  background-color: #b2b2b2;
  border:1px solid grey;
}
.fc-button-primary:hover{
  background-color: #8994e7 !important;
}
.fc-button-primary:focus{
  border:0 !important;
  box-shadow: none !important;
}
.fc-button-primary:disabled{
  background-color: #000000b0 !important;
}
.fc-button-active{
  background-color: #8994e7 !important;
}
/* .fc-button-primary:not(:disabled):active{
  background-color: #172554 !important;
  border:1px #000000b0 !important;
} */
.fc-toolbar-title{
  font-size: 1.5em;
}
`;
