import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import axios from 'axios';
import AlterarFormato from '../../FormChecks/formatDates/foramatDates';
export default async function PlanoDeSessao(userData, ufcd ,sessao) {
    // Imagens
    // Inicio Excel
    const workbook = new ExcelJS.Workbook();

    //INICIO DA PÁGINA CRONOGRAMA
    const worksheet = workbook.addWorksheet('Cronograma');

    const bold = { bold: true };
    const borderThin = {
        top: { style: 'thin' },    // Top border
        bottom: { style: 'thin' }, // Bottom border
        left: { style: 'thin' },   // Left border
        right: { style: 'thin' },  // Right border
    };
    // Tratar das cores
    function applyFillToCell(worksheet, cells) {
        const color = cells[0];
        cells.border = borderThin;
        cells.slice(1).forEach(cell => {
            worksheet.getCell(cell).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: color },
            };
        });
    }
    function convertHourString(start, end) {
        const dateStart = new Date(start);
        const dateEnd = new Date(end);

        // Calculate the difference in milliseconds
        const diffInMs = dateEnd - dateStart;

        // Convert milliseconds to hours and minutes
        const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
        const diffInMinutes = Math.floor(
          (diffInMs % (1000 * 60 * 60)) / (1000 * 60)
        );

        // Return the result as an object or string, depending on your needs
        return `${diffInHours}h ${diffInMinutes}min`;
      }
    // Variaveis de estilização
    const alignment = {
        horizontal: 'center',
        vertical: 'middle',
        wrapText: true,
    };
    const title = { bold: true, name: "DejaVu Sans", size: 14 };
    async function cabecalho() {
        const imageUrlEntFormador = userData.entFormadora[0].logotipo;
        const imageBufferEFor = await axios.get(imageUrlEntFormador, { responseType: 'arraybuffer' });


        // Create a function to load and add the image
        async function loadImageAndAddToWorksheet() {
            // Create a new Image object
            const img = new Image();

            // Create a promise to resolve when the image has loaded
            return new Promise((resolve, reject) => {
                img.onload = function () {
                    const originalWidth = img.width;
                    const originalHeight = img.height;

                    // Calculate the scaling factor based on the desired width (e.g., 150)

                    const scalingFactor = originalWidth / originalHeight;

                    // Calculate the scaled height
                    const scaledHeight = 50;
                    const desiredWidth = scalingFactor * 50;

                    const imageIdEFor = workbook.addImage({
                        buffer: imageBufferEFor.data,
                        extension: 'png',
                    });

                    worksheet.addImage(imageIdEFor, {
                        tl: { col: 0.5, row: 0.4 },
                        ext: { width: desiredWidth, height: scaledHeight },
                    });

                    // Resolve the promise
                    resolve();
                };

                // Set the source of the image to your image file
                img.src = imageUrlEntFormador;
            });
        }

        // Call the function to load and add the image
        await loadImageAndAddToWorksheet();

        if (userData.entFinanciadora.length > 0) {
            if (userData.entFinanciadora[0].logotipo1) {
                const imageUrlEntFinanciadora = userData.entFinanciadora[0].logotipo1;
                const imageBuffer = await axios.get(imageUrlEntFinanciadora, { responseType: 'arraybuffer' });

                async function loadImage2AndAddToWorksheet() {
                    // Create a new Image object
                    const img = new Image();

                    // Create a promise to resolve when the image has loaded
                    return new Promise((resolve, reject) => {
                        img.onload = function () {
                            const originalWidth = img.width;
                            const originalHeight = img.height;


                            const scalingFactor = originalWidth / originalHeight;

                            // Calculate the scaled height
                            const scaledHeight = 50;
                            const desiredWidth = scalingFactor * 50;


                            const imageId2 = workbook.addImage({
                                buffer: imageBuffer.data,
                                extension: 'png',
                            });
                            worksheet.addImage(imageId2, {
                                tl: { col: 32, row: 0.4 },
                                ext: { width: desiredWidth, height: scaledHeight },
                            });

                            // Resolve the promise
                            resolve();
                        };

                        // Set the source of the image to your image file
                        img.src = imageUrlEntFinanciadora;
                    });
                }
                await loadImage2AndAddToWorksheet();
            }
            if (userData.entFinanciadora[0].logotipo2) {
                const imageUrlEntFinanciadora = userData.entFinanciadora[0].logotipo2;
                const imageBuffer = await axios.get(imageUrlEntFinanciadora, { responseType: 'arraybuffer' });

                async function loadImage2AndAddToWorksheet() {
                    // Create a new Image object
                    const img = new Image();

                    // Create a promise to resolve when the image has loaded
                    return new Promise((resolve, reject) => {
                        img.onload = function () {
                            const originalWidth = img.width;
                            const originalHeight = img.height;


                            const scalingFactor = originalWidth / originalHeight;

                            // Calculate the scaled height
                            const scaledHeight = 50;
                            const desiredWidth = scalingFactor * 50;


                            const imageId2 = workbook.addImage({
                                buffer: imageBuffer.data,
                                extension: 'png',
                            });
                            worksheet.addImage(imageId2, {
                                tl: { col: 32, row: 45 },
                                ext: { width: desiredWidth, height: scaledHeight },
                            });

                            // Resolve the promise
                            resolve();
                        };

                        // Set the source of the image to your image file
                        img.src = imageUrlEntFinanciadora;
                    });
                }
                await loadImage2AndAddToWorksheet();
            }

        }
        const convertDateFormat3 = (dateString) => {
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
          };
        // Celulas de texto - Cabeçalho
        const A1 = worksheet.getCell('A1');
        const A2 = worksheet.getCell('A2');
        const A3 = worksheet.getCell('A3');
        const A4 = worksheet.getCell('A4');
        const A5 = worksheet.getCell('A5');
        const A6 = worksheet.getCell('A6');
        const A7 = worksheet.getCell('A7');
        const A8 = worksheet.getCell('A8');
        const A9 = worksheet.getCell('A9');
        const A10 = worksheet.getCell('A10');
        const A11 = worksheet.getCell('A11');
        const A12 = worksheet.getCell('A12');

        const D2 = worksheet.getCell('D2');
        const D3 = worksheet.getCell('D3');
        const D4 = worksheet.getCell('D4');
        const D5 = worksheet.getCell('D5');
        const D6 = worksheet.getCell('D6');
        const D11 = worksheet.getCell('D11');
        const D12 = worksheet.getCell('D12');

        const E6 = worksheet.getCell('E6');
        const E7 = worksheet.getCell('E7');
        const E8 = worksheet.getCell('E8');
        const E10 = worksheet.getCell('E10');

        const L6 = worksheet.getCell('L6');
        const L7 = worksheet.getCell('L7');
        const L8 = worksheet.getCell('L8');
        const L9 = worksheet.getCell('L9');
        const L10 = worksheet.getCell('L10');

        const V3 = worksheet.getCell('V3');
        const V4 = worksheet.getCell('V4');
        const V11 = worksheet.getCell('V11');
        const V12 = worksheet.getCell('V12');

        const S6 = worksheet.getCell('S6');
        const S7 = worksheet.getCell('S7');
        const S8 = worksheet.getCell('S8');
        const S9 = worksheet.getCell('S9');
        const S10 = worksheet.getCell('S10');

        const Z6 = worksheet.getCell('Z6');
        const Z7 = worksheet.getCell('Z7');
        const Z8 = worksheet.getCell('Z8');
        const Z9 = worksheet.getCell('Z9');
        const Z10 = worksheet.getCell('Z10');

        const AD2 = worksheet.getCell('AD2');
        const AD3 = worksheet.getCell('AD3');
        const AD4 = worksheet.getCell('AD4');
        const AD11 = worksheet.getCell('AD11');
        const AD12 = worksheet.getCell('AD12');

        const AG6 = worksheet.getCell('AG6');
        const AG7 = worksheet.getCell('AG7');
        const AG8 = worksheet.getCell('AG8');
        const AG9 = worksheet.getCell('AG9');
        const AG10 = worksheet.getCell('AG10');

        // Valor das Celulas
        A1.value = 'Plano de Sessao';
        A2.value = 'Entidade Formadora:'; D2.value = userData.entFormadora[0].name
        A3.value = 'Tipologia de Operação:'; D3.value = userData.operacao[0].nomeTipologia; V3.value = "Modalidade de Formação:"; AD3.value = userData.modalidade
        A4.value = 'Operação:'; D4.value = userData.operacao[0].codigoOperacao; V4.value = "Código e Área de Formação:"; AD4.value = userData.areaCodigoFormacao
        A5.value = 'Curso/Percurso:'; D5.value = userData.name;
        A6.value = 'UFCD/Disciplina:'; D6.value = ufcd.codeUfcd + " - " +ufcd.name;
        A7.value = 'Código Administrativo (em SIGO):'; E7.value = 'Código Interno:'; L7.value = 'Nível:'; S7.value = 'Carga Horária:'; Z7.value = 'N.º Curso (em SIIFSE):'; AG7.value = 'N.º Ação (em SIIFSE):';
        A8.value = userData.codeSIGO; E8.value = userData.codeInterno; L8.value = userData.nivel; S8.value = userData.duration; Z8.value = userData.cursoN;; AG8.value = userData.acaoN;;
        A10.value = "Data de Início:"; E10.value = AlterarFormato(userData.dateBegin); L10.value = "Data de Fim:"; S10.value = AlterarFormato(userData.dateEnd); Z10.value = 'Horário:'; AG10.value = userData.horario;

        A11.value = 'Local de Formação:'; D11.value = userData.locality; V11.value = "Data da Sessão"; AD11.value = convertDateFormat3(sessao.start)
        A12.value = 'Formador/a:'; D12.value = sessao.formadores[0].fullName; V12.value = "Rubrica Formador/a:";
        console.log(sessao)
        // Centrar
        A1.alignment = alignment
        A2.alignment = alignment
        A3.alignment = alignment
        A4.alignment = alignment
        A5.alignment = alignment
        A6.alignment = alignment
        A7.alignment = alignment
        A8.alignment = alignment
        A9.alignment = alignment
        A10.alignment = alignment
        A11.alignment = alignment
        A12.alignment = alignment

        E7.alignment = alignment
        E8.alignment = alignment
        E10.alignment = alignment

        L6.alignment = alignment
        L7.alignment = alignment
        L8.alignment = alignment
        L9.alignment = alignment

        V3.alignment = alignment
        V4.alignment = alignment
        V11.alignment = alignment
        V12.alignment = alignment

        S6.alignment = alignment
        S7.alignment = alignment
        S8.alignment = alignment
        S9.alignment = alignment

        Z6.alignment = alignment
        Z7.alignment = alignment
        Z8.alignment = alignment
        Z9.alignment = alignment

        AG6.alignment = alignment
        AG7.alignment = alignment
        AG8.alignment = alignment
        AG9.alignment = alignment

        // Negrito
        A1.font = title
        A2.font = bold
        A3.font = bold
        A4.font = bold
        A5.font = bold
        A6.font = bold
        A7.font = bold
        A9.font = bold
        A10.font = bold
        A11.font = bold
        A12.font = bold

        E6.font = bold

        L6.font = bold
        L9.font = bold

        V3.font = bold
        V4.font = bold
        V11.font = bold
        V12.font = bold

        S6.font = bold

        Z6.font = bold
        Z9.font = bold

        AG6.font = bold

        // Collumn Width
        worksheet.getColumn('A').width = 12
        worksheet.getColumn('B').width = 8
        for (let columnNumber = 3; columnNumber <= 39; columnNumber++) {
            worksheet.getColumn(columnNumber).width = 6.5;
        }
        // Row height
        worksheet.getRow('1').height = 50
        worksheet.getRow('6').height = 35

        // Estutura - Cabeçalho 
        worksheet.mergeCells('A1:AM1');
        worksheet.mergeCells('A2:C2'); worksheet.mergeCells('D2:AM2');
        worksheet.mergeCells('A3:C3'); worksheet.mergeCells('D3:U3'); worksheet.mergeCells('V3:AC3'); worksheet.mergeCells('AD3:AM3');
        worksheet.mergeCells('A4:C4'); worksheet.mergeCells('D4:U4'); worksheet.mergeCells('V4:AC4'); worksheet.mergeCells('AD4:AM4');
        worksheet.mergeCells('A5:C5'); worksheet.mergeCells('D5:AM5');
        worksheet.mergeCells('A6:C6'); worksheet.mergeCells('D6:AM6');
        worksheet.mergeCells('A7:D7'); worksheet.mergeCells('E7:K7'); worksheet.mergeCells('L7:R7'); worksheet.mergeCells('S7:Y7'); worksheet.mergeCells('Z7:AF7'); worksheet.mergeCells('AG7:AM7');
        worksheet.mergeCells('A8:D9'); worksheet.mergeCells('E8:K9'); worksheet.mergeCells('L8:R9'); worksheet.mergeCells('S8:Y9'); worksheet.mergeCells('Z8:AF9'); worksheet.mergeCells('AG8:AM9');
        worksheet.mergeCells('A10:D10'); worksheet.mergeCells('E10:K10'); worksheet.mergeCells('L10:R10'); worksheet.mergeCells('S10:Y10'); worksheet.mergeCells('Z10:AF10'); worksheet.mergeCells('AG10:AM10');
        worksheet.mergeCells('A11:C11'); worksheet.mergeCells('D11:U11'); worksheet.mergeCells('V11:AC11'); worksheet.mergeCells('AD11:AM11');
        worksheet.mergeCells('A12:C12'); worksheet.mergeCells('D12:U12'); worksheet.mergeCells('V12:AC12'); worksheet.mergeCells('AD12:AM12');

        // Estilização - Cabeçalho
        const startCell = worksheet.getCell('A1');
        const endCell = worksheet.getCell('AD7');

        worksheet.eachRow({ from: startCell.row, to: endCell.row }, (row) => {
            row.eachCell((cell) => {
                cell.border = borderThin
            });
        });
        const cells = ['b2b2b2', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A10', 'A11', 'A12', 'E7', 'L7', 'S7', 'Z7', 'AG7', 'V3', 'V4', 'V11', 'V12', 'L10', 'Z10'];

        //Palete de Cores
        applyFillToCell(worksheet, cells);
    }

    async function corpo() {

        const A16 = worksheet.getCell('A16');
        const A17 = worksheet.getCell('A17');
        const A18 = worksheet.getCell('A18');

        const D17 = worksheet.getCell('D17');

        const E7 = worksheet.getCell('E7');
        const E16 = worksheet.getCell('E16');
        const E18 = worksheet.getCell('E18');

        const F18 = worksheet.getCell('F18');
        
        const L7 = worksheet.getCell('L7');
        const L10 = worksheet.getCell('L10');    
        const L16 = worksheet.getCell('L16');
        const L18 = worksheet.getCell('L18');

        const S7 = worksheet.getCell('S7');
        const S16 = worksheet.getCell('S16');
        const S18 = worksheet.getCell('S18');

        const Z7 = worksheet.getCell('Z7');
        const Z10 = worksheet.getCell('Z10');       
        const Z16 = worksheet.getCell('Z16');

        const AB18 = worksheet.getCell('AB18');

        const AG16 = worksheet.getCell('AG16');

        const AH18 = worksheet.getCell('AH18');

        const AK18 = worksheet.getCell('AK18');

        A16.font = bold;
        A17.font = bold;
        A18.font = bold;

        D17.font = bold;

        E7.font = bold;
        E16.font = bold;
        E18.font = bold;

        F18.font = bold;

        L7.font = bold
        L10.font = bold
        L16.font = bold;
        L18.font = bold;

        S7.font = bold
        S16.font = bold;
        S18.font = bold;

        Z7.font = bold;
        Z10.font = bold;
        Z16.font = bold;

        AB18.font = bold;

        AG16.font = bold;
        
        AH18.font = bold;

        AK18.font = bold;

        A16.alignment = alignment;
        A17.alignment = alignment;
        A18.alignment = alignment;

        D17.alignment = alignment;

        E16.alignment = alignment;
        E18.alignment = alignment;

        F18.alignment = alignment;

        L16.alignment = alignment;
        L18.alignment = alignment;

        S16.alignment = alignment;
        S18.alignment = alignment;

        Z16.alignment = alignment;

        AB18.alignment = alignment;

        AG16.alignment = alignment;

        AH18.alignment = alignment;

        AK18.alignment = alignment;


        worksheet.mergeCells('A16:D16'); worksheet.mergeCells('E16:K16'); worksheet.mergeCells('L16:R16'); worksheet.mergeCells('S16:Y16'); worksheet.mergeCells('Z16:AF16'); worksheet.mergeCells('AG16:AM16');
        worksheet.mergeCells('A17:C17'); worksheet.mergeCells('D17:AM17');

        worksheet.mergeCells('A18:E18');
        worksheet.mergeCells('F18:K18');
        worksheet.mergeCells('L18:R18');
        worksheet.mergeCells('S18:AA18');
        worksheet.mergeCells('AB18:AG18');
        worksheet.mergeCells('AH18:AJ18');
        worksheet.mergeCells('AK18:AM18');

        worksheet.mergeCells('A19:E22');
        worksheet.mergeCells('F19:K22');
        worksheet.mergeCells('L19:R22');
        worksheet.mergeCells('S19:AA22');
        worksheet.mergeCells('AB19:AG22');
        worksheet.mergeCells('AH19:AJ22');
        worksheet.mergeCells('AK19:AM22');

        worksheet.mergeCells('A23:E26');
        worksheet.mergeCells('F23:K26');
        worksheet.mergeCells('L23:R26');
        worksheet.mergeCells('S23:AA26');
        worksheet.mergeCells('AB23:AG26');
        worksheet.mergeCells('AH23:AJ26');
        worksheet.mergeCells('AK23:AM26');

        worksheet.mergeCells('A27:E30');
        worksheet.mergeCells('F27:K30');
        worksheet.mergeCells('L27:R30');
        worksheet.mergeCells('S27:AA30');
        worksheet.mergeCells('AB27:AG30');
        worksheet.mergeCells('AH27:AJ30');
        worksheet.mergeCells('AK27:AM30');

        worksheet.mergeCells('A31:E34');
        worksheet.mergeCells('F31:K34');
        worksheet.mergeCells('L31:R34');
        worksheet.mergeCells('S31:AA34');
        worksheet.mergeCells('AB31:AG34');
        worksheet.mergeCells('AH31:AJ34');
        worksheet.mergeCells('AK31:AM34');

        worksheet.mergeCells('A35:E38');
        worksheet.mergeCells('F35:K38');
        worksheet.mergeCells('L35:R38');
        worksheet.mergeCells('S35:AA38');
        worksheet.mergeCells('AB35:AG38');
        worksheet.mergeCells('AH35:AJ38');
        worksheet.mergeCells('AK35:AM38');


        A16.value = "Público-Alvo:";E16.value = "";L16.value = "Nº Sessão:";S16.value= sessao.sessao;Z16.value = "Duração";AG16.value = convertHourString(sessao.start, sessao.end)
       A17.value= "Objetivos Pedagógicos:";D17.value = ""
       
       A18.value= "Objetivos Específicos";F18.value= "Fases";L18.value="Conteúdos";S18.value="Métodos e técnicas pedagógicas"; AB18.value="Recursos didáticos";AH18.value= "Tempo"; AK18.value= "Avaliação"
        const startCell = worksheet.getCell('A16');
        const endCell = worksheet.getCell('AM38');
        worksheet.eachRow({ from: startCell.row, to: endCell.row }, (row) => {
            row.eachCell((cell) => {
                cell.border = borderThin
            });
        });
        worksheet.getRow('13').height = 5
        worksheet.getRow('14').height = 5
        worksheet.getRow('15').height = 5
    }

    async function Footer (){
        const A40 = worksheet.getCell('A40');
        worksheet.mergeCells('A40:AM40');
        A40.font=bold
        A40.alignment=alignment

        A40.value = "O/A Formador/a: ______________________________________"

    } 
    await cabecalho()
    await corpo()
    await Footer()
    // Generate the Excel file
    // Generate the Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Convert the buffer to a Blob
    const blob = new Blob([buffer], { type: 'application/octet-stream' });

    // Save the Blob to the user's computer
    FileSaver.saveAs(blob, 'Plano de Sessao' + userData.name + '.xlsx');
}